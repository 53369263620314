import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as HvacIcon } from 'icons/hvac-icon.svg'
import BinaryBarChartTotalVsUsed from 'components/Charts/BinaryBarChart/BinaryBarChartTotalVsUsed'

const HvacListSummary = ({ data }) => {
  if (!data) return null

  const summaryMap = {
    title: `HVACs`,
    titleIcon: <HvacIcon />,
    titleChart: (
      <BinaryBarChartTotalVsUsed
        positiveCount={data.healthyCount}
        totalCount={data.totalCount}
        tooltip={`Healthy: ${data.healthyCount} | Total: ${data.totalCount}`}
        id="HvacHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Healthy',
            value: data.healthyCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: data.totalCount.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default HvacListSummary
