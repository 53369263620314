import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import * as S from './styles'
import ModalV2 from 'components/Modals/ModalV2/ModalV2'
import { ModalFooter } from './ModalFooter'
import { ControlledSelect } from './ControlledSelect'
import {
  postEksPcsGroupControlModeSettings,
  postEksPcsGroupSetpointSettings,
  postEksPcsModulesSetpointSettings,
} from 'api/queries.api'
import { SessionContext } from 'contexts/session'
import { useParams } from 'react-router-dom'

const FORM_NAME = 'editStackOsControlMode'

export function EksPcsGroupEditSettingsModal({ closeModal, data, toast }) {
  const { session } = React.useContext(SessionContext)
  const { stationCode, blockIndex, eksPcsGroupId } = useParams()

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    register,
    watch,
  } = useForm({
    defaultValues: {
      controlMode: data?.interfaceOperationMode ?? 'PPC_CONTROL',
      pcsGroup: {
        runCommand: '',
        totalRealPowerCmdkW: '',
        totalReactivePowerCmdkVAr: '',
        durationMillis: null,
      },
      pcsModules:
        data?.pcsModules?.map(({ arrayIndex, arrayPcsIndex }) => ({
          arrayIndex,
          arrayPcsIndex,
          runCommand: '',
          totalRealPowerCmdkW: '',
          totalReactivePowerCmdkVAr: '',
          durationMillis: null,
        })) ?? [],
    },
  })

  const onSubmit = async (formData) => {
    closeModal()
    toast.toastPending('Submitting PCS settings...')
    // Control Mode
    // If control mode changed, post it
    if (data?.interfaceOperationMode !== formData.controlMode) {
      try {
        const res = await postEksPcsGroupControlModeSettings(
          session,
          stationCode,
          blockIndex,
          eksPcsGroupId,
          {
            opModeInterfaceCmd: formData?.controlMode,
          },
        )
        if (!res?.valid) throw res
      } catch (e) {
        toast.toastError(
          e?.response?.data?.message ??
            'Something went wrong, please try again.',
        )
        return
      }
    }

    // StackOS Settings
    // If was and stays in stackos mode, submit settings
    if (
      !(
        data?.interfaceOperationMode === 'STACK_OS_CONTROL' &&
        formData.controlMode === 'STACK_OS_CONTROL'
      )
    ) {
      toast.toastSuccess('PCS settings submitted succesfully!')
      return
    }

    try {
      let res

      if (formData.commandLevel === 'pcsGroup') {
        res = await postEksPcsGroupSetpointSettings(
          session,
          stationCode,
          blockIndex,
          eksPcsGroupId,
          {
            runCommand:
              formData.pcsGroup.runCommand === ''
                ? null
                : formData.pcsGroup.runCommand,
            totalRealPowerCmdkW:
              formData.pcsGroup.totalRealPowerCmdkW === ''
                ? null
                : +formData.pcsGroup.totalRealPowerCmdkW,
            totalReactivePowerCmdkVAr:
              formData.pcsGroup.totalReactivePowerCmdkVAr === ''
                ? null
                : +formData.pcsGroup.totalReactivePowerCmdkVAr,
            durationMillis:
              formData.pcsGroup.durationMillis === ''
                ? null
                : +formData.pcsGroup.durationMillis,
          },
        )
      }

      if (formData.commandLevel === 'pcsModules') {
        res = await postEksPcsModulesSetpointSettings(
          session,
          stationCode,
          blockIndex,
          eksPcsGroupId,
          {
            pcsModules:
              formData?.pcsModules?.map((m) => ({
                ...m,
                runCommand: m.runCommand === '' ? null : m.runCommand,
                totalRealPowerCmdkW:
                  m.totalRealPowerCmdkW === '' ? null : +m.totalRealPowerCmdkW,
                totalReactivePowerCmdkVAr:
                  m.totalReactivePowerCmdkVAr === ''
                    ? null
                    : +m.totalReactivePowerCmdkVAr,
                durationMillis:
                  m.durationMillis === '' ? null : +m.durationMillis,
              })) ?? [],
          },
        )
      }

      if (!res?.valid) throw res
    } catch (e) {
      toast.toastError(
        e?.response?.data?.message ?? 'Something went wrong, please try again.',
      )
      return
    }
    toast.toastSuccess('PCS settings submitted succesfully!')
  }

  const controlMode = watch('controlMode')
  const commandLevel = watch('commandLevel', 'pcsGroup')

  const ppcControlIsActiveOrSelected =
    data?.interfaceOperationMode === 'PPC_CONTROL' ||
    Boolean(controlMode === 'PPC_CONTROL')

  return (
    <S.Container>
      <ModalV2
        title="EKS PCS Group Settings"
        handleClose={closeModal}
        footerContent={
          <ModalFooter
            disableSubmit={!isValid || isSubmitting}
            onCancel={closeModal}
            formName={FORM_NAME}
          />
        }
      >
        <S.Form onSubmit={handleSubmit(onSubmit)} id={FORM_NAME}>
          <S.Row>
            <S.Field>
              <label>CONTROL MODE</label>
              <ControlledSelect
                name="controlMode"
                control={control}
                options={[
                  { value: 'PPC_CONTROL', label: 'PPC Control' },
                  { value: 'STACK_OS_CONTROL', label: 'StackOS Control' },
                ]}
              />
            </S.Field>
            <S.Field>
              <label>COMMAND LEVEL</label>
              <ControlledSelect
                name="commandLevel"
                defaultValue="pcsGroup"
                disabled={ppcControlIsActiveOrSelected}
                control={control}
                options={[
                  { value: 'pcsGroup', label: 'PCS Group' },
                  { value: 'pcsModules', label: 'PCS Module' },
                ]}
              />
            </S.Field>
          </S.Row>
          {commandLevel === 'pcsGroup' && (
            <>
              <S.Row>
                <span style={{ marginBottom: '-0.5rem' }}>PCS GROUP</span>
              </S.Row>
              <S.Row>
                <S.Field>
                  <label>COMMAND</label>
                  <ControlledSelect
                    control={control}
                    allowEmpty
                    name="pcsGroup.runCommand"
                    disabled={ppcControlIsActiveOrSelected}
                    options={[
                      { label: 'Run', value: 'RUN' },
                      { label: 'Stop', value: 'STOP' },
                      { label: 'Disconnect', value: 'DISCONNECT' },
                    ]}
                  />
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field>
                  <label>REAL POWER</label>
                  <S.Input
                    id="pcsGroup.totalRealPowerCmdkW"
                    disabled={ppcControlIsActiveOrSelected}
                    type="number"
                    min={0}
                    step="any"
                    {...register('pcsGroup.totalRealPowerCmdkW', {})}
                  />
                </S.Field>
                <S.Field>
                  <label>REACTIVE POWER</label>
                  <S.Input
                    id="pcsGroup.totalReactivePowerCmdkVAr"
                    disabled={ppcControlIsActiveOrSelected}
                    type="number"
                    min={0}
                    step="any"
                    {...register('pcsGroup.totalReactivePowerCmdkVAr', {})}
                  />
                </S.Field>
                <S.Field>
                  <label>DURATION (ms)</label>
                  <S.Input
                    id="pcsGroup.durationMillis"
                    disabled={ppcControlIsActiveOrSelected}
                    type="number"
                    min={1}
                    step={1}
                    {...register('pcsGroup.durationMillis', {})}
                  />
                </S.Field>
              </S.Row>
            </>
          )}
          {commandLevel === 'pcsModules' && (
            <>
              {watch('pcsModules').map((f, i) => (
                <Fragment key={f.arrayIndex}>
                  <S.Row>
                    <span>
                      PCS MODULE {f.arrayIndex} :: {f.arrayPcsIndex}
                    </span>
                  </S.Row>
                  <S.Row>
                    <S.Field>
                      <label>COMMAND</label>
                      <ControlledSelect
                        control={control}
                        name={`pcsModules.${i}.runCommand`}
                        allowEmpty
                        disabled={ppcControlIsActiveOrSelected}
                        options={[
                          { label: 'Run', value: 'RUN' },
                          { label: 'Stop', value: 'STOP' },
                          { label: 'Disconnect', value: 'DISCONNECT' },
                        ]}
                      />
                    </S.Field>
                  </S.Row>
                  <S.Row>
                    <S.Field>
                      <label>REAL POWER</label>
                      <S.Input
                        disabled={ppcControlIsActiveOrSelected}
                        type="number"
                        min={0}
                        step="any"
                        {...register(`pcsModules.${i}.totalRealPowerCmdkW`, {})}
                      />
                    </S.Field>
                    <S.Field>
                      <label>REACTIVE POWER</label>
                      <S.Input
                        disabled={ppcControlIsActiveOrSelected}
                        type="number"
                        min={0}
                        step="any"
                        {...register(
                          `pcsModules.${i}.totalReactivePowerCmdkVAr`,
                          {},
                        )}
                      />
                    </S.Field>
                    <S.Field>
                      <label>DURATION (ms)</label>
                      <S.Input
                        disabled={ppcControlIsActiveOrSelected}
                        type="number"
                        allowEmpty
                        min={1}
                        step={1}
                        {...register(`pcsModules.${i}.durationMillis`, {})}
                      />
                    </S.Field>
                  </S.Row>
                </Fragment>
              ))}
            </>
          )}
        </S.Form>
      </ModalV2>
    </S.Container>
  )
}
