import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'
import { idsToString } from './SetPvPcsRotationModal'

const pvPcsDidUpdate = (pvPcsList, commandForm) => {
  let ret = false
  pvPcsList.pvPcses.forEach((pvPcs) => {
    if (pvPcs.outRotation === (commandForm.outRotation === 'true')) {
      ret = true
    }
  })
  return ret
}

const heading = 'IN ROTATION'

const ResultTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.outRotation === 'false' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
      )}
    </>
  )
}

export const PvPcsRotationResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={idsToString(ids)}
      userInputForm={userInputForm}
      pendingEvaluation={pvPcsDidUpdate}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={'pvPcsRotation'}
    />
  )
}
