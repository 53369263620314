import styled from 'styled-components/macro'

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 35px;
  border-bottom: 1px solid #d8d8d8;
  //box-shadow: inset 0 -1px 4px 0px rgb(0 0 0 / 10%);
  font-size: 12px;
`
const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid;
  border-color: #d8d8d8;

  &:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }
`
const ToolbarLabel = styled.div`
  margin-right: 5px;
  color: #717b82;
  font-weight: 500;
`
const ToggleButton = styled.div`
  display: flex;
  cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
  padding: 6px;
  fill: ${(props) => (props.isSelected ? '#5d5d5d' : '#d4d4d4')};
  border-radius: 5px;

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? 'transparent' : '#eaeaea'};
  }

  &:first-child {
    margin-right: 5px;
  }
`
const ToggleButtonIcon = styled.div`
  width: 12px;
  height: 12px;
  line-height: 0;
`
const ToolbarSelect = styled.select`
  flex: 1;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  background-color: transparent;
  padding: 2px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
`

export {
  Toolbar,
  ToolbarSection,
  ToolbarLabel,
  ToggleButton,
  ToggleButtonIcon,
  ToolbarSelect,
}
