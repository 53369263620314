import { useState, useEffect } from 'react'
import Table from 'components/Table/Table/Table'
import { formattedDate } from 'utils/formatting'

const getRowId = (row) => Number(row.timestamp)

const boldHandler = (status, content) => {
  if (status === 'Active') {
    return <strong>{content}</strong>
  }
  return content
}

const levelHandler = (level) => {
  if (level === 'S') return 'String'
  return level
}

const cgAndBpHandler = (level, id) => {
  if (level === 'S') return '—'
  return id
}

const categoryHandler = (category) => {
  return category.charAt(0) + category.slice(1).toLowerCase()
}

const columns = [
  {
    name: 'LEVEL',
    id: 'level',
    selector: (row) => row.level,
    sortable: true,
    width: '70px',
    noOmit: true,
    cell: (row) => boldHandler(row.status, levelHandler(row.level)),
  },
  {
    name: 'BP ID',
    id: 'bpId',
    selector: (row) => row.bpId,
    sortable: true,
    width: '60px',
    cell: (row) => boldHandler(row.status, cgAndBpHandler(row.level, row.bpId)),
    noOmit: false,
    omit: false,
  },
  {
    name: 'CG ID',
    id: 'cgId',
    selector: (row) => row.cgId,
    sortable: true,
    width: '60px',
    cell: (row) => boldHandler(row.status, cgAndBpHandler(row.level, row.cgId)),
    noOmit: false,
    omit: false,
  },
  {
    name: 'DESCRIPTION',
    id: 'description',
    selector: (row) => row.description,
    sortable: true,
    width: '400px',
    cell: (row) => boldHandler(row.status, row.description),
    noOmit: false,
    omit: false,
  },
  {
    name: 'CATEGORY',
    id: 'category',
    selector: (row) => row.category,
    sortable: true,
    width: '110px',
    cell: (row) => boldHandler(row.status, categoryHandler(row.category)),
    noOmit: false,
    omit: false,
  },
  {
    name: 'STATUS',
    id: 'status',
    selector: (row) => row.status,
    sortable: true,
    width: '100px',
    cell: (row) => boldHandler(row.status, row.status),
    noOmit: false,
    omit: false,
  },
  {
    name: 'TIMESTAMP',
    id: 'timestamp',
    selector: (row) => row.timestamp,
    sortable: true,
    width: '180px',
    cell: (row) => boldHandler(row.status, formattedDate(row.timestamp)),
    noOmit: false,
    omit: false,
  },
]

const filters = [
  {
    name: 'String',
    id: 'string',
    omit: false,
    filterBy: (row) => row.level !== 'S',
    section: 'Level',
  },
  {
    name: 'BP',
    id: 'bp',
    omit: false,
    filterBy: (row) => row.level !== 'BP',
    section: 'Level',
  },
  {
    name: 'CG',
    id: 'cg',
    omit: false,
    filterBy: (row) => row.level !== 'CG',
    section: 'Level',
  },
  {
    name: 'Alarm',
    id: 'alarm',
    omit: false,
    filterBy: (row) => row.category !== 'ALARM',
    section: 'Category',
  },
  {
    name: 'Info',
    id: 'info',
    omit: false,
    filterBy: (row) => row.category !== 'INFO',
    section: 'Category',
  },
  {
    name: 'Warning',
    id: 'warning',
    omit: false,
    filterBy: (row) => row.category !== 'WARNING',
    section: 'Category',
  },
  {
    name: 'Active',
    id: 'active',
    omit: false,
    filterBy: (row) => row.status !== 'Active',
    section: 'Status',
  },
  {
    name: 'Event',
    id: 'event',
    omit: false,
    filterBy: (row) => row.status !== 'Event',
    section: 'Status',
  },
]

const NotificationsAndEventsTable = (data) => {
  const [tableData, setTableData] = useState([])
  const tableDataAux = []

  data.data.events.forEach((event) => {
    let auxObj = {
      level: event.entityType,
      bpId: event.batteryPackIndex,
      cgId: event.cellGroupIndex,
      description: event.id + ' - ' + event.message,
      category: event.category,
      status: 'Event',
      timestamp: event.timestamp,
    }

    tableDataAux.push(auxObj)
  })

  data.data.notifications.forEach((notification) => {
    let auxObj = {
      level: notification.source.type,
      bpId: notification.batteryPackIndex,
      cgId: notification.cellGroupIndex,
      description:
        notification.notificationId + ' - ' + notification.notificationName,
      category: notification.notificationCategory,
      status: 'Active',
      timestamp: notification.timestampV1.epochMillis,
    }

    tableDataAux.push(auxObj)
  })

  useEffect(() => {
    setTableData(tableDataAux)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Table
      tableId="NotificationsAndEventsTable"
      data={tableData}
      columns={columns}
      headerPreFilters={filters}
      getRowId={getRowId}
      fixedHeader={false}
      defaultId="timestamp"
      defaultSortDirection="desc"
    />
  )
}

export default NotificationsAndEventsTable
