import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 15px;
  margin-left: 40px;
  height: 600px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FormColumn = styled.div`
  margin-right: 30px;
  width: ${(props) => props.$width};
  margin-top: ${(props) => props.$marginTop ?? ''};
`

export const StationDropItem = styled.div`
  cursor: ${(props) => (props.$isConnected ? 'pointer' : '')};
  padding: 4px;
  color: ${(props) => (props.$isConnected ? 'black' : 'gray')};
  background-color: ${(props) =>
    props.$backgroundColor ? COLORS.light_grey : ''};
`

export const UploadButton = styled.button`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  color: black;
  width: 90px;
  height: 32px;
`

export const FileName = styled.span`
  font-size: 12px;
  margin-left: 15px;
  color: ${(props) => (props.$isFileValid ? '' : COLORS.powin_red)};
`
