import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import { ReactComponent as EksPpcIcon } from 'icons/eks-ppc-icon.svg'
import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/typography'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { EksPpcUtilityList } from './EksPpcUtilityList'

export const EksPpcUtility = () => {
  return (
    <ExpandableCard
      title={
        <TitleWrapper>
          <span>EKS Power Plant Controller</span>
          <TitleLink to="block-topology/eks-ppc-details">
            View Details
          </TitleLink>
        </TitleWrapper>
      }
      icon={
        <div style={{ padding: 8 }}>
          <EksPpcIcon />
        </div>
      }
      isExpanded={true}
      minimal
    >
      <EksPpcUtilityList />
    </ExpandableCard>
  )
}

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`
export const TitleLink = styled(Link)`
  font-size: ${FONT_SIZES.normal};
  color: ${COLORS.action_blue};
  text-transform: none;
`
