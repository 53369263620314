import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.$isSelected ? 'block' : 'none')};
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #30bf91;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? 'transparent' : '#f1f1f1'};
  }

  a {
    display: block;
    color: ${(props) => (props.$isSelected ? '#282929' : '#b3b3b3')};
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }
`
