import { FC } from 'react'

import { BlockContactorFormProps } from './form'
import { FormRow } from 'components/Form/FormComponents.styled'
import {
  SelectWithBottomPadding,
  StringContainer,
} from '../../ContactorsFormUi.styles'
import ContactorState from 'api/types/ContactorState'

export const BlockContactorForm: FC<BlockContactorFormProps> = (props) => {
  const { ids, userInputForm, setUserInputForm } = props

  const handleUserInputFormChange = (
    name: string,
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (e?.target) {
      const update = { ...userInputForm }
      update[name] = e.target.value
      setUserInputForm(update)
    }
  }

  const blockIdsDisplay = ids.join(', ').replace(/:/g, '::')

  return (
    <>
      <StringContainer>
        <label>BLOCKS</label> <span>{blockIdsDisplay}</span>
      </StringContainer>
      <FormRow>
        <label>Contactor State</label>
      </FormRow>
      <FormRow>
        <SelectWithBottomPadding
          value={userInputForm.contactorState}
          onChange={(e) => handleUserInputFormChange('contactorState', e)}
        >
          <option value="" disabled>
            Choose
          </option>
          {Object.keys(ContactorState).map((contactorState) => (
            <option
              key={ContactorState[contactorState]}
              value={ContactorState[contactorState]}
            >
              {contactorState}
            </option>
          ))}
        </SelectWithBottomPadding>
      </FormRow>
    </>
  )
}
