import Icon from 'components/Icon/Icon'
import { CommandResultsTemplate } from '../CommandResultsTemplate'
import { ReactComponent as PreferredCurrentIcon } from 'icons/visualization-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

const preferredCurrentEvaluation = () => {
  // Intentionally left blank
}

const preferredCurrentHeadings = ['CHARGE MANAGER', 'DISCHARGE MANAGER']

const preferredCurrentTd = ({ userInputForm }) => {
  return (
    <>
      <td>
        {userInputForm.chargeManagerEnabled === 'true' ? (
          <Icon
            size="xs"
            color={COLORS.powin_green}
            icon={<PreferredCurrentIcon />}
          />
        ) : (
          <Icon
            size="xs"
            color={COLORS.powin_red}
            icon={<PreferredCurrentIcon />}
          />
        )}
      </td>
      <td>
        {userInputForm.dischargeManagerEnabled === 'true' ? (
          <Icon
            size="xs"
            color={COLORS.powin_green}
            icon={<PreferredCurrentIcon />}
          />
        ) : (
          <Icon
            size="xs"
            color={COLORS.powin_red}
            icon={<PreferredCurrentIcon />}
          />
        )}
      </td>
    </>
  )
}
export const PreferredCurrentResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={preferredCurrentEvaluation}
      resultHeading={preferredCurrentHeadings}
      ResultsTd={preferredCurrentTd}
      pendingQueryName={'preferredCurrentDetails'}
    />
  )
}
