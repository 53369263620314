import Table from 'components/Table/Table/Table'
import { getHvacStatus, checkNullRoundValue } from '../tableUtils'

const CollectionSegmentDetailHVACTable = ({ data }) => {
  const getRowId = (row) => row.status
  const columns = [
    {
      name: 'STATUS',
      id: 'status',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => getHvacStatus(row.isHealthy),
    },
    {
      name: 'HUMIDITY',
      id: 'humidity',
      selector: (row) => row.humidity,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => checkNullRoundValue(row.humidity, '%'),
    },
    {
      name: 'AIR TEMP',
      id: 'airTemp',
      selector: (row) => row.airTemp,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => checkNullRoundValue(row.airTemp, '° C'),
    },
    {
      name: 'COOL TO',
      id: 'coolTo',
      selector: (row) => row.coolTo,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => checkNullRoundValue(row.coolTo, '° C'),
    },
    {
      name: 'HEAT TO',
      id: 'heatTo',
      selector: (row) => row.heatTo,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => checkNullRoundValue(row.heatTo, '° C'),
    },
    {
      name: 'RESPONDING TO',
      id: 'respondingTo',
      selector: (row) => row.respondingTo,
      sortable: true,
      width: '120px',
      noOmit: true,
    },
    {
      name: 'HVAC SIGNALS',
      id: 'hvacSignals',
      selector: (row) => (row.hvacSignals === '' ? 'N/A' : row.hvacSignals),
      sortable: true,
      width: '120px',
      noOmit: true,
    },
    {
      name: 'HVAC STAGE',
      id: 'hvacStage',
      selector: (row) => row.hvacStage,
      sortable: true,
      width: '300px',
      noOmit: true,
    },
  ]

  return (
    <Table
      tableId="CollectionSegmentDetailTable"
      data={[data]}
      columns={columns}
      getRowId={getRowId}
      fixedHeader={false}
      pagination={false}
      controls={false}
    />
  )
}

export default CollectionSegmentDetailHVACTable
