import * as Styles from './styles'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import Icon from 'components/Icon/Icon'

import { ReactComponent as CollectionSegmentsIcon } from 'icons/centipede-collection-segment.svg'
import { ReactComponent as CentipedeEnergySegmentsIcon } from 'icons/centipede-energy-segment.svg'

import CentipedeLineupDetailsCollectionSegmentTable from 'modules/Station/components/Tables/CentipedeLineupDetailsTable/CentipedeLineupDetailsCollectionSegmentTable'
import CentipedeLineupDetailsEnergySegmentsTable from 'modules/Station/components/Tables/CentipedeLineupDetailsTable/CentipedeLineupDetailsEnergySegmentsTable'

const CustomTitle = (icon, title) => {
  return (
    <Styles.TitleContainer>
      <Icon icon={icon} size="sm" />
      <Styles.Title>{title}</Styles.Title>
    </Styles.TitleContainer>
  )
}

const CentipedeLineupDetailsMainColumn = () => {
  return (
    <div>
      <ExpandableCard
        title={CustomTitle(<CollectionSegmentsIcon />, 'COLLECTION SEGMENT')}
        isExpanded={true}
        minimal={true}
      >
        <CentipedeLineupDetailsCollectionSegmentTable />
      </ExpandableCard>

      <Styles.CardSpacer />

      <ExpandableCard
        title={CustomTitle(<CentipedeEnergySegmentsIcon />, 'ENERGY SEGMENTS')}
        isExpanded={true}
        minimal={true}
      >
        <CentipedeLineupDetailsEnergySegmentsTable />
      </ExpandableCard>
    </div>
  )
}

export default CentipedeLineupDetailsMainColumn
