// To keep the Plugin list ordered, please follow format for ids:
// `PlugIn{sequentialNumber}{PluginInitials}'

export const PLUGIN_LIST = {
  BALANCER_MANAGER: {
    id: 'PlugIn01BM',
    key: 'sitewidebalancer',
    name: 'Balancer Manager',
  },

  AUTO_BALANCER: {
    id: 'PlugIn02AB',
    key: 'autobalancer',
    name: 'Auto Balancer',
  },

  AUTO_CONTACTORMANAGEMENT: {
    id: 'PlugIn03AC',
    key: 'autocontactormanagement',
    name: 'Auto Contactor Management',
  },
}

export const getPluginMap = () => {
  const pluginMap = {}
  for (const pluginKey in PLUGIN_LIST) {
    const plugin = PLUGIN_LIST[pluginKey]
    pluginMap[plugin.id] = plugin
  }
  return pluginMap
}
