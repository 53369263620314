// import React from 'react'
import { Tooltip as BaseTooltip, TooltipProps } from 'antd'
import { ZINDEX } from 'design_system/z-index'
import { forwardRef } from 'react'

export const Tooltip = forwardRef((props: TooltipProps, ref) => {
  return (
    <BaseTooltip
      ref={ref}
      zIndex={ZINDEX.tooltip}
      className="appTooltip"
      {...props}
    />
  )
})

export default Tooltip
