import axios from 'axios'
import { SessionContext } from 'contexts/session'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const useDcDcPcsDetailGraphQuery = (period) => {
  const {
    stationCode,
    blockIndex,
    acPvBatteryIndex,
    dcDcGroupIndex,
    dcDcPcsIndex,
  } = useParams()
  const { session } = useContext(SessionContext)

  const serviceCall = async () => {
    const apiUrl = session.server
    const axiosOptions = {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    }
    const response = await axios.get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/acpvbatteries/${acPvBatteryIndex}/dcdcgroups/${dcDcGroupIndex}/dcdcpcses/${dcDcPcsIndex}/graph?period=${period}`,
      axiosOptions,
    )
    return response.data
  }

  return useQuery(
    [
      'DcDcPcsDetailGraph',
      stationCode,
      blockIndex,
      acPvBatteryIndex,
      dcDcGroupIndex,
      dcDcPcsIndex,
    ],
    serviceCall,
    { refetchInterval: 5000 },
  )
}

export default useDcDcPcsDetailGraphQuery
