// import { InverterRotationForm } from './InverterRotationForm'
import CommandModalTemplate from '../CommandModalTemplate'
import { DcDcGroupRotationResults } from './DcDcGroupRotationResults'
import {
  RotationForm,
  defaultRotationFormValues,
} from '../SetRotation/RotationForm'
import { ResourceEnum } from 'api/dictionaries/rotation-excuses'

const modalTitles = {
  start: 'Set DC:DC Group Rotation',
  finish: 'Set DC:DC Group Rotation',
}
const queryName = 'setDcDcGroupRotation'
const label = 'DC:DC Groups'
const resourceType = ResourceEnum.inverter

export const idsToString = (ids) =>
  ids.map((group) => {
    return `${group.acPvBatteryIndex}:${group.dcDcGroupIndex}`
  })

const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) => {
  return RotationForm({
    ids: idsToString(ids),
    onUserInputFormChange,
    userInputForm,
    resourceType,
    label,
  })
}

const commandResultJsx = ({ ids, userInputForm }) => (
  <DcDcGroupRotationResults ids={ids} userInputForm={userInputForm} />
)

/**
 *
 * ids - Array of { acPvBatteryIndex: number, dcDcGroupIndex: number } or { acPvBatteryIndex: number, pvPcsIndex: number }
 */
export const SetDcDcGroupRotationModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated
    />
  )
}
