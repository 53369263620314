import * as Styles from './styles'

const SearchInput = ({ onChange, placeholder, value }) => {
  return (
    <Styles.Search
      type="search"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default SearchInput
