import { CommandResultsTemplate } from '../../CommandResultsTemplate'
import { BALANCE_STATUSES } from '../BALANCING_STATUSES'
import React from 'react'

export const StringBalancingResults = ({ ids, userInputForm }) => {
  const balancingHeadings = ['BALANCING', 'CHARGE DB', 'DISCHARGE DB']

  const balancingTd = ({ userInputForm }) => (
    <React.Fragment>
      <td>{userInputForm.balancingCmd}</td>
      {userInputForm.balancingCmd != BALANCE_STATUSES.NO_BALANCE ? (
        <React.Fragment>
          <td>{userInputForm.chargeDeadband} mV</td>
          <td>{userInputForm.dischargeDeadband} mV</td>
        </React.Fragment>
      ) : null}
      {userInputForm.balancingCmd == BALANCE_STATUSES.BALANCE_TO_PROVIDED ? (
        <td key={'millivolts'}>{userInputForm.providedMillivolts} mV</td>
      ) : null}
    </React.Fragment>
  )

  const pendingQueryName = 'stringBalancingDetails'
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      resultHeading={balancingHeadings}
      ResultsTd={balancingTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
