import { fetchStringList } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * Will use params to load correct station and block status
 * @returns 5 second interval block status
 */
const useStringStatusQuery = (enabled = true) => {
  const { stationCode, blockIndex } = useParams()
  const { session, setSession } = useContext(SessionContext)

  return useQuery(
    ['Strings', stationCode, blockIndex],
    () => fetchStringList(stationCode, blockIndex, session, setSession),
    { refetchInterval: 5000, enabled },
  )
}

export default useStringStatusQuery
