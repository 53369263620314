// Style Imports
import * as Styles from './ActionsButton.styles'

// Component Imports
import Icon from '../Icon/Icon'
import React from 'react'
// Icon Imports
import { ReactComponent as EllipsisIcon } from '../../icons/ellipsis-icon.svg'

const ActionsButton = React.forwardRef(({ isActive, handleClick }, ref) => {
  const iconColor = '#40a3d5'
  const activeIconColor = '#7d7d7d'

  return (
    <Styles.EllipsesButton $isOpen={isActive} onClick={handleClick} ref={ref}>
      <Icon
        color={isActive ? activeIconColor : iconColor}
        size="sm"
        icon={<EllipsisIcon />}
      />
    </Styles.EllipsesButton>
  )
})
export default ActionsButton
