// protobuff values
export const BALANCE_STATUSES = {
  NO_BALANCE: 'Stop Balancing',
  BALANCE_TO_PROVIDED: 'Balance to Millivolts',
  BALANCE_TO_AVERAGE: 'Average Balancing',

  BALANCE_TO_HIGHEST: 'BALANCE_TO_HIGHEST',
  BALANCE_TO_LOWEST: 'BALANCE_TO_LOWEST',
  FORCE_CHARGE_BALANCE: 'FORCE_CHARGE_BALANCE',
  FORCE_DISCHARGE_BALANCE: 'FORCE_DISCHARGE_BALANCE',
  UNRECOGNIZED: 'UNRECOGNIZED',
}

/**
 * use this to convert balance form values to protobuff constant equivalents
 * used in backend verification for example
 * @param {string} balanceString
 * @returns {string}
 */
export const BALANCE_STATUSES_FROM_STRING = (balanceString) => {
  switch (balanceString) {
    case 'Stop Balancing':
      return 'NO_BALANCE'
    case 'Balance to Millivolts':
      return 'BALANCE_TO_PROVIDED'
    case 'Average Balancing':
      return 'BALANCE_TO_AVERAGE'
    // not currently used
    case 'BALANCE_TO_HIGHEST':
      return 'BALANCE_TO_HIGHEST'
    case 'BALANCE_TO_LOWEST':
      return 'BALANCE_TO_LOWEST'
    case 'FORCE_CHARGE_BALANCE':
      return 'FORCE_CHARGE_BALANCE'
    case 'FORCE_DISCHARGE_BALANCE':
      return 'FORCE_DISCHARGE_BALANCE'
    default:
      return 'UNRECOGNIZED'
  }
}
