import styled from 'styled-components/macro'

export const Container = styled.div`
  visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
  transition: visibility 0.25s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
export const Background = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
`
export const Drawer = styled.div`
  position: absolute;
  z-index: 101;
  left: 0;
  right: 0;
  bottom: ${(props) => (props.$isOpen ? 0 : '-600px')};
  background-color: #fff;
  height: 600px;
  max-height: 70%;
  transition: bottom 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
`
export const TitleRow = styled.div`
  display: flex;
  padding: 25px 100px;
  border-bottom: 1px solid #ccd5dd;
`
export const Title = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 15px;
`
export const CloseButton = styled.div`
  cursor: pointer;
  fill: #ccd5dd;
  width: 15px;
  height: 15px;
`
export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 25px 100px;
`
