import Table from 'components/Table/Table/Table'
import { COLORS } from 'design_system/colors'
import { FONT_WEIGHT } from 'design_system/typography'
import styled from 'styled-components/macro'

const getRowId = (row) => row.name

const columns = [
  {
    id: 'name',
    name: 'Name',
    selector: (row) => row.name,
    cell: (row) => <EmphasizedCell>{row.name}</EmphasizedCell>,
    sortable: true,
  },
  {
    id: 'status',
    name: 'Status',
    selector: (row) => row.status,
    cell: (row) => <EmphasizedCell>{row.status}</EmphasizedCell>,
  },
  {
    id: 'operation',
    name: 'Operation',
    selector: (row) => row.operation,
    cell: (row) => <DeEmphasizedCell>{row.operation}</DeEmphasizedCell>,
  },
]

export function StatusAndOperationTable({ tableId, data }) {
  return (
    <Table
      tableId={tableId}
      data={data}
      getRowId={getRowId}
      defaultId="status"
      fixedHeader={false}
      columns={columns}
      pagination={false}
      controls={false}
    />
  )
}

const EmphasizedCell = styled.div`
  font-weight: ${FONT_WEIGHT.strong};
`
const DeEmphasizedCell = styled(EmphasizedCell)`
  color: ${COLORS.font_secondary};
`
