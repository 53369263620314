import AppTooltip from '../../AppTooltip/AppTooltip'
import * as Styles from './styles'

const BinaryBarChart = ({ positiveCount, negativeCount, tooltip = '', id }) => {
  const totalCount = positiveCount + negativeCount

  return (
    <Styles.Container data-tooltip-id={id + 'pcsTooltip'}>
      <Styles.GreenBar $percent={positiveCount / totalCount} />
      {tooltip && <AppTooltip id={id + 'pcsTooltip'}>{tooltip}</AppTooltip>}
    </Styles.Container>
  )
}

export default BinaryBarChart
