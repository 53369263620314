import { COLORS } from 'design_system/colors'
import { FONT_SIZES, FONT_WEIGHT } from 'design_system/typography'
import styled from 'styled-components/macro'

export const Option = styled.div`
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  gap: 0.75rem;
  font-weight: ${FONT_WEIGHT.strong};

  & input {
    width: unset;
    height: unset;
  }
  & label {
    color: black;
    text-transform: unset;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1.5rem;
  }
  width: 300px;
  resize: both;

  .ant-picker-dropdown {
    position: relative;
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const SectionTitle = styled.p`
  color: ${COLORS.font_secondary};
  margin: 0;
`

export const Footer = styled.div`
  display: flex;
  gap: 1rem;
`

export const Button = styled.button`
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: ${FONT_SIZES.large};
  font-weight: ${FONT_WEIGHT.strong};
  background: #30bf91;
  text-transform: uppercase;
  &:disabled {
    background-color: #b9b9b9;
    cursor: not-allowed;
  }
`

export const SecondaryButton = styled(Button)`
  background: #b9b9b9;
`

export const FormSubmitButton = styled(Button)`
  padding: 6px 20px;
  background-color: ${(props) => (props.isValid ? '#30BF91' : '#b9b9b9')};
  color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  width: ${(props) => (props.width ? props.width : '130px')};
  text-transform: uppercase;
`
