import { StationDetailsPageLayout } from '../../../../../components/Layout'

const AnalysisPage = ({
  summaryColumnComponent,
  toolbarComponent,
  mainColumnComponent,
}) => {
  return (
    <StationDetailsPageLayout>
      <StationDetailsPageLayout.LeftColumn>
        {summaryColumnComponent}
      </StationDetailsPageLayout.LeftColumn>
      <StationDetailsPageLayout.RightColumn>
        {toolbarComponent}
        <StationDetailsPageLayout.RightColumnContent>
          {mainColumnComponent}
        </StationDetailsPageLayout.RightColumnContent>
      </StationDetailsPageLayout.RightColumn>
    </StationDetailsPageLayout>
  )
}

export default AnalysisPage
