import CommandModalTemplate from '../CommandModalTemplate'
import { ContactorsFormUi } from './ContactorsFormUi'
import { ContactorsFormResults } from './ContactorsFormResults'
import { useParams } from 'react-router-dom'
import { useSiteControllerVersionContext } from 'contexts/siteControllerVersionContext'
import ContactorState from 'api/types/ContactorState'
import EnablementState from 'api/types/EnablementState'

const modalTitles = {
  start: 'Set Contactors',
  finish: 'Contactor State',
}

const userInputFormJsx = ({ ids, userInputForm, setUserInputForm }) =>
  ContactorsFormUi({ ids, userInputForm, setUserInputForm })

const commandResultJsx = ({ ids, userInputForm }) => (
  <ContactorsFormResults ids={ids} rotationForm={userInputForm} />
)

export const SetContactorsModal = ({ setShowCommandModal, ids }) => {
  const { stationCode } = useParams()
  const { isOpModel2 } = useSiteControllerVersionContext()
  const defaultFormValues = {
    contactorState: ContactorState.OPEN,
    ignoreHighCellGroupVoltageAlarm: EnablementState.DISABLED,
    ignoreLowCellGroupVoltageAlarm: EnablementState.DISABLED,
  }
  const queryName = isOpModel2(stationCode)
    ? 'setStringContactors2'
    : 'setStringContactors'
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
    />
  )
}
