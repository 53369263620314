import { ResourceEnum } from 'api/dictionaries/rotation-excuses'
import { FC } from 'react'
import { RotationForm } from '../../SetRotation/RotationForm'
import { BlockInverterRotationFormProps } from './form'

export const BlockInverterRotationForm: FC<BlockInverterRotationFormProps> = (
  props,
) => {
  const { ids, userInputForm, onUserInputFormChange } = props
  const label = 'Blocks'
  return (
    <RotationForm
      ids={ids}
      userInputForm={userInputForm}
      onUserInputFormChange={onUserInputFormChange}
      resourceType={ResourceEnum.inverter}
      label={label}
    />
  )
}
