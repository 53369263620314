import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import UPSListTable from 'modules/Station/components/Tables/UPSListTable/UPSListTable'
import useUPSListQuery from 'api/useQueryHooks/useUPSlistQuery'
import UPSListSummary from './Summary/UpsListSummary'

const UPSList = ({ selectedParams, setSelectedParams, setSelectedPage }) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'ups-list' })
    document.title = `${stationCode}::${blockIndex} - UPSes`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<UPSListSummary data={data} />}
      tableComponent={<UPSListTable data={data} fixedHeader={true} />}
      className="upses"
    />
  )

  return handleQueryLoadingAndError(useUPSListQuery(), ListPageJsx)
}

export default UPSList
