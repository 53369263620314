import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'

/**
 * no tooltip and used in list page summary left column
 * @param {boolean} param0
 * @returns  Rotation Icon
 */

const Container = styled.div`
  fill: ${(props) =>
    props.$outRotation ? COLORS.powin_red : COLORS.powin_green};
`

const RotationStatusIcon = ({ outRotation }) => {
  return (
    <Container $outRotation={outRotation}>
      {outRotation ? <ErrorIcon /> : <CheckIcon />}
    </Container>
  )
}

export default RotationStatusIcon
