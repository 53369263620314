import { useMemo } from 'react'
import * as Styles from './styles'

// Icon Imports
import { ReactComponent as InterconnectIcon } from 'icons/interconnect-icon.svg'
import { ReactComponent as AcBatteryIcon } from 'icons/ac-battery-icon.svg'
import { ReactComponent as ContainerIcon } from 'icons/enclosure-icon.svg'
import { ReactComponent as DcAcPCSIcon } from 'icons/dc-ac-pcs-icon.svg'
import { ReactComponent as ArrayIcon } from 'icons/array-icon.svg'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import { ReactComponent as ACPVBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import { ReactComponent as DcGroupIcon } from 'icons/dc-group-icon.svg'
import { ReactComponent as SensorIcon } from 'icons/sensor-icon.svg'
import { ReactComponent as HvacIcon } from 'icons/hvac-icon.svg'
import { ReactComponent as StackManagerIcon } from 'icons/string-manager.svg'
import { ReactComponent as CollectionSegmentsIcon } from 'icons/centipede-collection-segment.svg'
import { ReactComponent as CentipedeEnergySegmentsIcon } from 'icons/centipede-energy-segment.svg'
import { ReactComponent as EnvironmentalControllersIcon } from 'icons/env-controller.svg'
import { ReactComponent as UPSIcon } from 'icons/ups-icon.svg'
import { ReactComponent as CentipedeLineupIcon } from 'icons/centipede-lineup.svg'
import { ReactComponent as EksPpcIcon } from 'icons/eks-ppc-icon.svg'
import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'

/**
 * crumb type
 * @param {JSX Element} icon ex. <AnIconHere />
 * @param {string} to ex. `/block-details/${selectedParams.stationCode}/${selectedParams.blockIndex}/block-topology` link
 * @param {string} label ex 'System Details'
 * @returns Crumb
 */
const Crumb = (icon, to, label) => (
  <Styles.Row key={label}>
    <Styles.Icon>{icon}</Styles.Icon>
    <Styles.Breadcrumb to={to}>{label}</Styles.Breadcrumb>
  </Styles.Row>
)

const toLinkRoot = (selectedParams) =>
  `/block-details/${selectedParams.stationCode}/${selectedParams.blockIndex}/block-topology`

/**
 * Step one in your custom listPage component useEffect -> ex:  setSelectedParams({ ...params, listView: 'dc-dc-pcs-details' })
 * set listView which is used for switch and map.  make sure useEffect [] no deps
 * Step two when adding a new crumb, add it here to the map
 * Step three create a new switch test with 1 or more appropriate crumbs
 * @param {object} selectedParams
 * @returns crumbMap
 */
const generateCrumbMap = (selectedParams) => {
  const root = toLinkRoot(selectedParams)
  const crumbMap = new Map()
  crumbMap.set(
    'system-details',
    Crumb(<InterconnectIcon />, `${root}`, 'System Details'),
  )

  crumbMap.set(
    'array-details',
    Crumb(
      <ArrayIcon />,
      `${root}/acBattery/${selectedParams.acBatteryId}/array/${selectedParams.arrayId}`,
      `Array ${selectedParams.arrayId}`,
    ),
  )

  crumbMap.set(
    'string-list',
    Crumb(<StringIcon />, `${root}/string-list`, 'All Strings'),
  )

  crumbMap.set(
    'string-details',
    Crumb(
      <StringIcon />,
      `${root}/acBattery/${selectedParams.acBatteryId}/array/${selectedParams.arrayId}/string/${selectedParams.stringID}`,
      `String ${selectedParams.stringID}`,
    ),
  )

  crumbMap.set(
    'centipede-lineups',
    Crumb(
      <AcBatteryIcon />,
      `${root}/centipede-lineups`,
      'All Centipede Lineups',
    ),
  )

  crumbMap.set(
    'centipede-lineup-details',
    Crumb(
      <CentipedeLineupIcon />,
      `${root}/centipede-lineup-details`,
      `Centipede Lineup ${selectedParams.centipedeId}`,
    ),
  )

  crumbMap.set(
    'collection-segments',
    Crumb(
      <CollectionSegmentsIcon />,
      `${root}/collection-segments`,
      'All Collection Segments',
    ),
  )

  crumbMap.set(
    'collection-segment-detail',
    Crumb(
      <CollectionSegmentsIcon />,
      `${root}/collection-segment/${selectedParams.collectionSegmentId}`,
      `Collection Segment ${selectedParams.collectionSegmentId}`,
    ),
  )

  crumbMap.set(
    'centipede-energy-segments',
    Crumb(
      <CentipedeEnergySegmentsIcon />,
      `${root}/centipede-energy-segments`,
      'All Energy Segments',
    ),
  )

  crumbMap.set(
    'centipede-energy-segment-details',
    Crumb(
      <CentipedeEnergySegmentsIcon />,
      `${root}/centipede-energy-segment/${selectedParams.energySegmentId}`,
      `Energy Segment ${selectedParams.energySegmentId}`,
    ),
  )

  crumbMap.set(
    'environmental-controllers',
    Crumb(
      <EnvironmentalControllersIcon />,
      `${root}/environmental-controllers`,
      'All Environmental Controllers',
    ),
  )

  crumbMap.set('ups-list', Crumb(<UPSIcon />, `${root}/ups-list`, 'All UPSes'))

  crumbMap.set(
    'sensors',
    Crumb(<SensorIcon />, `${root}/sensors`, 'All Sensors'),
  )

  crumbMap.set(
    'hvac-list',
    Crumb(<HvacIcon />, `${root}/hvac-list`, 'All HVACs'),
  )

  crumbMap.set(
    'stack-managers-list',
    Crumb(
      <StackManagerIcon />,
      `${root}/stack-managers-list`,
      'All Stack Managers',
    ),
  )

  crumbMap.set(
    'array-list',
    Crumb(<AcBatteryIcon />, `${root}/array-list`, 'All Arrays'),
  )

  crumbMap.set(
    'array-pcs-list',
    Crumb(<DcAcPCSIcon />, `${root}/array-pcs-list`, 'All PCSes'),
  )

  crumbMap.set(
    'pcs-details',
    Crumb(
      <DcAcPCSIcon />,
      `${root}/acBattery/${selectedParams.acBatteryId}/pcs/${selectedParams.pcsIndex}`,
      `PCS ${selectedParams.acBatteryId} :: ${selectedParams.pcsIndex}`,
    ),
  )

  crumbMap.set(
    'enclosure-list',
    Crumb(<ContainerIcon />, `${root}/enclosure-list`, 'All Enclosures'),
  )

  crumbMap.set(
    'enclosure-details',
    Crumb(
      <ContainerIcon />,
      `${root}/container/${selectedParams.containerId}`,
      `Enclosure ${selectedParams.containerId}`,
    ),
  )

  crumbMap.set(
    'ac-battery-list',
    Crumb(<AcBatteryIcon />, `${root}/ac-battery-list`, 'All AC Batteries'),
  )

  crumbMap.set(
    'ac-battery-details',
    Crumb(
      <AcBatteryIcon />,
      `${root}/acBattery/${selectedParams.acBatteryId}`,
      `AC Battery ${selectedParams.acBatteryId}`,
    ),
  )

  crumbMap.set(
    'dc-dc-groups-list',
    Crumb(<DcAcPCSIcon />, `${root}/dc-dc-groups-list`, 'All DC:DC Groups'),
  )

  crumbMap.set(
    'dc-dc-group-detail',
    Crumb(
      <DcGroupIcon />,
      `${root}/ac-pv-battery/${selectedParams.acPvBatteryIndex}/dc-dc-group/${selectedParams.dcDcGroupIndex}`,
      `DC:DC Group ${selectedParams.dcDcGroupIndex}`,
    ),
  )

  crumbMap.set(
    'dc-dc-pcs-list',
    Crumb(<DcAcPCSIcon />, `${root}/dc-dc-pcs-list`, 'All DC:DC Pcses'),
  )

  crumbMap.set(
    'dc-dc-pcs-details',
    Crumb(
      <DcAcPCSIcon />,
      `${root}/ac-pv-battery/${selectedParams.acPvBatteryIndex}/dc-dc-group/${selectedParams.dcDcGroupIndex}/dc-dc-pcs/${selectedParams.dcDcPcsIndex}`,
      `DC:DC PCS ${selectedParams.acPvBatteryIndex}`,
    ),
  )

  crumbMap.set(
    'pv-pcs-list',
    Crumb(<DcAcPCSIcon />, `${root}/array-pcs-list`, 'All PV PCSes'),
  )

  crumbMap.set(
    'pv-pcs-detail',
    Crumb(
      <DcAcPCSIcon />,
      `${root}/ac-pv-battery/${selectedParams.acPvBatteryIndex}/pv-pcs/${selectedParams.pvPcsIndex}`,
      `PV PCS ${selectedParams.acPvBatteryIndex}`,
    ),
  )

  crumbMap.set(
    'ac-pv-batteries-list',
    Crumb(
      <ACPVBatteryIcon />,
      `${root}/ac-pv-batteries-list`,
      'All AC PV Batteries',
    ),
  )

  crumbMap.set(
    'ac-pv-battery-detail',
    Crumb(
      <ACPVBatteryIcon />,
      `${root}/acPvBattery/${selectedParams.acPvBatteryIndex}`,
      `AC PV Battery ${selectedParams.acPvBatteryIndex}`,
    ),
  )

  crumbMap.set(
    'eks-ppc-details',
    Crumb(
      <EksPpcIcon />,
      `${root}/eks-ppc-details`,
      `EKS Power Plant Controller`,
    ),
  )

  crumbMap.set(
    'block-meter-list',
    Crumb(<BlockMeterIcon />, `${root}/block-meter-list`, `All Block Meters`),
  )

  crumbMap.set(
    'block-meter-details',
    Crumb(
      <BlockMeterIcon />,
      `${root}/block-meter-details`,
      `Block Meter ${selectedParams.blockMeterIndex}`,
    ),
  )

  crumbMap.set(
    'eks-pcs-group-list',
    Crumb(<DcAcPCSIcon />, `${root}/eks-pcs-group-list`, `All PCS Groups`),
  )

  crumbMap.set(
    'eks-pcs-group-details',
    Crumb(
      <DcAcPCSIcon />,
      `${root}/eks-pcs-group-details`,
      `PCS Group ${selectedParams.eksPcsGroupId}`,
    ),
  )

  return crumbMap
}

const crumbBuilder = (selectedParams) => {
  // initialize first and always present station-details crumb
  const crumbMap = generateCrumbMap(selectedParams)

  const crumbArray = [crumbMap.get('system-details')]

  switch (selectedParams.listView) {
    case 'ac-pv-batteries-list':
      crumbArray.push(crumbMap.get('ac-pv-batteries-list'))
      break
    case 'ac-pv-battery-detail':
      crumbArray.push(
        crumbMap.get('ac-pv-batteries-list'),
        crumbMap.get('ac-pv-battery-detail'),
      )
      break
    case 'dc-dc-group-detail':
      crumbArray.push(
        crumbMap.get('ac-pv-battery-detail'),
        crumbMap.get('dc-dc-group-detail'),
      )
      break
    case 'dc-dc-groups-list':
      crumbArray.push(crumbMap.get('dc-dc-groups-list'))
      break
    case 'ac-battery-list':
      crumbArray.push(crumbMap.get('ac-battery-list'))
      break
    case 'centipede-lineups':
      crumbArray.push(crumbMap.get('centipede-lineups'))
      break
    case 'centipede-lineup-details':
      crumbArray.push(crumbMap.get('centipede-lineup-details'))
      break
    case 'collection-segments':
      crumbArray.push(crumbMap.get('collection-segments'))
      break
    case 'collection-segment-detail':
      crumbArray.push(crumbMap.get('collection-segments'))
      crumbArray.push(crumbMap.get('collection-segment-detail'))
      break
    case 'centipede-energy-segments':
      crumbArray.push(crumbMap.get('centipede-energy-segments'))
      break
    case 'centipede-energy-segment-details':
      crumbArray.push(crumbMap.get('centipede-energy-segments'))
      crumbArray.push(crumbMap.get('centipede-energy-segment-details'))
      break
    case 'environmental-controllers':
      crumbArray.push(crumbMap.get('environmental-controllers'))
      break
    case 'ups-list':
      crumbArray.push(crumbMap.get('ups-list'))
      break
    case 'sensors':
      crumbArray.push(crumbMap.get('sensors'))
      break
    case 'hvac-list':
      crumbArray.push(crumbMap.get('hvac-list'))
      break
    case 'stack-managers-list':
      crumbArray.push(crumbMap.get('stack-managers-list'))
      break
    case 'array-list':
      crumbArray.push(crumbMap.get('array-list'))
      break
    case 'array-details':
      crumbArray.push(
        crumbMap.get('ac-battery-details'),
        crumbMap.get('array-details'),
      )
      break
    case 'array-details-acpv':
      crumbArray.push(
        crumbMap.get('ac-pv-battery-detail'),
        crumbMap.get('array-details'),
      )
      break
    case 'array-pcs-list':
      crumbArray.push(crumbMap.get('array-pcs-list'))
      break
    case 'pv-pcs-list':
      crumbArray.push(crumbMap.get('pv-pcs-list'))
      break
    case 'dc-dc-pcs-list':
      crumbArray.push(crumbMap.get('dc-dc-pcs-list'))
      break
    case 'dc-dc-pcs-details':
      crumbArray.push(
        crumbMap.get('ac-pv-battery-detail'),
        crumbMap.get('dc-dc-group-detail'),
        crumbMap.get('dc-dc-pcs-details'),
      )
      break
    case 'string-list':
      crumbArray.push(crumbMap.get('string-list'))
      break
    case 'string-details':
      if (selectedParams.acPvBatteryIndex) {
        crumbArray.push(crumbMap.get('ac-pv-battery-detail'))
      } else {
        crumbArray.push(crumbMap.get('ac-battery-details'))
      }
      crumbArray.push(
        crumbMap.get('array-details'),
        crumbMap.get('string-details'),
      )
      break
    case 'enclosure-list':
      crumbArray.push(crumbMap.get('enclosure-list'))
      break
    case 'enclosure-details':
      crumbArray.push(
        crumbMap.get('enclosure-list'),
        crumbMap.get('enclosure-details'),
      )
      break
    case 'ac-battery-details':
      crumbArray.push(
        crumbMap.get('ac-battery-list'),
        crumbMap.get('ac-battery-details'),
      )
      break
    case 'pcs-details':
      crumbArray.push(
        crumbMap.get('ac-battery-details'),
        crumbMap.get('pcs-details'),
      )
      break
    case 'pv-pcs-detail':
      crumbArray.push(
        crumbMap.get('ac-pv-battery-detail'),
        crumbMap.get('pv-pcs-detail'),
      )
      break
    case 'eks-ppc-details':
      crumbArray.push(crumbMap.get('eks-ppc-details'))
      break
    case 'block-meter-list':
      crumbArray.push(crumbMap.get('block-meter-list'))
      break
    case 'block-meter-details':
      crumbArray.push(crumbMap.get('block-meter-list'))
      crumbArray.push(crumbMap.get('block-meter-details'))
      break
    case 'eks-pcs-group-list':
      crumbArray.push(crumbMap.get('eks-pcs-group-list'))
      break
    case 'eks-pcs-group-details':
      crumbArray.push(crumbMap.get('eks-pcs-group-list'))
      crumbArray.push(crumbMap.get('eks-pcs-group-details'))
      break
    default:
      break
  }
  return crumbArray
}

/**
 * @param {} selectedParams from the url
 * @returns JSX breadcrumb component
 */
const Breadcrumbs = ({ selectedParams }) => {
  const Crumbs = useMemo(() => crumbBuilder(selectedParams), [selectedParams])
  return <Styles.Container>{Crumbs}</Styles.Container>
}

export default Breadcrumbs
