import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import axios from 'axios'

const useAdminCommandAuditLog = (date, pageNumber, pageSize) => {
  const { session } = useContext(SessionContext)

  return useQuery(
    ['AdminCommandAuditLog', date, pageNumber, pageSize],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
        params: {
          date: date,
          page: pageNumber,
          size: pageSize,
        },
      }
      const response = await axios.get(`${apiUrl}auditlogs`, axiosOptions)
      return response.data
    },
    {
      enabled: Boolean(
        date && typeof pageNumber === 'number' && typeof pageSize === 'number',
      ),
      refetchOnWindowFocus: false,
    },
  )
}

export default useAdminCommandAuditLog
