import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as EnergySegmentsIcon } from 'icons/centipede-energy-segment.svg'
import useOutdoorEnvironmentQuery from 'api/useQueryHooks/useOutdoorEnvironmentQuery'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

const CentipedeEnergySegmentDetailsSummary = ({ data, id, isHealthy }) => {
  const { data: envData, isFetched: envIsFetched } =
    useOutdoorEnvironmentQuery()

  let airTemp = 'Loading'
  let humidity = 'Loading'
  if (envIsFetched) {
    airTemp = checkNullRoundValue(envData.externalAirTemp, '° C')
    humidity = checkNullRoundValue(envData.externalHumidity, '%')
  }

  const summaryMap = {
    title: `Energy Segment ${id}`,
    titleIcon: <EnergySegmentsIcon />,
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: isHealthy ? (
              <StatusIcon $isHealthy={isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'Ambient Air Temp',
            value: airTemp.toLocaleString(),
          },
          {
            label: 'Ambient Humidity',
            value: humidity.toLocaleString(),
          },
        ],
      },
      {
        sectionTitle: 'AVAILABILITY',
        rows: [
          {
            label: 'Online Energy',
            value: checkNullRoundValue(data.onlineEnergykWh, ' kWh'),
          },
          {
            label: 'Energy Capacity',
            value: checkNullRoundValue(data.energyCapacitykWh, ' kWh'),
          },
          {
            label: 'DC Charge Power',
            value: checkNullRoundValue(data.dcchargePowerkW, ' kW'),
          },
          {
            label: 'DC Discharge Power',
            value: checkNullRoundValue(data.dcdischargePowerkW, ' kW'),
          },
          {
            label: 'Online SoC',
            value: checkNullRoundValue(data.soc, '%'),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default CentipedeEnergySegmentDetailsSummary
