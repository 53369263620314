// Style Imports
import * as Styles from './columnToggles.styledComponents'

const ColumnToggles = ({ columns, handleToggle }) => {
  // console.log('columns', columns)
  const renderCheckboxes = columns.map((c) => {
    // console.log('cat', c)
    return (
      <Styles.Row key={c.id}>
        <input
          type="checkbox"
          id={c.id}
          checked={!c.omit}
          onChange={() => handleToggle(c.id)}
        />
        <Styles.Label htmlFor={c.id} checked={!c.omit}>
          {c.name}
        </Styles.Label>
      </Styles.Row>
    )
  })
  return <div>{renderCheckboxes}</div>
}

export default ColumnToggles
