import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import DcDcGroupsSummary from './Summary/DcDcGroupsSummary'
import DcDcGroupsTable from 'modules/Station/components/Tables/DcDcGroupsTable/DcDcGroupsTable'
import useDcDcGroupsQuery from 'api/useQueryHooks/useDcDcGroupsQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'

const DcDcGroupsList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'dc-dc-groups-list' })
    document.title = `${stationCode}::${blockIndex} - DC:DC Groups`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const listPage = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<DcDcGroupsSummary data={data.dcDcGroups} />}
      tableComponent={
        <DcDcGroupsTable data={data.dcDcGroups} fixedHeader={true} />
      }
    />
  )

  return handleQueryLoadingAndError(useDcDcGroupsQuery(), listPage)
}

export default DcDcGroupsList
