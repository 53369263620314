import { useState, useReducer } from 'react'
import Table from 'components/Table/Table/Table'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  StringsToTernaryBarChartRow,
  hvacStageStyle,
  fieldOpacity,
  getEnvCtrlsStatus,
  getHvacStatus,
} from '../tableUtils'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import { ReactComponent as AlertCircleIcon } from 'icons/alert-circle-icon.svg'
import * as TableStyles from '../table.styled'
import { useParams } from 'react-router'
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'
import ActionMenuContent from '../../Commands/ClearEnclosureFaults/ActionMenuModal/ActionMenuContent'
import actionModalReducer from '../../Commands/ClearEnclosureFaults/ActionMenuModal/action-modal-reducer'
import * as Styles from './styles'
import AppTooltip from 'components/AppTooltip/AppTooltip'

// API Imports
import useCentipedeLineupDetailsQuery from 'api/useQueryHooks/useCentipedeLineupDetailsQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import {
  findSensor,
  omitTest,
} from './centipedeLineupDetailsCollectionSegment.utils'

const CentipedeLineupDetailsEnergySegmentsTable = () => {
  const getRowId = (row) => row.enclosureIndex

  const params = useParams()

  const [showCommandModal, setShowCommandModal] = useState(false)

  const [ActionModal, actionModalDispatch] = useReducer(actionModalReducer)

  const idCell = (row) => (
    <TableStyles.RowLink
      to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/centipede-lineup/${row.groupIndex}/centipede-energy-segment/${row.enclosureIndex}`}
    >
      {row.enclosureIndex}
    </TableStyles.RowLink>
  )

  const actionColumnMenu = (row) => {
    let isClearable = false
    Object.keys(row.sensorCounts).forEach((sensor) => {
      if (row.sensorCounts[sensor].readyToClear > 0) isClearable = true
    })
    if (isClearable) {
      return (
        <Styles.Container>
          <ActionsMenu onOpen={() => null} onClose={() => null}>
            <ActionMenuContent
              setShowCommandModal={setShowCommandModal}
              handleActionSelection={actionModalDispatch}
              stationCode={params.stationCode}
              blockIndex={params.blockIndex}
              target="ENCLOSURE"
              enclosureIndex={row.enclosureIndex}
            />
          </ActionsMenu>
        </Styles.Container>
      )
    }
    return <Styles.Container />
  }

  const columns = [
    {
      name: 'Actions',
      id: 'actions',
      sortable: false,
      noOmit: true,
      width: '80px',
      cell: actionColumnMenu,
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: idCell,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
    },
    {
      name: 'GAS',
      id: 'gas',
      selector: (row) => row.sensorCounts.GAS.total,
      sortable: true,
      width: '65px',
      cell: (row) => findSensor(row, 'GAS'),

      noOmit: false,
      omit: (row) => omitTest(row, 'GAS'),
    },
    {
      name: 'HEAT',
      id: 'heat',
      selector: (row) => row.sensorCounts.FIRE_SAFETY.total,
      sortable: true,
      width: '65px',
      cell: (row) => findSensor(row, 'FIRE_SAFETY'),
      noOmit: false,
      omit: (row) => omitTest(row, 'FIRE_SAFETY'),
    },
    {
      name: 'DOORS',
      id: 'doors',
      selector: (row) => row.sensorCounts.DOOR.total,
      sortable: true,
      width: '65px',
      cell: (row) => findSensor(row, 'DOOR'),
      noOmit: false,
      omit: (row) => omitTest(row, 'DOOR'),
    },
    {
      name: 'MOISTURE',
      id: 'moisture',
      selector: (row) => row.sensorCounts.MOISTURE.total,
      sortable: true,
      width: '85px',
      cell: (row) => findSensor(row, 'MOISTURE'),
      noOmit: false,
      omit: (row) => omitTest(row, 'MOISTURE'),
    },
    {
      name: 'VENTILATION',
      id: 'ventilation',
      selector: (row) => row.sensorCounts.VENTILATION.total,
      sortable: true,
      width: '95px',
      cell: (row) => findSensor(row, 'VENTILATION'),
      noOmit: false,
      omit: (row) => omitTest(row, 'VENTILATION'),
    },
    {
      name: 'LOST COMMS',
      id: 'lostComms',
      selector: (row) => row.sensorCounts.LOST_COMMS_WITH_IO.total,
      sortable: true,
      width: '95px',
      cell: (row) => findSensor(row, 'LOST_COMMS_WITH_IO'),
      noOmit: false,
      omit: (row) => omitTest(row, 'LOST_COMMS_WITH_IO'),
    },
    {
      name: 'DATA UNAVAIL',
      id: 'dataUnavail',
      selector: (row) => row.sensorCounts.DATA_UNAVAILABLE.total,
      sortable: true,
      width: '100px',
      cell: (row) => findSensor(row, 'DATA_UNAVAILABLE'),
      noOmit: false,
      omit: (row) => omitTest(row, 'DATA_UNAVAILABLE'),
    },
    {
      name: 'OTHER',
      id: 'other',
      selector: (row) => row.sensorCounts.OTHER.total,
      sortable: true,
      width: '65px',
      cell: (row) => findSensor(row, 'OTHER'),
      noOmit: false,
      omit: (row) => omitTest(row, 'OTHER'),
    },
    {
      name: 'ENV CTRLS',
      id: 'envCtrls',
      selector: (row) => row.envControllersTotal,
      sortable: true,
      width: '85px',
      cell: (row) =>
        getEnvCtrlsStatus(row.envControllersNonHealthy) ? (
          <StatusIcon $isHealthy={true} data-tooltip-id={row}>
            <CheckIcon />
            <AppTooltip id={row}>{'Healthy'}</AppTooltip>
          </StatusIcon>
        ) : (
          <StatusIcon data-tooltip-id={row}>
            <AlertCircleIcon />
            <AppTooltip id={row}>{'Unhealthy'}</AppTooltip>
          </StatusIcon>
        ),
      noOmit: false,
      omit: false,
    },
    {
      name: 'STRINGS',
      id: 'strings',
      selector: (row) => row.stringCounts,
      sortable: true,
      width: '85px',
      cell: (row) =>
        fieldOpacity(
          row.envControllersNonHealthy,
          StringsToTernaryBarChartRow(row.stringCounts),
        ),
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC',
      id: 'hvac',
      selector: (row) => `${row.hvacTotal}:${row.hvacHealthy}`,
      sortable: true,
      width: '65px',
      cell: (row) =>
        fieldOpacity(
          row.envControllersNonHealthy,
          getHvacStatus(row.hvacNonHealthy),
        ),
      noOmit: false,
      omit: false,
    },
    {
      name: 'HUMIDITY',
      id: 'humidity',
      selector: (row) => row.internalHumidity,
      sortable: true,
      width: '80px',
      cell: (row) =>
        fieldOpacity(row.envControllersNonHealthy, `${row.internalHumidity}%`),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AIR TEMP',
      id: 'airTemp',
      selector: (row) => row.internalAirTemp,
      sortable: true,
      width: '80px',
      cell: (row) =>
        fieldOpacity(row.envControllersNonHealthy, `${row.internalAirTemp}° C`),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'CELL TEMP',
      id: 'cellTemp',
      selector: (row) => row.avgCellTemp,
      sortable: true,
      width: '85px',
      cell: (row) =>
        fieldOpacity(
          row.envControllersNonHealthy,
          `${Math.round(row.avgCellTemp)}° C`,
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'COOL TO',
      id: 'coolTo',
      selector: (row) => row.coolTo,
      sortable: true,
      width: '80px',
      cell: (row) =>
        fieldOpacity(row.envControllersNonHealthy, `${row.coolTo}° C`),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'HEAT TO',
      id: 'heatTo',
      selector: (row) => row.heatTo,
      sortable: true,
      width: '80px',
      cell: (row) =>
        fieldOpacity(row.envControllersNonHealthy, `${row.heatTo}° C`),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'RESPONDING TO',
      id: 'respondingTo',
      selector: (row) => row.respondingTo,
      sortable: true,
      width: '110px',
      cell: (row) =>
        fieldOpacity(row.envControllersNonHealthy, row.respondingTo),
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC STAGE',
      id: 'hvacStage',
      selector: (row) => row.hvacStage,
      sortable: true,
      width: '90px',
      cell: (row) =>
        hvacStageStyle(row.hvacStage, row.envControllersNonHealthy),
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC SIGNALS',
      id: 'signals',
      selector: (row) => row.signals,
      sortable: true,
      width: '110px',
      cell: (row) => fieldOpacity(row.envControllersNonHealthy, row.signals),
      noOmit: false,
      omit: false,
    },
  ]

  const TableJsx = (data) => {
    return (
      <div style={{ overflow: 'none' }}>
        <Table
          tableId="CentipedeLineupDetailsEnergySegmentsTable"
          data={data.energySegments}
          columns={columns}
          getRowId={getRowId}
          pagination={false}
          defaultId="id"
        />
        {showCommandModal && <ActionModal />}
      </div>
    )
  }
  return handleQueryLoadingAndError(useCentipedeLineupDetailsQuery(), TableJsx)
}

export default CentipedeLineupDetailsEnergySegmentsTable
