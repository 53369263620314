import CommandModalTemplate from '../CommandModalTemplate'
import { ClearSensorFaultsUi } from './ClearSensorFaultsUi'
import { ClearSensorFaultsResult } from './ClearSensorFaultsResult'

const modalTitles = {
  start: 'Clear Fault',
  finish: 'Clear Fault',
}
const queryName = 'clearSensorFaults'

export const ClearSensorFaultsModal = ({
  sensorIndex,
  sensorName,
  centipedeLineupId,
  segmentId,
  segment,
  setShowCommandModal,
}) => {
  const commandResult = () => (
    <ClearSensorFaultsResult setShowCommandModal={setShowCommandModal} />
  )

  const userInputForm = () => (
    <ClearSensorFaultsUi sensorIndex={sensorIndex} sensorName={sensorName} />
  )

  return (
    <CommandModalTemplate
      ids={{ sensorIndex, sensorName }}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      CommandResultJsx={commandResult}
      UserInputFormJsx={userInputForm}
      queryName={queryName}
      additionalPayload={{ centipedeLineupId, segmentId, segment }}
    />
  )
}
