import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './CommunicatingTooltip.styles'

export const CommunicatingTooltip = ({ isCommunicating, timestamp }) => {
  const formattedDate = timestamp
    ? new Date(timestamp).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : 'Unknown'
  return (
    <Styles.Container>
      <Typography $fontColor={'white'} $strong>
        {isCommunicating ? 'Communicating' : 'Not Communicating'}
      </Typography>
      <Typography $fontColor={'white'}>
        Last Communication: {formattedDate}
      </Typography>
    </Styles.Container>
  )
}
