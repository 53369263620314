import { useState } from 'react'

import styled from 'styled-components/macro'
import Card from 'components/Cards/Card/Card'

import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useEksPcsDetailsGraphQuery from 'api/useQueryHooks/useEksPcsDetailsGraphQuery'
import { SeriesChart } from 'modules/Station/components/Charts/SeriesChart/SeriesChart'

const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
`

const PowerTrendsGraphs = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { data, isLoading } = useEksPcsDetailsGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  if (isLoading) return <LoadingPage />

  const series = {
    reactivePower: {
      title: 'Reactive Power',
      unit: ' kVAR',
      series: data?.pcsModuleValues?.map((p, i) => ({
        name: `PCS Module ${i + 1}`,
        graphData: p.acReactivePowerKVAR.series,
      })),
    },
    realPower: {
      title: 'Real Power',
      unit: ' kW',
      series: data?.pcsModuleValues?.map((p, i) => ({
        name: `PCS Module ${i + 1}`,
        graphData: p.acRealPowerKW.series,
      })),
    },
    transformerTemp: {
      title: 'Transformer Temperature',
      showXAxis: true,
      unit: ' ºC',
      series: [
        {
          name: 'Transformer Temperature',
          graphData: data?.transformerTemp?.series,
        },
      ],
    },
  }

  return (
    <Card
      title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
    >
      <GraphsWrapper>
        <SeriesChart data={series.realPower} period={graphPeriod} />
        <SeriesChart data={series.reactivePower} period={graphPeriod} />
        <SeriesChart data={series.transformerTemp} period={graphPeriod} />
      </GraphsWrapper>
    </Card>
  )
}

export default PowerTrendsGraphs
