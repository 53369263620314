import Table from 'components/Table/Table/Table'
import useACPVBattDCDCGroupsQuery from 'api/useQueryHooks/useACPVBattDCDCGroupsQuery'
import { ReactComponent as DcGroupIcon } from 'icons/dc-group-icon.svg'
import { ReactComponent as ArraysIcon } from 'icons/array-icon.svg'
import Icon from 'components/Icon/Icon'
import * as Styles from '../table.styled'
import { useParams } from 'react-router-dom'
import { SetDcDcGroupRotationModal } from '../../Commands/DcDcGroupRotation/SetDcDcGroupRotationModal'
import { DisabledControlCheckbox, useIsStationConnected } from '../tableUtils'
import { useContext, useReducer, useState } from 'react'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import Tooltip from 'components/Tooltip'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import { ApiErrorMessage } from 'components/ApiErrorMessage/ApiErrorMessage'

const getRowId = (row) => `${row.acPvBatteryIndex}::${row.dcDcGroupIndex}`
const idsToObject = (ids) =>
  ids.map((id) => {
    const [acPvBatteryIndex, dcDcGroupIndex] = id.split('::')
    return { acPvBatteryIndex, dcDcGroupIndex }
  })

/////////////////  ACTION MODALS /////////////////////////////////
// see Inverter table for example to add actions and row selections
/////////////////////////////////////////////////////////////////
const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showGroupRotationModal': {
      return () => (
        <SetDcDcGroupRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

const ACPVBatteryDetailTable = ({ acPvBatteryIndex }) => {
  const { stationCode, blockIndex } = useParams()
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [selectedIds, setSelectedIds] = useState([])
  const { data, isLoading, error } =
    useACPVBattDCDCGroupsQuery(acPvBatteryIndex)

  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  const isDcDcGroupRotationEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DC_DC_GROUP_ROTATION_COMMAND,
  )

  const handleSelectedRowsChange = (rowsObject) => {
    setSelectedIds(
      Object.keys(rowsObject).filter((key) => rowsObject[key]), //returns array of ids where key value is true
    )
  }

  if (isLoading) return <LoadingPage />

  if (error) <ApiErrorMessage error={error} />

  const enableCheckAll = data.dcDcGroups.some(
    (row) => row?.parentPvPcsOutRotation === false,
  )

  const disableRotation = (row) => row?.parentPvPcsOutRotation

  const _tableActions = Boolean(
    isStationConnected && userHas8PC && isDcDcGroupRotationEnabled,
  ) && (
    <Styles.ButtonContainer>
      <FormCommandButton
        disabled={selectedIds.length === 0}
        onClick={() => {
          modalDispatch({
            type: 'showGroupRotationModal',
            setShowCommandModal: modalDispatch,
            idsForModal: idsToObject(selectedIds),
          })
        }}
      >
        <Icon
          size="xxs"
          color={
            selectedIds.length === 0
              ? COLORS.disabled_action
              : COLORS.action_blue
          }
          icon={<CheckIcon />}
        />
        Set DC:DC Group Rotation
      </FormCommandButton>
    </Styles.ButtonContainer>
  )

  const cellId = (row) => (
    <Styles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/ac-pv-battery/${row.acPvBatteryIndex}/dc-dc-group/${row.dcDcGroupIndex}`}
    >
      {row.acBatteryIndex}
      <Styles.RowIconText>
        <Icon icon={<DcGroupIcon />} size="xxs" />
        {row.acPvBatteryIndex}::{row.dcDcGroupIndex}
      </Styles.RowIconText>
    </Styles.RowLink>
  )
  const cellArrayId = (row) => (
    <Styles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/acPvBattery/${row.acPvBatteryIndex}/array/${row.array.arrayIndex}`}
    >
      <Styles.RowIconText>
        <Icon icon={<ArraysIcon />} size="xxs" />
        {row.array.arrayIndex}
      </Styles.RowIconText>
    </Styles.RowLink>
  )

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit:
        !isStationConnected ||
        !userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC) ||
        !isDcDcGroupRotationEnabled,
      noOmit: true,
      width: '55px',
      cell: (row) => (
        <ActionsMenu key={row} onOpen={() => null} onClose={() => null}>
          <ActionsMenuModalContent
            rowId={getRowId(row)}
            setShowCommandModal={modalDispatch}
            disableRotation={disableRotation(row)}
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'DC:DC GROUP ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '110px',
      noOmit: true,
      cell: cellId,
    },
    {
      name: 'DC:DC PCS HEALTH',
      id: 'dcDcPcsHealth',
      selector: (row) => row.healthyDcDcPCSes,
      sortable: true,
      width: '140px',
      noOmit: true,
      cell: (row) => {
        const unhealthyPcses = row.totalDcDcPCSes - row.healthyDcDcPCSes
        return (
          <BinaryBarChart
            positiveCount={row.healthyDcDcPCSes}
            negativeCount={unhealthyPcses}
            tooltip={`Healthy: ${row.healthyDcDcPCSes} | Unhealthy: ${unhealthyPcses}`}
            id={`${row.acPvBatteryIndex}:${row.dcDcGroupIndex}`}
          />
        )
      },
    },
    {
      name: 'DC CMD',
      id: 'dcCmd',
      selector: (row) => row.dcCmdRealPowerKW + ' kW',
      sortable: true,
      width: '90px',
      noOmit: true,
      right: true,
    },
    {
      name: 'DC SETTING',
      id: 'dcSet',
      selector: (row) => row.dcRealPowerKW + ' kW',
      sortable: true,
      width: '90px',
      noOmit: true,
      right: true,
    },
    {
      name: 'ARRAY ID',
      id: 'arrayId',
      selector: (row) => row.array.arrayIndex,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: cellArrayId,
      right: true,
    },
    {
      name: 'ON. AVAIL. DC ENERGY',
      id: 'onAvailDcEnergy',
      selector: (row) => row.array.dcPowerAndEnergy.onlineAvailableKWH,
      sortable: true,
      width: '140px',
      noOmit: true,
      cell: (row) =>
        row.array.dcPowerAndEnergy.onlineAvailableKWH.toLocaleString() + ' kWh',
      right: true,
    },
    {
      name: 'TTL CAP. DC ENERGY',
      id: 'ttlCapDcEnergy',
      selector: (row) => row.array.dcPowerAndEnergy.totalCapacityKWH,
      sortable: true,
      width: '140px',
      noOmit: true,
      cell: (row) =>
        row.array.dcPowerAndEnergy.totalCapacityKWH.toLocaleString() + ' kWh',
      right: true,
    },
    {
      name: 'AVAIL. DC CHRG PWR',
      id: 'availDcChrgPwr',
      selector: (row) => row.array.dcPowerAndEnergy.maxChargePowerKW,
      sortable: true,
      width: '140px',
      noOmit: true,
      cell: (row) =>
        row.array.dcPowerAndEnergy.maxChargePowerKW.toLocaleString() + ' kW',
      right: true,
    },
    {
      name: 'AVAIL. DC DSCHRG PWR',
      id: 'availDcDschrgPwr',
      selector: (row) => row.array.dcPowerAndEnergy.maxDischargePowerKW,
      sortable: true,
      width: '160px',
      noOmit: true,
      cell: (row) =>
        row.array.dcPowerAndEnergy.maxDischargePowerKW.toLocaleString() + ' kW',
      right: true,
    },
    {
      name: 'ON. SOC',
      id: 'onSoc',
      selector: (row) => row.array.dcPowerAndEnergy.onlineSocPercent,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: (row) => row.array.dcPowerAndEnergy.onlineSocPercent + '%',
      right: true,
    },
  ]

  return (
    <>
      <Table
        tableId="ACPVBatteriesDetailTable"
        data={data.dcDcGroups}
        columns={columns}
        controls
        getRowId={getRowId}
        onSelectedRowsChange={(e) => handleSelectedRowsChange(e)}
        tableActions={_tableActions}
        selectableRows={Boolean(_tableActions)}
        fixedHeader={false}
        selectableRowsComponent={DisabledControlCheckbox}
        //selectable status is tied to rotation
        isColumCheckDisabled={!enableCheckAll}
        isRowCheckDisabled={disableRotation}
        defaultId="id"
      />
      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default ACPVBatteryDetailTable

const ActionsMenuModalContent = ({
  rowId,
  setShowCommandModal,
  disableRotation,
}) => {
  const actionClickHandler = (payload) => {
    setShowCommandModal(null) // closes action menu
    setShowCommandModal(payload)
  }

  const id = idsToObject([rowId])

  return (
    <Styles.ActionModalSection>
      <Tooltip
        overlayStyle={{ maxWidth: 300 }}
        title={disableRotation ? 'Control Disabled. Parent Equipment OOR.' : ''}
      >
        <div>
          <ActionsMenuItem
            onClick={() =>
              actionClickHandler({
                type: 'showGroupRotationModal',
                setShowCommandModal: setShowCommandModal,
                idsForModal: id,
              })
            }
            disabled={disableRotation}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={
                  disableRotation ? COLORS.disabled_action : COLORS.action_blue
                }
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
        </div>
      </Tooltip>
    </Styles.ActionModalSection>
  )
}
