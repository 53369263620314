import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'
import BlockMeterListSummary from './Summary/BlockMeterListSummary'
import BlockMeterTable from 'modules/Station/components/Tables/BlockMeterTable/BlockMeterTable'
import useBlockMeterQuery from 'api/useQueryHooks/useBlockMeterQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'

const BlockMeterList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  const { data: blockStatusData } = useBlockStatus()

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'block-meter-list' })
    document.title = `${stationCode}::${blockIndex} - Block Meter List`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={
        <BlockMeterListSummary
          data={data.elementList}
          enclosureType={blockStatusData?.siteEnclosureType}
        />
      }
      tableComponent={
        <BlockMeterTable
          data={data.elementList}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
    />
  )

  return handleQueryLoadingAndError(useBlockMeterQuery(), ListPageJsx)
}

export default BlockMeterList
