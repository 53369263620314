// Style Imports
import * as Styles from './styles'

// Icon Imports
import { ReactComponent as HealthGoodIcon } from '../../../icons/health-good-icon.svg'
import { ReactComponent as HealthWarningIcon } from '../../../icons/health-warning-icon.svg'
import { ReactComponent as HealthAlertIcon } from '../../../icons/health-alert-icon.svg'

const HealthStatus = ({ isHealthy, showWarning = false }) => {
  return (
    <Styles.Container $isHealthy={!showWarning ? isHealthy : false}>
      {isHealthy && !showWarning ? (
        <HealthGoodIcon />
      ) : !showWarning ? (
        <HealthAlertIcon />
      ) : null}
      {showWarning && <HealthWarningIcon />}
    </Styles.Container>
  )
}

export default HealthStatus
