import React, { useEffect, useState } from 'react'
import _ from 'lodash'

// Style Imports
import * as Styles from './styles'

// Component Imports
import ToolbarDropdown from '../ToolbarDropdown/ToolbarDropdown'

const ToolbarRangeFilter = ({ data, range, category, handleChange }) => {
  const [minValue, setMinValue] = useState(1)
  const [maxValue, setMaxValue] = useState(data.length)

  useEffect(() => {
    handleChange(minValue, maxValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue])

  const renderTitle = () => {
    return (
      <div style={{ display: 'flex' }}>
        {category}: <Styles.Value>{minValue}</Styles.Value> -{' '}
        <Styles.Value>{maxValue}</Styles.Value>
      </div>
    )
  }

  return (
    <ToolbarDropdown title={renderTitle()}>
      <Styles.Content>
        <Styles.Select
          onChange={(e) => setMinValue(e.target.value)}
          value={minValue}
        >
          {_.map(range, (a) => (
            <option key={a} value={a} disabled={a > maxValue}>
              {a}
            </option>
          ))}
        </Styles.Select>
        <Styles.Dash> - </Styles.Dash>
        <Styles.Select
          onChange={(e) => setMaxValue(e.target.value)}
          value={maxValue}
        >
          {_.map(range, (a) => (
            <option key={a} value={a} disabled={a < minValue}>
              {a}
            </option>
          ))}
        </Styles.Select>
      </Styles.Content>
    </ToolbarDropdown>
  )
}

export default ToolbarRangeFilter
