//3,4,5xx HTTP status codes
export const handleError = (error, clearSession) => {
  // eslint-disable-next-line no-console
  console.error(
    'Details about the error that just occurred:',
    JSON.stringify(error, null, ' '),
  )
  let message = 'Application error. Please log in again.'

  // data api down
  if (error.config.url.includes('initSession')) {
    message = 'Network not available.  Please try again in a few minutes.'
    clearSession(message)
    return
  }

  // 403 no access to resource
  switch (error?.response?.status) {
    case 429: //exceeded request rate
      message = 'Data rate exceeded. Please log in again.'
      clearSession(message)
      break
    case 401: //session token expired
    case 511: // network authentication required
      message = 'Session expired. Please log in again.'
      clearSession(message)
      break
    case 404:
      break
    case 422: // bad formed call
      message = 'Error 422'
      alert(message)
      break
    case 501: // not implemented
    case 502: // bad gateway
    case 503: // service unavailable
    case 504: // gateway timeout
      message = 'Unexpected Server Error Please Try Again Shortly'
      alert(message)
      break
    case 505: // HTTP Version Not Supported
      message = 'HTTP Version Not Supported'
      clearSession(message)
      break
    case 400: // Bad request
      message = 'Error 400 Bad Request'
      alert(message)
      window.location.reload()
      break
    default: {
      clearSession(message)
    }
  }
}
