import React from 'react'

// Style Imports
import * as Styles from './styles'

const PercentBarChart = ({ percent }) => (
  <Styles.Container $percent={percent}>{percent}%</Styles.Container>
)

export default PercentBarChart
