import { useEffect } from 'react'
import { PvPcsMainColumn } from './MainColumn/PvPcsMainColumn'
import { PvPcsSummary } from './Summary/PvPcsSummary'
import { useParams } from 'react-router-dom'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import usePvPcsIndexQuery from 'api/useQueryHooks/usePvPcsIndexQuery'

export const PvPcsDetail = ({
  selectedParams,
  setSelectedPage,
  setSelectedParams,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acPvBatteryIndex, pvPcsIndex } = params
  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - PV PCS ${acPvBatteryIndex}::${pvPcsIndex}`
    setSelectedParams({ ...params, listView: 'pv-pcs-detail' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DetailsPageJsx = (data) => (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<PvPcsSummary data={data} />}
      mainColumnComponent={<PvPcsMainColumn />}
    />
  )

  return handleQueryLoadingAndError(
    usePvPcsIndexQuery(acPvBatteryIndex, pvPcsIndex),
    DetailsPageJsx,
  )
}
