import { ResourceEnum } from 'api/dictionaries/rotation-excuses'
import CommandModalTemplate from '../CommandModalTemplate'
import { defaultRotationFormValues, RotationForm } from './RotationForm'
import { RotationResults } from './RotationResults'

const modalTitles = {
  start: 'Set Rotation',
  finish: 'Rotation State',
}

// step 3 command query
const queryName = 'setRotation'
const label = 'Strings'
const resourceType = ResourceEnum.string
// define followup modal custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <RotationResults ids={ids} rotationForm={userInputForm} />
)

// define custom input form returns jsx
const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  RotationForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    resourceType,
    label,
  })

// SetCommandModal keeps track of input form and each modal step, sending command, close modal
export const SetRotationModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated
    />
  )
}
