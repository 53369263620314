import { useState } from 'react'
import styled from 'styled-components/macro'
import { Route, Routes } from 'react-router-dom'

// Component Imports
import SystemDetails from './modules/SystemDetails/SystemDetails'
import Analysis from './modules/Analysis/StationAnalysis'
import StationService from './modules/Service'

// API hooks
import { TopHeader } from './TopHeader'
import StationAlertManagement from './modules/AlertManagement/StationAlertManagement'
import { Notifications } from './modules/SystemDetails/Notifications/Notifications'

const StationDetails = () => {
  const [selectedPage, setSelectedPage] = useState('')

  return (
    <Container>
      <TopHeader selectedPage={selectedPage} />

      <Content>
        <Routes>
          <Route path={`service`} element={<StationService />} />
          <Route
            path={'alert-management'}
            element={
              <StationAlertManagement
                setSelectedPage={() => setSelectedPage('alertManagement')}
              />
            }
          />
          <Route
            path={'analysis'}
            element={
              <Analysis setSelectedPage={() => setSelectedPage('analysis')} />
            }
          />
          <Route
            path={'notifications'}
            element={<Notifications setSelectedPage={setSelectedPage} />}
          />
          <Route
            path="*"
            element={<SystemDetails setSelectedPage={setSelectedPage} />}
          />
        </Routes>
      </Content>
    </Container>
  )
}

export default StationDetails

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow-y: hidden;
`
