import React from 'react'
import { Link, useParams } from 'react-router-dom'

import * as Styles from './styles'

// Icon Imports
import { ReactComponent as DcAcInverterIcon } from '../../../../../../icons/dc-ac-pcs-icon.svg'
import { ReactComponent as CheckIcon } from '../../../../../../icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../../../../icons/error-icon.svg'

const PCSNode = ({ data }) => {
  const params = useParams()

  return (
    <Styles.Container $isEnabled={data.valid}>
      <Styles.BusBar />
      <Styles.ContactorBar
        $contactorsHealthy={data.ready}
        $contactorsClosed={data.ready}
      />
      <Link
        to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${params.acBatteryId}/inverter-index1`}
      >
        <Styles.Tile $isHealthy={data.ready}>
          <Styles.TitleRow>
            <Styles.Icon>
              <DcAcInverterIcon />
            </Styles.Icon>
            <Styles.Title>1</Styles.Title>
          </Styles.TitleRow>
          <Styles.Row>
            <Styles.Label>Ready:</Styles.Label>
            <Styles.Value>
              <Styles.StatusIcon $isHealthy={data.ready}>
                {data.ready ? <CheckIcon /> : <ErrorIcon />}
              </Styles.StatusIcon>
            </Styles.Value>
          </Styles.Row>
        </Styles.Tile>
      </Link>
    </Styles.Container>
  )
}

export default PCSNode
