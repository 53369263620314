import { ReactComponent as CentipedeLineupIcon } from 'icons/centipede-lineup.svg'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import useOutdoorEnvironmentQuery from 'api/useQueryHooks/useOutdoorEnvironmentQuery'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

const CentipedeLineupDetailsSummary = ({ data }) => {
  const { data: outdoorData, isLoading: isLoadingOutdoorData } =
    useOutdoorEnvironmentQuery(!!data)
  if (!data) return null

  const outdoorTemp = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalAirTemp, '° C')
  const outdoorHumidity = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalHumidity, '%')

  const summaryMap = {
    title: `Centipede Lineup ${data.centipedeLineupIndex}`,
    titleIcon: <CentipedeLineupIcon />,

    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: data.isHealthy ? (
              <StatusIcon $isHealthy={data.isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'Air Temp',
            value: outdoorTemp.toLocaleString(),
          },
          {
            label: 'Humidity',
            value: outdoorHumidity.toLocaleString(),
          },
        ],
      },
      {
        sectionTitle: 'ENERGY',
        rows: [
          {
            label: 'SoC',
            value: checkNullRoundValue(data.soc, '%'),
          },
          {
            label: 'Amp Hours',
            value: checkNullRoundValue(data.ampHours, ' Ah'),
          },
          {
            label: 'Kilowatt Hours',
            value: checkNullRoundValue(data.kiloWattHours, ' kWh'),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default CentipedeLineupDetailsSummary
