import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin: 0;
  &:hover {
    border-color: ${(props) => (props.isExpanded ? 'transparent' : '#d6d6d6')};
  }
`
export const TopContent = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.$minimal ? '5px' : '10px 5px')};
  padding-left: ${(props) => (props.$expandable ? '5px' : '15px')};
`
export const ToggleIcon = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 10px;
  fill: #00ba86;
  line-height: 0;
  margin-right: 10px;
  border-radius: 5px;

  &:hover {
    background-color: #efefef;
  }
`
export const LeftRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 280px;
  cursor: ${(props) => (props.$expandable ? 'pointer' : 'default')};
`
export const SummaryRow = styled.div`
  display: flex;
  flex: 1;
`
export const SectionFlex = styled.div`
  flex: 1;
`
export const IconContainer = styled.div`
  margin-right: 15px;
`
export const TitleRow = styled.div`
  flex: 1;

  ${(props) => (props.$minimal ? '' : 'height: 40px;')}
  ${(props) => (props.$minimal ? '' : 'border-right: 1px solid #d0d0d0;')}
	margin-right: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #282929;
  text-transform: uppercase;
`
export const ListLink = styled(Link)`
  font-size: 12px;
`
export const SummarySection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  width: 150px;

  &:last-child {
    margin: 0;
  }
`
export const SummaryBarChartSection = styled(SummarySection)`
  width: 150px;
`
export const BarChartContainer = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
`
export const LabelRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  line-height: 1;
`
export const LabelIcon = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  fill: #798b95;
`
export const Label = styled.div`
  font-size: 12px;
  color: #909090;
  font-weight: 500;
`
export const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #282929;
`
export const ExpandedContent = styled.div`
  padding: 10px;
`
export const IconRow = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
`
export const HealthIcon = styled.div`
  width: 14px;
  height: 14px;
  fill: ${(props) => (props.$isHealthy ? '#00b985' : '#f05946')};
  margin-right: 5px;
`
