import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  width: 120px;
  height: 100px;
  opacity: ${(props) => (props.$isEnabled ? '1' : 0.5)};
`
export const Flex = styled.div`
  flex: 1;
`
export const BusBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background-color: #333;
`
export const ContactorBar = styled.div`
  position: absolute;
  width: 3px;
  height: ${(props) => (props.$contactorsClosed ? '20px' : '12px')};
  right: ${(props) => (props.$contactorsClosed ? '83px' : '80px')};
  top: ${(props) => (props.$contactorsClosed ? '1px' : '11px')};
  background-color: ${(props) =>
    props.$contactorsHealthy ? '#00b986' : 'red'};
  transform: ${(props) => (props.$contactorsClosed ? 'none' : 'rotate(45deg)')};
`
export const Tile = styled.div`
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100px;
  height: 80px;
  background-color: #fff;
  box-shadow: ${(props) =>
    props.$isSelected
      ? '0 2px 2px rgba(0, 0, 0, .2), 0 0 0 7px rgba(0, 0, 0, .15)'
      : '0 2px 2px rgba(0, 0, 0, .2)'};
  border-radius: 5px;
  border: 2px solid;
  border-color: ${(props) => (props.$isHealthy ? '#00ba86' : 'red')};
  overflow: hidden;
`
export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`
export const Icon = styled.div`
  width: 25px;
  height: 25px;
  fill: #788b95;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
`
export const SocBar = styled.div`
  height: 5px;
  width: 20px;
  border-radius: 10px;
  margin: 2px 0 0 2px;
  background: ${(props) =>
    `linear-gradient(to right, #40a3d5 0%, #40a3d5 ${props.$soc}%, #bbb ${props.$soc}%, #bbb)`};
`
export const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #333;
  line-height: 1;
`
export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  justify-content: space-between;
  padding: 3px 0;

  &:last-child {
    border-bottom: none;
  }
`
export const Label = styled.div`
  font-size: 11px;
  color: #909090;
  font-weight: 500;
`
export const Value = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: #282929;
`
export const AlertBanner = styled.div`
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  padding: 2px 0;
  background-color: red;
  margin: 0 -10px -5px;
`
