import React, { useReducer, useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Component Imports
import Table from 'components/Table/Table/Table'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import Icon from 'components/Icon/Icon'
import PlugInStatusModal from '../../Commands/PlugInStatus/PluginStatusModal'

// Utils Import
import * as Styles from 'modules/Station/components/Tables/table.styled'
import getPlugInsTableData from './getPlugInsTableData'

// Theme Imports
import { COLORS } from 'design_system/colors'

// Icon Imports
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as MinusIcon } from 'icons/minus-icon.svg'

// Hooks Imports
import usePlugInsQuery from 'api/useQueryHooks/usePlugInsQuery'
import { USER_ROLES } from 'constants/USER_ROLES'
import { AuthorizationContext } from 'contexts/authorizations.context'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'plugInStatus': {
      action.setShowCommandModal(true)
      return () => (
        <PlugInStatusModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

const removeHtml = (str) => {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}

const ActionsMenuModalContent = React.memo(
  ({ rowId, modalDispatch, setShowCommandModal, closeActionModal }) => {
    const actionClickHandler = (dispatchType) => {
      closeActionModal()
      modalDispatch({
        type: dispatchType,
        setShowCommandModal: setShowCommandModal,
        idsForModal: [rowId],
      })
    }

    return (
      <div key={rowId} id={rowId}>
        <Styles.ActionModalSection>
          <ActionsMenuItem onClick={() => actionClickHandler('plugInStatus')}>
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Toggle Status</div>
          </ActionsMenuItem>
        </Styles.ActionModalSection>
      </div>
    )
  },
)

/////////////////////////////////////////////////////////////////

const PlugInsTable = () => {
  const { data, isLoading } = usePlugInsQuery()
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [activeActionRow, setActiveActionRow] = useState(null)
  const { userHasRole } = useContext(AuthorizationContext)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null)
  }, [showCommandModal])

  if (isLoading) return <LoadingPage />
  const tableData = getPlugInsTableData(data)

  const isROCUser = userHasRole(USER_ROLES.ROC_OPERATOR)

  const getIdFromRow = (row) => Number(row.id)

  const conditionalRowStyles = [
    {
      when: (row) => getIdFromRow(row) === activeActionRow,
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  const statusCell = (row) =>
    row.valid ? (
      row.enabled ? (
        !_.isEmpty(row.shortStatus) ? (
          <Truncate>{removeHtml(row.shortStatus)}</Truncate>
        ) : (
          <Truncate>{removeHtml(row.longStatus)}</Truncate>
        )
      ) : (
        'Not Enabled'
      )
    ) : (
      'Invalid'
    )

  const columnActionMenu = (row) => {
    return row.canDisable ? (
      <ActionsMenu
        key={row}
        onOpen={() => onOpen(row)}
        onClose={() => onClose()}
      >
        {' '}
        <ActionsMenuModalContent
          rowId={row.id}
          modalDispatch={modalDispatch}
          setShowCommandModal={setShowCommandModal}
        />
      </ActionsMenu>
    ) : null
  }

  const onOpen = (row) => {
    setActiveActionRow(getIdFromRow(row))
  }

  const onClose = () => {
    setActiveActionRow(null)
  }

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      width: '55px',
      cell: columnActionMenu,
      omit: !isROCUser,
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <CheckIcon />
        </div>
      ),
      id: 'enabled',
      selector: (row) => row.enabled,
      sortable: true,
      width: '40px',
      cell: (row) => (
        <StatusIcon>
          {row.enabled ? (
            <CheckIcon fill="#00bf91" />
          ) : (
            <MinusIcon fill="#d0d0d0" />
          )}
        </StatusIcon>
      ),
    },
    {
      name: 'Name',
      id: 'name',
      selector: (row) => row.name,
      sortable: true,
      width: '250px',
      cell: (row) => <AppName $enabled={row.enabled}>{row.name}</AppName>,
    },
    {
      name: 'Status',
      id: 'status',
      selector: (row) => row.shortStatus,
      sortable: true,
      noOmit: false,
      omit: false,
      width: '480px',
      cell: statusCell,
    },
  ]

  return (
    <>
      <Table
        tableId="plugInsTable"
        data={tableData}
        columns={columns}
        getRowId={getIdFromRow}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={false}
        pagination={false}
        controls={false}
      />

      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default PlugInsTable

const StatusIcon = styled.div`
  display: flex;
  height: 17px;
  width: 10px;
  line-height: 1.25;
  margin-right: 5px;
`
const AppName = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.$enabled ? 'initial' : '#989ea0')};
`

const Truncate = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
