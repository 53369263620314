import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import EksPcsGroupDetailsSummary from './Summary/EksPcsGroupDetailsSummary'
import EksPcsGroupDetailsMain from './Main/EksPcsGroupDetailsMain'
import useEksPcsDetailsQuery from 'api/useQueryHooks/useEksPcsDetailsQuery'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'

const EksPcsGroupDetails = ({
  setSelectedPage,
  selectedParams,
  setSelectedParams,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, eksPcsGroupId } = params

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - Power Station ${eksPcsGroupId}`
    setSelectedParams({ ...params, listView: 'eks-pcs-group-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return handleQueryLoadingAndError(useEksPcsDetailsQuery(), (data) => (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<EksPcsGroupDetailsSummary data={data} />}
      mainColumnComponent={<EksPcsGroupDetailsMain data={data} />}
    />
  ))
}

export default EksPcsGroupDetails
