import { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { Route, useParams, Routes, Navigate } from 'react-router-dom'

// Component Imports
import System from 'modules/Station/modules/SystemDetails/System/System'
import ACBatteryDetails from 'modules/Station/modules/SystemDetails/ACBatteryDetails/ACBatteryDetails'
import ArrayPcsList from 'modules/Station/modules/SystemDetails/ArrayPcsList/ArrayPcsList'
import PCSDetails from 'modules/Station/modules/SystemDetails/PCSDetails/PCSDetails'
import ArrayList from 'modules/Station/modules/SystemDetails/ArrayList/ArrayList'
import ArrayDetails from 'modules/Station/modules/SystemDetails/ArrayDetails/ArrayDetails'
import StringList from 'modules/Station/modules/SystemDetails/StringList/StringList'
import EnclosureList from 'modules/Station/modules/SystemDetails/EnclosureList/EnclosureList'
import EnclosureDetails from 'modules/Station/modules/SystemDetails/EnclosureDetails/EnclosureDetails'
import ACBatteryList from './ACBatteryList/ACBatteryList'
import PvPcsList from './PvPcsList/PvPcsList'
import DcDcGroupsList from './DcDcGroupsList/DcDcGroupsList'
import DcDcPcsList from './DcDCPcsList/DcDcPcsList'
import ACPVBatteryList from './ACPVBatteryList/ACPVBatteryList'
import ACPVBatteryDetails from './ACPVBatteryDetails/ACPVBatteryDetails'
import { PvPcsDetail } from './PvPcsDetail/PvPcsDetail'
import { DcDcPcsDetail } from './DcDcPcsDetail/DcDcPcsDetail'
import DcDcGroupDetail from './DcDcGroupDetail/DcDcGroupDetail'
import CentipedeLineups from './CentipedeLineups/CentipedeLineups'
import CentipedeLineupDetails from './CentipedeLineupDetails/CentipedeLineupDetails'
import SensorsList from './SensorsList/SensorsList'
import HvacList from './HvacList/HvacList'
import StackManagersList from './StackManagersList/StackManagersList'
import CollectionSegments from './CollectionSegments/CollectionSegmentsList'
import EnergySegments from './CentipedeEnergySegments/EnergySegments'
import EnvironmentalControllersList from './EnvironmentalControllersList/EnvironmentalControllersList'
import UPSList from './UpsList/UpsList'
import StringDetails from './StringDetails/StringDetails'
import CollectionSegmentDetails from './CollectionSegmentDetails/CollectionSegmentDetails'
import CentipedeEnergySegmentDetails from './CentipedeEnergySegmentDetails/CentipedeEnergySegmentDetails'
import EksPowerPlantController from './EksPpcDetails/EksPpcDetails'
import BlockMeterList from './BlockMeterList/BlockMeterList'
import BlockMeterDetails from './BlockMeterDetails/BlockMeterDetails'
import { AuthorizationContext } from 'contexts/authorizations.context'
import EksPcsGroupList from './EksPcsList/EksPcsGroupList'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import EksPcsGroupDetails from './EksPcsGroupDetails/EksPcsGroupDetails'

//TODO: code splitting

const SystemDetails = ({ setSelectedPage }) => {
  const params = useParams()
  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  const [viewOneline, setViewOneline] = useState(false)
  const [selectedParams, setSelectedParams] = useState(params)

  return (
    <Container>
      <Routes>
        <Route
          path={`block-topology/enclosure-list`}
          element={
            <EnclosureList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allEnclosures')}
            />
          }
        />
        <Route
          path={`block-topology/container/:containerId`}
          element={
            <EnclosureDetails
              view={viewOneline}
              toggleView={setViewOneline}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              setSelectedPage={() => setSelectedPage('allEnclosures')}
            />
          }
        />
        <Route
          path={`block-topology/array-pcs-list`}
          element={
            <ArrayPcsList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allPCSes')}
            />
          }
        />
        <Route
          path={`block-topology/pv-pcs-list`}
          element={
            <PvPcsList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allPvPcses')}
            />
          }
        />
        <Route
          path={`block-topology/ac-pv-batteries-list`}
          element={
            <ACPVBatteryList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allAcPvBatteries')}
            />
          }
        />
        <Route
          path={`block-topology/ac-pv-battery/:acPvBatteryIndex/dc-dc-group/:dcDcGroupIndex`}
          element={
            <DcDcGroupDetail
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allDcGroups')}
            />
          }
        />
        <Route
          path={`block-topology/ac-pv-battery/:acPvBatteryIndex/pv-pcs/:pvPcsIndex`}
          element={
            <PvPcsDetail
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allPvPcses')}
            />
          }
        />
        <Route
          path={`block-topology/ac-pv-battery/:acPvBatteryIndex/dc-dc-group/:dcDcGroupIndex/dc-dc-pcs/:dcDcPcsIndex`}
          element={
            <DcDcPcsDetail
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allDcPcses')}
            />
          }
        />
        <Route
          path={`block-topology/dc-dc-groups-list`}
          element={
            <DcDcGroupsList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allDcDcGroups')}
            />
          }
        />
        <Route
          path={`block-topology/dc-dc-pcs-list`}
          element={
            <DcDcPcsList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allDcDcPcses')}
            />
          }
        />
        <Route
          path={`block-topology/centipede-lineups`}
          element={
            <CentipedeLineups
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('centipede-lineups')}
            />
          }
        />
        <Route
          path={`block-topology/centipede-lineup-details/:centipedeId`}
          element={
            <CentipedeLineupDetails
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('centipedeId')}
            />
          }
        />
        <Route
          path={`block-topology/collection-segments`}
          element={
            <CollectionSegments
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('collection-segments')}
            />
          }
        />
        <Route
          path={`block-topology/centipede-lineup/:centipedeLineupId/collection-segment/:collectionSegmentId`}
          element={
            <CollectionSegmentDetails
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() =>
                setSelectedPage(`collection-segment-detail`)
              }
            />
          }
        />
        <Route
          path={`block-topology/centipede-energy-segments`}
          element={
            <EnergySegments
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() =>
                setSelectedPage('centipede-energy-segments')
              }
            />
          }
        />
        <Route
          path={`block-topology/centipede-lineup/:centipedeLineupId/centipede-energy-segment/:energySegmentId`}
          element={
            <CentipedeEnergySegmentDetails
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() =>
                setSelectedPage('centipede-energy-segment-details')
              }
            />
          }
        />
        <Route
          path={`block-topology/ups-list`}
          element={
            <UPSList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('ups-list')}
            />
          }
        />
        <Route
          path={`block-topology/environmental-controllers`}
          element={
            <EnvironmentalControllersList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() =>
                setSelectedPage('environmental-controllers')
              }
            />
          }
        />
        <Route
          path={`block-topology/sensors`}
          element={
            <SensorsList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('sensors')}
            />
          }
        />
        <Route
          path={`block-topology/acBattery/:acBatteryId/pcs/:pcsIndex`}
          element={
            <PCSDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              setSelectedPage={() => setSelectedPage('allPCSes')}
            />
          }
        />
        <Route
          path={`block-topology/string-list`}
          element={
            <StringList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allStrings')}
            />
          }
        />
        <Route
          path={`block-topology/acBattery/:acBatteryId/array/:arrayId/string/:stringID`}
          element={
            <StringDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              setSelectedPage={() => setSelectedPage('allStrings')}
            />
          }
        />
        <Route
          path={`block-topology/acPvBattery/:acPvBatteryIndex/array/:arrayId/string/:stringID`}
          element={
            <StringDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              setSelectedPage={() => setSelectedPage('allACPVBatteryStrings')}
            />
          }
        />
        <Route
          path={`block-topology/array-list`}
          element={
            <ArrayList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allArrays')}
            />
          }
        />
        <Route
          path={`block-topology/acBattery/:acBatteryId/array/:arrayId`}
          element={
            <ArrayDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allArrays')}
            />
          }
        />
        <Route
          path={`block-topology/ac-pv-battery/:acPvBatteryIndex/array/:arrayId`}
          element={
            <ArrayDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allArrays')}
            />
          }
        />
        <Route
          path={`block-topology/acBattery/:acBatteryId`}
          element={
            <ACBatteryDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allAcBatteries')}
            />
          }
        />
        <Route
          path={`block-topology/acPvBattery/:acPvBatteryIndex`}
          element={
            <ACPVBatteryDetails
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('acPvBatteryIndex')}
            />
          }
        />
        <Route
          path={`block-topology/ac-battery-list`}
          element={
            <ACBatteryList
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('allAcBatteries')}
            />
          }
        />
        <Route
          path={`block-topology/hvac-list`}
          element={
            <HvacList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('hvac-list')}
            />
          }
        />
        <Route
          path={`block-topology/stack-managers-list`}
          element={
            <StackManagersList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('stack-managers-list')}
            />
          }
        />
        <Route
          path={`block-topology/eks-ppc-details`}
          element={
            <EksPowerPlantController
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('eks-ppc-details')}
            />
          }
        />
        <Route
          path={`block-topology/block-meter-list`}
          element={
            <BlockMeterList
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('block-meter-list')}
            />
          }
        />
        <Route
          path={`block-topology/block-meter-details/:blockMeterIndex`}
          element={
            <BlockMeterDetails
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('block-meter-details')}
            />
          }
        />
        <Route
          path={`block-topology/eks-pcs-group-list`}
          element={
            userHasApiAccessCode(API_ACCESS_CODES.THREE_DA) ? (
              <EksPcsGroupList
                setSelectedParams={setSelectedParams}
                selectedParams={selectedParams}
                setSelectedPage={() => setSelectedPage('eks-pcs-group-list')}
              />
            ) : (
              <Navigate to={'..'} replace />
            )
          }
        />
        <Route
          path={`block-topology/eks-pcs-group-details/:eksPcsGroupId`}
          element={
            userHasApiAccessCode(API_ACCESS_CODES.THREE_DA) ? (
              <EksPcsGroupDetails
                setSelectedParams={setSelectedParams}
                selectedParams={selectedParams}
                setSelectedPage={() => setSelectedPage('eks-pcs-group-details')}
              />
            ) : (
              <Navigate to={'..'} replace />
            )
          }
        />
        <Route
          path="*"
          element={
            <System
              viewOneline={viewOneline}
              toggleViewOneline={setViewOneline}
              setSelectedParams={setSelectedParams}
              selectedParams={selectedParams}
              setSelectedPage={() => setSelectedPage('systemDetails')}
            />
          }
        />
      </Routes>
    </Container>
  )
}

export default SystemDetails

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
