import _ from 'lodash'

// Style Imports
import * as Styles from './styles'

// Component Imports
import Icon from '../../Icon/Icon'
import HealthStatus from '../../Status/HealthStatus/HealthStatus'

const EquipmentSummaryCard = ({ icon, title, link, isHealthy, rows }) => {
  const renderRows = () => {
    return _.map(rows, (row, i) => {
      return (
        <Styles.SectionRow key={i}>
          <Styles.Label>{row.label}</Styles.Label>
          <Styles.Value>{row.value}</Styles.Value>
        </Styles.SectionRow>
      )
    })
  }

  return (
    <Styles.Container>
      <Icon icon={icon} size="md" />
      <Styles.Column>
        <Styles.Row>
          <Styles.Flex>
            <Styles.Title>{title}</Styles.Title>
            <Styles.DetailLink to={link}>View Details</Styles.DetailLink>
          </Styles.Flex>
          <Styles.HealthIcon>
            <HealthStatus isHealthy={isHealthy} />
          </Styles.HealthIcon>
        </Styles.Row>
        <Styles.Content>{renderRows()}</Styles.Content>
      </Styles.Column>
    </Styles.Container>
  )
}

export default EquipmentSummaryCard
