import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  padding: 0 25px 30px;
`
export const BusBar = styled.div`
  width: 2px;
  height: 50px;
  background-color: #bbb;
  margin-left: 47px;
`
export const InverterContainer = styled.div`
  position: relative;
  padding: 0 22px 15px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 2px;
    top: 10px;
    bottom: 20px;
    left: 47px;
    background-color: ${(props) => (props.isEnabled ? '#00b986' : 'red')};
  }
`
export const InverterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px 10px 27px;
`
export const ArrayContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px dashed #788b95;
  border-radius: 5px;
  margin: 10px 20px 20px;
  padding-top: 35px;
  box-shadow: ${(props) => {
    return props._selected
      ? '0 2px 2px rgba(0, 0, 0, .2), 0 0 0 7px rgba(0, 0, 0, .15)'
      : 'none'
  }};

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 2px;
    top: 15px;
    bottom: 139px;
    left: 25px;
    background-color: ${(props) => (props.isEnabled ? '#00b986' : 'red')};
  }
`
export const ArrayContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 10px 27px;
`
export const StringContainer = styled.div`
  margin-bottom: 30px;
`
export const ACBatteryContainer = styled.div`
  border: 1px dashed #788b95;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-top: 20px;
  padding-top: 15px;
  box-shadow: ${(props) =>
    props._selected
      ? '0 2px 2px rgba(0, 0, 0, .2), 0 0 0 7px rgba(0, 0, 0, .15)'
      : 'none'};
`
export const ComponentLabel = styled(Link)`
  position: absolute;
  display: flex;
  align-items: center;
  top: -16px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props._selected ? '#333' : '#788B95')};
  background-color: #f0f0f5;
  padding: 0 10px;
  fill: ${(props) => (props._selected ? '#333' : '#788B95')};
`
export const ACBatteryLabel = styled(ComponentLabel)`
  left: 25px;
`
export const ArrayLabel = styled(ComponentLabel)`
  left: 5px;
`
export const ComponentLabelIcon = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`
