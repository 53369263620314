import _ from 'lodash'

import ExpandableCard from '../../../../components/Cards/ExpandableCard/ExpandableCard'
import { ReactComponent as AppsIcon } from '../../../../icons/apps-icon.svg'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import StackOsAppsTable from './StackOsAppsTable/StackOsAppsTable'

const StackOsApps = () => {
  const { data, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  return (
    <ExpandableCard
      title="StackOS Apps"
      icon={
        <div style={{ padding: 8 }}>
          <AppsIcon />
        </div>
      }
      summarySections={[
        {
          label: 'Enabled',
          value: `${_.chain(data)
            .get('apps')
            .filter({ enabled: true })
            .value()
            .length.toLocaleString()} / ${_.chain(data)
            .get('apps')
            .toArray()
            .value()
            .length.toLocaleString()}`,
          type: 'value',
        },
      ]}
      isExpanded={true}
    >
      <StackOsAppsTable />
    </ExpandableCard>
  )
}

export default StackOsApps
