import _ from 'lodash'
import AppTooltip from 'components/AppTooltip/AppTooltip'
import { useParams } from 'react-router-dom'

import * as Styles from './styles'

// Component Imports
import LoadingPage from '../../../../../components/Loaders/LoadingPage/LoadingPage'
import PCSNode from '../Nodes/PCSNode/PCSNode'
import StackNode from '../Nodes/StackNode'

// Icon Imports
import { ReactComponent as AcBatteryIcon } from '../../../../../icons/ac-battery-icon.svg'
import { ReactComponent as ArrayIcon } from '../../../../../icons/array-icon.svg'

// API Imports
import useACBatteryDetailsQuery from 'api/useQueryHooks/useACBatteryDetails'
import useArrayByIdQuery from 'api/useQueryHooks/useArrayByIdQuery'

const ACBatteryOneline = () => {
  const params = useParams()
  const { stationCode, acBatteryId, arrayId } = params
  const { data: acBatteryData, isLoading } = useACBatteryDetailsQuery()

  const { data: arrayData, isLoading: isLoadingArray } =
    useArrayByIdQuery(arrayId)

  if (isLoading || isLoadingArray) return <LoadingPage />
  const data = { ...acBatteryData, ...arrayData }
  // useEffect(() => {
  const formattedACBatteryKeyToken = `ACBK-ST:${stationCode}-B:1-A:${acBatteryId}`
  const formattedACBattery = {
    acBatteryIndex: _.get(data, 'acBatteryIndex'),
    array: _.chain(data).get('array').value(),
    pcses: _.chain(data).get('arrayPcses').value(),
    strings: _.chain(data).get('strings').toArray().value(),
    topology: _.find(data.topology, {
      entityKeyToken: formattedACBatteryKeyToken,
    }),
  }
  const selectedACBattery = formattedACBattery
  if (_.isNull(selectedACBattery)) return <LoadingPage />

  const renderPCSes = () => {
    return _.map(selectedACBattery.pcses, (inverter, index) => {
      return <PCSNode data={inverter} key={index} />
    })
  }

  const renderStrings = () => {
    return _.map(selectedACBattery.strings, (s) => {
      return (
        <Styles.StringContainer key={s.stringIndex}>
          <StackNode key={s.stringIndex} data={s} />
        </Styles.StringContainer>
      )
    })
  }

  return (
    <Styles.Container>
      <Styles.BusBar />
      <Styles.ACBatteryContainer
        _selected={!params.pcsIndex && !params.arrayId && !params.stringID}
      >
        <Styles.ACBatteryLabel
          _selected={!params.pcsIndex && !params.arrayId && !params.stringID}
          to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${params.acBatteryId}`}
          data-tooltip-id={'acBatteryTooltip'}
        >
          <Styles.ComponentLabelIcon>
            <AcBatteryIcon />
          </Styles.ComponentLabelIcon>
          AC Battery {selectedACBattery.acBatteryIndex}
          <AppTooltip id="acBatteryTooltip">
            AC Battery {selectedACBattery.acBatteryIndex}
            <br />
            Status:{' '}
            {_.get(selectedACBattery.topology, 'ready') ? 'Ready' : 'Not Ready'}
          </AppTooltip>
        </Styles.ACBatteryLabel>
        <Styles.InverterContainer
          isEnabled={_.chain(selectedACBattery)
            .get('pcses')
            .filter({ outRotation: true, ready: false })
            .isEmpty()
            .value()}
        >
          <Styles.InverterContent>{renderPCSes()}</Styles.InverterContent>
        </Styles.InverterContainer>

        <Styles.ArrayContainer
          isEnabled={_.get(selectedACBattery, 'array.valid')}
          _selected={params.arrayId && !params.stringID}
        >
          <Styles.ArrayLabel
            to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${params.acBatteryId}/array/${params.acBatteryId}`}
            _selected={params.arrayId && !params.stringID}
            data-tooltip-id="arrayTooltip"
          >
            <Styles.ComponentLabelIcon>
              <ArrayIcon />
            </Styles.ComponentLabelIcon>
            Array {selectedACBattery.acBatteryIndex}
          </Styles.ArrayLabel>
          <Styles.ArrayContent>{renderStrings()}</Styles.ArrayContent>
        </Styles.ArrayContainer>
      </Styles.ACBatteryContainer>
    </Styles.Container>
  )
}

export default ACBatteryOneline
