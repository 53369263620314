import styled from 'styled-components/macro'

export const Name = styled.div`
  font-weight: 600;
  color: #333;
`
export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  fill: ${(props) =>
    props.$severity === 1
      ? '#f05946'
      : props.$severity === 2
        ? '#df7017'
        : props.$severity === 3
          ? '#e7c906'
          : '#aaa'};
`
export const Severity = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  line-height: 0.5;
  color: #fff;
  width: 100%;
  text-align: center;
`
