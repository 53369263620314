import Table from 'components/Table/Table/Table'
import { NotificationActions } from './actions/NotificationActions'

export const NotificationsTable = ({ data, filters, onFiltersChange }) => {
  const getRowId = (row) => Number(row.notificationId)
  const formattedDate = (row) =>
    row.timestamp
      ? new Date(row.timestamp).toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      : 'Unknown'
  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.notificationId,
      sortable: true,
      noOmit: true,
      cell: (row) => row.notificationId,
      width: '70px',
    },
    {
      name: 'Entity',
      id: 'entity',
      selector: (row) => row.entityKey,
      sortable: true,
      noOmit: true,
      cell: (row) => row.entityKey,
      width: '150px',
    },
    {
      name: 'Type',
      id: 'type',
      selector: (row) => row.type,
      sortable: true,
      noOmit: false,
      cell: (row) => row.type,
      width: '110px',
    },
    {
      name: 'Subtype',
      id: 'subtype',
      selector: (row) => row.subtype,
      sortable: true,
      noOmit: false,
      cell: (row) => row.subtype,
      width: '110px',
    },
    {
      name: 'Notifications',
      id: 'notification',
      selector: (row) => row.notification,
      sortable: true,
      noOmit: false,
      cell: (row) => row.notification,
      width: '350px',
    },
    {
      name: 'Timestamp',
      id: 'timestamp',
      selector: (row) => row.timestamp,
      sortable: true,
      noOmit: false,
      cell: formattedDate,
      width: '140px',
    },
    {
      name: 'Trigger',
      id: 'trigger',
      selector: (row) => row.triggerValue,
      sortable: true,
      noOmit: false,
      cell: (row) => row.triggerValue,
    },
  ]
  return (
    <Table
      tableId="NotificationsTable"
      data={data}
      columns={columns}
      getRowId={getRowId}
      defaultId="timestamp"
      tableActions={
        <NotificationActions
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      }
      fixedHeader={true}
    />
  )
}
