import styled from 'styled-components/macro'

// --------------------------------------
// Station Details Two Column Page Layout
// --------------------------------------
export const StationDetailsPageLayout = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`
StationDetailsPageLayout.LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 275px;
  border-right: 1px solid #d8d8d8;
  overflow-y: hidden;
`
StationDetailsPageLayout.RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
StationDetailsPageLayout.RightColumnContent = styled.div`
  flex: 1;
  padding: 20px 35px 20px 25px;
  overflow-y: auto;

  > div {
    margin-bottom: 25px;
  }
`
StationDetailsPageLayout.OnelineContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: #f0f0f5;
  box-shadow: inset 3px 3px 4px -3px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`
StationDetailsPageLayout.TableContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  & > div {
    border: none;
    border-radius: 0;
    overflow: hidden;
  }
`
