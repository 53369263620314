import { useParams } from 'react-router-dom'
import Table from 'components/Table/Table/Table'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import * as Styles from '../table.styled'
import { sortMultipleValues } from '../tableUtils'

const EksPcsGroupListTable = ({ fixedHeader = true, data }) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  const getRowId = (row) => Number(row.pcsGroupIndex)

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '60px',
      cell: (row) => (
        <Styles.RowLink
          to={`/block-details/${stationCode}/${blockIndex}/block-topology/eks-pcs-group-details/${row.pcsGroupIndex}`}
        >
          {row.pcsGroupIndex}
        </Styles.RowLink>
      ),
      noOmit: true,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => {
        return (
          <Styles.StatusIcon $isHealthy={row.isHealthy}>
            {row.isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
          </Styles.StatusIcon>
        )
      },
    },
    {
      name: 'In Rot.',
      id: 'rotation',
      selector: (row) => row.pcsModuleInRotationCount,
      sortable: true,
      width: '70px',
      cell: (row) => `${row.pcsModuleInRotationCount}/${row.pcsModuleCount}`,
      noOmit: false,
      omit: false,
    },
    {
      name: 'Total Real Power',
      id: 'totalRealPowerKW',
      selector: (row) => row.acActivePowerSummationkW,
      sortable: true,
      cell: (row) => row.acActivePowerSummationkW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max. Real Power Imported',
      id: 'maxRealPowerImportedKW',
      selector: (row) => row.fullSystemMaxAcActivePowerImportkW,
      sortable: true,
      cell: (row) => row.fullSystemMaxAcActivePowerImportkW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max. Real Power Exported',
      id: 'maxRealPowerExportedKW',
      selector: (row) => row.fullSystemMaxAcActivePowerExportkW,
      sortable: true,
      cell: (row) => row.fullSystemMaxAcActivePowerExportkW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Total Reactive Power',
      id: 'acReactivePowerSummationkVAr',
      selector: (row) => row.acReactivePowerSummationkVAr,
      sortable: true,
      cell: (row) => row.acReactivePowerSummationkVAr + ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max. Reactive Power Imported',
      id: 'fullSystemMaxAcReactivePowerImportkVAr',
      selector: (row) => row.fullSystemMaxAcReactivePowerImportkVAr,
      sortable: true,
      cell: (row) => row.fullSystemMaxAcReactivePowerImportkVAr + ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max. Reactive Power Exported',
      id: 'fullSystemMaxAcReactivePowerExportkVAr',
      selector: (row) => row.fullSystemMaxAcReactivePowerExportkVAr,
      sortable: true,
      cell: (row) => row.fullSystemMaxAcReactivePowerExportkVAr + ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC A > N',
      id: 'acAN',
      selector: () => 'acAN',
      sortFunction: (a, b) =>
        sortMultipleValues(
          [a.avgPhaseVoltageANV, a.phaseCurrentASummationA],
          [b.avgPhaseVoltageANV, b.phaseCurrentASummationA],
        ),
      sortable: true,
      width: '90px',
      cell: (row) =>
        `${row.avgPhaseVoltageANV} V / ${row.phaseCurrentASummationA} A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC B > N',
      id: 'acBN',
      selector: () => 'acBN',
      sortFunction: (a, b) =>
        sortMultipleValues(
          [a.avgPhaseVoltageBNV, a.phaseCurrentBSummationA],
          [b.avgPhaseVoltageBNV, b.phaseCurrentBSummationA],
        ),
      sortable: true,
      width: '90px',
      cell: (row) =>
        `${row.avgPhaseVoltageBNV} V / ${row.phaseCurrentBSummationA} A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC C > N',
      id: 'acCN',
      selector: () => 'acCN',
      sortFunction: (a, b) =>
        sortMultipleValues(
          [a.avgPhaseVoltageCNV, a.phaseCurrentCSummationA],
          [b.avgPhaseVoltageCNV, b.phaseCurrentCSummationA],
        ),
      sortable: true,
      width: '90px',
      cell: (row) =>
        `${row.avgPhaseVoltageCNV} V / ${row.phaseCurrentCSummationA} A`,
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  return (
    <Table
      tableId="EksPcsGroupListTable"
      data={data}
      columns={columns}
      fixedHeader={fixedHeader}
      getRowId={getRowId}
      defaultId="id"
    />
  )
}

export default EksPcsGroupListTable
