import {
  FormCancelButton,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { ErrorText, FooterContainer } from './styles'

export const ModalFooter = ({
  disableSubmit,
  hasSubmitError,
  onCancel,
  formName,
}) => (
  <FooterContainer>
    {hasSubmitError && (
      <ErrorText>Something went wrong, please try again.</ErrorText>
    )}
    <FormCancelButton onClick={() => onCancel()}>Cancel</FormCancelButton>
    <FormSubmitButton
      type="submit"
      form={formName}
      disabled={disableSubmit}
      $isValid={!disableSubmit}
    >
      Set
    </FormSubmitButton>
  </FooterContainer>
)
