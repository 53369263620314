import CommandModalTemplate from '../CommandModalTemplate'
import { ClearDeviceFaultsUi } from './ClearDeviceFaultsUi'
import { ClearDeviceFaultsResult } from './ClearDeviceFaultsResult'

const modalTitles = {
  start: 'Clear Faults',
  finish: 'Clear Faults',
}
const queryName = 'clearFaults'

export const ClearDeviceAlertsModal = ({
  faultingDevices,
  deviceType,
  setShowCommandModal,
}) => {
  return (
    <CommandModalTemplate
      ids={{ faultingDevices, deviceType }}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      CommandResultJsx={() => (
        <ClearDeviceFaultsResult setShowCommandModal={setShowCommandModal} />
      )}
      UserInputFormJsx={() => (
        <ClearDeviceFaultsUi
          faultingDevices={faultingDevices}
          deviceType={deviceType}
        />
      )}
      queryName={queryName}
    />
  )
}
