import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import useOutdoorEnvironmentQuery from 'api/useQueryHooks/useOutdoorEnvironmentQuery'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

const BlockMeterListSummary = ({ data, enclosureType }) => {
  const { data: outdoorData, isLoading: isLoadingOutdoorData } =
    useOutdoorEnvironmentQuery(!!data)
  if (!data) return null

  const outdoorTemp = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalAirTemp, '° C')
  const outdoorHumidity = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalHumidity, '%')

  const healthyCount = data.filter((obj) => obj.isHealthy === true).length
  const unhealthyCount = data.filter((obj) => obj.isHealthy === false).length

  const sections =
    enclosureType === 'CENTIPEDE_BASED'
      ? [
          {
            sectionTitle: 'ENVIRONMENT',
            rows: [
              {
                label: 'Air Temp',
                value: outdoorTemp.toLocaleString(),
              },
              {
                label: 'Humidity',
                value: outdoorHumidity.toLocaleString(),
              },
            ],
          },
        ]
      : ''

  const summaryMap = {
    title: `Block Meters`,
    titleIcon: <BlockMeterIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy Block Meters: ${healthyCount} | Unhealthy Block Meters: ${unhealthyCount}`}
        id="blockMetersHealth"
      />
    ),
    sections: sections,
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default BlockMeterListSummary
