import { StationDetailsPageLayout } from 'components/Layout'
import { Toolbar, ToolbarSection } from 'components/Toolbar'
import Breadcrumbs from '../../SystemDetailsBreadcrumbs/Breadcrumbs'
import OnelineToggle from '../../OnelineToggle/OnelineToggle'

const DetailsPage = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  summaryColumnComponent,
  onelineComponent,
  mainColumnComponent,
}) => {
  return (
    <StationDetailsPageLayout>
      <StationDetailsPageLayout.LeftColumn>
        {summaryColumnComponent}
      </StationDetailsPageLayout.LeftColumn>

      <StationDetailsPageLayout.RightColumn>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <ToolbarSection>
            <Breadcrumbs selectedParams={selectedParams} />
          </ToolbarSection>
          {onelineComponent && (
            <ToolbarSection>
              <OnelineToggle
                view={viewOneline}
                toggleView={toggleViewOneline}
              />
            </ToolbarSection>
          )}
        </Toolbar>

        {viewOneline ? (
          <StationDetailsPageLayout.OnelineContainer>
            {onelineComponent}
          </StationDetailsPageLayout.OnelineContainer>
        ) : (
          <StationDetailsPageLayout.RightColumnContent>
            {mainColumnComponent}
          </StationDetailsPageLayout.RightColumnContent>
        )}
      </StationDetailsPageLayout.RightColumn>
    </StationDetailsPageLayout>
  )
}

export default DetailsPage
