import React from 'react'
import _ from 'lodash'
import { Link, useParams } from 'react-router-dom'

import * as Styles from './styles'

// Icon Imports
import { ReactComponent as StringIcon } from '../../../../../../icons/string-icon.svg'

const StackNode = ({ data }) => {
  const params = useParams()

  const contactorsHealthy =
    _.isEqual(data.contactorsCloseExpected, data.negativeContactorClosed) &&
    _.isEqual(data.contactorsCloseExpected, data.positiveContactorClosed)
  const contactorsClosed =
    data.positiveContactorClosed && data.negativeContactorClosed

  const isSelected =
    params.stringID && Number(params.stringID) === data.stringIndex
  return (
    <Styles.Container $isEnabled={data.valid}>
      <Styles.BusBar />
      <Styles.ContactorBar
        $contactorsHealthy={contactorsHealthy}
        $contactorsClosed={contactorsClosed}
      />
      <Link
        to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${data.arrayIndex}/array/${data.arrayIndex}/string/${data.stringIndex}`}
      >
        <Styles.Tile
          $isHealthy={data.stringEnabled}
          $isSelected={isSelected}
          data-tooltip-id={`string${data.stringIndex}Tooltip`}
        >
          <Styles.Flex>
            <Styles.TitleRow>
              <Styles.Icon>
                <StringIcon />
              </Styles.Icon>
              <Styles.Title>
                {data.arrayIndex}::{data.stringIndex}
              </Styles.Title>
            </Styles.TitleRow>
            <Styles.Row>
              <Styles.Label>SoC:</Styles.Label>
              <Styles.Value>
                {_.round(data.soc, 1)}% <Styles.SocBar $soc={data.soc} />
              </Styles.Value>
            </Styles.Row>
          </Styles.Flex>
          {!data.stringEnabled && (
            <Styles.AlertBanner>Disabled</Styles.AlertBanner>
          )}
        </Styles.Tile>
      </Link>
    </Styles.Container>
  )
}

export default StackNode
