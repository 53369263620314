import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'
import Card from 'components/Cards/Card/Card'

import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import { RangePowerChart } from 'modules/Station/components/Charts/RangePowerChart/RangePowerChart'
import { COLORS } from 'design_system/colors'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useBlockMeterDetailsGraphQuery from 'api/useQueryHooks/useBlockMeterDetailsGraphQuery'

const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
`

const BlockMeterDetailsMainColumn = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { data, refetch, isLoading } =
    useBlockMeterDetailsGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  if (isLoading) return <LoadingPage />

  return (
    <Card
      title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
    >
      <GraphsWrapper>
        <RangePowerChart
          color={COLORS.charts.blue}
          title="Real Power"
          tooltip="Power"
          unit="kW"
          data={data.realPowerKW.series}
          period={graphPeriod}
          showXAxis={false}
        />
        <RangePowerChart
          color={COLORS.charts.orange}
          title="Reactive Power"
          tooltip="Power"
          unit="kVAr"
          data={data.reactivePowerKVAR.series}
          period={graphPeriod}
          showXAxis={false}
        />
        <RangePowerChart
          color={COLORS.charts.shamrock_green}
          title="Apparent Power"
          tooltip="Power"
          unit="kVA"
          data={data.apparentPowerKVA.series}
          period={graphPeriod}
          showXAxis={false}
        />
        <RangePowerChart
          color={COLORS.charts.brink_pink}
          title="Current"
          tooltip="Current"
          unit="A"
          data={data.currentAmp.series}
          period={graphPeriod}
        />
      </GraphsWrapper>
    </Card>
  )
}

export default BlockMeterDetailsMainColumn
