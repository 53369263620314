import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as DcDcConverterIcon } from 'icons/dc-dc-converter-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'

const DcDcPcsSummary = ({ data }) => {
  const count = data.length
  const inRotationCount = data.filter((p) => !p.outRotation).length
  const healthyCount = data.filter((p) => p.healthy && !p.faults.length).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `DC:DC PCSes`,
    titleIcon: <DcDcConverterIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy DC:DC PCSes: ${healthyCount} | Unhealthy DC:DC PCSes: ${unhealthyCount}`}
        id="dcDcPCSHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'In Rotation',
            value: inRotationCount.toLocaleString(),
          },
          {
            label: 'Ready',
            value: healthyCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default DcDcPcsSummary
