import { EksPpcUtility } from '../EksPpcUtility/EksPpcUtility'
import { RowHeader } from 'modules/Station/modules/SystemDetails/System/MainColumn/styles'
import useEksPpcDetailsQuery from 'api/useQueryHooks/useEksPpcDetailsQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

export const Utilities = () => {
  const { data, isLoading } = useEksPpcDetailsQuery()

  if (isLoading) return <LoadingPage />

  if (!data?.ppcInstalled) return null

  return (
    <>
      <RowHeader>Utilities</RowHeader>
      <EksPpcUtility />
    </>
  )
}
