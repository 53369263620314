import _ from 'lodash'
import * as Styles from './styles'
import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/sizes'
import { Typography } from 'components/typography/Typography.styled'
import { Accordion } from 'components/accordion'

/**
 * section titles can have icons
 * add key 'sectionTitleIcon' to a section with icon, value = <MyIcon />
 *
 * row can have icons , set row.isIcon to true
 * @param {Array}
 * @returns
 */
const SummaryColumn = ({
  summaryMap,
  accordionSections = [],
  expandedSections,
  accordionHandler,
  width,
}) => {
  const renderSections = () =>
    _.map(summaryMap.sections, (s, i) => {
      if (accordionSections.includes(s.sectionTitle)) {
        return (
          <div key={i} style={{ marginTop: '25px' }}>
            <Accordion
              key={i}
              expanded={expandedSections[s.sectionTitle]}
              onChange={() => accordionHandler(s.sectionTitle)}
              summary={
                <Styles.SectionTitle $isAccordion={true}>
                  {s.sectionTitleIcon && (
                    <Styles.Icon>{s.sectionTitleIcon}</Styles.Icon>
                  )}

                  <Typography $strong>{s.sectionTitle}</Typography>
                </Styles.SectionTitle>
              }
            >
              {renderSectionRow(s.rows)}
            </Accordion>
          </div>
        )
      }
      return (
        <Styles.Section key={i}>
          <Styles.SectionTitle $isAccordion={false}>
            {s.sectionTitleIcon && (
              <Styles.Icon>{s.sectionTitleIcon}</Styles.Icon>
            )}

            <Typography $strong>{s.sectionTitle}</Typography>
          </Styles.SectionTitle>

          {renderSectionRow(s.rows)}
        </Styles.Section>
      )
    })

  const renderSectionRow = (rows) =>
    _.map(rows, (r, i) => {
      return (
        <Styles.SectionRow key={i}>
          <Typography
            $fontColor={COLORS.font_secondary}
            $strong
            $width={r.label === 'Role' ? '' : width}
          >
            {r.label}
          </Typography>
          {r.isIcon ? (
            <Styles.Icon $doubleIcon={r.doubleIcon}>{r.value}</Styles.Icon>
          ) : (
            <Styles.Value $isAlignedEnd={true} $bold>
              {r.value}
            </Styles.Value>
          )}
        </Styles.SectionRow>
      )
    })

  return (
    <Styles.Container>
      <Styles.TitleRow>
        <Styles.TitleIcon>{summaryMap.titleIcon}</Styles.TitleIcon>
        <Styles.Flex>
          <Typography $fontSize={FONT_SIZES.xxl} $bold>
            {summaryMap.title}
          </Typography>
          {summaryMap.titleChart && (
            <Styles.Flex>{summaryMap.titleChart}</Styles.Flex>
          )}
        </Styles.Flex>
      </Styles.TitleRow>
      {summaryMap.subtitle && (
        <Styles.Subtitle>
          <Typography $fontSize={FONT_SIZES.large} $strong>
            {summaryMap.subtitle}
          </Typography>
        </Styles.Subtitle>
      )}
      <Styles.ScrollContent>{renderSections()}</Styles.ScrollContent>
    </Styles.Container>
  )
}

export default SummaryColumn
