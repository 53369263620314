import { useContext } from 'react'
import Table from 'components/Table/Table/Table'
import { getDeadband, getTargetmV } from '../tableUtils'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'
import { AuthorizationContext } from 'contexts/authorizations.context'

const PackBalancingTable = (data) => {
  const tableReadyData = []
  data.data
    ? Object.entries(data.data.balanceStatuses).forEach(([key, val]) => {
        tableReadyData.push({ ...val, packIndex: key })
      })
    : null

  const { hasEnabledFlags } = useContext(AuthorizationContext)

  const isBalancingCellIdEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.CELL_GROUP_NOTIFICATION_EVENT,
  )

  const getBalancingSource = (row) => {
    let resp = '-'
    if (row.balancingMode === 'NO_BALANCE') return resp
    switch (row.balancingSource) {
      case 'MANUAL':
        resp = 'Manual'
        break
      case 'AUTO':
        resp = 'Auto'
        break
      case 'APP':
        resp = 'App'
        break
      case 'UNRECOGNIZED':
        resp = 'Unrecognized'
        break
      default:
        resp = 'Unknown'
    }
    return resp
  }

  const getBalancingMode = (row) => {
    let resp = 'Unknown'
    switch (row.balancingMode) {
      case 'NO_BALANCE':
        resp = 'Not Balancing'
        break
      case 'BALANCE_TO_AVERAGE':
        resp = 'Average'
        break
      case 'BALANCE_TO_PROVIDED':
        resp = 'Target'
        break
      default:
      // should not happen, but we do have some deprecated balance modes
    }
    return resp
  }

  const getBalancingCellGroupId = (row) => {
    if (row.balancingMode === 'NO_BALANCE' || row.balancingState === 'NOT')
      return '-'
    return row.balancingCellGroupIndex
  }

  const getRowId = (row) => Number(row.packIndex)

  const columns = [
    {
      name: 'BP ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: getRowId,
      sortFunction: (a, b) => a.packIndex - b.packIndex,
    },

    {
      name: 'BALANCING',
      id: 'source',
      selector: (row) => row.balancingSource,
      sortable: true,
      width: '140px',
      cell: (row) => getBalancingSource(row),
      noOmit: false,
      omit: false,
    },
    {
      name: 'BALANCE MODE',
      id: 'mode',
      selector: (row) => row.balancingMode,
      sortable: true,
      width: '120px',
      cell: (row) => getBalancingMode(row),
      noOmit: false,
      omit: false,
    },
    {
      name: 'CHARGE DB',
      id: 'chargedb',
      selector: (row) => row.chargeDeadband,
      sortable: true,
      width: '100px',
      cell: (row) => getDeadband(row, true),
      noOmit: false,
      omit: false,
    },
    {
      name: 'DISCHARGE DB',
      id: 'dischargedb',
      selector: (row) => row.dischargeDeadband,
      sortable: true,
      width: '110px',
      cell: (row) => getDeadband(row, false),
      noOmit: false,
      omit: false,
    },
    {
      name: 'BALANCE TARGET',
      id: 'balanceTarget',
      selector: (row) => row.targetMillivolts,
      sortable: true,
      width: '120px',
      cell: (row) => getTargetmV(row),
      noOmit: false,
      omit: false,
    },
    {
      name: 'BALANCING CG ID',
      id: 'balancingCellId',
      selector: (row) => row.balancingCellGroupIndex,
      sortable: true,
      width: '130px',
      cell: getBalancingCellGroupId,
      noOmit: false,
      omit: !isBalancingCellIdEnabled,
    },
  ]

  return (
    <Table
      tableId="PackBalancingTable"
      data={tableReadyData}
      columns={columns}
      getRowId={getRowId}
      fixedHeader={false}
      pagination={false}
      controls={false}
      defaultId="id"
    />
  )
}

export default PackBalancingTable
