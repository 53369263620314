import { FC } from 'react'
import { AppStatusForm } from './form'
import { AppStatusModalProps } from './app-status'
import { AppStatusResult } from './result'
import CommandModalTemplate from '../CommandModalTemplate'

export const AppStatusModal: FC<AppStatusModalProps> = (props) => {
  const { ids, setShowCommandModal } = props
  const modalTitles = {
    start: 'Set App Status',
    finish: 'Set App Status',
  }
  const queryName = 'setAppStatus'

  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={{ enabled: 'choose' }}
      CommandResultJsx={AppStatusResult}
      UserInputFormJsx={AppStatusForm}
      queryName={queryName}
      isFormValidated={true}
    />
  )
}
