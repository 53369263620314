import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/sizes'
import { FONT_WEIGHT } from 'design_system/font_definitions'

const DEFAULT_PROPS = {
  fontColor: COLORS.font_primary,
  fontSize: FONT_SIZES.normal,
  margin: '0',
}

export const Typography = styled.p`
  width: ${(props) => (props.$width ? props.$width : '')};
  font-size: ${({ $fontSize = DEFAULT_PROPS.fontSize }) => $fontSize};
  color: ${({ $fontColor = DEFAULT_PROPS.fontColor }) => $fontColor};
  margin: ${({ $margin = DEFAULT_PROPS.margin }) => $margin};
  font-weight: ${({ $bold, $strong }) =>
    $bold
      ? FONT_WEIGHT.bold
      : $strong
      ? FONT_WEIGHT.strong
      : FONT_WEIGHT.normal};
  text-align: ${(props) => (props.$isAlignedEnd ? 'end' : '')};
`
