import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { ReactComponent as ArrayIcon } from 'icons/array-icon.svg'
import HealthStatus from 'components/Status/HealthStatus/HealthStatus'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import useArrayByIdQuery from 'api/useQueryHooks/useArrayByIdQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const ArrayDetailsSummary = () => {
  const { acBatteryId, arrayId, acPvBatteryIndex } = useParams()
  const { data, isLoading } = useArrayByIdQuery(arrayId)
  if (isLoading) return <LoadingPage />

  const isAllStringsOutOfRotation = Object.values(data.strings).every(
    (s) => s.outRotation === true,
  )
  const _title = acBatteryId
    ? `Array ${acBatteryId} :: ${arrayId}`
    : `Array ${acPvBatteryIndex} :: ${arrayId}`
  const summaryMap = {
    title: _title,
    titleIcon: <ArrayIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: (
              <HealthStatus
                isHealthy={_.get(data, 'valid') && !isAllStringsOutOfRotation}
              />
            ),
            isIcon: true,
          },
          {
            label: 'DC Bus Voltage',
            value: `${_.chain(data)
              .get('dcBusVoltage', 'N/A')
              .round()
              .value()
              .toLocaleString()} V`,
          },
          {
            label: 'DC Current',
            value: `${_.chain(data)
              .get('dcAmps', 'N/A')
              .round()
              .value()
              .toLocaleString()} A`,
          },
          {
            label: 'DC Power',
            value: `${_.chain(data)
              .get('dcKW', 'N/A')
              .round()
              .value()
              .toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Availability',
        rows: [
          {
            label: 'Online Energy',
            value: `${_.chain(data)
              .get('dcPowerAndEnergy.onlineAvailableKWH', 'N/A')
              .round()
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'Energy Capacity',
            value: `${_.chain(data)
              .get('dcPowerAndEnergy.totalCapacityKWH', 'N/A')
              .round()
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'DC Charge Power',
            value: `${_.chain(data)
              .get('dcPowerAndEnergy.maxChargePowerKW', 'N/A')
              .round()
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'DC Discharge Power',
            value: `${_.chain(data)
              .get('dcPowerAndEnergy.maxDischargePowerKW', 'N/A')
              .round()
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'Online SoC',
            value: `${_.chain(data)
              .get('dcPowerAndEnergy.onlineSocPercent', 'N/A')
              .round()
              .value()
              .toLocaleString()}%`,
          },
        ],
      },
      {
        sectionTitle: 'Preferred Current',
        rows: [
          {
            label: 'Charge',
            value: _.chain(data)
              .thru((obj) => {
                if (!obj.preferredChargeCurrentChecked) {
                  return 'Manager Disabled'
                } else if (!obj.hasPreferredChargeCurrent) {
                  return 'None'
                } else {
                  const roundedValue = _.chain(obj.preferredChargeCurrent)
                    .round()
                    .value()
                  return `${roundedValue.toLocaleString()} A`
                }
              })
              .value()
              .toLocaleString(),
          },
          {
            label: 'Discharge',
            value: _.chain(data)
              .thru((obj) => {
                if (!obj.preferredDischargeCurrentChecked) {
                  return 'Manager Disabled'
                } else if (!obj.hasPreferredDischargeCurrent) {
                  return 'None'
                } else {
                  const roundedValue = _.chain(obj.preferredDischargeCurrent)
                    .round()
                    .value()
                  return `${roundedValue.toLocaleString()} A`
                }
              })
              .value()
              .toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ArrayDetailsSummary
