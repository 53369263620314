import axios from 'axios'
import { setAppStatusProps } from './app'

export const setAppStatus: (props: setAppStatusProps) => any = async ({
  stationCode,
  blockIndex,
  session,
  ids,
  userInputForm,
}) => {
  try {
    const apiUrl = session.server
    const enableOrDisable =
      userInputForm.enabled === 'true' ? 'enable' : 'disable'
    const calls = ids.map((id) => {
      const idParts = id.split(':')
      const appCode = idParts[0]
      const appPriority = idParts[1]

      return axios.put(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/appcode/${appCode}/priority/${appPriority}/${enableOrDisable}`,
        {},
        {
          headers: {
            Accept: 'application/json',
            papitoken: session.token,
          },
        },
      )
    })

    const responses = await axios.all(calls)
    const anyFailures =
      responses.find((response) => response && response.data.valid !== true) !==
      undefined

    if (anyFailures) {
      // eslint-disable-next-line no-console
      console.log('setAppStatusService() failed', responses)
      return {
        status: 'error',
        message: 'setAppStatusService() failed',
        data: responses,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (error: any) {
    return { status: 'error', message: error.message }
  }
}
