import _ from 'lodash'

// Style Imports
import * as Styles from './styles'

// Component Imports
import ToolbarDropdown from '../ToolbarDropdown/ToolbarDropdown'

const ToolbarToggleFilter = ({ title, options, selected, setSelected }) => {
  const renderOptions = () => {
    return _.map(options, (o) => {
      return (
        <Styles.LabelRow key={o.label}>
          <input
            type="radio"
            value={o.value}
            checked={o.value === selected}
            onChange={(e) => setSelected(e.target.value)}
          />
          <Styles.Label $isSelected={o.value === selected}>
            {o.label}
          </Styles.Label>
        </Styles.LabelRow>
      )
    })
  }

  return (
    <ToolbarDropdown
      title={title}
      titleValue={_.find(options, { value: selected }).label}
    >
      {renderOptions()}
    </ToolbarDropdown>
  )
}

export default ToolbarToggleFilter
