import styled from 'styled-components/macro'

export const EllipsesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${(props) => (props.$isOpen ? '#cdcdcd ' : 'transparent')};
  border-radius: 5px;
  background: ${(props) => (props.$isOpen ? '#d8d8d8 ' : 'none')};
  width: 33px;
  height: 25px;
  cursor: pointer;
  &:hover {
    border: 1px solid;
    border-color: #40a3d5;
  }
`

export const ModalHeader = styled.p`
  font-size: 11px;
  color: #a9a9a9;
  font-weight: 600;
`
