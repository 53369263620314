import axios from 'axios'

export enum ResourceEnum {
  inverter = 'pcs',
  string = 'string',
}

export enum ExcusedEnum {
  excused = 'excusedrotation',
  non_excused = 'nonexcusedrotation',
}

export type ResourceType = keyof typeof ResourceEnum
export type ExcusedType = keyof typeof ExcusedEnum

export const fetchRotationReasons = async (
  resource: ResourceEnum,
  type: ExcusedEnum,
  session: any,
): Promise<string[]> => {
  try {
    const apiUrl = session.server
    const axiosOptions = {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    }
    const response = await axios.get(
      `${apiUrl}${resource}/reasons/${type}`,
      axiosOptions,
    )

    return response.data ? Object.values(response.data) : []
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Dictionaries-fetchRotationReasons] Error: ', error)
    return []
  }
}
