import Highcharts from 'highcharts/highstock'
import highchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import { COLORS } from 'design_system/colors'
import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './RangePowerChart.styles'
import * as dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import * as _ from 'lodash'
dayjs.extend(LocalizedFormat)

const tooltipFormatter = (unit) =>
  function (_tooltip, x = this.x, y = this.y, points = this.points) {
    const point = `<span style="color:${points[0].color}">●</span>`
    const dateTime = `<span style="font-size: 10px">
    ${dayjs(x).format('dddd, MMM D, YYYY, HH:mm:ss')}
  </span>`
    return `
          ${dateTime} <br />

          ${point}
          <span>
            ${points[0].series.name}: <b>${y.toFixed(2)} ${unit}</b>
          </span> <br />

          ${point}
          <span>
            ${points[1].series.name}: 
              <b>${points[1].point.low.toFixed(2)} ${unit} - 
              ${points[1].point.high.toFixed(2)} ${unit}</b>
          </span>`
  }

export const RangePowerChart = ({
  data,
  color,
  title,
  tooltip,
  unit,
  period = '1m',
  showXAxis = true,
}) => {
  highchartsMore(Highcharts)
  let isXAxisFirstPoint = true
  const chartColor = color
  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      height: 250,
      zooming: {
        mouseWheel: false,
      },
    },
    credits: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: unit,
      formatter: tooltipFormatter(unit),
    },
    plotOptions: {
      series: {
        pointStart: data[0]?.time,
        pointInterval: data[1]?.time - data[0]?.time,
      },
    },
    xAxis: {
      visible: showXAxis,
      type: 'datetime',
      labels: {
        formatter: (_val) => {
          if (period === '1w') {
            return `<span style="font-size: 10px">
            ${dayjs(_val.value).format('MMM D')}
          </span>`
          }
          if (isXAxisFirstPoint) {
            const dateTime = `<span style="font-size: 10px">
            ${dayjs(_val.value).format('HH:mm:ss<br />MMM D, Y')}
          </span>`
            isXAxisFirstPoint = false
            return `${dateTime}`
          }
          const dateTime = `<span style="font-size: 10px">
            ${dayjs(_val.value).format('HH:mm:ss')}
          </span>`
          return `${dateTime}`
        },
      },
    },
    yAxis: {
      title: {
        text: unit,
        style: {
          color: COLORS.font_secondary,
        },
      },
      labels: {
        formatter: (_val) => _val.value.toFixed(2),
      },
    },
    series: [
      {
        name: 'Average ' + tooltip,
        data: data?.map((data) => (data.hasData ? [data.avg] : '')),
        color: chartColor,
        zIndex: 1,
        yAxis: 0,
        marker: {
          fillColor: 'white',
          enabled: false,
          lineWidth: 2,
          lineColor: chartColor,
        },
      },
      {
        name: 'Range ' + tooltip,
        data: data?.map((data) => (data.hasData ? [data.min, data.max] : '')),
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: chartColor,
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ],
  }

  return (
    <div>
      <Styles.GraphTitleWrapper>
        <Styles.Square color={chartColor} />
        <Typography $strong>{title}</Typography>
      </Styles.GraphTitleWrapper>
      <HighchartsReact highcharts={Highcharts} options={options} oneToOne />
    </div>
  )
}
