import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * load electrical devices for station
 * @typedef {import('@tanstack/react-query').UseQueryOptions} UseQueryOptions
 * @param {UseQueryOptions} options
 * @returns UseQueryResult
 */
const useElectricalDevicesQuery = (options = {}) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    ['electricalDevices', stationCode, blockIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/electricaldevices`,
        axiosOptions,
      )
      return response.data
    },
    { refetchInterval: 5000, ...options },
  )
}

export default useElectricalDevicesQuery
