export function buildYAxis(min, max) {
  return [
    {
      title: {
        text: undefined,
      },
      opposite: false,
      labels: {
        format: '{text} °C',
      },
      tickAmount: 7,
      plotLines: [
        {
          value: min ?? 0,
          color: 'red',
          dashStyle: 'solid',
          width: 1,
          zIndex: 3,
          label: {
            text: 'Min. Operating Temperature',
            align: 'right',
            style: {
              color: '#555',
              fontSize: 10,
            },
          },
        },
        {
          value: max ?? 55,
          color: 'red',
          dashStyle: 'solid',
          width: 1,
          zIndex: 3,
          label: {
            text: 'Max. Operating Temperature',
            style: {
              color: '#555',
              fontSize: 10,
            },
          },
        },
      ],
    },
  ]
}
