import ActionMenuContent from '../../Commands/ClearEnclosureFaults/ActionMenuModal/ActionMenuContent'
import * as TableStyles from '../table.styled'
import { fieldOpacity, getSensorsStatus } from '../tableUtils'
import * as Styles from './styles'
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'

export const IdCell = (row, params) => (
  <TableStyles.RowLink
    to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/centipede-lineup/${row.groupIndex}/collection-segment/${row.enclosureIndex}`}
  >
    {row.enclosureIndex}
  </TableStyles.RowLink>
)

export const getRowId = (row) => Number(row.enclosureIndex)

export const ActionColumnMenu = (
  row,
  setShowCommandModal,
  actionModalDispatch,
  params,
) => {
  let isClearable = false
  Object.keys(row.sensorCounts).forEach((sensor) => {
    if (row.sensorCounts[sensor].readyToClear > 0) isClearable = true
  })
  if (isClearable) {
    return (
      <Styles.Container>
        <ActionsMenu>
          <ActionMenuContent
            setShowCommandModal={setShowCommandModal}
            handleActionSelection={actionModalDispatch}
            stationCode={params.stationCode}
            blockIndex={params.blockIndex}
            target="ENCLOSURE"
            enclosureIndex={row.enclosureIndex}
          />
        </ActionsMenu>
      </Styles.Container>
    )
  }
  return <Styles.Container />
}

export const findSensor = (row, sensor) =>
  fieldOpacity(
    row.envControllersNonHealthy,
    getSensorsStatus(row.sensorCounts[sensor]),
  )

export const omitTest = (row, sensorName) => {
  return (
    !row.sensorCounts[sensorName] || row.sensorCounts[sensorName].total === 0
  )
}
