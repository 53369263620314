function provideDownload(filename: string, data: any): any {
  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', data)
  linkElement.setAttribute('download', filename)
  document.body.appendChild(linkElement)
  linkElement.click()
  linkElement.remove()
}

/**
 * Given filename and data will immediately download to users computer
 * @param filename
 * @param data
 */
export function provideBlobDownload(filename: string, data: any): any {
  provideDownload(filename, window.URL.createObjectURL(new Blob([data])))
}
