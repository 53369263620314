import { FC } from 'react'
import { COLORS } from 'design_system/colors'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as ContactorClosedIcon } from 'icons/contactor-closed-icon.svg'
import { ResultTdProps } from './result'
import Icon from 'components/Icon/Icon'
import ContactorState from 'api/types/ContactorState'

export const ResultTd: FC<ResultTdProps> = (props) => {
  const { userInputForm } = props
  return (
    <>
      {userInputForm.contactorState === ContactorState.CLOSED ? (
        <Icon
          size="xs"
          color={COLORS.powin_green}
          icon={<ContactorClosedIcon />}
        />
      ) : (
        <Icon size="xs" color={COLORS.powin_red} icon={<ContactorOpenIcon />} />
      )}
    </>
  )
}
