/**
 * format numbers by rounding to the specified decimal place and adding units, or returning 'N/A' for null or undefined
 */
export const formatNumber = ({
  value,
  precision = 0,
  unit = '',
  commas = true,
}) => {
  if (typeof value === 'number') {
    if (commas === true) {
      return `${(
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
      ).toLocaleString()}${unit}`
    } else {
      return `${
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
      }${unit}`
    }
  }
  return 'N/A'
}

export const formattedDate = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}
