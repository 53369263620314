import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'
import ArrayListSummary from './Summary/ArrayListSummary'
import ArrayTable from 'modules/Station/components/Tables/ArrayTable/ArrayTable'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const ArrayList = ({ selectedParams, setSelectedParams, setSelectedPage }) => {
  const { data: blockData, isLoading } = useBlockStatus()
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'array-list' })
    document.title = `${stationCode}::${blockIndex} - Arrays`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <LoadingPage />

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<ArrayListSummary />}
      tableComponent={
        <ArrayTable data={blockData?.arrays ?? []} fixedHeader={true} />
      }
    />
  )
}

export default ArrayList
