import axios from 'axios'

export const setPvPcsRotationService = async (
  stationCode,
  blockIndex,
  ids,
  rotationInfo,
  session,
) => {
  try {
    const apiUrl = session.server
    const command =
      rotationInfo.outRotation === 'true' ? 'OUT_ROTATION' : 'IN_ROTATION'

    const payload =
      command === 'IN_ROTATION'
        ? {
            action: command,
            keys: ids,
          }
        : {
            action: command,
            keys: ids,
            excused: rotationInfo.excused === 'true' ? true : false,
            reason: rotationInfo.reason,
            explanation: rotationInfo.explanation,
          }

    const response = await axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/pvpcses/rotation`,
      payload,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )

    if (!response.data.valid) {
      return {
        status: 'error',
        message: 'PV PCS rotation failed.',
        data: response,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (error) {
    return { status: 'error', message: error.message }
  }
}
