import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DetailsPage from '../../../components/Layouts/DetailsPage/DetailsPage'
import StringDetailsSummary from './Summary/StringDetailsSummary'
import StringDetailsMainColumn from './MainColumn/StringDetailsMainColumn'
import ACBatteryOneline from '../../../components/Onelines/ACBatteryOneline/ACBatteryOneline'

const StringDetails = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acBatteryId, stringID } = params

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - String ${acBatteryId}:${stringID}`
    setSelectedParams({ ...params, listView: 'string-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<StringDetailsSummary />}
      onelineComponent={<ACBatteryOneline />}
      mainColumnComponent={<StringDetailsMainColumn />}
    />
  )
}

export default StringDetails
