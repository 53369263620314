import styled from 'styled-components/macro'
import { COLORS } from './colors'

/** @deprecated use typography.ts instead  */
export const FONT_WEIGHT = {
  normal: 400,
  strong: 500,
  bold: 600,
}

const Action_ModalHeader_p = styled.div`
  font-size: 11px;
  color: ${COLORS.column_header_font_color};
  text-transform: uppercase;
  font-weight: 500;
`

const Action_Modal_TextArea = styled.textarea`
  font-weight: 600;
  width: 100%;
  height: 50px;
  vertical-align: top;
  border-radius: 5px;
`

const Action_Modal_Input = styled.input`
  font-weight: 600;
  width: ${(props) => (props.width ? props.width : '100%')};
  vertical-align: top;
  border-radius: 5px;
  type: ${(props) => (props.type === 'number' ? 'number' : '')};
  margin-bottom: 20px;
`

export const FONT_STYLES = {
  ActionModalHeaderP: Action_ModalHeader_p,
  ActionModalTextArea: Action_Modal_TextArea,
  ActionModalInput: Action_Modal_Input,
}
