import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from '../../../components/Layouts/ListPage/ListPage'
import EnclosureSummary from './Summary/EnclosureSummary'
import EnclosureTable from '../../../components/Tables/EnclosureTable/EnclsosureTable'

const EnclosureList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'enclosure-list' })
    document.title = `${stationCode}::${blockIndex} - Enclosures`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<EnclosureSummary />}
      tableComponent={<EnclosureTable fixedHeader={true} />}
    />
  )
}

export default EnclosureList
