import Icon from 'components/Icon/Icon'
import { ActionModalSection, MenuIcon } from '../../Tables/table.styled'
import { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { COLORS } from 'design_system/colors'
import { ReactComponent as LightningIcon } from 'icons/lightning-icon.svg'
import { actions } from './action-modal-reducer'

function ActionMenuContent({
  stationCode,
  blockIndex,
  hasFaults,
  handleActionSelection,
  setShowCommandModal,
  faultingDevices,
  closeActionModal,
  deviceType,
}) {
  const handleClearFaultsAction = () => {
    closeActionModal?.()
    handleActionSelection({
      type: actions.SHOW_FAULT_CLEAR_MODAL,
      stationCode,
      blockIndex,
      faultingDevices,
      setShowCommandModal,
      deviceType,
    })
  }
  return (
    <ActionModalSection>
      <ActionsMenuItem disabled={!hasFaults} onClick={handleClearFaultsAction}>
        <MenuIcon>
          <Icon
            size="xxs"
            color={hasFaults ? COLORS.action_blue : COLORS.font_secondary}
            icon={<LightningIcon />}
          />
        </MenuIcon>
        <span>Clear Faults</span>
      </ActionsMenuItem>
    </ActionModalSection>
  )
}

export default ActionMenuContent
