import CommandModalTemplate from '../../CommandModalTemplate'
import { StringBalancingResults } from './StringBalancingResults'
import { BalancingForm, defaultBalanceFormValues } from '../BalancingForm'
import { modalTitles } from '../balancingUtils'
import { BALANCE_LEVELS } from '../BALANCING_LEVELS'

// step 3 command query
const queryName = 'setStringBalancing'
const label = 'Strings'
// define followup modal custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <StringBalancingResults ids={ids} userInputForm={userInputForm} />
)

const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  BalancingForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    label,
    originator: BALANCE_LEVELS.STRING,
  })

const SetStringBalancingModal = ({ setShowCommandModal, ids }) => {
  // define custom input form returns jsx

  defaultBalanceFormValues.balanceLevel = BALANCE_LEVELS.STRING

  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultBalanceFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated={true}
    />
  )
}

export default SetStringBalancingModal
