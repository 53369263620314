import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

type Params = {
  stationCode: string
  blockIndex: string
}

function useCentipedeLineupsQuery(): UseQueryResult {
  const { stationCode, blockIndex } = useParams<Params>()
  const { session } = useContext<any>(SessionContext)

  return useQuery(
    ['centipedeLineup', stationCode, blockIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/centipedelineups`,
        axiosOptions,
      )
      return response.data
    },
    { refetchInterval: 5000 },
  )
}

export default useCentipedeLineupsQuery
