import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import ConnectionStatus from 'components/Status/ConnectionStatus/ConnectionStatus'
import styled from 'styled-components/macro'
import StationDetailsNav from './components/StationNavDrawer/StationNavDrawer'
import { useParams } from 'react-router-dom'

/**
 * Command Center Header
 * @param {string} props.selectedPage Selected page key
 * @returns top header JSX
 */
export const TopHeader = ({ selectedPage }) => {
  const { data: blockData, isLoading } = useBlockStatus()
  const params = useParams()
  if (isLoading) return null
  const isStationConnected = blockData.valid

  return (
    <Top>
      <StationDetailsNav selectedPage={selectedPage} />
      <RightColumn $isConnected={isStationConnected}>
        <ConnectionLabel $isConnected={isStationConnected}>
          {isStationConnected ? 'Connected' : 'Disconnected'}
        </ConnectionLabel>
        <ConnectionStatus
          isConnected={isStationConnected}
          timestamp={blockData.timestamp.epochMillis}
          id={`${params.stationCode}::${params.blockIndex}`}
        />
      </RightColumn>
    </Top>
  )
}

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 35px;
  border-bottom: 1px solid #d8d8d8;
  box-shadow: 0 0 4px rgba(0, 0, 0, 10%);
`
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 0 35px 0 10px;
  background-color: ${(props) => (props.$isConnected ? '#cfefe3' : '#fbc3bc')};
  height: 100%;
`
const ConnectionLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => (props.$isConnected ? '#2eba86' : 'red')};
  margin-right: 5px;
`
