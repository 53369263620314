import Table from 'components/Table/Table/Table'
import { CellSOC, FaultsPresentTableRow } from '../tableUtils'

const UPSListTable = ({ data, fixedHeader = true }) => {
  const getRowId = (row) => Number(row.upsIndex)

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '80px',
      noOmit: true,
    },
    {
      name: 'FAULTS PRESENT',
      id: 'faults',
      selector: (row) => row.upsFaulted,
      sortable: true,
      width: '140px',
      cell: (row) => FaultsPresentTableRow(row.upsFaulted),
      noOmit: false,
      omit: false,
    },

    {
      name: 'FAULT DESCRIPTION',
      id: 'faultDescription',
      selector: (row) => row.upsFaultList[0],
      cell: (row) => row.upsFaultList[0],
      sortable: true,
      width: '240px',
      noOmit: false,
      omit: false,
    },

    {
      name: 'SoC',
      id: 'soc',
      selector: (row) => row.soc,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '80px',
      cell: (row) => CellSOC(row.soc),
    },
    {
      name: 'STATUS',
      id: 'status',
      selector: (row) => row.upsStatus,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '120px',
      cell: (row) => row.upsStatus,
    },
    {
      name: 'STATUS CHANGE CAUSE',
      id: 'statusChangeCause',
      selector: (row) => row.upsStatusChangeCause,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '160px',
      cell: (row) => row.upsStatusChangeCause,
    },
  ]
  return (
    <Table
      tableId="UPSListTable"
      data={data.elementList ?? []}
      columns={columns}
      getRowId={getRowId}
      defaultId="id"
      fixedHeader={fixedHeader}
      pagination
    />
  )
}

export default UPSListTable
