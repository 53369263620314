import axios from 'axios'
import { BALANCE_STATUSES } from './BALANCING_STATUSES'
import { BALANCE_LEVELS } from './BALANCING_LEVELS'

/**
 * Offers 3 types of balancing commands
 * @param {Array} ids
 * @param {*} userInputForm
 * @param {*} session
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns
 */
const BalancingService = (
  ids,
  userInputForm,
  session,
  stationCode,
  blockIndex,
) => {
  const {
    balancingCmd,
    chargeDeadband,
    dischargeDeadband,
    providedMillivolts,
    balanceLevel,
    batteryPack,
    timeoutMinutes,
  } = userInputForm

  let balanceMode

  switch (balancingCmd) {
    case BALANCE_STATUSES.BALANCE_TO_AVERAGE:
      balanceMode = 'BALANCE_TO_AVERAGE'
      break
    case BALANCE_STATUSES.BALANCE_TO_PROVIDED:
      balanceMode = 'BALANCE_TO_PROVIDED'
      break
    case BALANCE_STATUSES.NO_BALANCE:
      balanceMode = 'NO_BALANCE'
      break
    default:
      // Avoid sending any invalid command
      return Promise.reject(Error('Invalid command, please try again.'))
  }

  const command = {
    chargeDeadband,
    dischargeDeadband,
    balanceMode,
    providedMillivolts,
    timeoutMinutes,
    arrayIndex: '0',
    stringIndex: '0',
    batteryPackIndex: '0',
  }

  let commands = []

  const getArrayStringIndexes = (id) => {
    const values = id.split(':')
    const arrayIndex = values[0]
    const stringIndex = values[1]
    return { arrayIndex, stringIndex }
  }

  switch (balanceLevel) {
    case BALANCE_LEVELS.ARRAY:
      commands = ids.map((id) => ({ ...command, arrayIndex: id }))
      break
    case BALANCE_LEVELS.STRING:
      commands = ids.map((id) => ({ ...command, ...getArrayStringIndexes(id) }))
      break
    case BALANCE_LEVELS.PACK:
      if (batteryPack === null || batteryPack === 0) {
        // throw error?
      } else {
        commands = ids.map((id) => ({
          ...command,
          ...getArrayStringIndexes(id),
          batteryPackIndex: batteryPack,
        }))
      }
      break
    default:
      break
  }

  const jsonBody = commands
  const axiosHeader = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      papitoken: session.token,
    },
  }

  const apiUrl = session.server

  return axios.post(
    `${apiUrl}station/${stationCode}/block/${blockIndex}/balancer/commands`,
    jsonBody,
    axiosHeader,
  )
}

export default BalancingService
