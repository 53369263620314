import ExpandableCard from '../../../../components/Cards/ExpandableCard/ExpandableCard'
import PlugInsTable from './PlugInsTable/PlugInsTable'

const PlugIns = () => {
  return (
    <ExpandableCard title="Plug-Ins" isExpanded={true} minimal={true}>
      <PlugInsTable />
    </ExpandableCard>
  )
}

export default PlugIns
