import { useContext, useState } from 'react'
import _ from 'lodash'
import * as Styles from './styles'
import { ReactComponent as PowinLogo } from '../../icons/logo.svg'
import { fetchUserAuth, loginQuery } from '../../api/queries.api'
import { SessionContext } from '../../contexts/session'
import { useMutation } from '@tanstack/react-query'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const Login = () => {
  const { session, setSession, clearSession } = useContext(SessionContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const { isLoading, mutateAsync: asyncLoginMutation } = useMutation({
    mutationFn: (x) =>
      loginQuery(x.username, x.password, session, clearSession),
  })

  const { isLoading: authLoading, mutateAsync: asyncAuthMutation } =
    useMutation({
      mutationFn: (session) => fetchUserAuth(session, clearSession),
    })

  const handleSubmit = async (e) => {
    e.preventDefault()

    const loginData = await asyncLoginMutation({
      username,
      password,
      session,
      setSession,
    })

    if (loginData) {
      const message = loginData.success ? '' : 'Incorrect credentials'
      const token = loginData.token
      const tempSession = { ...session, username, message, token }
      const authData = await asyncAuthMutation(tempSession)
      tempSession.userAuth = authData
      setSession(tempSession)
    }
  }

  return session.token ? null : (
    <Styles.Container>
      <Styles.LeftColumn>
        <Styles.TitleRow>
          <Styles.Logo>
            <PowinLogo />
          </Styles.Logo>
          <Styles.Title>Command Center</Styles.Title>
          <Styles.Subtitle>- StackOS</Styles.Subtitle>
        </Styles.TitleRow>
        <Styles.Flex>
          <Styles.Card>
            <Styles.Intro>Log In</Styles.Intro>
            <form onSubmit={handleSubmit}>
              <Styles.Section>
                <input
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="User Name"
                />
              </Styles.Section>
              <Styles.Section>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Styles.Section>
              {!(isLoading || authLoading) && (
                <Styles.ErrorMessage $hasError={_.isEmpty(session.message)}>
                  {session.message}
                </Styles.ErrorMessage>
              )}
              <Styles.ButtonRow>
                <Styles.Button type="submit">Submit</Styles.Button>
              </Styles.ButtonRow>
            </form>
            {(isLoading || authLoading) && (
              <Styles.Flex>
                <LoadingPage />
              </Styles.Flex>
            )}
          </Styles.Card>
        </Styles.Flex>
      </Styles.LeftColumn>
      <Styles.Image />
    </Styles.Container>
  )
}

export default Login
