import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import CollectionSegmentDetailsSummary from './Summary/CollectionSegmentDetailsSummary'
import CollectionSegmentDetailsMainColumn from './MainColumn/CollectionSegmentDetailsMainColumn'
import useCollectionSegmentDetailsQuery from 'api/useQueryHooks/useCollectionSegmentDetailsQuery'

const CollectionSegmentDetails = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, collectionSegmentId } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'collection-segment-detail' })
    document.title = `${stationCode}::${blockIndex} - Collection Segment ${collectionSegmentId}`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={
        <CollectionSegmentDetailsSummary
          isHealthy={data.healthy}
          id={collectionSegmentId}
        />
      }
      mainColumnComponent={<CollectionSegmentDetailsMainColumn data={data} />}
      className="collectionSgmtDetail"
    />
  )

  return handleQueryLoadingAndError(
    useCollectionSegmentDetailsQuery(),
    ListPageJsx,
  )
}

export default CollectionSegmentDetails
