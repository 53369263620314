import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as UPSIcon } from 'icons/ups-icon.svg'
import BinaryBarChartTotalVsUsed from 'components/Charts/BinaryBarChart/BinaryBarChartTotalVsUsed'

const UPSListSummary = ({ data }) => {
  if (!data) return null

  const count = data?.elementList.length ?? 0
  const onlineCount = data.totalHealthyAndNotFaulting
  const offlineCount = data.totalFaulting

  const summaryMap = {
    title: `UPSes`,
    titleIcon: <UPSIcon />,
    titleChart: (
      <BinaryBarChartTotalVsUsed
        positiveCount={onlineCount}
        totalCount={count}
        tooltip={`Online: ${onlineCount} | Total: ${count}`}
        id="upsHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Online',
            value: onlineCount.toLocaleString(),
          },
          {
            label: 'Offline',
            value: offlineCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default UPSListSummary
