import { useContext } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import {
  fetchEksPpcDetails,
  postEksPpcControlMode,
  postEksPpcControlModeSettings,
} from 'api/queries.api'
import ModalV2 from 'components/Modals/ModalV2/ModalV2'
import { SessionContext } from 'contexts/session'

import { Column, Container, Field, Grid, InputWrapper } from './styles'
import { ModalFooter } from './ModalFooter'

const CONTROL_MODE_OPTIONS = [
  { value: 'PPC_CONTROL', label: 'PPC Control' },
  {
    value: 'STACK_OS_CONTROL',
    label: 'StackOS Control',
  },
]

const BOOLEAN_OPTIONS = [
  {
    value: true,
    label: 'Enable',
  },
  { value: false, label: 'Disable' },
]

const FREQUENCY_RESPONSE_OPTIONS = [
  { value: 'DISABLE', label: 'Disable' },
  { value: 'ENABLE_PRIMARY', label: 'Enable Primary' },
  { value: 'ENABLE_FAST', label: 'Enable Fast' },
]

// map setting values to command, so we can initialize the select with the right option
const FREQUENCY_RESPONSE_MAP_SETTING_TO_CMD = {
  DISABLED: 'DISABLE',
  ENABLED_PRIMARY: 'ENABLE_PRIMARY',
  ENABLED_FAST: 'ENABLE_FAST',
}

const START_STOP_COMMAND_OPTIONS = [
  { value: 'STOP', label: 'Stop' },
  { value: 'START', label: 'Start' },
  { value: 'EMERGENCY_STOP', label: 'Emergency Stop' },
]

/**
 *
 * @param {object} props - Same props as ModalV2 component
 */
export function EditPpcModesModal(props) {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
    watch,
  } = useForm({
    defaultValues: async () => {
      const data = await fetchEksPpcDetails(session, stationCode, blockIndex)

      const frequencyResponseValue = FREQUENCY_RESPONSE_OPTIONS.map(
        (o) => o.value,
      ).includes(
        FREQUENCY_RESPONSE_MAP_SETTING_TO_CMD[
          data?.opModeFrequencyResponseSetting
        ],
      )
        ? FREQUENCY_RESPONSE_MAP_SETTING_TO_CMD[
            data?.opModeFrequencyResponseSetting
          ]
        : ''

      const startStopCommandValue = START_STOP_COMMAND_OPTIONS.map(
        (o) => o.value,
      ).includes(data?.opModeStartStopSetting)
        ? data?.opModeStartStopSetting
        : ''

      const controlModeValue = CONTROL_MODE_OPTIONS.map(
        (o) => o.value,
      ).includes(data?.opModeInterfaceSetting)
        ? data?.opModeInterfaceSetting
        : ''

      return {
        opModeFrequencyResponseCmd: frequencyResponseValue,
        opModeStartStopCmd: startStopCommandValue,
        opModeInterfaceCmd: controlModeValue,
        opModeMasterCmd: data?.opModeMasterSetting ?? '',
        opModeReactivePowerControlCmd:
          data?.opModeReactivePowerControlSetting ?? '',
        opModePowerFactorControlCmd:
          data?.opModePowerFactorControlSetting ?? '',
        opModeRealPowerCurtailmentCmd:
          data?.opModeRealPowerCurtailmentSetting ?? '',
        opModeVoltageControlCmd: data?.opModeVoltageControlSetting ?? '',
        opModeRampControlCmd: data?.opModeRampControlSetting ?? '',
        opModeScheduleControlCmd: data?.opModeScheduleControlSetting ?? '',
      }
    },
    mode: 'onBlur',
    resetOptions: { keepDirtyValues: true },
  })

  function closeModal() {
    props?.handleClose?.()
  }

  async function onSubmit(data) {
    try {
      closeModal()
      props.toast.toastPending('Submitting PPC settings...')
      const controlModeResult = await postEksPpcControlMode(
        session,
        stationCode,
        blockIndex,
        {
          opModeInterfaceCmd: data?.opModeInterfaceCmd,
        },
      )

      if (!controlModeResult?.valid) throw controlModeResult

      if (data?.opModeInterfaceCmd === 'STACK_OS_CONTROL') {
        const settingsResult = await postEksPpcControlModeSettings(
          session,
          stationCode,
          blockIndex,
          data,
        )
        if (!settingsResult?.valid) throw settingsResult
      }

      props.toast.toastSuccess('PPC settings submitted successfully!')
    } catch (e) {
      props.toast.toastError(
        e?.response?.data?.message ?? 'Something went wrong, please try again.',
      )
    }
  }

  const controlMode = watch('opModeInterfaceCmd')

  const ppcControlIsSelected = Boolean(controlMode === 'PPC_CONTROL')

  return (
    <Container>
      <ModalV2
        title="EKS PPC Mode Settings"
        {...props}
        footerContent={
          <ModalFooter
            disableSubmit={!isValid || isSubmitting}
            onCancel={closeModal}
            formName="editPpcMode"
          />
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} id="editPpcMode">
          <Grid>
            <Column>
              <Field>
                <label htmlFor="opModeInterfaceCmd">Control Mode</label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeInterfaceCmd"
                    control={control}
                    options={CONTROL_MODE_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeMasterCmd">Master Command</label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeMasterCmd"
                    disabled={ppcControlIsSelected}
                    control={control}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeReactivePowerControlCmd">
                  Reactive Power Control
                </label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeReactivePowerControlCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModePowerFactorControlCmd">
                  Power Factor Control
                </label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModePowerFactorControlCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeFrequencyResponseCmd">
                  Frequency Response
                </label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeFrequencyResponseCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={FREQUENCY_RESPONSE_OPTIONS}
                  />
                </InputWrapper>
              </Field>
            </Column>
            <Column>
              <Field>
                <label htmlFor="opModeStartStopCmd">Start / Stop Command</label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeStartStopCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={START_STOP_COMMAND_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeRealPowerCurtailmentCmd">
                  Real Power Curtailment
                </label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeRealPowerCurtailmentCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeVoltageControlCmd">Voltage Control</label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeVoltageControlCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeRampControlCmd">Ramp Control</label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeRampControlCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>

              <Field>
                <label htmlFor="opModeScheduleControlCmd">
                  Schedule Control
                </label>
                <InputWrapper>
                  <ControlledSelect
                    name="opModeScheduleControlCmd"
                    control={control}
                    disabled={ppcControlIsSelected}
                    options={BOOLEAN_OPTIONS}
                  />
                </InputWrapper>
              </Field>
            </Column>
          </Grid>
        </form>
      </ModalV2>
    </Container>
  )
}

const ControlledSelect = ({ options, ...props }) => {
  const { field } = useController({
    ...props,
    rules: {
      /* disable submit if there's any empty input */
      validate: (value) => value !== '',
    },
  })

  return (
    <select
      {...field}
      onChange={(e) => {
        const value = e.target.value
        field.onChange(
          /* native input values are always strings, we should parse before saving */
          value === 'true' ? true : value === 'false' ? false : value,
        )
      }}
    >
      <option value="" disabled />
      {options.map((o) => (
        <option key={`${o.label}-${o.value}`} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  )
}
