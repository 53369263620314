import {
  MenuRow,
  ActionsContainer,
} from '../Modals/ActionModal/ActionModal.styles.jsx'
import ActionsButton from '../ActionsButton/ActionsButton'
import useDetectClickOut from '../../hooks/useDetectClickOut.hooks'
import React, { useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'
/**
 * 
 * @param {children} can have a 'toggleModalOpen' prop which will allow click events to close the outer modal
 * example inside child 
 * 	<ActionModelDiv id={rowStackId+'rotation'} onClick={() => {
					toggleModalOpen()
					callSetRotationModal()
				}
 * @returns JSX action button for table rows that opens a small actions menu modal
 */
export const ActionsMenu = ({ onOpen, onClose, children }) => {
  const { setShow, show, nodeRef, triggerRef } = useDetectClickOut(false)
  const opened = useRef(false)

  /**
   * popper
   */
  const referenceElement = useRef()
  const popperRef = useRef()

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  )

  useEffect(() => {
    if (opened.current === true && !show) {
      if (onClose) onClose()
      opened.current = false
    }
    if (show) {
      if (onOpen) onOpen()
      opened.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    nodeRef.current = popperRef?.current
  }, [popperRef, nodeRef])

  // toggle injected into children to close them by child
  const closeActionModal = () => {
    setShow((prev) => !prev)
  }

  return (
    <ActionsContainer ref={referenceElement}>
      <ActionsButton isActive={show} ref={triggerRef} />
      {createPortal(
        <Popper
          $show={show}
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          {show && React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { closeActionModal })
            }
          })}
        </Popper>,
        document.querySelector('#root'),
        'actionMenuKey',
      )}
    </ActionsContainer>
  )
}

/**
 * item as part of (children) list for ActionsMenu
 * @param {object} { children, onClick, disabled = false, tooltipId = ''}
 * @returns JSX element
 */
export const ActionsMenuItem = ({
  children,
  onClick,
  disabled = false,
  tooltipId = '',
}) => (
  <MenuRow
    disabled={disabled}
    onClick={() => !disabled && onClick()}
    data-tooltip-id={tooltipId}
  >
    {children}
  </MenuRow>
)

const Popper = styled.div`
  display: ${(props) => (props.$show ? 'block' : 'none')};
  flex: 1;
  width: max-content;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 5px;
  top: 30px;
  z-index: 444;
  position: absolute;
`
