import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'
import { idsToString } from './SetDcDcGroupRotationModal'

const groupDidUpdate = (dcDcGroupList, commandForm, id) => {
  const [acPvBatteryIndex, dcDcGroupIndex] = id.split(':')
  return dcDcGroupList?.dcDcGroups?.some((group) =>
    Boolean(
      group.acPvBatteryIndex === +acPvBatteryIndex &&
        group.dcDcGroupIndex === +dcDcGroupIndex &&
        group.outRotation === (commandForm.outRotation === 'true'),
    ),
  )
}

const heading = 'IN ROTATION'

const ResultTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.outRotation === 'false' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
      )}
    </>
  )
}

export const DcDcGroupRotationResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={idsToString(ids)}
      userInputForm={userInputForm}
      pendingEvaluation={groupDidUpdate}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={'dcDcGroupRotation'}
    />
  )
}
