import { formatNumber } from 'utils/formatting'
import { kilowatt_to_megawatt } from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function FrequencyResponseTable({ data }) {
  const tableData = [
    {
      name: 'Frequency Command',
      operation: `${data?.siteFrequencyCmdHz} Hz`,
      status: `${data?.siteFrequencySettingHz} Hz`,
    },
    {
      name: 'Ramp Rate Up',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateUpFreqResponseCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(
          data?.rampRateUpFreqResponseSettingkWperMin,
        ),
        precision: 2,
        unit: ' MW / min',
      }),
    },
    {
      name: 'Ramp Rate Down',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateDownFreqResponseCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(
          data?.rampRateDownFreqResponseSettingkWperMin,
        ),
        precision: 2,
        unit: ' MW / min',
      }),
    },
  ]

  return (
    <StatusAndOperationTable
      tableId="EksPpcFrequencyResponseTable"
      data={tableData}
    />
  )
}
