import { useState, useEffect, useReducer } from 'react'
import { useParams } from 'react-router'
import Table from 'components/Table/Table/Table'
import * as TableStyles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { RowIconText } from '../table.styled'
import {
  HealthStatusIconWithToolTips,
  trippedOrLatchedSensorIcon,
} from '../tableUtils'
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'
import ActionMenuContent from '../../Commands/ClearEnclosureFaults/ActionMenuModal/ActionMenuContent'
import actionModalReducer from '../../Commands/ClearEnclosureFaults/ActionMenuModal/action-modal-reducer'
import * as Styles from './styles'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const SensorsListTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const [filteredData, setFilteredData] = useState()
  const [stationSensors, setStationSensors] = useState({})
  const [isHideHealthyChecked, setIsHideHealthyChecked] = useState(false)
  const [isFormattingSensors, setIsFormattingSensors] = useState(true)

  const params = useParams()

  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModal, actionModalDispatch] = useReducer(actionModalReducer)

  useEffect(() => {
    if (Object.keys(stationSensors).length === 0) {
      data.forEach((enclosure) => {
        enclosure.sensorsForEnclosure.forEach((sensors) => {
          setStationSensors((prev) => ({ ...prev, [sensors.sensorRole]: true }))
        })
      })
    }
    setIsFormattingSensors(false)
  }, [data, stationSensors])

  const handleHideHealthyEquipment = (e) => {
    let _filteredData

    if (e.target.checked) {
      setIsHideHealthyChecked(true)
      _filteredData = data.filter(
        (d) => d.sensorsForEnclosure.filter((s) => !s.healthy).length != 0,
      )
    } else {
      setIsHideHealthyChecked(false)
      _filteredData = data
    }

    setFilteredData(_filteredData)
  }

  const getRowId = (row) => {
    if (row.enclosureType === null) return 'Station-Wide'
    return row.enclosureType
      ? row.enclosureIndex + '::' + row.enclosureType
      : row.enclosureId
  }

  const cellId = (row) => (
    <TableStyles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/sensors${getRowId(
        row,
      )}`}
    >
      {getRowId(row)}
    </TableStyles.RowLink>
  )

  const findSensor = (row, sensorName) => {
    return row.sensorsForEnclosure.find((x) => x.sensorRole === sensorName)
  }

  useEffect(() => {
    if (isHideHealthyChecked) {
      const aux = data.filter(
        (d) => d.sensorsForEnclosure.filter((s) => !s.healthy).length != 0,
      )
      setFilteredData(aux)
    } else {
      setFilteredData(data)
    }
  }, [data, isHideHealthyChecked])

  const actionColumnMenu = (row) => {
    let isClearable = false
    Object.keys(row.sensorsForEnclosure).forEach((sensor) => {
      if (row.sensorsForEnclosure[sensor].status === 'Ready to Clear')
        isClearable = true
    })
    const target = row.enclosureType ? 'ENCLOSURE' : 'NO_ENCLOSURE'
    if (isClearable) {
      return (
        <Styles.Container>
          <ActionsMenu onOpen={() => null} onClose={() => null}>
            <ActionMenuContent
              setShowCommandModal={setShowCommandModal}
              handleActionSelection={actionModalDispatch}
              stationCode={params.stationCode}
              blockIndex={params.blockIndex}
              target={target}
              enclosureIndex={row.enclosureIndex}
              enclosureType={row.enclosureType ?? 'Station-Wide'}
            />
          </ActionsMenu>
        </Styles.Container>
      )
    }
    return <Styles.Container />
  }

  const columns = [
    {
      name: 'Actions',
      id: 'actions',
      sortable: false,
      noOmit: true,
      width: '80px',
      cell: actionColumnMenu,
    },
    {
      name: 'LOCATION',
      id: 'location',
      selector: getRowId,
      sortable: true,
      width: '200px',
      noOmit: true,
      cell: cellId,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.healthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.healthy),
    },
    {
      name: 'FIRE',
      id: 'fire',
      selector: (row) => findSensor(row, 'FireAlarm'),
      sortable: true,
      width: '50px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'FireAlarm'), 'FIRE'),
      noOmit: !stationSensors['FireAlarm'],
      omit: !stationSensors['FireAlarm'],
    },
    {
      name: 'FIRE TROUBLE',
      id: 'fireTrbl',
      selector: (row) => findSensor(row, 'FireTrouble'),
      sortable: true,
      width: '100px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'FireTrouble'),
          'FIRE TROUBLE',
        ),
      noOmit: !stationSensors['FireTrouble'],
      omit: !stationSensors['FireTrouble'],
    },
    {
      name: 'FIRE SUPER',
      id: 'fireSupervisory',
      selector: (row) => findSensor(row, 'FireSupervisory'),
      sortable: true,
      width: '90px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'FireSupervisory'),
          'FIRE SUPER',
        ),
      noOmit: !stationSensors['FireSupervisory'],
      omit: !stationSensors['FireSupervisory'],
    },
    {
      name: 'ESTOP BUTTON',
      id: 'eStopButton',
      selector: (row) => findSensor(row, 'EStopButton'),
      sortable: true,
      width: '105px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'EStopButton'),
          'ESTOP BUTTON',
        ),
      noOmit: !stationSensors['EStopButton'],
      omit: !stationSensors['EStopButton'],
    },
    {
      name: 'STATX',
      id: 'statX',
      selector: (row) => findSensor(row, 'FireSuppressionReleased'),
      sortable: true,
      width: '60px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'FireSuppressionReleased'),
          'STATX',
        ),
      noOmit: !stationSensors['FireSuppressionReleased'],
      omit: !stationSensors['FireSuppressionReleased'],
    },
    {
      name: 'SMOKE',
      id: 'smoke',
      selector: (row) => findSensor(row, 'SmokeAlarm'),
      sortable: true,
      width: '70px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'SmokeAlarm'), 'SMOKE'),
      noOmit: !stationSensors['SmokeAlarm'],
      omit: !stationSensors['SmokeAlarm'],
    },
    {
      name: 'SMOKE TROUBLE',
      id: 'smokeTrouble',
      selector: (row) => findSensor(row, 'SmokeTrouble'),
      sortable: true,
      width: '115px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'SmokeTrouble'),
          'SMOKE TROUBLE',
        ),
      noOmit: !stationSensors['SmokeTrouble'],
      omit: !stationSensors['SmokeTrouble'],
    },
    {
      name: 'HEAT',
      id: 'heat',
      selector: (row) => findSensor(row, 'HeatSensor'),
      sortable: true,
      width: '60px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'HeatSensor'), 'HEAT'),
      noOmit: !stationSensors['HeatSensor'],
      omit: !stationSensors['HeatSensor'],
    },
    {
      name: 'HYDROGEN',
      id: 'hydrogen',
      selector: (row) => findSensor(row, 'HighHydrogenAlarm'),
      sortable: true,
      width: '90px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'HighHydrogenAlarm'),
          'HYDROGEN',
        ),
      noOmit: !stationSensors['HighHydrogenAlarm'],
      omit: !stationSensors['HighHydrogenAlarm'],
    },
    {
      name: 'HYDROGEN FAULT',
      id: 'hydrogenFault',
      selector: (row) => findSensor(row, 'HydrogenFault'),
      sortable: true,
      width: '120px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'HydrogenFault'),
          'HYDROGEN FAULT',
        ),
      noOmit: !stationSensors['HydrogenFault'],
      omit: !stationSensors['HydrogenFault'],
    },
    {
      name: 'GRID RECLOSER',
      id: 'gridRecloser',
      selector: (row) => findSensor(row, 'GridRecloserSignal'),
      sortable: true,
      width: '120px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'GridRecloserSignal'),
          'GRID RECLOSER',
        ),
      noOmit: !stationSensors['GridRecloserSignal'],
      omit: !stationSensors['GridRecloserSignal'],
    },
    {
      name: 'DATA',
      id: 'dataSensors',
      selector: (row) => findSensor(row, 'EnvironmentalControllerLostComms'),
      sortable: true,
      width: '55px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'EnvironmentalControllerLostComms'),
          'DATA',
        ),
      noOmit: !stationSensors['EnvironmentalControllerLostComms'],
      omit: !stationSensors['EnvironmentalControllerLostComms'],
    },
    {
      name: 'IO',
      id: 'io',
      selector: (row) => findSensor(row, 'EnvIOLostComms'),
      sortable: true,
      width: '40px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'EnvIOLostComms'), 'IO'),
      noOmit: !stationSensors['EnvIOLostComms'],
      omit: !stationSensors['EnvIOLostComms'],
    },
    {
      name: 'AC DOORS',
      id: 'acDoors',
      selector: (row) => findSensor(row, 'AcDoor'),
      sortable: true,
      width: '80px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'AcDoor'), 'AC DOORS'),
      noOmit: !stationSensors['AcDoor'],
      omit: !stationSensors['AcDoor'],
    },
    {
      name: 'DC DOORS',
      id: 'dcDoors',
      selector: (row) => findSensor(row, 'DCCabinet'),
      sortable: true,
      width: '80px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'DCCabinet'), 'DC DOORS'),
      noOmit: !stationSensors['DCCabinet'],
      omit: !stationSensors['DCCabinet'],
    },
    {
      name: 'AC SIDE DOOR',
      id: 'acSideDoor',
      selector: (row) => findSensor(row, 'AcSideDoor'),
      sortable: true,
      width: '100px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'AcSideDoor'),
          'AC SIDE DOOR',
        ),
      noOmit: !stationSensors['AcSideDoor'],
      omit: !stationSensors['AcSideDoor'],
    },
    {
      name: 'DC SIDE DOOR',
      id: 'dcSideDoor',
      selector: (row) => findSensor(row, 'DcSideDoor'),
      sortable: true,
      width: '100px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'DcSideDoor'),
          'DC SIDE DOOR',
        ),
      noOmit: !stationSensors['DcSideDoor'],
      omit: !stationSensors['DcSideDoor'],
    },
    {
      name: 'TOP CAP DOORS',
      id: 'topCapDoors',
      selector: (row) => findSensor(row, 'LowerTopCap'),
      sortable: true,
      width: '108px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'LowerTopCap'),
          'TOP CAP DOORS',
        ),
      noOmit: !stationSensors['LowerTopCap'],
      omit: !stationSensors['LowerTopCap'],
    },
    {
      name: 'BATTERY DOORS',
      id: 'batteryDoors',
      selector: (row) => findSensor(row, 'BatteryDoors'),
      sortable: true,
      width: '115px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'BatteryDoors'),
          'BATTERY DOORS',
        ),
      noOmit: !stationSensors['BatteryDoors'],
      omit: !stationSensors['BatteryDoors'],
    },
    {
      name: 'BATTERY CMPRTMNT DOOR',
      id: 'batteryCompartmentDoor',
      selector: (row) => findSensor(row, 'BatteryCompartmentDoor'),
      sortable: true,
      width: '165px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'BatteryCompartmentDoor'),
          'BATTERY COMPARTMENT DOOR',
        ),
      noOmit: !stationSensors['BatteryCompartmentDoor'],
      omit: !stationSensors['BatteryCompartmentDoor'],
    },
    {
      name: 'STRING CTRL ACCESS DOOR',
      id: 'stringControllerAccessDoor',
      selector: (row) => findSensor(row, 'StringControllerAccessDoor'),
      sortable: true,
      width: '170px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'StringControllerAccessDoor'),
          'STRING CONTROLLER ACCESS DOOR',
        ),
      noOmit: !stationSensors['StringControllerAccessDoor'],
      omit: !stationSensors['StringControllerAccessDoor'],
    },
    {
      name: 'MANUAL VENT',
      id: 'manualVent',
      selector: (row) => findSensor(row, 'ManualVentilation'),
      sortable: true,
      width: '100px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'ManualVentilation'),
          'MANUAL VENT',
        ),
      noOmit: !stationSensors['ManualVentilation'],
      omit: !stationSensors['ManualVentilation'],
    },
    {
      name: 'ENV CTRL',
      id: 'envCtrl',
      selector: (row) => findSensor(row, 'EnvironmentalControllerVentilation'),
      sortable: true,
      width: '80px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'EnvironmentalControllerVentilation'),
          'ENV CTRL',
        ),
      noOmit: !stationSensors['EnvironmentalControllerVentilation'],
      omit: !stationSensors['EnvironmentalControllerVentilation'],
    },
    {
      name: 'MOISTURE',
      id: 'moisture',
      selector: (row) => findSensor(row, 'MoistureAlarm'),
      sortable: true,
      width: '80px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'MoistureAlarm'),
          'MOISTURE',
        ),
      noOmit: !stationSensors['MoistureAlarm'],
      omit: !stationSensors['MoistureAlarm'],
    },
    {
      name: 'UPS',
      id: 'ups',
      selector: (row) => findSensor(row, 'UPSAlarm'),
      sortable: true,
      width: '50px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'UPSAlarm'), 'UPS'),
      noOmit: !stationSensors['UPSAlarm'],
      omit: !stationSensors['UPSAlarm'],
    },
    {
      name: 'CRITICAL AUX LOAD',
      id: 'criticalAuxLoadMode',
      selector: (row) => findSensor(row, 'CriticalAuxLoadMode'),
      sortable: true,
      width: '130px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'CriticalAuxLoadMode'),
          'CRITICAL AUX LOAD',
        ),
      noOmit: !stationSensors['CriticalAuxLoadMode'],
      omit: !stationSensors['CriticalAuxLoadMode'],
    },
    {
      name: 'MODBUS E-STOP',
      id: 'modbusEnabledEstop',
      selector: (row) => findSensor(row, 'ModbusEnabledEstop'),
      sortable: true,
      width: '120px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'ModbusEnabledEstop'),
          'MODBUS E-STOP',
        ),
      noOmit: !stationSensors['ModbusEnabledEstop'],
      omit: !stationSensors['ModbusEnabledEstop'],
    },
    {
      name: 'PCS E-STOP',
      id: 'pcsEstop',
      selector: (row) => findSensor(row, 'PCSEStop'),
      sortable: true,
      width: '90px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(findSensor(row, 'PCSEStop'), 'PCS E-STOP'),
      noOmit: !stationSensors['PCSEStop'],
      omit: !stationSensors['PCSEStop'],
    },
    {
      name: 'GROUND FAULT E-STOP',
      id: 'groudFaultEstop',
      selector: (row) => findSensor(row, 'GroundFault'),
      sortable: true,
      width: '150px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'GroundFault'),
          'GROUND FAULT E-STOP',
        ),
      noOmit: !stationSensors['GroundFault'],
      omit: !stationSensors['GroundFault'],
    },
    {
      name: 'GENERIC DOOR',
      id: 'genericDoor',
      selector: (row) => findSensor(row, 'GenericDoor'),
      sortable: true,
      width: '110px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'GenericDoor'),
          'GENERIC DOOR',
        ),
      noOmit: !stationSensors['GenericDoor'],
      omit: !stationSensors['GenericDoor'],
    },
    {
      name: 'UNRECOGNIZED',
      id: 'Unrecognized',
      selector: (row) => findSensor(row, 'UNRECOGNIZED'),
      sortable: true,
      width: '115px',
      cell: (row) =>
        trippedOrLatchedSensorIcon(
          findSensor(row, 'UNRECOGNIZED'),
          'UNRECOGNIZED',
        ),
      noOmit: !stationSensors['UNRECOGNIZED'],
      omit: !stationSensors['UNRECOGNIZED'],
    },
  ]
  if (isFormattingSensors) return <LoadingPage />

  return (
    <>
      <Table
        tableId="SensorsListTable"
        data={filteredData}
        columns={columns}
        getRowId={getRowId}
        defaultId="location"
        fixedHeader={fixedHeader}
        pagination
        tableActions={
          <HideHealthyEquipment
            handleHideHealthyEquipment={handleHideHealthyEquipment}
          />
        }
        hasDynamicColumns={true}
      />
      {showCommandModal && <ActionModal />}
    </>
  )
}

export default SensorsListTable

const HideHealthyEquipment = ({ handleHideHealthyEquipment }) => {
  return (
    <RowIconText>
      <label>
        <input
          type="checkbox"
          style={{ verticalAlign: '-2px' }}
          onClick={(e) => handleHideHealthyEquipment(e)}
        />{' '}
        Hide Healthy Equipment
      </label>
    </RowIconText>
  )
}
