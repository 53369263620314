import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Link } from 'react-router-dom'

//Component Imports
import SearchInput from '../../../../components/Inputs/SearchInput/SearchInput'

// Context Imports
import { alphanumericSort } from 'utils/sort'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const StationList = ({ selectedBlock, setShow }) => {
  const customSortFn = (a, b) => alphanumericSort(a.stationCode, b.stationCode)
  const { data, isLoading } = useStationsDataQuery()

  const [searchField, setSearchField] = useState('')
  const [initialBlockList, setInitialBlockList] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setInitialBlockList(data.blocks)
      setFilteredData(data.blocks)
    }
  }, [data])

  useEffect(() => {
    const filteredBlocks = initialBlockList.filter((block) => {
      return (
        (block.stationName &&
          block.stationName
            .toLowerCase()
            .includes(searchField.toLowerCase())) ||
        (block.stationCode &&
          block.stationCode.toLowerCase().includes(searchField.toLowerCase()))
      )
    })
    setFilteredData(filteredBlocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField])

  if (isLoading) return <LoadingPage />

  const renderBlockList = () => {
    if (filteredData.length > 0) {
      return [...filteredData].sort(customSortFn).map((b) => {
        return (
          <BlockRow
            selected={`${b.stationCode} :: ${b.blockIndex}` === selectedBlock}
            to={`/block-details/${b.stationCode}/${b.blockIndex}`}
            onClick={() => setShow(false)}
            key={`${b.stationCode}::${b.blockIndex}`}
          >
            <BlockName
              selected={`${b.stationCode} :: ${b.blockIndex}` === selectedBlock}
            >
              {b.stationCode} :: {b.blockIndex}
            </BlockName>
            <BlockCode>{b.stationName}</BlockCode>
          </BlockRow>
        )
      })
    } else {
      return <EmptyMessage>No Matching Blocks</EmptyMessage>
    }
  }

  const handleFilter = (e) => {
    setSearchField(e.target.value)
  }

  return (
    <Container>
      <TitleRow>
        <SearchInput
          type="search"
          onChange={handleFilter}
          placeholder="Search Fleet"
        />
      </TitleRow>
      <ListContainer>{renderBlockList()}</ListContainer>
    </Container>
  )
}

export default StationList

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const TitleRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 0 20px;
  padding: 5px 10px 15px;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`
const BlockRow = styled(Link)`
  display: flex;
  cursor: ${(props) => (props.selected ? 'default' : 'cursor')};
  text-decoration: none;
  padding: 10px 35px;
  border-right: 5px solid;
  border-right-color: ${(props) =>
    props.selected ? '#00b986' : 'transparent'};
  background-color: ${(props) => (props.selected ? '#e7e7e7' : 'transparent')};

  &:hover {
    background-color: ${(props) => (props.selected ? '#e7e7e7' : '#efefef')};
  }
`
const BlockName = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  color: #333;
`
const BlockCode = styled.div`
  color: #d0d0d0;
  font-size: 12px;
`
const EmptyMessage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  font-size: 13px;
  color: #798b95;
`
