/**
 * to be appended to table id to create a specific key to be saved
 * ie. alertManagementColumnVisibility
 */
export const TABLE_SAVE_OPTIONS = {
  COLUMN_VISIBILITY: 'columnVisibility',
  ROWS_PER_PAGE: 'rowsPerPage',
  SORT_BY: 'sortBy',
  HEADER_FILTERS: 'headerFilters',
}

export const getColumnVisibilityKey = (tableId) => {
  return tableId + '-' + TABLE_SAVE_OPTIONS.COLUMN_VISIBILITY
}
export const getSortByKey = (tableId) => {
  return tableId + '-' + TABLE_SAVE_OPTIONS.SORT_BY
}
export const getRowPerPageKey = (tableId) => {
  return tableId + '-' + TABLE_SAVE_OPTIONS.ROWS_PER_PAGE
}

export const getHeaderFilterKey = (tableId) => {
  return tableId + '-' + TABLE_SAVE_OPTIONS.HEADER_FILTERS
}
