import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import { getDcDcPCSesList } from 'api/queries.api'

/**
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useDcDcPCSesQuery = () => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    ['dcDcPcses', stationCode, blockIndex],
    async () => {
      const data = await getDcDcPCSesList(session, stationCode, blockIndex)
      return data
    },
    { refetchInterval: 5000 },
  )
}

export default useDcDcPCSesQuery
