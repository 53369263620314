import { ReactComponent as GasSensorIcon } from 'icons/gas-icon.svg'
import { ReactComponent as HeatIcon } from 'icons/heat-icon.svg'
import { ReactComponent as DoorIcon } from 'icons/door-sensor-icon.svg'
import { ReactComponent as EnvControllerIcon } from 'icons/env-controller.svg'
import { ReactComponent as StringsIcon } from 'icons/string-icon.svg'
import { ReactComponent as HVACIcon } from 'icons/hvac-icon.svg'
import { ReactComponent as MoistureIcon } from 'icons/moisture-icon.svg'
import { ReactComponent as UnknownIcon } from 'icons/unknown-icon.svg'
import { ReactComponent as IoLostCommsIcon } from 'icons/io-lost-coms-icon.svg'
import { ReactComponent as DataUnavailIcon } from 'icons/data-unavail-icon.svg'
import { ReactComponent as VentilationIcon } from 'icons/vent-icon.svg'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  getBinaryBarChartTotalVsUsed,
  StringCountsTo4BarChartRow,
} from '../modules/Station/components/Tables/tableUtils'
import TernaryBarChartSensorsTripped from 'components/Charts/TernaryBarChart/TernaryBarChartSensorsTripped'

const sensorSummary = (data, lineupKey) => (key) => {
  return (
    Math.round(data[lineupKey].sensorCounts[key].untripped).toLocaleString() +
    ' / ' +
    Math.round(data[lineupKey].sensorCounts[key].total).toLocaleString()
  )
}

export const filterSensorsSummary = (
  data,
  isCollectionSegment = false,
  lineupKey = 'centipedeSegmentsSummed',
) => {
  const summaryVal = sensorSummary(data, lineupKey)

  const sensorsSection = [
    {
      sectionKey: 'FIRE_SAFETY',
      sectionTitle: 'HEAT SENSORS',
      sectionTitleIcon: <HeatIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('FIRE_SAFETY'),
        },
      ],
    },
    {
      sectionKey: 'VENTILATION',
      sectionTitle: 'VENTILATION SENSORS',
      sectionTitleIcon: <VentilationIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('VENTILATION'),
        },
      ],
    },
    {
      sectionKey: 'DOOR',
      sectionTitle: 'DOORS SENSORS',
      sectionTitleIcon: <DoorIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('DOOR'),
        },
      ],
    },
    {
      sectionKey: 'MOISTURE',
      sectionTitle: 'MOISTURE SENSORS',
      sectionTitleIcon: <MoistureIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('MOISTURE'),
        },
      ],
    },
    {
      sectionKey: 'GAS',
      sectionTitle: 'GAS SENSORS',
      sectionTitleIcon: <GasSensorIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('GAS'),
        },
      ],
    },
    {
      sectionKey: 'LOST_COMMS_WITH_IO',
      sectionTitle: 'IO COMMUNICATIONS',
      sectionTitleIcon: <IoLostCommsIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('LOST_COMMS_WITH_IO'),
        },
      ],
    },
    {
      sectionKey: 'OTHER',
      sectionTitle: 'OTHER SENSORS',
      sectionTitleIcon: <UnknownIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('OTHER'),
        },
      ],
    },
    {
      sectionKey: 'DATA_UNAVAILABLE',
      sectionTitle: 'DATA UNAVAILABLE',
      sectionTitleIcon: <DataUnavailIcon />,
      rows: [
        {
          label: 'Untripped / Total',
          value: summaryVal('DATA_UNAVAILABLE'),
        },
      ],
    },
  ]

  const stringsSection = !isCollectionSegment
    ? [
        {
          sectionTitle: 'STRINGS',
          sectionTitleIcon: <StringsIcon />,
          rows: [
            {
              label: 'Online / Total',
              value: `${data[lineupKey].stringCounts?.online.toLocaleString() ?? 'N/A'} / ${
                data[lineupKey].stringCounts?.total.toLocaleString() ?? 'N/A'
              }`,
            },
          ],
        },
      ]
    : []

  const envCtrlsHvacSection = [
    {
      sectionTitle: 'ENV. CONTROLLERS',
      sectionTitleIcon: <EnvControllerIcon />,
      rows: [
        {
          label: 'Healthy / Total',
          value: `${data[lineupKey].envControllersHealthy.toLocaleString()} / ${data[lineupKey].envControllersTotal.toLocaleString()}`,
        },
      ],
    },
    {
      sectionTitle: 'HVAC',
      sectionTitleIcon: <HVACIcon />,
      rows: [
        {
          label: 'Healthy / Total',
          value: `${data[lineupKey].hvacHealthy.toLocaleString()} / ${data[lineupKey].hvacTotal.toLocaleString()}`,
        },
      ],
    },
  ]

  const auxSensors = []
  const auxSections = []
  Object.keys(data[lineupKey].sensorCounts).forEach((sensor) => {
    if (data[lineupKey].sensorCounts[sensor].total >= 1) {
      auxSensors.push(sensor)
    }
  })
  sensorsSection.forEach((section) => {
    if (auxSensors.find((el) => el === section.sectionKey))
      auxSections.push(section)
  })

  return auxSections.concat(stringsSection, envCtrlsHvacSection)
}

export const filterSensorsTable = (
  data,
  getRowId,
  idCell,
  isCollectionSegment = false,
) => {
  const healthTooltip = ($1, $2) => `Healthy: ${$1} | UnHealthy: ${$2}`

  const stationSensors = {}

  data.forEach((element) => {
    Object.keys(element.sensorCounts).forEach((sensor) => {
      if (element.sensorCounts[sensor].total > 0) stationSensors[sensor] = true
    })
  })

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: idCell,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
    },
    {
      name: 'HEAT',
      id: 'heat',
      selector: (row) => row.sensorCounts.FIRE_SAFETY.total,
      sortable: true,
      width: '100px',
      cell: (row) =>
        TernaryBarChartSensorsTripped(row.sensorCounts.FIRE_SAFETY),
      noOmit: !stationSensors['FIRE_SAFETY'],
      omit: !stationSensors['FIRE_SAFETY'],
    },
    {
      name: 'VENTILATION',
      id: 'ventilation',
      selector: (row) => row.sensorCounts.VENTILATION.total,
      sortable: true,
      width: '100px',
      cell: (row) =>
        TernaryBarChartSensorsTripped(row.sensorCounts.VENTILATION),
      noOmit: !stationSensors['VENTILATION'],
      omit: !stationSensors['VENTILATION'],
    },
    {
      name: 'DOORS',
      id: 'doors',
      selector: (row) => row.sensorCounts.DOOR.total,
      sortable: true,
      width: '100px',
      cell: (row) => TernaryBarChartSensorsTripped(row.sensorCounts.DOOR),
      noOmit: !stationSensors['DOOR'],
      omit: !stationSensors['DOOR'],
    },
    {
      name: 'MOISTURE',
      id: 'moisture',
      selector: (row) => row.sensorCounts.MOISTURE.total,
      sortable: true,
      width: '100px',
      cell: (row) => TernaryBarChartSensorsTripped(row.sensorCounts.MOISTURE),
      noOmit: !stationSensors['MOISTURE'],
      omit: !stationSensors['MOISTURE'],
    },
    {
      name: 'GAS',
      id: 'gas',
      selector: (row) => row.sensorCounts.GAS.total,
      sortable: true,
      width: '100px',
      cell: (row) => TernaryBarChartSensorsTripped(row.sensorCounts.GAS),
      noOmit: !stationSensors['GAS'],
      omit: !stationSensors['GAS'],
    },
    {
      name: 'LOST COMMS',
      id: 'lostCommsWithIo',
      selector: (row) => row.sensorCounts.LOST_COMMS_WITH_IO.total,
      sortable: true,
      width: '100px',
      cell: (row) =>
        TernaryBarChartSensorsTripped(row.sensorCounts.LOST_COMMS_WITH_IO),
      noOmit: !stationSensors['LOST_COMMS_WITH_IO'],
      omit: !stationSensors['LOST_COMMS_WITH_IO'],
    },
    {
      name: 'OTHER',
      id: 'other',
      selector: (row) => row.sensorCounts.OTHER.total,
      sortable: true,
      width: '100px',
      cell: (row) => TernaryBarChartSensorsTripped(row.sensorCounts.OTHER),
      noOmit: !stationSensors['OTHER'],
      omit: !stationSensors['OTHER'],
    },
    {
      name: 'DATA UNAVAIL',
      id: 'dataUnavail',
      selector: (row) => row.sensorCounts.DATA_UNAVAILABLE.total,
      sortable: true,
      width: '100px',
      cell: (row) =>
        TernaryBarChartSensorsTripped(row.sensorCounts.DATA_UNAVAILABLE),
      noOmit: !stationSensors['DATA_UNAVAILABLE'],
      omit: !stationSensors['DATA_UNAVAILABLE'],
    },
  ]

  const stringsColumn = !isCollectionSegment
    ? [
        {
          name: 'STRINGS',
          id: 'strings',
          selector: (row) => row.stringCounts?.online,
          sortable: true,
          width: '100px',
          cell: (row) => StringCountsTo4BarChartRow(row.stringCounts),
          noOmit: false,
          omit: false,
        },
      ]
    : []

  const envCtrlsHvacColumns = [
    {
      name: 'ENV CTRLS',
      id: 'envCtrls',
      selector: (row) => row.envControllersTotal,
      sortable: true,
      width: '100px',
      cell: (row) =>
        getBinaryBarChartTotalVsUsed(
          row.envControllersTotal,
          row.envControllersHealthy,
        ),
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC',
      id: 'hvac',
      selector: (row) => `${row.hvacTotal}:${row.hvacHealthy}`,
      sortable: true,
      width: '100px',
      cell: (row) =>
        getBinaryBarChartTotalVsUsed(
          row.hvacTotal,
          row.hvacHealthy,
          healthTooltip(row.hvacTotal, row.hvacTotal - row.hvacHealthy),
        ),
      noOmit: false,
      omit: false,
    },
  ]

  return columns.concat(stringsColumn, envCtrlsHvacColumns)
}
