import getAxiosBasicUrlAndHeader from 'api/helpers/getAxiosBasicUrlAndHeader'
import axios from 'axios'

/**
 * requires user to have group code 6A
 * @param {Array} ids
 * @param {*} userInputForm
 * @param {*} session
 * @param {string} stationCode
 * @returns
 */
const alertAssignService = async (ids, userInputForm, session) => {
  const id = ids[0]
  const { assignee, notes } = userInputForm

  const jsonBody = {
    blockIndex: id.blockIndex,
    stationCode: id.stationCode,
    bessStatusCode: id.bessStatusCode,
    startTimestamp: id.startTimestamp.epochMillis,
    assignTo: assignee,
    notes: notes,
    shouldKeepMuted: false,
  }

  const { apiUrl, axiosHeader } = getAxiosBasicUrlAndHeader(session)

  return await axios
    .put(`${apiUrl}alerts/reassign`, jsonBody, axiosHeader)
    .then((res) => res.data)
}

export default alertAssignService
