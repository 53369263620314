import { useState, useContext } from 'react'
import * as Styles from './FirmwareCommands.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import {
  FormInput,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { COLORS } from 'design_system/colors'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { SessionContext } from 'contexts/session'
import { firmwareDeployCommand } from 'api/queries.api'
import { ToastMessage } from 'components/Toasts/ToastMessages'

export const FirmwareDeploy = () => {
  const { session } = useContext(SessionContext)
  const { data, isLoading } = useStationsDataQuery()

  const [selectedStation, setSelectedStation] = useState(['Select One'])
  const [selectedFirmwareType, setSelectedFirmwareType] = useState('Select One')
  const [majorValue, setMajorValue] = useState('')
  const [isMajorValid, setIsMajorValid] = useState(false)
  const [minorValue, setMinorValue] = useState('')
  const [isMinorValid, setIsMinorValid] = useState(false)
  const [revisionValue, setRevisionValue] = useState('')
  const [isRevisionValid, setIsRevisionValid] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  if (isLoading) return <LoadingPage />

  const firmwareTypes = ['Turtle', 'CGC', 'BPC', 'SC', 'LCD', 'Feather']

  const regex = /^\d+$/

  const checkNumeric = (e) => {
    const value = e.key

    if (!regex.test(value)) {
      e.preventDefault()
    }
  }

  const isFormValid =
    selectedStation[0] != 'Select One' &&
    selectedFirmwareType !== 'Select One' &&
    isMajorValid &&
    isMinorValid &&
    isRevisionValid &&
    !isUploading

  const setSelectedStationHandler = (station, blockIndex, valid) => {
    if (!valid) return

    setSelectedStation([station + ' :: ' + blockIndex, station, blockIndex])
  }

  const setSelectedFirmwareTypeHandler = (type) => {
    setSelectedFirmwareType(type)
  }

  const onChangeHandler = (e, inputType) => {
    if (inputType === 'major') {
      if (regex.test(e.target.value) && e.target.value.trim().length > 0) {
        setIsMajorValid(true)
        setMajorValue(e.target.value)
        return
      }
      setIsMajorValid(false)
      return
    }

    if (inputType === 'minor') {
      if (regex.test(e.target.value) && e.target.value.trim().length > 0) {
        setIsMinorValid(true)
        setMinorValue(e.target.value)
        return
      }
      setIsMinorValid(false)
      return
    }

    if (inputType === 'revision') {
      if (regex.test(e.target.value) && e.target.value.trim().length > 0) {
        setIsRevisionValid(true)
        setRevisionValue(e.target.value)
        return
      }
      setIsRevisionValid(false)
    }
  }

  const onSubmitHandler = (e) => {
    setIsUploading(true)
    e.preventDefault()
    const payload = {
      firmwareType: selectedFirmwareType.toUpperCase(),
      major: majorValue,
      minor: minorValue,
      revision: revisionValue,
    }

    toastMessageHandler('FIRMWARE DEPLOY COMMAND IN PROGRESS', '', 'progress')

    const executeCommand = async () => {
      const response = await firmwareDeployCommand(
        session,
        selectedStation[1],
        selectedStation[2],
        payload,
      )

      if (response.valid) {
        setIsUploading(false)
        return toastMessageHandler(
          'FIRMWARE DEPLOY COMMAND COMPLETE',
          selectedStation[1] +
            ':' +
            selectedStation[2] +
            ' - ' +
            selectedFirmwareType +
            ' - ' +
            majorValue +
            '.' +
            minorValue +
            '.' +
            revisionValue,
          'complete',
          'toastInfo',
        )
      }

      setIsUploading(false)
      return toastMessageHandler(
        'FIRMWARE DEPLOY COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }
    executeCommand()

    setTimeout(() => {
      setIsUploading(false)
      toastMessageHandler(
        'FIRMWARE DEPLOY COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }, 30000)
  }

  const toastMessageHandler = (title, body, type, toastId) => {
    return ToastMessage(title, body, type, toastId)
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        Firmware Deploy Command
      </Typography>
      <Styles.FormRow>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            STATION :: BLOCK INDEX
          </Typography>
          <ToolbarDropdown
            title={selectedStation[0]}
            width="260px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            overflow="scroll"
            maxHeight="447px"
          >
            {data?.blocks.map((x) => (
              <Styles.StationDropItem
                $backgroundColor={
                  x.stationCode + ' :: ' + x.blockIndex === selectedStation[0]
                }
                $isConnected={x.valid}
                key={x.stationCode}
                onClick={() =>
                  setSelectedStationHandler(
                    x.stationCode,
                    x.blockIndex,
                    x.valid,
                  )
                }
                onKeyDown={null}
              >
                {x.stationCode + ' :: ' + x.blockIndex}
                {!x.valid && ' - Disconnected'}
              </Styles.StationDropItem>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            FIRMWARE TYPE
          </Typography>
          <ToolbarDropdown
            title={selectedFirmwareType}
            width="260px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
          >
            {firmwareTypes.map((type) => (
              <option
                style={{
                  cursor: 'pointer',
                  padding: '4px',
                  color: 'black',
                  backgroundColor:
                    type === selectedFirmwareType ? COLORS.light_grey : '',
                }}
                key={type}
                onClick={() => setSelectedFirmwareTypeHandler(type)}
                onKeyDown={null}
              >
                {type}
              </option>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            MAJOR
          </Typography>
          <FormInput
            id="major"
            onChange={(e) => onChangeHandler(e, 'major')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            MINOR
          </Typography>
          <FormInput
            id="minor"
            onChange={(e) => onChangeHandler(e, 'minor')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            REVISION
          </Typography>
          <FormInput
            id="revision"
            onChange={(e) => onChangeHandler(e, 'revision')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
      </Styles.FormRow>
      <FormSubmitButton
        $width="140px"
        $marginTop="25px"
        $isValid={isFormValid}
        disabled={!isFormValid}
        onClick={(e) => {
          onSubmitHandler(e)
        }}
      >
        DEPLOY
      </FormSubmitButton>
    </Styles.Container>
  )
}
