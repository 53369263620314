import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Component Imports
import DetailsPage from '../../../components/Layouts/DetailsPage/DetailsPage'
import ACBatteryDetailsMainColumn from './MainColumn/ACBatteryDetailsMainColumn'
import ACBatteryOneline from '../../../components/Onelines/ACBatteryOneline/ACBatteryOneline'
import ACBatteryDetailsSummary from './Summary/ACBatteryDetailsSummary'

const ACBatteryDetails = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acBatteryId } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'ac-battery-details' })
    document.title = `${stationCode}::${blockIndex} - AC Battery ${acBatteryId}`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<ACBatteryDetailsSummary />}
      onelineComponent={<ACBatteryOneline />}
      mainColumnComponent={<ACBatteryDetailsMainColumn />}
    />
  )
}

export default ACBatteryDetails
