import EquipmentSummaryCard from 'components/Cards/EquipmentSummaryCard'
import { ReactComponent as DcDcConverterIcon } from 'icons/dc-dc-converter-icon.svg'
import { useParams } from 'react-router-dom'

export const SummaryCardDcDcGroup = ({ dcDcPcs }) => {
  const {
    dcDcPcsIndex,
    acPvBatteryIndex,
    dcDcGroupIndex,
    healthy,
    dcPowerBatteryKW,
    dcVoltageBatteryVolt,
    dcCurrentBatteryAmp,
  } = dcDcPcs
  const { stationCode, blockIndex } = useParams()

  return (
    <EquipmentSummaryCard
      icon={<DcDcConverterIcon />}
      title={`DC:DC PCS ${dcDcPcsIndex}`}
      isHealthy={healthy}
      link={
        `/block-details/${stationCode}/${blockIndex}/block-topology` +
        `/ac-pv-battery/${acPvBatteryIndex}/dc-dc-group/${dcDcGroupIndex}/dc-dc-pcs/${dcDcPcsIndex}`
      }
      rows={[
        {
          label: 'Power',
          value: `${dcPowerBatteryKW.toLocaleString()} kW`,
        },
        {
          label: 'Voltage',
          value: `${dcVoltageBatteryVolt.toLocaleString()} VDC`,
        },
        {
          label: 'Current',
          value: `${dcCurrentBatteryAmp.toLocaleString()} A`,
        },
      ]}
    />
  )
}
