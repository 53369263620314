import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'

const inverterDidUpdate = (inverterList, commandForm) => {
  let ret = false
  inverterList.arrayPcses.forEach((inverter) => {
    if (inverter.outRotation === (commandForm.outRotation === 'true')) {
      ret = true
    }
  })
  return ret
}

const heading = 'IN ROTATION'

const ResultTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.outRotation === 'false' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
      )}
    </>
  )
}

export const InverterRotationResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={inverterDidUpdate}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={'inverterListFunc'}
    />
  )
}
