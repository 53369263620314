import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  ShowHealthyOrUnhealthyTableValue,
  TreatMemoryValue,
} from '../tableUtils'

const EnvironmentalControllersTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const getRowId = (row) => Number(row.featherId)

  const idCell = (row) => (
    <Styles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/environmental-controllers${getRowId(
        row,
      )}`}
    >
      {getRowId(row)}
    </Styles.RowLink>
  )

  const columns = [
    {
      name: 'LOCATION',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: idCell,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
    },
    {
      name: 'IP ADDRESS',
      id: 'ipAddress',
      selector: (row) => row.ip,
      sortable: true,
      width: '90px',
      cell: (row) => row.ip,
      noOmit: false,
      omit: false,
    },
    {
      name: 'VERSION',
      id: 'version',
      selector: (row) => row.controllerVer,
      sortable: true,
      width: '75px',
      cell: (row) => row.controllerVer,
      noOmit: false,
      omit: false,
    },
    {
      name: 'HD TOTAL',
      id: 'hdTotal',
      selector: (row) => row.hardDrive.total,
      sortable: true,
      width: '100px',
      cell: (row) => TreatMemoryValue(row.hardDrive.total),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'HD AVAIL',
      id: 'hdAvail',
      selector: (row) => row.hardDrive.available,
      sortable: true,
      width: '100px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.hardDrive.health,
          TreatMemoryValue(row.hardDrive.available),
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'MEM TOTAL',
      id: 'memTotal',
      selector: (row) => row.memory.total,
      sortable: true,
      width: '100px',
      cell: (row) => TreatMemoryValue(row.memory.total),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'MEM AVAIL',
      id: 'memAvail',
      selector: (row) => row.memory.available,
      sortable: true,
      width: '100px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.memory.health,
          TreatMemoryValue(row.memory.available),
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'MEM FREE',
      id: 'memFree',
      selector: (row) => row.memory.free,
      sortable: true,
      width: '100px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.memory.health,
          TreatMemoryValue(row.memory.free),
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'SWAP TOTAL',
      id: 'swapTotal',
      selector: (row) => row.memory.swapTotal,
      sortable: true,
      width: '100px',
      cell: (row) => TreatMemoryValue(row.memory.swapTotal),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'SWAP AVAIL',
      id: 'swapAvail',
      selector: (row) => row.memory.swapAvailable,
      sortable: true,
      width: '100px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.memory.swapHealth,
          TreatMemoryValue(row.memory.swapAvailable),
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'JVM TOTAL',
      id: 'jvmTotal',
      selector: (row) => row.memory.jvmTotal,
      sortable: true,
      width: '100px',
      cell: (row) => TreatMemoryValue(row.memory.jvmTotal),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'JVM AVAIL',
      id: 'jvmAvail',
      selector: (row) => row.memory.jvmAvailable,
      sortable: true,
      width: '100px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.memory.jvmHealth,
          TreatMemoryValue(row.memory.jvmAvailable),
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PROCS',
      id: 'procs',
      selector: (row) => row.cpu.procsAvailable,
      sortable: true,
      width: '80px',
      cell: (row) => row.cpu.procsAvailable,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: '1 MIN LOAD AVG',
      id: 'oneMinLoadAvg',
      selector: (row) => row.cpu.loadAvgOne.value,
      sortable: true,
      width: '120px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.cpu.loadAvgOne.health,
          row.cpu.loadAvgOne.value,
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: '5 MIN LOAD AVG',
      id: 'fiveMinLoadAvg',
      selector: (row) => row.cpu.loadAvgFive.value,
      sortable: true,
      width: '120px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.cpu.loadAvgFive.health,
          row.cpu.loadAvgFive.value,
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: '15 MIN LOAD AVG',
      id: 'fifteenMinLoadAvg',
      selector: (row) => row.cpu.loadAvgFifteen.value,
      sortable: true,
      width: '120px',
      cell: (row) =>
        ShowHealthyOrUnhealthyTableValue(
          row.cpu.loadAvgFifteen.health,
          row.cpu.loadAvgFifteen.value,
        ),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'UPTIME',
      id: 'uptime',
      selector: (row) => row.uptime,
      sortable: true,
      width: '100px',
      cell: (row) =>
        `${new Date(row.uptime * 1000).toISOString().slice(11, 19)}`,
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  return (
    <Table
      tableId="EnvironmentalControllersTable"
      data={data}
      columns={columns}
      getRowId={getRowId}
      defaultId="id"
      fixedHeader={fixedHeader}
      pagination
    />
  )
}

export default EnvironmentalControllersTable
