import _ from 'lodash'

// Component Imports
import ToolbarRangeFilter from '../../../../../../components/Toolbar/ToolbarRangeFilter'

const AnalysisArrayRangeFilter = ({ data, handleChange }) => {
  const rangeArray = _.map(data, (d) => d.arrayIndex)

  return (
    <ToolbarRangeFilter
      data={data}
      range={rangeArray}
      category="Array Range"
      handleChange={handleChange}
    />
  )
}

export default AnalysisArrayRangeFilter
