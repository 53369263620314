import { PlugInStatusForm } from './form/PluginStatusForm'
import PlugInStatusResult from './result/PluginStatusResult'
import CommandModalTemplate from '../CommandModalTemplate'

const PlugInStatusModal = (props) => {
  const { ids, setShowCommandModal } = props
  const modalTitles = {
    start: 'Set PlugIn Status',
    finish: 'PlugIn Status Set',
  }
  const queryName = 'setPlugInStatus'

  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={{ enabled: 'choose' }}
      CommandResultJsx={PlugInStatusResult}
      UserInputFormJsx={PlugInStatusForm}
      queryName={queryName}
      isFormValidated={true}
    />
  )
}

export default PlugInStatusModal
