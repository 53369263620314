import _ from 'lodash'
import { useContext, useState, useReducer } from 'react'
import { useParams } from 'react-router-dom'

// Component Imports
import Icon from 'components/Icon/Icon'
import StationList from '../StationList/StationList'

// Style Imports
import * as Styles from './styles'

// Icon Imports
import { ReactComponent as AnalysisIcon } from 'icons/analysis-icon.svg'
import { ReactComponent as AlertIcon } from 'icons/bell-icon.svg'
import { ReactComponent as CaretDownIcon } from 'icons/caret-down-icon.svg'
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg'
import { ReactComponent as InterconnectIcon } from 'icons/interconnect-icon.svg'
import { ReactComponent as MenuIcon } from 'icons/menu-icon.svg'
import { ReactComponent as SuperSnapIcon } from 'icons/supersnap-icon.svg'
import { ReactComponent as ShieldIcon } from 'icons/shield-icon.svg'
import { ReactComponent as PowinLogo } from 'icons/logo.svg'
import { ReactComponent as DownloadIcon } from 'icons/download-icon.svg'
import { ReactComponent as NotificationIcon } from 'icons/notification-icon.svg'

// Util Imports
import { downloadSuperSnap } from 'api/queries.api'
import { SessionContext } from 'contexts/session'
import useDetectClickOut from 'hooks/useDetectClickOut.hooks'
import { getAcSystemTopology } from './topology/ac-system-topology'
import { getDcSystemTopology } from './topology/dc-system-topology'
import { getSystemEnvironmentalDevices } from './topology/EnvironmentalDevices'
import useEnvironmentalDevicesQuery from 'api/useQueryHooks/useEnvironmentalDevicesQuery'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'
import useElectricalDevicesQuery from 'api/useQueryHooks/useElectricalDevicesQuery'
import { AuthorizationContext } from 'contexts/authorizations.context'
import FleetlistActionModalReducer from 'modules/Dashboard/modules/FleetList/reducers/FleetlistActionModalReducer'
import { ACTION_MODAL_ACTIONS } from 'modules/Dashboard/modules/FleetList/actions/action-modal.actions'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

const StationNavDrawer = ({ selectedPage }) => {
  const { setShow, show, nodeRef, triggerRef } = useDetectClickOut(false)
  const params = useParams()
  const { session } = useContext(SessionContext)
  const [showStationList, setShowStationList] = useState(false)
  const [showCommandModal, setShowCommandModal] = useState(false)
  const { hasEnabledFlags, userHasApiAccessCode } =
    useContext(AuthorizationContext)

  const isPcsGroupsEnabled = userHasApiAccessCode(API_ACCESS_CODES.THREE_DA)

  const { data: environmentalDevices } = useEnvironmentalDevicesQuery({
    refetchInterval: false,
  })

  const { data: blockStatusData, isLoading } = useBlockStatus()

  const { data: electricalDevicesData } = useElectricalDevicesQuery({
    refetchInterval: false,
  })

  const [ActionModalStateJsx, actionModalDispatch] = useReducer(
    FleetlistActionModalReducer,
    null,
  )

  if (isLoading) return <LoadingPage />

  const { isDcCoupled } = blockStatusData

  const handleExtractReportsClick = () => {
    setShow(false)
    actionModalDispatch({
      type: ACTION_MODAL_ACTIONS.SHOW_EXTRACT_REPORT_MODAL,
      setShowCommandModal,
    })
    !showCommandModal && setShowCommandModal(false)
  }

  const appSections = [
    {
      sectionId: 'system-details',
      sections: [
        {
          id: 'systemDetails',
          label: 'System Details',
          url: '',
          icon: <InterconnectIcon />,
          subSections: [],
        },
      ],
    },
    {
      sectionId: 'electrical-devices',
      label: 'Electrical Devices',
      sections: (isDcCoupled ? getDcSystemTopology : getAcSystemTopology)(
        blockStatusData,
        electricalDevicesData,
        params,
        {
          isPcsGroupsEnabled: isPcsGroupsEnabled,
        },
      ),
    },
    {
      sectionId: 'environmental-devices',
      label: 'Environmental Devices',
      sections: getSystemEnvironmentalDevices(
        blockStatusData,
        environmentalDevices,
      ),
    },
    {
      sectionId: 'systemAnalysis',
      label: 'System Analysis',
      sections: [
        {
          id: 'analysis',
          label: 'Analysis',
          url: `analysis`,
          icon: <AnalysisIcon />,
          subSections: [],
        },
      ],
    },
    {
      sectionId: 'alerts',
      label: 'Alerts',
      omit: false,
      sections: [
        {
          id: 'alertManagement',
          label: 'Alert Management',
          url: `alert-management`,
          icon: <AlertIcon />,
          subSections: [],
          omit: false,
        },
        {
          id: 'notifications',
          label: 'Notifications',
          url: `notifications`,
          icon: <NotificationIcon />,
          subSections: [],
        },
      ],
    },
    {
      sectionId: 'dataExtracts',
      label: 'Data Extracts',
      sections: [
        {
          id: 'superSnap',
          label: 'Supersnap',
          icon: <SuperSnapIcon />,
          subSections: [],
        },
        {
          id: 'extractReports',
          label: 'Extract Reports',
          icon: <DownloadIcon />,
          omit: false,
          subSections: [],
        },
      ],
    },
  ]

  const renderSubSections = (subSections) => {
    return subSections.map((s) => {
      if (!s.omit) {
        return (
          <div key={s.id}>
            <Styles.SubLink
              to={s.url}
              onClick={() => setShow(false)}
              $isSelected={selectedPage === s.id}
            >
              <Icon icon={s.icon} size="xs" />
              <Styles.Label>{s.label}</Styles.Label>
            </Styles.SubLink>
          </div>
        )
      }
    })
  }

  const renderSections = (sections) => {
    return _.map(sections, (s) => {
      if (s.id === 'superSnap') {
        return (
          <div
            key={s.id}
            onClick={() => {
              downloadSuperSnap(params.stationCode, params.blockIndex, session)
            }}
          >
            <Styles.SectionNonLink>
              <Icon icon={s.icon} size="xs" />
              <Styles.Label>{s.label}</Styles.Label>
            </Styles.SectionNonLink>
            {!_.isEmpty(s.subSections) && renderSubSections(s.subSections)}
          </div>
        )
      }

      if (s.id === 'extractReports') {
        return (
          <div key={s.id} onClick={handleExtractReportsClick}>
            <Styles.SectionNonLink>
              <Icon icon={s.icon} size="xs" />
              <Styles.Label>{s.label}</Styles.Label>
            </Styles.SectionNonLink>
            {!_.isEmpty(s.subSections) && renderSubSections(s.subSections)}
          </div>
        )
      }

      if (!s.omit) {
        return (
          <div key={s.id}>
            <Styles.SectionLink
              to={s.url}
              onClick={() => setShow(false)}
              $isSelected={selectedPage === s.id}
            >
              <Icon icon={s.icon} size="xs" />
              <Styles.Label>{s.label}</Styles.Label>
            </Styles.SectionLink>
            {!_.isEmpty(s.subSections) && renderSubSections(s.subSections)}
          </div>
        )
      }
    })
  }

  const RenderNavigation = () => {
    return _.map(appSections, (s) => {
      if (!s.omit) {
        return (
          <Styles.SectionContainer key={s.sectionId}>
            <Styles.SectionLabel>{s.label}</Styles.SectionLabel>
            <div>{renderSections(s.sections)}</div>
          </Styles.SectionContainer>
        )
      }
    })
  }

  return (
    <>
      <Styles.Container>
        <Styles.Button ref={triggerRef}>
          <Styles.NavIconContainer>
            <Icon icon={<MenuIcon />} size="xs" color="#797979" />
          </Styles.NavIconContainer>
          <Styles.SelectedBlock>
            {params.stationCode} :: {params.blockIndex}
          </Styles.SelectedBlock>
        </Styles.Button>
        {show && (
          <Styles.ModalContainer>
            <Styles.Background />
            <Styles.Modal ref={nodeRef} $isOpen={show}>
              <Styles.IdentityContainer>
                <Styles.LogoContainer to={`/`}>
                  <PowinLogo />
                </Styles.LogoContainer>
                <Styles.AppName to={`/`}>
                  Command Center <Styles.Light>- StackOS</Styles.Light>
                </Styles.AppName>
              </Styles.IdentityContainer>
              <Styles.ModalTitle onClick={() => setShow(false)}>
                <Styles.NavIconContainer>
                  <Icon icon={<CloseIcon />} size="xs" color="#797979" />
                </Styles.NavIconContainer>
                <Styles.SelectedBlock>
                  {params.stationCode} :: {params.blockIndex}
                </Styles.SelectedBlock>
              </Styles.ModalTitle>
              <Styles.ScrollContent>
                <Styles.StationSelectContainer>
                  <Styles.ToggleButton
                    onClick={() => setShowStationList(!showStationList)}
                  >
                    <Styles.CaretIcon $isOpen={showStationList}>
                      <CaretDownIcon />
                    </Styles.CaretIcon>
                    Switch Stations
                  </Styles.ToggleButton>
                  {showStationList && (
                    <Styles.StationListContainer>
                      <StationList
                        selectedBlock={`${params.stationCode} :: ${params.blockIndex}`}
                        setShow={setShowStationList}
                        show={showStationList}
                      />
                    </Styles.StationListContainer>
                  )}
                </Styles.StationSelectContainer>
                <RenderNavigation />
              </Styles.ScrollContent>
            </Styles.Modal>
          </Styles.ModalContainer>
        )}
      </Styles.Container>
      {showCommandModal && <ActionModalStateJsx />}
    </>
  )
}

export default StationNavDrawer
