export const API_ACCESS_CODES = {
  ZERO_U: '0U', // User Data. Current user's own state, the state of their existing session, usage statistics, and setting/reading their own preferences.
  ONE_BPE: '1BPE', // Block Power and Energy. Basic requests about a specific block : Available and actual power and energy (including SOC).
  TWO_2B: '2B', // Block. block level details, including summary information about individual connected devices.
  THREE_DA: '3DA', //Devices and Arrays. Balancing, detailed data about individual devices and arrays, and summary information about stacks.
  FOUR_S: '4S', //Stacks. Detailed data about stacks.
  FIVE_C: '5C', //Cell Groups. Detailed data about cell groups.
  SIX_A: '6A', // Alerts. Alert Management, details about Alerts.
  SEVEN_SC: '7SC', // String Control: contactor, rotation, balancing
  EIGHT_PC: '8PC', // PCS Control: Rotation
  NINE_AC: '9AC', // App Control: Enable, Disable
  TEN_UI: '10UI', // UI Lookups used by front end code for lists, drop-downs, autocompletes, constants
}
