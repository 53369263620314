import _ from 'lodash'

// Component Imports
import SummaryColumn from '../../../components/SummaryColumn/SummaryColumn'

// Icon Imports
import { ReactComponent as AnalysisIcon } from '../../../../../icons/analysis-icon.svg'

const Summary = ({ data, minArray, maxArray }) => {
  const summaryMap = {
    title: 'Station Analysis',
    titleIcon: <AnalysisIcon />,
    subtitle: `Array Range: ${minArray} - ${maxArray}`,
    sections: [
      {
        sectionTitle: 'Temperature',
        rows: [
          {
            label: 'Max Temperature',
            value: `${_.chain(data)
              .maxBy('cellTemperature100')
              .get('cellTemperature100')
              .round(1)
              .value()
              .toLocaleString()}° C`,
          },
          {
            label: 'Min Temperature',
            value: `${_.chain(data)
              .maxBy('cellTemperature000')
              .get('cellTemperature000')
              .round(1)
              .value()
              .toLocaleString()}° C`,
          },
        ],
      },
      {
        sectionTitle: 'Voltage',
        rows: [
          {
            label: 'Max Voltage',
            value: `${_.chain(data)
              .maxBy('cellVoltage100')
              .get('cellVoltage100')
              .round(1)
              .value()
              .toLocaleString()} mV`,
          },
          {
            label: 'Min Voltage',
            value: `${_.chain(data)
              .minBy('cellVoltage000')
              .get('cellVoltage000')
              .round(1)
              .value()
              .toLocaleString()} mV`,
          },
        ],
      },
      {
        sectionTitle: 'String Health',
        rows: [
          {
            label: 'Connected',
            value: _.sumBy(data, 'connectedCount').toLocaleString(),
          },
          {
            label: 'In Rotation',
            value: _.sumBy(data, 'inRotationCount').toLocaleString(),
          },
          {
            label: 'Total',
            value: _.chain(data).sumBy('stringCount').value().toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default Summary
