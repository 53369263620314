import { FormRow } from 'components/Form/FormComponents.styled'
import { StringContainer } from '../ContactorsFormUi.styles'
import { SelectWithBottomPadding } from '../ContactorsFormUi.styles'
import { useParams } from 'react-router-dom'
import { useSiteControllerVersionContext } from 'contexts/siteControllerVersionContext'
import ContactorState from 'api/types/ContactorState'
import EnablementState from 'api/types/EnablementState'

export const ContactorsFormUi = ({ ids, userInputForm, setUserInputForm }) => {
  const handleUserInputFormChange = (name, e) => {
    if (e?.target) {
      const update = { ...userInputForm }
      update[name] = e.target.value
      setUserInputForm(update)
    }
  }

  const { stationCode } = useParams()
  const { isOpModel2 } = useSiteControllerVersionContext()
  const stackIdsDisplay = ids.join(', ').replace(/:/g, ' :: ')
  return (
    <>
      <StringContainer>
        <label>Strings</label> <span>{stackIdsDisplay}</span>
      </StringContainer>
      <FormRow>
        <label>Contactor State</label>
      </FormRow>
      <FormRow>
        <SelectWithBottomPadding
          value={userInputForm.contactorState}
          onChange={(e) => handleUserInputFormChange('contactorState', e)}
        >
          <option value="" disabled>
            Choose
          </option>
          {Object.keys(ContactorState).map((contactorState) => (
            <option
              key={ContactorState[contactorState]}
              value={ContactorState[contactorState]}
            >
              {contactorState}
            </option>
          ))}
        </SelectWithBottomPadding>
      </FormRow>
      {isOpModel2(stationCode) && (
        <>
          <FormRow>
            <label>Ignore High CG Voltage Alarm</label>
            <SelectWithBottomPadding
              value={userInputForm.ignoreHighCellGroupVoltageAlarm}
              onChange={(e) =>
                handleUserInputFormChange('ignoreHighCellGroupVoltageAlarm', e)
              }
            >
              <option value="" disabled>
                Choose
              </option>
              {Object.keys(EnablementState).map((enablementState) => (
                <option
                  key={EnablementState[enablementState]}
                  value={EnablementState[enablementState]}
                >
                  {enablementState}
                </option>
              ))}
            </SelectWithBottomPadding>
          </FormRow>
          <FormRow>
            <label>Ignore Low CG Voltage Alarm</label>
            <SelectWithBottomPadding
              value={userInputForm.ignoreLowCellGroupVoltageAlarm}
              onChange={(e) =>
                handleUserInputFormChange('ignoreLowCellGroupVoltageAlarm', e)
              }
            >
              <option value="" disabled>
                Choose
              </option>
              {Object.keys(EnablementState).map((enablementState) => (
                <option
                  key={EnablementState[enablementState]}
                  value={EnablementState[enablementState]}
                >
                  {enablementState}
                </option>
              ))}
            </SelectWithBottomPadding>
          </FormRow>
        </>
      )}
    </>
  )
}
