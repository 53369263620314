import styled from 'styled-components/macro'
import DOMPurify from 'dompurify'

const StackOsAppsDetails = ({ data }) => {
  const altValue = (data) =>
    data.shortStatus ? data.shortStatus : 'Status Not Available'
  const finalValue = data.longStatus ? data.longStatus : altValue(data)
  return (
    <Container>
      <Label>Status</Label>
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalValue) }}
      />
    </Container>
  )
}

export default StackOsAppsDetails

const Container = styled.div`
  padding: 10px 25px 20px;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
  margin-top: -1px;
  font-size: 12px;
`
const Label = styled.div`
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #989ea0;
`
