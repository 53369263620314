import Icon from 'components/Icon/Icon'
import { ReactComponent as CaretRightIcon } from 'icons/caret-right-icon.svg'
import { ReactComponent as CaretDownIcon } from 'icons/caret-down-icon.svg'
import * as Styles from './Accordion.styles'
import { COLORS } from 'design_system/colors'

export const Accordion = (props) => {
  const {
    children,
    expanded,
    iconCollapsed = <CaretRightIcon />,
    iconExpanded = <CaretDownIcon />,
    iconColor = COLORS.powin_green,
    iconPosition = 'left',
    iconSize = 'xxs',
    onChange,
    summary,
  } = props

  return (
    <Styles.Container>
      <Styles.SummaryContainer $iconPosition={iconPosition} onClick={onChange}>
        <Icon
          size={iconSize}
          color={iconColor}
          icon={expanded ? iconExpanded : iconCollapsed}
        />
        <Styles.SummaryWrapper>{summary}</Styles.SummaryWrapper>
      </Styles.SummaryContainer>
      <Styles.ContentContainer $expanded={expanded}>
        {children}
      </Styles.ContentContainer>
    </Styles.Container>
  )
}
