/* use kebab-case */
export const FEATURE_TOGGLES = {
  FILE_GRAB_COMMAND: 'ccui-admin-command-file-grab',
  FILE_LIST_COMMAND: 'ccui-admin-command-file-list',
  ALERT_MANAGEMENT_NOTES: 'ccui-alert-management-notes',
  DOWNLOAD_CONFIGURATION: 'ccui-admin-command-download-configuration',
  DRAGON_KEY_LOCK: 'ccui-admin-command-dragon-key-lock',
  OS_PATCH: 'ccui-admin-command-os-patch',
  DC_DC_GROUP_ROTATION_COMMAND: 'ccui-dc-dc-group-rotation-command',
  PV_PCS_ROTATION_COMMAND: 'ccui-pv-pcs-rotation-command',
  DC_DC_PCS_ROTATION_COMMAND: 'ccui-dc-dc-pcs-rotation-command',
  CELL_GROUP_NOTIFICATION_EVENT: 'ccui-cell-group-notification-event',
}
