import React from 'react'
import { Link, useParams } from 'react-router-dom'

import * as Styles from './styles'

// Icon Imports
import { ReactComponent as ACBatteryIcon } from '../../../../../../icons/ac-battery-icon.svg'
import { ReactComponent as CheckIcon } from '../../../../../../icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../../../../icons/error-icon.svg'

const ACBatteryNode = ({ data }) => {
  const params = useParams()

  return (
    <Styles.Container isConnected={data.valid}>
      <Styles.BusBar />
      <Styles.ContactorBar contactorsClosed={data.ready} />
      <Link
        to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${data.acBatteryIndex}`}
      >
        <Styles.Tile
          isHealthy={data.ready}
          data-tooltip-id={`battery${data.acBatteryIndex}Tooltip`}
        >
          <Styles.Flex>
            <Styles.TitleRow>
              <Styles.Icon>
                <ACBatteryIcon />
              </Styles.Icon>
              <Styles.Title>{data.acBatteryIndex}</Styles.Title>
            </Styles.TitleRow>
            <Styles.Row>
              <Styles.Label>Ready:</Styles.Label>
              <Styles.Value>
                <Styles.StatusIcon isHealthy={data.ready}>
                  {data.ready ? <CheckIcon /> : <ErrorIcon />}
                </Styles.StatusIcon>
              </Styles.Value>
            </Styles.Row>
            <Styles.Row>
              <Styles.Label>SoC:</Styles.Label>
              <Styles.Value>
                {data.soc}% <Styles.SocBar soc={data.soc} />
              </Styles.Value>
            </Styles.Row>
          </Styles.Flex>
          {!data.enabled && <Styles.AlertBanner>Disabled</Styles.AlertBanner>}
        </Styles.Tile>
      </Link>
    </Styles.Container>
  )
}

export default ACBatteryNode
