import styled from 'styled-components/macro'

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`
export const Value = styled.span`
  color: #333;
  margin: 0 5px;
`
export const Select = styled.select`
  flex: 1;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  background-color: transparent;
  padding: 2px 4px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`
export const Dash = styled.div`
  margin: 0 5px;
  color: #798b95;
`
