import styled from 'styled-components/macro'

export const Select = styled.select`
  margin-bottom: 20px;
`
export const StringContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`

export const Container = styled.div`
  width: 270px;
`
