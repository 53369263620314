import _ from 'lodash'

// Style Imports
import * as Styles from './alertsTable.styledComponents'
import './alertsTable.css'
// Component Imports
import Table from '../../../../../components/Table/Table/Table'
import PercentBarChart from '../../../../../components/Charts/PercentBarChart/PercentBarChart'

// Icons Import
import { ReactComponent as AlertIcon } from '../../../../../icons/bell-icon.svg'
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { nanoid } from 'nanoid'
import NoTableData from 'components/Table/Table/NoTableData'
dayjs.extend(LocalizedFormat)

const AlertsTable = ({ data, fixedHeader = false, pagination }) => {
  const getRowId = (row) => row.id
  const filteredStationAlerts = data?.filter(
    (alert) => alert.alertStatus !== 'Resolved',
  )

  if (filteredStationAlerts.length === 0) return <NoTableData />

  const columns = [
    {
      name: (
        <div
          style={{
            fill: '#abaeb1',
            width: 17,
            height: 17,
            maxWidth: 47,
            marginLeft: 4,
          }}
        >
          <AlertIcon />
        </div>
      ),
      id: 'severity',
      selector: (row) => row.severity,
      sortable: true,
      cell: (row) => SeverityIconColumn(row.severity),
      noOmit: true,
      width: '3rem',
    },
    {
      name: 'Name',
      id: 'bessStatusCodeName',
      selector: (row) => row.bessStatusCodeName,
      sortable: true,
      width: '15rem',
      cell: (row) => BessStatusCodeNameColumn(row.bessStatusCodeName),
      noOmit: true,
    },
    {
      name: 'Code',
      id: 'bessStatusCode',
      selector: (row) => row.bessStatusCode,
      sortable: true,
      cell: (row) => row.bessStatusCode,
      omit: false,
      noOmit: false,
      width: '7rem',
    },
    {
      name: 'Assignee',
      id: 'assignee',
      selector: (row) => row.assignee,
      sortable: true,
      cell: (row) => (row.assignee ? row.assignee : 'Unassigned'),
      omit: false,
      noOmit: false,
      width: '10rem',
    },
    {
      name: 'Extent',
      id: 'extentValue',
      selector: (row) => row.extentValue,
      sortable: true,
      cell: (row) => (
        <PercentBarChart key={nanoid()} percent={row.extentValue} />
      ),
      omit: false,
      noOmit: false,
      width: '6rem',
    },
    {
      name: 'Age',
      id: 'age',
      selector: (row) => row.startTimestamp.epochMillis,
      sortable: true,
      cell: (row) => dayjs(_.get(row.startTimestamp, 'epochMillis')).fromNow(),
      omit: false,
      noOmit: false,
      width: '6rem',
    },
    {
      name: 'Start Time',
      id: 'startTimestamp',
      selector: (row) => row.startTimestamp.epochMillis,
      sortable: true,
      cell: (row) =>
        dayjs(_.get(row.startTimestamp, 'epochMillis')).format('lll'),
      omit: false,
      noOmit: false,
      width: '11rem',
    },
  ]

  return (
    <div style={{ width: '1000px' }} className="rowTableStyle">
      <Table
        tableId="AlertsTable"
        data={filteredStationAlerts}
        columns={columns}
        getRowId={getRowId}
        fixedHeader={fixedHeader}
        pagination={pagination}
        defaultId="severity"
      />
    </div>
  )
}

export default AlertsTable

const SeverityIconColumn = (severity) => (
  <Styles.IconContainer $severity={severity}>
    <Styles.Severity>{severity}</Styles.Severity>
    <AlertIcon />
  </Styles.IconContainer>
)

const BessStatusCodeNameColumn = (bessStatusCodeName) => (
  <Styles.Name>{bessStatusCodeName}</Styles.Name>
)
