import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
  width: 100%;
  background: repeating-linear-gradient(
    135deg,
    #f05946,
    #f05946 5px,
    #aa3f31 5px,
    #aa3f31 10px
  );
  opacity: ${(props) => props.$opacity};
  border: solid 0.5px grey;
`
export const Bar = styled.div`
  width: ${(props) => props.$percent * 100}%;
  height: 100%;
`
export const GreenBar = styled(Bar)`
  background-color: #00b985;
`
