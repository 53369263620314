import styled from 'styled-components/macro'

export const Container = styled.div`
  z-index: 0;
  pointer-events: auto;
  border-right: solid rgba(0, 0, 0, 0.12) 1px;
  width: 85%;
  height: 100%;
  margin-left: 5%;
  padding-top: 5%;
  align-items: center;
  display: flex;
`
