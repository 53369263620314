import { useEffect, useReducer, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Table from 'components/Table/Table/Table'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import * as Styles from '../table.styled'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { SetInverterRotationModal } from '../../Commands/InverterRotation/SetInverterRotationModal'
import { getPCSStatusMessage } from 'utils/pcs'
import { alphanumericSort } from 'utils/sort'
import {
  RowRotationWithTooltip,
  sortIdsWithColonRecursive,
  useIsStationConnected,
} from '../tableUtils'

import Tooltip from 'components/Tooltip'
import { ClearDeviceAlertsModal } from '../../Commands/ClearDeviceAlerts/ClearDeviceAlertsModal'
import { ELECTRICAL_DEVICE_TYPES } from '../../ElectricalDevices/constants'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showInverterRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetInverterRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showClearFaultsModal': {
      action.setShowCommandModal(true)
      return () => (
        <ClearDeviceAlertsModal
          setShowCommandModal={action.setShowCommandModal}
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          faultingDevices={action.faultingDevices}
          deviceType={action.deviceType}
        />
      )
    }
    default:
      return null
  }
}

/////////////////////////////////////////////////////////////////

const PCSTable = ({ fixedHeader = true, data }) => {
  const [isIdSortable, setIsIdSortable] = useState(false) // sortable by id after first render, as it sorts by selector instead for first render
  const params = useParams()
  const { stationCode, blockIndex } = params
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  useEffect(() => {
    setIsIdSortable(true)
  }, [])

  const { userHasApiAccessCode } = useContext(AuthorizationContext)
  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)

  const [activeActionRow, setActiveActionRow] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])

  const handleSelectedRowsChange = (rowsObject) => {
    setSelectedIds(
      Object.keys(rowsObject).filter((key) => rowsObject[key]), //returns array of ids where key value is true
    )
  }

  const customStatusSortFn = (a, b) => {
    const aStatus = getPCSStatusMessage(a)
    const bStatus = getPCSStatusMessage(b)

    return alphanumericSort(aStatus, bStatus)
  }

  const getRowId = (row) => `${row.arrayIndex}:${row.arrayPcsIndex}`

  const rowHasFaults = (row) => {
    return Boolean(row.faults.length)
  }

  const rowIsHealthy = (row) => {
    return row.valid && row.ready && !rowHasFaults(row)
  }

  const conditionalRowStyles = [
    {
      when: (row) =>
        getRowId(row) === activeActionRow ||
        selectedIds.includes(getRowId(row)),
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  // open/close actions menu modal
  const onOpen = (row) => {
    setActiveActionRow(getRowId(row))
  }

  const onClose = () => {
    setActiveActionRow(null)
  }

  const _tableActions = isStationConnected && userHas8PC && (
    <Styles.ButtonContainer>
      <FormCommandButton
        disabled={selectedIds.length === 0}
        onClick={() => {
          modalDispatch({
            type: 'showInverterRotationModal',
            setShowCommandModal: setShowCommandModal,
            open: true,
            idsForModal: selectedIds,
          })
        }}
      >
        <Icon
          size="xxs"
          color={selectedIds.length === 0 ? '#cccccc' : COLORS.action_blue}
          icon={<CheckIcon />}
        />
        Set PCS Rotation
      </FormCommandButton>
    </Styles.ButtonContainer>
  )

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit:
        !isStationConnected || !userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC),
      noOmit: true,
      width: '55px',
      cell: (row) => (
        <ActionsMenu
          key={row}
          onOpen={() => onOpen(row)}
          onClose={() => onClose()}
        >
          <ActionsMenuModalContent
            id={getRowId(row)}
            modalDispatch={modalDispatch}
            setShowCommandModal={setShowCommandModal}
            stationCode={stationCode}
            blockIndex={blockIndex}
            resetableFaults={
              row.topologyEntry?.allowFaultReset ? row.faults : []
            }
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: isIdSortable,
      sortFunction: (a, b) =>
        sortIdsWithColonRecursive(getRowId(a), getRowId(b)),
      width: '60px',
      cell: (row) => (
        <Styles.RowLink
          to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/acBattery/${row.arrayIndex}/pcs/${row.arrayPcsIndex}`}
        >
          {row.arrayIndex} :: {row.arrayPcsIndex}
        </Styles.RowLink>
      ),
      noOmit: true,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => rowIsHealthy(row),
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => {
        const isHealthy = rowIsHealthy(row)
        return (
          <Tooltip
            title={
              <>
                {row.ready ? 'Ready' : 'Not Ready'}
                {' | '}
                {row.valid ? 'Connected' : 'Disconnected'}
                {rowHasFaults(row) && ' | Faulted'}
              </>
            }
          >
            <Styles.StatusIcon $isHealthy={isHealthy}>
              {isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
            </Styles.StatusIcon>
          </Tooltip>
        )
      },
    },
    {
      name: 'In Rot.',
      id: 'rotation',
      selector: (row) => row.outRotation,
      sortable: true,
      width: '70px',
      cell: (row) => RowRotationWithTooltip(row.outRotation),
      noOmit: false,
      omit: false,
    },
    {
      name: 'Faults',
      id: 'faults',
      omit: false,
      selector: (row) => row.faults.length ?? 0,
      sortable: true,
      width: '70px',
      cell: (row) =>
        rowHasFaults(row) && (
          <Tooltip title={getPCSStatusMessage(row)}>
            <Styles.AlertIcon />
          </Tooltip>
        ),
    },
    {
      name: 'AC Current',
      id: 'acCurrent',
      selector: (row) => row.acCurrentAmp,
      sortable: true,
      width: '100px',
      cell: (row) => row.acCurrentAmp + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC Frequency',
      id: 'acFrequency',
      selector: (row) => row.acFrequencyHz,
      sortable: true,
      width: '110px',
      cell: (row) => checkNullRoundValue(row.acFrequencyHz, ' Hz', 2),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC Real Power',
      id: 'acRealPowerKW',
      selector: (row) => row.acRealPowerKW,
      sortable: true,
      width: '110px',
      cell: (row) => row.acRealPowerKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC Reactive Power',
      id: 'acReactivePowerKVAR',
      selector: (row) => row.acReactivePowerKVAR,
      sortable: true,
      width: '140px',
      cell: (row) => row.acReactivePowerKVAR + ' kVAR',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC Voltage',
      id: 'acVoltageVolt',
      selector: (row) => row.acVoltageVolt,
      sortable: true,
      width: '90px',
      cell: (row) => row.acVoltageVolt.toLocaleString() + ' V',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'DC Current',
      id: 'dcCurrentAmp',
      selector: (row) => row.dcCurrentAmp,
      sortable: true,
      width: '100px',
      cell: (row) => row.dcCurrentAmp + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'DC Voltage',
      id: 'dcVoltageVolt',
      selector: (row) => row.dcVoltageVolt,
      sortable: true,
      width: '90px',
      cell: (row) => row.dcVoltageVolt.toLocaleString() + ' V',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Status',
      id: 'status',
      selector: (row) => getPCSStatusMessage(row),
      sortFunction: customStatusSortFn,
      sortable: true,
      noOmit: false,
      omit: false,
    },
  ]

  return (
    <>
      <Table
        tableId="PCSTable"
        data={data}
        columns={columns}
        fixedHeader={fixedHeader}
        conditionalRowStyles={conditionalRowStyles}
        selectableRows={isStationConnected}
        onSelectedRowsChange={(e) => handleSelectedRowsChange(e)}
        getRowId={getRowId}
        tableActions={_tableActions}
        defaultId="id"
      />
      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default PCSTable

// Actions Menu Contents/Links to modals
const ActionsMenuModalContent = ({
  id,
  modalDispatch,
  setShowCommandModal,
  closeActionModal,
  resetableFaults,
  stationCode,
  blockIndex,
}) => {
  const actionClickHandler = (payload) => {
    closeActionModal() // closes action menu
    modalDispatch(payload)
  }

  return (
    <div key={id.toString()} id={id.toString()}>
      <Styles.ActionModalSection>
        <ActionsMenuItem
          onClick={() =>
            actionClickHandler({
              type: 'showInverterRotationModal',
              setShowCommandModal: setShowCommandModal,
              idsForModal: [id],
            })
          }
        >
          <Styles.MenuIcon>
            <Icon size="xxs" color={COLORS.action_blue} icon={<CheckIcon />} />
          </Styles.MenuIcon>
          <div>Set Rotation</div>
        </ActionsMenuItem>
        <ActionsMenuItem
          onClick={() =>
            actionClickHandler({
              type: 'showClearFaultsModal',
              deviceType: ELECTRICAL_DEVICE_TYPES['PCSes'],
              stationCode: stationCode,
              blockIndex: blockIndex,
              faultingDevices: resetableFaults,
              setShowCommandModal: setShowCommandModal,
            })
          }
          disabled={!resetableFaults?.length}
        >
          <Styles.MenuIcon>
            <Icon
              size="xxs"
              color={
                resetableFaults?.length
                  ? COLORS.action_blue
                  : COLORS.font_secondary
              }
              icon={<CheckIcon />}
            />
          </Styles.MenuIcon>
          <div>Clear Faults</div>
        </ActionsMenuItem>
      </Styles.ActionModalSection>
    </div>
  )
}
