import CommandModalTemplate from '../CommandModalTemplate'
import { ACContactorsFormUi } from './ACContactorsFormUi'
import { ContactorsFormResults } from './ACContactorsFormResults'
import ContactorState from 'api/types/ContactorState'
import EnablementState from 'api/types/EnablementState'

const queryName = 'setArrayContactors'
const modalTitles = {
  start: 'Set Contactors',
  finish: 'Contactor State',
}
const userInputFormJsx = ({ ids, userInputForm, setUserInputForm }) =>
  ACContactorsFormUi({ ids, userInputForm, setUserInputForm })

const commandResultJsx = ({ ids, userInputForm }) => (
  <ContactorsFormResults ids={ids} rotationForm={userInputForm} />
)

export const SetACBatteryContactorsModal = ({ setShowCommandModal, ids }) => {
  // set up initial form values
  const defaultFormValues = {
    contactorState: ContactorState.OPEN,
    ignoreHighCellGroupVoltageAlarm: EnablementState.DISABLED,
    ignoreLowCellGroupVoltageAlarm: EnablementState.DISABLED,
  }
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
    />
  )
}
