import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  position: relative;
`
export const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 400px;
  padding: 15px 0;
  z-index: 10000;
`
export const NavIconContainer = styled.div`
  cursor: pointer;
  fill: #797979;
  margin-right: 10px;
  padding-right: 14px;
  border-right: 1px solid #d8d8d8;
`
export const SelectedBlock = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  padding-right: 10px;
`

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.25;
`
export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 400px;
  z-index: 999;
  box-shadow:
    0 2px 2px 0 rgba(90, 90, 90, 0.5),
    0 0 4px 1px rgba(90, 90, 90, 0.1);
  background-color: #fff;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
`
export const IdentityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 50px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
`
export const LogoContainer = styled(Link)`
  display: block;
  width: 25px;
  height: 25px;
  margin-top: -1px;
`
export const AppName = styled(Link)`
  color: #22242a;
  text-decoration: none;
  font-weight: 600;
  font-size: 17px;
  padding-left: 15px;
`
export const Light = styled.span`
  margin-left: 5px;
  color: #989898;
  font-weight: 400;
`
export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 15px 15px;
  margin: 0 20px;
`
export const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 40px;
`
export const StationSelectContainer = styled.div`
  margin-bottom: 15px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
`
export const SectionLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$isSelected ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: ${(props) => (props.$isSelected ? 600 : 500)};
  color: #000;
  text-decoration: none;
  margin-top: 2px;
  padding: 8px 20px 8px 35px;
  background-color: ${(props) =>
    props.$isSelected ? '#e7e7e7' : 'transparent'};
  transition: background-color 0.25s ease-in-out;
  border-right: 5px solid;
  border-color: ${(props) => (props.$isSelected ? '#30c091' : 'transparent')};

  &:hover {
    background-color: ${(props) => (props.$isSelected ? '#e7e7e7' : '#efefef')};
    color: #555;
  }
`
export const SectionNonLink = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$isSelected ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: ${(props) => (props.$isSelected ? 600 : 500)};
  color: #000;
  text-decoration: none;
  margin-top: 2px;
  padding: 8px 20px 8px 35px;
  background-color: ${(props) =>
    props.$isSelected ? '#e7e7e7' : 'transparent'};
  transition: background-color 0.25s ease-in-out;
  border-right: 5px solid;
  border-color: ${(props) => (props.$isSelected ? '#30c091' : 'transparent')};

  &:hover {
    background-color: ${(props) => (props.$isSelected ? '#e7e7e7' : '#efefef')};
    color: #555;
  }
`
export const SubLink = styled(SectionLink)`
  font-size: 12px;
`
export const Label = styled.div`
  flex: 1;
  margin-left: 10px;
`
export const ToggleButton = styled.div`
  padding: 13px 35px;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #818182;
  cursor: pointer;
`
export const CaretIcon = styled.div`
  width: 10px;
  height: 10px;
  fill: #00b986;
  line-height: 1;
  margin-right: 10px;
  transform: ${(props) => (props.$isOpen ? 'none' : 'rotate(-90deg)')};
`
export const StationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  border-radius: 5px;
  padding: 0 0 5px 0;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
`
export const SectionContainer = styled.div`
  margin-bottom: 20px;
`
export const SectionLabel = styled.div`
  padding-left: 35px;
  color: #909090;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
`
