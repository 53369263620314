import AppTooltip from 'components/AppTooltip/AppTooltip'
import { uniqueId } from 'lodash'
import { useRef } from 'react'
import { UnitItem, UnitsWrapper } from './styles'

/**
 * Renders a list of separated hvac units
 * @param {1|2} unitIndex
 * @param data
 * @returns ReactComponent
 */
export const UnitsWithTooltip = (unitIndex, data) => {
  const _uniqueId = useRef(uniqueId())

  const units = [
    ['Y', data?.[`masterUnit${unitIndex}Y`]],
    ['Y2', data?.[`masterUnit${unitIndex}Y2`]],
    ['G', data?.[`masterUnit${unitIndex}G`]],
    ['W', data?.[`masterUnit${unitIndex}W`]],
    ['O', data?.[`masterUnit${unitIndex}O`]],
    ['Mar', data?.[`masterUnit${unitIndex}Mar`]],
  ].filter((unit) => unit[1] !== undefined)

  return (
    <UnitsWrapper data-tooltip-id={_uniqueId.current}>
      {units.map((unit) => (
        <UnitItem key={`${_uniqueId}-${unit[0]}`} $healthy={unit[1]}>
          {unit[0]}
        </UnitItem>
      ))}
      <AppTooltip id={_uniqueId.current}>
        Y - Compressor; Y2 - Economizer; G - Fan; W - Heat; O - Heat Pumps; MAR
        - Emergency Vent;
      </AppTooltip>
    </UnitsWrapper>
  )
}

export default UnitsWithTooltip
