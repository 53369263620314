import { CommandResultsTemplate } from '../../CommandResultsTemplate'
import { BALANCE_STATUSES } from '../BALANCING_STATUSES'
import React from 'react'

export const ACBatteryBalancingResults = ({ ids, userInputForm }) => {
  // TODO millivolt check not available see SW-1043
  // verify all balancingMode match (plus targetMillivolts for provided)
  const balancingEvaluation = (balanceStatuses, userInputForm) => {
    const matchingBalancingMode = Object.values(balanceStatuses).every(
      (balance) =>
        BALANCE_STATUSES[balance.balancingMode] == userInputForm.balancingCmd,
    )
    // if (userInputForm.balancingCmd == BALANCE_STATUSES.BALANCE_TO_PROVIDED) {
    //     const matchingMillivolts = Object.values(balanceStatuses).every(balance => balance.targetMillivolts == userInputForm.millivolts)
    //     return matchingBalancingMode && matchingMillivolts
    // }
    return matchingBalancingMode
  }

  const balancingHeadings = ['BALANCING', 'CHARGE DB', 'DISCHARGE DB']

  // to CommandResultsTemplate as ResultsTd
  const balancingTd = ({ userInputForm }) => (
    <React.Fragment>
      <td>{userInputForm.balancingCmd}</td>
      {userInputForm.balancingCmd != BALANCE_STATUSES.NO_BALANCE ? (
        <React.Fragment>
          <td>{userInputForm.chargeDeadband} mV</td>
          <td>{userInputForm.dischargeDeadband} mV</td>
        </React.Fragment>
      ) : null}
      {userInputForm.balancingCmd == BALANCE_STATUSES.BALANCE_TO_PROVIDED ? (
        <td key={'millivolts'}>{userInputForm.providedMillivolts} mV</td>
      ) : null}
    </React.Fragment>
  )

  const pendingQueryName = 'setACBatteryBalancing'
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={balancingEvaluation}
      resultHeading={balancingHeadings}
      ResultsTd={balancingTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
