import Icon from 'components/Icon/Icon'
import { ActionModalSection, MenuIcon } from '../../../Tables/table.styled'
import { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { COLORS } from 'design_system/colors'
import { ReactComponent as LightningIcon } from 'icons/lightning-icon.svg'
import { actions } from './action-modal-reducer'

function ActionMenuContent({
  stationCode,
  blockIndex,
  handleActionSelection,
  setShowCommandModal,
  sensorIndex,
  sensorName,
  centipedeLineupId,
  segmentId,
  segment,
  closeActionModal,
}) {
  const handleClearFaultsAction = () => {
    closeActionModal?.()
    handleActionSelection({
      type: actions.SHOW_FAULT_CLEAR_MODAL,
      stationCode,
      blockIndex,
      sensorIndex,
      sensorName,
      centipedeLineupId,
      segmentId,
      segment,
      setShowCommandModal,
    })
  }
  return (
    <ActionModalSection>
      <ActionsMenuItem onClick={handleClearFaultsAction}>
        <MenuIcon>
          <Icon
            size="xxs"
            color={COLORS.action_blue}
            icon={<LightningIcon />}
          />
        </MenuIcon>
        <span>Clear Fault</span>
      </ActionsMenuItem>
    </ActionModalSection>
  )
}

export default ActionMenuContent
