import { FC } from 'react'
import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'
import { ResultTdProps } from './result'
import Icon from 'components/Icon/Icon'

export const ResultTd: FC<ResultTdProps> = (props) => {
  const { userInputForm } = props
  return (
    <>
      {userInputForm.outRotation === 'false' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
      )}
    </>
  )
}
