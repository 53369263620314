import { formatNumber } from 'utils/formatting'
import {
  kilowatt_to_megawatt,
  volt_to_kilovolt,
  kilovar_to_megavar,
} from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function SetPointsTable({ data }) {
  const tableData = [
    {
      name: 'Total Real Power',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.totalRealPowerCmdkW),
        precision: 2,
        unit: ' MW',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.totalRealPowerSettingkW),
        precision: 2,
        unit: ' MW',
      }),
    },
    {
      name: 'Ramp Rate Up',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateUpCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateUpSettingkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
    },
    {
      name: 'Ramp Rate Down',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateDownCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateDownSettingkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
    },
    {
      name: 'Frequency Command',
      operation: `${data?.siteFrequencyCmdHz} Hz`,
      status: `${data?.siteFrequencySettingHz} Hz`,
    },
    {
      name: 'Schedule System Reference',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.scheduleSystemReferenceCmdkW),
        precision: 2,
        unit: ' MW',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.scheduleSystemReferenceSettingkW),
        precision: 2,
        unit: ' MW',
      }),
    },
    {
      name: 'Voltage Command',
      operation: formatNumber({
        value: volt_to_kilovolt(data?.siteVoltageCmdV),
        precision: 2,
        unit: ' kV',
      }),
      status: formatNumber({
        value: volt_to_kilovolt(data?.siteVoltageSettingV),
        precision: 2,
        unit: ' kV',
      }),
    },
    {
      name: 'Total Reactive Power',
      operation: formatNumber({
        value: kilovar_to_megavar(data?.totalReactivePowerCmdkVAr),
        precision: 2,
        unit: ' MVAr',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(data?.totalReactivePowerSettingkVAr),
        precision: 2,
        unit: ' MVAr',
      }),
    },
    {
      name: 'Power Factor Command',
      operation: `${data?.sitePowerFactorCmd} p. u.`,
      status: `${data?.sitePowerFactorSetting} p. u.`,
    },
  ]

  return (
    <StatusAndOperationTable tableId="EksPpcSetpointsTable" data={tableData} />
  )
}
