import _ from 'lodash'
import { useEffect, useReducer, useState, useContext } from 'react'

// Component Imports
import Table from 'components/Table/Table/Table'
import { SetACBatteryContactorsModal } from '../../Commands/ACBatteryContactors/SetACBatteryContactorsModal'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { FormCommandButton } from 'components/Form/FormComponents.styled'

// Icon Imports
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import Icon from 'components/Icon/Icon'
import { ReactComponent as BalancingIcon } from 'icons/balancing-icon.svg'

import { COLORS } from 'design_system/colors'
import * as Styles from '../table.styled'
import {
  HealthStatusIconWithMoreInfoToolTips,
  StringsToTernaryBarChartRow,
} from '../tableUtils'
import { healthyStringFraction } from 'utils/strings'
import { SetInverterRotationModal } from '../../Commands/InverterRotation/SetInverterRotationModal'
import { SetACBatteryRotationModal } from '../../Commands/ACBatteryRotation/SetACBatteryRotationModal'
import { SetACBatteryBalancingModal } from '../../Commands/Balancing/ACBatteryBalancing/SetACBatteryBalancingModal'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import usePCSList from 'api/useQueryHooks/usePCSList'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */
const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showContactorsModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetACBatteryContactorsModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetACBatteryRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showInverterRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetInverterRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showBalancingModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetACBatteryBalancingModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

/////////////////////////////////////////////////////////////////

const ACBatteryTable = ({
  fixedHeader = false,
  pagination,
  blockIndex,
  stationCode,
}) => {
  const tableId = 'ACBatteryTable'
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [activeActionRow, setActiveActionRow] = useState(null)
  const [selectedBatteryIds, setSelectedBatteryIds] = useState([])
  const { data, isLoading } = useBlockStatus()
  const { data: pcsData, isLoading: pcsLoading } = usePCSList()
  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  // CLEANUP!
  useEffect(() => {
    return () => {
      setSelectedBatteryIds([])
    }
  }, [])

  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  if (isLoading || pcsLoading) return <LoadingPage />
  const isStationConnected = data.valid

  let formattedACBatteries = []
  if (data.acBatteries) {
    const acBatteries = Object.values(data.acBatteries)
    formattedACBatteries = acBatteries.map((acBattery, index) => {
      index++
      return {
        ...acBattery,
        stationCode: data.stationCode,
        blockIndex: data.blockIndex,
        acBatteryIndex: index,
        array: Object.values(data.arrays).filter(
          (a) => a.arrayIndex === index,
        )[0],
        pcses: pcsData.arrayPcses
          .filter((a) => a.arrayIndex === index)
          .map((a) => a.arrayPcsIndex),
      }
    })
  }

  const handleSelectedRowChange = (rowChangeEvent) => {
    const trueIds = Object.keys(rowChangeEvent).filter(
      (key) => rowChangeEvent[key],
    )
    setSelectedBatteryIds(trueIds) //return array of keys whose value is true
  }

  const getACBatteryRowId = (row) => Number(row.acBatteryIndex)

  const isHealthy = (row) => {
    return HealthStatusIconWithMoreInfoToolTips(
      row.valid,
      row.enabled,
      row.ready,
      _.get(row, 'array.dcPowerAndEnergy.offlineStackCount'),
      _.get(row, 'array.dcPowerAndEnergy.nearlineStackCount'),
      row.acBatteryIndex,
    )
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.acBatteryIndex === 1,
      style: {
        borderTop: '1px solid #a7a7a7',
      },
    },
    {
      when: (row) =>
        row.acBatteryIndex === activeActionRow ||
        selectedBatteryIds.includes(row.acBatteryIndex.toString()),
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  // open/close actions menu modal
  const onOpen = (row) => {
    setActiveActionRow(row.acBatteryIndex)
  }
  const onClose = () => {
    setActiveActionRow(null)
  }

  const stringsToTernaryBarChartRowHandler = (row) => {
    return StringsToTernaryBarChartRow(row.array.dcPowerAndEnergy)
  }

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.acBatteryIndex,
      sortable: true,
      width: '40px',
      cell: (row) => (
        <Styles.RowLink
          to={`/block-details/${stationCode}/${blockIndex}/block-topology/acBattery/${row.acBatteryIndex}`}
        >
          {row.acBatteryIndex}
        </Styles.RowLink>
      ),
      noOmit: true,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'acBatteryStatus',
      selector: (row) => row.valid && row.enabled && row.ready,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: isHealthy,
    },
    {
      name: 'Strings',
      id: 'acBatteryStrings',
      selector: (row) =>
        healthyStringFraction(
          row.array.dcPowerAndEnergy.onlineStackCount,
          row.array.dcPowerAndEnergy.totalStackCount,
        ),
      sortable: true,
      width: '90px',
      cell: stringsToTernaryBarChartRowHandler,
      noOmit: true,
    },
    {
      name: 'Meas. Real Power',
      id: 'measuredRealPower',
      selector: (row) => row.measuredkW,
      sortable: true,
      width: '130px',
      cell: (row) =>
        _.chain(row).get('measuredkW').round(1).value().toLocaleString() +
        ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Meas. Reactive Power',
      id: 'measuredReactivePower',
      selector: (row) => row.measuredKVAr,
      sortable: true,
      width: '150px',
      cell: (row) =>
        _.chain(row).get('measuredKVAr').round(1).value().toLocaleString() +
        ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cmd. Real Power',
      id: 'commandedRealPower',
      selector: (row) => row.commandedkW,
      sortable: true,
      width: '130px',
      cell: (row) =>
        _.chain(row).get('commandedkW').round(1).value().toLocaleString() +
        ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cmd. Reactive Power',
      id: 'commandedReactivePower',
      selector: (row) => row.commandedKVAr,
      sortable: true,
      width: '150px',
      cell: (row) =>
        _.chain(row).get('commandedKVAr').round(1).value().toLocaleString() +
        ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Online Avail. Energy',
      id: 'onlineAvailKWH',
      selector: (row) => row.array.dcPowerAndEnergy.onlineAvailableKWH,
      sortable: true,
      width: '140px',
      cell: (row) =>
        _.chain(row)
          .get('array.dcPowerAndEnergy.onlineAvailableKWH')
          .round(1)
          .value()
          .toLocaleString() + ' kWh',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Total Capacity',
      id: 'totalCapacityKWH',
      selector: (row) => row.array.dcPowerAndEnergy.totalCapacityKWH,
      sortable: true,
      width: '120px',
      cell: (row) =>
        _.chain(row)
          .get('array.dcPowerAndEnergy.totalCapacityKWH')
          .round(1)
          .value()
          .toLocaleString() + ' kWh',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max Allowed Charge',
      id: 'maxAllowedChargeCurrent',
      selector: (row) => row.maxAllowedChargeCurrent,
      sortable: true,
      width: '150px',
      cell: (row) =>
        _.chain(row)
          .get('array.dcPowerAndEnergy.maxChargePowerKW')
          .round()
          .value()
          .toLocaleString() +
        ' kW' +
        ' / ' +
        _.chain(row)
          .get('array.dcPowerAndEnergy.maxAllowedChargeCurrent')
          .round()
          .value()
          .toLocaleString() +
        ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max Allowed Discharge',
      id: 'maxAllowedDischargeCurrent',
      selector: (row) => row.maxAllowedDischargeCurrent,
      sortable: true,
      width: '170px',
      cell: (row) =>
        _.chain(row)
          .get('array.dcPowerAndEnergy.maxDischargePowerKW')
          .round()
          .value()
          .toLocaleString() +
        ' kW' +
        ' / ' +
        _.chain(row)
          .get('array.dcPowerAndEnergy.maxAllowedDischargeCurrent')
          .round()
          .value()
          .toLocaleString() +
        ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  if (
    isStationConnected &&
    userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) &&
    userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  )
    columns.unshift({
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      noOmit: true,
      width: '55px',
      cell: (row) => (
        <ActionsMenu
          key={row}
          onOpen={() => onOpen(row)}
          onClose={() => onClose()}
        >
          <ActionsMenuModalContentACBattery
            rowStackId={[row.acBatteryIndex]}
            modalDispatch={modalDispatch}
            setShowCommandModal={setShowCommandModal}
            formattedACBatteries={formattedACBatteries}
            userHasApiAccessCode={userHasApiAccessCode}
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    })

  return (
    <>
      <Table
        tableId={tableId}
        data={formattedACBatteries}
        columns={columns}
        fixedHeader={fixedHeader}
        pagination={pagination}
        getRowId={getACBatteryRowId}
        defaultId="id"
        conditionalRowStyles={conditionalRowStyles}
        selectableRows={
          isStationConnected &&
          userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) &&
          userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
        }
        onSelectedRowsChange={(e) => handleSelectedRowChange(e)}
        tableActions={
          isStationConnected && (
            <Styles.ButtonContainer>
              {userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) && (
                <>
                  <FormCommandButton
                    disabled={selectedBatteryIds.length === 0}
                    onClick={() => {
                      modalDispatch({
                        type: 'showContactorsModal',
                        setShowCommandModal: setShowCommandModal,
                        open: true,
                        idsForModal: selectedBatteryIds,
                      })
                    }}
                  >
                    <Icon
                      size="sm"
                      color={
                        selectedBatteryIds.length === 0
                          ? '#cccccc'
                          : COLORS.action_blue
                      }
                      icon={<ContactorOpenIcon />}
                    />
                    Set Contactors
                  </FormCommandButton>
                  <FormCommandButton
                    disabled={selectedBatteryIds?.length === 0}
                    onClick={() => {
                      modalDispatch({
                        type: 'showRotationModal',
                        setShowCommandModal: setShowCommandModal,
                        open: true,
                        idsForModal: selectedBatteryIds,
                      })
                    }}
                  >
                    <Icon
                      size="xxs"
                      color={
                        selectedBatteryIds?.length === 0
                          ? '#cccccc'
                          : COLORS.action_blue
                      }
                      icon={<CheckIcon />}
                    />
                    Set Rotation
                  </FormCommandButton>
                  <FormCommandButton
                    disabled={selectedBatteryIds?.length === 0}
                    onClick={() => {
                      modalDispatch({
                        type: 'showBalancingModal',
                        setShowCommandModal: setShowCommandModal,
                        open: true,
                        idsForModal: selectedBatteryIds,
                      })
                    }}
                  >
                    <Icon
                      size="xxs"
                      color={
                        selectedBatteryIds?.length === 0
                          ? '#cccccc'
                          : COLORS.action_blue
                      }
                      icon={<BalancingIcon />}
                    />
                    Set Balancing
                  </FormCommandButton>
                </>
              )}

              {userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC) && (
                <>
                  <Styles.ButtonRowText
                    $disabled={selectedBatteryIds.length === 0}
                  >
                    INVERTER CONTROLS
                  </Styles.ButtonRowText>
                  <FormCommandButton
                    disabled={selectedBatteryIds.length === 0}
                    onClick={() => {
                      modalDispatch({
                        type: 'showInverterRotationModal',
                        setShowCommandModal: setShowCommandModal,
                        open: true,
                        idsForModal: getPCSIdsForSelectedAcBatteries(
                          selectedBatteryIds,
                          formattedACBatteries,
                        ),
                      })
                    }}
                  >
                    <Icon
                      size="xxs"
                      color={
                        selectedBatteryIds.length === 0
                          ? '#cccccc'
                          : COLORS.action_blue
                      }
                      icon={<CheckIcon />}
                    />
                    Set Rotation
                  </FormCommandButton>
                </>
              )}
            </Styles.ButtonContainer>
          )
        }
      />

      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default ACBatteryTable

// correctly sets ids for battery:inverter ie 2:1
const getPCSIdsForSelectedAcBatteries = (ids, formattedACBatteries) => {
  const batteryWithInverterIds = []
  ids.forEach((id) => {
    const inverterIds = formattedACBatteries[+id - 1].pcses
    inverterIds.forEach((invId) =>
      batteryWithInverterIds.push(`${id}:${invId}`),
    )
  })
  return batteryWithInverterIds
}

// Actions Menu Contents/Links to modals
const ActionsMenuModalContentACBattery = ({
  rowStackId,
  modalDispatch,
  setShowCommandModal,
  closeActionModal,
  formattedACBatteries,
  userHasApiAccessCode,
}) => {
  const actionClickHandler = (dispatchType) => {
    closeActionModal() // closes action menu, when command modal is opened
    if (dispatchType === 'showInverterRotationModal')
      rowStackId = getPCSIdsForSelectedAcBatteries(
        rowStackId,
        formattedACBatteries,
      )
    modalDispatch({
      type: dispatchType,
      setShowCommandModal: setShowCommandModal,
      idsForModal: rowStackId,
    })
  }

  return (
    <div key={rowStackId.toString()} id={rowStackId.toString()}>
      {userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) && (
        <Styles.ActionModalSection>
          <Styles.ActionModalHeader>String Controls</Styles.ActionModalHeader>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showContactorsModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xs"
                color={COLORS.action_blue}
                icon={<ContactorOpenIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Contactors</div>
          </ActionsMenuItem>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showRotationModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showBalancingModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<BalancingIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Balancing</div>
          </ActionsMenuItem>
        </Styles.ActionModalSection>
      )}
      {userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC) && (
        <Styles.ActionModalSection>
          <Styles.ActionModalHeader>Inverter Controls</Styles.ActionModalHeader>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showInverterRotationModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
        </Styles.ActionModalSection>
      )}
    </div>
  )
}
