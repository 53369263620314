import _ from 'lodash'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as EnclosureIcon } from 'icons/enclosure-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const EnclosureSummary = () => {
  const { data: blockStatusData, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />

  const enclosures = _.chain(blockStatusData)
    .get('blockEnclosures')
    .toArray()
    .value()
  const count = enclosures.length
  const healthyCount = _.filter(enclosures, { valid: true }).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `Enclosures`,
    titleIcon: <EnclosureIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy Enclosures: ${healthyCount} | Unhealthy Enclosures: ${unhealthyCount}`}
        id="enclosureHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Communicating',
            value: healthyCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default EnclosureSummary
