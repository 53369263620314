import CommandModalTemplate from '../CommandModalTemplate'
import { PreferredCurrentForm } from './PreferredCurrentForm'
import { PreferredCurrentResults } from './PreferredCurrentResults'

// set up initial form values
const defaultFormValues = {
  chargeManagerEnabled: false,
  dischargeManagerEnabled: false,
}

const queryName = 'setPreferredCurrent'

// 1st modal, 2nd modal headings
const modalTitles = {
  start: 'Set Preferred Current',
  finish: 'Preferred Current State',
}

// step 1: define custom input. form returns jsx
const userInputFormJsx = ({ ids, userInputForm, setUserInputForm }) =>
  PreferredCurrentForm({ ids, userInputForm, setUserInputForm })

// step 2: custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => {
  return <PreferredCurrentResults ids={ids} userInputForm={userInputForm} />
}

export const SetPreferredCurrentModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
    />
  )
}
