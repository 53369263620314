import { COLORS } from 'design_system/colors'
import styled from 'styled-components/macro'
import { BasicTable } from './ContactorsFormUi.styles'

export const ModalTable = styled(BasicTable)`
  thead td {
    background-color: ${COLORS.modal_table_header_bg};
  }
`

export const InfoHeading = styled.div`
  color: #00ba86;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  div {
    float: left;
    margin-right: 10px;
  }
`

export const PendingHeading = styled.div`
  color: #ba6800;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  div {
    float: left;
    margin-right: 10px;
  }
`

export const WarnHeading = styled(PendingHeading)`
  color: red;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
