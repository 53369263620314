import styled from 'styled-components/macro'

export const Row = styled.div`
  display: flex;
  margin-bottom: 25px;
`
export const Flex = styled.div`
  flex: 1;
  &:first-child {
    padding-right: 25px;
  }
`
