import { Navigate, useParams } from 'react-router-dom'

import DetailsPage from '../../../components/Layouts/DetailsPage/DetailsPage'
import { useEffect } from 'react'
import EksPowerPlantControllerSummary from './Summary/EksPowerPlantControllerSummary'
import useEksPpcDetailsQuery from 'api/useQueryHooks/useEksPpcDetailsQuery'
import { EksPowerPlantControllerTable } from './Main/EksPpcDetailsMain'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const EksPpcDetails = ({
  setSelectedPage,
  selectedParams,
  setSelectedParams,
}) => {
  const params = useParams()
  const { data, isLoading } = useEksPpcDetailsQuery()

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'eks-ppc-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <LoadingPage />

  if (!data.ppcInstalled) {
    return <Navigate to=".." />
  }

  return (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<EksPowerPlantControllerSummary />}
      mainColumnComponent={<EksPowerPlantControllerTable />}
    />
  )
}

export default EksPpcDetails
