import * as Styles from './styles'

import StackOsApps from 'modules/Station/components/StackOsApps/StackOsApps'
import ElectricalDevices from 'modules/Station/components/ElectricalDevices/ElectricalDevices'
import EnvironmentalDevices from 'modules/Station/components/EnvironmentalDevices/EnvironmentalDevices'
import PlugIns from 'modules/Station/components/PlugIns/PlugIns'
import { Utilities } from 'modules/Station/components/Utilities/Utilities'

const MainColumn = () => {
  return (
    <div>
      <Styles.RowHeader>DEVICES</Styles.RowHeader>

      <ElectricalDevices />
      <Styles.CardSpacer />

      <EnvironmentalDevices />
      <Styles.CardSpacer />

      <Styles.RowHeader>Control Loop</Styles.RowHeader>

      <StackOsApps />
      <Styles.CardSpacer />

      <Utilities />
      <Styles.CardSpacer />
      <PlugIns />
    </div>
  )
}

export default MainColumn
