import { useEffect, useState } from 'react'

import { COLORS } from 'design_system/colors'
import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import { RangePowerChart } from 'modules/Station/components/Charts/RangePowerChart/RangePowerChart'
import * as Styles from './styles'
import Card from 'components/Cards/Card/Card'
import useDcDcPcsDetailGraphQuery from 'api/useQueryHooks/useDcDcPcsDetailGraphQuery'

export const DcDcPcsDetailsMainColumn = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { isLoading, data, refetch } = useDcDcPcsDetailGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  return (
    <Card
      title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
    >
      {!isLoading && (
        <Styles.GraphsWrapper>
          <RangePowerChart
            color={COLORS.charts.blue}
            title="Battery Power"
            tooltip="Power"
            unit="kW"
            data={data?.pcsBatteryPowerKW.series}
            showXAxis={false}
            period={graphPeriod}
          />
          <RangePowerChart
            color={COLORS.charts.orange}
            title="PV Power"
            tooltip="Power"
            unit="kW"
            data={data?.pcsPvPowerKW.series}
            period={graphPeriod}
          />
        </Styles.GraphsWrapper>
      )}
    </Card>
  )
}
