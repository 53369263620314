// Component Imports
import Icon from '../../Icon/Icon'

// Style Imports
import * as Styles from './styles'

// Icon Imports
import { ReactComponent as LinkArrowIcon } from '../../../icons/link-arrow-icon.svg'

// Util Imports
import useDetectClickOut from '../../../hooks/useDetectClickOut.hooks'

const AppHeaderDropdown = ({ icon, title, link, children }) => {
  const { show, setShow, nodeRef, triggerRef } = useDetectClickOut(false)

  return (
    <Styles.Container>
      <Styles.IconContainer ref={triggerRef} open={show}>
        <Icon size="sm" icon={icon} />
      </Styles.IconContainer>
      {show && (
        <Styles.Modal ref={nodeRef}>
          <Styles.HeaderRow>
            <Styles.Title>{title}</Styles.Title>
            <Styles.HeaderLink to={link}>
              <LinkArrowIcon fill="#333" />
            </Styles.HeaderLink>
          </Styles.HeaderRow>
          <div onClick={() => setShow(false)} onKeyDown={null}>
            {children}
          </div>
        </Styles.Modal>
      )}
    </Styles.Container>
  )
}

export default AppHeaderDropdown
