import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 2px;
  height: 15px;
  background: ${(props) =>
    `linear-gradient(to right, #71c5ef 0%, #71c5ef ${props.$percent}%, #dae6ee ${props.$percent}%, #dae6ee)`};
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.4);
  font-size: 12px;
`
