import { useState, useEffect } from 'react'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as SensorIcon } from 'icons/sensor-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'

const sensorSummary = (data) => (key) => {
  return (
    Math.round(data[key].untripped).toLocaleString() +
    ' / ' +
    Math.round(data[key].total).toLocaleString()
  )
}

const SensorsListSummary = ({ data }) => {
  const [formattedSections, setFormattedSections] = useState([])

  const summaryVal = sensorSummary(data)

  useEffect(() => {
    const sections = [
      {
        sectionKey: 'FireAlarm',
        sectionTitle: 'FIRE',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('FireAlarm'),
          },
        ],
      },
      {
        sectionKey: 'FireTrouble',
        sectionTitle: 'FIRE TROUBLE',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('FireTrouble'),
          },
        ],
      },
      {
        sectionKey: 'FireSupervisory',
        sectionTitle: 'FIRE SUPERVISORY',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('FireSupervisory'),
          },
        ],
      },
      {
        sectionKey: 'EStopButton',
        sectionTitle: 'ESTOP BUTTON',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('EStopButton'),
          },
        ],
      },
      {
        sectionKey: 'FireSuppressionReleased',
        sectionTitle: 'STATX',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('FireSuppressionReleased'),
          },
        ],
      },
      {
        sectionKey: 'SmokeAlarm',
        sectionTitle: 'SMOKE',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('SmokeAlarm'),
          },
        ],
      },
      {
        sectionKey: 'SmokeTrouble',
        sectionTitle: 'SMOKE TROUBLE',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('SmokeTrouble'),
          },
        ],
      },
      {
        sectionKey: 'HeatSensor',
        sectionTitle: 'HEAT',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('HeatSensor'),
          },
        ],
      },
      {
        sectionKey: 'HighHydrogenAlarm',
        sectionTitle: 'HYDROGEN',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('HighHydrogenAlarm'),
          },
        ],
      },
      {
        sectionKey: 'HydrogenFault',
        sectionTitle: 'HYDROGEN FAULT',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('HydrogenFault'),
          },
        ],
      },
      {
        sectionKey: 'GridRecloserSignal',
        sectionTitle: 'GRID RECLOSER',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('GridRecloserSignal'),
          },
        ],
      },
      {
        sectionKey: 'EnvironmentalControllerLostComms',
        sectionTitle: 'DATA COMMUNICATIONS',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('EnvironmentalControllerLostComms'),
          },
        ],
      },
      {
        sectionKey: 'EnvIOLostComms',
        sectionTitle: 'IO COMMUNICATIONS',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('EnvIOLostComms'),
          },
        ],
      },
      {
        sectionKey: 'AcDoor',
        sectionTitle: 'AC DOORS',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('AcDoor'),
          },
        ],
      },
      {
        sectionKey: 'DCCabinet',
        sectionTitle: 'DC DOORS',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('DCCabinet'),
          },
        ],
      },
      {
        sectionKey: 'AcSideDoor',
        sectionTitle: 'AC SIDE DOOR',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('AcSideDoor'),
          },
        ],
      },
      {
        sectionKey: 'DcSideDoor',
        sectionTitle: 'DC SIDE DOOR',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('DcSideDoor'),
          },
        ],
      },
      {
        sectionKey: 'LowerTopCap',
        sectionTitle: 'TOP CAP DOOR',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('LowerTopCap'),
          },
        ],
      },
      {
        sectionKey: 'BatteryDoors',
        sectionTitle: 'BATTERY DOORS',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('BatteryDoors'),
          },
        ],
      },
      {
        sectionKey: 'BatteryCompartmentDoor',
        sectionTitle: 'BATTERY COMPARTMENT DOOR',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('BatteryCompartmentDoor'),
          },
        ],
      },
      {
        sectionKey: 'StringControllerAccessDoor',
        sectionTitle: 'STRING CTRL ACCESS DOOR',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('StringControllerAccessDoor'),
          },
        ],
      },
      {
        sectionKey: 'ManualVentilation',
        sectionTitle: 'MANUAL VENTILATION',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('ManualVentilation'),
          },
        ],
      },
      {
        sectionKey: 'EnvironmentalControllerVentilation',
        sectionTitle: 'ENVIRONMENTAL CONTROL',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('EnvironmentalControllerVentilation'),
          },
        ],
      },
      {
        sectionKey: 'UPSAlarm',
        sectionTitle: 'UPS ALARM',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('UPSAlarm'),
          },
        ],
      },
      {
        sectionKey: 'MoistureAlarm',
        sectionTitle: 'MOISTURE',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('MoistureAlarm'),
          },
        ],
      },
      {
        sectionKey: 'STATION-WIDE',
        sectionTitle: 'STATION-WIDE',
        rows: [
          {
            key: 'CriticalAuxLoadMode',
            label: 'Critical Aux Load',
            value: summaryVal('CriticalAuxLoadMode'),
          },
          {
            key: 'ModbusEnabledEstop',
            label: 'Modbus E-Stop',
            value: summaryVal('ModbusEnabledEstop'),
          },
          {
            key: 'PCSEStop',
            label: 'PCS E-Stop',
            value: summaryVal('PCSEStop'),
          },
          {
            key: 'GroundFault',
            label: 'Ground Fault E-Stop',
            value: summaryVal('GroundFault'),
          },
          {
            key: 'GenericDoor',
            label: 'Generic Door',
            value: summaryVal('GenericDoor'),
          },
        ],
      },
      {
        sectionKey: 'UNRECOGNIZED',
        sectionTitle: 'UNRECOGNIZED',
        rows: [
          {
            label: 'Untripped / Total',
            value: summaryVal('UNRECOGNIZED'),
          },
        ],
      },
    ]

    if (formattedSections.length === 0) {
      const auxSensors = []
      const auxSections = []
      Object.keys(data).forEach((sensor) => {
        if (data[sensor].total >= 1) {
          auxSensors.push(sensor)
        }
      })
      sections.forEach((section) => {
        if (auxSensors.find((el) => el === section.sectionKey))
          auxSections.push(section)
        if (section.sectionKey === 'STATION-WIDE') {
          const auxStationWide = []
          section.rows.forEach((row) => {
            if (!auxSensors.find((el) => el === row.key)) {
              auxStationWide.push(row.key)
            }
          })
          auxStationWide.forEach((sensor) => {
            const index = section.rows.findIndex((row) => row.key === sensor)
            section.rows.splice(index, 1)
          })
          auxSections.push(section)
        }
      })
      setFormattedSections(auxSections)
    }
  }, [formattedSections.length, data, summaryVal])

  const valueArray = Object.values(data)
  const untrippedCount = valueArray.reduce((p, n) => p + n.untripped, 0)
  const trippedCount = valueArray.reduce((p, n) => p + n.total - n.untripped, 0)

  if (formattedSections.length === 0) return null

  const summaryMap = {
    title: `Sensors`,
    titleIcon: <SensorIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={untrippedCount}
        negativeCount={trippedCount}
        tooltip={`Untripped: ${untrippedCount} | Tripped: ${trippedCount}`}
        id="Sensors"
      />
    ),
    sections: formattedSections,
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default SensorsListSummary
