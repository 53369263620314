import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  StringsToTernaryBarChartRow,
} from '../tableUtils'

const ACPVBatteriesTable = ({ data, blockIndex, stationCode }) => {
  const acPvBatteryId = (row) => Number(row.acPvBatteryIndex)

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: acPvBatteryId,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => (
        <Styles.RowLink
          to={`/block-details/${stationCode}/${blockIndex}/block-topology/acPvBattery/${row.acPvBatteryIndex}`}
        >
          {row.acPvBatteryIndex}
        </Styles.RowLink>
      ),
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
    },
    {
      name: 'STRINGS',
      id: 'acCurtailLimitCmd',
      selector: (row) => row.acPvBatteryIndex,
      sortable: true,
      width: '90px',
      cell: StringsToTernaryBarChartRow,
      noOmit: false,
      omit: false,
    },
    {
      name: 'MEAS. REAL POWER',
      id: 'measRealPwr',
      selector: (row) => row.measuredACkW,
      sortable: true,
      width: '130px',
      cell: (row) => `${row.measuredACkW.toLocaleString()}  kW`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'MEAS. REACTIVE POWER',
      id: 'pvdc',
      selector: (row) => row.measuredACKVAr,
      sortable: true,
      width: '150px',
      cell: (row) => `${row.measuredACKVAr.toLocaleString()} kVAr`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'CURTAILMENT LIMIT',
      id: 'acan',
      selector: (row) => row.curtailmentLimitACkW,
      sortable: true,
      width: '130px',
      cell: (row) => `${row.curtailmentLimitACkW.toLocaleString()} kW`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'CMD. REACTIVE POWER',
      id: 'acbn',
      selector: (row) => row.commandedACKVAr,
      sortable: true,
      width: '150px',
      cell: (row) => `${row.commandedACKVAr.toLocaleString()} kVAr`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'ONLINE AVAIL. ENERGY',
      id: 'acVoltageVolt',
      selector: (row) => row.onlineAvailableKWH,
      sortable: true,
      width: '140px',
      cell: (row) => `${row.onlineAvailableKWH.toLocaleString()} kWh`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Max Allowed Charge',
      id: 'maxAllowedChargeCurrent',
      selector: (row) => row.maxAllowedChargeCurrent,
      sortable: true,
      width: '150px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        Math.round(row.maxChargePowerKW).toLocaleString() +
        ' kW ' +
        ' / ' +
        row.maxAllowedChargeCurrent.toLocaleString() +
        ' A',
      right: true,
    },
    {
      name: 'Max Allowed Discharge',
      id: 'maxAllowedDischargeCurrent',
      selector: (row) => row.maxAllowedDischargeCurrent,
      sortable: true,
      width: '170px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        Math.round(row.maxDischargePowerKW).toLocaleString() +
        ' kW ' +
        ' / ' +
        row.maxAllowedDischargeCurrent.toLocaleString() +
        ' A',
      right: true,
    },
  ]

  return (
    <Table
      tableId="ACPVBatteriesTable"
      data={data}
      columns={columns}
      getRowId={acPvBatteryId}
      fixedHeader
      pagination
      defaultId="id"
    />
  )
}

export default ACPVBatteriesTable
