import Table from 'components/Table/Table/Table'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  RowRotationWithTooltip,
} from '../tableUtils'
import Tooltip from 'components/Tooltip'
import * as Styles from '../table.styled'
import { getPCSStatusMessage } from 'utils/pcs'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { useEffect, useMemo, useState } from 'react'
import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as LightningIcon } from 'icons/lightning-icon.svg'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { ELECTRICAL_DEVICE_TYPES } from '../../ElectricalDevices/constants'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { FONT_WEIGHT } from 'design_system/typography'

const STATUS = {
  INACTIVE: 'Inactive',
  DISCONNECTED: 'Disconnected',
  TEST_MODE: 'Test Mode',
  DC_PRECHARGE: 'DC Precharge',
  DC_CONNECTED: 'DC Connected',
  STOPPED: 'Stopped',
  AC_CAP_PCHG: 'AC Capacitor Precharge',
  AC_PRECHARGE: 'AC Precharge',
  AC_CONNECTED: 'AC Connected',
  AC_CONNECTED_DC: 'AC Connected DC',
  AC_CONNECTED_END: 'AC Connected End',
  RUN_AC_PCHG: 'Run AC Precharge',
  RUNNING_AC: 'Running AC',
  RUNNING: 'Running',
  QATN: 'QATN',
  null: 'Unknown',
  undefined: 'Unknown',
}

const COMMAND = {
  NO_COMMAND: 'No Command',
  RUN: 'Run',
  STOP: 'Stop',
  DISCONNECT: 'Disconnect',
  STANDBY: 'Standby',
  CLEAR_ERROR: 'Clear Error',
  null: 'Unknown',
  undefined: 'Unknown',
}

const deviceHasFaults = (device) => {
  return Boolean(
    device?.topologyEntry?.allowFaultReset ||
      device?.topologyEntryV1?.allowFaultReset,
  )
}

const getRowId = (row) => `${row.arrayIndex}:${row.arrayPcsIndex}`

const PcsModulesTable = ({ data, openModal, controlMode }) => {
  const [isIdSortable, setIsIdSortable] = useState(false) // sortable by id after first render, as it sorts by selector instead for first render
  const { stationCode, blockIndex } = useParams()
  const [selectedRows, setSelectedRows] = useState([])

  const handleRowsSelection = (rows) => {
    setSelectedRows(Object.keys(rows).filter((key) => rows[key]))
  }

  useEffect(() => {
    setIsIdSortable(true)
  }, [])

  const isGroupInStackOsMode = controlMode === 'STACK_OS_CONTROL'

  const columnsWithActions = useMemo(() => {
    const columns = [
      {
        name: 'ID',
        id: 'id',
        selector: getRowId,
        cell: (row) => (
          <IdCell>
            {row.arrayIndex} :: {row.arrayPcsIndex}
          </IdCell>
        ),
        sortable: isIdSortable,
        width: '75px',
      },
      {
        name: (
          <div>
            <HealthGoodIcon width="12px" height="12px" fill="#abaeb1" />
          </div>
        ),
        id: 'health',
        selector: (row) => row.isHealthy,
        cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
        sortable: true,
        width: '65px',
      },
      {
        name: 'IN ROT.',
        id: 'rotation',
        selector: (row) => !row.outRotation,
        sortable: true,
        width: '65px',
        cell: (row) => RowRotationWithTooltip(row.outRotation),
        noOmit: false,
        omit: false,
      },
      {
        name: 'Faults',
        id: 'faults',
        selector: deviceHasFaults,
        sortable: true,
        width: '70px',
        noOmit: false,
        omit: false,
        cell: (row) =>
          deviceHasFaults(row) && (
            <Tooltip title={getPCSStatusMessage(row)}>
              <Styles.AlertIcon />
            </Tooltip>
          ),
      },
      {
        name: 'Real Power Setpoint',
        id: 'realPowerSetpoint',
        selector: (row) => row.acRealPowerSettingKW,
        sortable: true,
        width: '70px',
        right: true,
        cell: (row) => `${row.acRealPowerSettingKW} kW`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'Real Power',
        id: 'realPower',
        selector: (row) => row.acRealPowerKW,
        sortable: true,
        width: '70px',
        right: true,
        cell: (row) => `${row.acRealPowerKW} kW`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'Reactive Power Setpoint',
        id: 'reactivePowerSetpoint',
        selector: (row) => row.acReactivePowerSettingKVAR,
        sortable: true,
        width: '70px',
        right: true,
        cell: (row) => `${row.acReactivePowerSettingKVAR} kVar`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'Reactive Power',
        id: 'reactivePower',
        selector: (row) => row.acReactivePowerKVAR,
        sortable: true,
        width: '70px',
        right: true,
        cell: (row) => `${row.acReactivePowerKVAR} kVar`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'AC A > N',
        id: 'acan',
        selector: (row) => row.acPhaseANVoltageVolt,
        sortable: true,
        width: '120px',
        cell: (row) =>
          `${row.acPhaseANVoltageVolt} V / ${row.acPhaseACurrentAmp}  A`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'AC B > N',
        id: 'acbn',
        selector: (row) => row.acPhaseBNVoltageVolt,
        sortable: true,
        width: '120px',
        cell: (row) =>
          `${row.acPhaseBNVoltageVolt} V / ${row.acPhaseBCurrentAmp}  A`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'AC C > N',
        id: 'acVoltageVolt',
        selector: (row) => row.acPhaseCNVoltageVolt,
        sortable: true,
        width: '120px',
        cell: (row) =>
          `${row.acPhaseCNVoltageVolt} V / ${row.acPhaseCCurrentAmp}  A`,
        noOmit: false,
        omit: false,
      },
      {
        name: 'Status',
        id: 'status',
        selector: (row) => STATUS[row.mainStatus],
        sortable: true,
        width: '120px',
        cell: (row) => STATUS[row.mainStatus],
        noOmit: false,
        omit: false,
      },
      {
        name: 'Command',
        id: 'command',
        selector: (row) => COMMAND[row.sentCommand],
        sortable: true,
        width: '120px',
        cell: (row) => COMMAND[row.sentCommand],
        noOmit: false,
        omit: false,
      },
      {
        name: 'ESS',
        id: 'ess',
        selector: (row) => row.essEnabled,
        sortable: true,
        width: '120px',
        cell: (row) => (row.essEnabled ? 'Enabled' : 'Disabled'),
        noOmit: false,
        omit: false,
      },
      {
        name: 'FR',
        id: 'fr',
        selector: (row) => row.frEnabled,
        sortable: true,
        width: '120px',
        cell: (row) => (row.frEnabled ? 'Enabled' : 'Disabled'),
        noOmit: false,
        omit: false,
      },
      {
        name: 'Grid Forming Mode',
        id: 'gfm',
        selector: (row) => row.gfmEnabled,
        sortable: true,
        width: '120px',
        cell: (row) => (row.gfmEnabled ? 'Enabled' : 'Disabled'),
        noOmit: false,
        omit: false,
      },
      {
        name: 'Blackstart',
        id: 'blackstart',
        selector: (row) => row.blackstartEnabled,
        sortable: true,
        width: '120px',
        cell: (row) => (row.blackstartEnabled ? 'Enabled' : 'Disabled'),
        noOmit: false,
        omit: false,
      },
      {
        name: 'QATN',
        id: 'qatn',
        selector: (row) => row.qatnEnabled,
        sortable: true,
        width: '120px',
        cell: (row) => (row.qatnEnabled ? 'Enabled' : 'Disabled'),
        noOmit: false,
        omit: false,
      },
    ]

    return isGroupInStackOsMode
      ? [
          {
            name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
            id: 'actions',
            sortable: false,
            width: '55px',
            cell: (row) => (
              <ActionsMenu onOpen={() => null} onClose={() => null}>
                <Styles.ActionModalSection>
                  <ActionsMenuItem
                    onClick={() =>
                      openModal({
                        type: 'showPcsModuleInverterRotationModal',
                        idsForModal: [getRowId(row)],
                      })
                    }
                  >
                    <Styles.MenuIcon>
                      <Icon
                        size="xxs"
                        color={COLORS.action_blue}
                        icon={<CheckIcon />}
                      />
                    </Styles.MenuIcon>
                    <div>Set Rotation</div>
                  </ActionsMenuItem>
                  <ActionsMenuItem
                    disabled={!deviceHasFaults(row)}
                    onClick={() =>
                      openModal({
                        type: 'showFaultClearModal',
                        deviceType: ELECTRICAL_DEVICE_TYPES['PCS Modules'],
                        stationCode: stationCode,
                        blockIndex: blockIndex,
                        faultingDevices: [row],
                      })
                    }
                  >
                    <Styles.MenuIcon>
                      <Icon
                        size="xxs"
                        color={
                          deviceHasFaults(row)
                            ? COLORS.action_blue
                            : COLORS.font_secondary
                        }
                        icon={<LightningIcon />}
                      />
                    </Styles.MenuIcon>
                    <div>Clear Faults</div>
                  </ActionsMenuItem>
                </Styles.ActionModalSection>
              </ActionsMenu>
            ),
            style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
          },
          ...columns,
        ]
      : columns
  }, [blockIndex, isGroupInStackOsMode, openModal, stationCode, isIdSortable])

  const tableActions = useMemo(() => {
    const faultingSelectedDevices = selectedRows
      .map((rowId) => {
        const device = data?.find((device) => getRowId(device) === rowId)
        if (!device) return null
        return deviceHasFaults(device) ? device : null
      })
      .filter((device) => device)

    return (
      <Styles.ButtonContainer>
        <FormCommandButton
          disabled={!selectedRows?.length}
          onClick={() =>
            openModal({
              type: 'showPcsModuleInverterRotationModal',
              idsForModal: selectedRows,
            })
          }
        >
          <Icon
            size="xxs"
            color={
              selectedRows.length === 0
                ? COLORS.disabled_action
                : COLORS.action_blue
            }
            icon={<CheckIcon />}
          />
          Set Rotation
        </FormCommandButton>
        <FormCommandButton
          disabled={!faultingSelectedDevices?.length}
          onClick={() =>
            openModal({
              type: 'showFaultClearModal',
              deviceType: ELECTRICAL_DEVICE_TYPES['PCS Modules'],
              stationCode: stationCode,
              blockIndex: blockIndex,
              faultingDevices: faultingSelectedDevices,
            })
          }
        >
          <Icon
            size="xxs"
            color={
              faultingSelectedDevices?.length
                ? COLORS.action_blue
                : COLORS.disabled_action
            }
            icon={<LightningIcon />}
          />
          Clear Faults
        </FormCommandButton>
      </Styles.ButtonContainer>
    )
  }, [blockIndex, data, openModal, selectedRows, stationCode])

  return (
    <Table
      tableId="EksPcsModules"
      data={data}
      columns={columnsWithActions}
      getRowId={getRowId}
      defaultId="id"
      fixedHeader={false}
      selectableRows={isGroupInStackOsMode}
      onSelectedRowsChange={handleRowsSelection}
      tableActions={isGroupInStackOsMode ? tableActions : null}
    />
  )
}

export default PcsModulesTable

const IdCell = styled.span`
  font-weight: ${FONT_WEIGHT.strong};
`
