export const USER_ROLES = {
  FIRMWARE_TRIGGER: 'ROLE_FIRMWARE_TRIGGER',
  EXTRACTS: 'ROLE_EXTRACTS',
  TECH_SUPPORT: 'ROLE_TECH_SUPPORT',
  TEMP_SENSOR_MANAGER: 'ROLE_TEMP_SENSOR_MANAGER',
  AVAILABILITY_MANAGER: 'ROLE_AVAILABILITY_MANAGER',
  CONFIG_STATION: 'ROLE_CONFIG_STATION',
  FIRMWARE_UPLOAD: 'ROLE_FIRMWARE_UPLOAD',
  ROC_OPERATOR: 'ROLE_ROC_OPERATOR',
  PROGRAMMATIC_USER: 'ROLE_PROGRAMMATIC_USER',
  FIRMWARE_DEPLOY: 'ROLE_FIRMWARE_DEPLOY',
}
