import { useMemo, useRef } from 'react'
import { toast } from 'react-toastify'

import { ToastContent } from './ToastContent'
import {
  completeToastOptions,
  inProgressToastOptions,
  failedToastOptions,
} from './toastOptions'

export default function useProgressToaster() {
  const toastId = useRef(null)

  return useMemo(() => {
    return {
      toastPending: (title, content) =>
        (toastId.current = toast(
          <ToastContent title={title} content={content} />,
          inProgressToastOptions,
        )),

      toastSuccess: (title, content) =>
        toast.update(toastId.current, {
          ...completeToastOptions,
          render: <ToastContent title={title} content={content} />,
        }),

      toastError: (title, content) =>
        toast.update(toastId.current, {
          ...failedToastOptions,
          render: <ToastContent title={title} content={content} />,
        }),
    }
  }, [])
}
