import { PLUGIN_LIST } from '../PLUGIN_LIST'

/**
 *
 * @param {*} data the direct data supplied from the usePluginsQuery
 * @returns returns formatted data for the PlugInsTable
 */
const getPlugInsTableData = (data) => {
  const list = data?.pluginInfoList ? data.pluginInfoList : null
  const tableData = []

  // eslint-disable-next-line
  Object.entries(PLUGIN_LIST).forEach(([_, val]) => {
    // set defaults
    let tempData = {
      name: val.name,
      id: val.id,
      enabled: false,
      shortStatus: 'Unknown',
      canDisable: true,
      valid: false,
    }

    // check and modify if they exist
    if (list && list[val.key]) {
      tempData.valid = true
      tempData.shortStatus = list[val.key].enabled ? 'Enabled' : 'Disabled'
      tempData.enabled = list[val.key].enabled ? true : false
    }

    tableData.push(tempData)
  })

  return tableData
}

export default getPlugInsTableData
