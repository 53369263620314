import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useOutdoorEnvironmentQuery = (enabled = true) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    ['outdoorEnvironment', stationCode, blockIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/outdoorenvironment`,
        axiosOptions,
      )
      return response.data
    },
    { refetchInterval: 5000, enabled: enabled },
  )
}

export default useOutdoorEnvironmentQuery
