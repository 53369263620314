import AppTooltip from 'components/AppTooltip/AppTooltip'
import * as Styles from './styles'
import { uniqueId } from 'lodash'

const BinaryBarChartTotalVsUsed = ({
  positiveCount,
  totalCount,
  tooltip = false,
  opacity,
}) => {
  const id = uniqueId('toolTip')
  const _percent =
    positiveCount === 0 && totalCount === 0 ? 100 : positiveCount / totalCount

  return (
    <Styles.Container $opacity={opacity} data-tooltip-id={id}>
      <Styles.GreenBar $percent={_percent} />
      {tooltip && <AppTooltip id={id}>{tooltip}</AppTooltip>}
    </Styles.Container>
  )
}

export default BinaryBarChartTotalVsUsed
