import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FONT_WEIGHT } from 'design_system/typography'
import { COLORS } from 'design_system/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 35px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
`
export const Flex = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`
export const LogoContainer = styled(Link)`
  display: block;
  width: 25px;
  height: 25px;
`
export const AppName = styled(Link)`
  color: #22242a;
  text-decoration: none;
  font-weight: 600;
  font-size: 17px;
  padding-left: 15px;
`
export const Version = styled.div`
  padding: 8px;
  position: absolute;
  bottom: 0px;
  font-size: 8px;
  z-index: 100;
  color: rgba(0, 0, 0, 0);
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`
export const Light = styled.span`
  margin-left: 5px;
  color: #989898;
  font-weight: 400;
`
export const HeaderButton = styled.div`
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  /* height: 30px; */
  font-weight: 600;
  /* position: fixed; */
  /* z-index: 9999; */

  &:hover {
    background-color: #e0e0e0;
  }
`

export const CurrentUser = styled.span`
  font-weight: ${FONT_WEIGHT.normal};
  color: ${COLORS.font_secondary};
`
