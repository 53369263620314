import { COLORS } from 'design_system/colors'
import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 15px;
  margin-left: 40px;
`
export const InputRow = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  max-width: ${({ $maxWidth }) => $maxWidth};
`

export const DropdownItem = styled.div`
  color: ${(props) => (props.$isConnected ? 'black' : 'gray')};
  cursor: pointer;
  background-color: ${(props) => props.$isSelected && COLORS.light_grey};
  padding: 4px;
`
