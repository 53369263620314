import _ from 'lodash'

/**
 * take list of columns and apply user preference list of unselected items on page load
 * @param {string} unselectedIds comma separated string of un-selected ids
 * @param {object[]} filterList
 * defaults to complete list, ids represent unchecked items
 */
export const selectListBasedOnIdsProvided = (unselectedIds, filterList) => {
  if (typeof unselectedIds !== 'string') return filterList
  const _ids = unselectedIds.split(',')

  const updatedFilterList = filterList.map((f) =>
    _ids.includes(f.id) ? { ...f, omit: true } : { ...f, omit: false },
  )
  return updatedFilterList
}

/**
 *
 * @param {string} tableSettingsResp comma separated string of unselected items
 * @param {string} visibilityKey name of page
 * @param {array} columns list of column definitions
 * @returns
 */
export const getVisibleColumns = (tableSettingsResp, _columns) => {
  if (typeof tableSettingsResp !== 'string') return _columns
  return selectListBasedOnIdsProvided(tableSettingsResp, _columns)
}

export const omitToggler = (c, id) =>
  c.id === id ? { ...c, omit: !c.omit } : c

const columnsNotToShow = (id) => {
  return (
    id === 'audioOn' ||
    id === 'severity' ||
    id === 'id' ||
    id === 'actions' ||
    id === 'healthy' ||
    id === 'acBatteryStatus'
  )
}

export const getUnselectedToggleIds = (result) =>
  result
    .filter((x) => x.omit)
    .filter((x) => !columnsNotToShow(x.id))
    .map((y) => y.id)
    .join(',')

// removed unnecessary ids in the header filters
export const cleanHeaderTableColumnFilters = (tableFilters, data) => {
  return tableFilters.filter((x) => !columnsNotToShow(x.id))
}

export const preFilterTableData = (filters, data) => {
  return filters.reduce((acc, curr) => {
    if (curr.omit) {
      return acc.filter((d) => curr.filterBy(d))
    }
    return acc
  }, data)
}

// only used by DashboardPage TODO replace preSortAndFilterTableData? and use built in table sorting?
export const preSortTableData = (sortBy, data) => {
  let sortedData
  if (sortBy?.sortFunction) {
    // custom sort function
    const directionalCompare = (a, b) => {
      // invert sortFunction if needed
      if (sortBy.sortDirection === 'asc') {
        return sortBy.sortFunction(a, b)
      } else {
        return sortBy.sortFunction(b, a)
      }
    }
    sortedData = [...data].sort(directionalCompare) //default sort function
  } else if (sortBy) {
    sortedData = _.orderBy(data, sortBy.selector, [sortBy.sortDirection])
  } else {
    sortedData = data
  }

  return sortedData
}
