import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import { fetchPlugInStatus } from 'api/queries.api'

/**
 * @param {boolean} isEnabled
 * @returns UseQueryResult
 */
const usePlugInsQuery = (isEnabled = true) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)
  if (!stationCode || !blockIndex)
    throw new Error(
      'usePlugInsQuery requires a station and blockId available in the url',
    )

  return useQuery(
    ['plugIns', stationCode, blockIndex],
    () => fetchPlugInStatus(stationCode, blockIndex, session),
    { enabled: isEnabled },
  )
}

export default usePlugInsQuery
