import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { ApiErrorMessage } from 'components/ApiErrorMessage/ApiErrorMessage'

/**
 *
 * @param {*} query custom useQueryHook located in api/useQueryHooks
 * @param {*} Jsx component to render on success within a list page, on success jsx is passed the query data
 * @returns loading page, error page or the provided listPage on success
 * example usage see src\modules\Station\modules\SystemDetails\ACPVBatteryList\ACPVBatteryList.jsx
 */
const handleQueryLoadingAndError = (query, jsxComponent) => {
  const { isLoading, data, error } = query
  if (isLoading && !data) return <LoadingPage />

  if (error) return <ApiErrorMessage error={error} />

  const filteredData = Array.isArray(data)
    ? data.filter((row) => row.total > 0)
    : data
  return jsxComponent(filteredData)
}

export default handleQueryLoadingAndError
