import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import StringListSummary from './Summary/StringListSummary'
import StringTable from 'modules/Station/components/Tables/StringTable/StringTable'
import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'

const StringList = ({ selectedParams, setSelectedParams, setSelectedPage }) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'string-list' })
    document.title = `${stationCode}::${blockIndex} - Strings`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<StringListSummary />}
      tableComponent={<StringTable fixedHeader={true} />}
    />
  )
}

export default StringList
