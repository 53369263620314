import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
  width: 100%;
  box-shadow: inset 0 0 1px 1px #d3d3d3;
  border: solid 0.5px grey;
`

export const Bar = styled.div`
  width: ${(props) => props.$percent * 100}%;
  height: 100%;
`
export const GreenBar = styled(Bar)`
  background-color: #00b985;
`
export const GreyBar = styled(Bar)`
  background-color: #dae6ee;
`
export const WhiteBar = styled(Bar)`
  background-color: white;
`
export const RedBar = styled(Bar)`
  background-image: repeating-linear-gradient(
    135deg,
    #f05946,
    #f05946 5px,
    #aa3f31 5px,
    #aa3f31 10px
  );
  background-size: 200% 200%;
`
