// import { InverterRotationForm } from './InverterRotationForm'
import CommandModalTemplate from '../CommandModalTemplate'
import { PcsModuleInverterRotationResults } from './PcsModuleInverterRotation'
import {
  RotationForm,
  defaultRotationFormValues,
} from '../SetRotation/RotationForm'
import { ResourceEnum } from 'api/dictionaries/rotation-excuses'

// 1st modal, 2nd modal heading
const modalTitles = {
  start: 'Set PCS Module Rotation',
  finish: 'Set PCS Module Rotation',
}
const queryName = 'setPCSRotation'
const label = 'PCS Modules'
const resourceType = ResourceEnum.inverter

// step 1 define custom input form returns jsx
// form validation passed from CommandModalTemplate
const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  RotationForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    resourceType,
    label,
  })

// step 2 custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <PcsModuleInverterRotationResults ids={ids} userInputForm={userInputForm} />
)

// SetCommandModal keeps track of input form and each modal step, sending command, close modal
export const SetPcsModuleInverterRotationModal = ({
  setShowCommandModal,
  ids,
}) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated
    />
  )
}
