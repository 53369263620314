import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components/macro'

/**
 ** @deprecated - use Tooltip instead
 */
const AppTooltip = styled(ReactTooltip).attrs({
  float: true,
  className: 'appTooltip',
})`
  z-index: 100;
`

export default AppTooltip
