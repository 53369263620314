import styled from 'styled-components/macro'

export const Section = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const SectionName = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #989ea0;
`
export const Row = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`
export const Label = styled.label`
  cursor: pointer;
  color: ${(props) => (props.checked ? '#2d3e45' : '#949697')};
  font-weight: 500;
`
