import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { FONT_STYLES } from 'design_system/font_definitions'
import { ReactComponent as AlertIconSvg } from 'icons/alert-icon.svg'
import { ICON_SIZES } from 'design_system/sizes'
import { COLORS } from 'design_system/colors'

export const ActionsHeader = styled.div`
  border-right: solid rgba(0, 0, 0, 0.12) 1px;
  width: 55px;
  height: 27px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-flex;
  align-items: center;
`
export const SocCell = styled.div`
  display: flex;
  width: 70px;
  padding: 5px;
  font-size: 12px;
`

export const CenteredGridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StatusIcon = styled.div`
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : '')};
  width: ${(props) => (props.$width ? props.$width : '14px')};
  height: ${(props) => (props.$height ? props.$height : '14px')};
  fill: ${(props) =>
    props.$isHealthy ? COLORS.powin_green : COLORS.powin_red};
`

export const ActionModalDiv = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 20px auto;
  cursor: pointer;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const RowLink = styled(Link)`
  font-weight: 600;
  font-size: 13px;
  color: #333;
`

export const RotationIcon = styled.div`
  width: 12px;
  height: 12px;
  fill: ${(props) =>
    props.$inRotation ? COLORS.powin_green : COLORS.powin_red};
`

export const ButtonRowText = styled.div`
  background-color: #ffffff;
  display: flex;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.$disabled ? '#cccccc' : '#000000')};
  border-left: 1px solid grey;
  margin-left: 10px;
  margin-right: 10px;
  height: 25px;
`
export const ActionModalSection = styled.div`
  margin-bottom: 5px;
  padding: 4px 4px;

  &:last-child {
    margin: 0;
  }
`
export const ActionModalHeader = styled(FONT_STYLES.ActionModalHeaderP)`
  padding: 10px 15px 5px;
`
export const MenuIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// use this for a row entry that holds an icon next to say an id <RowIconText><icon><text></RowIconText>
export const RowIconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  line-height: 1;
`

export const AlertIcon = styled(AlertIconSvg)`
  width: ${ICON_SIZES.xxs};
  height: ${ICON_SIZES.xxs};
  fill: ${COLORS.powin_red};
`

export const TitleValue = styled.div`
  text-transform: capitalize;
`
