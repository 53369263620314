import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import ACPVBatteryDetailTable from 'modules/Station/components/Tables/ACPVBatteryDetailTable/ACPVBatteryDetailTable'

const ACPVBatDetailsMainColumn = (acPvBatteryIndex) => {
  return (
    <ExpandableCard title="BATTERY COMPONENTS" isExpanded={true} minimal={true}>
      <ACPVBatteryDetailTable acPvBatteryIndex={acPvBatteryIndex} />
    </ExpandableCard>
  )
}

export default ACPVBatDetailsMainColumn
