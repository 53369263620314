import axios from 'axios'
import { SessionContext } from 'contexts/session'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const usePvPcsDetailGraphQuery = (period) => {
  const { stationCode, blockIndex, acPvBatteryIndex, pvPcsIndex } = useParams()
  const { session } = useContext(SessionContext)

  const serviceCall = async () => {
    const apiUrl = session.server
    const axiosOptions = {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    }
    const response = await axios.get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/acpvbatteries/${acPvBatteryIndex}/pvpcses/${pvPcsIndex}/graph?period=${period}`,
      axiosOptions,
    )
    return response.data
  }

  return useQuery(
    ['PvPcsDetailGraph', stationCode, blockIndex, acPvBatteryIndex, pvPcsIndex],
    serviceCall,
    { refetchInterval: 5000 },
  )
}

export default usePvPcsDetailGraphQuery
