import { ReactComponent as ACPVBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'

const ACPVBatterySummary = ({ data }) => {
  if (!data) return null

  const summaryMap = {
    title: `AC PV Battery ${data.acPvBatteryIndex}`,
    titleIcon: <ACPVBatteryIcon />,

    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: data.isHealthy ? (
              <StatusIcon $isHealthy={data.isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
        ],
      },
      {
        sectionTitle: 'ESS AVAILABILITY',
        rows: [
          {
            label: 'Charge Power',
            value:
              Math.round(data.maxAvailableChargePowerKW).toLocaleString() +
              ' kW',
          },
          {
            label: 'Discharge Power',
            value:
              Math.round(data.maxAvailableDischargePowerKW).toLocaleString() +
              ' kW',
          },
        ],
      },
      {
        sectionTitle: 'ESS ENERGY',
        rows: [
          {
            label: 'Online Available',
            value: data.onlineAvailableKWH.toLocaleString() + ' kWh',
          },
          {
            label: 'Total Capacity',
            value: data.totalCapacityKWH.toLocaleString() + ' kWh',
          },
        ],
      },
      {
        sectionTitle: 'AC REAL POWER',
        rows: [
          {
            label: 'Measured',
            value: data.measuredACkW.toLocaleString() + ' kW',
          },
          {
            label: 'AC Curtailment Limit',
            value: data.curtailmentLimitACkW.toLocaleString() + ' kW',
          },
        ],
      },
      {
        sectionTitle: 'REACTIVE POWER',
        rows: [
          {
            label: 'Measured',
            value: data.measuredACKVAr.toLocaleString() + ' kVAr',
          },
          {
            label: 'Commanded',
            value: data.commandedACKVAr.toLocaleString() + ' kVAr',
          },
        ],
      },
      {
        sectionTitle: 'DC POWER',
        rows: [
          {
            label: 'Measured',
            value: data.measuredDCkW.toLocaleString() + ' kW',
          },
          {
            label: 'Commanded',
            value: data.commandedDCkW.toLocaleString() + ' kW',
          },
        ],
      },
      {
        sectionTitle: 'STRINGS',
        rows: [
          {
            label: 'Online',
            value: data.onlineStackCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: data.totalStackCount.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ACPVBatterySummary
