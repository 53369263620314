import React from 'react'

// Style Imports
import * as Styles from './styles'

// Component Imports
import Card from '../../../../../components/Cards/Card/Card'
import StringTempDistribution from '../../../components/Charts/StringTempDistribution/StringTEmpDistribution'
import StringVoltDistribution from '../../../components/Charts/StringVoltDistribution/StringVoltageDistribution'
import StringAmpDistribution from '../../../components/Charts/StringAmpDistribution/StringAmpDistribution'

const MainColumn = ({ data, viewType }) => {
  return (
    <Styles.Container>
      <Card title="Temperature Distribution" height="280px" isHorizontal={true}>
        <StringTempDistribution data={data} viewType={viewType} />
      </Card>

      <Card title="Voltage Distribution" height="280px" isHorizontal={true}>
        <StringVoltDistribution data={data} viewType={viewType} />
      </Card>

      <Card title="Amp Distribution" height="280px" isHorizontal={true}>
        <StringAmpDistribution data={data} viewType={viewType} />
      </Card>
    </Styles.Container>
  )
}

export default MainColumn
