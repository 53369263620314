import CommandModalTemplate from '../CommandModalTemplate'
import { BlockContactorForm } from './form'
import { BlockContactorResult } from './result'
import ContactorState from 'api/types/ContactorState'
import EnablementState from 'api/types/EnablementState'
import { useParams } from 'react-router-dom'
import { useSiteControllerVersionContext } from 'contexts/siteControllerVersionContext'

const modalTitles = {
  start: 'Set Contactors',
  finish: 'Contactor State',
}

export const BlockContactorModal = ({ ids, setShowCommandModal }) => {
  const { stationCode } = useParams()
  const { isOpModel2 } = useSiteControllerVersionContext()
  const defaultFormValues = {
    contactorState: ContactorState.OPEN,
    ignoreHighCellGroupVoltageAlarm: EnablementState.DISABLED,
    ignoreLowCellGroupVoltageAlarm: EnablementState.DISABLED,
  }
  const queryName = isOpModel2(stationCode)
    ? 'setBlockContactors2'
    : 'setBlockContactors'
  return (
    <CommandModalTemplate
      setShowCommandModal={setShowCommandModal}
      ids={ids}
      defaultFormValues={defaultFormValues}
      modalTitles={modalTitles}
      CommandResultJsx={BlockContactorResult}
      UserInputFormJsx={BlockContactorForm}
      queryName={queryName}
    />
  )
}
