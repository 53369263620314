import { fetchACBatteryStatus } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import { useParams } from 'react-router'

/**
 * Returns stations list
 * @returns 5 second interval block status
 */
const useACBatteryDetailsQuery = () => {
  const { session, setSession } = useContext(SessionContext)
  const { stationCode, blockIndex, acBatteryId, acPvBatteryIndex } = useParams()

  return useQuery(
    ['acBatteryStatusData', acBatteryId],
    () =>
      fetchACBatteryStatus(
        stationCode,
        blockIndex,
        acBatteryId || acPvBatteryIndex,
        session,
        setSession,
      ),
    {
      refetchInterval: 5000,
    },
  )
}

export default useACBatteryDetailsQuery
