import { useEffect, useState } from 'react'

import * as Styles from './FileList.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { COLORS } from 'design_system/colors'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import { FileListTable } from './FileListTable/FileListTable'

export const FileListCommand = ({ setSelectedCommandPage }) => {
  const [stationSelected, setStationSelected] = useState('Select one')
  const [systemTypeSelected, setSystemTypeSelected] = useState('Select one')
  const { data: stations, isLoading: isLoadingStations } =
    useStationsDataQuery()
  const systemTypes = ['Dragon', 'Feather', 'Phoenix']
  const commonDirectories = [
    '/',
    '/etc/powin',
    '/home/powin',
    '/var/lib/tomcat8',
    '/var/log/tomcat8',
  ]
  useEffect(() => setSelectedCommandPage('File List Command', []))

  const selectStationHandler = (station, block) => {
    setStationSelected(`${station} :: ${block}`)
  }

  const selectSystemTypeHandler = (systemType) => {
    setSystemTypeSelected(systemType)
  }

  if (isLoadingStations) {
    return <LoadingPage></LoadingPage>
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        File List Command
      </Typography>
      <Styles.FormGrid>
        <div>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0 12px"
          >
            STATION :: BLOCK INDEX
          </Typography>
          <ToolbarDropdown
            title={stationSelected}
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            overflow="scroll"
          >
            {stations.blocks.map((station) => (
              <div
                style={{
                  cursor: 'pointer',
                  padding: '4px',
                  color: 'black',
                  backgroundColor:
                    station.stationCode + ' :: ' + station.blockIndex ===
                    stationSelected
                      ? COLORS.light_grey
                      : '',
                }}
                key={`${station.stationCode}::${station.blockIndex}`}
                onClick={() =>
                  selectStationHandler(station.stationCode, station.blockIndex)
                }
              >
                {station.stationCode + ' :: ' + station.blockIndex}
              </div>
            ))}
          </ToolbarDropdown>
        </div>
        <div>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0 12px"
          >
            SYSTEM TYPE
          </Typography>
          <ToolbarDropdown
            title={systemTypeSelected}
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            overflow="scroll"
          >
            {systemTypes.map((type) => (
              <div
                style={{
                  cursor: 'pointer',
                  padding: '4px',
                  color: 'black',
                  backgroundColor:
                    type === systemTypeSelected ? COLORS.light_grey : '',
                }}
                key={type}
                onClick={() => selectSystemTypeHandler(type)}
              >
                {type}
              </div>
            ))}
          </ToolbarDropdown>
        </div>
      </Styles.FormGrid>
      <div>
        <Typography
          $fontSize={FONT_SIZES.medium}
          $fontColor={COLORS.font_secondary}
          $margin="0 0 5px"
        >
          COMMON DIRECTORIES
        </Typography>
        <Styles.PathsWrapper>
          {commonDirectories.map((directory, index) => (
            <Typography
              $bold
              $fontColor={COLORS.action_blue}
              $margin="5px 16px"
              key={index}
            >
              {directory}
            </Typography>
          ))}
        </Styles.PathsWrapper>
      </div>
      <Typography $bold $fontSize={FONT_SIZES.large} $margin="5px 0">
        Contents of /var/log/tomcat
      </Typography>

      <div>
        <Typography
          $fontSize={FONT_SIZES.medium}
          $fontColor={COLORS.font_secondary}
          $margin="5px 0"
        >
          PARENT DIRECTORY
        </Typography>
        <Typography $bold $fontColor={COLORS.action_blue}>
          /var/log
        </Typography>
      </div>
      <div>
        <Typography
          $fontSize={FONT_SIZES.medium}
          $fontColor={COLORS.font_secondary}
          $margin="5px 0"
        >
          SUB DIRECTORIES
        </Typography>
        <Typography $bold $fontColor={COLORS.action_blue}>
          cghistorian
        </Typography>
      </div>
      <div>
        <Typography
          $fontSize={FONT_SIZES.medium}
          $fontColor={COLORS.font_secondary}
          $margin="5px 0"
        >
          FILES
        </Typography>
        <FileListTable />
      </div>
    </Styles.Container>
  )
}
