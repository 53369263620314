import styled from 'styled-components/macro'

export const LabelRow = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }

  input {
    cursor: pointer;
    margin: 0 5px 0 0;
    line-height: 1;
  }
`
export const Label = styled.div`
  cursor: pointer;
  line-height: 1;
  flex: 1;
  font-weight: 500;
  color: ${(props) => (props.$isSelected ? '#333' : 'inherit')};
`
