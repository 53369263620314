import _ from 'lodash'

import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import Icon from 'components/Icon/Icon'
import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'
import { ReactComponent as AcBatteriesIcon } from 'icons/ac-battery-icon.svg'
import { ReactComponent as ArraysIcon } from 'icons/array-icon.svg'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import { ReactComponent as AcPvBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import { ReactComponent as DcAcPCSesIcon } from 'icons/dc-ac-pcs-icon.svg'
import { ReactComponent as DcDcGroupIcon } from 'icons/dc-group-icon.svg'
import { ReactComponent as DcDcPcsIcon } from 'icons/dc-dc-converter-icon.svg'

import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import ElectricalDevicesTable from './ElectricalDevicesTable/ElectricalDevicesTable'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useElectricalDevicesQuery from 'api/useQueryHooks/useElectricalDevicesQuery'
import { ELECTRICAL_DEVICE_TYPES } from './constants'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { useContext } from 'react'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

const ElectricalDevices = () => {
  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  const { data: blockStatusData, isLoading: isLoadingBlockStatus } =
    useBlockStatus()

  const { data: electricalDevicesData, isLoading: isLoadingElectricalDevices } =
    useElectricalDevicesQuery({
      enabled: true,
    })

  if (isLoadingBlockStatus || isLoadingElectricalDevices) return <LoadingPage />

  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(
    blockStatusData.isDcCoupled,
  )
  const blockPowerAndEnergyData = blockStatusData[blockPowerAndEnergyKey] || {}

  const blockMeterRow = () => {
    const { total: blockMeterTotal = 0, healthy: blockMeterHealthy = 0 } =
      electricalDevicesData?.blockMeters ?? {}

    if (blockMeterTotal > 0) {
      return [
        {
          icon: <Icon icon={<BlockMeterIcon />} size="xs" />,
          type: ELECTRICAL_DEVICE_TYPES['Block Meters'],
          linkPage: 'block-meter-list',
          total: blockMeterTotal,
          healthyCount: blockMeterHealthy,
          healthText: `${blockMeterHealthy} Healthy | ${
            blockMeterTotal - blockMeterHealthy
          } Unhealthy`,
        },
      ]
    }
    return []
  }

  const sharedRows = () => {
    //equipment found in all (ac- and dc-coupled) systems

    const arrayCount = _.chain(blockStatusData)
      .get('arrays')
      .toArray()
      .value().length
    const arrayHealthy = _.chain(blockStatusData)
      .get('arrays')
      .toArray()
      .filter({ valid: true })
      .value().length

    const {
      total: totalStackCount = 0,
      online: onlineStackCount = 0,
      offline: offlineStackCount = 0,
      nearline: nearlineStackCount = 0,
      notCommunicating: nonCommunicatingStackCount = 0,
    } = electricalDevicesData?.strings ?? {}

    const { total: totalPcsGroups = 0, healthy: healthyPcsGroups = 0 } =
      electricalDevicesData?.pcsGroups ?? {}

    const omitPowerStations =
      !userHasApiAccessCode(API_ACCESS_CODES.THREE_DA) || !totalPcsGroups

    return [
      {
        icon: <Icon icon={<ArraysIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES.Arrays,
        linkPage: 'array-list',
        total: arrayCount,
        healthyCount: arrayHealthy,
        healthText: `${arrayHealthy} Healthy | ${
          arrayCount - arrayHealthy
        } Unhealthy`,
      },
      {
        icon: <Icon icon={<StringIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES.Strings,
        linkPage: 'string-list',
        total: totalStackCount,
        healthyCount: {
          online: onlineStackCount,
          nearline: nearlineStackCount,
          offline: offlineStackCount,
          nonCommunicating: nonCommunicatingStackCount,
          total: totalStackCount,
        },
        healthText: `${onlineStackCount} On | ${nearlineStackCount} Near | ${offlineStackCount} Off | ${nonCommunicatingStackCount} NC`,
      },
      {
        icon: <Icon icon={<DcAcPCSesIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['PCS Groups'],
        linkPage: 'eks-pcs-group-list',
        omitRow: omitPowerStations,
        total: totalPcsGroups,
        healthyCount: healthyPcsGroups,
        healthText: `${healthyPcsGroups} Healthy | ${
          totalPcsGroups - healthyPcsGroups
        } Unhealthy`,
      },
    ]
  }

  const acRows = () => {
    //equipment only found in ac-coupled systems

    const acBatteryCount = blockPowerAndEnergyData?.acBatteryCount || 0
    const acBatteryHealthy = _.chain(blockStatusData)
      .get('acBatteries')
      .toArray()
      .filter({ enabled: true, valid: true, ready: true })
      .value().length

    const arrayPcsCount = electricalDevicesData?.pcses.total ?? 0
    const arrayPcsHealthy = electricalDevicesData?.pcses.healthy ?? 0

    return [
      ...blockMeterRow(),
      {
        icon: <Icon icon={<AcBatteriesIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['AC Batteries'],
        linkPage: 'ac-battery-list',
        total: acBatteryCount,
        healthyCount: acBatteryHealthy,
        healthText: `${acBatteryHealthy} Healthy | ${
          acBatteryCount - acBatteryHealthy
        } Unhealthy`,
      },
      {
        icon: <Icon icon={<DcAcPCSesIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES.PCSes,
        linkPage: 'array-pcs-list',
        total: arrayPcsCount,
        healthyCount: arrayPcsHealthy,
        healthText: `${arrayPcsHealthy} Healthy | ${
          arrayPcsCount - arrayPcsHealthy
        } Unhealthy`,
        faulting: electricalDevicesData?.pcses?.faulting ?? [],
      },
      ...sharedRows(),
    ]
  }

  const dcRows = () => {
    //equipment only found in dc-coupled systems

    return [
      ...blockMeterRow(),
      {
        icon: <Icon icon={<AcPvBatteryIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['AC PV Batteries'],
        linkPage: 'ac-pv-batteries-list',
        total: electricalDevicesData.acPvBatteries.total,
        healthyCount: electricalDevicesData.acPvBatteries.healthy,
        healthText: `${electricalDevicesData.acPvBatteries.healthy} Healthy | ${electricalDevicesData.acPvBatteries.unhealthy} Unhealthy`,
      },
      {
        icon: <Icon icon={<DcAcPCSesIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['PV PCSes'],
        linkPage: 'pv-pcs-list',
        total: electricalDevicesData.pvPcses.total,
        healthyCount: electricalDevicesData.pvPcses.healthy,
        healthText: `${electricalDevicesData.pvPcses.healthy} Healthy | ${electricalDevicesData.pvPcses.unhealthy} Unhealthy`,
        faulting: electricalDevicesData?.pvPcses?.faulting ?? [],
      },
      {
        icon: <Icon icon={<DcDcGroupIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['DC:DC Groups'],
        linkPage: 'dc-dc-groups-list',
        total: electricalDevicesData.dcDcGroups.total,
        healthyCount: electricalDevicesData.dcDcGroups.healthy,
        healthText: `${electricalDevicesData.dcDcGroups.healthy} Healthy | ${electricalDevicesData.dcDcGroups.unhealthy} Unhealthy`,
      },
      {
        icon: <Icon icon={<DcDcPcsIcon />} size="xs" />,
        type: ELECTRICAL_DEVICE_TYPES['DC:DC PCSes'],
        linkPage: 'dc-dc-pcs-list',
        total: electricalDevicesData.dcDcPcses.total,
        healthyCount: electricalDevicesData.dcDcPcses.healthy,
        healthText: `${electricalDevicesData.dcDcPcses.healthy} Healthy | ${electricalDevicesData.dcDcPcses.unhealthy} Unhealthy`,
        faulting: electricalDevicesData?.dcDcPcses?.faulting ?? [],
      },
      ...sharedRows(),
    ]
  }

  const tableData =
    (blockStatusData.isDcCoupled ? dcRows() : acRows())?.filter(
      (row) => !row?.omitRow,
    ) ?? []

  return (
    <ExpandableCard
      title="ELECTRICAL DEVICES"
      isExpanded={true}
      minimal={true}
      className="elecDevices"
    >
      <ElectricalDevicesTable data={tableData} />
    </ExpandableCard>
  )
}

export default ElectricalDevices
