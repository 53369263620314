import React, { useState, useCallback, useMemo } from 'react'

const servers = {
  prod: 'https://kobold.powinservices.com/kobold/dataapi/v1/',
  staging: 'https://goblin-test01.powinservices.com/kobold/dataapi/v1/',
  qa: 'https://testkobold.powinservices.com/kobold/dataapi/v1/',
  beta: 'https://kobold.powinservices.com/kobold/dataapi/v1/', //same as prod
  sec: 'https://kobold-sec.powindevqa.com/kobold/dataapi/v1/',
  qas: 'https://kobold-development.qas.powindevqa.com/kobold/dataapi/v1/',
  qas2: 'https://kobold-02-qas.powindevqa.com/kobold/dataapi/v1/',
  local: 'https://localhost:8443/kobold/dataapi/v1/',
  qah: 'https://kobold-qah.powindevqa.com/kobold/dataapi/v1/',
  sus: 'https://kobold-sus.powindevqa.com/kobold/dataapi/v1/',
  ppd: 'https://kobold-ppd.powindevqa.com/kobold/dataapi/v1/',
}

const hostName = window.location.hostname
let environmentName

// very important to update if env URL changes, sets once on app start
switch (hostName) {
  case 'localhost.powinservices.com':
  case 'commandcenter-development.qas.powindevqa.com':
    environmentName = 'qas'
    break
  case 'command-center-02-qas.powindevqa.com':
    environmentName = 'qas2'
    break
  case 'staging-commandcenter.powinservices.com':
    environmentName = 'staging'
    break
  case 'commandcenterdemo.powinservices.com':
    environmentName = 'prod'
    break
  case 'testkobold-commandcenter.powinservices.com':
    environmentName = 'qa'
    break
  case 'commandcenterlimited.powinservices.com':
    environmentName = 'beta'
    break
  case 'command-center-sec.powindevqa.com':
    environmentName = 'sec'
    break
  case 'command-center-qah.powindevqa.com':
  case 'localhost.qah.powinservices.com':
    environmentName = 'qah'
    break
  case 'command-center-sus.powindevqa.com':
    environmentName = 'sus'
    break
  case 'command-center-ppd.powindevqa.com':
    environmentName = 'ppd'
    break
  case 'localhost':
    environmentName = 'local'
    break
  default:
    environmentName = 'prod'
    break
}

const defaultSession = {
  server: servers[environmentName],
  settings: {},
}

const sessionInStorage = JSON.parse(localStorage.getItem('session'))
const sessionToken = sessionInStorage?.token
const sessionUserAuth = sessionInStorage?.userAuth
const sessionUsername = sessionInStorage?.username

const initialState = sessionToken
  ? {
      ...defaultSession,
      token: sessionToken,
      userAuth: sessionUserAuth,
      username: sessionUsername,
    }
  : defaultSession

///////////////////////////////////////////
const SessionContext = React.createContext(initialState)

const SessionContextProvider = ({ children }) => {
  const [session, setSession] = useState(initialState)

  const clearSession = useCallback((message) => {
    const resetSession = {
      ...defaultSession,
      message: message,
    }
    localStorage.setItem('session', JSON.stringify(resetSession))
    setSession(resetSession)
  }, [])

  // always preserve settings until moved to data api
  const userSetSession = useCallback(
    (newSession) => {
      const updatedSession = { ...session, ...newSession }
      setSession(updatedSession)
      updateLocalStorage(
        updatedSession.token,
        updatedSession.userAuth,
        updatedSession.settings,
        updatedSession.username,
      )
    },
    [session],
  )

  const sessionValue = useMemo(() => {
    return {
      session,
      setSession: userSetSession,
      clearSession,
    }
  }, [clearSession, session, userSetSession])

  return (
    <SessionContext.Provider value={sessionValue}>
      {children}
    </SessionContext.Provider>
  )
}

export { SessionContextProvider, SessionContext }

// currently token, userAuth, settings, username are needed to persist session to new tabs
const updateLocalStorage = (token, userAuth, settings, username) => {
  const local = {
    token,
    userAuth,
    settings,
    username,
  }
  localStorage.setItem('session', JSON.stringify(local))
}
