// Component Imports
import Icon from 'components/Icon/Icon'

// Icon Imports
import { ReactComponent as AlertIcon } from 'icons/alert-icon.svg'
import { ReactComponent as CheckCircleIcon } from 'icons/check-circle-icon.svg'
import { ReactComponent as MinusIcon } from 'icons/minus-icon.svg'
import { ReactComponent as QuestionIcon } from 'icons/question-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

import {
  InfoHeading,
  ModalTable,
  WarnHeading,
  PendingHeading,
  Row,
  Container,
} from './CommandResultsTemplate.styles'

import { useCommandResultsQueries } from './useCommandResultsQueries'
import { processBalancingHeadings } from './Balancing/balancingUtils'

/*
example inputs

const rotationEvaluation = (stringDetails, userInputForm) => stringDetails.outRotation === (userInputForm.outRotation === 'true')

const rotationHeadings = (userInputForm) => userInputForm.outRotation === 'true' ? 'OUT ROTATION' : 'IN ROTATION'  // ROTATION

const rotationTd = (userInputForm) => {
    return (
        <>
        {userInputForm.outRotation === 'false' ? <Icon
            size="xxs"
            color={COLORS.powin_green}
            icon={<CheckIcon />}
        /> :
            <Icon
                size="xxs"
                color={COLORS.powin_red}
                icon={<ErrorIcon />}
            />
        }
    </>
    )
} 
 */

/**
 *
 * @param ids is an array of id strings in a format that uniquely identifies the type of item being commanded
 * @param userInputForm is the data entered by the user as part of the command
 * @param pendingEvaluation (stringDetails, userInputForm) compare stringDetails with userInputForm to return boolean if matches form desire
 * @param headingsEvaluation (userInputForm) update heading based on userInputForm for example 'IN ROTATION' vs 'OUT ROTATION'
 * @param resultHeading (userInputForm) update heading based on userInputForm for example 'IN ROTATION' vs 'OUT ROTATION'
 * @param ResultsTd (userInputForm) success icons based on userInputForm.  ex: inRotation CheckIcon vs outRotation ErrorIcon
 * @param pendingQueryName is a string, you add a matching query to the switch inside of useEffect below
 * @returns results modal after user makes command selection on previous modal
 */
export const CommandResultsTemplate = ({
  ids,
  userInputForm,
  pendingEvaluation,
  resultHeading,
  ResultsTd,
  pendingQueryName,
}) => {
  const { results, showWarning } = useCommandResultsQueries(
    pendingEvaluation,
    userInputForm,
    ids,
    pendingQueryName,
  )

  // auto adds's for multiples
  const itemName = {
    stringDetails: 'STRING',
    inverterListFunc: 'PCS',
    arrayDetailsFunc: 'ARRAY',
    queryACContactors: 'AC BATTERY',
    queryACRotation: 'AC BATTERY',
    blockDetails: 'BLOCK',
    setAppStatus: 'APP',
    setPlugInStatus: 'PLUGIN',
    stringBalancingDetails: 'STRING',
    arrayBalancingDetails: 'ARRAY',
    setACBatteryBalancing: 'AC BATTERY',
    preferredCurrentDetails: 'PREFERRED CURRENT',
    dcDcGroupRotation: 'DC:DC GROUP',
    dcDcPcsesRotation: 'DC:DC PCS',
    pvPcsRotation: 'PV PCS',
  }[pendingQueryName]

  const updatedCount = results.updated.length
  const pendingCount = results.pending.length

  const formatItemName = (itemName, count) => {
    if (itemName === 'AC BATTERY') {
      return count > 1 ? 'AC BATTERIES' : itemName
    } else if (itemName === 'DC:DC PCS') {
      return count > 1 ? 'DC:DC PCSES' : itemName
    } else if (itemName === 'PV PCS') {
      return count > 1 ? 'PV PCSES' : itemName
    } else if (itemName === 'PCS') {
      return count > 1 ? 'PCSES' : itemName
    } else {
      return `${itemName}${count > 1 ? 's' : ''}`
    }
  }

  // add to list if a multi td results
  const multiTableDataRows = {
    stringBalancingDetails: true,
    arrayBalancingDetails: true,
    setACBatteryBalancing: true,
    preferredCurrentDetails: true,
  }

  // it is now possible to pass in an array for resultHeading otherwise backwards compatible with single heading value
  const processResultHeading = (resultHeading) => {
    if (multiTableDataRows[pendingQueryName]) {
      return processBalancingHeadings(userInputForm, resultHeading)
    }
    return <td>{resultHeading}</td>
  }

  const processResultData = () => {
    if (multiTableDataRows[pendingQueryName]) {
      return <ResultsTd userInputForm={userInputForm} />
    }
    return (
      <td>
        <ResultsTd userInputForm={userInputForm} />
      </td>
    )
  }

  const processPendingData = () => {
    if (multiTableDataRows[pendingQueryName]) {
      return <ResultsTd userInputForm={userInputForm} />
    }
    return (
      <td>
        <Icon size="xxs" color={COLORS.warning_amber} icon={<MinusIcon />} />
      </td>
    )
  }

  return (
    <Container>
      {updatedCount > 0 && (
        <>
          <Row>
            <InfoHeading>
              <Icon size="xs" color="#00ba86" icon={<CheckCircleIcon />} />{' '}
              {updatedCount} {formatItemName(itemName, updatedCount)} UPDATED
            </InfoHeading>
          </Row>
          <Row>
            <ModalTable>
              <thead>
                <tr key={'header'}>
                  <td>{itemName.toUpperCase()} ID</td>
                  {processResultHeading(resultHeading)}
                </tr>
              </thead>
              <tbody>
                {results.updated.map((id) => (
                  <tr key={id + 'current'}>
                    <td>{id.replace(/:/g, ' :: ')}</td>
                    {processResultData()}
                  </tr>
                ))}
              </tbody>
            </ModalTable>
          </Row>
        </>
      )}
      {pendingCount !== 0 && (
        <>
          <Row>
            {showWarning ? (
              <WarnHeading>
                <Icon size="xs" color="red" icon={<AlertIcon />} />
                {pendingCount} {formatItemName(itemName, pendingCount)} not
                updated
              </WarnHeading>
            ) : (
              <PendingHeading>
                <Icon size="xs" color="#ba6800" icon={<QuestionIcon />} />
                {pendingCount} {formatItemName(itemName, pendingCount)} pending
                update
              </PendingHeading>
            )}
          </Row>
          <Row>
            <ModalTable>
              <thead>
                <tr>
                  <td>{itemName.toUpperCase()} ID</td>
                  {processResultHeading(resultHeading)}
                </tr>
              </thead>
              <tbody>
                {results.pending.map((id) => (
                  <tr key={id}>
                    <td>{id.replace(/:/g, ' :: ')}</td>
                    {processPendingData()}
                  </tr>
                ))}
              </tbody>
            </ModalTable>
          </Row>
        </>
      )}
    </Container>
  )
}
