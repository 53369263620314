import { formatNumber } from 'utils/formatting'
import { kilovar_to_megavar } from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function ReactivePowerTable({ data }) {
  const tableData = [
    {
      name: 'Total Reactive Power',
      operation: formatNumber({
        value: kilovar_to_megavar(data?.totalReactivePowerCmdkVAr),
        precision: 2,
        unit: ' MVAr',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(data?.totalReactivePowerSettingkVAr),
        precision: 2,
        unit: ' MVAr',
      }),
    },
    {
      name: 'Ramp Rate Up',
      operation: formatNumber({
        value: kilovar_to_megavar(
          data?.rampRateUpReactiveResponseCmdkVArPerMin,
        ),
        precision: 2,
        unit: ' MVAr / min',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(
          data?.rampRateUpReactiveResponseSettingkVArPerMin,
        ),
        precision: 2,
        unit: ' MVAr / min',
      }),
    },
    {
      name: 'Ramp Rate Down',
      operation: formatNumber({
        value: kilovar_to_megavar(
          data?.rampRateDownReactiveResponseCmdkVArPerMin,
        ),
        precision: 2,
        unit: ' MVAr / min',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(
          data?.rampRateDownReactiveResponseSettingkVArPerMin,
        ),
        precision: 2,
        unit: ' MVAr / min',
      }),
    },
  ]

  return (
    <StatusAndOperationTable
      tableId="EksPpcReactivePowerTable"
      data={tableData}
    />
  )
}
