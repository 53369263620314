import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #f7f7f9;
  background-color: #fff;
`
export const LeftColumn = styled.div`
  min-width: 500px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1;
`
export const Flex = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: -110px;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  padding-right: 100px;
  width: 100%;
`
export const Intro = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`
export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding: 35px;
  font-size: 20px;
`
export const Logo = styled.div`
  width: 40px;
  height: 40px;
`
export const Title = styled.div`
  color: #22242a;
  text-decoration: none;
  font-weight: 600;
  margin-left: 20px;
`
export const Subtitle = styled.div`
  color: #989898;
  margin-left: 5px;
`
export const Section = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  input,
  select {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 8px;
    font-size: 14px;
    width: 100%;
  }
  select {
    min-height: 3.2em;
  }
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Button = styled.button`
  cursor: pointer;
  margin-top: 20px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  background-color: #30bf91;
  color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`
export const Image = styled.div`
  flex: 1;
  background-image: url('/images/login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
export const ErrorMessage = styled.div`
  background-color: ${(props) => (props.$hasError ? 'red' : 'transparent')};
  color: red;
`
export const SwitchButton = styled(Link)`
  padding: 3px 10px;
  background: #00bf91;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
  }
`
