export function buildYAxis(min, max, isZooming) {
  return {
    title: {
      text: undefined,
    },
    opposite: false,
    labels: {
      format: '{value:,.0f} mV',
    },
    tickPosition: 'inside',
    startOnTick: false,
    endOnTick: false,
    min: 2000,
    max: 3700,
    plotLines: [
      {
        value: min ?? 2000,
        color: 'red',
        dashStyle: 'solid',
        width: 1,
        zIndex: 3,
        label: {
          text: 'Min. Operating Voltage',
          align: 'right',
          style: {
            color: '#555',
            fontSize: 10,
          },
        },
      },
      {
        value: max ?? 3700,
        color: 'red',
        dashStyle: 'solid',
        width: 1,
        zIndex: 3,
        label: {
          text: 'Max. Operating Voltage',
          style: {
            color: '#555',
            fontSize: 10,
          },
        },
      },
    ],
    tickPositions: isZooming
      ? undefined
      : [2000, 2300, 2600, 2900, 3200, 3450, 3700],
  }
}
