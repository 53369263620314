import styled from 'styled-components/macro'

/**
 * props: isRow(boolean) -> true will set flex else block
 */
const FormRow = styled.div`
  display: ${(props) => (props.$isRow ? 'flex' : 'block')};
  align-items: center;
  margin-bottom: 15px;
`
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 15px;

  &:last-child {
    padding: 0;
  }
`
const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #aab7c1;
  text-transform: uppercase;
  margin-bottom: 10px;
`
const FormInput = styled.input`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 8px;
  font-size: 14px;
  width: 100%;
  background-color: ${(props) => (props.$isDisabled ? '#f1f1f3' : '')};
`
const FormButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: ${(props) => (props.$width ? props.$width : 'auto')};
`

// parent
const FormButton = styled.button`
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
`
const FormCancelButton = styled(FormButton)`
  background-color: #b9b9b9;
  color: #fff;
  margin-right: 10px;
  padding: 6px 20px;
  text-transform: uppercase;
`

const FormSubmitButton = styled(FormButton)`
  padding: 6px 20px;
  background-color: ${(props) => (props.$isValid ? '#30BF91' : '#b9b9b9')};
  color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  width: ${(props) => (props.$width ? props.$width : '130px')};
  height: ${(props) => (props.$height ? props.$height : '')};
  text-transform: uppercase;
  margin-right: ${(props) => (props.$marginRight ? props.$marginRight : '')};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '')};
`

const FormCommandButton = styled(FormButton)`
  background-color: #ffffff;
  border: 1px solid #ccc;
  display: flex;
  gap: 10px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin-right: 10px;
  &:disabled {
    color: #cccccc;
    background-color: #eeeeee;
    cursor: not-allowed;
  }
  height: 25px;
`
const FormWarningLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  margin: 10px 0px;
`
const FormWarningRow = styled.div`
  background-color: #ff0000;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export {
  FormRow,
  FormColumn,
  FormLabel,
  FormInput,
  FormButtonRow,
  FormCancelButton,
  FormSubmitButton,
  FormCommandButton,
  FormWarningLabel,
  FormWarningRow,
}
