import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 15px;
  margin-left: 40px;
`

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
`

export const PathsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
