import { Typography } from 'components/typography/Typography.styled'
import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
export const Flex = styled.div`
  flex: 1;
`
export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px 10px 35px;
`
export const TitleIcon = styled.div`
  fill: #788b95;
  width: 40px;
  height: 40px;
  margin-right: 15px;
`

export const Subtitle = styled.div`
  padding: 15px 20px 10px 35px;
`

export const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px 20px 20px 25px;
`

export const Section = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }
`

export const SectionTitle = styled.div`
  text-transform: uppercase;
  padding: ${(props) => (props.$isAccordion ? '' : '10px 10px 5px 10px')};
  border-bottom: ${(props) => (props.$isAccordion ? '' : '1px solid #d8d8d8')};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 5px 0;
  margin: 0 5px 0 10px;
  border-bottom: 1px solid #e4e4e4;

  &:last-child {
    border-bottom: none;
  }
`

export const Icon = styled.div`
  font-size: 12px;
  height: 15px;
  width: ${(props) => (props.$doubleIcon ? '40px' : '15px')};
`

export const SummaryDefault = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 50px 0;
  margin-right: 25px;
  border-radius: 5px;
  background-color: #eeeef1;
  font-size: 11px;
  font-weight: 500;
  color: #b6b6bb;
  text-transform: uppercase;
`
export const SummaryBarChart = styled.div`
  width: 100px;
  padding: 3px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  background-color: #fff;
`

export const Value = styled(Typography)`
  white-space: nowrap;
`
