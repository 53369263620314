import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { useParams } from 'react-router-dom'
import {
  DisabledControlCheckbox,
  HealthStatusIconWithToolTips,
  RowRotationWithTooltip,
  sortIdsWithColonRecursive,
  useIsStationConnected,
} from '../tableUtils'
import Tooltip from 'components/Tooltip'
import { COLORS } from 'design_system/colors'
import { ELECTRICAL_DEVICE_TYPES } from '../../ElectricalDevices/constants'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as LightningIcon } from 'icons/lightning-icon.svg'
import { ClearDeviceAlertsModal } from '../../Commands/ClearDeviceAlerts/ClearDeviceAlertsModal'
import { useContext, useEffect, useReducer, useState } from 'react'
import Icon from 'components/Icon/Icon'
import { Typography } from 'components/typography/Typography.styled'
import { DcDcPcsRotationModal } from '../../Commands/DcDcPcsRotation/DcDcPcsRotationModal'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'
import { FormCommandButton } from 'components/Form/FormComponents.styled'

/////////////////  ACTION MODALS /////////////////////////////////
// see Inverter table for example to add actions and row selections
/////////////////////////////////////////////////////////////////

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showClearFaultsModal': {
      action.setShowCommandModal(true)
      return () => (
        <ClearDeviceAlertsModal
          setShowCommandModal={action.setShowCommandModal}
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          faultingDevices={action.faultingDevices}
          deviceType={action.deviceType}
        />
      )
    }
    case 'showDcDcPCSRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <DcDcPcsRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

const DcDcPCSesTable = ({ data, fixedHeader = true }) => {
  const [isIdSortable, setIsIdSortable] = useState(false) // sortable by id after first render, as it sorts by selector instead for first render
  const { stationCode, blockIndex } = useParams()
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [selectedIds, setSelectedIds] = useState([])
  const [showCommandModal, setShowCommandModal] = useState(false)
  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  const isRotationEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DC_DC_PCS_ROTATION_COMMAND,
  )

  useEffect(() => {
    setIsIdSortable(true)
  }, [])

  const baseUrl = `/block-details/${stationCode}/${blockIndex}/block-topology`

  const dcDcPCSesId = (row) => {
    const { acPvBatteryIndex, dcDcGroupIndex, dcDcPcsIndex } = row
    return (
      <Styles.RowLink
        to={`${baseUrl}/ac-pv-battery/${acPvBatteryIndex}/dc-dc-group/${dcDcGroupIndex}/dc-dc-pcs/${dcDcPcsIndex}`}
      >
        {`${acPvBatteryIndex} :: ${dcDcGroupIndex} :: ${dcDcPcsIndex}`}
      </Styles.RowLink>
    )
  }

  const getRowId = (row) =>
    `${row.acPvBatteryIndex}:${row.dcDcGroupIndex}:${row.dcDcPcsIndex}`

  const rowHasFaults = (row) => {
    return Boolean(row?.faults?.length)
  }

  const disableRotation = (row) =>
    row?.parentDcDcGroupOutRotation || row?.parentPvPcsOutRotation

  const enableCheckAll = data.some(
    (row) =>
      row?.parentDcDcGroupOutRotation === false &&
      row?.parentPvPcsOutRotation === false,
  )

  const handleSelectedRowsChange = (rowsObject) => {
    setSelectedIds(
      Object.keys(rowsObject).filter((key) => rowsObject[key]), //returns array of ids where key value is true
    )
  }

  const areTableActionsEnabled =
    isStationConnected && userHas8PC && isRotationEnabled

  const tableActions = areTableActionsEnabled && (
    <Styles.ButtonContainer>
      <FormCommandButton
        disabled={selectedIds.length === 0}
        onClick={() => {
          modalDispatch({
            type: 'showDcDcPCSRotationModal',
            setShowCommandModal: setShowCommandModal,
            idsForModal: selectedIds,
          })
        }}
      >
        <Icon
          size="xxs"
          color={
            selectedIds.length === 0
              ? COLORS.disabled_action
              : COLORS.action_blue
          }
          icon={<CheckIcon />}
        />
        Set PCS Rotation
      </FormCommandButton>
    </Styles.ButtonContainer>
  )
  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit: false,
      width: '55px',
      cell: (row) => (
        <ActionsMenu key={row} onOpen={() => null} onClose={() => null}>
          <ActionsMenuModalContent
            id={getRowId(row)}
            modalDispatch={modalDispatch}
            setShowCommandModal={setShowCommandModal}
            stationCode={stationCode}
            blockIndex={blockIndex}
            isRotationDisabled={disableRotation(row)}
            resetableFaults={
              row?.topologyEntry?.allowFaultReset ? row.faults : []
            }
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: isIdSortable,
      sortFunction: (a, b) =>
        sortIdsWithColonRecursive(getRowId(a), getRowId(b)),
      width: '90px',
      noOmit: true,
      cell: dcDcPCSesId,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.healthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) =>
        HealthStatusIconWithToolTips(row.healthy && !rowHasFaults(row)),
    },
    {
      name: 'IN ROT.',
      id: 'rotation',
      selector: (row) => row.outRotation,
      sortable: true,
      width: '65px',
      cell: (row) => RowRotationWithTooltip(row.outRotation),
      noOmit: false,
      omit: false,
    },
    {
      name: 'Faults',
      id: 'faults',
      omit: false,
      selector: (row) => row.faults.length ?? 0,
      sortable: true,
      width: '70px',
      cell: (row) =>
        rowHasFaults(row) && (
          <Tooltip title={row?.topologyEntry?.statusMessage ?? 'N/A'}>
            <Styles.AlertIcon />
          </Tooltip>
        ),
    },
    {
      name: 'BATTERY VOLT.',
      id: 'batV',
      selector: (row) => row.dcVoltageBatteryVolt,
      sortable: true,
      width: '100px',
      cell: (row) => `${row.dcVoltageBatteryVolt} VDC`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'BATTERY CURR.',
      id: 'batC',
      selector: (row) => row.dcCurrentBatteryAmp,
      sortable: true,
      width: '105px',
      cell: (row) => row.dcCurrentBatteryAmp + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'BATTERY POWER',
      id: 'batP',
      selector: (row) => row.dcPowerBatteryKW,
      sortable: true,
      width: '110px',
      cell: (row) => row.dcPowerBatteryKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PV VOLT.',
      id: 'pvVolt',
      selector: (row) => row.dcVoltageBusVolt,
      sortable: true,
      width: '90px',
      cell: (row) => row.dcVoltageBusVolt + ' VDC',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PV CURR.',
      id: 'pvCur',
      selector: (row) => row.dcCurrentBusAmp,
      sortable: true,
      table: true,
      width: '90px',
      cell: (row) => row.dcCurrentBusAmp + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PV POWER',
      id: 'pvPower',
      selector: (row) => row.dcPowerBusKW,
      sortable: true,
      table: true,
      width: '90px',
      cell: (row) => row.dcPowerBusKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
  ]
  return (
    <>
      <Table
        tableId="DcDcPCSesTable"
        data={data}
        columns={columns}
        fixedHeader={fixedHeader}
        getRowId={getRowId}
        defaultId="id"
        tableActions={tableActions}
        selectableRows={areTableActionsEnabled}
        selectableRowsComponent={DisabledControlCheckbox}
        onSelectedRowsChange={(e) => handleSelectedRowsChange(e)}
        //selectable status is tied to rotation
        isColumCheckDisabled={!enableCheckAll}
        isRowCheckDisabled={disableRotation}
      />
      {showCommandModal && <ActionModalStateJsx />}
    </>
  )
}

const ActionsMenuModalContent = ({
  id,
  modalDispatch,
  setShowCommandModal,
  closeActionModal,
  isRotationDisabled,
  resetableFaults,
  stationCode,
  blockIndex,
}) => {
  const actionClickHandler = (payload) => {
    closeActionModal()
    modalDispatch(payload)
  }

  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  const isRotationEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DC_DC_PCS_ROTATION_COMMAND,
  )

  const showRotationCommand =
    isStationConnected && userHas8PC && isRotationEnabled

  return (
    <div key={id.toString()} id={id.toString()}>
      <Styles.ActionModalSection>
        {showRotationCommand && (
          <Tooltip
            overlayStyle={{ maxWidth: 300 }}
            title={
              isRotationDisabled
                ? 'Control Disabled. Parent Equipment OOR.'
                : ''
            }
          >
            <div>
              <ActionsMenuItem
                onClick={() =>
                  actionClickHandler({
                    type: 'showDcDcPCSRotationModal',
                    setShowCommandModal: setShowCommandModal,
                    idsForModal: [id],
                  })
                }
                disabled={isRotationDisabled}
              >
                <Styles.MenuIcon>
                  <Icon
                    size="xxs"
                    color={
                      isRotationDisabled
                        ? COLORS.font_secondary
                        : COLORS.action_blue
                    }
                    icon={<CheckIcon />}
                  />
                </Styles.MenuIcon>
                <Typography
                  $fontColor={isRotationDisabled ? COLORS.font_secondary : null}
                  $bold
                >
                  Set Rotation
                </Typography>
              </ActionsMenuItem>
            </div>
          </Tooltip>
        )}
        <ActionsMenuItem
          onClick={() =>
            actionClickHandler({
              type: 'showClearFaultsModal',
              deviceType: ELECTRICAL_DEVICE_TYPES['DC:DC PCSes'],
              stationCode: stationCode,
              blockIndex: blockIndex,
              faultingDevices: resetableFaults,
              setShowCommandModal: setShowCommandModal,
            })
          }
          disabled={!resetableFaults?.length}
        >
          <Styles.MenuIcon>
            <Icon
              size="xxs"
              color={
                resetableFaults?.length
                  ? COLORS.action_blue
                  : COLORS.font_secondary
              }
              icon={<LightningIcon />}
            />
          </Styles.MenuIcon>
          <div>Clear Faults</div>
        </ActionsMenuItem>
      </Styles.ActionModalSection>
    </div>
  )
}

export default DcDcPCSesTable
