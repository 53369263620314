import { ReactComponent as DcDcConverterIcon } from 'icons/dc-dc-converter-icon.svg'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import RotationStatusIcon from 'components/Status/RotationStatusIcon'

const DcDcGroupDetailSummary = ({ data }) => {
  if (!data) return null

  const summaryMap = {
    title: `DC:DC Group ${data.dcDcGroupIndex}`,
    titleIcon: <DcDcConverterIcon />,

    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: data.isHealthy ? (
              <StatusIcon $isHealthy={data.isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'In Rotation',
            value: <RotationStatusIcon outRotation={data.outRotation} />,
            isIcon: true,
          },
        ],
      },
      {
        sectionTitle: 'DC:DC GROUP BATTERY',
        rows: [
          {
            label: 'Voltage',
            value: data.dcVoltageBatteryVolt.toLocaleString() + ' VDC',
          },
          {
            label: 'Current',
            value: data.dcCurrentBatteryAmp.toLocaleString() + ' A',
          },
          {
            label: 'Power',
            value: data.dcPowerBatteryKW.toLocaleString() + ' kW',
          },
        ],
      },
      {
        sectionTitle: 'DC:DC GROUP PV',
        rows: [
          {
            label: 'Voltage',
            value: data.dcVoltageBusVolt.toLocaleString() + ' VDC',
          },
          {
            label: 'Current',
            value: data.dcCurrentBusAmp.toLocaleString() + ' A',
          },
          {
            label: 'Power',
            value: data.dcPowerBusKW.toLocaleString() + ' kW',
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default DcDcGroupDetailSummary
