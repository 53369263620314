import styled from 'styled-components/macro'

import { COLORS } from 'design_system/colors'

export const ItemWrapper = styled.div`
  font-size: 12px;
  color: ${COLORS.column_header_font_color};
`

export const ActionContainer = styled.div`
  display: flex;
`

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  &:not(:first-child) {
    margin-left: 5px;
    border-left: 2px solid ${COLORS.light_grey};
    padding-left: 10px;
  }
`

export const OptionWrapper = styled.div`
  cursor: pointer;
  padding: 3px 0;
`
