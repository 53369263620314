import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 15px;
  margin-left: 40px;
`

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 50%;
`

export const TableContainer = styled.div``
