import { useState } from 'react'
import { FONT_STYLES } from 'design_system/font_definitions'
import {
  FormRow,
  FormWarningLabel,
  FormWarningRow,
} from 'components/Form/FormComponents.styled'
import {
  SelectWithBottomPadding,
  StringContainer,
} from '../ContactorsFormUi.styles'
import { BALANCE_STATUSES } from './BALANCING_STATUSES'
import { BALANCE_LEVELS } from './BALANCING_LEVELS'
import useBmsParamsQuery from 'api/useQueryHooks/useBmsParamsQuery'
import usePlugInsQuery from 'api/useQueryHooks/usePlugInsQuery'
import { testForNegativeOrEmpty, isFormValid } from './balancingUtils'
import styled from 'styled-components/macro'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useStringStatusQuery from 'api/useQueryHooks/useStringStatusQuery'
import { useSiteControllerVersionContext } from 'contexts/siteControllerVersionContext'
import { useParams } from 'react-router'
import { COLORS } from 'design_system/colors'

export const BalancingForm = ({
  ids,
  onUserInputFormChange, // sends updated form to parent
  userInputForm,
  label,
  originator,
}) => {
  // opModel2 Station check
  const params = useParams()
  const { isOpModel2 } = useSiteControllerVersionContext() // tortuga station?
  const isOpModel2Station = isOpModel2(params.stationCode)
  const isOpModel2StationSingleString =
    originator === BALANCE_LEVELS.STRING &&
    isOpModel2Station &&
    ids?.length === 1

  const [dirtyForm, setDirtyForm] = useState(false)
  const [improperWarning, setImproperWarning] = useState(false)

  const isOp2WarningAppropriate =
    originator === BALANCE_LEVELS.STRING && isOpModel2Station

  // opModel2/Tortuga only code //////////////////
  const { data: stringsData, isLoading } = useStringStatusQuery(
    isOp2WarningAppropriate,
  ) // needed for bp index list opModel2

  const arraysFromIds = Array.from(
    new Set(ids.map((id) => (isNaN(id) ? id.split(':')[0] : id))),
  )
  const opModel2BmsParamsQueries = useBmsParamsQuery(
    arraysFromIds,
    isOp2WarningAppropriate,
  )

  const { data: pluginResponse } = usePlugInsQuery(isOp2WarningAppropriate) // only call for tortuga

  if (isOp2WarningAppropriate && isLoading) return <LoadingPage />

  let packIndexList, bpBalancingEnabled
  const filteredStringsData = []

  // opModel2/Tortuga only code //////////////////
  if (isOp2WarningAppropriate) {
    bpBalancingEnabled =
      pluginResponse?.pluginInfoList?.batterypacklevelbalancer?.enabled

    if (isOpModel2StationSingleString) {
      const ans = getPackIndexListAndFilteredStrings(
        isOpModel2StationSingleString,
        ids,
        stringsData,
      )
      packIndexList = ans.packIndexList
      filteredStringsData.push(ans.filteredStringsData)
    } else {
      if (stringsData.strings[0] !== undefined) {
        for (let id = 0; id < ids.length; id++) {
          const element = ids[id]
          // eslint-disable-next-line prettier/prettier
          const [arrayIndex, stringIndex] = element.split(':').map(Number)
          const filteredString = stringsData.strings.filter(
            (row) =>
              getArrayIndex(row) === arrayIndex &&
              getStringIndex(row) === stringIndex,
          )[0]
          filteredStringsData.push(filteredString)
        }
      }
    }
  }
  /////////////////////////////////////////

  const handleUserInputFormChange = (name, e) => {
    if (e?.target) {
      const _updatedUserInputForm = { ...userInputForm, [name]: e.target.value }
      if (
        isOp2WarningAppropriate &&
        _updatedUserInputForm.chargeDeadband &&
        _updatedUserInputForm.dischargeDeadband
      ) {
        // opModel2 only show when balancing is not recommended string in good shape
        if (
          (_updatedUserInputForm.balancingCmd ==
            BALANCE_STATUSES.BALANCE_TO_PROVIDED &&
            _updatedUserInputForm.providedMillivolts) ||
          _updatedUserInputForm.balancingCmd ==
            BALANCE_STATUSES.BALANCE_TO_AVERAGE
        ) {
          setImproperWarning(
            isOpModel2StringShowWarning(
              opModel2BmsParamsQueries,
              filteredStringsData,
              _updatedUserInputForm,
              arraysFromIds,
            ),
          )
        } else {
          setImproperWarning(false)
        }
      }

      onUserInputFormChange(
        _updatedUserInputForm,
        isFormValid(_updatedUserInputForm),
      )
      if (!e.target.innerText.includes('Choose')) {
        setDirtyForm(true)
      }
    }
  }

  return (
    <form key={'stringBalForm'}>
      {improperWarning && <ImproperMsg />}

      <StringContainer>
        <label>{label}</label>{' '}
        <span>{ids?.join(', ').replace(/:/g, ' :: ')}</span>
      </StringContainer>

      {originator === BALANCE_LEVELS.STRING && bpBalancingEnabled && (
        <FormRow>
          <label>BALANCE LEVEL</label>
          {ids?.length > 1 ? <span>String</span> : null}
        </FormRow>
      )}

      {originator === BALANCE_LEVELS.STRING &&
        ids?.length === 1 &&
        bpBalancingEnabled && (
          <SelectWithBottomPadding
            key={'balanceLevel' + ids}
            value={userInputForm.balanceLevel}
            onChange={(e) => handleUserInputFormChange('balanceLevel', e)}
          >
            <option value="choose" disabled>
              Choose
            </option>
            <option value={BALANCE_LEVELS.STRING}>String</option>
            <option value={BALANCE_LEVELS.PACK}>Battery Pack</option>
          </SelectWithBottomPadding>
        )}

      {originator === BALANCE_LEVELS.STRING &&
        userInputForm.balanceLevel === BALANCE_LEVELS.PACK && (
          <>
            <FormRow>
              <label>BATTERY PACK</label>
            </FormRow>

            <SelectWithBottomPadding
              key={'bp' + ids}
              value={userInputForm.batteryPack}
              onChange={(e) => handleUserInputFormChange('batteryPack', e)}
            >
              <option value="0" disabled>
                Choose
              </option>
              {packIndexList?.map((index) => (
                <option key={`BP${index}`} value={index}>
                  {index}
                </option>
              ))}
            </SelectWithBottomPadding>
          </>
        )}

      <FormRow>
        <label>BALANCING COMMAND</label>
      </FormRow>

      <SelectWithBottomPadding
        key={'cmd' + ids}
        value={userInputForm.balancingCmd}
        onChange={(e) => handleUserInputFormChange('balancingCmd', e)}
      >
        <option value="choose" disabled>
          Choose
        </option>
        <option value={BALANCE_STATUSES.NO_BALANCE}>Stop Balancing</option>
        <option value={BALANCE_STATUSES.BALANCE_TO_AVERAGE}>
          Balance to Average
        </option>
        <option value={BALANCE_STATUSES.BALANCE_TO_PROVIDED}>
          Balance to Millivolts
        </option>
      </SelectWithBottomPadding>

      {userInputForm.balancingCmd === BALANCE_STATUSES.BALANCE_TO_PROVIDED && (
        <>
          <FormRow>
            <label>TARGET MILLIVOLTS</label>
          </FormRow>

          <FONT_STYLES.ActionModalInput
            key={'millivolts' + ids}
            type={'number'}
            min="1"
            value={userInputForm.providedMillivolts}
            onChange={(e) => handleUserInputFormChange('providedMillivolts', e)}
          ></FONT_STYLES.ActionModalInput>

          {dirtyForm && (
            <InputValidation
              val={userInputForm.providedMillivolts}
              inputType={'target'}
            />
          )}
        </>
      )}

      {(userInputForm.balancingCmd === BALANCE_STATUSES.BALANCE_TO_AVERAGE ||
        userInputForm.balancingCmd ===
          BALANCE_STATUSES.BALANCE_TO_PROVIDED) && (
        <>
          <RowX>
            <ColumnX>
              <>
                <FormRow>
                  <label>CHARGE DEADBAND</label>
                </FormRow>
                <FONT_STYLES.ActionModalInput
                  key={'chargeDeadband' + ids}
                  type={'number'}
                  min="1"
                  value={userInputForm.chargeDeadband}
                  onChange={(e) =>
                    handleUserInputFormChange('chargeDeadband', e)
                  }
                ></FONT_STYLES.ActionModalInput>

                {dirtyForm && (
                  <InputValidation
                    val={userInputForm.chargeDeadband}
                    inputType={'deadband'}
                  />
                )}
              </>
            </ColumnX>

            <ColumnX>
              <FormRow>
                <label>DISCHARGE DEADBAND</label>
              </FormRow>
              <FONT_STYLES.ActionModalInput
                key={'discharge' + ids}
                type={'number'}
                min="1"
                // width="50%"
                value={userInputForm.dischargeDeadband}
                // onClick={() => onClickHandler('dischargeDeadband')}
                onChange={(e) =>
                  handleUserInputFormChange('dischargeDeadband', e)
                }
              ></FONT_STYLES.ActionModalInput>
              {dirtyForm && (
                <InputValidation
                  val={userInputForm.dischargeDeadband}
                  inputType={'deadband'}
                />
              )}
            </ColumnX>
          </RowX>

          {isOpModel2Station && (
            <>
              <FormRow>
                <label>TIMEOUT IN MINUTES</label>
              </FormRow>

              <FONT_STYLES.ActionModalInput
                key={'timeout' + ids}
                type={'number'}
                min="1"
                value={userInputForm.timeoutMinutes}
                onChange={(e) => handleUserInputFormChange('timeoutMinutes', e)}
              ></FONT_STYLES.ActionModalInput>
            </>
          )}
        </>
      )}
    </form>
  )
}

// show warning if already in a good stater, only for strings not array
const isOpModel2StringShowWarning = (
  opModel2BmsParamsQueries,
  _filteredStringsData,
  userInputForm,
  arraysFromIds,
) => {
  if (!opModel2BmsParamsQueries || !userInputForm?.balancingCmd) return null

  const _dischargeDeadband = +userInputForm.dischargeDeadband ?? 0
  const _chargeDeadband = +userInputForm.chargeDeadband ?? 0
  for (let index = 0; index < _filteredStringsData.length; index++) {
    const testString = _filteredStringsData[index]
    const arrayIndex = testString.arrayIndex
    const bmsPosition = arraysFromIds.indexOf(arrayIndex.toString())
    let ans = false
    if (userInputForm.balancingCmd === 'Average Balancing') {
      const xCharge = Number(testString.cellAvgVoltage) - _chargeDeadband
      const yDischarge = Number(testString.cellAvgVoltage) + _dischargeDeadband
      const avgStep1 = Number(testString.cellMinVoltage) < xCharge // true show warning
      const avgStep2 =
        Number(testString.cellMinVoltage) >
        opModel2BmsParamsQueries[bmsPosition].data.chargeBalancerMaxVoltage
      const avgStep3 = Number(testString.cellMaxVoltage) > yDischarge
      const avgStep4 =
        Number(testString.cellMaxVoltage) <
        opModel2BmsParamsQueries[bmsPosition].data.dischargeBalancerMinVoltage
      ans = (avgStep1 && avgStep2) || (avgStep3 && avgStep4)
    } else if (userInputForm.balancingCmd === 'Balance to Millivolts') {
      const tCharge = Number(userInputForm.providedMillivolts) - _chargeDeadband
      const tDischarge =
        Number(userInputForm.providedMillivolts) + _dischargeDeadband
      const targetStep1 = Number(testString.cellMinVoltage) < tCharge
      const targetStep2 =
        Number(testString.cellMinVoltage) >
        opModel2BmsParamsQueries[bmsPosition].data.chargeBalancerMaxVoltage
      const targetStep3 = Number(testString.cellMaxVoltage) > tDischarge
      const targetStep4 =
        Number(testString.cellMaxVoltage) <
        opModel2BmsParamsQueries[bmsPosition].data.dischargeBalancerMinVoltage
      ans = (targetStep1 && targetStep2) || (targetStep3 && targetStep4)
    }
    if (ans === true) return true
  }
  return false
}

// ES details endpoint uses stringId/arrayId If that changes, these can go.
const getArrayIndex = (row) => (row.arrayIndex ? row.arrayIndex : row.arrayId)
const getStringIndex = (row) =>
  row.stringIndex ? row.stringIndex : row.stringId

// If opModel2 and a single string command, populate the Battery Pack Index list
// BPs will always be sequential, 1 indexed
const getPackIndexListAndFilteredStrings = (
  isOpModel2Station,
  ids,
  stringsData,
) => {
  if (!isOpModel2Station || ids.length != 1 || !stringsData) {
    return null
  }

  let filteredStringsData
  // eslint-disable-next-line prettier/prettier
  const [arrayIndex, stringIndex] = ids[0].split(':').map(Number)

  if (stringsData.strings[0] !== undefined) {
    //The data is an array
    filteredStringsData = stringsData.strings.filter(
      (row) =>
        getArrayIndex(row) === arrayIndex &&
        getStringIndex(row) === stringIndex,
    )[0]
  }
  const packIndexList = []

  const balancingCount = filteredStringsData?.balancingCount
    ? filteredStringsData.balancingCount
    : filteredStringsData.cBalancingCount
  let numPacks = Object.values(balancingCount).reduce(
    (acc, val) => acc + val,
    0,
  )
  for (let i = 1; i <= numPacks; i++) packIndexList.push(i)

  return { packIndexList, filteredStringsData }
}

export const defaultBalanceFormValues = {
  balancingCmd: 'choose',
  balanceLevel: 'choose',
  batteryPack: '0',
  chargeDeadband: '',
  dischargeDeadband: '',
  providedMillivolts: '',
  timeoutMinutes: '60',
}

const RowX = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 300px;
  gap: 8px;
`

const ColumnX = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex: 1;
`

const ImproperMsg = () => (
  <FormWarningRow>
    <FormWarningLabel>
      WARNING: BALANCING TO AN UNRECOMMENDED ZONE
    </FormWarningLabel>
  </FormWarningRow>
)

const InputWarningMsg = (
  msg = 'Please choose a positive integer',
  color = COLORS.powin_red,
) => <p style={{ color, marginTop: '-15px', fontSize: '0.8em' }}>{msg}</p>

const outOfBoundsDeadband = (val) => Number(val) > 500
const incorrectDeadbandDisplay = (val) => Number(val) > 255
const outOfBoundsTarget = (val) => Number(val) > 4000

const InputValidation = ({ val, inputType }) => {
  if (!val) return null
  if (inputType == 'target' && outOfBoundsTarget(val)) {
    return InputWarningMsg('Must be < 4000')
  }
  if (inputType == 'deadband' && outOfBoundsDeadband(val)) {
    return InputWarningMsg('Must be < 500')
  }
  if (inputType == 'deadband' && incorrectDeadbandDisplay(val)) {
    return InputWarningMsg(
      'Deadband will incorrectly display 255. Will function properly',
      COLORS.warning_amber,
    )
  }
  if (testForNegativeOrEmpty(val)) {
    return InputWarningMsg()
  }
  return null
}
