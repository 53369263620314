import React from 'react'

// Style Imports
import * as Styles from './styles'

// Icon imports
import { ReactComponent as CloseIcon } from '../../../icons/close-icon.svg'

const BottomDrawer = ({ isOpen, handleClose, title, children }) => {
  return (
    <Styles.Container $isOpen={isOpen}>
      <Styles.Background onClick={handleClose} $isOpen={isOpen} />

      <Styles.Drawer $isOpen={isOpen}>
        <Styles.TitleRow>
          <Styles.Title>{title}</Styles.Title>
          <Styles.CloseButton onClick={handleClose}>
            <CloseIcon />
          </Styles.CloseButton>
        </Styles.TitleRow>
        <Styles.Content>{children}</Styles.Content>
      </Styles.Drawer>
    </Styles.Container>
  )
}

export default BottomDrawer
