import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'
import Card from 'components/Cards/Card/Card'

import usePCSDetailsGraphQuery from 'api/useQueryHooks/usePCSDetailsGraphQuery'
import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import { RangePowerChart } from 'modules/Station/components/Charts/RangePowerChart/RangePowerChart'
import { COLORS } from 'design_system/colors'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
`

const PCSDetailsMainColumn = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { data, refetch, isLoading } = usePCSDetailsGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  if (isLoading) return <LoadingPage />

  return (
    <Card
      title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
    >
      <GraphsWrapper>
        <RangePowerChart
          color={COLORS.charts.blue}
          title="Real Power"
          tooltip="Power"
          unit="kW"
          data={data.acRealPowerKW.series}
          period={graphPeriod}
          showXAxis={false}
        />
        <RangePowerChart
          color={COLORS.charts.orange}
          title="Reactive Power"
          tooltip="Power"
          unit="kW"
          data={data.acReactivePowerKVAR.series}
          period={graphPeriod}
        />
      </GraphsWrapper>
    </Card>
  )
}

export default PCSDetailsMainColumn
