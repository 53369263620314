import { toast } from 'react-toastify'

// Component Imports
import Icon from 'components/Icon/Icon'

// Style Imports
import * as Styles from './ToastMessages.styles'

//Icon Imports
import { ReactComponent as LoadingIcon } from 'icons/loading-icon.svg'
import { ReactComponent as CircleCheck } from 'icons/check-circle-icon.svg'
import { ReactComponent as AlertIcon } from 'icons/alert-icon.svg'

//Theme Imports
import { COLORS } from 'design_system/colors'
// toast styling based on id
import 'components/Toasts/ToastByIdStyles.css'

const inProgressToastOptions = {
  position: 'bottom-left',
  autoClose: false,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  toastId: 'toastInfo',
  icon: <Icon size="lg" color="white" icon={<LoadingIcon />} />,
  style: {
    width: 500,
    border: `2px solid ${COLORS.action_blue}`,
  },
}

const failedToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  toastId: 'toastFailed',
  icon: <Icon size="sm" color="white" icon={<AlertIcon />} />,
  style: {
    width: 500,
    border: `2px solid ${COLORS.powin_red}`,
  },
}

const completeToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  toastId: 'toastComplete',
  icon: <Icon size="sm" color="white" icon={<CircleCheck />} />,
  style: {
    width: 500,
    border: `2px solid ${COLORS.powin_green}`,
  },
}

/**
 *
 * @param {string} title toast title text
 * @param {string} body totast body text
 * @param {'progress'|'complete'|'fail'} type can be 'progress' 'done' 'fail'
 * @param {boolean} update or return a new toast
 * @returns
 */
export const ToastMessage = (title, body, type, updateId) => {
  updateId = typeof updateId !== 'string' ? null : updateId
  const ToastContent = (
    <Styles.InfoMessageDiv>
      <Styles.MessageTitle>{title}</Styles.MessageTitle>
      {body}
    </Styles.InfoMessageDiv>
  )

  switch (type) {
    case 'progress':
      return updateId
        ? toast.update(updateId, {
            render: ToastContent,
            ...inProgressToastOptions,
          })
        : toast(ToastContent, inProgressToastOptions)

    case 'complete':
      return updateId
        ? toast.update(updateId, {
            render: ToastContent,
            ...completeToastOptions,
          })
        : toast(ToastContent, completeToastOptions)

    case 'fail':
      return updateId
        ? toast.update(updateId, {
            render: ToastContent,
            ...failedToastOptions,
          })
        : toast(ToastContent, failedToastOptions)

    default:
      break
  }
}
