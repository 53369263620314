import { useEffect, useState } from 'react'

import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import * as Styles from './PvPcsMainColumn.styles'
import Card from 'components/Cards/Card/Card'
import usePvPcsDetailGraphQuery from 'api/useQueryHooks/usePvPcsDetailGraphQuery'
import { RangePowerChart } from 'modules/Station/components/Charts/RangePowerChart/RangePowerChart'
import { COLORS } from 'design_system/colors'

export const PvPcsMainColumn = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { isLoading, data, refetch } = usePvPcsDetailGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  return (
    <Card
      title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
    >
      {!isLoading && (
        <Styles.GraphsWrapper>
          <RangePowerChart
            color={COLORS.charts.blue}
            title="AC Actual Real Power"
            tooltip="Power"
            unit="kW"
            data={data?.acActualRealPowerKW.series}
            period={graphPeriod}
            showXAxis={false}
          />
          <RangePowerChart
            color={COLORS.charts.orange}
            title="PV Actual Real Power"
            tooltip="Power"
            unit="kW"
            data={data?.pvActualRealPowerKW.series}
            period={graphPeriod}
            showXAxis={false}
          />
          <RangePowerChart
            color={COLORS.charts.green}
            title="AC Actual Reactive Power"
            tooltip="Power"
            unit="kVAr"
            data={data?.acActualReactivePowerVAR.series}
            period={graphPeriod}
          />
        </Styles.GraphsWrapper>
      )}
    </Card>
  )
}
