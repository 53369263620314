import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from '../../../components/Layouts/ListPage/ListPage'
import ArrayPcsListSummary from './Summary/ArrayPcsListSummary'
import PCSTable from '../../../components/Tables/PCSTable/PCSTable'
import useElectricalDevicesQuery from 'api/useQueryHooks/useElectricalDevicesQuery'
import usePCSList from 'api/useQueryHooks/usePCSList'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const ArrayPcsList = ({
  // data: blockAndArrayPcsData,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  const { data: pcsData, isLoading: isLoadingPcsData } = usePCSList()
  const { data: electricalDevicesData, isLoading: isLoadingElectricalDevices } =
    useElectricalDevicesQuery({
      enabled: true,
    })

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'array-pcs-list' })
    document.title = `${stationCode}::${blockIndex} - Array PCSes`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useMemo(() => {
    if (!pcsData) return []

    return pcsData.arrayPcses.map((pcs) => ({
      ...pcs,
      faults:
        electricalDevicesData?.pcses?.faulting?.filter?.(
          (device) =>
            device.arrayIndex === pcs.arrayIndex &&
            device.arrayPcsIndex === pcs.arrayPcsIndex,
        ) ?? [],
    }))
  }, [electricalDevicesData, pcsData])

  if (isLoadingPcsData || isLoadingElectricalDevices) return <LoadingPage />

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<ArrayPcsListSummary data={data} />}
      tableComponent={<PCSTable data={data} fixedHeader={true} />}
    />
  )
}

export default ArrayPcsList
