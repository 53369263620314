import {
  ContentWrapper,
  DeviceList,
  DeviceListBody,
  DeviceListHeader,
  DeviceListItem,
  DeviceListRow,
} from './styles'

export const ClearSensorFaultsUi = ({ sensorIndex, sensorName }) => {
  return (
    <ContentWrapper>
      <DeviceList>
        <DeviceListHeader>
          <DeviceListRow>
            <DeviceListItem>SENSOR</DeviceListItem>
            <DeviceListItem>STATUS</DeviceListItem>
          </DeviceListRow>
        </DeviceListHeader>
        <DeviceListBody>
          <DeviceListRow key={sensorIndex}>
            <DeviceListItem>{sensorName}</DeviceListItem>
            <DeviceListItem>Ready to Clear</DeviceListItem>
          </DeviceListRow>
        </DeviceListBody>
      </DeviceList>
    </ContentWrapper>
  )
}
