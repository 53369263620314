import HealthStatus from 'components/Status/HealthStatus/HealthStatus'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as DcAcInverterIcon } from 'icons/dc-ac-pcs-icon.svg'
import RotationStatusIcon from 'components/Status/RotationStatusIcon'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

export const PvPcsSummary = ({ data }) => {
  const summaryMap = {
    title: `PV PCS ${data.acPvBatteryIndex}:${data.pvPcsIndex}`,
    titleIcon: <DcAcInverterIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={data.healthy} />,
            isIcon: true,
          },
          {
            label: 'In Rotation',
            value: <RotationStatusIcon outRotation={data.outRotation} />,
            isIcon: true,
          },
        ],
      },
      {
        sectionTitle: 'Current Readings',
        rows: [
          {
            label: 'DC',
            value: `${checkNullRoundValue(
              data.dcVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.dcCurrentAmp, ' A')}`,
          },
          {
            label: 'PV DC',
            value: `${checkNullRoundValue(
              data.pvDcVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.dcCurrentAmp, ' A')}`,
          },
          {
            label: 'AC A > N',
            value: `${checkNullRoundValue(
              data.acPhaseANVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseACurrentAmp, ' A')}`,
          },
          {
            label: 'AC B > N',
            value: `${checkNullRoundValue(
              data.acPhaseBNVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseBCurrentAmp, ' A')}`,
          },
          {
            label: 'AC C > N',
            value: `${checkNullRoundValue(
              data.acPhaseCNVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseCCurrentAmp, ' A')}`,
          },
          {
            label: 'AC Curtailment Limit Command',
            value: data.acCmdRealPowerLimitKW.toLocaleString() + ' kW',
          },
          {
            label: 'AC Curtailment Limit Setting',
            value: data.acRealPowerSettingLimitKW.toLocaleString() + ' kW',
          },
          {
            label: 'AC Command',
            value: data.acCmdReactivePowerKVAR.toLocaleString() + ' kVAr',
          },
          {
            label: 'AC Setting',
            value: data.acReactivePowerSettingKVAR.toLocaleString() + ' kVAr',
          },
          {
            label: 'AC Actual',
            value: `${checkNullRoundValue(
              data.acRealPowerKW,
              ' kW',
            )} / ${checkNullRoundValue(data.acReactivePowerKVAR, ' kVAr')}`,
          },
          {
            label: 'PV Actual',
            value: data.pvRealPowerKW.toLocaleString() + ' kW',
          },
          {
            label: 'Frequency',
            value: checkNullRoundValue(data.acFrequencyHz, ' Hz', 2),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}
