import { isNil, isNumber } from 'lodash'
import * as Styles from 'modules/Station/components/Tables/table.styled'
import AppTooltip from 'components/AppTooltip/AppTooltip'
import TernaryBarChartStrings from 'components/Charts/TernaryBarChart/TernaryBarChartStrings'
import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { ReactComponent as AlertCircleIcon } from 'icons/alert-circle-icon.svg'
import { ReactComponent as AlertIcon } from 'icons/alert-icon.svg'
import { ReactComponent as CentipedeLineupIcon } from 'icons/centipede-lineup.svg'
import { ReactComponent as GasSensorIcon } from 'icons/gas-icon.svg'
import { ReactComponent as HeatIcon } from 'icons/heat-icon.svg'
import { ReactComponent as DoorIcon } from 'icons/door-sensor-icon.svg'
import { ReactComponent as EnvControllerIcon } from 'icons/env-controller.svg'
import { ReactComponent as StringManagersIcon } from 'icons/string-manager.svg'
import { ReactComponent as HVACIcon } from 'icons/hvac-icon.svg'
import { ReactComponent as UPSsIcon } from 'icons/ups-icon.svg'
import { ReactComponent as MoistureIcon } from 'icons/moisture-icon.svg'
import { ReactComponent as EnergySegmentsIcon } from 'icons/centipede-energy-segment.svg'
import { ReactComponent as CollectionSegmentsIcon } from 'icons/centipede-collection-segment.svg'
import { ReactComponent as EnclosuresIcon } from 'icons/enclosure-icon.svg'
import { ReactComponent as StatusDisconnectedIcon } from 'icons/status-disconnected-icon.svg'
import { ReactComponent as ReadyToClearIcon } from 'icons/reload-readytoclear-icon.svg'
import BinaryBarChartTotalVsUsed from 'components/Charts/BinaryBarChart/BinaryBarChartTotalVsUsed'
import PercentBarChart from 'components/Charts/PercentBarChart/PercentBarChart'
import useBlockStatusByStationAndBlock from 'api/useQueryHooks/useBlockStatusByStationAndBlock'
import { BALANCE_STATUSES } from '../Commands/Balancing/BALANCING_STATUSES'
import { CommunicatingStatus } from 'components/Status/CommunicationStatus/CommunicationStatus'
import { formatNumber } from 'utils/formatting'
import { FONT_WEIGHT } from 'design_system/typography'
import Tooltip from 'components/Tooltip'
import { nanoid } from 'nanoid'
import { forwardRef } from 'react'

/**
 * example usage in a table column cell: row => HealthStatusIconWithToolTips(row.isHealthy)
 * @param {boolean} isHealthy
 * @returns (un)healthy icon
 */
export const HealthStatusIconWithToolTips = (isHealthy) => {
  const _id = nanoid('health')

  return (
    <Styles.StatusIcon $isHealthy={isHealthy} data-tooltip-id={_id}>
      {isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
      <AppTooltip id={_id}>{isHealthy ? 'Healthy' : 'Unhealthy'}</AppTooltip>
    </Styles.StatusIcon>
  )
}

/**
 * example usage in a table column cell: row => HealthStatusIconWithMoreInfoToolTips(valid, enabled, ready, offlineStack, nearlineStack)
 * similar function to HealthStatusIconWithToolTips but different information on ToolTip
 * @param {boolean} valid
 * @param {boolean} enabled
 * @param {boolean} ready
 * @param {boolean} offlineStack
 * @param {boolean} nearlineStack
 * @param {boolean} nearlineStack
 * @param {string} key
 * @returns (un)healthy icon
 */
export const HealthStatusIconWithMoreInfoToolTips = (
  valid,
  enabled,
  ready,
  offlineStack,
  nearlineStack,
  key,
) => {
  const isReady =
    valid && enabled && ready && offlineStack == 0 && nearlineStack == 0

  return (
    <Styles.StatusIcon
      $isHealthy={isReady}
      data-tooltip-id={key + 'ACBatteryStatus'}
    >
      {isReady ? <HealthGoodIcon /> : <HealthAlertIcon />}
      <AppTooltip id={key + 'ACBatteryStatus'}>
        {valid ? 'Connected' : 'Disconnected'} |{' '}
        {enabled ? 'Enabled' : 'Disabled'} | {ready ? 'Ready' : 'Not Ready'}{' '}
        {offlineStack == 0 ? '' : '| Offline: ' + offlineStack}{' '}
        {nearlineStack == 0 ? '' : '| Nearline: ' + nearlineStack}
      </AppTooltip>
    </Styles.StatusIcon>
  )
}

/**
 * usage in table rows
 * @param {boolean} outRotation
 * @returns rotationIcon
 */
export const RowRotationWithTooltip = (outRotation) => {
  const _uniqueId = nanoid('rotation')
  if (outRotation) {
    return (
      <Styles.RotationIcon $inRotation={false} data-tooltip-id={_uniqueId}>
        <ErrorIcon />
        <AppTooltip id={_uniqueId}>Out of Rotation</AppTooltip>
      </Styles.RotationIcon>
    )
  } else {
    return (
      <Styles.RotationIcon $inRotation data-tooltip-id={_uniqueId}>
        <CheckIcon />
        <AppTooltip id={_uniqueId}>In Rotation</AppTooltip>
      </Styles.RotationIcon>
    )
  }
}

// backward compatible may be able to remove keys with 'stackCount'
const rowToStringsObject = (stringCounts, isNonCommunicatingAdded = false) => {
  const online = stringCounts.onlineStackCount ?? stringCounts.online
  const offline = stringCounts.offlineStackCount ?? stringCounts.offline
  const nearline = stringCounts.nearlineStackCount ?? stringCounts.nearline
  const nonCommunicating =
    stringCounts.nonCommunicatingStackCount ?? stringCounts.notCommunicating
  const total = online + offline + nearline + nonCommunicating
  const strings = {
    online,
    offline,
    nearline,
    total,
  }
  if (isNonCommunicatingAdded) strings.nonCommunicating = nonCommunicating
  return strings
}

/**
 * 3 bar charts for Strings
 * @param {{onlineStackCount,offlineStackCount,nearlineStackCount, nonCommunicatingStackCount}} stringCounts
 * @returns TernaryBarChart (online, nearline, offline)
 */
export const StringsToTernaryBarChartRow = (stringCounts) => {
  if (!stringCounts) return 'N/A'
  const strings = rowToStringsObject(stringCounts)
  return (
    <div style={{ width: 80 }}>
      <TernaryBarChartStrings data={strings} />
    </div>
  )
}

/**
 * 4 bar charts for Strings, varies from StringsToTernaryBarChartRow adds 'nonCommunicating'
 * @param {{onlineStackCount,offlineStackCount,nearlineStackCount, nonCommunicatingStackCount}} stringCounts
 * @returns TernaryBarChart (online, nearline, offline, nonCommunicating)
 */
export const StringCountsTo4BarChartRow = (stringCounts) => {
  if (!stringCounts) return 'N/A'
  const strings = rowToStringsObject(stringCounts, true)
  return (
    <div style={{ width: 120 }}>
      <TernaryBarChartStrings data={strings} />
    </div>
  )
}

/**
 * this method will sum all matching keys and round them and add a suffix
 * @param {Array} data
 * @param {string} key
 * @param {string} measure ie suffix ' kW' added to end of sum (optional)
 * @param {boolean} commas defaults to true, adds commas to the number (optional)
 * @returns
 */
export const numSumRounder = (data, key, measure = '', commas = true) => {
  const sum = data.reduce(
    (prev, next) => (isNumber(next[key]) ? next[key] + prev : prev),
    0,
  )
  if (commas === true) {
    return `${Math.round(sum).toLocaleString()} ${measure}`
  } else {
    return `${Math.round(sum)} ${measure}`
  }
}

/**
 *
 * @param {number} total total collection size
 * @param {number} pos positive value
 * @param {string} toolTip defaults to `Total: ${total} Untripped: ${pos}`
 *
 * @returns binary bar chart jsx
 */
export const getBinaryBarChartTotalVsUsed = (
  total,
  pos,
  toolTip = `Total: ${total} | Untripped: ${pos}`,
) => {
  return (
    <BinaryBarChartTotalVsUsed
      positiveCount={pos}
      totalCount={total}
      tooltip={toolTip}
    />
  )
}

const RedAlertCircleIconAndText = ({ val }) => (
  <div
    style={{
      color: COLORS.powin_red,
      display: 'flex',
      gap: '3px',
      alignItems: 'center',
    }}
  >
    {val}
    <Icon icon={<AlertCircleIcon />} size="xxs" color={COLORS.powin_red} />
  </div>
)

/**
 * for tables where there is a corresponding health (string "INFO" or "WARNING") to the value, used in EnvironmentalControllersTable and StackMaganersListTable
 * @param {string} health
 * @param {any} val
 * @returns
 */
export const ShowHealthyOrUnhealthyTableValue = (health, val) =>
  health === 'INFO' ? val : <RedAlertCircleIconAndText val={val} />

/**
 * for tables where there is a memory value being received in bytes (treatment goes from bytes up to GB)
 * @param {number} val
 * @returns
 */
export const TreatMemoryValue = (val) => {
  if (val >= 10 ** 9) {
    return (val / 10 ** 9).toFixed(2) + ' GB'
  }
  if (val >= 10 ** 6) {
    return (val / 10 ** 6).toFixed(2) + ' MB'
  }
  if (val >= 10 ** 3) {
    return (val / 10 ** 3).toFixed(2) + ' KB'
  } else return val + ' bytes'
}

const FaultDetected = () => (
  <div
    style={{
      color: COLORS.powin_red,
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontWeight: FONT_WEIGHT.strong,
    }}
  >
    <Icon icon={<AlertIcon />} size="xxs" color={COLORS.powin_red} />
    Fault Detected
  </div>
)
const NoFaultDetected = () => (
  <div
    style={{
      color: COLORS.powin_green,
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontWeight: FONT_WEIGHT.strong,
    }}
  >
    <Icon icon={<CheckIcon />} size="xxs" color={COLORS.powin_green} />
    No Faults
  </div>
)

/**
 * used in UPSes List page for faults present column
 * @param {boolean} isFaultDetected
 * @returns
 */
export const FaultsPresentTableRow = (isFaultDetected) =>
  isFaultDetected ? <FaultDetected /> : <NoFaultDetected />

/**
 * renders a table cell with percent bar graph
 * @param {number} soc
 * @returns
 */
export const CellSOC = (soc) => (
  <Styles.SocCell>
    <PercentBarChart percent={soc} rowKey={``} />
  </Styles.SocCell>
)

// ***************************** Balancing tools ********************************
/**
 * renders a string with formatted balancing counts
 * @param {object} counts
 * @returns string
 */
export const BalanceCount = (counts) =>
  `${formatNumber({ value: counts.charge })}↑ ${formatNumber({
    value: counts.discharge,
  })}↓ ${formatNumber({ value: counts.not })}- ${formatNumber({
    value: counts.unknown,
  })}?`

export const getBalanceMode = (balanceMode) =>
  BALANCE_STATUSES[balanceMode] || 'No balancing'

export const getBalanceDisplayStatus = (status) =>
  BALANCE_STATUSES[status] === `Stop Balancing`
    ? 'Not Balancing'
    : BALANCE_STATUSES[status]

/**
 * renders a string to display the charge or discharge deadband
 * @param {object} row data row
 * @param {boolean} charge true if charge, false if discharge
 * @returns {string}
 */
export const getDeadband = (row, charge) => {
  if (
    (row.balanceMode && row.balanceMode === 'NO_BALANCE') ||
    (row.balancingMode && row.balancingMode === 'NO_BALANCE')
  )
    return '-'
  let db = charge ? row.chargeDeadband : row.dischargeDeadband
  return formatNumber({ value: db, unit: ' mV' })
}

export const getTargetmV = (row) => {
  if (
    (row.balancingMode && row.balancingMode !== 'BALANCE_TO_PROVIDED') ||
    (row.balanceMode && row.balanceMode !== 'BALANCE_TO_PROVIDED')
  )
    return '-'
  return formatNumber({ value: row.targetMillivolts, unit: ' mV' })
}

//*******************************************************************************
// ***************************** Sensor tools ***********************************

export const sensorLostConnectionReason = (topology) => {
  if (!topology.enabled) {
    return 'Not enabled.'
  } else if (!topology.ready) {
    return 'Not ready.'
  } else if (!topology.communicating) {
    return 'Not communicating.'
  }
  return null
}

/**
 * for table rows showing tripped / untripped icons
 * @param {*} sensor a table row representing a sensor
 * @returns null if undefined, check icon if not isTripped and not isLatched else Alert Icon
 */
export const trippedOrLatchedSensorIcon = (sensor, rowId) => {
  let isTripped = sensor?.isTripped
  let isLatched = sensor?.isLatched
  if (isTripped == undefined || isTripped == null) return null
  if (isLatched == undefined || isLatched == null) isLatched = false

  const sensorIsConnected =
    sensor.sensorTopology.communicating &&
    sensor.sensorTopology.ready &&
    sensor.sensorTopology.enabled
  const lostConnectionReason = sensorLostConnectionReason(sensor.sensorTopology)

  if (!isTripped && !isLatched) {
    const _uniqueId = nanoid('untripped')
    return (
      <div data-tooltip-id={_uniqueId}>
        <Icon
          icon={<CheckIcon />}
          color={COLORS.powin_green}
          size="xxs"
          isCrossed={!sensorIsConnected}
        />
        <AppTooltip id={_uniqueId}>
          {sensorIsConnected
            ? `${rowId} Untripped.`
            : `${rowId} ${lostConnectionReason}`}
        </AppTooltip>
      </div>
    )
  } else if (!isTripped && isLatched) {
    const _uniqueId = nanoid('latched')
    return (
      <div data-tooltip-id={_uniqueId}>
        <Icon
          icon={<ReadyToClearIcon />}
          color={COLORS.action_blue}
          size="xxs"
          isCrossed={!sensorIsConnected}
        />
        <AppTooltip id={_uniqueId}>
          {sensorIsConnected
            ? `${rowId} Latched. Requires manual clear`
            : `${rowId} ${lostConnectionReason}`}
        </AppTooltip>
      </div>
    )
  } else {
    const _uniqueId = nanoid('tripped')
    return (
      <div data-tooltip-id={_uniqueId}>
        <Icon
          icon={<AlertCircleIcon />}
          color={COLORS.powin_red}
          size="xxs"
          isCrossed={!sensorIsConnected}
        />
        <AppTooltip id={_uniqueId}>
          {sensorIsConnected
            ? `${rowId} Tripped. Still active.`
            : `${rowId} ${lostConnectionReason}`}
        </AppTooltip>
      </div>
    )
  }
}

/**
 * used in CentipedeLienupDetails tables to return the correct Icons depending on each sensor status
 * @param {object} sensor containing noncommunicating, communicating, tripped and untripped values
 * @returns jsx Icon (Ready to Clear, Check, Alert or StatusDisconnected)
 */
// Since this is for summary, Does not need to check latching, as that has been handled in DataApi
export const getSensorsStatus = (sensor) => {
  const _id = nanoid(sensor)

  if (sensor.total === 0) return null
  if (sensor.readyToClear > 0) {
    return (
      <StatusIcon
        style={{ width: '17px', height: '17px', fill: COLORS.action_blue }}
        data-tooltip-id={_id}
      >
        <ReadyToClearIcon />
        <AppTooltip id={_id}>{'Ready to clear'}</AppTooltip>
      </StatusIcon>
    )
  }
  if (sensor.tripped > 0)
    return (
      <StatusIcon data-tooltip-id={_id}>
        <AlertCircleIcon />
        <AppTooltip id={_id}>{'Tripped'}</AppTooltip>
      </StatusIcon>
    )
  if (sensor.noncommunicating > 0)
    return (
      <>
        <StatusDisconnectedIcon
          style={{ width: '21px', height: '21px', fill: 'red' }}
          data-tooltip-id={_id}
        />
        <AppTooltip id={_id}>{'Non communicating'}</AppTooltip>
      </>
    )
  return (
    <StatusIcon $isHealthy={true} data-tooltip-id={_id}>
      <CheckIcon />
      <AppTooltip id={_id}>{'Untripped'}</AppTooltip>
    </StatusIcon>
  )
}

/**
 * returns a sortable string selector for trippable sensors
 * @param {(number, number, number)} sensor total, untripped, noncommunicating
 * @returns string
 */
// Since this is for summary, Does not need to check latching, as that has been handled in DataApi
export const getTernaryTrippedString = (sensor) => {
  if (!sensor) return 'N/A'
  const { total, untripped, noncommunicating } = sensor
  return `${total}:${untripped}:${noncommunicating}`
}

/**
 *
 * For Energy and Collection Segment Details pages:
 * @param row the data row
 *
 * @returns {StatusIcon} will return an icon and label that signifies status.
 */
export const tableDisplayTrippedStatusWithIcon = (row) => {
  const tripped = row.isTripped
  const latched = row.isLatched
  const sensorIsConnected =
    row.sensorTopology.communicating &&
    row.sensorTopology.ready &&
    row.sensorTopology.enabled

  if (tripped) {
    return (
      <Styles.CenteredGridItem>
        <Icon
          icon={<AlertCircleIcon />}
          size="xxs"
          color={COLORS.powin_red}
          isCrossed={!sensorIsConnected}
          crossedColor={COLORS.powin_red}
        />
        <Styles.TitleValue style={{ color: COLORS.powin_red }}>
          <strong>
            &nbsp;
            {!sensorIsConnected ? <s> Tripped</s> : ' Tripped'}
          </strong>
        </Styles.TitleValue>
      </Styles.CenteredGridItem>
    )
  }
  if (latched) {
    return (
      <Styles.CenteredGridItem>
        <Icon
          icon={<ReadyToClearIcon />}
          size="xxs"
          color={COLORS.action_blue}
          isCrossed={!sensorIsConnected}
        />
        <Styles.TitleValue>
          <strong>
            &nbsp;
            {!sensorIsConnected ? <s> Ready to Clear</s> : ' Ready to Clear'}
          </strong>
        </Styles.TitleValue>
      </Styles.CenteredGridItem>
    )
  }

  return (
    <Styles.CenteredGridItem color={COLORS.powin_green}>
      <Icon
        icon={<CheckIcon />}
        size="xxs"
        color={COLORS.powin_green}
        isCrossed={!sensorIsConnected}
        crossedColor={COLORS.column_header_font_color}
      />
      <Styles.TitleValue style={{ color: COLORS.column_header_font_color }}>
        &nbsp;
        {!sensorIsConnected ? <s> Untripped</s> : ' Untripped'}
      </Styles.TitleValue>
    </Styles.CenteredGridItem>
  )
}

//*******************************************************************************

export const getCommunicationStatus = (status, timestamp) => {
  const isCommunicating = status === 'VALID'
  return (
    <CommunicatingStatus
      isCommunicating={isCommunicating}
      timestamp={timestamp}
      size="25px"
    />
  )
}

const xsIcon = (_Icon) => <Icon icon={_Icon} size="xs" />

export const GetIconFromTypeName = (typeName) => {
  switch (typeName) {
    case 'Enclosures':
      return xsIcon(<EnclosuresIcon />)
    case 'Centipede Lineups':
      return xsIcon(<CentipedeLineupIcon />)
    case 'Collection Segments':
      return xsIcon(<CollectionSegmentsIcon />)
    case 'Energy Segments':
      return xsIcon(<EnergySegmentsIcon />)
    case 'Fire Safety Sensors':
      return xsIcon(<HeatIcon />)
    case 'Gas Sensors':
      return xsIcon(<GasSensorIcon />)
    case 'Door Sensors':
      return xsIcon(<DoorIcon />)
    case 'Environmental Controllers':
      return xsIcon(<EnvControllerIcon />)
    case 'String Managers':
      return xsIcon(<StringManagersIcon />)
    case 'Stack Managers':
      return xsIcon(<StringManagersIcon />)
    case 'HVACs':
      return xsIcon(<HVACIcon />)
    case 'UPSes':
      return xsIcon(<UPSsIcon />)
    case 'Moisture Sensors':
      return xsIcon(<MoistureIcon />)
    default:
      return <></>
  }
}

const blockTopologyBaseLink = (params, finalUrlParam) =>
  `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/${finalUrlParam}`

export const getPageLinkFromTypeNameAndParams = (typeName, params) => {
  switch (typeName) {
    case 'Centipede Lineups':
      return blockTopologyBaseLink(params, 'centipede-lineups')
    case 'Collection Segments':
      return blockTopologyBaseLink(params, 'collection-segments')
    case 'Energy Segments':
      return blockTopologyBaseLink(params, 'centipede-energy-segments')
    case 'Enclosures':
      return blockTopologyBaseLink(params, 'enclosure-list')
    case 'Fire Safety Sensors':
    case 'Hydrogen Sensors':
    case 'Gas Sensors':
    case 'Door Sensors':
    case 'Moisture Sensors':
      return blockTopologyBaseLink(params, 'sensors')
    case 'Environmental Controllers':
      return blockTopologyBaseLink(params, 'environmental-controllers')
    case 'Stack Managers':
      return blockTopologyBaseLink(params, 'stack-managers-list')
    case 'HVACs':
      return blockTopologyBaseLink(params, 'hvac-list')
    case 'UPSes':
      return blockTopologyBaseLink(params, 'ups-list')

    default:
      return `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/`
  }
}

/**
 * returns a sortable string selector from stringCounts
 * @param {(number, number, number, number, number )} stringCounts total, offline, online, nearline, notCommunicating
 * @returns string
 */
export const getSortableStringFromStringCounts = (stringCounts) => {
  if (!stringCounts) return 'N/A'
  const { total, offline, online, nearline, notCommunicating } = stringCounts
  return `${total}:${offline}:${online}:${nearline}:${notCommunicating}`
}

export const useIsStationConnected = (stationCode, blockIndex) => {
  const { data: blockStatus, isLoading } = useBlockStatusByStationAndBlock(
    stationCode,
    blockIndex,
  )
  if (isLoading) return null
  return blockStatus?.valid ?? false
}

/**
 * used in CentipedeLineupDetailsCollectionSegmentTable and
 * CentipedeLineupDetailsEnergySegmentsTable tables to return the correct Icons depending on the sensor status
 * @param {object} sensor hvac
 * @returns Icon (Check or Alert)
 */

export const getHvacStatus = (hvacStatus) => {
  const _id = nanoid(hvacStatus)
  // Equals zero is for hvacNonHealthy and equals true is for isHealthy
  // (info is different depending on the endpoint)
  if (hvacStatus === 0 || hvacStatus === true)
    return (
      <StatusIcon $isHealthy={true} data-tooltip-id={_id}>
        <CheckIcon />
        <AppTooltip id={_id}>{'Healthy'}</AppTooltip>
      </StatusIcon>
    )
  return (
    <StatusIcon data-tooltip-id={_id}>
      <AlertCircleIcon />
      <AppTooltip id={_id}>{'Unhealthy'}</AppTooltip>
    </StatusIcon>
  )
}

/**
 * used in CentipedeEnergySegmentsTable,
 * CentipedeLineupDetailsCollectionSegmentTable,
 * CentipedeLineupDetailsEnergySegmentsTable,
 * CollectionSegmentsTable and HvacListTable
 * to return hvacStage field with it's content justified to the right
 * @param {string} hvacStage
 * @param {string} envControllersNonHealthy // controlls row opacity depending on Env Controllers Health (optional)
 * @returns div with it's content aligned to the right
 */
export const hvacStageStyle = (hvacStage, envControllersNonHealthy) => {
  if (envControllersNonHealthy === 0 || !envControllersNonHealthy)
    return <div>{hvacStage}</div>
  return <div style={{ opacity: '0.4' }}>{hvacStage}</div>
}

/**
 * used in CentipedeLineupDetailsCollectionSegmentTable
 * to turn Upses data into a BinaryBarChart and
 * optionally return it faded
 * @param {number} total
 * @param {number} healthy
 * @param {number} opacity // optional
 * @returns BinaryBarChart
 */
export const upsesToBinaryBarChartRow = (
  total,
  healthy,
  opacity,
  toolTip = `Total: ${total} Healthy: ${healthy}`,
) => {
  return (
    <BinaryBarChartTotalVsUsed
      positiveCount={healthy}
      totalCount={total}
      tooltip={toolTip}
      opacity={opacity}
    />
  )
}

/**
 * used in CentipedeLineupDetailsCollectionSegmentTable
 * and CentipedeLineupDetailsEnergySegmentsTable,
 * to control signalize if data should be fade dependening on Env Controller's status
 * @param {number} envControllersNonHealthy
 * @returns {boolean}
 */
export const getEnvCtrlsStatus = (envControllersNonHealthy) => {
  return envControllersNonHealthy <= 0
}

/**
 * used in CentipedeLineupDetailsCollectionSegmentTable
 * and CentipedeLineupDetailsEnergySegmentsTable,
 * to return a specific data field faded or not
 * @param {number} envControllersNonHealthy
 * @param {any} field
 * @returns {any} //the field of data (faded or not)
 */
export const fieldOpacity = (envControllersNonHealthy, field) => {
  if (getEnvCtrlsStatus(envControllersNonHealthy)) {
    return field
  } else return <div style={{ opacity: '0.4' }}>{field}</div>
}

/**
 * used in CentipedeLineupDetailsEnergySegmentsTable
 * to return a faded Chart depending on the result of
 * getEnvCtrls
 * @param {number} upsTotal
 * @param {number} upsHealthy
 * @param {number} envControllersNonHealthy
 * @returns function that transform the data into a BarChart
 */
export const upsesBarChartOpacity = (
  upsTotal,
  upsHealthy,
  envControllersNonHealthy,
) => {
  let opacity = 0.4
  if (getEnvCtrlsStatus(envControllersNonHealthy)) {
    return upsesToBinaryBarChartRow(upsTotal, upsHealthy)
  } else return upsesToBinaryBarChartRow(upsTotal, upsHealthy, opacity)
}

/**
 * used in CentipedeLineups, CollectionSegments, EnergySegments,
 * and HvacList tables to return N/A if a number
 * is null and the rounded value if it's not.
 * @param {number} value
 * @param {string} measure ie suffix 'º C' added to end of value (optional)
 * @param {number} decimals number of needed decimal points (optional)
 * @returns N/A if null and rounded number if not
 */
export const checkNullRoundValue = (value, measure = '', decimals = null) => {
  if (value != null && decimals != null)
    return `${value.toFixed(decimals).toLocaleString()}${measure}`
  if (value != null) return `${Math.round(value).toLocaleString()}${measure}`
  return 'N/A'
}

/**
 * example output from a timestamp
 * July 31, 2023 at 2:10:10 PM
 * @param {sensorIsCommunicating} optional // necessary to return values with strikethrough in case of sensor not communicating
 */
export const timestampToMYDTLocal = (timestamp, sensorIsCommunicating) => {
  const newDate = new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  })

  if (sensorIsCommunicating === false) {
    return (
      <div style={{ color: COLORS.column_header_font_color }}>
        &nbsp;
        {sensorIsCommunicating === false ? <s>{newDate}</s> : { newDate }}
      </div>
    )
  }

  return <strong>{newDate}</strong>
}

/**
 * This function will correctly sort ids with colons
 * [1:1, 1:11, 1:2] -> correctly sorted to [1:1, 1:2, 1:11]
 * works for mult : like 1:1:1, all strings must be the same size
 * @param {string} a
 * @param {string} b
 * @returns sort function ie.  x.sort(sortIdsWithColon)
 *
 * implementation example {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      sortFunction: (a, b) =>
        sortIdsWithColonRecursive(getRowId(a), getRowId(b)),
 */
export const sortIdsWithColonRecursive = (a, b) => {
  const arr1 = a.split(':')
  const arr2 = b.split(':')
  return sortMultipleValues(arr1, arr2)
}

/**
 * used in BlockMeterDetailsSummary and BlockMeterTable
 * to return a friendly name for the correspondent
 * Block Meter role
 * @param {string} role
 * @returns friendly name for the desired role
 */
export const getRoleFriendlyName = (role) => {
  switch (role) {
    case 'UNKNOWN':
      return 'Unknown'
    case 'POI':
      return 'Point of Interconnect'
    case 'PV':
      return 'Photovoltaic'
    case 'BATTERY_BLOCK':
      return 'Battery Block'
    default:
      return null
  }
}

/**
 * This function will compare multiple numbers by it's position in the array.
 * @param {Array<number>} a
 * @param {Array<number>} b
 * @returns {0 | 1 | -1} 1 if a > b, -1 if b > a or 0 if both arrays has the same items or equivalent weight
 *
 * @example Usage on tables:
 *  {
      name: 'ID',
      id: 'id',
      selector: () => 'id',
      sortable: true,
      sortFunction: (a, b) =>
        sortMultipleValues([a.arrayIndex, a.stringIndex], [b.arrayIndex, b.stringIndex]),
    }
 */
export const sortMultipleValues = (a, b) => {
  const greaterLength = a.length > b.length ? a.length : b.length

  for (let index = 0; index < greaterLength; index++) {
    const aValue = isNil(a[index]) ? 0 : a[index]
    const bValue = isNil(b[index]) ? 0 : b[index]
    if (aValue !== bValue) return aValue - bValue
  }
  return 0
}

/** a native input checkbox wrapped by a tooltip when disabled */
export const DisabledControlCheckbox = forwardRef(
  ({ disabled, checked, onChange, ...props }, ref) => {
    return (
      <Tooltip
        overlayStyle={{ maxWidth: 300 }}
        title={disabled ? 'Control disabled. Parent Equipment OOR.' : ''}
      >
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
          {...props}
        />
      </Tooltip>
    )
  },
)
