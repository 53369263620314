import { StringContainer } from '../ContactorsFormUi.styles'
import { FormRow } from 'components/Form/FormComponents.styled'

export const PreferredCurrentForm = ({
  ids,
  userInputForm,
  setUserInputForm,
}) => {
  const handleUserInputFormChange = (name, e) => {
    if (e?.target) {
      const update = { ...userInputForm }
      update[name] = e.target.value
      setUserInputForm(update)
    }
  }
  return (
    <>
      <StringContainer>
        <label>Array{ids.length > 1 ? 's' : ''}</label>{' '}
        <span>{ids.join(', ')}</span>
      </StringContainer>
      <FormRow>
        <label>Charge Manager</label>
      </FormRow>
      <FormRow>
        <select
          value={userInputForm.chargeManagerEnabled}
          onChange={(e) => handleUserInputFormChange('chargeManagerEnabled', e)}
        >
          <option value={true}>Enabled</option>
          <option value={false}>Disabled</option>
        </select>
      </FormRow>
      <FormRow>
        <label>Discharge Manager</label>
      </FormRow>
      <FormRow>
        <select
          value={userInputForm.dischargeManagerEnabled}
          onChange={(e) =>
            handleUserInputFormChange('dischargeManagerEnabled', e)
          }
        >
          <option value={false}>Disabled</option>
          <option value={true}>Enabled</option>
        </select>
      </FormRow>
    </>
  )
}
