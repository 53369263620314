import _ from 'lodash'

import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'
import { ReactComponent as AcPvBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import { ReactComponent as ArrayIcon } from 'icons/array-icon.svg'
import { ReactComponent as DcAcInverterIcon } from 'icons/dc-ac-pcs-icon.svg'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import { ReactComponent as DcGroupIcon } from 'icons/dc-group-icon.svg'
import { ReactComponent as DcConverterIcon } from 'icons/dc-dc-converter-icon.svg'
import { ReactComponent as DcAcPCSIcon } from 'icons/dc-ac-pcs-icon.svg'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'

export const getDcSystemTopology = (
  blockStatusData,
  electricalDevicesData,
  params,
  options,
) => {
  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(
    blockStatusData.isDcCoupled,
  )

  return [
    {
      id: 'allBlockMeters',
      label: 'Block Meters',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/block-meter-list`,
      icon: <BlockMeterIcon />,
      omit: _.isEmpty(blockStatusData.blockMeters),
    },
    {
      id: 'allAcPvBatteries',
      label: 'AC PV Batteries',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/ac-pv-batteries-list`,
      icon: <AcPvBatteryIcon />,
      omit: !blockStatusData[blockPowerAndEnergyKey].acPvBatteryCount,
    },
    {
      id: 'allPvPcses',
      label: 'PV Pcses',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/pv-pcs-list`,
      icon: <DcAcInverterIcon />,
      omit: !blockStatusData[blockPowerAndEnergyKey].pvPcsCount,
    },
    {
      id: 'allDcDcGroups',
      label: 'DC:DC Groups',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/dc-dc-groups-list`,
      icon: <DcGroupIcon />,
      omit: !blockStatusData[blockPowerAndEnergyKey].dcDcGroupCount,
    },
    {
      id: 'allDcDcPcses',
      label: 'DC:DC Pcses',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/dc-dc-pcs-list`,
      icon: <DcConverterIcon />,
      omit: !blockStatusData[blockPowerAndEnergyKey].dcDcPcsCount,
    },
    {
      id: 'allArrays',
      label: 'Arrays',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/array-list`,
      icon: <ArrayIcon />,
      omit: _.isEmpty(blockStatusData.arrays),
    },
    {
      id: 'allStrings',
      label: 'Strings',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/string-list`,
      icon: <StringIcon />,
      omit: !electricalDevicesData?.strings?.total,
    },
    {
      id: 'allPcsGroups',
      label: 'PCS Groups',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/eks-pcs-group-list`,
      icon: <DcAcPCSIcon />,
      omit:
        !options.isPcsGroupsEnabled || !electricalDevicesData?.pcsGroups?.total,
    },
  ]
}
