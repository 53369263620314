import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components/macro'

// Component Imports
import Table from 'components/Table/Table/Table'

// Theme Imports
import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/sizes'
import { FONT_WEIGHT } from 'design_system/font_definitions'
import useEnvironmentalDevicesQuery from 'api/useQueryHooks/useEnvironmentalDevicesQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import {
  GetIconFromTypeName,
  getPageLinkFromTypeNameAndParams,
  getBinaryBarChartTotalVsUsed,
} from '../../Tables/tableUtils'

const getHealthyOrUntripped = (row) =>
  typeof row.healthy === 'number'
    ? row.healthy + ' Healthy'
    : row.untripped + ' Untripped'
const getUnHealthyOrTripped = (row) =>
  typeof row.unhealthy === 'number'
    ? row.unhealthy + ' Unhealthy'
    : row.tripped + ' Tripped'
const getPosHealthValue = (row) =>
  typeof row.healthy === 'number' ? row.healthy : row.untripped

const EnvironmentalDevicesTable = () => {
  const params = useParams()
  const columns = [
    {
      name: '',
      id: 'icon',
      selector: (row) => row.name,
      sortable: false,
      width: '30px',
      cell: (row) => GetIconFromTypeName(row.name),
    },
    {
      name: 'Type',
      id: 'type',
      selector: (row) => row.name,
      sortable: false,
      width: '150px',
      cell: (row) => (
        <EquipmentLink to={getPageLinkFromTypeNameAndParams(row.name, params)}>
          {row.name}
        </EquipmentLink>
      ),
    },
    {
      name: 'Total',
      id: 'total',
      selector: (row) => row.total,
      sortable: false,
      width: '75px',
      right: true,
      cell: (row) => <BoldCell>{row.total}</BoldCell>,
    },
    {
      name: 'Health',
      id: 'healthyCount',
      selector: (row) => row.total,
      sortable: false,
      width: '100px',
      cell: (row) =>
        getBinaryBarChartTotalVsUsed(row.total, getPosHealthValue(row)),
    },
    {
      name: '',
      id: 'healthText',
      selector: getHealthyOrUntripped,
      sortable: false,
      width: '200px',
      cell: (row) => (
        <LightCell>
          {getHealthyOrUntripped(row) + ' | ' + getUnHealthyOrTripped(row)}
        </LightCell>
      ),
    },
  ]

  const getRowId = (row) => Number(row.id)

  // has defined order do not provide defaultId
  const TableJsx = (data) => {
    return (
      <Table
        tableId="environmentalDevicesTable"
        data={data}
        columns={columns}
        getRowId={getRowId}
        fixedHeader={false}
        pagination={false}
        controls={false}
      />
    )
  }

  return handleQueryLoadingAndError(useEnvironmentalDevicesQuery(), TableJsx)
}

export default EnvironmentalDevicesTable

export const EquipmentLink = styled(Link)`
  font-size: ${FONT_SIZES.normal};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${COLORS.font_primary};
  text-decoration: underline;
  cursor: pointer;
`
const BoldCell = styled.div`
  font-size: ${FONT_SIZES.normal};
  font-weight: ${FONT_WEIGHT.bold};
`
const LightCell = styled.div`
  font-size: ${FONT_SIZES.normal};
  color: ${COLORS.font_secondary};
`
