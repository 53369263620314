import { useState, useEffect } from 'react'
import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { filterSensorsTable } from 'utils/sensors-utils'
import { hvacStageStyle, checkNullRoundValue } from '../tableUtils'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const CollectionSegmentsTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const [formattedColumns, setFormattedColumns] = useState([])

  const getRowId = (row) => Number(row.enclosureIndex)

  useEffect(() => {
    const idCell = (row) => (
      <Styles.RowLink
        to={`/block-details/${stationCode}/${blockIndex}/block-topology/centipede-lineup/${row.groupIndex}/collection-segment/${row.enclosureIndex}`}
      >
        {row.enclosureIndex}
      </Styles.RowLink>
    )

    setFormattedColumns(filterSensorsTable(data, getRowId, idCell, true))
  }, [data, stationCode, blockIndex])

  const additionalColumns = [
    {
      name: 'HUMIDITY',
      id: 'humidity',
      selector: (row) => row.internalHumidity,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.internalHumidity, '%'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AIR TEMP',
      id: 'airTemp',
      selector: (row) => row.internalAirTemp,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.internalAirTemp, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'COOL TO',
      id: 'coolTo',
      selector: (row) => row.coolTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.coolTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'HEAT TO',
      id: 'heatTo',
      selector: (row) => row.heatTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.heatTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'RESPONDING TO',
      id: 'respondTo',
      selector: (row) => row.respondingTo,
      sortable: true,
      width: '110px',
      cell: (row) => row.respondingTo,
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC STAGE',
      id: 'hvacStage',
      selector: (row) => row.hvacStage,
      sortable: true,
      width: '90px',
      cell: (row) => hvacStageStyle(row.hvacStage),
      noOmit: false,
      omit: false,
    },
    {
      name: 'SIGNALS',
      id: 'signals',
      selector: (row) => row.signals,
      sortable: true,
      width: '80px',
      cell: (row) => row.signals,
      noOmit: false,
      omit: false,
    },
  ]

  if (formattedColumns.length === 0) return <LoadingPage />
  return (
    <Table
      tableId="CollectionSegmentsTable"
      data={data ?? []}
      columns={formattedColumns.concat(additionalColumns)}
      getRowId={getRowId}
      fixedHeader={fixedHeader}
      pagination
      controls={false}
      hasDynamicColumns={true}
    />
  )
}

export default CollectionSegmentsTable
