import { useParams } from 'react-router-dom'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as PcsIcon } from 'icons/dc-ac-pcs-icon.svg'
import HealthStatus from 'components/Status/HealthStatus/HealthStatus'
import RotationStatusIcon from 'components/Status/RotationStatusIcon'
import { formatNumber } from 'utils/formatting'

const CONTROL_MODE = {
  PPC_CONTROL: 'PPC Mode',
  STACK_OS_CONTROL: 'StackOS Control',
  HMI_CONTROL: 'HMI Control',
  MAINTENANCE: 'Maintenance',
  OTHER: 'Other',
  undefined: 'Unknown',
  null: 'Unknown',
}

const EksPcsGroupDetailsSummary = ({ data }) => {
  const { eksPcsGroupId } = useParams()

  const summaryMap = {
    title: `PCS Group ${eksPcsGroupId}`,
    titleIcon: <PcsIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={data.isHealthy} />,
            isIcon: true,
          },
          {
            label: 'In Rotation',
            value: (
              <RotationStatusIcon
                outRotation={
                  data.pcsModuleInRotationCount != data.pcsModuleCount
                }
              />
            ),
            isIcon: true,
          },
          {
            label: 'Control Mode',
            value: CONTROL_MODE[data.interfaceOperationMode],
          },
        ],
      },
      {
        sectionTitle: 'Environment',
        rows: [
          {
            label: 'Transformer Temp.',
            value: formatNumber({
              value: data.mainTransformerTemperaturedegC,
              precision: 2,
              unit: ' º C',
            }),
          },
          {
            label: 'Ambient Temp.',
            value: formatNumber({
              value: data.externalAmbientTemperaturedegC,
              precision: 2,
              unit: 'º C',
            }),
          },
          {
            label: 'Cabinet Temp.',
            value: formatNumber({
              value: data.ambientTemperaturedegC,
              precision: 2,
              unit: 'º C',
            }),
          },
          {
            label: 'Ambient Humidity',
            value: formatNumber({
              value: data.externalHumidityPercent,
              precision: 2,
              unit: ' %',
            }),
          },
          {
            label: 'Cabinet Humidity',
            value: formatNumber({
              value: data.humidityPercent,
              precision: 2,
              unit: ' %',
            }),
          },
        ],
      },
      {
        sectionTitle: 'Real Power',
        rows: [
          {
            label: 'Total',
            value: `${data.acActivePowerSummationkW.toLocaleString()} kW`,
          },
          {
            label: 'Max Imported',
            value: `${data.fullSystemMaxAcActivePowerImportkW.toLocaleString()} kW`,
          },
          {
            label: 'Max Exported',
            value: `${data.fullSystemMaxAcActivePowerExportkW.toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Reactive Power',
        rows: [
          {
            label: 'Total',
            value: `${data.acReactivePowerSummationkVAr.toLocaleString()} kVAr`,
          },
          {
            label: 'Max Imported',
            value: `${data.fullSystemMaxAcReactivePowerImportkVAr.toLocaleString()} kVAr`,
          },
          {
            label: 'Max Exported',
            value: `${data.fullSystemMaxAcReactivePowerExportkVAr.toLocaleString()} kVAr`,
          },
        ],
      },
      {
        sectionTitle: 'Current Readings',
        rows: [
          {
            label: 'AC A > N',
            value: `${data.avgPhaseVoltageANV.toLocaleString()} V / ${data.phaseCurrentASummationA.toLocaleString()} A`,
          },
          {
            label: 'AC B > N',
            value: `${data.avgPhaseVoltageBNV.toLocaleString()} V / ${data.phaseCurrentBSummationA.toLocaleString()} A`,
          },
          {
            label: 'AC C > N',
            value: `${data.avgPhaseVoltageCNV.toLocaleString()} V / ${data.phaseCurrentCSummationA.toLocaleString()} A`,
          },
          {
            label: 'Frequency',
            value: formatNumber({
              value: data.acFrequencyHz,
              precision: 2,
              unit: ' Hz',
            }),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default EksPcsGroupDetailsSummary
