import _ from 'lodash'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import TernaryBarChartStrings from 'components/Charts/TernaryBarChart/TernaryBarChartStrings'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const StringListSummary = () => {
  const { data, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(data.isDcCoupled)

  const summaryMap = {
    title: `Strings`,
    titleIcon: <StringIcon />,
    titleChart: (
      <TernaryBarChartStrings
        data={{
          online: _.get(data, `${blockPowerAndEnergyKey}.onlineStackCount`),
          offline: _.get(data, `${blockPowerAndEnergyKey}.offlineStackCount`),
          nearline: _.get(data, `${blockPowerAndEnergyKey}.nearlineStackCount`),
          total: _.get(data, `${blockPowerAndEnergyKey}.totalStackCount`),
        }}
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Online',
            value: _.get(
              data,
              `${blockPowerAndEnergyKey}.onlineStackCount`,
            ).toLocaleString(),
          },
          {
            label: 'Nearline',
            value: _.get(
              data,
              `${blockPowerAndEnergyKey}.nearlineStackCount`,
            ).toLocaleString(),
          },
          {
            label: 'Offline',
            value: _.get(
              data,
              `${blockPowerAndEnergyKey}.offlineStackCount`,
            ).toLocaleString(),
          },
          {
            label: 'Total',
            value: _.get(
              data,
              `${blockPowerAndEnergyKey}.totalStackCount`,
            ).toLocaleString(),
          },
        ],
      },
      {
        sectionTitle: 'Online Strings',
        rows: [
          {
            label: 'SoC',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.onlineSocPercent`,
            )}%`,
          },
          {
            label: 'Available Energy',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.onlineAvailableKWH`,
            ).toLocaleString()} kWh`,
          },
          {
            label: 'Capacity',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.onlineCapacityKWH`,
            ).toLocaleString()} kWh`,
          },
        ],
      },
      {
        sectionTitle: 'Nearline Strings',
        rows: [
          {
            label: 'SoC',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.nearlineSocPercent`,
            )}%`,
          },
          {
            label: 'Available Energy',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.nearlineEnergyKWH`,
            ).toLocaleString()} kWh`,
          },
          {
            label: 'Capacity',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.nearlineCapacityKWH`,
            ).toLocaleString()} kWh`,
          },
        ],
      },
      {
        sectionTitle: 'Offline Strings',
        rows: [
          {
            label: 'SoC',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.offlineSocPercent`,
            )}%`,
          },
          {
            label: 'Available Energy',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.offlineEnergyKWH`,
            ).toLocaleString()} kWh`,
          },
          {
            label: 'Capacity',
            value: `${_.get(
              data,
              `${blockPowerAndEnergyKey}.offlineCapacityKWH`,
            ).toLocaleString()} kWh`,
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default StringListSummary
