import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * PCS Details graph data
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const usePCSDetailsGraphQuery = (period) => {
  const { stationCode, blockIndex, acBatteryId, pcsIndex } = useParams()
  const { session } = useContext(SessionContext)
  return useQuery(
    ['pcsDetailsGraph', stationCode, blockIndex, acBatteryId, pcsIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${acBatteryId}/arraypcs/${pcsIndex}/graph?period=${period}`,
        axiosOptions,
      )

      return response.data
    },
    { refetchInterval: 5000 },
  )
}

export default usePCSDetailsGraphQuery
