import { COLORS } from 'design_system/colors'
import styled from 'styled-components/macro'

export const UnitItem = styled.span`
  color: ${({ $healthy }) =>
    $healthy ? COLORS.powin_green : COLORS.font_secondary};
`

export const UnitsWrapper = styled.div`
  display: inline-flex;
  gap: 4px;
`

export const RegularEnclosureLocation = styled.span`
  font-weight: 600;
  font-size: 13px;
  color: #333;
`
