import { useState, useEffect, useMemo, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './AdminCommands.styles'
import { FONT_SIZES } from 'design_system/typography'
import { AuditLog } from './components/AuditLog/AuditLog'
import { ExecuteRemoteCommand } from './components/ExecuteRemoteCommand/ExecuteRemoteCommand'
import { FileGrabCommand } from './components/FileGrab/FileGrab'
import { FileListCommand } from './components/File List/FileList'
import { FirmwareDeploy } from './components/FirmwareCommands/FirmwareDeploy'
import { FirmwareTrigger } from './components/FirmwareCommands/FirmwareTrigger'
import { FirmwareUpload } from './components/FirmwareCommands/FirmwareUpload'
import { ResetCommand } from './components/ResetCommand/ResetCommand'
import { SessionContext } from 'contexts/session'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { USER_ROLES } from 'constants/USER_ROLES'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'

export const AdminCommands = () => {
  const { userHasRole, hasEnabledFlags } = useContext(AuthorizationContext)
  const { session } = useContext(SessionContext)

  const isSuperUser = session?.userAuth?.settings?.SUPERUSER === '1'
  const isTechSupport = userHasRole(USER_ROLES.TECH_SUPPORT)
  const isFirmDeploy = userHasRole(USER_ROLES.FIRMWARE_DEPLOY)
  const isFirmTrigger = userHasRole(USER_ROLES.FIRMWARE_TRIGGER)
  const isFirmUpload = userHasRole(USER_ROLES.FIRMWARE_UPLOAD)

  const getInitialPage = () => {
    if (isSuperUser || isTechSupport) return 0
    if (isFirmDeploy) return 6
    if (isFirmTrigger) return 7
    if (isFirmUpload) return 8
  }

  const [selectedCommand, setSelectedCommand] = useState(getInitialPage())

  const navigate = useNavigate()

  const isDownloadConfigEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DOWNLOAD_CONFIGURATION,
  )
  const isDragonKeyLockEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DRAGON_KEY_LOCK,
  )
  const isFileGrabEnabled = hasEnabledFlags(FEATURE_TOGGLES.FILE_GRAB_COMMAND)
  const isFileListEnabled = hasEnabledFlags(FEATURE_TOGGLES.FILE_LIST_COMMAND)
  const isOsPatchEnabled = hasEnabledFlags(FEATURE_TOGGLES.OS_PATCH)

  const commands = useMemo(() => {
    return [
      {
        name: 'Audit Log',
        permission: isTechSupport,
        isEnabled: true,
      },
      {
        name: 'Download Configuration',
        permission: isTechSupport,
        isEnabled: isDownloadConfigEnabled,
      },
      {
        name: 'Dragon Key Lock',
        permission: isSuperUser,
        isEnabled: isDragonKeyLockEnabled,
      },
      {
        name: 'Execute Remote Command',
        permission: isSuperUser,
        isEnabled: true,
      },
      {
        name: 'File Grab Command',
        permission: isSuperUser,
        isEnabled: isFileGrabEnabled,
      },
      {
        name: 'File List Command',
        permission: isTechSupport,
        isEnabled: isFileListEnabled,
      },
      {
        name: 'Firmware Deploy Command',
        permission: isFirmDeploy,
        isEnabled: true,
      },
      {
        name: 'Firmware Trigger Command',
        permission: isFirmTrigger,
        isEnabled: true,
      },
      {
        name: 'Firmware Upload Command',
        permission: isFirmUpload,
        isEnabled: true,
      },
      {
        name: 'OS Patch',
        permission: isSuperUser,
        isEnabled: isOsPatchEnabled,
      },
      {
        name: 'Reset Command',
        permission: isSuperUser,
        isEnabled: true,
      },
    ]
  }, [
    isSuperUser,
    isTechSupport,
    isFirmDeploy,
    isFirmTrigger,
    isFirmUpload,
    isDownloadConfigEnabled,
    isDragonKeyLockEnabled,
    isFileGrabEnabled,
    isFileListEnabled,
    isOsPatchEnabled,
  ])

  const commandSelectedHandler = useMemo(() => {
    return (commandIndex) => {
      const command = commands[commandIndex].name
      const path = command.toLowerCase().replace(new RegExp(' ', 'g'), '-')
      setSelectedCommand(commandIndex)
      navigate(path)
    }
  }, [commands, navigate])

  const setSelectedCommandPage = (commandName) => {
    const commandIndex = commands.findIndex(
      (command) => command.name === commandName,
    )
    setSelectedCommand(commandIndex)
  }

  useEffect(() => {
    commandSelectedHandler(selectedCommand)
  }, [selectedCommand, navigate, commandSelectedHandler])

  return (
    <Styles.Container>
      <Styles.LeftContainer>
        <Typography $bold $fontSize={FONT_SIZES.xxl} $margin="16px">
          Admin Commands
        </Typography>
        <Styles.CommandsContainer>
          {commands.map(
            (command, index) =>
              command.permission &&
              command.isEnabled && (
                <Styles.CommandItemWrapper
                  key={index}
                  $active={index == selectedCommand}
                  onClick={() => commandSelectedHandler(index)}
                >
                  <Typography $fontSize={FONT_SIZES.large} $bold>
                    {command.name}
                  </Typography>
                </Styles.CommandItemWrapper>
              ),
          )}
        </Styles.CommandsContainer>
      </Styles.LeftContainer>
      <Styles.RightContainer>
        <Routes>
          {isTechSupport && (
            <Route
              path="audit-log"
              element={
                <AuditLog setSelectedCommandPage={setSelectedCommandPage} />
              }
            />
          )}
          {isTechSupport && isDownloadConfigEnabled && (
            <Route
              path="download-configuration"
              element={
                <Typography $bold $fontSize={FONT_SIZES.xl} $margin="16px">
                  {commands[selectedCommand].name}
                </Typography>
              }
            />
          )}
          {isSuperUser && isDragonKeyLockEnabled && (
            <Route
              path="dragon-key-lock"
              element={
                <Typography $bold $fontSize={FONT_SIZES.xl} $margin="16px">
                  {commands[selectedCommand].name}
                </Typography>
              }
            />
          )}
          {isSuperUser && (
            <Route
              path="execute-remote-command"
              element={
                <ExecuteRemoteCommand
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isSuperUser && isFileGrabEnabled && (
            <Route
              path="file-grab-command"
              element={
                <FileGrabCommand
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isTechSupport && isFileListEnabled && (
            <Route
              path="file-list-command"
              element={
                <FileListCommand
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isFirmDeploy && (
            <Route
              path="firmware-deploy-command"
              element={
                <FirmwareDeploy
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isFirmTrigger && (
            <Route
              path="firmware-trigger-command"
              element={
                <FirmwareTrigger
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isFirmUpload && (
            <Route
              path="firmware-upload-command"
              element={
                <FirmwareUpload
                  setSelectedCommandPage={setSelectedCommandPage}
                />
              }
            />
          )}
          {isSuperUser && isOsPatchEnabled && (
            <Route
              path="os-patch"
              element={
                <Typography $bold $fontSize={FONT_SIZES.xl} $margin="16px">
                  {commands[selectedCommand].name}
                </Typography>
              }
            />
          )}
          {isSuperUser && (
            <Route
              path="reset-command"
              element={
                <ResetCommand setSelectedCommandPage={setSelectedCommandPage} />
              }
            />
          )}
        </Routes>
      </Styles.RightContainer>
    </Styles.Container>
  )
}
