import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'
import Table from 'components/Table/Table/Table'
import { formattedDate } from 'utils/formatting'

export const FileListTable = () => {
  const getFormattedDate = (row) =>
    row.dateModified ? formattedDate(row.dateModified) : 'Unknown'

  const data = [
    {
      fileSize: 23,
      filename: 'coblynau-2023-10-10.log.gz',
      dateModified: 1699933010000,
    },
    {
      fileSize: 54,
      filename: 'coblynau-2023-10-11.log.gz',
      dateModified: 1699953010000,
    },
    {
      fileSize: 12,
      filename: 'coblynau-2023-10-12.log.gz',
      dateModified: 1699983010000,
    },
  ]

  const columnActionMenu = (row) => {
    return (
      <ActionsMenu key={row} onOpen={null} onClose={null}>
        ACTIONS
      </ActionsMenu>
    )
  }

  const columns = [
    {
      name: 'ACTIONS',
      id: 'actions',
      sortable: false,
      omit: false,
      width: '60px',
      cell: columnActionMenu,
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'FILE NAME',
      id: 'fileName',
      sortable: true,
      selector: (row) => row.filename,
      omit: false,
      cell: (row) => row.filename,
    },
    {
      name: 'FILE SIZE',
      id: 'fileSize',
      sortable: true,
      selector: (row) => row.fileSize,
      omit: false,
      cell: (row) => row.fileSize + ' KB',
    },
    {
      name: 'DATE MODIFIED',
      id: 'dateModified',
      sortable: true,
      selector: (row) => row.dateModified,
      omit: false,
      cell: getFormattedDate,
    },
  ]

  const getRowId = (row) => Number(row.id)

  return (
    <Table
      tableId="FileListCommandTable"
      data={data}
      columns={columns}
      getRowId={getRowId}
      fixedHeader={false}
      pagination={false}
      controls={false}
      defaultId="dateModified"
    />
  )
}
