import { useState, useEffect } from 'react'
import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { checkNullRoundValue } from '../tableUtils'
import { filterSensorsTable } from 'utils/sensors-utils'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const CentipedeLineupsTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const [formattedColumns, setFormattedColumns] = useState([])

  const getRowId = (row) => Number(row.centipedeLineupIndex)

  useEffect(() => {
    const idCell = (row) => (
      <Styles.RowLink
        to={`/block-details/${stationCode}/${blockIndex}/block-topology/centipede-lineup-details/${row.centipedeLineupIndex}`}
      >
        {row.centipedeLineupIndex}
      </Styles.RowLink>
    )

    setFormattedColumns(filterSensorsTable(data.elementList, getRowId, idCell))
  }, [data, stationCode, blockIndex])

  const additionalColumns = [
    {
      name: 'CS AIR TEMP',
      id: 'csAirTemp',
      selector: (row) => row.csinternalAirTemp,
      sortable: true,
      width: '100px',
      cell: (row) => checkNullRoundValue(row.csinternalAirTemp, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'ES AVG CELL TEMP',
      id: 'esAvgCellTemp',
      selector: (row) => row.esavgCellTemp,
      sortable: true,
      width: '130px',
      cell: (row) => checkNullRoundValue(row.esavgCellTemp, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'COOL TO',
      id: 'coolTo',
      selector: (row) => row.coolTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.coolTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'HEAT TO',
      id: 'heatTo',
      selector: (row) => row.heatTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.heatTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  if (formattedColumns.length === 0) return <LoadingPage />
  return (
    <Table
      tableId="CentipedeLineupsTable"
      data={data.elementList ?? []}
      columns={formattedColumns.concat(additionalColumns)}
      getRowId={getRowId}
      fixedHeader={fixedHeader}
      pagination
      controls={false}
      hasDynamicColumns={true}
      defaultId="id"
    />
  )
}

export default CentipedeLineupsTable
