import styled from 'styled-components/macro'

export const ContactorWrapper = styled.div`
  display: ${(props) => (props.$isHealthy ? 'block' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
`

export const StatusIcon = styled.div`
  width: 20px;
  height: 15px;
  fill: ${(props) => (props.$isReady ? '#00ba86' : 'red')};
`

export const WarningIcon = styled.div`
  width: 5px;
  height: 12px;
  fill: red;
`
