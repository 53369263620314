import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from '../../../components/Layouts/ListPage/ListPage'
import PvPcsSummary from './Summary/PvPcsSummary'
import PvPcsTable from 'modules/Station/components/Tables/PvPcsTable/PvPcsTable'
import usePvPcsesQuery from 'api/useQueryHooks/usePvPcsesQuery'

import useElectricalDevicesQuery from 'api/useQueryHooks/useElectricalDevicesQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const PvPcsList = ({ selectedParams, setSelectedParams, setSelectedPage }) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  const { data: pvPcsesData, isLoading: isLoadingPvPcsesData } =
    usePvPcsesQuery()

  const { data: electricalDevicesData, isLoading: isLoadingElectricalDevices } =
    useElectricalDevicesQuery({
      enabled: true,
    })

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'pv-pcs-list' })
    document.title = `${stationCode}::${blockIndex} - PV PCSes`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useMemo(() => {
    if (!pvPcsesData) return []

    return pvPcsesData.pvPcses.map((pvpcs) => ({
      ...pvpcs,
      faults:
        electricalDevicesData?.pvPcses?.faulting?.filter?.(
          (device) =>
            device.acPvBatteryIndex === pvpcs.acPvBatteryIndex &&
            device.pvPcsIndex === pvpcs.pvPcsIndex,
        ) ?? [],
    }))
  }, [electricalDevicesData, pvPcsesData])

  if (isLoadingPvPcsesData || isLoadingElectricalDevices) return <LoadingPage />

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<PvPcsSummary data={data} />}
      tableComponent={<PvPcsTable data={data} fixedHeader={true} />}
    />
  )
}

export default PvPcsList
