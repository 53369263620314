export const healthyStringFraction = (
  onlineStack: number,
  totalStack: number,
): number => {
  return totalStack > 0 ? onlineStack / totalStack : 0
}

export const createStringsObjectFromStackCounts = (
  onlineStackCount: number,
  offlineStackCount: number,
  nearlineStackCount: number,
  nonCommunicatingStackCount: number,
) => {
  return {
    online: onlineStackCount,
    offline: offlineStackCount,
    nearline: nearlineStackCount,
    total:
      onlineStackCount +
      offlineStackCount +
      nearlineStackCount +
      nonCommunicatingStackCount,
  }
}
