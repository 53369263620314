export const ELECTRICAL_DEVICE_TYPES = {
  'AC Batteries': 'AC Batteries',
  PCSes: 'PCSes',
  Arrays: 'Arrays',
  Strings: 'Strings',
  'Block Meters': 'Block Meters',
  'AC PV Batteries': 'AC PV Batteries',
  'PV PCSes': 'PV PCSes',
  'DC:DC Groups': 'DC:DC Groups',
  'DC:DC PCSes': 'DC:DC PCSes',
  'PCS Modules': 'PCS Modules',
  'PCS Groups': 'PCS Groups',
} as const
