import { CommunicatingTooltip } from 'components/CommunicatingTooltip/CommunicatingTooltip'
import { ReactComponent as StatusDisconnectedIcon } from 'icons/status-disconnected-icon.svg'
import { ReactComponent as StatusConnectedIcon } from 'icons/status-connected-icon.svg'
import Tooltip from 'components/Tooltip'
import * as Styles from './CommunicationStatus.styles'

export const CommunicatingStatus = ({
  isCommunicating,
  timestamp,
  showTooltip = true,
  size = '20px',
}) => {
  const openTooltipProp = showTooltip ? null : { open: false }
  return (
    <Tooltip
      title={
        <CommunicatingTooltip
          isCommunicating={isCommunicating}
          timestamp={timestamp}
        />
      }
      {...openTooltipProp}
    >
      <Styles.Container $size={size} $isCommunicating={isCommunicating}>
        {isCommunicating ? <StatusConnectedIcon /> : <StatusDisconnectedIcon />}
      </Styles.Container>
    </Tooltip>
  )
}
