import { useParams } from 'react-router-dom'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import StringTable from '../../Tables/StringTable/StringTable'
import _ from 'lodash'

const StringListCard = ({ data }) => {
  const { stationCode, blockIndex } = useParams()
  if (!data) return undefined

  const stringsInRotation = _.chain(data)
    .get('strings')
    .filter({ outRotation: false })
    .value().length

  const stringsOutRotation = _.chain(data)
    .get('strings')
    .filter({ outRotation: true })
    .value().length

  const count = Object.keys(data.strings).length

  return (
    <ExpandableCard
      title="STRINGS"
      icon={<StringIcon />}
      isExpanded={true}
      summarySections={[
        {
          label: 'Total',
          value: count,
          type: 'value',
        },
        {
          label: 'Ready | Not Ready',
          type: 'binary',
          positiveCount: stringsInRotation,
          negativeCount: stringsOutRotation,
          tooltip: `In Rotation: ${stringsInRotation} / Out Rotation: ${stringsOutRotation}`,
          id: stationCode + 'StringsBar',
        },
      ]}
      listLink={`/block-details/${stationCode}/${blockIndex}/block-topology/string-list`}
    >
      <StringTable data={data} fixedHeader={false} pagination={false} />
    </ExpandableCard>
  )
}

export default StringListCard
