import { FONT_SIZES } from 'design_system/typography'
import styled from 'styled-components/macro'

export const InfoMessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: auto;
  padding: 5px;
  font-size: ${FONT_SIZES.normal};
  gap: 0.25rem;
`

export const MessageTitle = styled.div`
  font-size: ${FONT_SIZES.xl};
  font-weight: 600;
`
