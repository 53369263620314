import {
  RotationForm,
  defaultRotationFormValues,
} from '../SetRotation/RotationForm'
import CommandModalTemplate from '../CommandModalTemplate'
import { ArrayRotationResults } from './ArrayRotationResults'
import { ResourceEnum } from 'api/dictionaries/rotation-excuses'

// 1st modal, 2nd modal heading
const modalTitles = {
  start: 'Array Rotation',
  finish: 'Array Rotation',
}
const label = 'Arrays'
const resourceType = ResourceEnum.string
const queryName = 'setArrayRotation'

// step 2 custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <ArrayRotationResults ids={ids} userInputForm={userInputForm} />
)
// step 1 define custom input form returns jsx
const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  RotationForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    resourceType,
    label,
  })

// SetCommandModal keeps track of input form and each modal step, sending command, close modal
export const SetArrayRotationModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated
    />
  )
}
