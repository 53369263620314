import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as CollectionSegmentsIcon } from 'icons/centipede-collection-segment.svg'
import useOutdoorEnvironmentQuery from 'api/useQueryHooks/useOutdoorEnvironmentQuery'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

const CollectionSegmentDetailsSummary = ({ id, isHealthy }) => {
  const { data: envData, isFetched } = useOutdoorEnvironmentQuery()

  let airTemp = 'Loading'
  let humidity = 'Loading'
  if (isFetched) {
    airTemp = checkNullRoundValue(envData.externalAirTemp, '° C')
    humidity = checkNullRoundValue(envData.externalHumidity, '%')
  }

  const summaryMap = {
    title: `Collection Segment ${id}`,
    titleIcon: <CollectionSegmentsIcon />,
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: isHealthy ? (
              <StatusIcon $isHealthy={isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'Ambient Air Temp',
            value: airTemp.toLocaleString(),
          },
          {
            label: 'Ambient Humidity',
            value: humidity.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default CollectionSegmentDetailsSummary
