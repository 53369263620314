import React from 'react'
import { Link } from 'react-router-dom'

// Style Imports
import * as Styles from './styles'

const LinkTab = ({ children, isSelected, handleClick, to }) => {
  return (
    <Styles.Container $isSelected={isSelected} onClick={handleClick}>
      <Link to={to}>{children}</Link>
    </Styles.Container>
  )
}

export default LinkTab
