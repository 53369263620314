import { COLORS } from 'design_system/colors'
import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './NotificationActions.styles'
import SearchInput from 'components/Inputs/SearchInput/SearchInput'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import useNotificationsSubtypesQuery from 'api/useQueryHooks/useNotificationsSubtypesQuery'
import useNotificationsTypesQuery from 'api/useQueryHooks/useNotificationsTypesQuery'

export const NotificationActions = ({ filters, onFiltersChange }) => {
  const { data: notificationTypes } = useNotificationsTypesQuery()
  const { data: notificationSubtypes } = useNotificationsSubtypesQuery()

  const typeOptions =
    notificationTypes &&
    notificationTypes.notificationTypes.map((type) => (
      <Styles.OptionWrapper
        key={type}
        onClick={() => onFiltersChange('type', type)}
      >
        {type}
      </Styles.OptionWrapper>
    ))

  const subtypesOptions =
    notificationSubtypes &&
    notificationSubtypes.notificationSubTypes.map((subtype) => (
      <Styles.OptionWrapper
        key={subtype}
        onClick={() => onFiltersChange('subtype', subtype)}
      >
        {subtype}
      </Styles.OptionWrapper>
    ))

  return (
    <Styles.ActionContainer>
      <Styles.ActionGroup>
        <SearchInput
          type="search"
          onChange={(e) => onFiltersChange('query', e.target.value)}
          placeholder="Search Notifications"
          value={filters.query}
        />
      </Styles.ActionGroup>
      <Styles.ActionGroup>
        <Typography $fontColor={COLORS.column_header_font_color} $strong>
          FILTERS:
        </Typography>
        <Styles.ItemWrapper>
          <ToolbarDropdown
            title={'Type'}
            width="165px"
            titleValue={filters.type}
            handleClose={filters.type}
          >
            {filters.type && (
              <Styles.OptionWrapper
                key={'clear'}
                onClick={() => onFiltersChange('type', '')}
              >
                Clear
              </Styles.OptionWrapper>
            )}
            {typeOptions}
          </ToolbarDropdown>
        </Styles.ItemWrapper>
        <Styles.ItemWrapper>
          <ToolbarDropdown
            title={'Subtype'}
            width="165px"
            titleValue={filters.subtype}
            handleClose={filters.subtype}
          >
            {filters.subtype && (
              <Styles.OptionWrapper
                key={'clear'}
                onClick={() => onFiltersChange('subtype', '')}
              >
                Clear
              </Styles.OptionWrapper>
            )}
            {subtypesOptions}
          </ToolbarDropdown>
        </Styles.ItemWrapper>
      </Styles.ActionGroup>
    </Styles.ActionContainer>
  )
}
