import styled from 'styled-components/macro'

export const Container = styled.div`
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const TableControls = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 5px;
  }
`
export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px;
`
export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Icon = styled.div`
  fill: #30bf91;
  line-height: 0;
  margin: 0 auto;
`
export const CollapsedIcon = styled(Icon)`
  width: 5px;
  height: 10px;
`
export const ExpandedIcon = styled(Icon)`
  width: 10px;
  height: 5px;
`
