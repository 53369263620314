import _ from 'lodash'
import { useParams } from 'react-router-dom'

import * as Styles from './styles'

import HealthStatus from 'components/Status/HealthStatus/HealthStatus'

// Icon Imports
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'
import { ReactComponent as ContactorClosedIcon } from 'icons/contactor-closed-icon.svg'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as WarningIcon } from 'icons/warning-icon.svg'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import useStringStatusDetailQuery from 'api/useQueryHooks/useStringStatusDetailQuery'
import {
  BalanceCount,
  getBalanceDisplayStatus,
  getDeadband,
} from 'modules/Station/components/Tables/tableUtils'
import { CommunicatingStatus } from 'components/Status/CommunicationStatus/CommunicationStatus'
import { formatNumber } from 'utils/formatting'

const StringDetailsSummary = () => {
  const { arrayId, stringID } = useParams()
  const { data, isLoading } = useStringStatusDetailQuery()

  if (isLoading) return <LoadingPage />

  const isCommunicating = data?.reportTimestamp?.status === 'VALID'
  const isPositiveContactorHealthy =
    data.contactorsCloseExpected === data.positiveContactorClosed
  const isNegativeContactorHealthy =
    data.contactorsCloseExpected === data.negativeContactorClosed

  const summaryMap = {
    title: `String ${arrayId} :: ${stringID}`,
    titleIcon: <StringIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={!_.get(data, 'outRotation')} />,
            isIcon: true,
          },
          {
            label: 'Communication',
            value: (
              <CommunicatingStatus
                isCommunicating={isCommunicating}
                timestamp={data?.reportTimestamp?.timestamp}
                showTooltip={false}
              />
            ),
            isIcon: true,
          },
          {
            label: 'Contactor 1',
            value: (
              <Styles.ContactorWrapper $isHealthy={isPositiveContactorHealthy}>
                <Styles.StatusIcon $isReady={isPositiveContactorHealthy}>
                  {_.get(data, 'positiveContactorClosed') ? (
                    <ContactorClosedIcon />
                  ) : (
                    <ContactorOpenIcon />
                  )}
                </Styles.StatusIcon>
                {!isPositiveContactorHealthy && (
                  <Styles.WarningIcon>
                    <WarningIcon />
                  </Styles.WarningIcon>
                )}
              </Styles.ContactorWrapper>
            ),
            isIcon: true,
            doubleIcon: !isPositiveContactorHealthy,
          },
          {
            label: 'Contactor 2',
            value: (
              <Styles.ContactorWrapper $isHealthy={isNegativeContactorHealthy}>
                <Styles.StatusIcon $isReady={isNegativeContactorHealthy}>
                  {_.get(data, 'negativeContactorClosed') ? (
                    <ContactorClosedIcon />
                  ) : (
                    <ContactorOpenIcon />
                  )}
                </Styles.StatusIcon>
                {!isNegativeContactorHealthy && (
                  <Styles.WarningIcon>
                    <WarningIcon />
                  </Styles.WarningIcon>
                )}
              </Styles.ContactorWrapper>
            ),
            isIcon: true,
            doubleIcon: !isNegativeContactorHealthy,
          },
        ],
      },

      {
        sectionTitle: 'Balancing',
        rows: [
          {
            label: 'Mode',
            value: data.mixedBalancingMode
              ? 'Mixed'
              : getBalanceDisplayStatus(data?.balanceMode),
          },
          {
            label: 'Charge Deadband',
            value: getDeadband(data, true),
          },
          {
            label: 'Discharge Deadband',
            value: getDeadband(data, false),
          },
          {
            label: 'Counts',
            value: `${BalanceCount(
              _.chain(data).get('balancingCount').value(),
            )}`,
          },
        ],
      },

      {
        sectionTitle: 'Energy',
        rows: [
          {
            label: 'SoC',
            value: formatNumber({ value: _.get(data, 'soc'), unit: '%' }),
          },
          {
            label: 'Amp Hours',
            value: formatNumber({
              value: _.get(data, 'ampHours'),
              precision: 2,
              unit: ' Ah',
            }),
          },
          {
            label: 'Kilowatt Hours',
            value: formatNumber({
              value: _.get(data, 'kilowattHours'),
              precision: 2,
              unit: ' kWh',
            }),
          },
        ],
      },
      {
        sectionTitle: 'Power',
        rows: [
          {
            label: 'Amps',
            value: formatNumber({
              value: _.get(data, 'current'),
              precision: 2,
              unit: ' A',
            }),
          },
          {
            label: 'Kilowatts',
            value: formatNumber({
              value: _.get(data, 'kiloWatts'),
              precision: 2,
              unit: ' kW',
            }),
          },
        ],
      },
      {
        sectionTitle: 'Voltage',
        rows: [
          {
            label: 'Measured',
            value: formatNumber({
              value: _.get(data, 'measuredStringVoltage'),
              precision: 2,
              unit: ' V',
            }),
          },
          {
            label: 'Calculated',
            value: formatNumber({
              value: _.get(data, 'calculatedStringVoltage'),
              precision: 2,
              unit: ' V',
            }),
          },
          {
            label: 'Bus',
            value: formatNumber({
              value: _.get(data, 'dcBusVoltage'),
              precision: 2,
              unit: ' V',
            }),
          },
          {
            label: <span>&#916;</span>,
            value: formatNumber({
              value:
                _.get(data, 'dcBusVoltage') -
                _.get(data, 'measuredStringVoltage'),
              precision: 2,
              unit: ' V',
            }),
          },
        ],
      },
      {
        sectionTitle: 'Cell Voltage',
        rows: [
          {
            label: 'Minimum',
            value: formatNumber({
              value: _.get(data, 'cellMinVoltage'),
              precision: 2,
              unit: ' mV',
            }),
          },
          {
            label: 'Maximum',
            value: formatNumber({
              value: _.get(data, 'cellMaxVoltage'),
              precision: 2,
              unit: ' mV',
            }),
          },
          {
            label: 'Average',
            value: formatNumber({
              value: _.get(data, 'cellAvgVoltage'),
              precision: 2,
              unit: ' mV',
            }),
          },
          {
            label: <span>&#916;</span>,
            value: formatNumber({
              value:
                _.get(data, 'cellMaxVoltage') - _.get(data, 'cellMinVoltage'),
              precision: 2,
              unit: ' mV',
            }),
          },
        ],
      },
      {
        sectionTitle: 'Cell Temperature',
        rows: [
          {
            label: 'Minimum',
            value: formatNumber({
              value: _.get(data, 'cellMinTemp'),
              precision: 2,
              unit: ' °C',
            }),
          },
          {
            label: 'Maximum',
            value: formatNumber({
              value: _.get(data, 'cellMaxTemp'),
              precision: 2,
              unit: ' °C',
            }),
          },
          {
            label: 'Average',
            value: formatNumber({
              value: _.get(data, 'cellAvgTemp'),
              precision: 2,
              unit: ' °C',
            }),
          },
          {
            label: <span>&#916;</span>,
            value: formatNumber({
              value: _.get(data, 'cellMaxTemp') - _.get(data, 'cellMinTemp'),
              precision: 2,
              unit: ' °C',
            }),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default StringDetailsSummary
