import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { fetchControllerStatistics } from 'api/queries.api'
import { SessionContext } from 'contexts/session'

const SiteControllerVersionContext = createContext()
export function useSiteControllerVersionContext() {
  return useContext(SiteControllerVersionContext)
}

export function SiteControllerVersionProvider({ children }) {
  const [siteControllerVersions, setSiteControllerVersions] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { session, setSession } = useContext(SessionContext)
  useEffect(() => {
    setIsLoading(true)
    fetchControllerStatistics(session, setSession)
      .then((response) => {
        const siteControllerVersionsMap = {}
        for (const controller of response.controllers) {
          const [major, minor, release] = controller.turtleVersion.split('.')
          siteControllerVersionsMap[controller.stationCode] = {
            major,
            minor,
            release,
          }
        }
        setSiteControllerVersions(siteControllerVersionsMap)
        setIsLoading(false)
      })
      .catch((reason) =>
        // this is needed when app restarts next day, 401 displays to user
        // eslint-disable-next-line no-console
        console.warn(
          'https://kobold-qas.powindevqa.com/kobold/dataapi/v1/controllers after logout error:',
          reason,
        ),
      )
  }, [session, setSession])

  const value = useMemo(() => {
    const isOpModel2 = (stationCode) =>
      siteControllerVersions[stationCode] &&
      siteControllerVersions[stationCode].major === '3'

    return {
      siteControllerVersions: siteControllerVersions,
      isOpModel2,
      isLoading,
    }
  }, [isLoading, siteControllerVersions])

  return (
    <SiteControllerVersionContext.Provider value={value}>
      {children}
    </SiteControllerVersionContext.Provider>
  )
}
