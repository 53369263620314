import * as Styles from './styles'
import useACBatteryDetailsQuery from 'api/useQueryHooks/useACBatteryDetails'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import SummaryCardArray from 'modules/Station/components/Cards/SummaryCardArray'
import SummaryCardInverter from 'modules/Station/components/Cards/SummaryCardInverter'
import StringListCard from 'modules/Station/components/Cards/StringListCard/StringListCard'
import useArrayByIdQuery from 'api/useQueryHooks/useArrayByIdQuery'
import { useParams } from 'react-router'

const ACBatteryDetailsMainColumn = () => {
  const params = useParams()
  const { data, isLoading } = useACBatteryDetailsQuery()
  const { data: arrayData, isLoading: stringDataLoading } = useArrayByIdQuery(
    params.acBatteryId,
  )
  if (isLoading || stringDataLoading) return <LoadingPage />
  return (
    <div>
      <Styles.Row>
        <Styles.Flex>
          <SummaryCardArray data={data} />
        </Styles.Flex>
        <Styles.Flex>
          <SummaryCardInverter data={data} />
        </Styles.Flex>
      </Styles.Row>
      <StringListCard data={arrayData} />
    </div>
  )
}

export default ACBatteryDetailsMainColumn
