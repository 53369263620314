import { fetchArrayStatus } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * Fetch Array status by ID
 * @returns arrayId, station and block gathered from URL
 */
const useArrayByIdQuery = (arrayId) => {
  const { stationCode, blockIndex } = useParams()
  const { session, setSession } = useContext(SessionContext)

  return useQuery(
    ['Array', stationCode, blockIndex, arrayId],
    () =>
      fetchArrayStatus(stationCode, blockIndex, arrayId, session, setSession),
    { refetchInterval: 5000 },
  )
}

export default useArrayByIdQuery
