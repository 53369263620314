import getAxiosBasicUrlAndHeader from 'api/helpers/getAxiosBasicUrlAndHeader'
import axios from 'axios'
import { ELECTRICAL_DEVICE_TYPES } from 'modules/Station/components/ElectricalDevices/constants'

const endpoints = {
  [ELECTRICAL_DEVICE_TYPES.PCSes]: 'arraypcses/clearfaults',
  [ELECTRICAL_DEVICE_TYPES['DC:DC PCSes']]: 'dcdcpcses/clearfaults',
  [ELECTRICAL_DEVICE_TYPES['PV PCSes']]: 'pvpcses/clearfaults',
  [ELECTRICAL_DEVICE_TYPES['PCS Modules']]: 'arraypcses/clearfaults',
}

/**
 * required 8PC
 * @param {Object} payload
 * @param {*} session
 * @param {string} stationCode
 * @param {string} blockIndex
 * @returns Promise success or fail message
 */
const clearDeviceFaultsService = async (
  payload,
  session,
  stationCode,
  blockIndex,
) => {
  const { deviceType, faultingDevices } = payload
  if (!deviceType || !Object.keys(endpoints).includes(deviceType))
    throw Error('Invalid query type')

  const jsonBody = {
    keys: faultingDevices,
  }

  const { apiUrl, axiosHeader } = getAxiosBasicUrlAndHeader(session)

  return await axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/${endpoints[deviceType]}`,
      jsonBody,
      axiosHeader,
    )
    .then((res) => res.data)
}

export default clearDeviceFaultsService
