import _ from 'lodash'

export const formatAnalysisData = (
  d,
  viewType,
  minArray,
  maxArray,
  filters,
) => {
  const formattedData =
    viewType === 'array'
      ? formatByArray(d, minArray, maxArray)
      : formatByString(d, minArray, maxArray)

  const filteredData = filterData(formattedData, filters)
  return filteredData
}

const formatByArray = (d, minArray, maxArray) =>
  _.reduce(
    d,
    function (result, value) {
      if (value.arrayIndex < minArray || value.arrayIndex > maxArray) {
        return result
      } else if (_.some(result, ['arrayIndex', value.arrayIndex])) {
        const index = _.findIndex(result, ['arrayIndex', value.arrayIndex])
        const averagedArray = _.find(result, ['arrayIndex', value.arrayIndex])
        const connectedCount = value.connected
          ? averagedArray.connectedCount + 1
          : averagedArray.connectedCount
        const inRotationCount = value.inRotation
          ? averagedArray.inRotationCount + 1
          : averagedArray.inRotationCount

        const updatedArray = {
          ...value,
          cellTemperature000:
            averagedArray.cellTemperature000 < value.cellTemperature000
              ? averagedArray.cellTemperature000
              : value.cellTemperature000,
          cellTemperature025: _.mean([
            averagedArray.cellTemperature025,
            value.cellTemperature025,
          ]),
          cellTemperature075: _.mean([
            averagedArray.cellTemperature075,
            value.cellTemperature075,
          ]),
          cellTemperature100:
            averagedArray.cellTemperature100 > value.cellTemperature100
              ? averagedArray.cellTemperature100
              : value.cellTemperature100,
          cellTemperatureAverage: _.mean([
            averagedArray.cellTemperatureAverage,
            value.cellTemperatureAverage,
          ]),
          cellVoltage000:
            averagedArray.cellVoltage000 < value.cellVoltage000
              ? averagedArray.cellVoltage000
              : value.cellVoltage000,
          cellVoltage025: _.mean([
            averagedArray.cellVoltage025,
            value.cellVoltage025,
          ]),
          cellVoltage075: _.mean([
            averagedArray.cellVoltage075,
            value.cellVoltage075,
          ]),
          cellVoltage100:
            averagedArray.cellVoltage100 > value.cellVoltage100
              ? averagedArray.cellVoltage100
              : value.cellVoltage100,
          cellVoltageAverage: _.mean([
            averagedArray.cellVoltageAverage,
            value.cellVoltageAverage,
          ]),
          currentAmps: _.mean([averagedArray.currentAmps, value.currentAmps]),
          id: `${value.arrayIndex}`,
          stringCount: averagedArray.stringCount + 1,
          connected:
            value.connected === false ? false : averagedArray.connected,
          connectedCount,
          inRotation:
            value.inRotation === false ? false : averagedArray.inRotation,
          inRotationCount,
        }

        result[index] = updatedArray
        return result
      } else {
        return [
          ...result,
          {
            ...value,
            id: `${value.arrayIndex}`,
            stringCount: 1,
            connectedCount: value.connected ? 1 : 0,
            inRotationCount: value.inRotation ? 1 : 0,
          },
        ]
      }
    },
    [],
  )

const formatByString = (d, minArray, maxArray) =>
  _.reduce(
    d,
    function (result, value) {
      if (value.arrayIndex < minArray || value.arrayIndex > maxArray) {
        return result
      } else
        return [
          ...result,
          {
            ...value,
            id: `${value.arrayIndex}::${value.stringIndex}`,
            stringCount: 1,
            connectedCount: value.connected ? 1 : 0,
            inRotationCount: value.inRotation ? 1 : 0,
          },
        ]
    },
    [],
  )

const filterData = (data, filters) => {
  const filteredData = _.reduce(
    filters,
    (result, f) => {
      if (f.omit) {
        return _.filter(result, (r) => f.filterBy(r))
      }
      return result
    },
    data,
  )
  return filteredData
}
