const compressedPadding = '7px'
const compressedTable = {
  tableWrapper: {
    style: {
      display: 'table',
    },
  },
  table: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  header: {
    style: {
      backgroundColor: 'transparent',
      minHeight: '27px',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#e7ecee',
      minHeight: '27px',
      paddingLeft: compressedPadding,
    },
  },
  headCells: {
    style: {
      backgroundColor: 'transparent',
      minHeight: '27px',
      fontSize: '10px',
      color: '#abaeb1',
      textTransform: 'uppercase',
      paddingLeft: compressedPadding,
      paddingRight: compressedPadding,
    },
    activeSortStyle: {
      color: '#5e5e5e',
    },
  },
  cells: {
    style: {
      paddingLeft: compressedPadding,
      paddingRight: compressedPadding,
    },
  },
  rows: {
    style: {
      backgroundColor: 'transparent',
      fontSize: '12px',
      fontWeight: 400,
      minHeight: '30px',
      paddingLeft: compressedPadding,
      '&:nth-child(2n + 2)': {
        backgroundColor: '#f5f5f5',
      },

      '&:last-child': {
        border: 'none',
      },

      '&:not(:last-of-type)': {
        borderBottomWidth: 0,
      },
      '&:first-child': {
        // override conditional styles for 1st string in string table array delineation
        borderTop: 'none !important',
      },
    },
  },
  pagination: {
    style: {
      backgroundColor: 'transparent',
    },
  },
}

const dashboardTable = {
  table: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  header: {
    style: {
      backgroundColor: 'transparent',
      minHeight: '27px',
      marginBottom: '50px',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#e7ecee',
      minHeight: '27px',
      padding: '0 35px',
      marginBottom: '5px',
    },
  },
  headCells: {
    style: {
      backgroundColor: 'transparent',
      minHeight: '27px',
      fontSize: '11px',
      color: '#abaeb1',
      textTransform: 'uppercase',
    },
    activeSortStyle: {
      color: '#5e5e5e',
    },
  },
  rows: {
    style: {
      backgroundColor: 'transparent',
      fontSize: '13px',
      fontWeight: 400,
      minHeight: '38px',
    },
  },
  pagination: {
    style: {
      backgroundColor: '#f7f7f9',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: '0px -2px 3px 0px rgba(0, 0, 0, 0.1);',
    },
  },
}

const fixedPaginationStyles = {
  pagination: {
    style: {
      backgroundColor: '#f7f7f9',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: '0px -2px 3px 0px rgba(0, 0, 0, 0.1);',
    },
  },
}

export { compressedTable, dashboardTable, fixedPaginationStyles }
