import { QueryCache, QueryClient } from '@tanstack/query-core'
import { handleError } from 'api/helpers'
import { redirect } from 'react-router'

// queryClient can be used to invalidate queries and more
// https://tanstack.com/query/v4/docs/react/reference/QueryClient
//   const { mutate, isPending, isError, error } = useMutation({
//     mutationFn: createNewEvent,
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ['events'] });
//       navigate('/events');
//     },
//   });  // this will force an update to all queries with key containing 'events'
const ccuiQueryClient = (clearSession) => {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        handleError(error, clearSession)
        if (error.response?.status === 401) {
          try {
            query.cancel()
            redirect('/')
          } catch (error) {
            console.log('query cancel error', error)
          }
        }
      },
    }),
    defaultOptions: {
      queries: {
        // refetchOnWindowFocus: false,
        staleTime: 5000,
        retry: (failureCount, error) => {
          // is invalid user no need to continue a retry status 401
          if (error.response.status === 401) {
            return false
          } else if (failureCount > 2) {
            return false
          } else {
            return true
          }
        },
      },
    },
  })
}

export default ccuiQueryClient
