import styled from 'styled-components/macro'

export const InfoMessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: auto;
  padding: 5px;
  font-weight: bold;
`

export const MessageTitle = styled.div`
  font-weight: 600;
  color: black;
`
