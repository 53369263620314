import useDetectClickOut from 'hooks/useDetectClickOut.hooks'
import * as Styles from './styles'

const TableControlDropdown = ({ icon, title, children }) => {
  const { show, nodeRef, triggerRef } = useDetectClickOut(false)

  return (
    <Styles.Container>
      {!show && (
        <Styles.ToggleButton $isOpen={show} ref={triggerRef}>
          <Styles.ButtonIcon>{icon}</Styles.ButtonIcon>
        </Styles.ToggleButton>
      )}
      {show && (
        <Styles.ModalContainer>
          <Styles.ModalToggleButton>
            <Styles.ButtonIcon>{icon}</Styles.ButtonIcon>
          </Styles.ModalToggleButton>
          <Styles.Modal ref={nodeRef}>
            <Styles.Header>{title}</Styles.Header>
            <Styles.Content>{children}</Styles.Content>
          </Styles.Modal>
        </Styles.ModalContainer>
      )}
    </Styles.Container>
  )
}

export default TableControlDropdown
