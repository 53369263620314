import { useState, useEffect } from 'react'
import _ from 'lodash'
import Highcharts from 'highcharts/highstock'
import heatmap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router-dom'
import { HeatMapWrapperDiv } from '../charts.styled'
import HeatMapsToolTipWrapper from '../HeatMapsToolTipWrapper'
import { COLORS, getPowinColorFromBasicColor } from 'design_system/colors'

const VoltageHeatmap = ({ data }) => {
  heatmap(Highcharts)

  const params = useParams()

  const [seriesData, setSeriesData] = useState([])
  const [chartData, setChartData] = useState([])
  // const [numBatteryPacks, setNumBatteryPacks] = useState()
  // const [numCells, setNumCells] = useState()

  useEffect(() => {
    if (data) {
      const formattedData = formatData(data)
      // console.log('formattedData', formattedData)
      setSeriesData(formattedData)
      // setNumBatteryPacks(formatData.length)
      // setNumCells(formattedData[0]?.cellGroups?.length)
    }
  }, [params, data])

  useEffect(() => {
    setChartData(formatChartData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData])

  useEffect(() => {
    setOptions({
      ...options,
      series: [
        {
          ...options.series,
          data: chartData,
        },
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  const formatData = (data) => {
    return _.chain(data)
      .get('batteryPacks')
      .keys()
      .map((key) => ({
        ...data.batteryPacks[key],
        batteryPackId: _.toNumber(key),
        cellGroups: Object.keys(data.batteryPacks[key].cellGroups).map(
          (cell) => ({
            cellGroupId: _.toNumber(cell),
            ...data.batteryPacks[key].cellGroups[cell],
          }),
        ),
      }))
      .value()
  }

  const formatChartData = () => {
    const bpArray = _.map(seriesData, (bp) => {
      return _.map(bp.cellGroups, (cg) => {
        return {
          x: cg.cellGroupId,
          y: bp.batteryPackId,
          value: cg.milliVolts,
          color: getPowinColorFromBasicColor(cg.milliVoltsStatus),
        }
      })
    })

    return _.flatten(bpArray)
  }

  const [options, setOptions] = useState({
    accessibility: {
      enabled: false,
    },
    chart: {
      type: 'heatmap',
    },
    title: { text: undefined },
    credits: { enabled: false },
    legend: { enabled: false },
    yAxis: {
      title: {
        text: 'Battery Pack',
        style: {
          fontWeight: 600,
          color: COLORS.stringHeatMaps.chartLegendGrey,
        },
      },
      labels: {
        style: {
          fontSize: '10px',
        },
      },
      tickInterval: 1,
      reversed: true,
    },
    xAxis: {
      title: {
        text: 'Cell Group',
        style: {
          fontWeight: 600,
          color: COLORS.stringHeatMaps.chartLegendGrey,
        },
      },
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return HeatMapsToolTipWrapper(this, 'Voltage')
      },
    },
    series: {
      name: 'VoltageHeatMapSeries',
      borderWidth: 1,
      borderColor: '#fff',
      data: chartData,
      dataLabels: {
        enabled: false,
      },
    },
  })

  return (
    <HeatMapWrapperDiv>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </HeatMapWrapperDiv>
  )
}

export default VoltageHeatmap
