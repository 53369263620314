import { useContext, useReducer, useState } from 'react'
import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { useParams } from 'react-router-dom'
import { RowRotationWithTooltip, useIsStationConnected } from '../tableUtils'
import { hardwareIdSort } from 'utils/sort'
import Tooltip from 'components/Tooltip'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { ClearDeviceAlertsModal } from '../../Commands/ClearDeviceAlerts/ClearDeviceAlertsModal'
import { ELECTRICAL_DEVICE_TYPES } from '../../ElectricalDevices/constants'
import { COLORS } from 'design_system/colors'
import Icon from 'components/Icon/Icon'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as LightningIcon } from 'icons/lightning-icon.svg'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'
import { SetPvPcsRotationModal } from '../../Commands/PvPcsRotation/SetPvPcsRotationModal'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'

const getRowId = (row) => `${row.acPvBatteryIndex}::${row.pvPcsIndex}`

const idsToObject = (ids) =>
  ids.map((id) => {
    const [acPvBatteryIndex, pvPcsIndex] = id.split('::')
    return { acPvBatteryIndex, pvPcsIndex }
  })

/////////////////  ACTION MODALS /////////////////////////////////
// see Inverter table for example to add actions and row selections
/////////////////////////////////////////////////////////////////

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showPvPcsRotationModal': {
      return () => (
        <SetPvPcsRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showClearFaultsModal': {
      return () => (
        <ClearDeviceAlertsModal
          setShowCommandModal={action.setShowCommandModal}
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          faultingDevices={action.faultingDevices}
          deviceType={action.deviceType}
        />
      )
    }
    default:
      return null
  }
}

const PvPcsTable = ({ data, fixedHeader = true }) => {
  const { stationCode, blockIndex } = useParams()
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [selectedIds, setSelectedIds] = useState([])

  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  const isPvPcsRotationEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.PV_PCS_ROTATION_COMMAND,
  )

  const baseUrl = `/block-details/${stationCode}/${blockIndex}/block-topology`

  const handleSelectedRowsChange = (rowsObject) => {
    setSelectedIds(
      Object.keys(rowsObject).filter((key) => rowsObject[key]), //returns array of ids where key value is true
    )
  }

  const pvPcsId = (row) => {
    const { acPvBatteryIndex, pvPcsIndex } = row
    return (
      <Styles.RowLink
        to={`${baseUrl}/ac-pv-battery/${acPvBatteryIndex}/pv-pcs/${pvPcsIndex}`}
      >
        {`${acPvBatteryIndex} :: ${pvPcsIndex}`}
      </Styles.RowLink>
    )
  }

  const rowHasFaults = (row) => {
    return Boolean(row?.faults?.length)
  }

  const rowIsHealthy = (row) => {
    return row.healthy && !rowHasFaults(row)
  }

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit:
        !isStationConnected || !userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC),
      width: '55px',
      cell: (row) => (
        <ActionsMenu key={row} onOpen={() => null} onClose={() => null}>
          <ActionsMenuModalContent
            rowId={getRowId(row)}
            setShowCommandModal={modalDispatch}
            stationCode={stationCode}
            blockIndex={blockIndex}
            resetableFaults={
              row.topologyEntry?.allowFaultReset ? row.faults : []
            }
            isEnabled={isPvPcsRotationEnabled}
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      sortFunction: (a, b) => hardwareIdSort(getRowId(a), getRowId(b)),
      width: '60px',
      noOmit: true,
      cell: pvPcsId,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.healthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => {
        const isHealthy = rowIsHealthy(row)
        return (
          <Tooltip
            title={
              <>
                {isHealthy ? 'Healthy' : 'Unhealthy'}
                {rowHasFaults(row) && ' | Faulted'}
              </>
            }
          >
            <Styles.StatusIcon $isHealthy={isHealthy}>
              {isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
            </Styles.StatusIcon>
          </Tooltip>
        )
      },
    },
    {
      name: 'IN ROT.',
      id: 'rotation',
      selector: (row) => row.outRotation,
      sortable: true,
      width: '70px',
      cell: (row) => RowRotationWithTooltip(row.outRotation),
      noOmit: false,
      omit: false,
    },
    {
      name: 'Faults',
      id: 'faults',
      omit: false,
      selector: (row) => row?.faults?.length ?? 0,
      sortable: true,
      width: '70px',
      cell: (row) =>
        rowHasFaults(row) && (
          <Tooltip title={row?.topologyEntry?.statusMessage ?? 'N/A'}>
            <Styles.AlertIcon />
          </Tooltip>
        ),
    },
    {
      name: 'DC',
      id: 'dc',
      selector: (row) => row.dcVoltageVolt,
      sortable: true,
      width: '120px',
      cell: (row) => `${row.dcVoltageVolt} V / ${row.dcCurrentAmp}  A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PV DC',
      id: 'pvdc',
      selector: (row) => row.pvDcVoltageVolt,
      sortable: true,
      width: '90px',
      cell: (row) => `${row.pvDcVoltageVolt} V`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC A > N',
      id: 'acan',
      selector: (row) => row.acPhaseANVoltageVolt,
      sortable: true,
      width: '120px',
      cell: (row) =>
        `${row.acPhaseANVoltageVolt} V / ${row.acPhaseACurrentAmp}  A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC B > N',
      id: 'acbn',
      selector: (row) => row.acPhaseBNVoltageVolt,
      sortable: true,
      width: '120px',
      cell: (row) =>
        `${row.acPhaseBNVoltageVolt} V / ${row.acPhaseBCurrentAmp}  A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC C > N',
      id: 'acVoltageVolt',
      selector: (row) => row.acPhaseCNVoltageVolt,
      sortable: true,
      width: '120px',
      cell: (row) =>
        `${row.acPhaseCNVoltageVolt} V / ${row.acPhaseCCurrentAmp}  A`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC CURTAIL LIMIT CMD',
      id: 'acCurtailLimitCmd',
      selector: (row) => row.acCmdRealPowerLimitKW,
      sortable: true,
      width: '140px',
      cell: (row) => row.acCmdRealPowerLimitKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC CURTAIL LIMIT SET',
      id: 'acCurtailLimitSet',
      selector: (row) => row.acRealPowerSettingLimitKW,
      sortable: true,
      width: '135px',
      cell: (row) => row.acRealPowerSettingLimitKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC CMD',
      id: 'acCmd',
      selector: (row) => row.acCmdReactivePowerKVAR,
      sortable: true,
      width: '90px',
      cell: (row) => row.acCmdReactivePowerKVAR + ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC SET',
      id: 'acSet',
      selector: (row) => row.acReactivePowerSettingKVAR,
      sortable: true,
      width: '90px',
      cell: (row) => row.acReactivePowerSettingKVAR + ' kVAr',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AC ACTUAL',
      id: 'acActual',
      selector: (row) => row.acRealPowerKW,
      sortable: true,
      width: '145px',
      cell: (row) =>
        `${row.acRealPowerKW} kw / ${row.acReactivePowerKVAR}  kVAr`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'PV ACTUAL',
      id: 'pvActual',
      selector: (row) => row.pvRealPowerKW,
      sortable: true,
      width: '85px',
      cell: (row) => row.pvRealPowerKW + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'FREQUENCY',
      id: 'freq',
      selector: (row) => row.acFrequencyHz,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.acFrequencyHz, ' Hz', 2),
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  return (
    <>
      <Table
        tableId="PvPcsTable"
        data={data}
        columns={columns}
        fixedHeader={fixedHeader}
        getRowId={getRowId}
        defaultId="id"
        onSelectedRowsChange={(e) => handleSelectedRowsChange(e)}
        tableActions={TableActions(
          isPvPcsRotationEnabled,
          isStationConnected,
          userHas8PC,
          selectedIds,
          modalDispatch,
        )}
        selectableRows={isPvPcsRotationEnabled}
      />
      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

const TableActions = (
  isPvPcsRotationEnabled,
  isStationConnected,
  userHas8PC,
  selectedIds,
  modalDispatch,
) => {
  return (
    Boolean(isStationConnected && userHas8PC && isPvPcsRotationEnabled) && (
      <Styles.ButtonContainer>
        <FormCommandButton
          disabled={selectedIds.length === 0}
          onClick={() => {
            modalDispatch({
              type: 'showPvPcsRotationModal',
              setShowCommandModal: modalDispatch,
              idsForModal: idsToObject(selectedIds),
            })
          }}
        >
          <Icon
            size="xxs"
            color={
              selectedIds.length === 0
                ? COLORS.disabled_action
                : COLORS.action_blue
            }
            icon={<CheckIcon />}
          />
          Set PV PCS Rotation
        </FormCommandButton>
      </Styles.ButtonContainer>
    )
  )
}

const ActionsMenuModalContent = ({
  rowId,
  setShowCommandModal,
  stationCode,
  blockIndex,
  resetableFaults,
  isEnabled,
}) => {
  const actionClickHandler = (payload) => {
    setShowCommandModal(null)
    setShowCommandModal(payload)
  }

  const id = idsToObject([rowId])

  return (
    <div key={rowId.toString()} id={rowId.toString()}>
      <Styles.ActionModalSection>
        {isEnabled && (
          <ActionsMenuItem
            onClick={() =>
              actionClickHandler({
                type: 'showPvPcsRotationModal',
                setShowCommandModal: setShowCommandModal,
                idsForModal: id,
              })
            }
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
        )}
        <ActionsMenuItem
          onClick={() =>
            actionClickHandler({
              type: 'showClearFaultsModal',
              deviceType: ELECTRICAL_DEVICE_TYPES['PV PCSes'],
              stationCode: stationCode,
              blockIndex: blockIndex,
              faultingDevices: resetableFaults,
              setShowCommandModal: setShowCommandModal,
            })
          }
          disabled={!resetableFaults?.length}
        >
          <Styles.MenuIcon>
            <Icon
              size="xxs"
              color={
                resetableFaults?.length
                  ? COLORS.action_blue
                  : COLORS.font_secondary
              }
              icon={<LightningIcon />}
            />
          </Styles.MenuIcon>
          <div>Clear Faults</div>
        </ActionsMenuItem>
      </Styles.ActionModalSection>
    </div>
  )
}

export default PvPcsTable
