import { COLORS, getPowinColorFromBasicColor } from 'design_system/colors'

const HeatMapsToolTipWrapper = (classThis, chartType) => {
  const _val = classThis.point.value

  let line2
  switch (chartType) {
    case 'Voltage':
      line2 =
        '<div style="width: 100%;">Measured: ' +
        _val.toLocaleString() +
        ' mV</div>'
      break
    case 'Temperature':
      line2 = '<div>Measured: ' + _val + '°</div>'
      break
    case 'Notifications': {
      let finalNotificationText
      if (_val.length > 0)
        finalNotificationText = _val
          .sort(notificationSort)
          .map((v) => {
            const { level, notificationCategory, notificationName } = v
            const textColor =
              notificationCategory === 'WARNING'
                ? COLORS.stringHeatMaps.chartWarningText
                : getPowinColorFromBasicColor(notificationCategory)
            return `<div><span style="color: ${textColor};">${level} ${titleCase(notificationCategory)} - </span>${notificationName}</div>`
          })
          .join('')

      line2 = finalNotificationText || '<div>No Notifications</div>'
      break
    }

    default:
      throw new Error('incorrect chart type provided ' + chartType)
  }

  return `
      <div style="display: flex; flex-direction: column; gap: 6px; font-weight: 600;">
      <div style="color: grey; display: block;">Battery Pack ${classThis.point.y}&nbsp;&nbsp;|&nbsp;&nbsp;Cell Group ${classThis.point.x}</div>
      ${line2}
      </div>
  `
}
export default HeatMapsToolTipWrapper

// cg first
const notificationSort = (a, b) => {
  if (a.level > b.level) return -1
  else if (a.level < b.level) return 1

  if (a.notificationCategory < b.notificationCategory) return -1
  else if (a.notificationCategory > b.notificationCategory) return 1

  return 0
}

const titleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())
}
