import CommandModalTemplate from '../CommandModalTemplate'
import { ClearEnclosureFaultsUi } from './ClearEnclosureFaultsUi'
import { ClearEnclosureFaultsResult } from './ClearEnclosureFaultsResult'

const modalTitles = {
  start: 'Clear Faults',
  finish: 'Clear Faults',
}
const queryName = 'clearEnclosureFaults'

export const ClearEnclosureFaultsModal = ({
  target,
  enclosureType,
  enclosureIndex,
  setShowCommandModal,
}) => {
  const commandResult = () => (
    <ClearEnclosureFaultsResult setShowCommandModal={setShowCommandModal} />
  )

  const userInputForm = () => (
    <ClearEnclosureFaultsUi
      enclosureIndex={enclosureIndex}
      enclosureType={enclosureType}
    />
  )

  return (
    <CommandModalTemplate
      ids={{ enclosureIndex }}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      CommandResultJsx={commandResult}
      UserInputFormJsx={userInputForm}
      queryName={queryName}
      additionalPayload={{ target }}
    />
  )
}
