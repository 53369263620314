import { COLORS } from 'design_system/colors'
import { FONT_WEIGHT } from 'design_system/font_definitions'
import { FONT_SIZES } from 'design_system/sizes'

import styled from 'styled-components/macro'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.p`
  color: ${COLORS.font_secondary};
  margin: 0;
`
export const DeviceList = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
`
export const DeviceListHeader = styled.div`
  background: ${COLORS.modal_table_header_bg};
  color: ${COLORS.column_header_font_color};
  padding: 3px 6px;
  font-weight: ${FONT_WEIGHT.strong};
  font-size: ${FONT_SIZES.small};
  overflow: hidden;
`

export const DeviceListRow = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 4px 4px;
`

export const DeviceListBody = styled.div`
  & ${DeviceListRow}:nth-child(even) {
    background: #eee;
  }
`

export const DeviceListItem = styled.p`
  flex: 1;
  margin: 0;
  padding: 0 4px;
`
