import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`
export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
export const ModalToggleButton = styled(ToggleButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  border: 1px solid #ccd5dd;
  border-bottom: none;
  background-color: #fff;
`
export const ButtonIcon = styled.div`
  width: 12px;
  height: 12px;
  fill: #30bf91;
`
export const ModalContainer = styled.div`
  position: absolute;
  z-index: 500;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
`
export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 29px;
  right: 0;
  z-index: 1;
  width: 225px;
  max-height: 50vh;
  box-shadow: 0px 2px 2px 0px rgba(90, 90, 90, 0.5);
  border: 1px solid rgb(204, 213, 221);
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`
export const Header = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccd5dd;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`
export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px 10px;
  font-size: 13px;

  input {
    margin-right: 5px;
  }
`
