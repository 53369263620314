/**
 *
 * @param {object} tableRef useRef of table
 * @returns string number+'px'
 */
export const tableResizer = (tableRef) => {
  const getNewHeight = () => {
    if (tableRef?.current) {
      const boundingRect = tableRef.current.getBoundingClientRect()
      const { height } = boundingRect
      const tableContentHeight = height - 30
      return tableContentHeight + 'px'
    }
  }

  return getNewHeight()
}
