export const COLORS = {
  column_header_font_color: '#A9A9A9',
  action_blue: '#40A3D5',
  powin_green: '#00BF91',
  powin_red: '#F05A47',
  warning_amber: '#BA6800',
  modal_table_header_bg: '#e7ecee',
  font_primary: '#282929',
  font_secondary: '#909090',
  grey: '#cfd0d0',
  light_grey: '#ececec',
  disabled_action: '#ccc',
  sev3WarningYellow: '#e7c906',
  //cell group map HeatMaps
  stringHeatMaps: {
    chartSoftGreen: '#cfefe3',
    chartSoftRed: '#f05757',
    chartWarning: '#e6da4f',
    chartWarningText: '#bcae07',
    chartLegendGrey: '#808080',
  },
  charts: {
    orange: '#f4a163',
    blue: '#707bee',
    green: '#7bc0af',
    baby_blue: '#41d2e5',
    light_blue: '#30a7c9',
    pink: '#e86cf0',
    dark_red: '#ca1c01',
    dark_yellow: '#cba43b',
    brink_pink: '#f86379',
    shamrock_green: '#34cc93',
  },
}

/**
 * maps colors like 'GREEN' to correct chart color
 * can also map WARNING AND ALARM
 * used by Cell Group HeatMaps
 */
export const getPowinColorFromBasicColor = (basicColor) => {
  switch (basicColor) {
    case 'GREEN':
    case 'NONE':
      return COLORS.stringHeatMaps.chartSoftGreen
    case 'YELLOW':
    case 'WARNING':
      return COLORS.stringHeatMaps.chartWarning
    case 'RED':
    case 'ALARM':
      return COLORS.stringHeatMaps.chartSoftRed
    case 'UNKNOWN':
      return COLORS.stringHeatMaps.grey
    default:
      throw new Error(basicColor + ' has not been configured in our color map')
  }
}
