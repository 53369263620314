import AppTooltip from 'components/AppTooltip/AppTooltip'

// Component Imports
import Icon from '../../Icon/Icon'

// Style Imports
import * as Styles from './styles'
import { COLORS } from 'design_system/colors'

// Icon Imports
import { ReactComponent as ConnectedIcon } from '../../../icons/status-connected-icon.svg'
import { ReactComponent as DisconnectedIcon } from '../../../icons/status-disconnected-icon.svg'
import { ReactComponent as ConnectWarningIcon } from '../../../icons/status-connect-warning-icon.svg'

const SensorStatus = ({
  isConnected,
  lostConnectionReason = 'Not Communicating',
  showWarning = false,
  id,
  size = 'sm',
}) => {
  const connectionIcon = (isConnected, showWarning) => {
    if (isConnected && !showWarning) return <ConnectedIcon />
    if (showWarning) return <ConnectWarningIcon />
    return <DisconnectedIcon />
  }

  return (
    <Styles.Container data-tooltip-id={`connectionStatus-${id}`}>
      <Icon
        size={size}
        color={isConnected ? COLORS.powin_green : COLORS.powin_red}
        icon={connectionIcon(isConnected, showWarning)}
      />
      <AppTooltip id={`connectionStatus-${id}`}>
        {isConnected && !showWarning ? 'Communicating' : lostConnectionReason}
      </AppTooltip>
    </Styles.Container>
  )
}

export default SensorStatus
