import { ReactComponent as InverterIcon } from 'icons/dc-ac-pcs-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'

const ArrayPcsListSummary = ({ data }) => {
  const count = data.length

  const inRotationCount = data.filter((p) => !p.outRotation).length
  const readyCount = data.filter((p) => p.ready).length
  const healthyCount = data.filter(
    (p) => p.valid && p.ready && !p.faults.length,
  ).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `PCSes`,
    titleIcon: <InverterIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy PCSes: ${healthyCount} | Unhealthy PCSes: ${unhealthyCount}`}
        id="inverterHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'In Rotation',
            value: inRotationCount.toLocaleString(),
          },
          {
            label: 'Ready',
            value: readyCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ArrayPcsListSummary
