import { fetchBlockStatus } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import axios from 'axios'

/**
 * Will use params to load correct station and block status
 * @returns 5 second interval block status
 */
const useBlockStatusByStationAndBlock = (stationCode, blockIndex) => {
  const { session, setSession } = useContext(SessionContext)
  if (!stationCode || !blockIndex)
    throw new Error(
      'useBlockStatusByStationAndBlock requires station and blockId params',
    )
  return useQuery(
    ['blockStatus', stationCode, blockIndex],
    ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })
      return fetchBlockStatus(
        stationCode,
        blockIndex,
        session,
        setSession,
        source,
      )
    },
    { refetchInterval: 5000 },
  )
}

export default useBlockStatusByStationAndBlock
