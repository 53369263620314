import { formatNumber } from 'utils/formatting'
import { kilowatt_to_megawatt } from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function PowerCurtailmentAndRampRateTable({ data }) {
  const tableData = [
    {
      name: 'Total Real Power',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.totalRealPowerCmdkW),
        precision: 2,
        unit: ' MW',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.totalRealPowerSettingkW),
        precision: 2,
        unit: ' MW',
      }),
    },
    {
      name: 'Ramp Rate Up',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateUpCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateUpSettingkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
    },
    {
      name: 'Ramp Rate Down',
      operation: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateDownCmdkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
      status: formatNumber({
        value: kilowatt_to_megawatt(data?.rampRateDownSettingkWperMin),
        precision: 2,
        unit: ' MW / min',
      }),
    },
  ]

  return (
    <StatusAndOperationTable
      tableId="EksPpcPowerCurtailmentAndRampRate"
      data={tableData}
    />
  )
}
