import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as ACPVBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import { numSumRounder } from 'modules/Station/components/Tables/tableUtils'

const ACPVBatterySummary = ({ data }) => {
  if (!data) return null
  const count = data.length
  const healthyCount = data.filter((p) => p.isHealthy).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `AC PV Batteries`,
    titleIcon: <ACPVBatteryIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy AC PV Batteries: ${healthyCount} | Unhealthy AC PV Batteries: ${unhealthyCount}`}
        id="dcDcPCSHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Ready',
            value: data.filter((d) => d.topologyEntry.ready).length,
          },
          {
            label: 'Enabled',
            value: data.filter((d) => d.topologyEntry.enabled).length,
          },

          {
            label: 'Total',
            value: count,
          },
        ],
      },
      {
        sectionTitle: 'ESS AVAILABILITY',
        rows: [
          {
            label: 'Charge Power',
            value: numSumRounder(data, 'maxAvailableChargePowerKW', 'kW'),
          },
          {
            label: 'Discharge Power',
            value: numSumRounder(data, 'maxAvailableDischargePowerKW', 'kW'),
          },
        ],
      },
      {
        sectionTitle: 'ESS ENERGY',
        rows: [
          {
            label: 'Online Available',
            value: numSumRounder(data, 'onlineAvailableKWH', 'kWh'),
          },
          {
            label: 'Total Capacity',
            value: numSumRounder(data, 'totalCapacityKWH', 'kWh'),
          },
        ],
      },
      {
        sectionTitle: 'AC REAL POWER',
        rows: [
          {
            label: 'Measured',
            value: numSumRounder(data, 'measuredACkW', 'kW'),
          },
          {
            label: 'AC Curtailment Limit',
            value: numSumRounder(data, 'curtailmentLimitACkW', 'kW'),
          },
        ],
      },
      {
        sectionTitle: 'REACTIVE POWER',
        rows: [
          {
            label: 'Measured',
            value: numSumRounder(data, 'measuredACKVAr', 'kVAr'),
          },
          {
            label: 'Commanded',
            value: numSumRounder(data, 'commandedACKVAr', 'kVAr'),
          },
        ],
      },
      {
        sectionTitle: 'DC POWER',
        rows: [
          {
            label: 'Measured',
            value: numSumRounder(data, 'measuredDCkW', 'kW'),
          },
          {
            label: 'Commanded',
            value: numSumRounder(data, 'commandedDCkW', 'kW'),
          },
        ],
      },
      {
        sectionTitle: 'STRINGS',
        rows: [
          {
            label: 'Online',
            value: data
              .reduce((p, n) => p + n.onlineStackCount, 0)
              .toLocaleString(),
          },
          {
            label: 'Total',
            value: data
              .reduce((p, n) => p + n.totalStackCount, 0)
              .toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ACPVBatterySummary
