import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import DcDcPcsDetailsSummary from './Summary/DcDcPcsDetailsSummary'
import { DcDcPcsDetailsMainColumn } from './MainColumn/DcDcPcsDetailsMainColumn'
import useDcDcPcsDetailsQuery from 'api/useQueryHooks/useDcDCPcsDetailsQuery'

export const DcDcPcsDetail = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const {
    stationCode,
    blockIndex,
    acPvBatteryIndex,
    dcDcGroupIndex,
    dcDcPcsIndex,
  } = params

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - DC:DC PCS ${acPvBatteryIndex}::${dcDcGroupIndex}::${dcDcPcsIndex}`
    setSelectedParams({ ...params, listView: 'dc-dc-pcs-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DetailsPageJsx = (data) => {
    return (
      <DetailsPage
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
        setSelectedPage={setSelectedPage}
        summaryColumnComponent={<DcDcPcsDetailsSummary data={data} />}
        mainColumnComponent={
          <DcDcPcsDetailsMainColumn acPvBatteryIndex={acPvBatteryIndex} />
        }
      />
    )
  }
  return handleQueryLoadingAndError(
    useDcDcPcsDetailsQuery(acPvBatteryIndex, dcDcGroupIndex, dcDcPcsIndex),
    DetailsPageJsx,
  )
}
