import { useState, useEffect, useReducer, useContext } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import * as Styles from './styles'

// Component Imports
import Card from 'components/Cards/Card/Card'
import StringTempDistribution from 'modules/Station/components/Charts/StringTempDistribution/StringTEmpDistribution'
import StringVoltDistribution from 'modules/Station/components/Charts/StringVoltDistribution/StringVoltageDistribution'
import StringListCard from 'modules/Station/components/Cards/StringListCard/StringListCard'
import {
  FormCommandButton,
  FormRow,
} from 'components/Form/FormComponents.styled'
import Icon from 'components/Icon/Icon'
import { SetArrayContactorModal } from 'modules/Station/components/Commands/ArrayContactors/SetArrayContactorModal'
import { SetArrayRotationModal } from 'modules/Station/components/Commands/ArrayRotation/SetArrayRotationModal'
import { SetPreferredCurrentModal } from '../../../../components/Commands/PreferredCurrent/SetPreferredCurrentModal'

//Icon Imports
import { ReactComponent as ContactorsIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as BalancingIcon } from 'icons/balancing-icon.svg'
import { ReactComponent as PreferredCurrentIcon } from 'icons/visualization-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

// Util Imports
import { formatByString } from 'utils/charts.utils'
import { SetArrayBalancingModal } from 'modules/Station/components/Commands/Balancing/ArrayBalancing/SetArrayBalancingModal'
import { useIsStationConnected } from 'modules/Station/components/Tables/tableUtils'
import useCellDistributionQuery from 'api/useQueryHooks/useCellDistributionQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useArrayByIdQuery from 'api/useQueryHooks/useArrayByIdQuery'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */

const modalReducer = (state, action) => {
  switch (action?.type) {
    case 'showContactorsModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayContactorModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showBalancingModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayBalancingModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
          data={action.data}
        />
      )
    }
    case 'showPreferredCurrentModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetPreferredCurrentModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

/////////////////////////////////////////////////////////////////

const ArrayDetailsMainColumn = () => {
  const params = useParams()
  const { stationCode, blockIndex, arrayId } = params
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [showCommandModal, setShowCommandModal] = useState(false)

  const { userHasApiAccessCode } = useContext(AuthorizationContext)
  const userHas7SC = userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  const { data: cellData, isLoading } = useCellDistributionQuery()
  const { data, isLoading: isArrayLoading } = useArrayByIdQuery(arrayId)
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)

  if (isLoading || isArrayLoading) return <LoadingPage />

  const arrayCellData = _.chain(cellData)
    .get('stringCellDistributions')
    .filter({ arrayIndex: parseInt(arrayId) })
    .value()
  const cellDistributionData = formatByString(arrayCellData, 0, 100, data)

  return (
    <Styles.Container>
      {isStationConnected && userHas7SC && (
        <FormRow $isRow={true}>
          <FormCommandButton
            onClick={() => {
              modalDispatch({
                type: 'showContactorsModal',
                setShowCommandModal: setShowCommandModal,
                open: true,
                idsForModal: [params.arrayId],
              })
            }}
          >
            <Icon
              size="sm"
              color={COLORS.action_blue}
              icon={<ContactorsIcon />}
            />{' '}
            Set Contactors
          </FormCommandButton>
          <FormCommandButton
            onClick={() => {
              modalDispatch({
                type: 'showRotationModal',
                setShowCommandModal: setShowCommandModal,
                open: true,
                idsForModal: [params.arrayId],
              })
            }}
          >
            <Icon size="xxs" color={COLORS.action_blue} icon={<CheckIcon />} />{' '}
            Set Rotation
          </FormCommandButton>
          <FormCommandButton
            onClick={() => {
              modalDispatch({
                type: 'showBalancingModal',
                setShowCommandModal: setShowCommandModal,
                open: true,
                idsForModal: [params.arrayId],
                data: data,
              })
            }}
          >
            <Icon
              size="xxs"
              color={COLORS.action_blue}
              icon={<BalancingIcon />}
            />
            Set Balancing
          </FormCommandButton>
          <FormCommandButton
            onClick={() => {
              modalDispatch({
                type: 'showPreferredCurrentModal',
                setShowCommandModal: setShowCommandModal,
                open: true,
                idsForModal: [params.arrayId],
              })
            }}
          >
            <Icon
              size="xxs"
              color={COLORS.action_blue}
              icon={<PreferredCurrentIcon />}
            />
            Set Preferred Current
          </FormCommandButton>
        </FormRow>
      )}

      <Styles.Row>
        <Styles.Spacer>
          <Card title={'Temperature Distribution'} height="250px">
            <StringTempDistribution data={cellDistributionData} />
          </Card>
        </Styles.Spacer>
        <Styles.Spacer>
          <Card title={'Voltage Distribution'} height="250px">
            <StringVoltDistribution data={cellDistributionData} />
          </Card>
        </Styles.Spacer>
      </Styles.Row>
      <StringListCard data={data} />
      {ActionModalStateJsx && <ActionModalStateJsx />}
    </Styles.Container>
  )
}

export default ArrayDetailsMainColumn
