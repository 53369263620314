import styled from 'styled-components/macro'

export const Container = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const Background = styled.div`
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 100);
  cursor: default;
  opacity: ${(props) => (props.$isOpen ? 0.4 : 0)};
  transition:
    visibility 0.25s,
    opacity 0.25s ease-in-out;
  pointer-events: auto;
`

/**
 * props.width optional
 */
export const ModalContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  width: ${({ $width }) => $width || '400px'};
  min-width: 350px;
  margin: 100px auto 0;
  left: 0;
  right: 0;
  cursor: default;
  z-index: 999;
  pointer-events: auto;
`
export const TitleRow = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccd5dd;
`
export const FilterNameInput = styled.div`
  flex-direction: column;
  display: flex;
  padding: 20px;
`
export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid #ccd5dd;
`
export const Title = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 17px;
`
export const CloseButton = styled.div`
  cursor: pointer;
  fill: #ccd5dd;
  width: 15px;
  height: 11px;
`

export const ScrollableContent = styled.div`
  max-height: ${({ $scrollableFooter }) =>
    $scrollableFooter ? '80vh' : '50vh'};
  overflow-y: auto;
`

export const Content = styled.div`
  font-size: 13px;
  flex: 1;
  padding: 20px;
  label {
    color: #888888;
    text-transform: uppercase;
    padding-right: 40px;
  }
  input,
  select {
    font-weight: 600;
    width: 100%;
    height: 40px;
    border: solid #cccccc 1px;
    border-radius: 5px;
  }
  span {
    font-weight: 600;
  }
  button {
    text-transform: uppercase;
  }
`
