import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import EnvironmentalDevicesTable from './EnvironmentalDevicesTable/EnvironmentalDevicesTable'

const EnvironmentalDevices = () => {
  return (
    <ExpandableCard
      title="ENVIRONMENTAL DEVICES"
      isExpanded={true}
      minimal={true}
      className="envDevices"
    >
      <EnvironmentalDevicesTable />
    </ExpandableCard>
  )
}

export default EnvironmentalDevices
