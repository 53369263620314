import _ from 'lodash'

import { ReactComponent as ArrayIcon } from 'icons/array-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const ArrayListSummary = () => {
  const { data: blockData, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  const arrays = _.chain(blockData).get('arrays').toArray().value()
  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(
    blockData.isDcCoupled,
  )
  const count = arrays.length
  const healthyCount = _.filter(arrays, { valid: true }).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `Arrays`,
    titleIcon: <ArrayIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy Arrays: ${healthyCount} | Unhealthy Arrays: ${unhealthyCount}`}
        id="arrayHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Communicating',
            value: _.filter(arrays, { valid: true }).length.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
      {
        sectionTitle: 'Availability',
        rows: [
          {
            label: 'Online Energy',
            value: `${_.chain(blockData)
              .get(`${blockPowerAndEnergyKey}.onlineAvailableKWH`, 'N/A')
              .round()
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'Energy Capacity',
            value: `${_.chain(blockData)
              .get(`${blockPowerAndEnergyKey}.totalCapacityKWH`, 'N/A')
              .round()
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'DC Charge Power',
            value: `${_.chain(blockData)
              .get(`${blockPowerAndEnergyKey}.maxChargePowerKW`, 'N/A')
              .round()
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'DC Discharge Power',
            value: `${_.chain(blockData)
              .get(`${blockPowerAndEnergyKey}.maxDischargePowerKW`, 'N/A')
              .round()
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'Online SoC',
            value: `${_.chain(blockData)
              .get(`${blockPowerAndEnergyKey}.onlineSocPercent`, 'N/A')
              .round()
              .value()
              .toLocaleString()}%`,
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ArrayListSummary
