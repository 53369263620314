import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import axios from 'axios'
import { useParams } from 'react-router-dom'

/**
 * @param {string} date
 */
const useAvailableExtractReports = (date) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)
  return useQuery(
    ['availableExtractReports', stationCode, blockIndex, date],
    async () => {
      const apiUrl = session.server

      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
        params: {
          date: date,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/extracts/list`,
        axiosOptions,
      )

      return response.data
    },
    {
      enabled: !!(stationCode && blockIndex && date),
      refetchOnWindowFocus: false,
    },
  )
}

export default useAvailableExtractReports
