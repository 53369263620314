import { useState, useContext } from 'react'

import * as Styles from './ExecuteRemoteCommand.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import {
  FormInput,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { COLORS } from 'design_system/colors'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { SessionContext } from 'contexts/session'
import { executeRemoteCommand, fetchSystemIndexes } from 'api/queries.api'

export const ExecuteRemoteCommand = () => {
  const { session } = useContext(SessionContext)
  const { data, isLoading } = useStationsDataQuery()

  const [selectedStation, setSelectedStation] = useState(['Select One'])
  const [selectedSystemType, setSelectedSystemType] = useState('')
  const [availableSystemTypes, setAvailableSystemTypes] = useState([])
  const [selectedIndex, setSelectedIndex] = useState('')
  const [availableIndexes, setAvailableIndexes] = useState([])
  const [systemTypeIndexes, setSystemTypeIndexes] = useState([])
  const [directoryValue, setDirectoryValue] = useState('')
  const [isDirectoryValid, setIsDirectoryValid] = useState(false)
  const [commandValue, setCommandValue] = useState('')
  const [isCommandValid, setIsCommandValid] = useState(false)
  const [timeoutValue, setTimeoutValue] = useState(30)
  const [isTimeoutValid, setIsTimeoutValid] = useState(true)
  const [response, setResponse] = useState('')
  const [apartSetup, setApartSetup] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  if (isLoading) return <LoadingPage />

  const fetchIndexesHandler = async (station = null, blockIndex = null) => {
    const response = await fetchSystemIndexes(
      session,
      station ?? selectedStation[1],
      blockIndex ?? selectedStation[2],
    )
    if (
      response.localControllerIndexes.length === 0 ||
      response.environmentalControllerIndexes.length === 0
    ) {
      setApartSetup(false)
      setSelectedIndex('')
      setSelectedSystemType('Dragon')
      return
    }
    setApartSetup(true)
    setSelectedIndex('')
    setAvailableIndexes(response)
    setSelectedSystemType('Select One')
    setAvailableSystemTypes(['Dragon', 'Feather', 'Phoenix'])
  }

  const setSelectedStationHandler = (station, blockIndex, valid) => {
    if (!valid) return

    fetchIndexesHandler(station, blockIndex)

    setSelectedStation([station + ' :: ' + blockIndex, station, blockIndex])
  }

  const setSelectedSystemTypeHandler = (type) => {
    setSelectedSystemType(type)
    if (type === 'Dragon') {
      setSelectedIndex('')
      return
    }
    if (type === 'Feather')
      setSystemTypeIndexes(availableIndexes.environmentalControllerIndexes)
    if (type === 'Phoenix')
      setSystemTypeIndexes(availableIndexes.localControllerIndexes)
    setSelectedIndex('Select One')
  }

  const regex = /^\d+$/

  const checkNumeric = (e) => {
    const value = e.key

    if (!regex.test(value)) {
      e.preventDefault()
    }
  }

  const onChangeHandler = (e, inputType) => {
    if (inputType === 'directory') {
      if (e.target.value.trim().length > 0) {
        setIsDirectoryValid(true)
        setDirectoryValue(e.target.value)
        return
      }
      setIsDirectoryValid(false)
      return
    }

    if (inputType === 'command') {
      if (e.target.value.trim().length > 0) {
        setIsCommandValid(true)
        setCommandValue(e.target.value)
        return
      }
      setIsCommandValid(false)
      return
    }

    if (inputType === 'timeout') {
      if (regex.test(e.target.value)) {
        setIsTimeoutValid(true)
        setTimeoutValue(e.target.value)
        return
      }
      setIsTimeoutValid(false)
    }
  }

  const isSysIndexRequired =
    selectedStation[0] !== 'Select One' &&
    selectedSystemType !== 'Select One' &&
    selectedSystemType !== 'Dragon' &&
    selectedSystemType !== ''

  const isFormValid =
    selectedStation[0] !== 'Select One' &&
    selectedSystemType !== 'Select One' &&
    selectedIndex !== 'Loading...' &&
    selectedIndex !== 'Select One' &&
    isDirectoryValid &&
    isCommandValid &&
    isTimeoutValid &&
    !isUploading

  const onSubmitHandler = (e) => {
    setIsUploading(true)
    setResponse('Loading...')

    e.preventDefault()
    const payload = {
      systemType: selectedSystemType.toUpperCase(),
      systemIndex: selectedIndex ?? 0,
      dir: directoryValue,
      command: commandValue,
      timeout: timeoutValue * 1000,
    }

    const executeCommand = async () => {
      const response = await executeRemoteCommand(
        session,
        selectedStation[1],
        selectedStation[2],
        payload,
      )
      setResponse(response.message)
      setIsUploading(false)
    }

    executeCommand()

    setTimeout(() => {
      if (isUploading && response === 'Loading...') {
        setResponse('No Response')
        setIsUploading(false)
      }
    }, 60000)
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        Execute Remote Command
      </Typography>
      <Styles.FormRow>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            STATION :: BLOCK INDEX
          </Typography>
          <ToolbarDropdown
            title={selectedStation[0]}
            width="280px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            overflow="scroll"
            maxHeight="447px"
          >
            {data?.blocks.map((x) => (
              <Styles.StationDropItem
                $backgroundColor={
                  x.stationCode + ' :: ' + x.blockIndex === selectedStation[0]
                }
                $isConnected={x.valid}
                key={x.stationCode}
                onClick={() =>
                  setSelectedStationHandler(
                    x.stationCode,
                    x.blockIndex,
                    x.valid,
                  )
                }
                onKeyDown={null}
              >
                {x.stationCode + ' :: ' + x.blockIndex}
                {!x.valid && ' - Disconnected'}
              </Styles.StationDropItem>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
        <Styles.FormColumn $width="250px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            SYSTEM TYPE
          </Typography>
          {(selectedSystemType === 'Dragon' || selectedSystemType === '') &&
            !apartSetup && (
              <FormInput
                id="systemType"
                disabled={true}
                $isDisabled={true}
                defaultValue={selectedSystemType}
              />
            )}
          {selectedSystemType !== '' && apartSetup && (
            <ToolbarDropdown
              title={selectedSystemType}
              width="250px"
              handleClose={null}
              backgroundColor="white"
              textColor="black"
              fontSize="12px"
              height="32px"
              border="1px solid #ccc"
              borderRadius="4px"
            >
              {availableSystemTypes.map((type) => (
                <option
                  style={{
                    cursor: 'pointer',
                    padding: '4px',
                    color: 'black',
                    backgroundColor:
                      type === selectedSystemType ? COLORS.light_grey : '',
                  }}
                  key={type}
                  onClick={() => setSelectedSystemTypeHandler(type)}
                  onKeyDown={null}
                >
                  {type}
                </option>
              ))}
            </ToolbarDropdown>
          )}
        </Styles.FormColumn>
        <Styles.FormColumn $width="250px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            INDEX
          </Typography>
          {!isSysIndexRequired && (
            <FormInput
              id="index"
              onChange={(e) => onChangeHandler(e, 'index')}
              disabled={true}
              $isDisabled={true}
              defaultValue={selectedIndex}
            />
          )}
          {isSysIndexRequired && (
            <ToolbarDropdown
              title={selectedIndex}
              width="250px"
              handleClose={null}
              backgroundColor="white"
              textColor="black"
              fontSize="12px"
              height="32px"
              border="1px solid #ccc"
              borderRadius="4px"
              overflow="auto"
              maxHeight="447px"
            >
              {systemTypeIndexes?.map((index) => (
                <option
                  style={{
                    cursor: 'pointer',
                    padding: '4px',
                    color: 'black',
                    backgroundColor:
                      index === selectedIndex ? COLORS.light_grey : '',
                  }}
                  key={index}
                  onClick={() => setSelectedIndex(index)}
                  onKeyDown={null}
                >
                  {index}
                </option>
              ))}
            </ToolbarDropdown>
          )}
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.FormColumn $width="840px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            DIRECTORY
          </Typography>
          <FormInput
            id="directory"
            onChange={(e) => onChangeHandler(e, 'directory')}
          />
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.FormColumn $width="560px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            COMMAND
          </Typography>
          <FormInput
            id="command"
            onChange={(e) => onChangeHandler(e, 'command')}
          />
        </Styles.FormColumn>
        <Styles.FormColumn $width="250px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            TIMEOUT (Seconds)
          </Typography>
          <FormInput
            id="timeout"
            onChange={(e) => onChangeHandler(e, 'timeout')}
            defaultValue={timeoutValue}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
      </Styles.FormRow>
      <FormSubmitButton
        $width="140px"
        $marginTop="25px"
        $isValid={isFormValid}
        disabled={!isFormValid}
        onClick={(e) => {
          onSubmitHandler(e)
        }}
      >
        SUBMIT
      </FormSubmitButton>
      <Styles.FormRow>
        <Styles.FormColumn $marginTop="20px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            RESPONSE
          </Typography>
          <Styles.Response
            type="text"
            name="response"
            id="response"
            size="10"
            disabled
          >
            <div style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
              {response}
            </div>
          </Styles.Response>
        </Styles.FormColumn>
      </Styles.FormRow>
    </Styles.Container>
  )
}
