import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import StackManagersListSummary from './Summary/StackManagersListSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import StackManagerListTable from 'modules/Station/components/Tables/StackManagerListTable/StackManagerListTable'
import useStackManagerListQuery from 'api/useQueryHooks/useStackManagerListQuery'

const StackManagersList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'stack-managers-list' })
    document.title = `${stationCode}::${blockIndex} - Stack Managers`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<StackManagersListSummary data={data} />}
      tableComponent={
        <StackManagerListTable
          data={data.elementList ? data.elementList : []}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
      className="stackMngrs"
    />
  )

  return handleQueryLoadingAndError(useStackManagerListQuery(), ListPageJsx)
}

export default StackManagersList
