import { useState, useContext } from 'react'
import useEksPpcDetailsQuery from 'api/useQueryHooks/useEksPpcDetailsQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { FONT_SIZES, FONT_WEIGHT } from 'design_system/typography'
import { PpcModesTable } from 'modules/Station/modules/SystemDetails/EksPpcDetails/Main/Tables/PpcModesTable'
import { SetPointsTable } from 'modules/Station/modules/SystemDetails/EksPpcDetails/Main/Tables/SetPointsTable'
import styled from 'styled-components/macro'
import ErrorsAndAlarmsTable from '../Tables/EksPcsGroupDetails/ErrorsAndAlarmsTable'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { ReactComponent as PencilIcon } from 'icons/pencil-icon.svg'
import { EditPpcSetpointsModal } from '../EksPpcModals/EditPpcSetpointsModal'
import { EditPpcModesModal } from '../EksPpcModals/EditPpcModesModal'
import useProgressToaster from 'hooks/useProgressToaster/useProgressToaster'
import { SessionContext } from 'contexts/session'

export const EksPpcUtilityList = () => {
  const { data, isLoading } = useEksPpcDetailsQuery()
  const [openModalId, setOpenModalId] = useState(null)

  const { session } = useContext(SessionContext)

  const isReadOnly = session?.userAuth?.settings?.READ_ONLY === '1'

  const modalToast = useProgressToaster()

  if (isLoading) return <LoadingPage />

  const canEditModes = data?.opModeInterfaceCmd !== 'HMI_CONTROL'

  const canEditSetpoints = data?.opModeInterfaceSetting === 'STACK_OS_CONTROL'

  function closeModal() {
    setOpenModalId(null)
  }

  function openModal(id) {
    if (!['ppc', 'modes'].includes(id)) return
    closeModal()
    setOpenModalId(id)
  }
  return (
    <>
      <ListWrapper>
        <div>
          <ModesWrapper $isReadOnly={isReadOnly}>
            <Header>
              <Title $isReadOnly={isReadOnly}>Modes</Title>
              {!isReadOnly && (
                <FormCommandButton
                  disabled={!canEditModes}
                  onClick={() => openModal('modes')}
                >
                  <Icon
                    size="xxs"
                    color={
                      canEditModes ? COLORS.action_blue : COLORS.disabled_action
                    }
                    icon={<PencilIcon />}
                  />
                  Edit Modes
                </FormCommandButton>
              )}
            </Header>
            <PpcModesTable data={data} />
          </ModesWrapper>
        </div>
        <div>
          <Header>
            <Title>Setpoints</Title>
            <FormCommandButton
              disabled={!canEditSetpoints}
              onClick={() => openModal('ppc')}
            >
              <Icon
                size="xxs"
                color={
                  canEditSetpoints ? COLORS.action_blue : COLORS.disabled_action
                }
                icon={<PencilIcon />}
              />
              Edit Setpoints
            </FormCommandButton>
          </Header>
          <SetPointsTable data={data} />
        </div>
        <FullRow>
          <Header>
            <Title>Errors and Alarms</Title>
          </Header>
          <ErrorsAndAlarmsTable data={data?.errorsAndAlarms ?? []} />
        </FullRow>
      </ListWrapper>
      {openModalId === 'ppc' && (
        <EditPpcSetpointsModal
          isOpen
          handleClose={closeModal}
          toast={modalToast}
        />
      )}
      {openModalId === 'modes' && (
        <EditPpcModesModal isOpen handleClose={closeModal} toast={modalToast} />
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0.5rem 0;
  align-items: center;

  ${FormCommandButton} {
    margin: 0;
  }
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`

const FullRow = styled.div`
  grid-column: 1/-1;
`

const Title = styled.span`
  display: block;
  font-size: ${FONT_SIZES.large};
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHT.strong};
  flex: 1;
  margin-bottom: ${(props) => (props.$isReadOnly ? '4px' : '')};
`

const ModesWrapper = styled.div`
  margin-top: ${(props) => (props.$isReadOnly ? '12.5px' : '')};
`
