import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import boost from 'highcharts/modules/boost'
import { useParams } from 'react-router-dom'

// Component Imports
import BottomDrawer from '../../../../../components/Modals/BottomDrawer/BottomDrawer'

// Util Imports
import { renderPlotlines } from '../../../../../utils/charts.utils'
import { buildYAxis } from './utils'

const initialModalData = { name: '' }

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})
highchartsMore(Highcharts)
boost(Highcharts)

const StringVoltDistribution = ({ data, viewType }) => {
  const params = useParams()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalData, setModalData] = useState(initialModalData)
  const [voltageData, setVoltageData] = useState([])
  const [isZooming, setIsZooming] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setVoltageData(data)
    }
  }, [params, data])

  const handleToggleModal = (data) => {
    setModalData(data)
    setModalIsOpen(true)
  }

  const formatVoltageData = (data) => {
    return _.map(data, (d) => {
      return {
        low: d.cellVoltage000,
        q1: d.cellVoltage025,
        median: d.cellVoltageAverage,
        q3: d.cellVoltage075,
        high: d.cellVoltage100,
        name: d.id,
        color:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
              ? 'red'
              : 'orange',
        fillColor:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
              ? 'red'
              : 'orange',
        lineColor:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
              ? 'red'
              : 'orange',
        boostThreshold: 100,
      }
    })
  }

  const voltageSeries = {
    type: 'boxplot',
    name: 'Voltage',
    turboThreshold: 10000000,
    data: formatVoltageData(voltageData),
    point: {
      events: {
        click: function () {
          handleToggleModal(this.options)
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return `<span style="color: ${this.color}">\u25CF</span> <b>${
          this.series.name
        }</span></b>
					<br />
					Maximum: ${_.round(this.high, 2).toLocaleString()} mV
					<br />
					Upper quartile: ${_.round(this.q3, 2).toLocaleString()} mV
					<br />
					Median: ${_.round(this.median, 2).toLocaleString()} mV
					<br />
					Lower quartile: ${_.round(this.q1, 2).toLocaleString()} mV
					<br />
					Minimum: ${_.round(this.low, 2).toLocaleString()} mV	
					`
      },
    },
  }

  const [chartOptions, setChartOptions] = useState({
    title: {
      text: undefined,
    },
    accessibility: { enabled: false },
    boost: {
      enabled: true,
      seriesThreshold: 100,
    },
    chart: {
      zoomType: 'y',
      marginLeft: 90,
      panKey: 'alt',
      events: {
        selection: function (event) {
          setIsZooming(!event?.resetSelection)
        },
      },
    },
    plotOptions: {
      boxplot: {
        lineWidth: 1,
        stemWidth: 1,
        whiskerWidth: 0,
      },
    },
    legend: { enabled: false },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      crosshair: true,
      title: {
        text: undefined,
        style: {
          textTransform: 'capitalize',
        },
      },
      type: 'category',
      labels: {
        format: '{text}',
      },
      plotLines: renderPlotlines(voltageData, viewType),
    },

    yAxis: buildYAxis(),

    series: [voltageSeries],
  })

  useEffect(() => {
    const getMinMaxOperationVoltages = () =>
      voltageData?.reduce((prev, curr) => {
        const minMaxVoltages = [...prev]
        if (
          minMaxVoltages[0] === undefined ||
          minMaxVoltages[1] === undefined
        ) {
          return [curr.cellVoltage000, curr.cellVoltage100]
        }

        if (curr.cellVoltage000 < minMaxVoltages[0])
          minMaxVoltages[0] = curr.cellVoltage000
        if (curr.cellVoltage100 > minMaxVoltages[1])
          minMaxVoltages[1] = curr.cellVoltage100

        return minMaxVoltages
      }, []) ?? []

    setChartOptions((chartOptions) => ({
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        plotLines: renderPlotlines(voltageData, viewType),
      },
      yAxis: buildYAxis(...getMinMaxOperationVoltages(), isZooming),
      series: [voltageSeries],
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voltageData, viewType, isZooming])
  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <BottomDrawer
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        title={`String ${modalData.name}`}
      ></BottomDrawer>
    </Container>
  )
}

export default StringVoltDistribution

const Container = styled.div`
  flex: 1;

  & > div {
    height: 100%;
  }
`
