import React, { useState } from 'react'
import _ from 'lodash'

// Styles Imports
import * as Styles from './ExpandedCard.styled'

// Component Imports
import Icon from '../../Icon/Icon'
import BinaryBarChart from '../../Charts/BinaryBarChart/BinaryBarChart'
import TernaryBarChartStrings from '../../Charts/TernaryBarChart/TernaryBarChartStrings'

// Icon Imports
import { ReactComponent as CaretRightIcon } from '../../../icons/caret-right-icon.svg'
import { ReactComponent as CaretDownIcon } from '../../../icons/caret-down-icon.svg'
import { ReactComponent as HealthGoodIcon } from '../../../icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from '../../../icons/health-alert-icon.svg'

const ExpandableCard = ({
  children,
  title,
  icon,
  summarySections,
  isExpanded = false,
  listLink,
  expandable = true,
  minimal = false,
}) => {
  const [isOpen, setIsOpen] = useState(isExpanded)

  const renderSummarySections = () => {
    return _.map(summarySections, (s, i) => {
      if (s.type === 'value') {
        return (
          <Styles.SummarySection key={i}>
            {s.labelIcon && <Styles.LabelIcon>{s.labelIcon}</Styles.LabelIcon>}
            <Styles.SectionFlex>
              <Styles.LabelRow>
                <Styles.Label>{s.label}</Styles.Label>
              </Styles.LabelRow>
              <Styles.Value>{s.value}</Styles.Value>
            </Styles.SectionFlex>
          </Styles.SummarySection>
        )
      } else if (s.type === 'binary') {
        return (
          <Styles.SummaryBarChartSection key={i}>
            {s.labelIcon && <Styles.LabelIcon>{s.labelIcon}</Styles.LabelIcon>}
            <Styles.SectionFlex>
              <Styles.LabelRow>
                <Styles.Label>{s.label}</Styles.Label>
              </Styles.LabelRow>
              <Styles.BarChartContainer>
                <BinaryBarChart
                  positiveCount={s.positiveCount}
                  negativeCount={s.negativeCount}
                  tooltip={s.tooltip}
                  id={s.id}
                />
              </Styles.BarChartContainer>
            </Styles.SectionFlex>
          </Styles.SummaryBarChartSection>
        )
      } else if (s.type === 'ternary') {
        return (
          <Styles.SummaryBarChartSection key={i}>
            {s.labelIcon && <Styles.LabelIcon>{s.labelIcon}</Styles.LabelIcon>}
            <Styles.SectionFlex>
              <Styles.LabelRow>
                <Styles.Label>{s.label}</Styles.Label>
              </Styles.LabelRow>
              <Styles.BarChartContainer>
                <TernaryBarChartStrings data={s.data} />
              </Styles.BarChartContainer>
            </Styles.SectionFlex>
          </Styles.SummaryBarChartSection>
        )
      } else if (s.type === 'health') {
        return (
          <Styles.SummarySection key={i}>
            {s.labelIcon && <Styles.LabelIcon>{s.labelIcon}</Styles.LabelIcon>}
            <Styles.SectionFlex>
              <Styles.LabelRow>
                <Styles.Label>{s.label}</Styles.Label>
              </Styles.LabelRow>
              <Styles.IconRow>
                <Styles.HealthIcon $isHealthy={s.isHealthy}>
                  {s.isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
                </Styles.HealthIcon>
                <Styles.Value>{s.value}</Styles.Value>
              </Styles.IconRow>
            </Styles.SectionFlex>
          </Styles.SummarySection>
        )
      } else if (s.type === 'jsx') {
        return s.value
      }
    })
  }

  return (
    <Styles.Container>
      <Styles.TopContent $minimal={minimal}>
        <Styles.LeftRow
          onClick={() => setIsOpen(!isOpen)}
          $expandable={expandable}
        >
          {expandable && (
            <Styles.ToggleIcon onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <CaretDownIcon /> : <CaretRightIcon />}
            </Styles.ToggleIcon>
          )}
          {!_.isEmpty(icon) && (
            <Styles.IconContainer>
              <Icon size="md" icon={icon} />
            </Styles.IconContainer>
          )}
          <Styles.TitleRow $minimal={minimal}>
            <Styles.Title>{title}</Styles.Title>
            {listLink && (
              <Styles.ListLink to={listLink}>View All</Styles.ListLink>
            )}
          </Styles.TitleRow>
        </Styles.LeftRow>
        <Styles.SummaryRow>{renderSummarySections()}</Styles.SummaryRow>
      </Styles.TopContent>
      {expandable && isOpen && (
        <Styles.ExpandedContent>{children}</Styles.ExpandedContent>
      )}
    </Styles.Container>
  )
}

export default ExpandableCard
