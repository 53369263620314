import {
  fetchArrayStatus,
  fetchBlockStatus,
  fetchPCSList,
  fetchPlugInStatus,
  fetchStringDetails,
  getDcDcGroupList,
  getDcDcPCSesList,
  getPvPcsList,
} from 'api/queries.api'
import { SessionContext } from 'contexts/session'
import { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import getPlugInsTableData from '../PlugIns/PlugInsTable/getPlugInsTableData'
import { BALANCE_STATUSES_FROM_STRING } from './Balancing/BALANCING_STATUSES'
import axios from 'axios'
import { createKey as createDcDcGroupQueryKey } from 'api/useQueryHooks/useDcDcGroupsQuery'
import { useQueryClient } from '@tanstack/react-query'

export const useCommandResultsQueries = (
  pendingEvaluation,
  userInputForm,
  ids,
  pendingQueryName,
) => {
  const { session, setSession } = useContext(SessionContext)
  const { stationCode, blockIndex } = useParams()
  const warningTimerId = useRef() // after 20 sec show warning
  let checkPendingTimersIds = useRef([])
  const [results, setResults] = useState({ pending: [...ids], updated: [] })
  const [showWarning, setShowWarning] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    let fetchPendingDetails = () => {}
    const stringDetailsFunc = () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const promiseResults = results.pending.map(async (id) => {
          const [arrayId, stringID] = id.split(':')
          const stringDetails = await fetchStringDetails(
            stationCode,
            blockIndex,
            arrayId,
            stringID,
            session,
            setSession,
          )
          if (pendingEvaluation(stringDetails, userInputForm)) {
            // ROTATION UPDATE VALUE
            newResults.updated.push(id)
          }
        })
        Promise.all(promiseResults).then(() => {
          newResults.pending = ids.filter(
            (id) => !newResults.updated.includes(id),
          )
          setResults(newResults)
        })
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const inverterListFunc = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const inverterList = await fetchPCSList(
          stationCode,
          blockIndex,
          session,
          setSession,
        )
        results.pending.forEach((id) => {
          if (pendingEvaluation(inverterList, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const arrayDetailsFunc = () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const promiseResults = results.pending.map(async (id) => {
          const arrayDetails = await fetchArrayStatus(
            stationCode,
            blockIndex,
            id,
            session,
            setSession,
          )
          const stringList = _.toArray(arrayDetails.strings)
          // Check if all strings in the array succeeded
          const stringSuccess = _.every(stringList, (string) =>
            pendingEvaluation(string, userInputForm),
          )

          if (stringSuccess === true) newResults.updated.push(id)
        })
        Promise.all(promiseResults).then(() => {
          newResults.pending = ids.filter(
            (id) => !newResults.updated.includes(id),
          )
          setResults(newResults)
        })
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const blockDetailsFunc = () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const promiseResults = results.pending.map(async (id) => {
          const idParts = id.split(':')
          const _stationCode = idParts[0]
          const _blockIndex = idParts[1]
          const blockDetails = await fetchBlockStatus(
            _stationCode,
            _blockIndex,
            session,
            setSession,
          )
          const blockList = _.toArray(blockDetails.strings)
          // Check if all blocks in the array succeeded
          const blocksSuccess = _.every(blockList, (string) =>
            pendingEvaluation(string, userInputForm),
          )

          if (blocksSuccess === true) newResults.updated.push(id)
        })
        Promise.all(promiseResults).then(() => {
          newResults.pending = ids.filter(
            (id) => !newResults.updated.includes(id),
          )
          setResults(newResults)
        })
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const appStatusFunc = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const blockDetails = await fetchBlockStatus(
          stationCode,
          blockIndex,
          session,
          setSession,
        )
        results.pending.forEach((id) => {
          if (pendingEvaluation(blockDetails.apps, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const plugInStatusFunc = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const plugInStatus = await fetchPlugInStatus(
          stationCode,
          blockIndex,
          session,
        )

        const plugInData = getPlugInsTableData(plugInStatus)
        results.pending.forEach((id) => {
          if (pendingEvaluation(plugInData, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const acContactorsFunc = arrayDetailsFunc

    const balanceVerificationCheck = async (type) => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const pending = [...results.pending]
        const jsonBody = {
          type: type,
          ids: [],
          balanceMode: BALANCE_STATUSES_FROM_STRING(userInputForm.balancingCmd),
          providedMillivolts: userInputForm.providedMillivolts ?? -1,
        }
        pending.forEach((id) => jsonBody.ids.push(id))

        const data = await stationBalanceVerifyQuery(
          jsonBody,
          session,
          stationCode,
          blockIndex,
        )

        data?.validations?.forEach((validation) =>
          validation.valid ? newResults.updated.push(validation.id) : null,
        )

        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }
    const stringBalancingDetailsFunc = () => {
      balanceVerificationCheck('STRING')
    }
    const arrayBalancingDetailsFunc = () => {
      balanceVerificationCheck('ARRAY')
    }

    const preferredCurrentDetailsFunc = async () => {
      if (results.pending.length !== 0) {
        const newPending = []
        const pending = [...results.pending]
        for (const id of pending) {
          const jsonBody = {
            arrayIndexes: [id],
            chargeManagerEnabled: userInputForm.chargeManagerEnabled,
            dischargeManagerEnabled: userInputForm.dischargeManagerEnabled,
          }
          const data = await preferredCurrentVerifyQuery(
            jsonBody,
            session,
            stationCode,
            blockIndex,
          )
          if (data.validArrayIndexes && data.validArrayIndexes[0] == id) {
            setResults((results) => ({
              ...results,
              pending: [...results.pending, id],
            }))
          } else {
            newPending.push(id)
          }
        }
        setResults((results) => ({ ...results, pending: newPending }))
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const dcDcGroupList = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const dcDcGroupList = await getDcDcGroupList(
          session,
          stationCode,
          blockIndex,
        )

        queryClient?.setQueryData?.(
          createDcDcGroupQueryKey(stationCode, blockIndex),
          () => dcDcGroupList,
        )

        results.pending.forEach((id) => {
          if (pendingEvaluation(dcDcGroupList, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const dcDcPcsesList = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const dcDcGroupList = await getDcDcPCSesList(
          session,
          stationCode,
          blockIndex,
        )

        results.pending.forEach((id) => {
          if (pendingEvaluation(dcDcGroupList, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    const pvPcsRotation = async () => {
      if (results.pending.length !== 0) {
        const newResults = { updated: [], pending: [] }
        const pvPcsList = await getPvPcsList(session, stationCode, blockIndex)
        results.pending.forEach((id) => {
          if (pendingEvaluation(pvPcsList, userInputForm, id)) {
            newResults.updated.push(id)
          }
        })
        newResults.pending = ids.filter(
          (id) => !newResults.updated.includes(id),
        )
        setResults(newResults)
      } else {
        checkPendingTimersIds.current.forEach((t) => {
          clearTimeout(t)
        })
        checkPendingTimersIds.current = []
      }
    }

    let pendingTimeoutMillis = 1000 * 20

    switch (pendingQueryName) {
      case 'stringDetails':
        fetchPendingDetails = stringDetailsFunc
        break
      case 'inverterListFunc':
        fetchPendingDetails = inverterListFunc
        break
      case 'arrayDetailsFunc':
      case 'queryACRotation':
        fetchPendingDetails = arrayDetailsFunc
        break
      case 'queryACContactors':
        fetchPendingDetails = acContactorsFunc
        break
      case 'blockDetails':
        fetchPendingDetails = blockDetailsFunc
        break
      case 'stringBalancingDetails':
        fetchPendingDetails = stringBalancingDetailsFunc
        pendingTimeoutMillis = 1000 * 60 * 2
        break
      case 'setAppStatus':
        fetchPendingDetails = appStatusFunc
        break
      case 'arrayBalancingDetails':
      case 'setACBatteryBalancing':
        fetchPendingDetails = arrayBalancingDetailsFunc
        pendingTimeoutMillis = 1000 * 60 * 2
        break
      case 'setPlugInStatus':
        fetchPendingDetails = plugInStatusFunc
        break
      case 'preferredCurrentDetails':
        fetchPendingDetails = preferredCurrentDetailsFunc
        break
      case 'dcDcGroupRotation':
        fetchPendingDetails = dcDcGroupList
        break
      case 'dcDcPcsesRotation':
        fetchPendingDetails = dcDcPcsesList
        break
      case 'pvPcsRotation':
        fetchPendingDetails = pvPcsRotation
        break
      default:
        break
    }

    for (let ms = 0; ms <= pendingTimeoutMillis; ms += 5000) {
      const v = setTimeout(() => fetchPendingDetails(), ms)
      checkPendingTimersIds.current.push(v) // creates pendingTimeoutMillis/5000 timers, store timer ids
    }
    warningTimerId.current = setTimeout(() => {
      setShowWarning(true)
    }, pendingTimeoutMillis)
    return () => {
      checkPendingTimersIds?.current.forEach((t) => clearTimeout(t))
      clearTimeout(warningTimerId?.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingQueryName]) // don't reset timers unless pendingQueryName changes

  return { results, showWarning }
}

const stationBalanceVerifyQuery = async (
  jsonBody,
  session,
  stationCode,
  blockIndex,
) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }

  const response = await axios.post(
    `${apiUrl}station/${stationCode}/block/${blockIndex}/balance/verify`,
    jsonBody,
    axiosOptions,
  )
  return response.data
}

const preferredCurrentVerifyQuery = async (
  jsonBody,
  session,
  stationCode,
  blockIndex,
) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }
  const response = await axios.post(
    `${apiUrl}station/${stationCode}/block/${blockIndex}/preferredcurrentmanager/verify`,
    jsonBody,
    axiosOptions,
  )
  return response.data
}
