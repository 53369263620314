import AppTooltip from 'components/AppTooltip/AppTooltip'

// Style Imports
import * as Styles from './styles'

// Component Imports
import Icon from '../../Icon/Icon'

// Icon Imports
import { ReactComponent as ConnectedIcon } from '../../../icons/status-connected-icon.svg'
import { ReactComponent as DisconnectedIcon } from '../../../icons/status-disconnected-icon.svg'
import { ReactComponent as ConnectWarningIcon } from '../../../icons/status-connect-warning-icon.svg'
import * as dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)

const ConnectionStatus = ({
  isConnected,
  showWarning = false,
  timestamp,
  id,
  size = 'sm',
}) => {
  const updatedTimestamp =
    timestamp === 0 ? 'unknown' : dayjs(timestamp).format('lll')
  const updatedShowWarning = showWarning ? (
    <ConnectWarningIcon />
  ) : (
    <DisconnectedIcon />
  )
  return (
    <div data-tooltip-id={`connectionStatus-${id}`}>
      <Icon
        size={size}
        color={isConnected ? '#00ba86' : 'red'}
        icon={
          isConnected && !showWarning ? <ConnectedIcon /> : updatedShowWarning
        }
      />
      <AppTooltip id={`connectionStatus-${id}`}>
        <Styles.Label>
          {isConnected && !showWarning ? 'Connected' : 'Disconnected'}
        </Styles.Label>

        <Styles.Sublabel>Last Connection:</Styles.Sublabel>
        <Styles.Timestamp>{updatedTimestamp}</Styles.Timestamp>
      </AppTooltip>
    </div>
  )
}

export default ConnectionStatus
