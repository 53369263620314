import { useController } from 'react-hook-form'

export const ControlledSelect = ({ options, allowEmpty, ...props }) => {
  const { field } = useController({
    ...props,
    rules: {
      /* disable submit if there's any empty input */
      validate: allowEmpty
        ? undefined
        : (value) => ![null, undefined, ''].includes(value),
    },
  })

  return (
    <select
      style={{ width: 200, maxWidth: 300 }}
      {...field}
      onChange={(e) => {
        const value = e.target.value
        field.onChange(
          /* native input values are always strings, we should parse before saving */
          value === 'true' ? true : value === 'false' ? false : value,
        )
      }}
    >
      <option value="" disabled={!allowEmpty} />
      {options.map((o) => (
        <option key={`${o.label}-${o.value}`} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  )
}
