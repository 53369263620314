import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useNotificationsSearchQuery = (filters) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)
  const queryParams = Object.keys(filters)
    .map((key) => (filters[key] ? `&${key}=${filters[key]}` : ''))
    .reduce((queryParams, queryParam) => queryParams + queryParam, '?')

  return useQuery(
    ['notificationsSearch', stationCode, blockIndex, filters],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }
      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/notifications${queryParams}`,
        axiosOptions,
      )
      return response.data
    },
    { refetchInterval: 5000 },
  )
}

export default useNotificationsSearchQuery
