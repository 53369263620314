import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useBlockMeterDetailsQuery from 'api/useQueryHooks/useBlockMeterDetailsQuery'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import BlockMeterDetailsSummary from './Summary/BlockMeterDetailsSummary'
import BlockMeterDetailsMainColumn from './MainColumn/BlockMeterDetailsMainColumn'

const BlockMeterDetails = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, blockMeterIndex } = params
  useEffect(() => {
    setSelectedParams({
      ...params,
      listView: 'block-meter-details',
    })
    document.title = `${stationCode}::${blockIndex} - Block Meter ${blockMeterIndex}`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={
        <BlockMeterDetailsSummary data={data} id={blockMeterIndex} />
      }
      mainColumnComponent={<BlockMeterDetailsMainColumn data={data} />}
      className="blockMeterDetails"
    />
  )

  return handleQueryLoadingAndError(useBlockMeterDetailsQuery(), ListPageJsx)
}

export default BlockMeterDetails
