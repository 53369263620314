import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Component Imports
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import DcDcGroupDetailSummary from './Summary/DcDcGroupDetailSummary'
import DcDcGroupDetailMainColumn from './MainColumn/DcDcGroupDetailMainColumn'

// API Imports
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useDcDcGroupStatus from 'api/useQueryHooks/useDcDcGroupStatus'

const DcDcGroupDetail = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acPvBatteryIndex, dcDcGroupIndex } = params
  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - DC DC Group ${acPvBatteryIndex}::${dcDcGroupIndex}`
    setSelectedParams({ ...params, listView: 'dc-dc-group-detail' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DetailsPageJsx = (data) => {
    return (
      <DetailsPage
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
        setSelectedPage={setSelectedPage}
        summaryColumnComponent={<DcDcGroupDetailSummary data={data} />}
        mainColumnComponent={
          <DcDcGroupDetailMainColumn acPvBatteryIndex={acPvBatteryIndex} />
        }
      />
    )
  }
  return handleQueryLoadingAndError(
    useDcDcGroupStatus(acPvBatteryIndex, dcDcGroupIndex),
    DetailsPageJsx,
  )
}

export default DcDcGroupDetail
