import styled from 'styled-components/macro'

export function ApiErrorMessage({ error }) {
  return (
    <CenteredDiv>
      <ErrorMessage>
        Data API Error{error?.message && `: ${error.message}`}
      </ErrorMessage>
      <ErrorSubText>
        If problem persists please contact{' '}
        <a href="mailto:commandcenter@powin.com">commandcenter@powin.com</a>
      </ErrorSubText>
    </CenteredDiv>
  )
}

export const ErrorMessage = styled.h1`
  color: red;
  text-align: center;
`

export const ErrorSubText = styled.h2`
  text-align: center;
`

export const CenteredDiv = styled.div`
  margin: auto;
  width: 50%;
  border: 3px solid red;
  padding: 10px;
`
