import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import EnvironmentalControllersListSummary from './Summary/EnvironmentalControllersListSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useEnvironmentalControllerListQuery from 'api/useQueryHooks/useEnvironmentalControllerListQuery'
import EnvironmentalControllersTable from 'modules/Station/components/Tables/EnvironmentalControllersTable/EnvironmentalControllersTable'

const EnvironmentalControllersList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'environmental-controllers' })
    document.title = `${stationCode}::${blockIndex} - Environmental Controllers`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={
        <EnvironmentalControllersListSummary data={data} />
      }
      tableComponent={
        <EnvironmentalControllersTable
          data={data.elementList ? data.elementList : []}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
      className="envControllers"
    />
  )

  return handleQueryLoadingAndError(
    useEnvironmentalControllerListQuery(),
    ListPageJsx,
  )
}

export default EnvironmentalControllersList
