import { DatePicker } from 'antd'
import * as S from './extractReports.styled-components'
import useAvailableExtractReports from 'api/useQueryHooks/useAvailableExtractReports'
import { useContext, useState } from 'react'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useProgressToaster from 'hooks/useProgressToaster/useProgressToaster'
import axios from 'axios'
import { provideBlobDownload } from 'api/helpers'
import { useParams } from 'react-router-dom'
import { SessionContext } from 'contexts/session'
import ModalV2 from 'components/Modals/ModalV2/ModalV2'

export function ExtractReportsDownloadModal({ onClose }) {
  const { session } = useContext(SessionContext)
  const { stationCode, blockIndex } = useParams()
  const [selectedReportDate, setSelectedReportDate] = useState(null)
  const [downloadPcsReport, setDownloadPcsReport] = useState(false)
  const [downloadArrayReport, setDownloadArrayReport] = useState(false)
  const [downloadStringReport, setDownloadStringReport] = useState(false)
  const { toastError, toastPending, toastSuccess } = useProgressToaster()
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false)

  const {
    data: availableReportsData,
    isFetching: isLoadingAvailableReportsData,
    isFetched: isFetchedAvailableReportsData,
  } = useAvailableExtractReports(selectedReportDate)

  function getAvailableReportUrl(name) {
    if (!name) return false
    return (
      availableReportsData?.downloadExtractEntryList?.find((url) =>
        url.includes(name),
      ) ?? false
    )
  }

  const availablePcsReportUrl = getAvailableReportUrl('PcsReport')

  const availableArrayReportUrl = getAvailableReportUrl('ArrayReport')

  const availableStringReportUrl = getAvailableReportUrl('StringReport')

  function resetCheckboxes() {
    setDownloadPcsReport(false)
    setDownloadArrayReport(false)
    setDownloadStringReport(false)
  }

  function onDateChange(_date, dateString) {
    setSelectedReportDate(dateString ?? null)
    resetCheckboxes()
  }

  async function onDownload() {
    const downloadFile = (fileName) => {
      if (!fileName) return null

      return axios
        .post(
          `${session.server}station/${stationCode}/block/${blockIndex}/extracts/download`,
          {
            fileName: fileName,
            date: selectedReportDate,
          },
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              papitoken: session.token,
            },
            responseType: 'arraybuffer',
          },
        )
        .then((res) => provideBlobDownload(fileName, res.data))
    }

    try {
      const promises = [
        downloadFile(downloadPcsReport && availablePcsReportUrl),
        downloadFile(downloadArrayReport && availableArrayReportUrl),
        downloadFile(downloadStringReport && availableStringReportUrl),
      ].filter((file) => file)

      setIsDownloadInProgress(true)
      toastPending('Extract Reports Download in Progress')

      await Promise.all(promises)

      onClose()
      toastSuccess(
        'Extract Reports Download Complete',
        'Check your downloads folder.',
      )
    } catch {
      toastError('Extract Reports Download Failed', 'Please try again.')
    } finally {
      setIsDownloadInProgress(false)
    }
  }

  const canDownload =
    availableReportsData &&
    !isLoadingAvailableReportsData &&
    !isDownloadInProgress &&
    Boolean(downloadArrayReport || downloadPcsReport || downloadStringReport)

  const hasAvailableReports = Boolean(
    availableReportsData &&
      (availablePcsReportUrl ||
        availableArrayReportUrl ||
        availableStringReportUrl),
  )

  return (
    <ModalV2
      title="Download Extract Reports"
      handleClose={onClose}
      footerContent={
        <S.Footer>
          <S.SecondaryButton onClick={onClose}>Cancel</S.SecondaryButton>
          <S.Button disabled={!canDownload} onClick={onDownload}>
            Download
          </S.Button>
        </S.Footer>
      }
      scrollableFooter
    >
      <S.Container>
        <S.Section>
          <S.SectionTitle>DATE</S.SectionTitle>
          <DatePicker
            onChange={onDateChange}
            disabledDate={(date) => date?.isAfter(new Date(), 'day')}
            getPopupContainer={(target) => target.parentNode}
          />
        </S.Section>
        {isLoadingAvailableReportsData && <LoadingPage size={20} />}
        <S.Section>
          {Boolean(!isLoadingAvailableReportsData && hasAvailableReports) && (
            <>
              <S.SectionTitle>AVAILABLE REPORTS</S.SectionTitle>
              <S.Option $hide={!availablePcsReportUrl}>
                <input
                  type="checkbox"
                  id="pcs"
                  checked={downloadPcsReport}
                  onChange={(e) => setDownloadPcsReport(e.target.checked)}
                />
                <label htmlFor="pcs">Array PCS Report</label>
              </S.Option>
              <S.Option $hide={!availableArrayReportUrl}>
                <input
                  type="checkbox"
                  id="array"
                  checked={downloadArrayReport}
                  onChange={(e) => setDownloadArrayReport(e.target.checked)}
                />
                <label htmlFor="array">Array Report</label>
              </S.Option>
              <S.Option $hide={!availableStringReportUrl}>
                <input
                  type="checkbox"
                  id="string"
                  checked={downloadStringReport}
                  onChange={(e) => setDownloadStringReport(e.target.checked)}
                />
                <label htmlFor="string">String Report</label>
              </S.Option>
            </>
          )}
          {Boolean(
            isFetchedAvailableReportsData &&
              !isLoadingAvailableReportsData &&
              !hasAvailableReports,
          ) && <span>There are no available reports for the given date.</span>}
        </S.Section>
      </S.Container>
    </ModalV2>
  )
}
