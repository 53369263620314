import { FormRow } from 'components/Form/FormComponents.styled'
import { StringContainer } from '../ContactorsFormUi.styles'

export const ACContactorsFormUi = ({
  ids,
  userInputForm,
  setUserInputForm,
}) => {
  const handleUserInputFormChange = (name, e) => {
    if (e?.target) {
      const update = { ...userInputForm }
      update[name] = e.target.value
      setUserInputForm(update)
    }
  }

  const stackIdsDisplay = ids.join(', ').replace(/:/g, '::')
  return (
    <>
      <StringContainer>
        <label>AC BATTERIES</label> <span>{stackIdsDisplay}</span>
      </StringContainer>
      <FormRow>
        <label>Contactor State</label>
      </FormRow>
      <FormRow>
        <select
          value={userInputForm.contactorsCloseExpected}
          onChange={(e) => handleUserInputFormChange('contactorState', e)}
        >
          <option value="open">Open</option>
          <option value="closed">Close</option>
        </select>
      </FormRow>
    </>
  )
}
