import { useContext, useRef, useState, memo } from 'react'

// Component Imports
import {
  FormCancelButton,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled.js'

// context imports
import { useParams } from 'react-router-dom'
import { SessionContext } from 'contexts/session'
import handleCommandSet from './command-modal-submit-handler'
import ModalV2 from 'components/Modals/ModalV2/ModalV2'
import styled from 'styled-components/macro'

const defaultFormState = { status: 'none', message: '' }

export const CommandModalTemplate = ({
  setShowCommandModal,
  ids,
  defaultFormValues,
  UserInputFormJsx,
  CommandResultJsx,
  modalTitles,
  queryName,
  additionalPayload = {},
  isFormValidated = false, // true for optional validated forms
}) => {
  const { session } = useContext(SessionContext)
  const { stationCode, blockIndex } = useParams()
  const [commandResult, setCommandResult] = useState(defaultFormState) // determines form, result or error to show
  const [modalTitle, setModalTitle] = useState(modalTitles.start)
  const [userInputForm, setUserInputForm] = useState(defaultFormValues)
  const [isValidated, setIsValidated] = useState(!isFormValidated) // toggles form submit button disabled for invalid
  const [isModalOpen, setIsModalOpen] = useState(true)

  const submitButton = useRef('SET')
  customSubmitButton(queryName, submitButton)

  const closeSetCommand = () => {
    setCommandResult(defaultFormState) // reset form
    setUserInputForm(defaultFormValues)
    setModalTitle(modalTitles.start)
    setIsModalOpen(false)
    setShowCommandModal(false)
  }

  // input forms will update their form and send valid boolean if form is validated
  // sending setUserInput to form is deprecated and may be removed
  const userInputFormChangeHandler = (updatedForm, valid) => {
    if (updatedForm) {
      setUserInputForm(updatedForm)
    }
    if (
      updatedForm.reason === 'Other' &&
      updatedForm.explanation.trim() === ''
    ) {
      setIsValidated(false)
      return
    }
    if (isFormValidated) setIsValidated(valid)
  }

  if (!isModalOpen) return null

  return (
    <ModalV2
      title={modalTitle}
      handleClose={closeSetCommand}
      scrollableFooter
      footerContent={
        <>
          <FormCancelButton onClick={closeSetCommand}>
            {commandResult.status === 'none' ? 'CANCEL' : 'CLOSE'}
          </FormCancelButton>
          {commandResult.status === 'none' && (
            <FormSubmitButton
              $width={150}
              onClick={() =>
                handleCommandSet(
                  queryName,
                  stationCode,
                  blockIndex,
                  ids,
                  userInputForm,
                  session,
                  setCommandResult,
                  isValidated,
                  setShowCommandModal,
                  setIsModalOpen,
                  additionalPayload,
                )
              }
              disabled={!isValidated}
              $isValid={isValidated}
            >
              {submitButton.current}
            </FormSubmitButton>
          )}
        </>
      }
    >
      <ModalContent>
        {commandResult.status === 'none' && (
          <UserInputFormJsx
            ids={ids}
            onUserInputFormChange={userInputFormChangeHandler}
            userInputForm={userInputForm}
            setUserInputForm={setUserInputForm}
          />
        )}
        {commandResult.status === 'success' && (
          <CommandResultJsx ids={ids} userInputForm={userInputForm} />
        )}
        {commandResult.status === 'error' && commandResult.message}
      </ModalContent>
    </ModalV2>
  )
}

export default memo(CommandModalTemplate)

const customSubmitButton = (queryName, submitButton) => {
  switch (queryName) {
    case 'setAcknowledgeAlert':
      submitButton.current = 'ACKNOWLEDGE'
      break
    case 'setAssigneeAlert':
      submitButton.current = 'ASSIGN'
      break
    case 'setMuteAlert':
      submitButton.current = 'MUTE ALERT'
      break
    case 'setUnmuteAlert':
      submitButton.current = 'UNMUTE ALERT'
      break
    case 'setResolveAlert':
      submitButton.current = 'RESOLVE'
      break
    case 'clearFaults':
      submitButton.current = 'CLEAR FAULTS'
      break
    case 'clearSensorFaults':
      submitButton.current = 'CLEAR FAULT'
      break
    case 'clearEnclosureFaults':
      submitButton.current = 'CLEAR FAULTS'
      break
    default:
      break
  }
}

const ModalContent = styled.div`
  cursor: default;
`
