import { useContext, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Navigate, Route, Routes } from 'react-router-dom'

// Component Imports
import AppHeader from './components/AppHeader/AppHeader'

// Module Imports
import Login from './modules/Login/Login'
import Dashboard from './modules/Dashboard/Dashboard'
import StationDetails from './modules/Station/StationDetails'

// Context Imports
import { SessionContext } from './contexts/session'
import { SiteControllerVersionProvider } from 'contexts/siteControllerVersionContext'

//Remember to render the ToastContainer once in your application tree. If you can't figure out where to put it, rendering it in the application root would be the best bet.
import 'react-toastify/dist/ReactToastify.css' // required styles for Toastify
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider } from '@tanstack/react-query'
import ccuiQueryClient from 'ccui-QueryClient'
import _ from 'lodash'
import {
  AuthorizationContext,
  AuthorizationContextProvider,
} from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import { AdminCommands } from 'modules/AdminCommands/AdminCommands'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'

// These protected routes will block unauthorized users from going directly to a url without permission
const ProtectedRouteAlertManagement = ({ children }) => {
  const { userHasApiAccessCode } = useContext(AuthorizationContext)
  const validUser = userHasApiAccessCode(API_ACCESS_CODES.SIX_A)
  if (!validUser) {
    return <Navigate to="/" replace />
  }
  return children
}

const ProtectedRouteControllerStatistics = ({ children, session }) => {
  const validUser =
    _.chain(session)
      .get(`userAuth.roles`)
      .includes('ROLE_TECH_SUPPORT')
      .value() || session.userAuth?.settings.SUPERUSER === '1'
  if (!validUser) {
    return <Navigate to="/" replace />
  }
  return children
}

const ProtectedRouteAdminCommands = ({ children, session }) => {
  const permissions = [
    'ROLE_TECH_SUPPORT',
    'ROLE_FIRMWARE_DEPLOY',
    'ROLE_FIRMWARE_TRIGGER',
    'ROLE_FIRMWARE_UPLOAD',
  ]
  const validUser =
    _.chain(session)
      .get(`userAuth.roles`)
      .some((p) => permissions.includes(p))
      .value() || session.userAuth?.settings.SUPERUSER === '1'

  if (!validUser) {
    return <Navigate to="/" replace />
  }
  return children
}

function App() {
  useEffect(() => {
    if (self === top) {
      const antiClickjack = document.getElementById('antiClickjack')
      if (antiClickjack) {
        antiClickjack.parentNode.removeChild(antiClickjack)
      }
    } else {
      top.location = self.location
    }
  }, [])
  const { session, clearSession } = useContext(SessionContext)

  const queryClient = ccuiQueryClient(clearSession)
  const validSession = !!(session?.token && session.userAuth)
  if (!validSession) queryClient.cancelQueries()

  return (
    <QueryClientProvider client={queryClient}>
      <style id="antiClickjack">
        {`
            body {
                display: none !important;
            }
        `}
      </style>
      <AuthorizationContextProvider>
        <ToastContainer />
        <AppContainer validSession={validSession}>
          <AppHeader />
          <AppContent>
            <SiteControllerVersionProvider>
              <Routes>
                <Route
                  path="/block-details/:stationCode/:blockIndex/*"
                  element={<StationDetails />}
                />
                <Route
                  path="/alert-management/*"
                  element={
                    <ProtectedRouteAlertManagement>
                      <Dashboard selectedTabInit={1} />
                    </ProtectedRouteAlertManagement>
                  }
                />
                <Route
                  path="/controller-statistics/*"
                  element={
                    <ProtectedRouteControllerStatistics session={session}>
                      <Dashboard selectedTabInit={2} />
                    </ProtectedRouteControllerStatistics>
                  }
                />
                <Route
                  path="/admin-commands/*"
                  element={
                    <ProtectedRouteAdminCommands session={session}>
                      <AdminCommands />
                    </ProtectedRouteAdminCommands>
                  }
                />
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </SiteControllerVersionProvider>
          </AppContent>
        </AppContainer>
      </AuthorizationContextProvider>
      {!validSession && <Login />}
    </QueryClientProvider>
  )
}

export default App

const LogicalAppContainer = ({ className, children, validSession }) => {
  return validSession ? <div className={className}>{children}</div> : null
}

const AppContainer = styled(LogicalAppContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;

  .ant-picker {
    border-color: #ccc;
    border-radius: 5px;
  }
  .ant-picker-large {
    height: 38px;

    .ant-picker-input > input {
      font-size: 14px;

      &::placeholder {
        color: #808080;
      }
    }
  }
  .ant-picker-suffix {
    color: #aaa;
  }
`

const AppContent = styled.div`
  background-color: #f7f7f9;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
`
