import { ClearSensorFaultsModal } from 'modules/Station/components/Commands/ClearSensorsFaults/ClearSensorFaultsModal'

export const actions = {
  SHOW_FAULT_CLEAR_MODAL: 'showFaultClearModal',
}

const actionModalReducer = (_state, action) => {
  switch (action?.type) {
    case actions.SHOW_FAULT_CLEAR_MODAL: {
      action.setShowCommandModal(true)
      return () => (
        <ClearSensorFaultsModal
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          sensorIndex={action.sensorIndex}
          sensorName={action.sensorName}
          setShowCommandModal={action.setShowCommandModal}
          centipedeLineupId={action.centipedeLineupId}
          segmentId={action.segmentId}
          segment={action.segment}
        />
      )
    }
    default:
      return null
  }
}

export default actionModalReducer
