export const ICON_SIZES = {
  lg: '45px',
  md: '35px',
  sm: '25px',
  xs: '20px',
  xxs: '11px',
  xxxs: '8px',
}

/** @deprecated - use typography.ts instead */
export const FONT_SIZES = {
  xxl: '17px',
  xl: '15px',
  large: '14px',
  normal: '12px',
  small: '10px',
}
