import { COLORS } from 'design_system/colors'
import styled from 'styled-components/macro'

export const SelectWithBottomPadding = styled.select`
  margin-bottom: 20px;
`
export const StringContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`

export const BasicTable = styled.table`
  margin-top: -10px;
  border: solid 1px #dddddd;
  width: 100%;
  thead td {
    background-color: #dddddd;
    color: #888888;
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
  }
  tbody td {
    padding: 12px;
    font-size: 13px;
    font-weight: 600;
  }
`

export const ModalTable = styled(BasicTable)`
  thead td {
    background-color: ${COLORS.modal_table_header_bg};
  }
`

export const InfoHeading = styled.div`
  color: #00ba86;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  div {
    float: left;
    margin-right: 10px;
  }
`

export const PendingHeading = styled.div`
  color: #ba6800;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  div {
    float: left;
    margin-right: 10px;
  }
`

export const WarnHeading = styled(PendingHeading)`
  color: red;
`
