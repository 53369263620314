import { useState, useEffect, useContext, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'

import _ from 'lodash'

// Component Imports
import TabGroup from '../../components/Tabs/TabGroup/TabGroup'
import LinkTab from '../../components/Tabs/LinkTab/LinkTab'
import LoadingPage from '../../components/Loaders/LoadingPage/LoadingPage'
import SearchInput from '../../components/Inputs/SearchInput/SearchInput'

// Context Imports
import { SessionContext } from '../../contexts/session'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { USER_ROLES } from 'constants/USER_ROLES'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

// Lazy Module Imports
const FleetList = lazy(() => import('./modules/FleetList/FleetList'))
const AlertManagement = lazy(() =>
  import('./modules/AlertManagement/AlertManagement'),
)
const ControllerStatistics = lazy(() =>
  import('./modules/ControllerStatistics/ControllerStatistics'),
)

const SuspenseWrapper = ({ children }) => (
  <Suspense
    fallback={
      <LoadingContainer>
        <LoadingPage />
      </LoadingContainer>
    }
  >
    {children}
  </Suspense>
)

const Dashboard = ({ selectedTabInit = 0 }) => {
  const { session } = useContext(SessionContext)
  const { hasEnabledFlags, userHasRole, userHasApiAccessCode } =
    useContext(AuthorizationContext)
  const [selectedTab, setSelectedTab] = useState(selectedTabInit)
  const [searchField, setSearchField] = useState('')
  const [showControllerStatistics, setShowControllerStatistics] =
    useState(false)

  useEffect(() => {
    document.title = `Powin Command Center: ${_.startCase(
      session.environmentName,
    )}`

    const controllerStatisticsAccess = userHasRole(USER_ROLES.TECH_SUPPORT)

    if (controllerStatisticsAccess) {
      setShowControllerStatistics(true)
    }
  }, [
    hasEnabledFlags,
    session.environmentName,
    userHasApiAccessCode,
    userHasRole,
  ])

  return (
    <Container>
      <Top>
        <TabGroup stationDetailsTabs={true}>
          <LinkTab
            isSelected={selectedTab === 0}
            handleClick={() => setSelectedTab(0)}
            to={`/`}
          >
            Fleet List
          </LinkTab>
          {userHasApiAccessCode(API_ACCESS_CODES.SIX_A) && (
            <LinkTab
              isSelected={selectedTab === 1}
              handleClick={() => setSelectedTab(1)}
              to={`/alert-management`}
            >
              Alert Management
            </LinkTab>
          )}
          {showControllerStatistics && (
            <LinkTab
              isSelected={selectedTab === 2}
              handleClick={() => setSelectedTab(2)}
              to={`/controller-statistics`}
            >
              Controller Statistics
            </LinkTab>
          )}
        </TabGroup>
        <TopRight>
          <Flex>
            <SearchInput
              onChange={(e) => setSearchField(e.target.value)}
              placeholder="Search Stations"
              value={searchField}
            />
          </Flex>
        </TopRight>
      </Top>
      <DashboardContainer>
        {selectedTab === 1 && (
          <SuspenseWrapper>
            <AlertManagement searchField={searchField} />
          </SuspenseWrapper>
        )}
        {selectedTab === 2 && (
          <SuspenseWrapper>
            <ControllerStatistics searchField={searchField} />
          </SuspenseWrapper>
        )}
        {selectedTab === 0 && (
          <SuspenseWrapper>
            <FleetList searchField={searchField} />
          </SuspenseWrapper>
        )}
      </DashboardContainer>
    </Container>
  )
}

export default Dashboard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
const DashboardContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 0 0 56px;
`
const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
`
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 35px;
  border-bottom: 1px solid #d8d8d8;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
`
const TopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;

  & > div {
    margin-left: 20px;
  }
`
const Flex = styled.div`
  flex: 1;
`
