import axios from 'axios'
import { useQueries } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * only read 1x does not change often.  provides
 * @param {Array} arrayIndexes integer. Index of the array you want to retrieve the parameters from
 * @param {boolean} isEnabled set to false to disable
 * @returns {object} bmsParametersData from the station if the bmsParamsData parameter is null, or undefined. Otherwise, the passed in bmsParametersData
 */
const useBmsParamsQuery = (arrayIndexes, isEnabled) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)
  const withoutDupes = new Set(arrayIndexes)
  return useQueries({
    queries: Array.from(withoutDupes).map((id) => {
      return {
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['BmsParams', stationCode, blockIndex, id],
        queryFn: () => bmsAxiosRequest(session, stationCode, blockIndex, id),
        enabled: isEnabled,
      }
    }),
  })
}

async function bmsAxiosRequest(session, stationCode, blockIndex, arrayIndex) {
  return await axios
    .get(
      `${session.server}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/bmsParameters`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

export default useBmsParamsQuery
