import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RangePowerChart } from 'modules/Station/components/Charts/RangePowerChart/RangePowerChart'
import { COLORS } from 'design_system/colors'
import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import * as Styles from './styles'
import Card from 'components/Cards/Card/Card'
import useDcDcGroupDetailGraphQuery from 'api/useQueryHooks/useDcDcGroupDetailGraphQuery'
import useDcDcPCSesQuery from 'api/useQueryHooks/useDcDcPCSesQuery'
import { SummaryCardDcDcGroup } from 'modules/Station/components/Cards/SummaryCardDcDcGroup/SummaryCardDcDcGroup'

const DcDcGroupDetailMainColumn = () => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const { acPvBatteryIndex, dcDcGroupIndex } = useParams()
  const { data: graphData, refetch } = useDcDcGroupDetailGraphQuery(graphPeriod)
  const { data: pcsesData } = useDcDcPCSesQuery()

  const onSelectPeriod = (period) => setGraphPeriod(period)

  const dcDcPcses = pcsesData?.dcDcPcses
    .filter(
      (dcDcPcs) =>
        dcDcPcs.acPvBatteryIndex === parseInt(acPvBatteryIndex) &&
        dcDcPcs.dcDcGroupIndex === parseInt(dcDcGroupIndex),
    )
    .map((dcDcPcs) => (
      <SummaryCardDcDcGroup key={dcDcPcs.dcDcPcsIndex} dcDcPcs={dcDcPcs} />
    ))

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  return (
    <>
      <Styles.CardsWrapper>{dcDcPcses}</Styles.CardsWrapper>
      <Card
        title={<GraphHeader onSelect={onSelectPeriod} period={graphPeriod} />}
      >
        {graphData && (
          <Styles.GraphsWrapper>
            <RangePowerChart
              color={COLORS.charts.blue}
              title="Group Battery Power"
              tooltip="Power"
              unit="kW"
              data={graphData.groupBatteryPower.series}
              showXAxis={false}
              period={graphPeriod}
            />
            <RangePowerChart
              color={COLORS.charts.orange}
              title="Group PV Power"
              tooltip="Power"
              unit="kW"
              data={graphData.groupPvPower.series}
              period={graphPeriod}
            />
          </Styles.GraphsWrapper>
        )}
      </Card>
    </>
  )
}

export default DcDcGroupDetailMainColumn
