import { ReactComponent as NotificationIcon } from 'icons/notification-icon.svg'

import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'

export const NotificationSummary = ({ notifications }) => {
  const summaryMap = {
    title: 'Notifications',
    titleIcon: <NotificationIcon />,
    sections: [
      {
        sectionTitle: 'Summary',
        rows: [
          {
            label: 'Active Notifications',
            value: notifications?._page?.totalElements.toLocaleString() || 0,
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}
