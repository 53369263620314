import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

// Component Imports
import EquipmentSummaryCard from '../../../../../components/Cards/EquipmentSummaryCard'

// Icon Imports
import { ReactComponent as InverterIcon } from '../../../../../icons/dc-ac-pcs-icon.svg'

const SummaryCardInverter = ({ data }) => {
  const { stationCode, blockIndex, acBatteryId } = useParams()

  const arrayPCSIndex = Object.keys(data.arrayPcses)[0]
  const dcVoltage = _.chain(data)
    .get('arrayPcses.' + arrayPCSIndex + '.dcVoltageVolt')
    .round(1)
    .value()
  const dcCurrent = _.chain(data)
    .get('arrayPcses.' + arrayPCSIndex + '.dcCurrentAmp')
    .toNumber()
    .value()
  const acVoltage = _.chain(data)
    .get('arrayPcses.' + arrayPCSIndex + '.acVoltageVolt')
    .round(1)
    .value()
  const acCurrent = _.chain(data)
    .get('arrayPcses.' + arrayPCSIndex + '.acCurrentAmp')
    .toNumber()
    .value()
  const acFrequencyHz = _.chain(data)
    .get('arrayPcses.' + arrayPCSIndex + '.acFrequencyHz')
    .value()

  return (
    <EquipmentSummaryCard
      icon={<InverterIcon />}
      title={`PCS ${_.get(data, 'acBatteryIndex')} :: ${arrayPCSIndex}`}
      link={`/block-details/${stationCode}/${blockIndex}/block-topology/acBattery/${acBatteryId}/pcs/${arrayPCSIndex}`}
      isHealthy={_.get(data, 'arrayPcses.1.ready')}
      rows={[
        {
          label: 'DC Voltage / DC Current',
          value: `${dcVoltage.toLocaleString()} V / ${dcCurrent.toLocaleString()} A`,
        },
        {
          label: 'AC Voltage / AC Current',
          value: `${acVoltage.toLocaleString()} V / ${acCurrent.toLocaleString()} A`,
        },
        {
          label: 'AC Frequency',
          value: checkNullRoundValue(acFrequencyHz, ' Hz', 2),
        },
      ]}
    />
  )
}
export default SummaryCardInverter
