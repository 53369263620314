/**
 * Compare function to sort string asc
 * @param {element} a first string Element
 * @param {element} b second string Element
 * @returns > 0	sort a after b, e.g. [b, a]
            < 0	sort a before b, e.g. [a, b]
            === 0	keep original order of a and b
 */
export const sortStringByIdCompareFn = (a, b) => {
  if (a.arrayIndex < b.arrayIndex) {
    return -1
  } else if (a.arrayIndex > b.arrayIndex) {
    return 1
  } else if (a.arrayIndex === b.arrayIndex) {
    if (a.stringIndex < b.stringIndex) {
      return -1
    } else if (a.stringIndex > b.stringIndex) {
      return 1
    } else {
      return 0
    }
  }
}
