import _ from 'lodash'

// Style Imports
import * as Styles from './styles'

// Component Imports
import ToolbarDropdown from '../ToolbarDropdown/ToolbarDropdown'

const ToolbarMultiFilter = ({
  category,
  filters,
  handleFilter = 'initial',
}) => {
  const renderFilters = () => {
    return _.map(filters, (f) => {
      return (
        <Styles.LabelRow key={f.id}>
          <input
            type="checkbox"
            id={f.id}
            checked={!f.omit}
            onChange={() => handleFilter({ ...f, omit: !f.omit })}
          />
          <Styles.Label htmlFor={f.id} $isSelected={!f.omit}>
            {f.label}
          </Styles.Label>
        </Styles.LabelRow>
      )
    })
  }

  return <ToolbarDropdown title={category}>{renderFilters()}</ToolbarDropdown>
}

export default ToolbarMultiFilter
