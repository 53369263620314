import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  position: relative;
`
export const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: 10px;
  margin-left: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.open ? 'rgb(204, 213, 221)' : 'transparent'};
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${(props) => (props.isOpen ? '#fff' : 'transparent')};
`
export const Modal = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 0;
  width: 350px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0px 2px 2px 0px rgba(90, 90, 90, 0.5);
  border: 1px solid rgb(204, 213, 221);
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`
export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #ccd5dd;
`
export const Title = styled.div`
  flex: 1;
  font-size: 12px;
  color: #798b95;
  font-weight: 500;
  text-transform: uppercase;
`
export const HeaderLink = styled(Link)`
  text-decoration: none;
  width: 6px;
  height: 10px;
  line-height: 0;
  fill: #00b986;
`
