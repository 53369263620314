import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../components/Layouts/ListPage/ListPage'
import Summary from './Summary/AlertManagementSummary'
import AlertsTable from '../../components/Tables/AlertsTable/AlertsTable'
import useAlertsQuery from 'api/useQueryHooks/useAlertsQuery'
import NoTableData from 'components/Table/Table/NoTableData'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const StationAlertManagement = ({ setSelectedPage }) => {
  const params = useParams()

  const { data: alertData, isLoading } = useAlertsQuery({
    filters: { station: [`${params.stationCode}::${params.blockIndex}`] },
  })

  useEffect(() => {
    setSelectedPage()
    document.title = `${params.stationCode}::${params.blockIndex} - Alert Management`
  }, [params.blockIndex, params.stationCode, setSelectedPage])

  if (isLoading) return <LoadingPage />

  if (alertData.length === 0) return <NoTableData />

  return (
    <ListPage
      selectedParams={{}}
      summaryColumnComponent={<Summary data={alertData} />}
      tableComponent={<AlertsTable data={alertData} />}
      hideBreadcrumbs={true}
    />
  )
}

export default StationAlertManagement
