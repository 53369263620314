import { useCallback, useContext, useEffect, useState } from 'react'

import {
  ExcusedEnum,
  fetchRotationReasons,
} from 'api/dictionaries/rotation-excuses'
import { FONT_STYLES } from 'design_system/font_definitions'
import { FormRow } from 'components/Form/FormComponents.styled'
import { Container, Select, StringContainer } from './RotationForm.styles'
import { SessionContext } from 'contexts/session'

// used by both ArrayTable and ACBatteriesTable
export const RotationForm = ({
  ids,
  onUserInputFormChange,
  userInputForm,
  resourceType,
  label,
}) => {
  const [reasons, setReasons] = useState([])
  const { session } = useContext(SessionContext)

  const getRotationReasons = useCallback(
    async (excused) => {
      const excusedValue =
        excused === 'true' ? ExcusedEnum.excused : ExcusedEnum.non_excused
      const reasons = await fetchRotationReasons(
        resourceType,
        excusedValue,
        session,
      )
      setReasons(reasons)
    },
    [session, resourceType],
  )

  useEffect(() => {
    if (userInputForm.excused != 'choose')
      getRotationReasons(userInputForm.excused)
  }, [userInputForm.excused, getRotationReasons])

  // track form locally then send to handler
  const handleUserInputFormChange = (name, e) => {
    if (e?.target) {
      const updatedUserInputForm = { ...userInputForm, [name]: e.target.value }
      let valid = false
      if (updatedUserInputForm.outRotation === 'false') {
        valid = true
      } else {
        const isValidatedCheck =
          Object.entries(updatedUserInputForm).filter(([, v]) => v === 'choose')
            .length < 1
        if (isValidatedCheck) valid = true
      }
      onUserInputFormChange(updatedUserInputForm, valid)
    }
  }

  const pluralHandler =
    (label === 'PCS' || label === 'PV PCS') && ids.length > 1 ? 'ES' : ''

  return (
    <Container>
      <StringContainer>
        <label>{label + pluralHandler}</label>{' '}
        <span>{ids?.join(', ').replace(/:/g, ' :: ')}</span>
      </StringContainer>

      <FormRow>
        <label>ROTATION STATE</label>
      </FormRow>

      <Select
        value={userInputForm.outRotation}
        onChange={(e) => handleUserInputFormChange('outRotation', e)}
      >
        <option value="choose" disabled>
          Choose
        </option>
        <option value={false}>In Rotation</option>
        <option value={true}>Out of Rotation</option>
      </Select>

      {userInputForm.outRotation === 'true' && (
        <>
          <FormRow>
            <label>EXCUSED / UNEXCUSED</label>
          </FormRow>

          <Select
            value={userInputForm.excused}
            onChange={(e) => handleUserInputFormChange('excused', e)}
          >
            <option value="choose" disabled>
              Choose
            </option>
            <option value={true}>Excused</option>
            <option value={false}>Unexcused</option>
          </Select>
        </>
      )}

      {userInputForm.outRotation === 'true' &&
        userInputForm.excused !== 'choose' && (
          <>
            <FormRow>
              <label>REASON</label>
            </FormRow>
            <Select
              value={userInputForm.reason}
              onChange={(e) => handleUserInputFormChange('reason', e)}
            >
              <option value="choose" disabled>
                Choose Reason
              </option>
              {reasons.map((reason) => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              ))}
            </Select>
          </>
        )}

      {userInputForm.outRotation === 'true' && (
        <>
          <FormRow>
            <label htmlFor="explanation">EXPLANATION</label>
          </FormRow>
          <FONT_STYLES.ActionModalTextArea
            id="explanation"
            value={userInputForm.explanation}
            onChange={(e) => handleUserInputFormChange('explanation', e)}
            maxLength={1000}
          />
        </>
      )}
    </Container>
  )
}

export const defaultRotationFormValues = {
  outRotation: 'choose', // in / out for data api call
  excused: 'choose', // boolean
  reason: 'choose',
  explanation: '', // optional
}
