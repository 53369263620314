import { ELECTRICAL_DEVICE_TYPES } from '../../ElectricalDevices/constants'
import {
  ContentWrapper,
  DeviceList,
  DeviceListBody,
  DeviceListHeader,
  DeviceListItem,
  DeviceListRow,
  Title,
} from './styles'

const getRowId = (row) =>
  row?.topologyEntry?.entityKey.friendlyString ??
  row.topologyEntryV1.entityKey.friendlyString

const getRowStatus = (row) =>
  row?.topologyEntry?.statusMessage ?? row.topologyEntryV1.statusMessage

const headerId = {
  [ELECTRICAL_DEVICE_TYPES['PV PCSes']]: 'ID (BATTERY :: PV PCS)',
  [ELECTRICAL_DEVICE_TYPES['PCSes']]: 'ID (ARRAY :: PCS)',
  [ELECTRICAL_DEVICE_TYPES['DC:DC PCSes']]: 'ID (BATTERY :: GROUP :: DC PCS)',
  [ELECTRICAL_DEVICE_TYPES['PCS Modules']]: 'ID (ARRAY :: PCS MODULE)',
}

export const ClearDeviceFaultsUi = ({ deviceType, faultingDevices }) => {
  return (
    <ContentWrapper>
      <Title>{`FAULTED ${deviceType}`}</Title>
      <DeviceList>
        <DeviceListHeader>
          <DeviceListRow>
            <DeviceListItem>{headerId[deviceType] ?? 'ID'}</DeviceListItem>
            <DeviceListItem>STATUS</DeviceListItem>
          </DeviceListRow>
        </DeviceListHeader>
        <DeviceListBody>
          {faultingDevices.map((device, i) => (
            <DeviceListRow key={`${getRowId(device)}${i}`}>
              <DeviceListItem>{getRowId(device)}</DeviceListItem>
              <DeviceListItem>{getRowStatus(device)}</DeviceListItem>
            </DeviceListRow>
          ))}
        </DeviceListBody>
      </DeviceList>
    </ContentWrapper>
  )
}
