import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import EnergySegmentsSummary from './Summary/EnergySegmentsSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useEnergySegmentsQuery from 'api/useQueryHooks/useEnergySegmentsQuery'
import CentipedeEnergySegmentsTable from 'modules/Station/components/Tables/CentipedeEnergySegmentsTable/CentipedeEnergySegmentsTable'

const EnergySegments = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'centipede-energy-segments' })
    document.title = `${stationCode}::${blockIndex} - Centipede Energy Segments`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<EnergySegmentsSummary data={data} />}
      tableComponent={
        <CentipedeEnergySegmentsTable
          data={data}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
      className="energySgmt"
    />
  )

  return handleQueryLoadingAndError(useEnergySegmentsQuery(), ListPageJsx)
}

export default EnergySegments
