import styled from 'styled-components/macro'

export const Container = styled.div`
  display: ${(props) => (props.$isHorizontal ? 'flex' : 'block')};
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`
export const TitleRow = styled.div`
  display: flex;
  align-items: ${(props) => (props.$isHorizontal ? 'flex-start' : 'center')};
  border-bottom: ${(props) =>
    props.$isHorizontal ? 'none' : '1px solid #d8d8d8'};
  border-right: ${(props) =>
    props.$isHorizontal ? '1px solid #d8d8d8' : 'none'};
  padding: 15px 15px;
  width: ${(props) => (props.$isHorizontal ? '120px' : 'initial')};
`
export const IconContainer = styled.div`
  width: 15px;
  height: 15px;
  fill: #798b95;
  margin-right: 10px;
`
export const Title = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  color: #322242;
  text-transform: uppercase;
`
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: ${(props) =>
    props.$contentPadding ? props.$contentPadding : '20px 25px'};
  height: ${(props) => (props.$height ? props.$height : 'initial')};
`
