import { fetchCellData } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * Will use params to load correct cell details by array and string id
 * @returns 5 second interval block status
 */
const useCellDetailsQuery = () => {
  const { stationCode, blockIndex, arrayId, stringID } = useParams()
  const { session, setSession } = useContext(SessionContext)

  return useQuery(
    ['cellDetails', stationCode, blockIndex, arrayId, stringID],
    () =>
      fetchCellData(
        stationCode,
        blockIndex,
        arrayId,
        stringID,
        session,
        setSession,
      ),
    { refetchInterval: 5000 },
  )
}

export default useCellDetailsQuery
