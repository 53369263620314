import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * TODO connect to real api
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useEnvironmentalControllerListQuery = () => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    ['environmentalControllers', stationCode, blockIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/environmentalcontrollers`,
        axiosOptions,
      )
      return response.data
    },
    { refetchInterval: 5000 },
  )
}

export default useEnvironmentalControllerListQuery
