import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import _ from 'lodash'

/**
 * Get All station alerts
 * @returns UseQueryResult
 * {filters: { state: ['Active']} }  -> load active alerts
 */
const useAlertsQuery = (payload = { filters: {} }, enabled = true) => {
  const { session } = useContext(SessionContext)
  let queryName = 'all'
  if (!_.isEmpty(payload.filters)) queryName = JSON.stringify(payload.filters)
  return useQuery(
    ['alerts', queryName],
    () => axiosAlertsQuery(session, payload),
    { refetchInterval: 5000, enabled: enabled },
  )
}

export default useAlertsQuery

export const axiosAlertsQuery = async (session, payload) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }

  const response = await axios.post(
    `${apiUrl}alerts/filter`,
    payload,
    axiosOptions,
  )

  const nonResolved = response.data.bessStatusAlerts.filter((alert) => {
    if (alert.alertStatus !== 'Resolved') return alert.alertStatus
  })

  return nonResolved
}
