import { FC } from 'react'
import { BlockRotationForm } from './form'
import { BlockRotationModalProps } from './block-rotation'
import { BlockRotationResult } from './result'
import { defaultRotationFormValues } from '../SetRotation/RotationForm'
import CommandModalTemplate from '../CommandModalTemplate'

export const BlockRotationModal: FC<BlockRotationModalProps> = (props) => {
  const { ids, setShowCommandModal } = props
  const modalTitles = {
    start: 'Set Block Rotation',
    finish: 'Set Block Rotation',
  }
  const queryName = 'setBlockRotation'

  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={BlockRotationResult}
      UserInputFormJsx={BlockRotationForm}
      queryName={queryName}
      isFormValidated
    />
  )
}
