// Component Imports
import SummaryColumn from '../../../components/SummaryColumn/SummaryColumn'

// Icon Imports
import { ReactComponent as AlertIcon } from '../../../../../icons/bell-icon.svg'

const Summary = ({ data }) => {
  const activeAlarmsCount = data.filter((x) => x.alertState === 'Active').length
  const summaryMap = {
    title: 'Alert Management',
    titleIcon: <AlertIcon />,
    sections: [
      {
        sectionTitle: 'Summary',
        rows: [
          {
            label: 'Active Alarms',
            value: activeAlarmsCount.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default Summary
