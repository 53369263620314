import Highcharts from 'highcharts/highstock'
import highchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import { COLORS } from 'design_system/colors'
import * as Styles from './SeriesChart.styles'
import { Typography } from 'components/typography/Typography.styled'
import * as dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)

const tooltipFormatter = (unit) =>
  function (_tooltip, x = this.x, points = this.points) {
    const dateTime = `<span style="font-size: 10px">
      ${dayjs(x).format('dddd, MMM D, YYYY, HH:mm:ss')}
    </span>`

    const formattedArray = points.map((point) => {
      return `<span style="color:${point.color}">●</span>
      <span>
        ${point.series.name}: <b>${point.y.toFixed(2)}${unit}</b>
      </span>`
    })

    return `${dateTime}<br />${formattedArray.join('<br />')}`
  }

/**
 * Render a time series chart
 * @param {object} props
 * @example
 * <SeriesChart data={
 *    {
 *      title: 'Reactive Power',
 *      showXAxis: true,
 *      unit: ' kVAR',
 *      colors: ['#fff', '#000'] // optional
 *      series: [
 *        {
 *          name: 'HVAC 1',
 *          graphData: [[1701199310678, 14],...] // [unix time in milliseconds, value]
 *          color: '#fff' // optional
 *        }
 *      ],
 *    }
 * } />
 */
export const SeriesChart = ({ data, period }) => {
  highchartsMore(Highcharts)
  let isXAxisFirstPoint = true
  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      height: 250,
      zooming: {
        mouseWheel: false,
      },
    },
    credits: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    title: {
      text: null,
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: data.unit,
      formatter: tooltipFormatter(data.unit),
    },
    xAxis: {
      visible: data.showXAxis,
      type: 'datetime',
      labels: {
        formatter: (val) => {
          if (period === '1w') {
            return `<span style="font-size: 10px">
            ${dayjs(val.value).format('MMM D')}
          </span>`
          }
          if (isXAxisFirstPoint) {
            const dateTime = `<span style="font-size: 10px">
            ${dayjs(val.value).format('HH:mm:ss<br />MMM D, Y')}
          </span>`
            if (period !== '1w') isXAxisFirstPoint = false
            return `${dateTime}`
          }
          const dateTime = `<span style="font-size: 10px">
            ${dayjs(val.value).format('HH:mm:ss')}
          </span>`
          return `${dateTime}`
        },
      },
    },
    yAxis: {
      title: {
        text: data.unit,
        style: {
          color: COLORS.font_secondary,
        },
      },
      labels: {
        formatter: (val) => val.value.toFixed(2),
      },
      plotLines: data.plotLines
        ? [
            {
              value: data.plotLineMax,
              color: 'grey',
              dashStyle: 'dash',
              width: 1,
              zIndex: 1,
              label: {
                text: data.plotLineMaxText,
                style: {
                  color: '#999',
                  fontSize: 10,
                },
              },
            },
            {
              value: data.plotLineMin,
              color: 'grey',
              dashStyle: 'dash',
              width: 1,
              zIndex: 1,
              label: {
                text: data.plotLineMinText,
                style: {
                  color: '#999',
                  fontSize: 10,
                },
              },
            },
          ]
        : '',
    },
    colors: data?.colors ?? Object.values(COLORS.charts),
    series: data.series.map((data) => {
      return {
        name: data.name,
        data: data.graphData.map((item) =>
          item.hasData ? [item.time, item.avg] : null,
        ),
        color: data.color,
        marker: {
          fillColor: 'white',
          enabled: false,
          lineWidth: 2,
          lineColor: data.color,
        },
      }
    }),
  }

  return (
    <div>
      <Styles.GraphTitleWrapper>
        <Typography $strong>{data.title}</Typography>
      </Styles.GraphTitleWrapper>
      <HighchartsReact highcharts={Highcharts} options={options} oneToOne />
    </div>
  )
}
