import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import { fetchEksPpcDetails } from 'api/queries.api'

const useEksPpcDetailsQuery = (options = {}) => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    ['eksPpcDetails', stationCode, blockIndex],
    async () => {
      const response = await fetchEksPpcDetails(
        session,
        stationCode,
        blockIndex,
      )
      return response
    },
    { refetchInterval: 5000, ...options },
  )
}

export default useEksPpcDetailsQuery
