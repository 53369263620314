import { useParams } from 'react-router-dom'

import SummaryColumn from '../../../../components/SummaryColumn/SummaryColumn'
import { ReactComponent as PCSIcon } from '../../../../../../icons/dc-ac-pcs-icon.svg'
import HealthStatus from '../../../../../../components/Status/HealthStatus/HealthStatus'
import RotationStatusIcon from 'components/Status/RotationStatusIcon'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'

const PCSDetailsSummary = ({ data }) => {
  const { acBatteryId, pcsIndex } = useParams()

  const summaryMap = {
    title: `PCS ${acBatteryId} :: ${pcsIndex}`,
    titleIcon: <PCSIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={data.isHealthy} />,
            isIcon: true,
          },
          {
            label: 'In Rotation',
            value: <RotationStatusIcon outRotation={data.outRotation} />,
            isIcon: true,
          },
        ],
      },
      {
        sectionTitle: 'Current Readings',
        rows: [
          {
            label: 'DC',
            value: `${checkNullRoundValue(
              data.dcVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.dcCurrentAmp, ' A')}`,
          },
          {
            label: 'AC A > N',
            value: `${checkNullRoundValue(
              data.acPhaseANVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseACurrentAmp, ' A')}`,
          },
          {
            label: 'AC B > N',
            value: `${checkNullRoundValue(
              data.acPhaseBNVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseBCurrentAmp, ' A')}`,
          },
          {
            label: 'AC C > N',
            value: `${checkNullRoundValue(
              data.acPhaseCNVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseCCurrentAmp, ' A')}`,
          },
          {
            label: 'AC A > B',
            value: `${checkNullRoundValue(
              data.acPhaseABVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseACurrentAmp, ' A')}`,
          },
          {
            label: 'AC B > C',
            value: `${checkNullRoundValue(
              data.acPhaseBCVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseBCurrentAmp, ' A')}`,
          },
          {
            label: 'AC C > A',
            value: `${checkNullRoundValue(
              data.acPhaseCAVoltageVolt,
              ' V',
            )} / ${checkNullRoundValue(data.acPhaseCCurrentAmp, ' A')}`,
          },
          {
            label: 'AC Command',
            value: `${checkNullRoundValue(
              data.acCmdRealPowerKW,
              ' kW',
            )} / ${checkNullRoundValue(data.acCmdReactivePowerKVAR, ' kVAR')}`,
          },
          {
            label: 'AC Setting',
            value: `${checkNullRoundValue(
              data.acRealPowerSettingKW,
              ' kW',
            )} / ${checkNullRoundValue(
              data.acReactivePowerSettingKVAR,
              ' kVAR',
            )}`,
          },
          {
            label: 'Actual',
            value: `${checkNullRoundValue(
              data.acRealPowerKW,
              ' kW',
            )} / ${checkNullRoundValue(data.acReactivePowerKVAR, ' kVAR')}`,
          },
          {
            label: 'Frequency',
            value: checkNullRoundValue(data.acFrequencyHz, ' Hz', 2),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default PCSDetailsSummary
