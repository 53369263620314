import { ResourceEnum } from 'api/dictionaries/rotation-excuses'
import { FC } from 'react'
import { RotationForm } from '../../SetRotation/RotationForm'
import { BlockRotationFormProps } from './form'

export const BlockRotationForm: FC<BlockRotationFormProps> = (props) => {
  const { ids, userInputForm, onUserInputFormChange } = props
  const label = 'Blocks'
  return (
    <RotationForm
      ids={ids}
      userInputForm={userInputForm}
      onUserInputFormChange={onUserInputFormChange}
      resourceType={ResourceEnum.string}
      label={label}
    />
  )
}
