import React from 'react'
import _ from 'lodash'

// Style Imports
import * as Styles from './styles'

const FilterToggles = ({ filters, handleToggle }) => {
  const sections = _.chain(filters)
    .groupBy('section')
    .map((filters, section) => {
      return {
        section,
        filters,
      }
    })
    .value()

  const renderSections = (data) => {
    return _.map(data, (d) => {
      return (
        <Styles.Section key={d.section}>
          <Styles.SectionName>{d.section}</Styles.SectionName>
          <div>{renderCheckboxes(d.filters)}</div>
        </Styles.Section>
      )
    })
  }

  const renderCheckboxes = (data) => {
    return _.map(data, (c) => {
      return (
        <Styles.Row key={c.id}>
          <input
            type="checkbox"
            id={c.id}
            checked={!c.omit}
            onChange={() => handleToggle({ ...c, omit: !c.omit })}
          />
          <Styles.Label htmlFor={c.id} checked={!c.omit}>
            {c.name}
          </Styles.Label>
        </Styles.Row>
      )
    })
  }

  return <div>{renderSections(sections)}</div>
}

export default FilterToggles
