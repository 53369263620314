import * as Styles from './card.styled'

const Card = ({
  icon,
  title,
  children,
  height,
  isHorizontal = false,
  contentPadding,
}) => {
  return (
    <Styles.Container $isHorizontal={isHorizontal}>
      <Styles.TitleRow $isHorizontal={isHorizontal}>
        {icon && <Styles.IconContainer>{icon}</Styles.IconContainer>}
        <Styles.Title>{title}</Styles.Title>
      </Styles.TitleRow>
      <Styles.Content $height={height} $contentPadding={contentPadding}>
        {children}
      </Styles.Content>
    </Styles.Container>
  )
}

export default Card
