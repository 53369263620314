import { fetchEksPpcDetails, postEksPpcSetpoints } from 'api/queries.api'
import ModalV2 from 'components/Modals/ModalV2/ModalV2'
import { SessionContext } from 'contexts/session'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import {
  Column,
  Container,
  Field,
  Grid,
  Input,
  InputWrapper,
  Unit,
} from './styles'

import {
  kilovar_to_megavar,
  kilovolt_to_volt,
  kilowatt_to_megawatt,
  megavar_to_kilovar,
  megawatt_to_kilowatt,
  volt_to_kilovolt,
} from 'utils/powerUnits'
import { ModalFooter } from './ModalFooter'

/**
 *
 * @param {object} props - Same props as ModalV2 component
 */
export function EditPpcSetpointsModal(props) {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues: async () => {
      const data = await fetchEksPpcDetails(session, stationCode, blockIndex)
      return {
        totalRealPowerCmdkW: kilowatt_to_megawatt(
          data?.totalRealPowerSettingkW ?? 0,
        ),
        rampRateUpCmdkWperMin: kilowatt_to_megawatt(
          data?.rampRateUpSettingkWperMin ?? 0,
        ),
        rampRateDownCmdkWperMin: kilowatt_to_megawatt(
          data?.rampRateDownSettingkWperMin ?? 0,
        ),
        totalReactivePowerCmdkVAr: kilovar_to_megavar(
          data?.totalReactivePowerSettingkVAr ?? 0,
        ),
        siteVoltageCmdV: volt_to_kilovolt(data?.siteVoltageSettingV ?? 0),
        sitePowerFactorCmd: data?.sitePowerFactorSetting ?? 0,
        siteFrequencyCmdHz: data?.siteFrequencySettingHz ?? 0,
        scheduleSystemReferenceCmdkW: kilowatt_to_megawatt(
          data?.scheduleSystemReferenceSettingkW,
        ),
      }
    },
    resetOptions: { keepDirtyValues: true },
  })

  function closeModal() {
    props?.handleClose?.()
  }

  async function onSubmit(formData) {
    const data = {
      totalRealPowerCmdkW: Math.trunc(
        megawatt_to_kilowatt(+formData.totalRealPowerCmdkW),
      ),
      totalReactivePowerCmdkVAr: Math.trunc(
        megavar_to_kilovar(+formData.totalReactivePowerCmdkVAr),
      ),
      siteVoltageCmdV: Math.trunc(kilovolt_to_volt(+formData.siteVoltageCmdV)),
      sitePowerFactorCmd: +formData.sitePowerFactorCmd,
      rampRateUpCmdkWperMin: Math.trunc(
        megawatt_to_kilowatt(+formData.rampRateUpCmdkWperMin),
      ),
      rampRateDownCmdkWperMin: Math.trunc(
        megawatt_to_kilowatt(+formData.rampRateDownCmdkWperMin),
      ),
      siteFrequencyCmdHz: +formData.siteFrequencyCmdHz,
      scheduleSystemReferenceCmdkW: Math.trunc(
        megawatt_to_kilowatt(+formData.scheduleSystemReferenceCmdkW),
      ),
    }

    try {
      closeModal()
      props.toast.toastPending('Submitting PPC settings...')
      const res = await postEksPpcSetpoints(
        session,
        stationCode,
        blockIndex,
        data,
      )

      if (!res?.valid) throw res
      props.toast.toastSuccess('PPC settings submitted succesfully!')
    } catch (e) {
      props.toast.toastError(
        e?.response?.data?.message ?? 'Something went wrong, please try again.',
      )
    }
  }

  return (
    <Container>
      <ModalV2
        title="EKS PPC Setpoints"
        {...props}
        footerContent={
          <ModalFooter
            formName="editPpc"
            disableSubmit={!isValid || isSubmitting}
            onCancel={closeModal}
          />
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} id="editPpc">
          <Grid>
            <Column>
              <Field>
                <label htmlFor="totalRealPowerCmdkW">Total Real Power</label>
                <InputWrapper>
                  <Input
                    id="totalRealPowerCmdkW"
                    type="number"
                    min={0}
                    step="any"
                    {...register('totalRealPowerCmdkW', {})}
                    aria-invalid={Boolean(errors.totalRealPowerCmdkW)}
                  />
                  <Unit>MW</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="rampRateDownCmdkWperMin">Ramp Rate Down</label>
                <InputWrapper>
                  <Input
                    id="rampRateDownCmdkWperMin"
                    type="number"
                    min={0}
                    step="any"
                    {...register('rampRateDownCmdkWperMin')}
                    aria-invalid={Boolean(errors.rampRateDownCmdkWperMin)}
                  />
                  <Unit>MW / min</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="scheduleSystemReferenceCmdkW">
                  Schedule System Reference
                </label>
                <InputWrapper>
                  <Input
                    id="scheduleSystemReferenceCmdkW"
                    type="number"
                    min={0}
                    step="any"
                    {...register('scheduleSystemReferenceCmdkW')}
                    aria-invalid={Boolean(errors.scheduleSystemReferenceCmdkW)}
                  />
                  <Unit>MW</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="totalReactivePowerCmdkVAr">
                  Total Reactive Power
                </label>
                <InputWrapper>
                  <Input
                    id="totalReactivePowerCmdkVAr"
                    type="number"
                    min={0}
                    step="any"
                    {...register('totalReactivePowerCmdkVAr')}
                    aria-invalid={Boolean(errors.totalReactivePowerCmdkVAr)}
                  />
                  <Unit>MVAr</Unit>
                </InputWrapper>
              </Field>
            </Column>
            <Column>
              <Field>
                <label htmlFor="rampRateUpCmdkWperMin">Ramp Rate Up</label>
                <InputWrapper>
                  <Input
                    id="rampRateUpCmdkWperMin"
                    type="number"
                    min={0}
                    step="any"
                    {...register('rampRateUpCmdkWperMin')}
                    aria-invalid={Boolean(errors.rampRateUpCmdkWperMin)}
                  />
                  <Unit>MW / min</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="siteFrequencyCmdHz">Frequency Command</label>
                <InputWrapper>
                  <Input
                    id="siteFrequencyCmdHz"
                    type="number"
                    min={0}
                    step="any"
                    {...register('siteFrequencyCmdHz')}
                    aria-invalid={Boolean(errors.siteFrequencyCmdHz)}
                  />
                  <Unit>Hz</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="siteVoltageCmdV">Voltage Command</label>
                <InputWrapper>
                  <Input
                    id="siteVoltageCmdV"
                    type="number"
                    min={0}
                    step="any"
                    {...register('siteVoltageCmdV')}
                    aria-invalid={Boolean(errors.siteVoltageCmdV)}
                  />
                  <Unit>kV</Unit>
                </InputWrapper>
              </Field>
              <Field>
                <label htmlFor="sitePowerFactorCmd">Power Factor Command</label>
                <InputWrapper>
                  <Input
                    id="sitePowerFactorCmd"
                    type="number"
                    min={0}
                    step="any"
                    {...register('sitePowerFactorCmd')}
                    aria-invalid={Boolean(errors.sitePowerFactorCmd)}
                  />
                  <Unit>p. u.</Unit>
                </InputWrapper>
              </Field>
            </Column>
          </Grid>
        </form>
      </ModalV2>
    </Container>
  )
}
