import getAxiosBasicUrlAndHeader from 'api/helpers/getAxiosBasicUrlAndHeader'
import axios from 'axios'

/**
 * required 8PC
 * @param {Object} payload
 * @param {*} session
 * @param {string} stationCode
 * @param {string} blockIndex
 * @returns Promise success or fail message
 */
const clearSensorFaultsService = async (
  payload,
  session,
  stationCode,
  blockIndex,
  additionalPayload,
) => {
  const { sensorIndex } = payload
  if (!sensorIndex) throw Error('Invalid query type')

  const jsonBody = {
    sensorIndexes: [sensorIndex],
  }

  const { apiUrl, axiosHeader } = getAxiosBasicUrlAndHeader(session)

  return await axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/centipedelineups/${additionalPayload.centipedeLineupId}/${additionalPayload.segment}segments/${additionalPayload.segmentId}/clearfaults`,
      jsonBody,
      axiosHeader,
    )
    .then((res) => res.data)
}

export default clearSensorFaultsService
