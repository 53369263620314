import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

// Util Imports
import { renderPlotlines } from '../../../../../utils/charts.utils'

//const plot1Color = '#1795c3';
const plot2Color = '#9d71bb'

//to round up to two decimal places
const roundToHundredths = (num) => {
  return Math.ceil(num * 100) / 100
}

const StringAmpDistribution = ({ data, viewType }) => {
  const formatAmpData = (data) => {
    return _.map(data, (d) => {
      return {
        name: d.id,
        y: roundToHundredths(d.currentAmps),
        color:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
            ? 'red'
            : 'orange',
      }
    })
  }

  const ampSeries = {
    name: 'Amps',
    turboThreshold: 10000000,
    data: formatAmpData(data),
    color: plot2Color,
  }

  const [chartOptions, setChartOptions] = useState({
    title: {
      text: undefined,
    },
    boost: {
      enabled: true,
      seriesThreshold: 100,
    },
    chart: {
      type: 'column',
      zoomType: 'y',
      marginLeft: 70,
      panning: {
        enabled: true,
        type: 'y',
      },
      panKey: 'alt',
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        color: plot2Color,
      },
      column: {
        minPointLength: 3,
        turboThreshold: 10000000,
      },
    },
    tooltip: {
      formatter: function () {
        return this.x + ': ' + this.y + ' A'
      },
      shared: true,
      crosshairs: true,
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      crosshair: true,
      title: {
        text: undefined,
        style: {
          textTransform: 'capitalize',
        },
      },
      type: 'category',
      labels: {
        format: '{text}',
      },
      plotLines: renderPlotlines(data, viewType),
      categories: _.map(data, (d) => d.id),
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        format: '{text} A',
      },
      opposite: false,
      max: 240,
      min: -240,
      tickAmount: 7,
    },
    series: ampSeries,
  })

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        plotLines: renderPlotlines(data, viewType),
        categories: _.map(data, (d) => d.id),
      },
      series: ampSeries,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Container>
  )
}

export default StringAmpDistribution

const Container = styled.div`
  flex: 1;

  & > div {
    height: 100%;
  }
`
