import SummaryColumn from '../../../../components/SummaryColumn/SummaryColumn'
import { ReactComponent as InverterIcon } from '../../../../../../icons/dc-ac-pcs-icon.svg'
import BinaryBarChart from '../../../../../../components/Charts/BinaryBarChart/BinaryBarChart'

const PvPcsSummary = ({ data }) => {
  const count = data.length

  const inRotationCount = data.filter((p) => !p.outRotation).length
  const healthyCount = data.filter((p) => p.healthy && !p.faults.length).length
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `PV PCSes`,
    titleIcon: <InverterIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy Pcses: ${healthyCount} | Unhealthy Pcses: ${unhealthyCount}`}
        id="pvPcsHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'In Rotation',
            value: inRotationCount.toLocaleString(),
          },
          {
            label: 'Ready',
            value: healthyCount.toLocaleString(),
          },
          {
            label: 'Total',
            value: count.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default PvPcsSummary
