import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
`
export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 -10px 25px;
`
export const Spacer = styled.div`
  padding: 0 10px 10px;
  flex: 1;
  min-width: 350px;
`
