import React, { useContext, useCallback, useMemo } from 'react'
import { SessionContext } from './session'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

/**
 * @returns {object} functions: {hasEnabledFlags, userHasRole, userHasApiAccessCode,}
 */
const AuthorizationContext = React.createContext(null)

/**
 * use this context to check for feature toggles, roles
 * @param {object} children all child jsx elements wishing to have this context
 * @returns {object} functions: hasEnabledFlags, userHasRole, userHasApiAccessCode
 * check enabled toggle, check user role, check api access code like 6A
 */
const AuthorizationContextProvider = ({ children }) => {
  const { session } = useContext(SessionContext)
  const isValidSession = !!session.token

  // only call once session is valid
  const { data: enabledFeatures } = useFeatureToggles(session, isValidSession)

  const userRoles = getUserRoles(session)
  const sessionDataApiGroupLimits =
    session?.userAuth?.settings.DATA_API_GROUP_LIMITS
  const isSuperUser = userRoles?.isSuperUser

  /**
   * check if user has appropriate api code to call
   * @param {"0U","1BPE","2B","3DA","4S","5C","6A","7SC","8PC","9AC","10UI"} apiGroupLimitCodes
   * @returns {boolean}
   */
  const userHasApiAccessCode = useCallback(
    (apiGroupLimitCodesToCheck) => {
      if (!apiGroupLimitCodesToCheck) {
        // eslint-disable-next-line no-console
        console.error('apiGroupLimitCodes must always be defined')
        return false
      }
      if (isSuperUser) return true
      const usersAllowedCodes = () => {
        const dataApiGroupLimits = sessionDataApiGroupLimits || ''
        // convert cryptic string format to an object useful for checking access
        // example "0U=-1/1,1BPE=-1/1,2B=-1/1,3DA=-1/1,4S=-1/1,5C=-1/1,6A=-1/1,7SC=-1/1,8PC=-1/1,9AC=-1/1,10UI=-1/1"
        const ret = {}
        const a = dataApiGroupLimits.split(',')
        if (a[0] === '') return ret
        a.forEach((item) => {
          const [code, value] = item.split('=')
          const numerator = value.split('/')[0]
          ret[code] = Number(numerator) !== 0
        })

        return ret
      }
      // return true if user has permission for all the feature names in the array
      if (typeof apiGroupLimitCodesToCheck == 'string') {
        apiGroupLimitCodesToCheck = [apiGroupLimitCodesToCheck]
      }
      const usersCodes = usersAllowedCodes()
      const ans = apiGroupLimitCodesToCheck.reduce((previous, featureName) => {
        return previous && !!usersCodes[featureName]
      }, true)
      return ans
    },
    [isSuperUser, sessionDataApiGroupLimits],
  )

  const providerValue = useMemo(() => {
    /**
     * check if any feature in the array is enabled, toggle check
     * @param {string|string[]} flags - A flag or an array of flags to check
     * @returns boolean
     */
    const hasEnabledFlags = (flags) => {
      // Ensure flags is always an array
      const flagsArray = Array.isArray(flags) ? flags : [flags]
      // Check if any flag is enabled
      return flagsArray.some((flag) => enabledFeatures?.includes(flag))
    }
    return {
      hasEnabledFlags,
      userHasRole: userRoles.userHasRole,
      userHasApiAccessCode,
    }
  }, [enabledFeatures, userHasApiAccessCode, userRoles.userHasRole])

  return (
    <AuthorizationContext.Provider value={providerValue}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export { AuthorizationContextProvider, AuthorizationContext }

const REFETCH_INTERVAL = 1000 * 60 * 2 // 2 minutes

// private internal, call from context
const useFeatureToggles = (session, isValidSession) => {
  return useQuery(
    ['featureToggles'],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(`${apiUrl}enabledtoggles`, axiosOptions)
      return response.data
    },
    {
      refetchInterval: REFETCH_INTERVAL,
      staleTime: REFETCH_INTERVAL,
      enabled: !!isValidSession,
    },
  )
}

// private internal, call from context
/**
 * checks if user has role or is superuser
 * @param {object} session
 * @returns { userHasRole,isSuperUser }
 */
const getUserRoles = (session) => {
  if (!session) return null
  const userRoles = session?.userAuth?.roles

  const isSuperUser = session?.userAuth?.settings?.SUPERUSER === '1'

  /**
   *
   * @param {*} roles can be a string or array of strings made up of USER_ROLES constant
   * @returns {boolean} true if user has role or all roles in array
   */
  const userHasRole = (roles) => {
    if (!userRoles) return false
    if (isSuperUser) return true
    const rolesArr = typeof roles === 'string' ? new Array(roles) : roles

    return rolesArr.reduce((acc, cur) => acc && userRoles.includes(cur), true)
  }
  return {
    userHasRole,
    isSuperUser,
  }
}
