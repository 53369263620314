import {
  setBlockContactors,
  setBlockContactors2,
  setArrayContactors,
  setArrayContactors2,
  setArrayRotation,
  setStringContactors,
  setStringContactors2,
  setPcsRotation,
  setRotation,
  setPreferredCurrent,
} from 'api/queries.api'
import { setBlockRotationService } from 'api/rotation'
import { setBlockInverterRotationService } from 'api/rotation/block-pcs-rotation'
import BalancingService from './Balancing/BalancingService'
import { setAppStatus } from 'api/setAppStatus'
import setPlugInStatus from 'api/setPlugInStatus/setPlugInStatus'
import alertAssignService from 'modules/Dashboard/modules/AlertManagement/row-actions/assign-alert/alert-assign-service'
import alertAcknowledgeService from 'modules/Dashboard/modules/AlertManagement/row-actions/acknowledge-alert/alert-acknowledge-service'
import alertMuteService from 'modules/Dashboard/modules/AlertManagement/row-actions/mute-alert/alert-mute-service'
import alertUnmuteService from 'modules/Dashboard/modules/AlertManagement/row-actions/unmute-alert/alert-unmute-service'
import alertResolveService from 'modules/Dashboard/modules/AlertManagement/row-actions/resolve-alert/alert-resolve-service'
import clearDeviceFaultsService from 'modules/Dashboard/modules/AlertManagement/row-actions/clear-device-faults/clear-device-faults-service'
import clearSensorFaultsService from 'modules/Dashboard/modules/AlertManagement/row-actions/clear-sensor-faults/clear-sensor-faults-service'
import clearEnclosureFaultsService from 'modules/Dashboard/modules/AlertManagement/row-actions/clear-enclosure-faults/clear-enclosure-faults-service'
import { setDcDcGroupRotationService } from 'api/rotation/dc-dc-group-rotation'
import { setDcDcPcsRotationService } from 'api/rotation/dc-dc-pcs-rotation'
import { setPvPcsRotationService } from 'api/rotation/pv-pcs-rotation'

// Uses queryName to call correct command from action modal to data api
const handleCommandSet = (
  queryName,
  stationCode,
  blockIndex,
  ids,
  userInputForm,
  session,
  setCommandResult,
  isValidated,
  setShowCommandModal,
  setIsModalOpen,
  additionalPayload,
) => {
  const errorMessage = (error) =>
    setCommandResult({ status: 'error', message: error.message })
  const successMessage = () =>
    setCommandResult({
      status: 'success',
      message: 'Command sent successfully',
    })
  const processAlertRes = (res) =>
    res.valid === true ? setIsModalOpen(false) : errorMessage(res)

  switch (queryName) {
    // CONTACTORS
    case 'setStringContactors':
      setStringContactors(
        stationCode,
        blockIndex,
        ids,
        userInputForm.contactorState,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setStringContactors2':
      setStringContactors2(
        stationCode,
        blockIndex,
        ids,
        userInputForm.contactorState,
        userInputForm.ignoreHighCellGroupVoltageAlarm,
        userInputForm.ignoreLowCellGroupVoltageAlarm,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setArrayContactors':
      setArrayContactors(
        stationCode,
        blockIndex,
        ids,
        userInputForm.contactorState,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setArrayContactors2':
      setArrayContactors2(
        stationCode,
        blockIndex,
        ids,
        userInputForm.contactorState,
        userInputForm.ignoreHighCellGroupVoltageAlarm,
        userInputForm.ignoreLowCellGroupVoltageAlarm,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setBlockContactors':
      setBlockContactors(ids, userInputForm.contactorState, session).then(
        (response) => setCommandResult({ ...response }),
      )
      break
    case 'setBlockContactors2':
      setBlockContactors2(
        stationCode,
        blockIndex,
        userInputForm.contactorState,
        userInputForm.ignoreHighCellGroupVoltageAlarm,
        userInputForm.ignoreLowCellGroupVoltageAlarm,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break
    // ROTATION
    case 'setRotation':
      setRotation(stationCode, blockIndex, ids, session, userInputForm)
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setArrayRotation':
      setArrayRotation(stationCode, blockIndex, ids, session, userInputForm)
        .then(successMessage)
        .catch(errorMessage)
      break
    case 'setPCSRotation':
      {
        const { outRotation, excused, reason, explanation } = userInputForm
        setPcsRotation(
          stationCode,
          blockIndex,
          ids,
          outRotation,
          excused,
          reason,
          explanation,
          session,
        )
          .then(successMessage)
          .catch(errorMessage)
      }
      break
    case 'setACRotation':
      if (isValidated) {
        setArrayRotation(stationCode, blockIndex, ids, session, userInputForm)
          .then(successMessage)
          .catch(errorMessage)
      }
      break
    case 'setBlockRotation':
      setBlockRotationService(ids, userInputForm, session).then((response) =>
        setCommandResult({ ...response }),
      )
      break
    case 'setBlockInverterRotation':
      setBlockInverterRotationService(ids, userInputForm, session).then(
        (response) => setCommandResult({ ...response }),
      )
      break
    case 'setDcDcGroupRotation':
      {
        const { outRotation, excused, reason, explanation } = userInputForm
        setDcDcGroupRotationService(
          stationCode,
          blockIndex,
          ids,
          { outRotation, excused, reason, explanation },
          session,
        )
          .then(successMessage)
          .catch(errorMessage)
      }
      break
    case 'setDcDcPcsRotation':
      setDcDcPcsRotationService(
        stationCode,
        blockIndex,
        ids,
        userInputForm,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
        break
    case 'setPVPCSRotation':
      setPvPcsRotationService(
        stationCode,
        blockIndex,
        ids,
        userInputForm,
        session,
      )
        .then(successMessage)
        .catch(errorMessage)
      break

    // BALANCING
    case 'setArrayBalancing':
    // falls through
    case 'setStringBalancing':
    // falls through
    case 'setACBatteryBalancing':
      BalancingService(ids, userInputForm, session, stationCode, blockIndex)
        .then(successMessage)
        .catch(errorMessage)
      break

    //Preferred Current
    case 'setPreferredCurrent':
      setPreferredCurrent(stationCode, blockIndex, ids, userInputForm, session)
        .then(successMessage)
        .catch(errorMessage)
      break

    // APP STATUS
    case 'setAppStatus':
      setAppStatus({ stationCode, blockIndex, ids, userInputForm, session })
        .then(successMessage)
        .catch(errorMessage)
      break

    // PLUGIN STATUS
    case 'setPlugInStatus':
      setPlugInStatus({ stationCode, blockIndex, ids, userInputForm, session })
        .then(successMessage)
        .catch(errorMessage)
      break

    // ALERT MANAGEMENT close after submit or message on error
    case 'setAssigneeAlert':
      alertAssignService(ids, userInputForm, session)
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'setAcknowledgeAlert':
      alertAcknowledgeService(ids, userInputForm, session)
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'setMuteAlert':
      alertMuteService(ids, userInputForm, session)
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'setUnmuteAlert':
      alertUnmuteService(ids, session).then(processAlertRes).catch(errorMessage)
      break
    case 'setResolveAlert':
      alertResolveService(ids, userInputForm, session)
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'clearFaults':
      clearDeviceFaultsService(ids, session, stationCode, blockIndex)
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'clearSensorFaults':
      clearSensorFaultsService(
        ids,
        session,
        stationCode,
        blockIndex,
        additionalPayload,
      )
        .then(processAlertRes)
        .catch(errorMessage)
      break
    case 'clearEnclosureFaults':
      clearEnclosureFaultsService(
        ids,
        session,
        stationCode,
        blockIndex,
        additionalPayload,
      )
        .then(processAlertRes)
        .catch(errorMessage)
      break
    // INVALID QUERY NAME
    default:
      setCommandResult({
        status: 'error',
        message: `invalid queryName command ${queryName}`,
      })
      break
  }
}

export default handleCommandSet
