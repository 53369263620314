import React, { useReducer, useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import AppTooltip from 'components/AppTooltip/AppTooltip'

// Component Imports
import Table from 'components/Table/Table/Table'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import Icon from 'components/Icon/Icon'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { SetArrayContactorModal } from '../../Commands/ArrayContactors/SetArrayContactorModal'
import { SetArrayRotationModal } from '../../Commands/ArrayRotation/SetArrayRotationModal'

// Icon Imports
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as BalancingIcon } from 'icons/balancing-icon.svg'
import { ReactComponent as PreferredCurrentIcon } from 'icons/visualization-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

// Util Imports
import {
  StringsToTernaryBarChartRow,
  getSortableStringFromStringCounts,
  useIsStationConnected,
} from '../tableUtils'
import * as Styles from '../table.styled'
import { createStringsObjectFromStackCounts } from 'utils/strings'
import { SessionContext } from 'contexts/session'
import { fetchArrayStatus } from 'api/queries.api'
import { SetArrayBalancingModal } from '../../Commands/Balancing/ArrayBalancing/SetArrayBalancingModal'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { useQuery } from '@tanstack/react-query'
import { SetPreferredCurrentModal } from '../../Commands/PreferredCurrent/SetPreferredCurrentModal'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showContactorsModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayContactorModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showBalancingModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetArrayBalancingModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showPreferredCurrentModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetPreferredCurrentModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

/////////////////////////////////////////////////////////////////

const getIdFromRow = (row) => Number(row.arrayIndex)

let healthValues = []

const healthCalculation = (row) => {
  const healthy = healthValues[row.arrayIndex - 1]
  return (
    <Styles.StatusIcon
      $isHealthy={healthy}
      data-tooltip-id={row.arrayIndex + 'ArrayStatus'}
    >
      {healthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
      <AppTooltip id={row.arrayIndex + 'ArrayStatus'}>
        {healthy ? 'Ready' : 'Not Ready'}
      </AppTooltip>
    </Styles.StatusIcon>
  )
}

const stringsToTernaryBarChartRowHandler = (row) => {
  return StringsToTernaryBarChartRow(row.dcPowerAndEnergy)
}

const idCalculation = (params) => (row) => {
  const acPvBatteryIndex = row.acPvBatteryIndex
  const batteryURL = !acPvBatteryIndex
    ? `acBattery/${row.arrayIndex}`
    : `ac-pv-battery/${acPvBatteryIndex}`
  return (
    <Styles.RowLink
      to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/${batteryURL}/array/${row.arrayIndex}`}
    >
      {row.arrayIndex}
    </Styles.RowLink>
  )
}

const getOutRotations = async (arrayIndexes, params, session, setSession) => {
  const arrayStatusesIn = new Map()
  const allPromises = []
  arrayIndexes.forEach((i) => {
    allPromises.push(
      fetchArrayStatus(
        params.stationCode,
        params.blockIndex,
        i,
        session,
        setSession,
      ),
    )
  })
  healthValues = []
  await Promise.all(allPromises).then((values) => {
    values.forEach((val) => {
      const arrayIndex = val.arrayIndex
      healthValues[arrayIndex - 1] = val.isHealthy
      const outRotationIn = val?.strings['1']?.outRotation
      arrayStatusesIn.set(arrayIndex, outRotationIn)
    })
  })
  return arrayStatusesIn
}

const ArrayTable = ({ expandableRows = false, fixedHeader = true, data }) => {
  const params = useParams()
  const isStationConnected = useIsStationConnected(
    params.stationCode,
    params.blockIndex,
  )
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [activeActionRow, setActiveActionRow] = useState(null)
  const [selectedArrayIds, setSelectedArrayIds] = useState([])
  const { session, setSession } = useContext(SessionContext)
  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  // load outRotation values on data change
  const arrayTableData = data ? Object.values(data) : []
  const arrayIndexes = arrayTableData
    ? Object.values(arrayTableData).map((d) => d.arrayIndex)
    : []

  const { data: rotationData, isLoading: isRotationLoading } = useQuery(
    ['outRotation'],
    () => getOutRotations(arrayIndexes, params, session, setSession),
    { enabled: !!data, refetchInterval: 5000 },
  )

  if (isRotationLoading) return <LoadingPage />

  let listPageData
  listPageData = arrayTableData.map((rowObj) => {
    rowObj['outRotation'] = rotationData.get(rowObj.arrayIndex)
    return rowObj
  })

  const handleSelectedRowChange = (rowChangeEvent) => {
    setSelectedArrayIds(
      Object.keys(rowChangeEvent).filter((key) => rowChangeEvent[key]),
    ) //return array of keys whose value is true
  }

  const columnActionMenu = (row) => {
    const rowStringId = [getIdFromRow(row)]
    if (!userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)) return
    return (
      <ActionsMenu
        key={row}
        onOpen={() => onOpen(row)}
        onClose={() => onClose()}
      >
        {' '}
        <ActionsMenuModalContent
          rowStringId={rowStringId}
          modalDispatch={modalDispatch}
          setShowCommandModal={setShowCommandModal}
        />
      </ActionsMenu>
    )
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.arrayIndex === 1,
      style: {
        borderTop: '1px solid #a7a7a7',
      },
    },
    {
      when: (row) =>
        getIdFromRow(row) === activeActionRow ||
        selectedArrayIds?.includes(getIdFromRow(row).toString()),
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  // open/close actions menu modal
  const onOpen = (row) => {
    setActiveActionRow(getIdFromRow(row))
  }
  const onClose = () => {
    setActiveActionRow(null)
  }

  const getPreferredChargeCurrentCellValue = (row) => {
    if (!row.preferredChargeCurrentChecked) {
      return (
        <span>
          <ErrorIcon
            style={{
              fill: '#abaeb1',
              width: 16,
              height: 10,
              color: '#000000',
              top: '12px',
            }}
          />
          Manager Disabled
        </span>
      )
    } else if (!row.hasPreferredChargeCurrent) {
      return <span>None</span>
    } else {
      const roundedValue = _.chain(row.preferredChargeCurrent).round().value()
      return `${roundedValue.toLocaleString()} A`
    }
  }

  const getPreferredDischargeCurrentCellValue = (row) => {
    if (!row.preferredDischargeCurrentChecked) {
      return (
        <span>
          <ErrorIcon
            style={{
              fill: '#abaeb1',
              width: 16,
              height: 10,
              color: '#000000',
              top: '12px',
            }}
          />
          Manager Disabled
        </span>
      )
    } else if (!row.hasPreferredDischargeCurrent) {
      return <span>None</span>
    } else {
      const roundedValue = _.chain(row.preferredDischargeCurrent)
        .round()
        .value()
      return `${roundedValue.toLocaleString()} A`
    }
  }

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getIdFromRow,
      sortable: true,
      width: '40px',
      cell: idCalculation(params),
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'health',
      selector: (row) => row.valid,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: healthCalculation,
    },
    {
      name: 'Strings',
      id: 'strings',
      selector: (row) =>
        getSortableStringFromStringCounts(
          createStringsObjectFromStackCounts(
            row.dcPowerAndEnergy.onlineStackCount,
            row.dcPowerAndEnergy.offlineStackCount,
            row.dcPowerAndEnergy.nearlineStackCount,
            row.dcPowerAndEnergy.nonCommunicatingStackCount,
          ),
        ),
      sortable: true,
      width: '90px',
      cell: stringsToTernaryBarChartRowHandler,
      noOmit: false,
      omit: false,
    },
    {
      name: 'Online Energy',
      id: 'onlineEnergy',
      selector: (row) => row.dcPowerAndEnergy.onlineAvailableKWH,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '120px',
      cell: (row) =>
        _.round(row.dcPowerAndEnergy.onlineAvailableKWH).toLocaleString() +
        ' kWh',
      right: true,
    },
    {
      name: 'Nearline Energy',
      id: 'nearlineEnergy',
      selector: (row) => row.dcPowerAndEnergy.nearlineEnergyKWH,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '120px',
      cell: (row) =>
        _.round(row.dcPowerAndEnergy.nearlineEnergyKWH).toLocaleString() +
        ' kWh',
      right: true,
    },
    {
      name: 'Offline Energy',
      id: 'offlineEnergy',
      selector: (row) => row.dcPowerAndEnergy.offlineEnergyKWH,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '120px',
      cell: (row) =>
        _.round(row.dcPowerAndEnergy.offlineEnergyKWH).toLocaleString() +
        ' kWh',
      right: true,
    },
    {
      name: 'Total Energy Capacity',
      id: 'energyCapacity',
      selector: (row) => row.dcPowerAndEnergy.totalCapacityKWH,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '150px',
      cell: (row) =>
        _.round(row.dcPowerAndEnergy.totalCapacityKWH).toLocaleString() +
        ' kWh',
      right: true,
    },
    {
      name: 'Pref. Charge Current',
      id: 'prefChargeCurrent',
      selector: (row) => row.preferredChargeCurrent,
      sortable: true,
      width: '140px',
      noOmit: false,
      omit: false,
      cell: (row) => getPreferredChargeCurrentCellValue(row),
      right: true,
    },
    {
      name: 'Pref. Discharge Current',
      id: 'prefDischargeCurrent',
      selector: (row) => row.preferredDischargeCurrent,
      sortable: true,
      width: '140px',
      noOmit: false,
      omit: false,
      cell: (row) => getPreferredDischargeCurrentCellValue(row),
      right: true,
    },
    {
      name: 'DC Power',
      id: 'dcPower',
      selector: (row) => row.dcKW,
      sortable: true,
      width: '100px',
      noOmit: false,
      omit: false,
      cell: (row) => _.round(row.dcKW, 2).toLocaleString() + ' kW',
      right: true,
    },
    {
      name: 'DC Bus Voltage',
      id: 'dcBusVoltage',
      selector: (row) => row.dcBusVoltage,
      sortable: true,
      width: '120px',
      noOmit: false,
      omit: false,
      cell: (row) => _.round(row.dcBusVoltage, 2).toLocaleString() + ' V',
      right: true,
    },
    {
      name: 'DC Current',
      id: 'dcCurrent',
      selector: (row) => row.dcBusVoltage,
      sortable: true,
      width: '120px',
      noOmit: false,
      omit: false,
      cell: (row) => _.round(row.dcAmps).toLocaleString() + ' A',
      right: true,
    },
    {
      name: 'Max Allowed Charge',
      id: 'maxAllowedChargeCurrent',
      selector: (row) => row.dcPowerAndEnergy.maxAllowedChargeCurrent,
      sortable: true,
      width: '150px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        Math.round(row.dcPowerAndEnergy.maxChargePowerKW).toLocaleString() +
        ' kW ' +
        ' / ' +
        row.dcPowerAndEnergy.maxAllowedChargeCurrent.toLocaleString() +
        ' A',
      right: true,
    },
    {
      name: 'Max Allowed Discharge',
      id: 'maxAllowedDischargeCurrent',
      selector: (row) => row.dcPowerAndEnergy.maxAllowedDischargeCurrent,
      sortable: true,
      width: '170px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        Math.round(row.dcPowerAndEnergy.maxDischargePowerKW).toLocaleString() +
        ' kW ' +
        ' / ' +
        row.dcPowerAndEnergy.maxAllowedDischargeCurrent.toLocaleString() +
        ' A',
      right: true,
    },
  ]

  if (isStationConnected)
    columns.unshift({
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit: !userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC),
      noOmit: true,
      width: '55px',
      cell: columnActionMenu,
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    })

  const isHeaderButtonDisabled = selectedArrayIds?.length === 0

  return (
    <>
      <Table
        tableId="ArrayTable"
        data={listPageData}
        columns={columns}
        fixedHeader={fixedHeader}
        expandableRows={expandableRows}
        conditionalRowStyles={conditionalRowStyles}
        selectableRows={
          isStationConnected && userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)
        }
        onSelectedRowsChange={handleSelectedRowChange}
        getRowId={getIdFromRow}
        defaultId="id"
        tableActions={
          isStationConnected &&
          userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) && (
            <Styles.ButtonContainer>
              <FormCommandButton
                disabled={isHeaderButtonDisabled}
                onClick={() => {
                  modalDispatch({
                    type: 'showContactorsModal',
                    setShowCommandModal: setShowCommandModal,
                    open: true,
                    idsForModal: selectedArrayIds,
                  })
                }}
              >
                <Icon
                  size="sm"
                  color={
                    isHeaderButtonDisabled
                      ? COLORS.disabled_action
                      : COLORS.action_blue
                  }
                  icon={<ContactorOpenIcon />}
                />
                Set Contactors
              </FormCommandButton>
              <FormCommandButton
                disabled={isHeaderButtonDisabled}
                onClick={() => {
                  modalDispatch({
                    type: 'showRotationModal',
                    setShowCommandModal: setShowCommandModal,
                    open: true,
                    idsForModal: selectedArrayIds,
                  })
                }}
              >
                <Icon
                  size="xxs"
                  color={
                    isHeaderButtonDisabled
                      ? COLORS.disabled_action
                      : COLORS.action_blue
                  }
                  icon={<CheckIcon />}
                />
                Set Rotation
              </FormCommandButton>
              <FormCommandButton
                disabled={isHeaderButtonDisabled}
                onClick={() => {
                  modalDispatch({
                    type: 'showBalancingModal',
                    setShowCommandModal: setShowCommandModal,
                    open: true,
                    idsForModal: selectedArrayIds,
                  })
                }}
              >
                <Icon
                  size="xxs"
                  color={
                    isHeaderButtonDisabled
                      ? COLORS.disabled_action
                      : COLORS.action_blue
                  }
                  icon={<BalancingIcon />}
                />
                Set Balancing
              </FormCommandButton>
              <FormCommandButton
                disabled={isHeaderButtonDisabled}
                onClick={() => {
                  modalDispatch({
                    type: 'showPreferredCurrentModal',
                    setShowCommandModal: setShowCommandModal,
                    open: true,
                    idsForModal: selectedArrayIds,
                  })
                }}
              >
                <Icon
                  size="xxs"
                  color={
                    isHeaderButtonDisabled
                      ? COLORS.disabled_action
                      : COLORS.action_blue
                  }
                  icon={<PreferredCurrentIcon />}
                />
                Set Preferred Current
              </FormCommandButton>
            </Styles.ButtonContainer>
          )
        }
      />

      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default ArrayTable

// Actions Menu Contents/Links to modals
const ActionsMenuModalContent = React.memo(
  ({ rowStringId, modalDispatch, setShowCommandModal, closeActionModal }) => {
    const actionClickHandler = (dispatchType) => {
      closeActionModal() // closes action menu, when command modal is opened
      modalDispatch({
        type: dispatchType,
        setShowCommandModal: setShowCommandModal,
        idsForModal: rowStringId,
      })
    }

    return (
      <div key={rowStringId.toString()} id={rowStringId.toString()}>
        <Styles.ActionModalSection>
          <Styles.ActionModalHeader>String Controls</Styles.ActionModalHeader>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showContactorsModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xs"
                color={COLORS.action_blue}
                icon={<ContactorOpenIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Contactors</div>
          </ActionsMenuItem>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showRotationModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showBalancingModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<BalancingIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Balancing</div>
          </ActionsMenuItem>
          <ActionsMenuItem
            onClick={() => actionClickHandler('showPreferredCurrentModal')}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<PreferredCurrentIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Preferred Current</div>
          </ActionsMenuItem>
        </Styles.ActionModalSection>
      </div>
    )
  },
)
