import _ from 'lodash'
import AppTooltip from 'components/AppTooltip/AppTooltip'

import * as Styles from './styles'

// Component Imports
import ACBatteryNode from '../Nodes/ACBatteryNode'

// Icon Imports
import { ReactComponent as InterconnectIcon } from 'icons/interconnect-icon.svg'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const InterconnectOneline = () => {
  const { data, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  if (_.isEmpty(data?.acBatteries))
    return 'Oneline view is not available for this station and block.'

  const formattedArrays = _.values(data.arrays)

  const formatACBatteries = () => {
    const acBatteries = Object.keys(data.acBatteries).map((key) => ({
      acBatteryIndex: parseInt(key),
      ...data.acBatteries[key],
    }))

    return _.map(acBatteries, (a) => {
      return {
        acBatteryIndex: a.acBatteryIndex,
        entityKeyToken: a.acBatteryIndex,
        enabled: a.enabled,
        ready: a.ready,
        soc: _.find(formattedArrays, { arrayIndex: a.acBatteryIndex })
          .dcPowerAndEnergy.onlineSocPercent,
        valid: a.valid,
      }
    })
  }

  const renderAcBatteries = () => {
    return _.map(formatACBatteries(), (a) => {
      const acBatteryIndex = _.chain(a.entityKeyToken).split(':').last().value()
      return (
        <Styles.NodeContainer key={acBatteryIndex}>
          <ACBatteryNode key={acBatteryIndex} data={a} />
        </Styles.NodeContainer>
      )
    })
  }

  return (
    <Styles.Container>
      <Styles.InterconnectTile data-tooltip-id="interconnect" $isEnabled={true}>
        <Styles.Icon>
          <InterconnectIcon />
        </Styles.Icon>
        <AppTooltip id="interconnect">Interconnect</AppTooltip>
      </Styles.InterconnectTile>

      <Styles.ACBatteriesRow $isEnabled={true}>
        {renderAcBatteries()}
      </Styles.ACBatteriesRow>
    </Styles.Container>
  )
}

export default InterconnectOneline
