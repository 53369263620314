import { useState, useEffect, useContext, useRef } from 'react'

// For documentation of the DataTable component, visit:
// https://github.com/jbetancur/react-data-table-component
import DataTable from 'react-data-table-component'

// Style Imports
import * as Styles from './table.styles'

// Component Imports
import ColumnToggles from '../ColumnToggles/ColumnToggles'
import FilterToggles from '../FilterToggles'
import TableControlDropdown from 'components/Dropdowns/TableControlDropdown/TableControlDropdown'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

// Icon Imports
import { ReactComponent as CaretRightIcon } from 'icons/caret-right-icon.svg'
import { ReactComponent as CaretDownIcon } from 'icons/caret-down-icon.svg'
import { ReactComponent as FilterIcon } from 'icons/filter-icon.svg'
import { ReactComponent as ColumnIcon } from 'icons/column-icon.svg'

// Util Imports
import { compressedTable } from 'utils/table.style-definitions'
// Hooks Imports
import { SessionContext } from 'contexts/session'
import {
  cleanHeaderTableColumnFilters,
  getUnselectedToggleIds,
  getVisibleColumns,
  omitToggler,
  preFilterTableData,
  selectListBasedOnIdsProvided,
} from './tableUtils'
import { updateUserPreferences } from 'api/queries.api'
import {
  getColumnVisibilityKey,
  getHeaderFilterKey,
  getRowPerPageKey,
  getSortByKey,
} from 'api/types/tableSettingsTypes4api'
import useUserPreferencesQuery from 'api/useQueryHooks/useUserPreferencesQuery'
import { tableResizer } from 'modules/Dashboard/components/Layouts/DashboardPage/tableResizer'

/**
 *
 * @param {function} getRowId - provide table with a getRowId function
 * @returns
 */
const Table = ({
  tableId,
  data,
  columns,
  headerPreFilters = null, // see 'StackOS apps' and 'notifications table' header filter
  expandableRowsComponent,
  fixedHeader = true,
  expandableRows = false,
  pagination = true,
  paginationServer = false,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  progressPending, // can be tied to query isLoading
  paginationRowsPerPageOptions = [50, 100, 200],
  conditionalRowStyles,
  selectableRows = false,
  getRowId = () => {
    throw new Error(
      'you must provide a getRowId definition for your table, like {row =>row.thingIndex}',
    )
  },
  onSelectedRowsChange = () => {},
  isColumCheckDisabled = false,
  isRowCheckDisabled = () => false,
  tableActions = null,
  controls = true,
  customStyles = compressedTable, // default see table.utils.js also dashboardTable
  selectableRowsComponent,
  defaultId = 'id', // set to default column id to sort, see column.id =
  defaultSortDirection = 'asc',
  hasDynamicColumns = false, // required to differentiate between tables with dynamic column rendering (ex.: sensor list) vs static columns
}) => {
  if (!tableId)
    throw new Error('tableId must be unique and provided for all tables')
  if (typeof data === 'object') {
    data = Object.values(data) // data sometimes may be an object
  }

  const tableRef = useRef()
  const { session } = useContext(SessionContext)
  // saved page settings
  const { data: loadedUserSettings, isLoading: isLoadingUserPreferences } =
    useUserPreferencesQuery()
  const defaultSortBy = {
    id: defaultId,
    selector: getRowId,
    sortDirection: defaultSortDirection,
  }
  const [tableColumns, setTableColumns] = useState(columns)
  const [tableColumnsVisibility, setTableColumnsVisibility] = useState()
  const [tableFilters, setTableFilters] = useState(headerPreFilters)
  const [tableSortBy, setTableSortBy] = useState()
  const [rowsPerPage, setRowsPerPage] = useState()
  const [selectedRows, setSelectedRows] = useState({})
  const [selectAll, setSelectAll] = useState(false)

  const tableData = tableFilters ? preFilterTableData(tableFilters, data) : data

  useEffect(() => {
    if (!isLoadingUserPreferences) {
      // const savedValues = {} // TODO for testing can be removed soon
      const savedValues = loadedUserSettings.values // empty object when never used b4 { }
      const savedColsResp = savedValues[getColumnVisibilityKey(tableId)]

      // set saved columns
      const visCols =
        typeof savedColsResp === 'string' && !hasDynamicColumns
          ? getVisibleColumns(savedColsResp, columns)
          : columns

      setTableColumnsVisibility(cleanHeaderTableColumnFilters(visCols))
      setTableColumns(visCols)

      // set rows per page
      const loadedRowsPerPage = savedValues[getRowPerPageKey(tableId)]
      if (loadedRowsPerPage) {
        setRowsPerPage(Number(loadedRowsPerPage))
      } else {
        setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
      }

      // set header filters
      const loadedHeaderFilters = savedValues[getHeaderFilterKey(tableId)]
      if (loadedHeaderFilters) {
        const updatedTableFilters = selectListBasedOnIdsProvided(
          loadedHeaderFilters,
          tableFilters,
        )
        setTableFilters(updatedTableFilters)
      } else {
        setTableFilters(headerPreFilters)
      }

      // set sort by
      const loadedSortBy = savedValues[getSortByKey(tableId)]
      setTableSortBy(getSortBy(loadedSortBy, columns, defaultSortBy))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId, isLoadingUserPreferences])

  const userSettingsComplete = rowsPerPage && tableSortBy && tableColumns

  // when some rows are selected we want to be sure they are not disabled
  useEffect(() => {
    if (!selectableRows || !data) return

    const selected = new Map(Object.entries(selectedRows))

    if (!selected.size) return

    const newSelected = {}

    data.forEach((row) => {
      /* rowId needs to be coerced to string to match
      selectedRows keys (obj keys are always strings or symbols) */
      const rowId = String(getRowId(row))
      if (!selected.has(rowId) || !selected.get(rowId)) return
      if (isRowCheckDisabled(row)) return

      newSelected[rowId] = true
    })

    setSelectedRows(newSelected)
    onSelectedRowsChange(newSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectableRows])

  useEffect(() => {
    if (isColumCheckDisabled) setSelectAll(false)
  }, [isColumCheckDisabled])

  if (isLoadingUserPreferences || !userSettingsComplete) return <LoadingPage />

  const tableHeight = tableResizer(tableRef)

  //////// COLUMNS TOGGLE
  const handleColumnToggle = (id) => {
    const updatedColumns = tableColumns?.map((c) => omitToggler(c, id))
    const value = {
      value: getUnselectedToggleIds(updatedColumns),
    }
    setTableColumnsVisibility(cleanHeaderTableColumnFilters(updatedColumns))
    setTableColumns(updatedColumns)
    // update store and userPreferences
    updateUserPreferences(session, getColumnVisibilityKey(tableId), value) // api storage
  }

  const handleFilterToggle = (filter) => {
    const filterStates = tableFilters.map((f) => omitToggler(f, filter.id))
    setTableFilters(filterStates)
    const value = { value: getUnselectedToggleIds(filterStates) }
    updateUserPreferences(session, getHeaderFilterKey(tableId), value)
  }

  const handleSortChange = (column, sortDirection) => {
    if (!isLoadingUserPreferences) {
      const _sort = {
        id: column.id,
        selector: column.selector,
        sortDirection,
        sortFunction: column.sortFunction,
      }
      setTableSortBy(_sort)
      const sort2Save = { id: column.id, sortDirection: sortDirection }
      const saveSortBy = JSON.stringify(sort2Save)
      updateUserPreferences(session, getSortByKey(tableId), {
        value: saveSortBy,
      })
    }
  }

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    if (!tableData?.length) return
    setRowsPerPage(currentRowsPerPage)
    updateUserPreferences(session, getRowPerPageKey(tableId), {
      value: currentRowsPerPage.toString(),
    })
    if (onChangeRowsPerPage) onChangeRowsPerPage(currentRowsPerPage, page)
  }

  const handleRowSelectionChange = (e, row) => {
    const newSelectedRows = {
      ...selectedRows,
      [getRowId(row)]: e.target.checked,
    }
    setSelectedRows(newSelectedRows)
    onSelectedRowsChange(newSelectedRows)
    if (!e.target.checked) {
      setSelectAll(false)
    }
  }

  const handleSelectAllRows = (e) => {
    const newSelectedRows = {}
    tableData.map((row) => {
      if (!isRowCheckDisabled(row))
        newSelectedRows[getRowId(row)] = e.target.checked
    })
    setSelectedRows(newSelectedRows)
    setSelectAll(e.target.checked)
    onSelectedRowsChange(newSelectedRows)
  }

  const isRowSelected = (row) => selectedRows?.[getRowId(row)] || false

  const makeSelectable = (columns) => {
    if (!columns) return []
    // add a checkbox column if selectableRows is true
    if (selectableRows) {
      const CheckBox = selectableRowsComponent ?? 'input'
      const selectColumn = {
        name: (
          <CheckBox
            type="checkbox"
            disabled={isColumCheckDisabled}
            checked={selectAll}
            onChange={handleSelectAllRows}
          />
        ),
        id: 'select',
        width: '40px',
        cell: (row) => (
          <CheckBox
            type="checkbox"
            checked={isRowSelected(row)}
            disabled={isRowCheckDisabled(row)}
            onChange={(e) => handleRowSelectionChange(e, row)}
          />
        ),
      }
      return [selectColumn, ...columns]
    }
    return columns
  }

  // we use a custom selectable rows
  return (
    <Styles.Container ref={tableRef}>
      {controls && (
        <Styles.TableControls>
          <Styles.Actions>{tableActions}</Styles.Actions>
          <Styles.Options>
            {tableFilters && (
              <TableControlDropdown icon={<FilterIcon />} title="Table Filters">
                <FilterToggles
                  filters={tableFilters}
                  handleToggle={handleFilterToggle}
                />
              </TableControlDropdown>
            )}

            {!hasDynamicColumns && (
              <TableControlDropdown
                icon={<ColumnIcon />}
                title="Column Visibility"
              >
                <ColumnToggles
                  columns={tableColumnsVisibility}
                  handleToggle={handleColumnToggle}
                />
              </TableControlDropdown>
            )}
          </Styles.Options>
        </Styles.TableControls>
      )}

      <DataTable
        columns={makeSelectable(tableColumns)}
        data={tableData}
        customStyles={customStyles}
        responsive
        pagination={pagination}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        showPaginationBottom={pagination}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationPerPage={rowsPerPage}
        selectableRows={false}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={tableHeight}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        expandableIcon={{
          collapsed: (
            <Styles.CollapsedIcon>
              <CaretRightIcon />
            </Styles.CollapsedIcon>
          ),
          expanded: (
            <Styles.ExpandedIcon>
              <CaretDownIcon />
            </Styles.ExpandedIcon>
          ),
        }}
        defaultSortFieldId={tableSortBy?.id}
        defaultSortAsc={tableSortBy?.sortDirection == 'asc'}
        onSort={handleSortChange}
        progressPending={progressPending || isLoadingUserPreferences}
        progressComponent={<LoadingPage size="50" />}
        conditionalRowStyles={conditionalRowStyles}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={onChangePage}
        currentPage
      />
    </Styles.Container>
  )
}

export default Table

const DEFAULT_ROWS_PER_PAGE = 50

/**
 * handles saved sortBy missing id, with id, and no saved sortBy
 * @param {JSON} loadedSortBy
 * @param {object} columns
 * @param {object} defaultSortBy
 * @returns object sortBy
 */
const getSortBy = (loadedSortBy, columns, defaultSortBy) => {
  if (loadedSortBy) {
    const updatedSortBy = JSON.parse(loadedSortBy)
    if (updatedSortBy.id) {
      updatedSortBy.selector = columns.find(
        (x) => x.id === updatedSortBy.id,
      )?.selector
      return updatedSortBy
    } else {
      return defaultSortBy // saved but empty sortBy
    }
  } else {
    return defaultSortBy // default sort by
  }
}
