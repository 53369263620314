import styled, { css } from 'styled-components/macro'

const invertedSummary = css`
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
  cursor: pointer;
  border-bottom: 1px solid #d8d8d8;

  ${({ $iconPosition }) =>
    $iconPosition === 'right' ? invertedSummary : null};
`

export const SummaryWrapper = styled.div`
  width: 100%;
`

export const ContentContainer = styled.div`
  ${({ $expanded }) =>
    !$expanded
      ? css`
          display: none;
        `
      : null}
`
