import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'

export const Container = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  // position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const ModalContainer = styled.div`
  visibility: ${(props) => (props.isOpen ? 'block' : 'none')};
  // visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  flex: 1;
  width: max-content;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 5px;
  top: 30px;
  z-index: 101;
  position: absolute;
  right: ${(props) => (props.align !== 'right' ? '0' : 'auto')};
  left: ${(props) => (props.align === 'right' ? '0' : 'auto')};
  overflow: hidden;
`
export const MenuRow = styled.div`
  color: ${({ disabled }) =>
    disabled ? COLORS.font_secondary : COLORS.font_primary};
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: left;
  font-size: 12px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 5px 20px 5px 15px;

  &:hover {
    background-color: #efefef;
  }
`
export const ActionsContainer = styled.div`
  position: relative;
`
