import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Summary from './Summary/EnclosureDetailsSummary'
import MainColumn from './MainColumn/EnclosureDetailsMainColumn'
import ACBatteryOneline from 'modules/Station/components/Onelines/ACBatteryOneline/ACBatteryOneline'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'

const EnclosureDetails = ({
  data,
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, containerId } = params

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - Enclosure ${containerId}`
    setSelectedParams({ ...params, listView: 'enclosure-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<Summary data={data} />}
      onelineComponent={<ACBatteryOneline />}
      mainColumnComponent={<MainColumn data={data} />}
    />
  )
}

export default EnclosureDetails
