import { useEffect, useState } from 'react'

import { NotificationsTable } from 'modules/Station/components/Tables/NotificationsTable/NotificationsTable'
import { NotificationSummary } from './Summary/NotificationsSummary'
import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'
import useNotificationsSearchQuery from 'api/useQueryHooks/useNotificationsSearchQuery'
import { useParams } from 'react-router'

export const Notifications = ({ setSelectedPage }) => {
  const [filters, setFilters] = useState({ query: '', type: '', subtype: '' })
  const { data: notifications, refetch } = useNotificationsSearchQuery(filters)
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedPage('notifications')
    document.title = `${stationCode}::${blockIndex} - Notifications`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.query])

  const filtersChangeHandler = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  return (
    <ListPage
      selectedParams={{}}
      summaryColumnComponent={
        <NotificationSummary notifications={notifications} />
      }
      tableComponent={
        <NotificationsTable
          data={notifications?.elementList || []}
          filters={filters}
          onFiltersChange={filtersChangeHandler}
        />
      }
      hideBreadcrumbs
    />
  )
}
