import { useState } from 'react'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'
import { StatusIcon } from 'modules/Station/components/Tables/table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { ReactComponent as StatusConnectedIcon } from 'icons/status-connected-icon.svg'
import { ReactComponent as StatusDisconnectedIcon } from 'icons/status-disconnected-icon.svg'
import {
  checkNullRoundValue,
  getRoleFriendlyName,
} from 'modules/Station/components/Tables/tableUtils'

const BlockMeterDetailsSummary = ({ data, id }) => {
  const [isExpanded, setIsExpanded] = useState({
    'Phase A': true,
    'Phase B': true,
    'Phase C': true,
  })

  const accordionHandler = (section) => {
    setIsExpanded(() => {
      return { ...isExpanded, [section]: !isExpanded[section] }
    })
  }

  const summaryMap = {
    title: `Block Meter ${id}`,
    titleIcon: <BlockMeterIcon />,
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            isIcon: true,
            value: data.isHealthy ? (
              <StatusIcon $isHealthy={data.isHealthy}>
                {' '}
                <HealthGoodIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon>
                {' '}
                <HealthAlertIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'Communication',
            isIcon: true,
            value: data.isCommunicating ? (
              <StatusIcon
                $isHealthy={data.isCommunicating}
                $marginLeft="-3.5px"
                $width="20px"
                $height="20px"
              >
                {' '}
                <StatusConnectedIcon />{' '}
              </StatusIcon>
            ) : (
              <StatusIcon $marginLeft="-3.5px" $width="20px" $height="20px">
                {' '}
                <StatusDisconnectedIcon />{' '}
              </StatusIcon>
            ),
          },
          {
            label: 'Type',
            value: data.type,
          },
          {
            label: 'Role',
            value: getRoleFriendlyName(data.role),
          },
        ],
      },
      {
        sectionTitle: 'Current Readings',
        rows: [
          {
            label: 'Voltage LN',
            value: checkNullRoundValue(data.voltageVoltLN, ' V', 2),
          },
          {
            label: 'Voltage LL',
            value: checkNullRoundValue(data.voltageVoltLL, ' V', 2),
          },
          {
            label: 'Current',
            value: checkNullRoundValue(data.currentAmp, ' A', 2),
          },
          {
            label: 'Frequency',
            value: checkNullRoundValue(data.frequencyHz, ' Hz', 2),
          },
          {
            label: 'Power Factor',
            value: checkNullRoundValue(data.powerFactor, '', 3),
          },
          {
            label: 'Real Power',
            value: checkNullRoundValue(data.realPowerKW, ' kW', 2),
          },
          {
            label: 'Apparent Power',
            value: checkNullRoundValue(data.apparentPowerKVA, ' kVA', 2),
          },
          {
            label: 'Reactive Power',
            value: checkNullRoundValue(data.reactivePowerKVAR, ' kVAr', 2),
          },
          {
            label: 'Energy Imported',
            value: checkNullRoundValue(data.importEnergyKWh, ' kWh', 2),
          },
          {
            label: 'Energy Exported',
            value: checkNullRoundValue(data.exportEnergyKWh, ' kWh', 2),
          },
          {
            label: 'Reactive Energy Imported',
            value: checkNullRoundValue(data.importEnergyKVArh, ' KVArH', 2),
          },
          {
            label: 'Reactive Energy Exported',
            value: checkNullRoundValue(data.exportEnergyKVArh, ' KVArH', 2),
          },
        ],
      },
      {
        sectionTitle: 'Phase A',
        rows: [
          {
            label: 'Current',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.currentAmp,
              ' A',
              2,
            ),
          },
          {
            label: 'Real Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.realPowerKW,
              ' kW',
              2,
            ),
          },
          {
            label: 'Apparent Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.apparentPowerKVA,
              ' kVA',
              2,
            ),
          },
          {
            label: 'Reactive Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.reactivePowerKVAR,
              ' kVAr',
              2,
            ),
          },
          {
            label: 'Power Factor',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.powerFactor,
              '',
              3,
            ),
          },
          {
            label: 'Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.realEnergyKWHImported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.realEnergyKWHExported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Apparent Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.apparentEnergyKVAHImported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Apparent Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.apparentEnergyKVAHExported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.reactiveEnergyKVARHImported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.reactiveEnergyKVARHExported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Voltage To Next Phase',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.voltageVoltToNextPhase,
              ' V',
              2,
            ),
          },
          {
            label: 'Voltage To Neutral',
            value: checkNullRoundValue(
              data.blockMeterPhaseA.voltageVoltToNeutral,
              ' V',
              2,
            ),
          },
        ],
      },
      {
        sectionTitle: 'Phase B',
        rows: [
          {
            label: 'Current',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.currentAmp,
              ' A',
              2,
            ),
          },
          {
            label: 'Real Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.realPowerKW,
              ' kW',
              2,
            ),
          },
          {
            label: 'Apparent Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.apparentPowerKVA,
              ' kVA',
              2,
            ),
          },
          {
            label: 'Reactive Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.reactivePowerKVAR,
              ' kVAr',
              2,
            ),
          },
          {
            label: 'Power Factor',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.powerFactor,
              '',
              3,
            ),
          },
          {
            label: 'Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.realEnergyKWHImported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.realEnergyKWHExported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Apparent Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.apparentEnergyKVAHImported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Apparent Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.apparentEnergyKVAHExported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.reactiveEnergyKVARHImported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.reactiveEnergyKVARHExported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Voltage To Next Phase',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.voltageVoltToNextPhase,
              ' V',
              2,
            ),
          },
          {
            label: 'Voltage To Neutral',
            value: checkNullRoundValue(
              data.blockMeterPhaseB.voltageVoltToNeutral,
              ' V',
              2,
            ),
          },
        ],
      },
      {
        sectionTitle: 'Phase C',
        rows: [
          {
            label: 'Current',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.currentAmp,
              ' A',
              2,
            ),
          },
          {
            label: 'Real Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.realPowerKW,
              ' kW',
              2,
            ),
          },
          {
            label: 'Apparent Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.apparentPowerKVA,
              ' kVA',
              2,
            ),
          },
          {
            label: 'Reactive Power',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.reactivePowerKVAR,
              ' kVAr',
              2,
            ),
          },
          {
            label: 'Power Factor',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.powerFactor,
              '',
              3,
            ),
          },
          {
            label: 'Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.realEnergyKWHImported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.realEnergyKWHExported,
              ' kWh',
              2,
            ),
          },
          {
            label: 'Apparent Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.apparentEnergyKVAHImported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Apparent Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.apparentEnergyKVAHExported,
              ' kVAH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Imported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.reactiveEnergyKVARHImported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Reactive Energy Exported',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.reactiveEnergyKVARHExported,
              ' KVArH',
              2,
            ),
          },
          {
            label: 'Voltage To Next Phase',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.voltageVoltToNextPhase,
              ' V',
              2,
            ),
          },
          {
            label: 'Voltage To Neutral',
            value: checkNullRoundValue(
              data.blockMeterPhaseC.voltageVoltToNeutral,
              ' V',
              2,
            ),
          },
        ],
      },
    ],
  }

  return (
    <SummaryColumn
      summaryMap={summaryMap}
      accordionSections={['Phase A', 'Phase B', 'Phase C']}
      expandedSections={isExpanded}
      accordionHandler={accordionHandler}
      width="105px"
    />
  )
}

export default BlockMeterDetailsSummary
