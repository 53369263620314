import { ClearDeviceAlertsModal } from '../../Commands/ClearDeviceAlerts/ClearDeviceAlertsModal'

export const actions = {
  SHOW_FAULT_CLEAR_MODAL: 'showFaultClearModal',
}

const actionModalReducer = (_state, action) => {
  switch (action?.type) {
    case actions.SHOW_FAULT_CLEAR_MODAL: {
      action.setShowCommandModal(true)
      return () => (
        <ClearDeviceAlertsModal
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          faultingDevices={action.faultingDevices}
          deviceType={action.deviceType}
          setShowCommandModal={action.setShowCommandModal}
        />
      )
    }
    default:
      return null
  }
}

export default actionModalReducer
