import styled from 'styled-components/macro'

// Theme / Style Imports
import { FormRow } from 'components/Form/FormComponents.styled'
import { getPluginMap } from 'modules/Station/components/PlugIns/PLUGIN_LIST'

export const PlugInStatusForm = (props) => {
  const { onUserInputFormChange, userInputForm, ids } = props

  const handleSelect = (e) => {
    const updatedUserInputForm = { enabled: e.target.value }
    onUserInputFormChange(updatedUserInputForm, e.target.value !== 'choose')
  }
  const pluginId = ids[0]
  const pluginMap = getPluginMap()
  const pluginName = pluginMap[pluginId].name
  return (
    <>
      <FormRow>
        <label>PlugIn</label> <span>{pluginName}</span>
      </FormRow>

      <FormRow>
        <label>PlugIn STATUS</label>
      </FormRow>

      <SelectWithBottomPadding
        value={userInputForm.enabled}
        onChange={handleSelect}
      >
        <option value="choose" disabled>
          Choose
        </option>
        <option value="false">Disable</option>
        <option value="true">Enable</option>
      </SelectWithBottomPadding>
    </>
  )
}

export const SelectWithBottomPadding = styled.select`
  margin-bottom: 20px;
`
