import { FC } from 'react'

import { BlockInverterRotationModalProps } from './block-inverter-rotation'
import { defaultRotationFormValues } from '../SetRotation/RotationForm'
import CommandModalTemplate from '../CommandModalTemplate'
import { BlockInverterRotationForm } from './form'
import { BlockInverterRotationResult } from './result'

export const BlockInverterRotationModal: FC<BlockInverterRotationModalProps> = (
  props,
) => {
  const { ids, setShowCommandModal } = props
  const modalTitles = {
    start: 'Set Block Inverter Rotation',
    finish: 'Set Block Inverter Rotation',
  }
  const queryName = 'setBlockInverterRotation'

  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={BlockInverterRotationResult}
      UserInputFormJsx={BlockInverterRotationForm}
      queryName={queryName}
      isFormValidated
    />
  )
}
