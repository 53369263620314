import { getAcCoupledSections } from './ac-coupled-sections'
import { getDcCoupledSections } from './dc-coupled-sections'
import { ReactComponent as InterconnectIcon } from 'icons/interconnect-icon.svg'
import SummaryColumn from '../../../../components/SummaryColumn/SummaryColumn'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const SystemDetailsSummary = ({ title, icon }) => {
  const { data, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  const { isDcCoupled } = data
  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(data.isDcCoupled)
  const blockPowerAndEnergyData = data[blockPowerAndEnergyKey]
  const summaryMap = {
    title: title ?? 'System Details',
    titleIcon: icon ?? <InterconnectIcon />,
    sections: isDcCoupled
      ? getDcCoupledSections(blockPowerAndEnergyData)
      : getAcCoupledSections(blockPowerAndEnergyData),
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default SystemDetailsSummary
