import { COLORS } from 'design_system/colors'
import { FONT_WEIGHT } from 'design_system/font_definitions'
import { FONT_SIZES } from 'design_system/sizes'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const EquipmentLink = styled(Link)`
  font-size: ${FONT_SIZES.normal};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${COLORS.font_primary};
  text-decoration: underline;
  cursor: pointer;
`
export const BoldCell = styled.div`
  font-size: ${FONT_SIZES.normal};
  font-weight: ${FONT_WEIGHT.bold};
`
export const LightCell = styled.div`
  font-size: ${FONT_SIZES.normal};
  color: ${COLORS.font_secondary};
`
export const DetectedAlerts = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${COLORS.powin_red};
  font-weight: ${FONT_WEIGHT.bold};
`
