import styled from 'styled-components/macro'

export const Row = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`
export const Label = styled.label`
  cursor: pointer;
  color: ${(props) => (props.checked ? '#2d3e45' : '#949697')};
  font-weight: 500;
`
