import styled from 'styled-components/macro'
import { Typography } from 'components/typography/Typography.styled'

const NoTableData = () => (
  <NoDataDiv>
    <Typography>There are no records to display</Typography>
  </NoDataDiv>
)

const NoDataDiv = styled.div`
  display: flex;
  justify-content: center;
`
export default NoTableData
