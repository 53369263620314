import { useParams } from 'react-router-dom'

// Component Imports
import Table from 'components/Table/Table/Table'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import TernaryBarChartStrings from 'components/Charts/TernaryBarChart/TernaryBarChartStrings'

import { BoldCell, DetectedAlerts, EquipmentLink, LightCell } from './styles'
import { ActionsHeader, AlertIcon } from '../../Tables/table.styled'
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'
import { useReducer, useState } from 'react'
import ActionMenuContent from './ActionMenuContent'
import actionModalReducer from './action-modal-reducer'

const isPCSDevice = (row) => row.type?.includes?.('PCS')
const getFaultingDevicesFromRow = (row) => row?.faulting

const getAlertsColumnCell = (row) => {
  if (!isPCSDevice(row)) return null

  const unhealthyCount = getFaultingDevicesFromRow(row)?.length

  if (!unhealthyCount) return null

  const copy = unhealthyCount > 1 ? 'Faults Detected' : 'Fault Detected'
  return unhealthyCount > 0 ? (
    <DetectedAlerts>
      <AlertIcon />
      {`${unhealthyCount} ${copy}`}
    </DetectedAlerts>
  ) : null
}

const ElectricalDevicesTable = ({ data }) => {
  const params = useParams()

  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModal, actionModalDispatch] = useReducer(actionModalReducer)

  const actionColumnMenu = (row) => {
    return (
      <ActionsMenu onOpen={() => null} onClose={() => null}>
        <ActionMenuContent
          hasFaults={Boolean(row?.faulting?.length)}
          setShowCommandModal={setShowCommandModal}
          handleActionSelection={actionModalDispatch}
          faultingDevices={getFaultingDevicesFromRow(row)}
          stationCode={params.stationCode}
          blockIndex={params.blockIndex}
          deviceType={row.type}
        />
      </ActionsMenu>
    )
  }

  const columns = [
    {
      name: <ActionsHeader>ACTIONS</ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit: false,
      width: '55px',
      cell: actionColumnMenu,
    },
    {
      name: '',
      id: 'icon',
      selector: (row) => row.icon,
      sortable: false,
      width: '30px',
      cell: (row) => row.icon,
    },
    {
      name: 'Type',
      id: 'type',
      selector: (row) => row.type,
      sortable: false,
      width: '150px',
      cell: (row) => (
        <EquipmentLink
          to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/${row.linkPage}`}
        >
          {row.type}
        </EquipmentLink>
      ),
    },
    {
      name: 'Total',
      id: 'total',
      selector: (row) => row.total,
      sortable: false,
      width: '75px',
      right: true,
      cell: (row) => <BoldCell>{row.total}</BoldCell>,
    },
    {
      name: 'Health',
      id: 'healthyCount',
      selector: (row) => row.healthyCount,
      sortable: false,
      width: '100px',
      cell: (row) => {
        if (typeof row.healthyCount === 'number') {
          return (
            <BinaryBarChart
              positiveCount={row.healthyCount}
              negativeCount={row.total - row.healthyCount}
            />
          )
        } else {
          return (
            <TernaryBarChartStrings
              data={row.healthyCount}
              showTooltip={false}
            />
          )
        }
      },
    },
    {
      name: '',
      id: 'healthText',
      selector: (row) => row.healthText,
      sortable: false,
      width: '200px',
      cell: (row) => <LightCell>{row.healthText}</LightCell>,
    },
    {
      name: 'Alerts',
      id: 'alerts',
      omit: false,
      selector: (row) => getFaultingDevicesFromRow(row)?.length ?? 0,
      sortable: true,
      width: '150px',
      cell: getAlertsColumnCell,
    },
  ]

  const getRowId = (row) => Number(row.id)

  // has defined order do not provide defaultId
  return (
    <>
      <Table
        tableId="electricalDevicesTable"
        data={data}
        columns={columns}
        getRowId={getRowId}
        fixedHeader={false}
        pagination={false}
        controls={false}
      />
      {showCommandModal && <ActionModal />}
    </>
  )
}

export default ElectricalDevicesTable
