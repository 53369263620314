import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import * as Styles from './appHeader.styles'
import AppHeaderDropdown from '../Dropdowns/AppHeaderDropdown/AppHeaderDropdown'
import { ReactComponent as PowinLogo } from '../../icons/logo.svg'
import { ReactComponent as UserIcon } from '../../icons/user-icon.svg'
import { SessionContext } from 'contexts/session'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { useAlertFilterGlobalStore } from 'modules/Dashboard/modules/AlertManagement/alert-filters/useAlertFilterGlobalStore'
import useUserPreferencesQuery from 'api/useQueryHooks/useUserPreferencesQuery'
import { logoutQuery } from 'api/queries.api'
import { USER_ROLES } from 'constants/USER_ROLES'

const AppHeader = () => {
  const { session, clearSession } = useContext(SessionContext)
  const { userHasRole } = useContext(AuthorizationContext)
  const mutate = useMutation({
    mutationFn: () => logoutQuery(session, clearSession),
    onSuccess: () => clearSession('You have logged out.'),
  })
  const navigate = useNavigate()

  const handleLogout = async (e) => {
    e.preventDefault()
    await mutate.mutateAsync()
  }

  const isTechSupport = userHasRole(USER_ROLES.TECH_SUPPORT)
  const isFirmwareDeploy = userHasRole(USER_ROLES.FIRMWARE_DEPLOY)
  const isFirmwareTrigger = userHasRole(USER_ROLES.FIRMWARE_TRIGGER)
  const isFirmwareUpload = userHasRole(USER_ROLES.FIRMWARE_UPLOAD)

  const setFilters = useAlertFilterGlobalStore((state) => state.setFilters)
  const { data: userPreferences, isLoading: prefsLoading } =
    useUserPreferencesQuery('alertFilters')

  useEffect(() => {
    if (!prefsLoading && userPreferences?.values.alertFilters) {
      // if there are saved filters overwrite the defaults
      setFilters(JSON.parse(userPreferences.values.alertFilters))
    }
  }, [prefsLoading, setFilters, userPreferences])

  return (
    <>
      <Styles.Version>v {process.env.REACT_APP_VERSION}</Styles.Version>
      <Styles.Container>
        <Styles.Flex>
          <Styles.LogoContainer to={`/`}>
            <PowinLogo />
          </Styles.LogoContainer>
          <Styles.AppName to={`/`}>
            Command Center <Styles.Light>- StackOS</Styles.Light>
          </Styles.AppName>
        </Styles.Flex>
        <AppHeaderDropdown
          icon={<UserIcon />}
          title="User Management"
          link={`/`}
        >
          {(isTechSupport ||
            isFirmwareDeploy ||
            isFirmwareTrigger ||
            isFirmwareUpload) && (
            <Styles.HeaderButton
              onClick={() => {
                navigate('/admin-commands')
              }}
            >
              Admin Commands
            </Styles.HeaderButton>
          )}
          <Styles.HeaderButton onClick={handleLogout}>
            Log Out
            <Styles.CurrentUser>{` - ${session.username}`}</Styles.CurrentUser>
          </Styles.HeaderButton>
        </AppHeaderDropdown>
      </Styles.Container>
    </>
  )
}

export default AppHeader
