import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { useParams } from 'react-router-dom'
import {
  DisabledControlCheckbox,
  HealthStatusIconWithToolTips,
  RowRotationWithTooltip,
  useIsStationConnected,
} from '../tableUtils'
import { SetDcDcGroupRotationModal } from '../../Commands/DcDcGroupRotation/SetDcDcGroupRotationModal'
import { useContext, useReducer, useState } from 'react'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'
import Tooltip from 'components/Tooltip'

const getRowId = (row) => `${row.acPvBatteryIndex}::${row.dcDcGroupIndex}`

const idsToObject = (ids) =>
  ids.map((id) => {
    const [acPvBatteryIndex, dcDcGroupIndex] = id.split('::')
    return { acPvBatteryIndex, dcDcGroupIndex }
  })

/////////////////  ACTION MODALS /////////////////////////////////
// see Inverter table for example to add actions and row selections
/////////////////////////////////////////////////////////////////
const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showGroupRotationModal': {
      return () => (
        <SetDcDcGroupRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

const DcDcGroupsTable = ({ data, fixedHeader = true }) => {
  const { stationCode, blockIndex } = useParams()
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [selectedIds, setSelectedIds] = useState([])

  const userHas8PC = userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC)
  const isRotationEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.DC_DC_GROUP_ROTATION_COMMAND,
  )

  const baseUrl = `/block-details/${stationCode}/${blockIndex}/block-topology`

  const handleSelectedRowsChange = (rowsObject) => {
    setSelectedIds(
      Object.keys(rowsObject).filter((key) => rowsObject[key]), //returns array of ids where key value is true
    )
  }

  const dcDcGroupsId = (row) => {
    const { acPvBatteryIndex, dcDcGroupIndex } = row
    return (
      <Styles.RowLink
        to={`${baseUrl}/ac-pv-battery/${acPvBatteryIndex}/dc-dc-group/${dcDcGroupIndex}`}
      >
        {`${acPvBatteryIndex} :: ${dcDcGroupIndex}`}
      </Styles.RowLink>
    )
  }

  const enableCheckAll = data.some(
    (row) => row?.parentPvPcsOutRotation === false,
  )

  const disableRotation = (row) => row?.parentPvPcsOutRotation

  const _tableActions = Boolean(
    isStationConnected && userHas8PC && isRotationEnabled,
  ) && (
    <Styles.ButtonContainer>
      <FormCommandButton
        disabled={selectedIds.length === 0}
        onClick={() => {
          modalDispatch({
            type: 'showGroupRotationModal',
            setShowCommandModal: modalDispatch,
            idsForModal: idsToObject(selectedIds),
          })
        }}
      >
        <Icon
          size="xxs"
          color={
            selectedIds.length === 0
              ? COLORS.disabled_action
              : COLORS.action_blue
          }
          icon={<CheckIcon />}
        />
        Set DC:DC Group Rotation
      </FormCommandButton>
    </Styles.ButtonContainer>
  )

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit:
        !isStationConnected ||
        !userHasApiAccessCode(API_ACCESS_CODES.EIGHT_PC) ||
        !isRotationEnabled,
      noOmit: true,
      width: '55px',
      cell: (row) => (
        <ActionsMenu key={row} onOpen={() => null} onClose={() => null}>
          <ActionsMenuModalContent
            rowId={getRowId(row)}
            setShowCommandModal={modalDispatch}
            disableRotation={disableRotation(row)}
          />
        </ActionsMenu>
      ),
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '60px',
      noOmit: true,
      cell: dcDcGroupsId,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.healthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.healthy),
    },
    {
      name: 'IN ROT.',
      id: 'rotation',
      selector: (row) => row.outRotation,
      sortable: true,
      width: '70px',
      cell: (row) => RowRotationWithTooltip(row.outRotation),
      noOmit: false,
      omit: false,
    },
    {
      name: 'DC Command',
      id: 'dcCmdkW',
      selector: (row) => row.dcCmdRealPowerKW,
      sortable: true,
      width: '100px',
      cell: (row) => row.dcCmdRealPowerKW.toLocaleString() + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'DC SETTING',
      id: 'dcSet',
      selector: (row) => row.dcRealPowerKW,
      sortable: true,
      width: '90px',
      cell: (row) => `${row.dcRealPowerKW.toLocaleString()} kW`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'BATTERY VOLT.',
      id: 'batV',
      selector: (row) => row.dcVoltageBatteryVolt,
      sortable: true,
      width: '100px',
      cell: (row) => `${row.dcVoltageBatteryVolt.toLocaleString()} VDC`,
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'BATTERY CURR.',
      id: 'batC',
      selector: (row) => row.dcCurrentBatteryAmp,
      sortable: true,
      width: '105px',
      cell: (row) => row.dcCurrentBatteryAmp.toLocaleString() + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'POWER',
      id: 'power',
      selector: (row) => row.dcPowerBusKW,
      sortable: true,
      table: true,
      width: '90px',
      cell: (row) => row.dcPowerBusKW.toLocaleString() + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
  ]
  return (
    <>
      <Table
        tableId="DcDcGroupsTable"
        data={data}
        columns={columns}
        fixedHeader={fixedHeader}
        getRowId={getRowId}
        defaultId="id"
        onSelectedRowsChange={(e) => handleSelectedRowsChange(e)}
        tableActions={_tableActions}
        selectableRows={Boolean(_tableActions)}
        selectableRowsComponent={DisabledControlCheckbox}
        //selectable status is tied to rotation
        isColumCheckDisabled={!enableCheckAll}
        isRowCheckDisabled={disableRotation}
      />
      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default DcDcGroupsTable

const ActionsMenuModalContent = ({
  rowId,
  setShowCommandModal,
  disableRotation,
}) => {
  const actionClickHandler = (payload) => {
    setShowCommandModal(null) // closes action menu
    setShowCommandModal(payload)
  }

  const id = idsToObject([rowId])

  return (
    <Styles.ActionModalSection style={{ position: 'relative' }}>
      <Tooltip
        overlayStyle={{ maxWidth: 300 }}
        title={disableRotation ? 'Control Disabled. Parent Equipment OOR.' : ''}
      >
        <div>
          <ActionsMenuItem
            onClick={() =>
              actionClickHandler({
                type: 'showGroupRotationModal',
                setShowCommandModal: setShowCommandModal,
                idsForModal: id,
              })
            }
            disabled={disableRotation}
          >
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={
                  disableRotation ? COLORS.disabled_action : COLORS.action_blue
                }
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Set Rotation</div>
          </ActionsMenuItem>
        </div>
      </Tooltip>
    </Styles.ActionModalSection>
  )
}
