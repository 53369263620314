import { StatusAndOperationTable } from './StatusAndOperationTable'

const COMMAND_SETTING = {
  START: 'Start Enabled',
  STOP: 'Stop Enabled',
  EMERGENCY_STOP: 'Emergency Stop Enabled',
}

const COMMAND_CMD = {
  START: 'Start Enable',
  STOP: 'Stop Enable',
  EMERGENCY_STOP: 'Emergency Stop Enable',
}

const CONTROL_MODE = {
  PPC_CONTROL: 'PPC Mode',
  STACK_OS_CONTROL: 'StackOS Control',
  HMI_CONTROL: 'EKS HMI Control',
}

const BOOLEAN_CMD = {
  true: 'Enable',
  false: 'Disable',
}

const BOOLEAN_SETTING = {
  true: 'Enabled',
  false: 'Disabled',
}

const FREQUENCY_RESPONSE = {
  ENABLE_PRIMARY: 'Enable Primary',
  ENABLE_FAST: 'Enable Fast',
  ENABLED_PRIMARY: 'Enabled Primary',
  ENABLED_FAST: 'Enabled Fast',
  DISABLE: 'Disable',
  DISABLED: 'Disabled',
}

export function PpcModesTable({ data }) {
  const tableData = [
    {
      name: 'Control Mode',
      operation:
        CONTROL_MODE[data?.opModeInterfaceCmd] ?? data?.opModeInterfaceCmd,
      status:
        CONTROL_MODE[data?.opModeInterfaceSetting] ??
        data?.opModeInterfaceSetting,
    },
    {
      name: 'Start / Stop Command',
      operation:
        COMMAND_CMD[data?.opModeStartStopCmd] ?? data?.opModeStartStopCmd,
      status:
        COMMAND_SETTING[data?.opModeStartStopSetting] ??
        data?.opModeStartStopSetting,
    },
    {
      name: 'Master Command',
      operation: BOOLEAN_CMD[data?.opModeMasterCmd],
      status: BOOLEAN_SETTING[data?.opModeMasterSetting],
    },
    {
      name: 'Real Power Curtailment',
      operation: BOOLEAN_CMD[data?.opModeRealPowerCurtailmentCmd],
      status: BOOLEAN_SETTING[data?.opModeRealPowerCurtailmentSetting],
    },
    {
      name: 'Reactive Power Control',
      operation: BOOLEAN_CMD[data?.opModeReactivePowerControlCmd],
      status: BOOLEAN_SETTING[data?.opModeReactivePowerControlSetting],
    },
    {
      name: 'Voltage Control',
      operation: BOOLEAN_CMD[data?.opModeVoltageControlCmd],
      status: BOOLEAN_SETTING[data?.opModeVoltageControlSetting],
    },
    {
      name: 'Power Factor Control',
      operation: BOOLEAN_CMD[data?.opModePowerFactorControlCmd],
      status: BOOLEAN_SETTING[data?.opModePowerFactorControlSetting],
    },
    {
      name: 'Ramp Control',
      operation: BOOLEAN_CMD[data?.opModeRampControlCmd],
      status: BOOLEAN_SETTING[data?.opModeRampControlSetting],
    },
    {
      name: 'Frequency Response',
      operation:
        FREQUENCY_RESPONSE[data?.opModeFrequencyResponseCmd] ??
        data?.opModeFrequencyResponseCmd,
      status:
        FREQUENCY_RESPONSE[data?.opModeFrequencyResponseSetting] ??
        data?.opModeFrequencyResponseSetting,
    },
    {
      name: 'Schedule Control',
      operation: BOOLEAN_CMD[data?.opModeScheduleControlCmd],
      status: BOOLEAN_SETTING[data?.opModeScheduleControlSetting],
    },
  ]

  return <StatusAndOperationTable tableId="EksPpcModesTable" data={tableData} />
}
