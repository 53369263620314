import _ from 'lodash'

import { ReactComponent as ACBatteryIcon } from 'icons/ac-battery-icon.svg'
import useACBatteryDetailsQuery from 'api/useQueryHooks/useACBatteryDetails'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import HealthStatus from 'components/Status/HealthStatus/HealthStatus'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'

const ACBatteryDetailsSummary = () => {
  const { data, isLoading } = useACBatteryDetailsQuery()
  if (isLoading) return <LoadingPage />

  const summaryMap = {
    title: `AC Battery ${_.get(data, 'acBatteryIndex')}`,
    titleIcon: <ACBatteryIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: (
              <HealthStatus
                isHealthy={
                  _.get(data, 'valid') &&
                  _.get(data, 'enabled') &&
                  _.get(data, 'ready') &&
                  _.get(data, 'acPowerAndEnergy.offlineStackCount') === 0 &&
                  _.get(data, 'acPowerAndEnergy.nearlineStackCount') === 0
                }
              />
            ),
            isIcon: true,
          },
        ],
      },
      {
        sectionTitle: 'Availability',
        rows: [
          {
            label: 'Online Energy',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.onlineAvailableKWH', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'Energy Capacity',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.totalCapacityKWH', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'AC Charge Power',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.availableChargeAcPowerKW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'AC Discharge Power',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.availableDischargeAcPowerKW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Real Power',
        rows: [
          {
            label: 'Measured',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.measuredkW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'Commanded',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.commandedkW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Reactive Power',
        rows: [
          {
            label: 'Measured',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.measuredKVAr', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kVAR`,
          },
          {
            label: 'Commanded',
            value: `${_.chain(data)
              .get('acPowerAndEnergy.commandedKVAr', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kVAR`,
          },
        ],
      },
      {
        sectionTitle: 'Strings',
        rows: [
          {
            label: 'Online Strings',
            value: _.chain(data)
              .get('acPowerAndEnergy.onlineStackCount', 'N/A')
              .value()
              .toLocaleString(),
          },
          {
            label: 'Total Strings',
            value: _.chain(data)
              .get('acPowerAndEnergy.totalStackCount', 'N/A')
              .value()
              .toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ACBatteryDetailsSummary
