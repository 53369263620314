import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'

export const Container = styled.div`
  position: relative;
  width: ${(props) => props.$width};
  background-color: ${(props) => props.$backgroundColor};
`
export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${(props) => props.$borderRadius};
  padding: 2px 10px;
  border: ${(props) => props.$border};
  transition: background-color ease-in-out 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.85);
  }
`
export const ButtonLabel = styled.div`
  align-items: center;
  display: flex;
  color: ${(props) => props.$textColor};
  font-weight: 500;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '')};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${(props) => props.$height};
`
export const TitleValue = styled.div`
  color: #333;
  margin: 0 5px;
  text-transform: ${(props) => (props.$isCustomFilters ? '' : 'capitalize')};
`
export const CaretIconContainer = styled.div`
  width: 7px;
  height: 7px;
  line-height: 0;
  margin-left: 7px;
  fill: #00b986;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'none')};
`
export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.25;
`
export const ModalContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
`
export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -4px;
  right: 0;
  left: 0;
  z-index: 1;
  box-shadow:
    0 2px 2px 0 rgba(90, 90, 90, 0.5),
    0 0 4px 1px rgba(90, 90, 90, 0.1);
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: ${(props) => props.$width};
`
export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding: 7px 11px;
  color: #798b95;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
`
export const Content = styled.div`
  display: ${(props) => props.$display};
  flex-direction: column;
  flex: 1;
  font-size: 13px;
  padding: 10px;
  color: ${(props) =>
    props.$isCustomFilters ? COLORS.font_primary : '#798b95'};
  max-height: ${(props) => props.$maxHeight};
  overflow-y: ${(props) => props.$overflow};
`
