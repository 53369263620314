import { useParams } from 'react-router-dom'

import { useState, useReducer } from 'react'
import Table from 'components/Table/Table/Table'
import {
  timestampToMYDTLocal,
  tableDisplayTrippedStatusWithIcon,
  sensorLostConnectionReason,
} from '../tableUtils'
import { ReactComponent as ConnectedIcon } from 'icons/status-connected-icon.svg'
import SensorStatus from 'components/Status/SensorStatus/SensorStatus'
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu'
import ActionMenuContent from '../../Commands/ClearSensorsFaults/ActionMenuModal/ActionMenuContent'
import actionModalReducer from '../../Commands/ClearSensorsFaults/ActionMenuModal/action-modal-reducer'
import * as Styles from './styles'

const CollectionSegmentDetailSensorStatusTable = ({ data }) => {
  const params = useParams()

  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModal, actionModalDispatch] = useReducer(actionModalReducer)

  const getRowId = (row) => row.sensorRole

  const rowName = (row) => <strong>{row.friendlyName}</strong>

  const isConnected = (topology) =>
    topology.communicating && topology.ready && topology.enabled

  const connectionStatus = (row) => (
    <SensorStatus
      isConnected={isConnected(row.sensorTopology)}
      lostConnectionReason={sensorLostConnectionReason(row.sensorTopology)}
      id={row.sensorRole}
      size={'xs'}
    />
  )

  const actionColumnMenu = (row) => {
    if (row.status === 'Ready to Clear') {
      return (
        <Styles.Container>
          <ActionsMenu onOpen={() => null} onClose={() => null}>
            <ActionMenuContent
              setShowCommandModal={setShowCommandModal}
              handleActionSelection={actionModalDispatch}
              stationCode={params.stationCode}
              blockIndex={params.blockIndex}
              sensorIndex={row.sensorIndex}
              sensorName={row.friendlyName}
              centipedeLineupId={params.centipedeLineupId}
              segmentId={params.collectionSegmentId}
              segment={'collection'}
            />
          </ActionsMenu>
        </Styles.Container>
      )
    }
    return <Styles.Container />
  }

  const columns = [
    {
      name: 'Actions',
      id: 'actions',
      sortable: false,
      noOmit: true,
      width: '80px',
      cell: actionColumnMenu,
    },
    {
      name: 'TYPE',
      id: 'type',
      selector: (row) => row.friendlyName,
      sortable: false,
      width: '140px',
      noOmit: true,
      cell: rowName,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 17, height: 17 }}>
          <ConnectedIcon />
        </div>
      ),
      id: 'connected',
      selector: (row) => isConnected(row.sensorTopology),
      sortable: false,
      // omit: true,
      noOmit: true,
      width: '80px',
      cell: connectionStatus,
    },
    {
      name: 'STATUS',
      id: 'status',
      selector: (row) => row.status,
      sortable: false,
      width: '175px',
      noOmit: true,
      cell: (row) => tableDisplayTrippedStatusWithIcon(row),
    },
    {
      name: 'TIMESTAMP',
      id: 'timestamp',
      selector: (row) =>
        timestampToMYDTLocal(row.timestamp, isConnected(row.sensorTopology)),
      sortable: false,
      width: '250px',
      noOmit: true,
      // cell: idCell,
    },
  ]

  // predefined sort order
  return (
    <>
      <Table
        tableId="CollectionSegmentDetailTable"
        data={data}
        columns={columns}
        getRowId={getRowId}
        fixedHeader={false}
        pagination={false}
        controls={false}
      />
      {showCommandModal && <ActionModal />}
    </>
  )
}

export default CollectionSegmentDetailSensorStatusTable
