import { formatNumber } from 'utils/formatting'

export const getAcCoupledSections = (data) => {
  return [
    {
      sectionTitle: 'Availability',
      rows: [
        {
          label: 'Charge Power',
          value: formatNumber({
            value: data.onlineAvailableAcPowerChargekW,
            precision: 1,
            unit: ' kW',
          }),
        },
        {
          label: 'Discharge Power',
          value: formatNumber({
            value: data.onlineAvailableAcPowerDischargekW,
            precision: 1,
            unit: ' kW',
          }),
        },
        {
          label: 'Charge Energy',
          value: formatNumber({
            value: data.onlineAvailableAcEnergyChargekWh,
            precision: 1,
            unit: ' kWh',
          }),
        },
        {
          label: 'Discharge Energy',
          value: formatNumber({
            value: data.onlineAvailableAcEnergyDischargekWh,
            precision: 1,
            unit: ' kWh',
          }),
        },
      ],
    },
    {
      sectionTitle: 'Energy Capacity',
      rows: [
        {
          label: 'DC Online',
          value: formatNumber({
            value: data.onlineAvailableDcEnergykWh,
            precision: 1,
            unit: ' kWh',
          }),
        },
        {
          label: 'DC Nearline',
          value: formatNumber({
            value: data.nearlineDcEnergykWh,
            precision: 1,
            unit: ' kWh',
          }),
        },
        {
          label: 'AC Online',
          value: formatNumber({
            value: data.onlineAvailableAcEnergykWh,
            precision: 1,
            unit: ' kWh',
          }),
        },
        ...(data.contractualEnergykWh !== 0 &&
        isFinite(data.contractualEnergykWh) &&
        data.contractualEnergykWh < Number.MAX_VALUE
          ? [
              {
                label: 'Contractual',
                value: formatNumber({
                  value: data.contractualEnergykWh,
                  precision: 1,
                  unit: ' kWh',
                }),
              },
            ]
          : []),
      ],
    },
    {
      sectionTitle: 'Real Power',
      rows: [
        {
          label: 'Measured',
          value: formatNumber({
            value: data.measuredkW,
            precision: 1,
            unit: ' kW',
          }),
        },
        {
          label: 'Commanded',
          value: formatNumber({
            value: data.commandedkW,
            precision: 1,
            unit: ' kW',
          }),
        },
      ],
    },
    {
      sectionTitle: 'Reactive Power',
      rows: [
        {
          label: 'Measured',
          value: formatNumber({
            value: data.measuredKVAr,
            precision: 1,
            unit: ' kVAR',
          }),
        },
        {
          label: 'Commanded',
          value: formatNumber({
            value: data.commandedKVAr,
            precision: 1,
            unit: ' kVAR',
          }),
        },
      ],
    },
  ]
}
