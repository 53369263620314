import _ from 'lodash'
import { useReducer, useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import * as Styles from '../table.styled'

import Table from 'components/Table/Table/Table'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import Icon from 'components/Icon/Icon'
import { SetRotationModal } from '../../Commands/SetRotation/SetRotationModal'
import { SetContactorsModal } from '../../Commands/SetContactors/SetContactorsModal'

// Icon Imports
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ContactorClosedIcon } from 'icons/contactor-closed-icon.svg'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as HealthAlertIcon } from 'icons/health-alert-icon.svg'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as WarningIcon } from 'icons/warning-icon.svg'
import { ReactComponent as BalancingIcon } from 'icons/balancing-icon.svg'
import { ReactComponent as StatusConnectedIcon } from 'icons/status-connected-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

// Util Imports
import {
  CellSOC,
  BalanceCount,
  RowRotationWithTooltip,
  useIsStationConnected,
  getBalanceMode,
  getCommunicationStatus,
  getBalanceDisplayStatus,
  getDeadband,
  getTargetmV,
  sortIdsWithColonRecursive,
} from '../tableUtils'
import SetStringBalancingModal from '../../Commands/Balancing/StringBalancing/SetStringBalancingModal'
import useStringStatusQuery from 'api/useQueryHooks/useStringStatusQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { formatNumber } from 'utils/formatting'
import Tooltip from 'components/Tooltip'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */
const modalReducer = (_state, action) => {
  // const ids4Modal = useMemo(action.idsForModal, [])
  switch (action?.type) {
    case 'showRotationModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showContactorsModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetContactorsModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showBalancingModal': {
      action.setShowCommandModal(true)
      return () => (
        <SetStringBalancingModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return () => null
  }
}
/////////////////////////////////////////////////////////////////

/**
 *
 * @param {object} data optional, only provided by expandable cards for a subsection of strings
 * will resolve from useStringsStatusQuery when undefined.
 * @returns jsx
 */
const StringTable = ({
  fixedHeader = true,
  pagination = true,
  data,
  isEnergySegment = false,
}) => {
  // const [isIdSortable, setIsIdSortable] = useState(false) // sortable by id after first render, as it sorts by selector instead for first render
  const params = useParams()
  const { stationCode, blockIndex } = params
  const tableId = 'stringTable'
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(
    useCallback(modalReducer, []),
    null,
  )
  const [activeActionRow, setActiveActionRow] = useState(null)
  const [selectedStringIds, setSelectedStringIds] = useState([])
  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  // CLEANUP!
  useEffect(() => {
    return () => {
      setSelectedStringIds([])
    }
  }, [])
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const { data: _stringListData, isLoading } = useStringStatusQuery(!data)
  const stringListData = data || _stringListData

  if ((!data && isLoading) || isStationConnected === null) {
    return <LoadingPage />
  }

  const handleSelectedRowChange = (rowChangeEvent) => {
    setSelectedStringIds(
      Object.keys(rowChangeEvent).filter((key) => rowChangeEvent[key]),
    ) //return array of keys whose value is true
  }

  const stringsData =
    (isEnergySegment ? stringListData : stringListData?.strings) || []

  const getIdFromRow = (row) => `${row.arrayIndex}:${row.stringIndex}`

  const conditionalRowStyles = [
    {
      when: (row) => row.stringIndex === 1,
      style: {
        borderTop: '1px solid #a7a7a7',
      },
    },
    {
      when: (row) =>
        getIdFromRow(row) === activeActionRow ||
        selectedStringIds.includes(getIdFromRow(row)),
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  // open/close actions menu modal
  const onOpen = (row) => {
    setActiveActionRow(getIdFromRow(row))
  }
  const onClose = () => {
    setActiveActionRow(null)
  }
  const columnActionMenu = (row) => {
    const rowStringId = [getIdFromRow(row)]

    return (
      <ActionsMenu
        key={row}
        onOpen={() => onOpen(row)}
        onClose={() => onClose()}
      >
        <ActionsMenuModalContent
          rowStringId={rowStringId}
          modalDispatch={modalDispatch}
          setShowCommandModal={setShowCommandModal}
          data={stringsData}
        />
      </ActionsMenu>
    )
  }

  const getRowId = (row) => `${row.arrayIndex}:${row.stringIndex}`

  const rowIdLink = (row) => {
    const batteryURL = stringListData.isAcPvStation
      ? `acPvBattery/${row.acPvBatteryIndex}`
      : `acBattery/${row.arrayIndex}`

    return (
      <Styles.RowLink
        to={`/block-details/${stationCode}/${blockIndex}/block-topology/${batteryURL}/array/${row.arrayIndex}/string/${row.stringIndex}`}
      >
        {row.arrayIndex} :: {row.stringIndex}
      </Styles.RowLink>
    )
  }

  const rowHealth = (row) => {
    const isHealthy = ['NEARLINE', 'ONLINE', 'OFFLINE'].includes(
      row.stringConnectionState,
    )
    return (
      <Tooltip title={isHealthy ? 'Healthy' : 'Unhealthy'}>
        <Styles.StatusIcon $isHealthy={isHealthy}>
          {isHealthy ? <HealthGoodIcon /> : <HealthAlertIcon />}
        </Styles.StatusIcon>
      </Tooltip>
    )
  }

  const cellContactors = (row) => {
    const contactorStateCauseMapping = {
      ESTOP: 'E-Stop',
      MANUAL: 'Manual Command',
      AUTO: 'Auto',
    }
    const contactorStateCauseText =
      contactorStateCauseMapping[row.stringContactorStateCause]
    const showContactorStateCause =
      !row.contactorsCloseExpected && row.stringContactorStateCause !== 'AUTO'
    const contactorsExpectedStateText = row.contactorsCloseExpected
      ? 'Closed'
      : 'Open'
    const isHealthy =
      _.isEqual(row.contactorsCloseExpected, row.negativeContactorClosed) &&
      _.isEqual(row.contactorsCloseExpected, row.positiveContactorClosed)
    return (
      <Tooltip
        title={
          <>
            <div>
              <span>Expected State: </span>
              {row.contactorsCloseExpected ? 'Closed' : 'Open'}
            </div>
            <div>
              <span>Negative Contactor: </span>
              {row.negativeContactorClosed ? 'Closed' : 'Open'}
            </div>
            <div>
              <span>Positive Contactor: </span>
              {row.positiveContactorClosed ? 'Closed' : 'Open'}
            </div>
            {showContactorStateCause && (
              <>
                <br />
                <div style={{ color: 'red' }}>
                  <span>Contactors {contactorsExpectedStateText} Due To: </span>
                  <span>{contactorStateCauseText}</span>
                </div>
              </>
            )}
          </>
        }
      >
        <ContactorCell>
          <ContactorIcon
            $isReady={isHealthy}
            data-tooltip-id={
              row.arrayIndex + ':' + row.stringIndex + 'ContactorStatus'
            }
          >
            {row.negativeContactorClosed && row.positiveContactorClosed ? (
              <ContactorClosedIcon />
            ) : (
              <ContactorOpenIcon />
            )}
          </ContactorIcon>
          {!isHealthy && (
            <Warning>
              <WarningIcon />
            </Warning>
          )}
        </ContactorCell>
      </Tooltip>
    )
  }

  // these get set 1x and don't listen to stateChanges
  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) =>
        sortIdsWithColonRecursive(getRowId(a), getRowId(b)),
      width: '70px',
      cell: rowIdLink,
      noOmit: true,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 25, height: 12 }}>
          <StatusConnectedIcon />
        </div>
      ),
      id: 'communicating',
      selector: (row) => row.valid && row.stringEnabled,
      sortable: true,
      width: '50px',
      noOmit: true,
      cell: (row) =>
        getCommunicationStatus(
          row?.reportTimestamp?.status,
          row?.reportTimestamp?.timestamp,
        ),
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'health',
      selector: (row) => row.valid && row.stringEnabled,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: rowHealth,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 25, height: 12 }}>
          <ContactorOpenIcon />
        </div>
      ),
      id: 'contactors',
      selector: (row) => row.negativeContactorClosed,
      sortable: true,
      width: '50px',
      noOmit: true,
      cell: cellContactors,
    },
    {
      name: 'In Rot.',
      id: 'rotation',
      selector: (row) => row.outRotation,
      sortable: true,
      width: '70px',
      cell: (row) => RowRotationWithTooltip(row.outRotation),
      noOmit: false,
      omit: false,
    },
    {
      name: 'SoC',
      id: 'soc',
      selector: (row) => row.soc,
      sortable: true,
      omit: false,
      noOmit: false,
      width: '80px',
      cell: (row) => CellSOC(row.soc),
    },
    ...(userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)
      ? [
          {
            name: 'Balance Source',
            id: 'balanceSource',
            selector: (row) => row.balanceSource,
            sortable: true,
            omit: false,
            noOmit: false,
            width: '120px',
            cell: (row) =>
              row.mixedBalancingSource ? 'Mixed' : row.balanceSource,
          },
          {
            name: 'Balance Mode',
            id: 'balanceMode',
            selector: (row) => getBalanceMode(row.balanceMode),
            sortable: true,
            omit: false,
            noOmit: false,
            width: '125px',
            cell: (row) =>
              row.mixedBalancingMode
                ? 'Mixed'
                : getBalanceDisplayStatus(row.balanceMode),
          },
          {
            name: 'Charge DB',
            id: 'chargeDB',
            selector: (row) =>
              formatNumber({ value: row.chargeDeadband, unit: ' mV' }),
            sortable: true,
            omit: false,
            noOmit: false,
            width: '90px',
            right: true,
            cell: (row) =>
              row.mixedChargeDeadband ? 'Mixed' : getDeadband(row, true),
          },
          {
            name: 'Discharge DB',
            id: 'dischargeDb',
            selector: (row) =>
              formatNumber({ value: row.dischargeDeadband, unit: ' mV' }),
            sortable: true,
            omit: false,
            noOmit: false,
            width: '110px',
            right: true,
            cell: (row) =>
              row.mixedDischargeDeadband ? 'Mixed' : getDeadband(row, false),
          },
          {
            name: 'Balance Target',
            id: 'balanceTarget',
            selector: (row) =>
              formatNumber({ value: row.targetMillivolts, unit: ' mV' }),
            sortable: true,
            omit: false,
            noOmit: false,
            width: '115px',
            right: true,
            cell: (row) =>
              row.mixedProvidedVoltage ? 'Mixed' : getTargetmV(row),
          },
        ]
      : []),

    {
      name: 'Balance Count',
      id: 'balanceCount',
      selector: (row) => BalanceCount(row.balancingCount),
      sortable: true,
      omit: false,
      noOmit: false,
      width: '115px',
      right: true,
    },
    {
      name: 'Voltage Meas.',
      id: 'measuredStringVoltage',
      selector: (row) => row.measuredStringVoltage,
      sortable: true,
      width: '105px',
      cell: (row) => _.round(row.measuredStringVoltage) + ' V',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Voltage Calc.',
      id: 'calculatedStringVoltage',
      selector: (row) => row.calculatedStringVoltage,
      sortable: true,
      width: '110px',
      cell: (row) => _.round(row.calculatedStringVoltage) + ' V',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Voltage Bus',
      id: 'dcBusVoltage',
      selector: (row) => row.dcBusVoltage,
      sortable: true,
      width: '100px',
      cell: (row) => _.round(row.dcBusVoltage) + ' V',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Voltage Δ',
      id: 'voltageDelta',
      selector: (row) => row.dcBusVoltage - row.measuredStringVoltage,
      sortable: true,
      width: '90px',
      cell: (row) => _.round(row.dcBusVoltage - row.measuredStringVoltage, 1),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Power A',
      id: 'powerA',
      selector: (row) => row.current,
      sortable: true,
      width: '85px',
      cell: (row) => _.chain(row).get('current').round(1).value() + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Power kW',
      id: 'powerKW',
      selector: (row) => row.kiloWatts,
      sortable: true,
      width: '85px',
      cell: (row) => _.chain(row).get('kiloWatts').round(1).value() + ' kW',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Energy kWh',
      id: 'energyKWh',
      selector: (row) => row.kilowattHours,
      sortable: true,
      width: '95px',
      cell: (row) =>
        _.chain(row).get('kilowattHours').round(1).value() + ' kWh',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Current',
      id: 'current',
      selector: (row) => row.current,
      sortable: true,
      width: '95px',
      cell: (row) => _.chain(row).get('current').round(1).value() + ' A',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Min Voltage',
      id: 'minCellVoltage',
      selector: (row) => row.cellMinVoltage,
      sortable: true,
      width: '120px',
      cell: (row) => _.round(row.cellMinVoltage).toLocaleString() + ' mV',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Max Voltage',
      id: 'maxCellVoltage',
      selector: (row) => row.cellMaxVoltage,
      sortable: true,
      width: '130px',
      cell: (row) => _.round(row.cellMaxVoltage).toLocaleString() + ' mV',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Avg Voltage',
      id: 'avgCellVoltage',
      selector: (row) => row.cellAvgVoltage,
      sortable: true,
      width: '120px',
      cell: (row) => _.round(row.cellAvgVoltage).toLocaleString() + ' mV',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Min Temp',
      id: 'minCellTemp',
      selector: (row) => row.cellMinTemp,
      sortable: true,
      width: '110px',
      cell: (row) => _.round(row.cellMinTemp) + '° C',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Max Temp',
      id: 'maxCellTemp',
      selector: (row) => row.cellMaxTemp,
      sortable: true,
      width: '110px',
      cell: (row) => _.round(row.cellMaxTemp) + '° C',
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'Cell Avg Temp',
      id: 'avgCellTemp',
      selector: (row) => row.cellAvgTemp,
      sortable: true,
      width: '110px',
      cell: (row) => _.round(row.cellAvgTemp) + '° C',
      noOmit: false,
      omit: false,
      right: true,
    },
  ]

  if (isStationConnected && userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC))
    columns.unshift({
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit: !userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC),
      noOmit: true,
      width: '55px',
      cell: columnActionMenu,
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    })

  const isHeaderButtonDisabled = selectedStringIds.length === 0
  // table column buttons
  return (
    <>
      <Table
        tableId={tableId}
        data={stringsData}
        columns={columns}
        fixedHeader={fixedHeader}
        pagination={pagination}
        getRowId={getRowId}
        defaultId="id"
        conditionalRowStyles={conditionalRowStyles}
        selectableRows={
          isStationConnected && userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)
        }
        onSelectedRowsChange={(e) => handleSelectedRowChange(e)}
        tableActions={StringTableActions(
          isStationConnected,
          userHasApiAccessCode,
          isHeaderButtonDisabled,
          modalDispatch,
          setShowCommandModal,
          selectedStringIds,
          stringsData,
        )}
      />

      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

export default StringTable

// Actions Menu Contents/Links to modals
const ActionsMenuModalContent = ({
  rowStringId,
  modalDispatch,
  setShowCommandModal,
  closeActionModal,
  data,
}) => {
  const actionClickHandler = (dispatchType) => {
    closeActionModal() // closes action menu, when command modal is opened
    modalDispatch({
      type: dispatchType,
      setShowCommandModal: setShowCommandModal,
      idsForModal: rowStringId,
      data,
    })
  }

  return (
    <div key={rowStringId.toString()} id={rowStringId.toString()}>
      <Styles.ActionModalSection>
        <Styles.ActionModalHeader>String Controls</Styles.ActionModalHeader>
        <ActionsMenuItem
          onClick={() => actionClickHandler('showContactorsModal')}
        >
          <Styles.MenuIcon>
            <Icon
              size="xs"
              color={COLORS.action_blue}
              icon={<ContactorClosedIcon />}
            />
          </Styles.MenuIcon>
          <div>Set Contactors</div>
        </ActionsMenuItem>
        <ActionsMenuItem
          onClick={() => actionClickHandler('showRotationModal')}
        >
          <Styles.MenuIcon>
            <Icon size="xxs" color={COLORS.action_blue} icon={<CheckIcon />} />
          </Styles.MenuIcon>
          <div>Set Rotation</div>
        </ActionsMenuItem>
        <ActionsMenuItem
          onClick={() => actionClickHandler('showBalancingModal')}
        >
          <Styles.MenuIcon>
            <Icon
              size="xxs"
              color={COLORS.action_blue}
              icon={<BalancingIcon />}
            />
          </Styles.MenuIcon>
          <div>Set Balancing</div>
        </ActionsMenuItem>
      </Styles.ActionModalSection>
    </div>
  )
}

const ContactorCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ContactorIcon = styled.div`
  width: 25px;
  height: 25px;
  fill: ${(props) => (props.$isReady ? '#00ba86' : 'red')};
`
const Warning = styled.div`
  height: 15px;
  fill: red;
  margin-left: 5px;
`

const StringTableActions = (
  isStationConnected,
  userHasApiAccessCode,
  isHeaderButtonDisabled,
  modalDispatch,
  setShowCommandModal,
  selectedStringIds,
  stringsData,
) =>
  isStationConnected &&
  userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC) && (
    <Styles.ButtonContainer>
      <FormCommandButton
        disabled={isHeaderButtonDisabled}
        onClick={() => {
          modalDispatch({
            type: 'showContactorsModal',
            setShowCommandModal: setShowCommandModal,
            open: true,
            idsForModal: selectedStringIds,
            data: stringsData,
          })
        }}
      >
        <Icon
          size="sm"
          color={isHeaderButtonDisabled ? '#cccccc' : COLORS.action_blue}
          icon={<ContactorClosedIcon />}
        />
        Set Contactors
      </FormCommandButton>

      <FormCommandButton
        disabled={isHeaderButtonDisabled}
        onClick={() => {
          modalDispatch({
            type: 'showRotationModal',
            setShowCommandModal: setShowCommandModal,
            open: true,
            idsForModal: selectedStringIds,
            data: stringsData,
          })
        }}
      >
        <Icon
          size="xxs"
          color={isHeaderButtonDisabled ? '#cccccc' : COLORS.action_blue}
          icon={<CheckIcon />}
        />
        Set Rotation
      </FormCommandButton>

      <FormCommandButton
        disabled={isHeaderButtonDisabled}
        onClick={() => {
          modalDispatch({
            type: 'showBalancingModal',
            setShowCommandModal: setShowCommandModal,
            open: true,
            idsForModal: selectedStringIds,
            data: stringsData,
          })
        }}
      >
        <Icon
          size="xxs"
          color={isHeaderButtonDisabled ? '#cccccc' : COLORS.action_blue}
          icon={<BalancingIcon />}
        />
        Set Balancing
      </FormCommandButton>
    </Styles.ButtonContainer>
  )
