import styled from 'styled-components/macro'

export const Search = styled.input`
  flex: 1;
  border: 1px solid #d0d0d0;
  border-radius: 15px;
  font-size: 12px;
  padding: 5px 15px;
  margin-right: 10px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
`
