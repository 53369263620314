import styled from 'styled-components/macro'

export const RowHeader = styled.div`
  margin-bottom: 10px;
  color: #949494;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`
export const CardRow = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 -20px 15px -10px;
`
export const CardSpacer = styled.div`
  padding: 0 10px 20px;
  flex: 1;
  max-width: 25%;
  min-width: 250px;
`
export const TileSpacer = styled.div`
  display: flex;
  padding: 0 10px 20px;
  width: 190px;
`
