import { formatNumber } from 'utils/formatting'
import { kilovar_to_megavar } from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function PowerFactorTable({ data }) {
  const tableData = [
    {
      name: 'Power Factor Command',
      operation: `${data?.sitePowerFactorCmd} p. u.`,
      status: `${data?.sitePowerFactorSetting} p. u.`,
    },
    {
      name: 'Ramp Rate Up',
      status: formatNumber({
        value: kilovar_to_megavar(data?.rampRateUpPowerFactorSettingkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
      operation: formatNumber({
        value: kilovar_to_megavar(data?.rampRateUpPowerFactorCmdkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
    },
    {
      name: 'Ramp Rate Down',
      status: formatNumber({
        value: kilovar_to_megavar(
          data?.rampRateDownPowerFactorSettingkVArPerMin,
        ),
        precision: 2,
        unit: ' MVAr',
      }),
      operation: formatNumber({
        value: kilovar_to_megavar(data?.rampRateDownPowerFactorCmdkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
    },
  ]

  return (
    <StatusAndOperationTable
      tableId="EksPcsPowerFactorTable"
      data={tableData}
    />
  )
}
