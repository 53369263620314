import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

const StationService = ({ setTabState }) => {
  const params = useParams()

  useEffect(() => {
    setTabState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = `${params.stationCode}::${params.blockIndex} - Service`
  }, [params])

  return (
    <Container>
      <Text>Service Section Coming Soon</Text>
    </Container>
  )
}

export default StationService

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Text = styled.div`
  font-size: 16px;
  color: #9d9d9d;
  font-weight: 500;
`
