import { StationDetailsPageLayout } from 'components/Layout'
import { Toolbar, ToolbarSection } from 'components/Toolbar'
import Breadcrumbs from '../../SystemDetailsBreadcrumbs/Breadcrumbs'

const ListPage = ({
  selectedParams,
  summaryColumnComponent,
  tableComponent,
  hideBreadcrumbs = false,
}) => {
  return (
    <StationDetailsPageLayout>
      <StationDetailsPageLayout.LeftColumn>
        {summaryColumnComponent}
      </StationDetailsPageLayout.LeftColumn>
      <StationDetailsPageLayout.RightColumn>
        {!hideBreadcrumbs && (
          <Toolbar>
            <ToolbarSection>
              <Breadcrumbs selectedParams={selectedParams} />
            </ToolbarSection>
          </Toolbar>
        )}
        <StationDetailsPageLayout.TableContainer>
          {tableComponent}
        </StationDetailsPageLayout.TableContainer>
      </StationDetailsPageLayout.RightColumn>
    </StationDetailsPageLayout>
  )
}

export default ListPage
