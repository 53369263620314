import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * Collection Segment Detail data
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useCollectionSegmentDetailsGraphQuery = (period) => {
  const { stationCode, blockIndex, centipedeLineupId, collectionSegmentId } =
    useParams()
  const { session } = useContext(SessionContext)
  return useQuery(
    [
      'collectionSegmentDetailsGraph',
      stationCode,
      blockIndex,
      centipedeLineupId,
      collectionSegmentId,
    ],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/centipedelineups/${centipedeLineupId}/collectionsegments/${collectionSegmentId}/graph?period=${period}`,
        axiosOptions,
      )

      return response.data
    },
    { refetchInterval: 5000 },
  )
}

export default useCollectionSegmentDetailsGraphQuery
