// Component Imports
import Icon from 'components/Icon/Icon'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import ContactorState from 'api/types/ContactorState'

// Icon Imports
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as ContactorClosedIcon } from 'icons/contactor-closed-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'

// Determine if a contactor operation is pending based on user input and contactor state.
const isContactorOperationComplete = (string, userInputForm) => {
  const expectedClosed = userInputForm.contactorState === ContactorState.CLOSED
  const bothClosed =
    string.negativeContactorClosed && string.positiveContactorClosed
  const bothOpen =
    !string.negativeContactorClosed && !string.positiveContactorClosed
  return (expectedClosed && bothClosed) || (!expectedClosed && bothOpen)
}

const contactorsHeadings = 'CONTACTOR STATE'

const contactorTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.contactorState === ContactorState.CLOSED ? (
        <Icon
          size="xs"
          color={COLORS.powin_green}
          icon={<ContactorClosedIcon />}
        />
      ) : (
        <Icon
          size="xs"
          color={COLORS.powin_green}
          icon={<ContactorOpenIcon />}
        />
      )}
    </>
  )
}

export const ArrayContactorResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={isContactorOperationComplete}
      resultHeading={contactorsHeadings}
      ResultsTd={contactorTd}
      pendingQueryName={'arrayDetailsFunc'}
    />
  )
}
