import styled from 'styled-components/macro'

export const CardSpacer = styled.div`
  padding: 0 10px 20px;
  flex: 1;
  max-width: 25%;
  min-width: 250px;
`

export const TitleContainer = styled.div`
  display: flex;
  margin-top: 5px;
`

export const Title = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
`

export const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
`

export const DropDownContainer = styled.div`
  margin-top: -40px;
  padding-right: 5px;
  float: right;
  font-size: 12px;
`
