import { useState } from 'react'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import useEksPpcDetailsQuery from 'api/useQueryHooks/useEksPpcDetailsQuery'
import ErrorsAndAlarmsTable from 'modules/Station/components/Tables/EksPcsGroupDetails/ErrorsAndAlarmsTable'

import { PpcModesTable } from './Tables/PpcModesTable'
import { SetPointsTable } from './Tables/SetPointsTable'
import styled from 'styled-components/macro'
import { PowerCurtailmentAndRampRateTable } from './Tables/PowerCurtailmentAndRampRateTable'
import { FrequencyResponseTable } from './Tables/FrequencyResponseTable'
import { VoltageTable } from './Tables/VoltrageTable'
import { PowerFactorTable } from './Tables/PowerFactorTable'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { ApiErrorMessage } from 'components/ApiErrorMessage/ApiErrorMessage'
import { EditPpcSetpointsModal } from 'modules/Station/components/EksPpcModals/EditPpcSetpointsModal'
import { EditPpcModesModal } from 'modules/Station/components/EksPpcModals/EditPpcModesModal'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import Icon from 'components/Icon/Icon'
import { ReactComponent as PencilIcon } from 'icons/pencil-icon.svg'
import { COLORS } from 'design_system/colors'
import { ReactivePowerTable } from './Tables/ReactivePowerTable'
import useProgressToaster from 'hooks/useProgressToaster/useProgressToaster'

export const EksPowerPlantControllerTable = () => {
  const { data, isLoading, error, isError } = useEksPpcDetailsQuery()
  const [openModalId, setOpenModalId] = useState(null)
  const modalToast = useProgressToaster()

  if (isLoading) return <LoadingPage />

  if (isError) return <ApiErrorMessage error={error} />

  const canEditSetpoints = data?.opModeInterfaceSetting === 'STACK_OS_CONTROL'

  function closeModal() {
    setOpenModalId(null)
  }

  function openModal(id) {
    if (!['ppc', 'modes'].includes(id)) return
    closeModal()
    setOpenModalId(id)
  }

  return (
    <>
      <ExpandableCard title="Errors and Alarms" minimal isExpanded>
        <ErrorsAndAlarmsTable data={data?.errorsAndAlarms ?? []} />
      </ExpandableCard>

      <ExpandableCard
        title="PPC Modes"
        minimal
        isExpanded
        summarySections={[
          {
            type: 'jsx',
            value: (
              <ButtonWrapper>
                <FormCommandButton onClick={() => openModal('modes')}>
                  <Icon
                    size="xxs"
                    color={COLORS.action_blue}
                    icon={<PencilIcon />}
                  />
                  Edit
                </FormCommandButton>
              </ButtonWrapper>
            ),
          },
        ]}
      >
        <PpcModesTable data={data} />
      </ExpandableCard>

      <ExpandableCard
        title="Setpoints"
        minimal
        isExpanded
        summarySections={[
          {
            type: 'jsx',
            value: (
              <ButtonWrapper>
                <FormCommandButton
                  disabled={!canEditSetpoints}
                  onClick={() => openModal('ppc')}
                >
                  <Icon
                    size="xxs"
                    color={
                      canEditSetpoints
                        ? COLORS.action_blue
                        : COLORS.disabled_action
                    }
                    icon={<PencilIcon />}
                  />
                  Edit
                </FormCommandButton>
              </ButtonWrapper>
            ),
          },
        ]}
      >
        <SetPointsTable data={data} />
      </ExpandableCard>

      <ExpandableCard
        title="Active Power Curtailment And Ramp Rate"
        minimal
        isExpanded
      >
        <PowerCurtailmentAndRampRateTable data={data} />
      </ExpandableCard>
      <ExpandableCard title="Frequency Response" minimal isExpanded>
        <FrequencyResponseTable data={data} />
      </ExpandableCard>
      <ExpandableCard title="Reactive Power" minimal isExpanded>
        <ReactivePowerTable data={data} />
      </ExpandableCard>
      <ExpandableCard title="Voltage (VC REF / VC Q-V)" minimal isExpanded>
        <VoltageTable data={data} />
      </ExpandableCard>
      <ExpandableCard title="Power Factor" minimal isExpanded>
        <PowerFactorTable data={data} />
      </ExpandableCard>
      {openModalId === 'ppc' && (
        <EditPpcSetpointsModal
          isOpen
          handleClose={closeModal}
          toast={modalToast}
        />
      )}
      {openModalId === 'modes' && (
        <EditPpcModesModal isOpen handleClose={closeModal} toast={modalToast} />
      )}
    </>
  )
}

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
