import { CommandResultsTemplate } from '../../CommandResultsTemplate'

import { COLORS } from 'design_system/colors'
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as MinusIcon } from 'icons/minus-icon.svg'
import Icon from 'components/Icon/Icon'

const PlugInStatusResult = (props) => {
  const { ids, userInputForm } = props
  const pendingQueryName = 'setPlugInStatus'
  const heading = 'Set PlugIn Status'

  const statusEvaluation = (plugInList, form, id) => {
    let ret = false
    for (const plugIn of plugInList) {
      if (
        `${plugIn.id}` === id &&
        plugIn.enabled === (form.enabled === 'true') &&
        plugIn.valid === true
      ) {
        ret = true
      }
    }
    return ret
  }

  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={statusEvaluation}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={pendingQueryName}
    />
  )
}

const ResultTd = (props) => {
  const { userInputForm } = props
  return (
    <>
      {userInputForm.enabled === 'true' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_green} icon={<MinusIcon />} />
      )}
    </>
  )
}

export default PlugInStatusResult
