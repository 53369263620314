import { create } from 'zustand'

export const defaultFilterModel = {
  filterName: undefined,
  severity: { ids: [] },
  station: { ids: [] },
  state: { ids: [] },
  alertName: { ids: [] },
  status: { ids: [] },
  extent: undefined,
  ageInMinutes: undefined,
  visibility: { ids: ['Unmuted'] },
  assignee: { ids: [] },
}

/**
 * NOTE!! State has to be updated immutably and the set function merges state to help it.
 * This model holds the UI state representing filter select components state and
 * not what is sent to data api for the query.
 * See AlertManagement.jsx.getApiValues for backend call
 */
export const useAlertFilterGlobalStore = create((set) => ({
  filters: { ...defaultFilterModel },
  currentCustomFilter: '',
  setCurrentCustomFilter: (_currentCustomFilter) => {
    set({
      currentCustomFilter: _currentCustomFilter,
    })
  },
  setFilters: (_filters) => {
    set({
      filters: { ...defaultFilterModel, ..._filters },
    })
  },
  setFilterName: (name) =>
    set((state) => ({
      filters: { ...state.filters, filterName: name },
    })),
  setSeverity: (ids) =>
    set((state) => ({
      filters: { ...state.filters, severity: { ids: [...ids] } },
    })),
  setStation: (ids) =>
    set((state) => ({
      filters: { ...state.filters, station: { ids: [...ids] } },
    })),
  setState: (ids) =>
    set((state) => ({
      filters: { ...state.filters, state: { ids: [...ids] } },
    })),
  setAlertName: (ids) =>
    set((state) => ({
      filters: { ...state.filters, alertName: { ids: [...ids] } },
    })),
  setStatus: (ids) =>
    set((state) => ({
      filters: { ...state.filters, status: { ids: [...ids] } },
    })),
  setExtent: (extentVal) =>
    set((state) => ({
      filters: { ...state.filters, extent: extentVal },
    })),
  setAgeInMinutes: (ageInMinutes) =>
    set((state) => ({
      filters: { ...state.filters, ageInMinutes: ageInMinutes },
    })),
  setVisibility: (ids) =>
    set((state) => ({
      filters: { ...state.filters, visibility: { ids: [...ids] } },
    })),
  setAssignee: (ids) =>
    set((state) => ({
      filters: { ...state.filters, assignee: { ids: [...ids] } },
    })),
  reset: () => set({ filters: { ...defaultFilterModel } }),
}))
