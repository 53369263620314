import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'

export const RotationResults = ({ ids, rotationForm }) => {
  const rotationEvaluation = (stringDetails, userInputForm) =>
    stringDetails.outRotation === (userInputForm.outRotation === 'true')

  const rotationHeadings = 'IN ROTATION'

  const rotationTd = ({ userInputForm }) => {
    return (
      <>
        {userInputForm.outRotation === 'false' ? (
          <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
        ) : (
          <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
        )}
      </>
    )
  }

  const pendingQueryName = 'stringDetails'
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={rotationForm}
      pendingEvaluation={rotationEvaluation}
      resultHeading={rotationHeadings}
      ResultsTd={rotationTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
