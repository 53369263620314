import React from 'react'
import _ from 'lodash'

// Component Imports
import {
  Toolbar,
  ToolbarSection,
  ToolbarLabel,
} from '../../../../../components/Toolbar'
import ToolbarMultiFilter from '../../../../../components/Toolbar/ToolbarMultiFilter/ToolbarMultiFilter'
import AnalysisArrayRangeFilter from './AnalysisArrayRangeFilter'
import AnalysisZoomToggle from './AnalysisZoomToggle/AnalysisZoomToggle'

const AnalysisToolbar = ({
  data,
  viewType,
  setViewType,
  setMinArray,
  setMaxArray,
  filters,
  setFilters,
}) => {
  const handleFilterToggle = (filter) => {
    let filterStates = _.map(filters, (f) => {
      return f.id === filter.id ? filter : f
    })
    setFilters(filterStates)
  }

  const handleRangeFilter = (min, max) => {
    setMinArray(min)
    setMaxArray(max)
  }

  return (
    <Toolbar>
      <ToolbarSection>
        <ToolbarLabel>Filters</ToolbarLabel>
      </ToolbarSection>
      <ToolbarSection>
        <AnalysisZoomToggle viewType={viewType} setViewType={setViewType} />
      </ToolbarSection>
      <ToolbarSection>
        <AnalysisArrayRangeFilter
          data={_.chain(data).get('arrays').toArray().value()}
          handleChange={handleRangeFilter}
        />
      </ToolbarSection>
      <ToolbarSection>
        <ToolbarMultiFilter
          category="Connection Status"
          filters={_.filter(filters, { category: 'connection' })}
          handleFilter={handleFilterToggle}
        />
      </ToolbarSection>
    </Toolbar>
  )
}

export default AnalysisToolbar
