export const FONT_SIZES = {
  xxl: '1.25rem',
  xl: '1rem',
  large: '0.875rem',
  normal: '0.75rem',
  small: '0.625rem',
} as const

export const FONT_WEIGHT = {
  normal: 400,
  strong: 500,
  bold: 600,
} as const
