import { ReactComponent as EksPpcIcon } from 'icons/eks-ppc-icon.svg'
import SystemDetailsSummary from '../../System/Summary/SystemDetailsSummary'

const EksPowerPlantControllerSummary = () => {
  // same summary we have at the system details page
  return (
    <SystemDetailsSummary
      title="EKS Power Plant Controller"
      icon={<EksPpcIcon />}
    />
  )
}

export default EksPowerPlantControllerSummary
