import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './GraphHeader.styles'
import { useState } from 'react'

const options = {
  '1m': '1 Minute',
  '15m': '15 Minutes',
  '6h': '6 Hours',
  '1w': '1 Week',
}

export const GraphHeader = ({ onSelect, isHvacTrends, period }) => {
  const [value, setValue] = useState(options[period])
  const selectValueHandler = (selectedValue) => {
    onSelect(selectedValue)
    setValue(options[selectedValue])
  }

  return (
    <Styles.Container>
      {!isHvacTrends && <Typography $bold>POWER GRAPHS</Typography>}
      <ToolbarDropdown
        title="Time range"
        titleValue={value}
        width="176px"
        handleClose={value}
      >
        <Styles.OptionWrapper onClick={() => selectValueHandler('1m')}>
          <Typography>1 Minute</Typography>
        </Styles.OptionWrapper>
        <Styles.OptionWrapper onClick={() => selectValueHandler('15m')}>
          <Typography>15 Minutes</Typography>
        </Styles.OptionWrapper>
        <Styles.OptionWrapper onClick={() => selectValueHandler('6h')}>
          <Typography>6 Hours</Typography>
        </Styles.OptionWrapper>
        <Styles.OptionWrapper onClick={() => selectValueHandler('1w')}>
          <Typography>1 Week</Typography>
        </Styles.OptionWrapper>
      </ToolbarDropdown>
    </Styles.Container>
  )
}
