import { formatNumber } from 'utils/formatting'
import { volt_to_kilovolt, kilovar_to_megavar } from 'utils/powerUnits'
import { StatusAndOperationTable } from './StatusAndOperationTable'

export function VoltageTable({ data }) {
  const tableData = [
    {
      name: 'Voltage Command',
      operation: formatNumber({
        value: volt_to_kilovolt(data?.siteVoltageCmdV),
        precision: 2,
        unit: ' kV',
      }),
      status: formatNumber({
        value: volt_to_kilovolt(data?.siteVoltageSettingV),
        precision: 2,
        unit: ' kV',
      }),
    },
    {
      name: 'Ramp Rate Up',
      operation: formatNumber({
        value: kilovar_to_megavar(data?.rampRateUpVoltageCmdkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(data?.rampRateUpVoltageSettingkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
    },
    {
      name: 'Ramp Rate Down',
      operation: formatNumber({
        value: kilovar_to_megavar(data?.rampRateDownVoltageCmdkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
      status: formatNumber({
        value: kilovar_to_megavar(data?.rampRateDownVoltageSettingkVArPerMin),
        precision: 2,
        unit: ' MVAr',
      }),
    },
  ]

  return (
    <StatusAndOperationTable tableId="EksPcsVoltageTable" data={tableData} />
  )
}
