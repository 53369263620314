import { useParams } from 'react-router-dom'

// Component Imports
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import StringTable from 'modules/Station/components/Tables/StringTable/StringTable'

// Icon Imports
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'

// API Imports
import _ from 'lodash'

const CentipedeStringListCard = ({ data, count, isLoading }) => {
  const { stationCode, blockIndex } = useParams()

  const stringsInRotation = _.chain(data)
    .filter({ outRotation: false })
    .value().length
  const stringsOutRotation = _.chain(data)
    .filter({ outRotation: true })
    .value().length

  return (
    <ExpandableCard
      title="STRINGS"
      icon={<StringIcon />}
      isExpanded={true}
      summarySections={[
        {
          label: 'Total',
          value: count.toLocaleString(),
          type: 'value',
        },
        {
          label: 'Ready | Not Ready',
          type: 'binary',
          positiveCount: stringsInRotation,
          negativeCount: stringsOutRotation,
          tooltip: `In Rotation: ${stringsInRotation} / Out Rotation: ${stringsOutRotation}`,
        },
      ]}
      listLink={`/block-details/${stationCode}/${blockIndex}/block-topology/string-list`}
    >
      <StringTable
        data={data}
        fixedHeader={false}
        pagination={false}
        isLoading={isLoading}
        isEnergySegment={true}
      />
    </ExpandableCard>
  )
}

export default CentipedeStringListCard
