import { ChangeEvent, ChangeEventHandler, FC } from 'react'
import styled from 'styled-components/macro'
import { AppStatusFormProps } from './form'

// Theme / Style Imports
import { FormRow } from 'components/Form/FormComponents.styled'

export const AppStatusForm: FC<AppStatusFormProps> = (props) => {
  const { onUserInputFormChange, userInputForm, ids } = props

  const handleSelect: ChangeEventHandler = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const updatedUserInputForm = { enabled: e.target.value }
    onUserInputFormChange(updatedUserInputForm, e.target.value !== 'choose')
  }

  return (
    <>
      <FormRow>
        <label>EMS App</label> <span>{ids[0].split(':')[0]}</span>
      </FormRow>

      <FormRow>
        <label>APP STATUS</label>
      </FormRow>

      <SelectWithBottomPadding
        value={userInputForm.enabled}
        onChange={handleSelect}
      >
        <option value="choose" disabled>
          Choose
        </option>
        <option value="false">Disabled</option>
        <option value="true">Enabled</option>
      </SelectWithBottomPadding>
    </>
  )
}

export const SelectWithBottomPadding = styled.select`
  margin-bottom: 20px;
`
