import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import HvacListSummary from './Summary/HvacListSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import HvacListTable from 'modules/Station/components/Tables/HvacListTable/HvacListTable'
import useHvacListQuery from 'api/useQueryHooks/useHvacListQuery'

const HvacList = ({ selectedParams, setSelectedParams, setSelectedPage }) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'hvac-list' })
    document.title = `${stationCode}::${blockIndex} - HVAC List`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<HvacListSummary data={data} />}
      tableComponent={
        <HvacListTable
          data={data}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
    />
  )

  return handleQueryLoadingAndError(useHvacListQuery(), ListPageJsx)
}

export default HvacList
