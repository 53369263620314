import styled from 'styled-components/macro'
import { ICON_SIZES } from 'design_system/sizes'

const assignSize = (size) => {
  return size === 'lg'
    ? ICON_SIZES.lg
    : size === 'md'
    ? ICON_SIZES.md
    : size === 'sm'
    ? ICON_SIZES.sm
    : size === 'xs'
    ? ICON_SIZES.xs
    : size === 'xxs'
    ? ICON_SIZES.xxs
    : size === 'xxxs'
    ? ICON_SIZES.xxxs
    : '35px'
}

export const Container = styled.div`
  fill: ${(props) => props.color};
  height: ${(props) => assignSize(props.size)};
  width: ${(props) => assignSize(props.size)};

  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    position: absolute;
    content: '_';
    color: ${(props) => props.$crossedColor};
    opacity: ${(props) => props.$crossedOpacity};
    font-weight: 5;
    font-size: 1.85em;
    left: 7px;
    top: -8px;
  }
`
