import styled from 'styled-components/macro'

export const GraphTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Square = styled.div`
  height: 14px;
  width: 14px;
  background-color: ${({ color }) => color};
`
