import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import ArrayDetailsSummary from './Summary/ArrayDetailsSummary'
import ArrayDetailsMainColumn from 'modules/Station/modules/SystemDetails/ArrayDetails/MainColumn/ArrayDetailsMainColumn'
import ACBatteryOneline from 'modules/Station/components/Onelines/ACBatteryOneline/ACBatteryOneline'

/**
 * Shared with AC Batteries and ACPV Batteries may need future separation ?
 * hiding oneLineComponent will hide oneLine button for types without a oneLine developed
 * @param {*} param0
 * @returns
 */
const ArrayDetails = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, arrayId, acPvBatteryIndex } = params
  // hide oneLine if acPvBattery not developed
  const oneLineComponent = acPvBatteryIndex ? null : <ACBatteryOneline />
  const listView = acPvBatteryIndex ? 'array-details-acpv' : 'array-details'

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex}::${arrayId} - Array Details`
    setSelectedParams({ ...params, listView: listView })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<ArrayDetailsSummary />}
      onelineComponent={oneLineComponent}
      mainColumnComponent={<ArrayDetailsMainColumn />}
    />
  )
}

export default ArrayDetails
