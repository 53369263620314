import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Component Imports
import ACPVBatDetailsMainColumn from './MainColumn/ACPVBatDetailsMainColumn'

// API Imports
import useACPVBatteryDataQuery from 'api/useQueryHooks/useACPVBatteryDataQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import ACPVBatterySummary from './Summary/ACPVBatteryDetailsSummary'

const ACPVBatteryDetails = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acPvBatteryIndex } = params
  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - AC PV Battery ${acPvBatteryIndex}`
    setSelectedParams({ ...params, listView: 'ac-pv-battery-detail' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // don't worry will reset on new id

  const DetailsPageJsx = (data) => {
    return (
      <DetailsPage
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
        setSelectedPage={setSelectedPage}
        summaryColumnComponent={<ACPVBatterySummary data={data} />}
        mainColumnComponent={
          <ACPVBatDetailsMainColumn acPvBatteryIndex={acPvBatteryIndex} />
        }
      />
    )
  }
  return handleQueryLoadingAndError(
    useACPVBatteryDataQuery(acPvBatteryIndex),
    DetailsPageJsx,
  )
}

export default ACPVBatteryDetails
