import { useCallback, useReducer, useState } from 'react'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import ErrorsAndAlarmsTable from 'modules/Station/components/Tables/EksPcsGroupDetails/ErrorsAndAlarmsTable'
import PcsModulesTable from 'modules/Station/components/Tables/EksPcsGroupDetails/PcsModulesTable'
import { SetPcsModuleInverterRotationModal } from 'modules/Station/components/Commands/PcsModuleInverterRotation/SetPcsModuleInverterRotationModal'
import { ClearDeviceAlertsModal } from 'modules/Station/components/Commands/ClearDeviceAlerts/ClearDeviceAlertsModal'
import ArrayTable from 'modules/Station/components/Tables/ArrayTable/ArrayTable'
import StringTable from 'modules/Station/components/Tables/StringTable/StringTable'
import PowerTrendsGraphs from './PowerTrendsGraphs'
import { FormCommandButton } from 'components/Form/FormComponents.styled'
import { ReactComponent as PencilIcon } from 'icons/pencil-icon.svg'
import { COLORS } from 'design_system/colors'
import Icon from 'components/Icon/Icon'
import { EksPcsGroupEditSettingsModal } from 'modules/Station/components/EksPcsGroupModals/EksPcsGroupEditSettingsModal'
import useProgressToaster from 'hooks/useProgressToaster/useProgressToaster'
import { sortStringByIdCompareFn } from 'utils/sort/strings'

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'showPcsModuleInverterRotationModal': {
      action.setShowCommandModal(true)
      return (
        <SetPcsModuleInverterRotationModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    case 'showFaultClearModal': {
      action.setShowCommandModal(true)
      return (
        <ClearDeviceAlertsModal
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          faultingDevices={action.faultingDevices}
          deviceType={action.deviceType}
          setShowCommandModal={action.setShowCommandModal}
        />
      )
    }
    case 'showEksPcsGroupEditSettingsModal': {
      action.setShowCommandModal(true)
      return (
        <EksPcsGroupEditSettingsModal
          closeModal={() => action.setShowCommandModal(false)}
          data={action.data}
          toast={action.toast}
        />
      )
    }
    default:
      return null
  }
}

const EksPcsGroupDetailsMain = ({ data }) => {
  const [modalContent, modalDispatch] = useReducer(modalReducer)
  const [showCommandModal, setShowCommandModal] = useState(false)
  const toast = useProgressToaster()

  const handleModalAction = useCallback(
    (action) => {
      modalDispatch({
        ...action,
        setShowCommandModal: setShowCommandModal,
      })
    },
    [modalDispatch],
  )

  //const isOnPpcControlMode = data?.interfaceOperationMode === 'PPC_CONTROL'
  //const isOnStackOsControlMode = data?.interfaceOperationMode === 'STACK_OS_CONTROL'

  //const isEditButtonDisabled = !(isOnPpcControlMode || isOnStackOsControlMode)

  return (
    <>
      <FormCommandButton
        disabled
        onClick={() =>
          handleModalAction({
            type: 'showEksPcsGroupEditSettingsModal',
            data,
            toast,
          })
        }
        style={{ margin: '0.5rem 0' }}
      >
        <Icon size="xxs" color={COLORS.disabled_action} icon={<PencilIcon />} />
        Edit PCS Settings
      </FormCommandButton>
      <ExpandableCard
        title="Errors and Alarms"
        isExpanded={true}
        minimal={true}
      >
        <ErrorsAndAlarmsTable data={data?.errorsAndAlarms ?? []} />
      </ExpandableCard>
      <ExpandableCard title="PCS Modules" isExpanded minimal>
        <PcsModulesTable
          data={data?.pcsModules ?? []}
          controlMode={data?.interfaceOperationMode}
          openModal={handleModalAction}
        />
      </ExpandableCard>
      <ExpandableCard title="Arrays" isExpanded minimal>
        <ArrayTable data={data?.arrays ?? []} fixedHeader={false} />
      </ExpandableCard>
      <ExpandableCard title="Strings" isExpanded minimal>
        <StringTable
          fixedHeader={false}
          data={{ strings: data?.strings.sort(sortStringByIdCompareFn) ?? [] }}
        />
      </ExpandableCard>
      <ExpandableCard title="Trends" isExpanded minimal>
        <PowerTrendsGraphs />
      </ExpandableCard>
      {showCommandModal && modalContent}
    </>
  )
}

export default EksPcsGroupDetailsMain
