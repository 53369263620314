import React, { useReducer, useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Component Imports
import Table from 'components/Table/Table/Table'
import StackOsAppsDetails from '../StackOsAppsDetail/StackOsAppsDetail'
import {
  ActionsMenu,
  ActionsMenuItem,
} from 'components/ActionsMenu/ActionsMenu'
import Icon from 'components/Icon/Icon'
import { AppStatusModal } from 'modules/Station/components/Commands/AppStatus/app-status-modal.tsx'

// Context Imports

// Theme Imports
import { COLORS } from 'design_system/colors'

// Util Imports
import * as Styles from 'modules/Station/components/Tables/table.styled'

// Icon Imports
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as MinusIcon } from 'icons/minus-icon.svg'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'

/////////////////  ACTION MODALS /////////////////////////////////
/**
 * define modals for every control button/action
 * action type will determine which modal to open
 */

const modalReducer = (_state, action) => {
  switch (action?.type) {
    case 'appStatus': {
      action.setShowCommandModal(true)
      return () => (
        <AppStatusModal
          setShowCommandModal={action.setShowCommandModal}
          ids={action.idsForModal}
        />
      )
    }
    default:
      return null
  }
}

const removeHtml = (str) => {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}

/////////////////////////////////////////////////////////////////

const StackOsAppsTable = () => {
  const { data: blockStatus, isLoading } = useBlockStatus()
  const [showCommandModal, setShowCommandModal] = useState(false)
  const [ActionModalStateJsx, modalDispatch] = useReducer(modalReducer, null)
  const [activeActionRow, setActiveActionRow] = useState(null)
  const { userHasApiAccessCode } = useContext(AuthorizationContext)

  useEffect(() => {
    if (!showCommandModal) modalDispatch(null) // hides command modal
  }, [showCommandModal])

  if (isLoading) return <LoadingPage />

  const data = blockStatus.apps
  const listPageData = Object.values(data)

  const getIdFromRow = (row) => `${row.appCode}:${row.appPriority}`

  const conditionalRowStyles = [
    {
      when: (row) => getIdFromRow(row) === activeActionRow,
      style: {
        backgroundColor: 'rgb(213, 230, 241)',
      },
    },
  ]

  const columnActionMenu = (row) => {
    const rowId = getIdFromRow(row)

    return row.canDisable && userHasApiAccessCode(API_ACCESS_CODES.NINE_AC) ? (
      <ActionsMenu
        key={row}
        onOpen={() => onOpen(row)}
        onClose={() => onClose()}
      >
        {' '}
        <ActionsMenuModalContent
          rowId={rowId}
          modalDispatch={modalDispatch}
          setShowCommandModal={setShowCommandModal}
        />
      </ActionsMenu>
    ) : null
  }

  // open/close actions menu modal
  const onOpen = (row) => {
    setActiveActionRow(getIdFromRow(row))
  }

  const onClose = () => {
    setActiveActionRow(null)
  }

  const statusCell = (row) =>
    row.enabled ? (
      !_.isEmpty(row.shortStatus) ? (
        <Truncate>{removeHtml(row.shortStatus)}</Truncate>
      ) : (
        <Truncate>{removeHtml(row.longStatus)}</Truncate>
      )
    ) : (
      'Not Enabled'
    )

  const columns = [
    {
      name: <Styles.ActionsHeader>ACTIONS</Styles.ActionsHeader>,
      id: 'actions',
      sortable: false,
      omit: !userHasApiAccessCode(API_ACCESS_CODES.NINE_AC),
      noOmit: true,
      width: '55px',
      cell: columnActionMenu,
      style: { borderRight: 'solid rgba(0,0,0,.12) 1px' },
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <CheckIcon />
        </div>
      ),
      id: 'enabled',
      selector: (row) => row.enabled,
      sortable: true,
      width: '40px',
      cell: (row) => (
        <StatusIcon>
          {row.enabled ? (
            <CheckIcon fill="#00bf91" />
          ) : (
            <MinusIcon fill="#d0d0d0" />
          )}
        </StatusIcon>
      ),
    },
    {
      name: 'Priority',
      id: 'priority',
      selector: (row) => row.appPriority,
      sortable: true,
      width: '80px',
      noOmit: false,
      omit: false,
      cell: (row) => <LightText>{row.appPriority}</LightText>,
    },
    {
      name: 'App Name',
      id: 'appName',
      selector: (row) => row.appName,
      sortable: true,
      width: '250px',
      cell: (row) => <AppName $enabled={row.enabled}>{row.appName}</AppName>,
    },
    {
      name: 'App Code',
      id: 'appCode',
      selector: (row) => row.appCode,
      sortable: true,
      width: '100px',
      omit: true,
      noOmit: false,
      cell: (row) => <LightText>{row.appCode}</LightText>,
    },
    {
      name: 'Config',
      id: 'config',
      selector: (row) => row.configName,
      sortable: true,
      width: '180px',
      noOmit: false,
      omit: false,
      cell: (row) => (
        <LightText>
          {row.configName} / {row.configVersionId}
        </LightText>
      ),
    },
    {
      name: 'Status',
      id: 'status',
      selector: (row) => row.shortStatus,
      sortable: true,
      noOmit: false,
      omit: false,
      width: '480px',
      cell: statusCell,
    },
  ]

  const filters = [
    {
      name: 'Enabled',
      id: 'enabled',
      omit: false,
      filterBy: (row) => !row.enabled,
      section: 'Status',
    },
    {
      name: 'Disabled',
      id: 'disabled',
      omit: true,
      filterBy: (row) => row.enabled,
      section: 'Status',
    },
  ]

  return (
    <>
      <Table
        tableId="StackOSAppsTable"
        data={listPageData}
        columns={columns}
        headerPreFilters={filters}
        fixedHeader={false}
        pagination={false}
        expandableRows
        conditionalRowStyles={conditionalRowStyles}
        expandableRowsComponent={StackOsAppsDetails}
        getRowId={getIdFromRow}
        defaultId="priority"
      />

      {ActionModalStateJsx && <ActionModalStateJsx />}
    </>
  )
}

// Actions Menu Contents/Links to modals
const ActionsMenuModalContent = React.memo(
  ({ rowId, modalDispatch, setShowCommandModal, closeActionModal }) => {
    const actionClickHandler = (dispatchType) => {
      closeActionModal() // closes action menu, when command modal is opened
      modalDispatch({
        type: dispatchType,
        setShowCommandModal: setShowCommandModal,
        idsForModal: [rowId],
      })
    }

    return (
      <div key={rowId} id={rowId}>
        <Styles.ActionModalSection>
          <ActionsMenuItem onClick={() => actionClickHandler('appStatus')}>
            <Styles.MenuIcon>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
            </Styles.MenuIcon>
            <div>Toggle Status</div>
          </ActionsMenuItem>
        </Styles.ActionModalSection>
      </div>
    )
  },
)

export default StackOsAppsTable

const StatusIcon = styled.div`
  display: flex;
  height: 17px;
  width: 10px;
  line-height: 1.25;
  margin-right: 5px;
`
const AppName = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.$enabled ? 'initial' : '#989ea0')};
`
const LightText = styled.div`
  font-size: 12px;
  color: #989ea0;
`
const Truncate = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.$enabled ? 'initial' : '#989ea0')};
`
