import styled from 'styled-components/macro'
import { COLORS } from 'design_system/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 15px;
  margin-left: 40px;
  height: 600px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FormColumn = styled.div`
  margin-right: 30px;
  width: ${(props) => props.$width};
  margin-top: ${(props) => props.$marginTop ?? ''};
  margin-bottom: ${(props) => props.$marginBottom ?? ''};
`

export const StationDropItem = styled.div`
  cursor: ${(props) => (props.$isConnected ? 'pointer' : '')};
  padding: 4px;
  color: ${(props) => (props.$isConnected ? 'black' : 'gray')};
  background-color: ${(props) =>
    props.$backgroundColor ? COLORS.light_grey : ''};
`
