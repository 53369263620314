import styled from 'styled-components/macro'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
  padding: 35px 43px;
`
export const InterconnectTile = styled.div`
  position: relative;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border: 3px solid;
  border-color: ${(props) => (props.$isEnabled ? '#00b986' : 'red')};

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 2px;
    height: ${(props) => (props.$isEnabled ? '30px' : '15px')};
    background-color: ${(props) => (props.$isEnabled ? '#00b986' : 'red')};
    bottom: ${(props) => (props.$isEnabled ? '-30px' : '-15px')};
    left: 26px;
  }
`
export const ACBatteriesRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  margin: 20px 0 0 29px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 2px;
    background-color: ${(props) => (props.$isEnabled ? '#00b986' : 'red')};
    left: 0;
    top: 0;
    bottom: 200px;
  }
`
export const NodeContainer = styled.div`
  margin-bottom: 30px;
`

export const Icon = styled.div`
  width: 25px;
  height: 25px;
  fill: #788b95;
  background-color: #fff;
  border-radius: 50%;
`
