import { COLORS } from 'design_system/colors'
import { toast } from 'react-toastify'

export const inProgressToastOptions = {
  position: 'bottom-left',
  autoClose: false,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  icon: '',
  style: {
    width: 500,
    border: `2px solid ${COLORS.action_blue}`,
  },
}

export const failedToastOptions = {
  type: toast.TYPE.ERROR,
  position: 'bottom-left',
  autoClose: 7000,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  icon: '',
  style: {
    width: 500,
    border: `2px solid ${COLORS.powin_red}`,
  },
}

export const completeToastOptions = {
  type: toast.TYPE.SUCCESS,
  position: 'bottom-left',
  autoClose: 7000,
  closeButton: true,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  icon: '',
  style: {
    width: 500,
    border: `2px solid ${COLORS.powin_green}`,
  },
}
