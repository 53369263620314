import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import EksPcsGroupListSummary from './Summary/EksPcsListSummary'
import EksPcsGroupListTable from 'modules/Station/components/Tables/EksPcsGroupListTable/EksPcsGroupListTable'
import useEksPcsListQuery from 'api/useQueryHooks/useEksPcsListQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'

const EksPcsGroupList = ({
  setSelectedPage,
  selectedParams,
  setSelectedParams,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    document.title = `${stationCode}::${blockIndex} - All PCS Groups`
    setSelectedParams({ ...params, listView: 'eks-pcs-group-list' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return handleQueryLoadingAndError(useEksPcsListQuery(), (data) => (
    <ListPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={
        <EksPcsGroupListSummary data={data?.elementList ?? []} />
      }
      tableComponent={<EksPcsGroupListTable data={data?.elementList ?? []} />}
    />
  ))
}

export default EksPcsGroupList
