import { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import DetailsPage from '../../../components/Layouts/DetailsPage/DetailsPage'
import SystemOneline from '../../../components/Onelines/SystemOneline'
import SummaryColumn from './Summary/SystemDetailsSummary'
import MainColumn from './MainColumn/MainColumn'

const System = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  useEffect(() => {
    document.title = `${params.stationCode}::${params.blockIndex} - System`
    !_.isEqual(params, selectedParams) && setSelectedParams(params)
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<SummaryColumn />}
      onelineComponent={<SystemOneline />}
      mainColumnComponent={<MainColumn />}
    />
  )
}

export default System
