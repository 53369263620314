// Component Imports
import ToolbarToggleFilter from 'components/Toolbar/ToolbarToggleFilter/ToolbarToggleFilter'

const AnalysisZoomToggle = ({ viewType, setViewType }) => {
  return (
    <ToolbarToggleFilter
      title="Zoom Level"
      options={[
        {
          label: 'Array',
          value: 'array',
        },
        {
          label: 'String',
          value: 'string',
        },
      ]}
      selected={viewType}
      setSelected={setViewType}
    />
  )
}

export default AnalysisZoomToggle
