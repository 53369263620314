import { useContext, useEffect, useState } from 'react'

import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/typography'
import { Typography } from 'components/typography/Typography.styled'
import * as Styles from './FileGrab.styles'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import {
  FormInput,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { fileGrabCommand } from 'api/queries.api'
import { SessionContext } from 'contexts/session'
import useSystemIndexesQuery from 'api/useQueryHooks/useSystemIndexesQuery'
import { ToastMessage } from 'components/Toasts/ToastMessages'
import { provideBlobDownload } from 'api/helpers'

export const FileGrabCommand = ({ setSelectedCommandPage }) => {
  const DEFAULT_SELECT_VALUE = 'Select one'
  const SYSTEM_TYPES = ['Dragon', 'Feather', 'Phoenix']
  const inProgressTitle = 'FILE GRAB COMMAND IN PROGRESS'
  const completedTitle = 'FILE GRAB COMMAND COMPLETE'
  const failedTitle = 'FILE GRAB COMMAND FAILED'
  const failedMessage = 'Please try again.'
  const { session } = useContext(SessionContext)
  const [stationSelectedId, setStationSelectedId] =
    useState(DEFAULT_SELECT_VALUE)
  const [systemType, setSystemType] = useState('')
  const [systemIndex, setSystemIndex] = useState('')
  const [availableSystemIndexes, setAvailableSystemIndexes] = useState([])
  const [isSystemTypeAvailable, setIsSystemTypeAvailable] = useState(false)
  const [stationSelected, setStationSelected] = useState(null)
  const [filePath, setFilePath] = useState('')
  const { data: stations, isLoading: isLoadingStations } =
    useStationsDataQuery()

  const { data: systemIndexes, isLoading: isLoadingSystemIndexes } =
    useSystemIndexesQuery(
      stationSelected?.stationCode,
      stationSelected?.blockIndex,
    )

  useEffect(() => setSelectedCommandPage('File Grab Command', []))

  useEffect(() => {
    if (
      systemIndexes?.localControllerIndexes?.length === 0 ||
      systemIndexes?.environmentalControllerIndexes?.length === 0
    ) {
      setIsSystemTypeAvailable(false)
      setSystemType('Dragon')
    } else if (
      systemIndexes?.localControllerIndexes?.length ||
      systemIndexes?.environmentalControllerIndexes?.length
    ) {
      setIsSystemTypeAvailable(true)
      setSystemType(DEFAULT_SELECT_VALUE)
    }
  }, [stationSelected, systemIndexes])

  useEffect(() => {
    if (systemType === 'Dragon') {
      setSystemIndex(1)
    } else if (systemType === 'Feather') {
      setSystemIndex(DEFAULT_SELECT_VALUE)
      setAvailableSystemIndexes(systemIndexes?.environmentalControllerIndexes)
    } else if (systemType === 'Phoenix') {
      setSystemIndex(DEFAULT_SELECT_VALUE)
      setAvailableSystemIndexes(systemIndexes?.localControllerIndexes)
    } else if (systemType === DEFAULT_SELECT_VALUE || systemType === '') {
      setSystemIndex('')
    } else {
      setSystemIndex(DEFAULT_SELECT_VALUE)
    }
  }, [systemType, systemIndexes])

  const selectStationHandler = (station) => {
    setStationSelectedId(`${station.stationCode} :: ${station.blockIndex}`)
    setStationSelected(station)
  }

  const selectSystemTypeHandler = (type) => {
    setSystemType(type)
    setSystemIndex('')
  }

  const setFilePathHandler = (filePath) => {
    setFilePath(filePath)
  }

  const fetchFileGrabCommand = async () => {
    const response = await fileGrabCommand(
      session,
      stationSelected.stationCode,
      stationSelected.blockIndex,
      systemType,
      systemIndex,
      filePath,
    )

    return response
  }
  const onSubmitHandler = async () => {
    const toastId = ToastMessage(inProgressTitle, '', 'progress')
    try {
      const response = await fetchFileGrabCommand()
      if (
        response.valid &&
        response.message &&
        response.message.includes('http')
      ) {
        const filename = filePath.split('/').slice(-1)
        provideBlobDownload(filename[0], response.message)
        ToastMessage(
          completedTitle,
          `${stationSelected.stationCode}:${stationSelected.blockIndex} - File Downloaded`,
          'complete',
          toastId,
        )
      } else {
        ToastMessage(failedTitle, failedMessage, 'fail', toastId)
      }
    } catch (error) {
      ToastMessage(failedTitle, failedMessage, 'fail', toastId)
    }
  }

  const isFormValid =
    filePath != '' &&
    stationSelectedId != DEFAULT_SELECT_VALUE &&
    systemType !== '' &&
    systemType !== DEFAULT_SELECT_VALUE &&
    systemIndex !== '' &&
    systemIndex !== DEFAULT_SELECT_VALUE

  if (isLoadingStations) {
    return <LoadingPage></LoadingPage>
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        File Grab Command
      </Typography>
      <Styles.InputRow>
        <Styles.InputWrapper $maxWidth="250px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0 5px"
          >
            STATION :: BLOCK INDEX
          </Typography>
          <ToolbarDropdown
            title={stationSelectedId}
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            maxHeight="300px"
            modalWidth="inherit"
          >
            {stations.blocks.map((station) => (
              <Styles.DropdownItem
                $isSelected={
                  station.stationCode + ' :: ' + station.blockIndex ===
                  stationSelectedId
                }
                $isConnected={station.valid}
                key={station.stationCode + '::' + station.blockIndex}
                onClick={() => {
                  if (station.valid) selectStationHandler(station)
                }}
              >
                {station.stationCode + ' :: ' + station.blockIndex}
              </Styles.DropdownItem>
            ))}
          </ToolbarDropdown>
        </Styles.InputWrapper>
        <Styles.InputWrapper $maxWidth="100px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0 5px"
          >
            SYSTEM TYPE
          </Typography>
          {isLoadingSystemIndexes || !isSystemTypeAvailable ? (
            <FormInput
              key="systemType"
              disabled
              $isDisabled
              value={systemType}
            />
          ) : (
            <ToolbarDropdown
              title={systemType}
              handleClose={null}
              backgroundColor="white"
              textColor="black"
              fontSize="12px"
              height="32px"
              border="1px solid #ccc"
              borderRadius="4px"
              modalWidth="inherit"
            >
              {SYSTEM_TYPES.map((_systemType) => (
                <Styles.DropdownItem
                  $isConnected
                  $isSelected={_systemType === systemType}
                  key={_systemType}
                  onClick={() => selectSystemTypeHandler(_systemType)}
                >
                  {_systemType}
                </Styles.DropdownItem>
              ))}
            </ToolbarDropdown>
          )}
        </Styles.InputWrapper>
        <Styles.InputWrapper $maxWidth="100px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0 5px"
          >
            INDEX
          </Typography>

          {systemType === 'Dragon' ||
          systemType === '' ||
          systemType === DEFAULT_SELECT_VALUE ? (
            <FormInput
              key="systemIndex"
              disabled
              $isDisabled
              value={systemIndex}
            />
          ) : (
            <ToolbarDropdown
              title={systemIndex}
              handleClose={null}
              backgroundColor="white"
              textColor="black"
              fontSize="12px"
              height="32px"
              border="1px solid #ccc"
              borderRadius="4px"
              modalWidth="inherit"
            >
              {availableSystemIndexes?.map((index) => (
                <Styles.DropdownItem
                  $isConnected
                  $isSelected={index === systemIndex}
                  key={index}
                  onClick={() => setSystemIndex(index)}
                >
                  {index}
                </Styles.DropdownItem>
              ))}
            </ToolbarDropdown>
          )}
        </Styles.InputWrapper>
      </Styles.InputRow>
      <Styles.InputWrapper>
        <Typography
          $fontSize={FONT_SIZES.medium}
          $fontColor={COLORS.font_secondary}
          $margin="12px 0 12px"
        >
          FILE PATH
        </Typography>
        <FormInput
          onChange={(e) => setFilePathHandler(e.currentTarget.value)}
        />
      </Styles.InputWrapper>
      <Styles.InputWrapper>
        <FormSubmitButton
          $width="140px"
          $marginTop="25px"
          $isValid={isFormValid}
          disabled={!isFormValid}
          onClick={(e) => {
            onSubmitHandler(e)
          }}
        >
          SUBMIT
        </FormSubmitButton>
      </Styles.InputWrapper>
    </Styles.Container>
  )
}
