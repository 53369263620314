import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import { SessionContext } from 'contexts/session'
import { fetchSystemIndexes } from 'api/queries.api'

const useSystemIndexesQuery = (station, block) => {
  const { session } = useContext(SessionContext)
  return useQuery(['SystemIndexes', station, block], async () => {
    if (station && block) {
      return fetchSystemIndexes(session, station, block)
    }
    return {}
  })
}

export default useSystemIndexesQuery
