import Table from 'components/Table/Table/Table'
import * as Styles from '../table.styled'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import {
  HealthStatusIconWithToolTips,
  hvacStageStyle,
  checkNullRoundValue,
} from '../tableUtils'
import UnitsWithTooltip from './UnitsWithTooltip'
import { RegularEnclosureLocation } from './styles'

const HvacListTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const getRowId = (row) => Number(row.hvacIndex)

  const cellId = (row) => (
    <Styles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/hvac-list/${getRowId(
        row,
      )}`}
    >
      {getRowId(row)}
    </Styles.RowLink>
  )
  const cellLocation = (row) => {
    const urlByCentipedeEnclosureType = {
      CollectionSegment: `/block-details/${stationCode}/${blockIndex}/block-topology/collection-segments/${row.enclosureIndex}`,
      EnergySegment: `/block-details/${stationCode}/${blockIndex}/block-topology/centipede-energy-segments/${row.enclosureIndex}`,
    }

    /* non-centipede systems can have a different enclosureType, so no links for them */
    if (!urlByCentipedeEnclosureType[row.enclosureType]) {
      return (
        <RegularEnclosureLocation>
          {`${row.enclosureType} :: ${row.enclosureIndex}`}
        </RegularEnclosureLocation>
      )
    }

    return (
      <Styles.RowLink
        to={urlByCentipedeEnclosureType[row.enclosureType]}
      >{`${row.enclosureType} :: ${row.enclosureIndex}`}</Styles.RowLink>
    )
  }

  const columns = [
    {
      name: 'LOCATION',
      id: 'location',
      selector: (row) => `${row.enclosureType} :: ${row.enclosureIndex}`,
      sortable: true,
      width: '180px',
      noOmit: true,
      cell: cellLocation,
    },
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '80px',
      noOmit: true,
      cell: cellId,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.healthy,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.healthy),
    },
    {
      name: 'HUMIDITY',
      id: 'humidity',
      selector: (row) => row.humidity,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.humidity, '%'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'AIR TEMP',
      id: 'airTemp',
      selector: (row) => row.airTemp,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.airTemp, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'CELL TEMP',
      id: 'cellTemp',
      selector: (row) => row.avgCellTemp,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.avgCellTemp, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'COOL TO',
      id: 'coolTo',
      selector: (row) => row.coolTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.coolTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'HEAT TO',
      id: 'heatTo',
      selector: (row) => row.heatTo,
      sortable: true,
      width: '80px',
      cell: (row) => checkNullRoundValue(row.heatTo, '° C'),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'RESPONDING TO',
      id: 'respondTo',
      selector: (row) => row.respondingTo,
      sortable: true,
      width: '110px',
      cell: (row) => row.respondingTo,
      noOmit: false,
      omit: false,
    },
    {
      name: 'HVAC STAGE',
      id: 'hvacStage',
      selector: (row) => row.hvacStage,
      sortable: true,
      width: '90px',
      cell: (row) => hvacStageStyle(row.hvacStage),
      noOmit: false,
      omit: false,
    },
    {
      name: 'SIGNALS',
      id: 'signals',
      selector: (row) => row.signals,
      sortable: true,
      width: '80px',
      cell: (row) => row.signals,
      noOmit: false,
      omit: false,
    },
    {
      name: 'UNIT 1',
      id: 'unit1',
      width: '110px',
      cell: (row) => UnitsWithTooltip(1, row),
      noOmit: false,
      omit: false,
    },
    {
      name: 'UNIT 2',
      id: 'unit2',
      width: '110px',
      cell: (row) => UnitsWithTooltip(2, row),
      noOmit: false,
      omit: false,
    },
  ]
  return (
    <Table
      tableId="HVACListTable"
      data={data.elementList ?? []}
      columns={columns}
      getRowId={getRowId}
      defaultId="location"
      fixedHeader={fixedHeader}
      pagination
    />
  )
}

export default HvacListTable
