import { COLORS } from 'design_system/colors'
import styled, { css } from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  overflow: auto;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 275px;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`

export const CommandsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 16px;
  border: 1px solid ${COLORS.light_grey};
  border-radius: 5px;
  box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.75);
  overflow: hidden;
`

export const CommandItemWrapper = styled.div`
  padding: 12px;
  cursor: pointer;
  ${({ $active }) =>
    $active &&
    css`
      border-right: 5px solid ${COLORS.powin_green};
      background-color: ${COLORS.light_grey};
    `}

  &:hover {
    background-color: ${COLORS.light_grey};
  }
`
