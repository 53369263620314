import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import SensorsListSummary from './Summary/SensorsListSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import SensorsListTable from 'modules/Station/components/Tables/SensorsListTable/SensorsListTable'
import useSensorsListQuery from 'api/useQueryHooks/useSensorsListQuery'

const SensorsList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'sensors' })
    document.title = `${stationCode}::${blockIndex} - All Sensors`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<SensorsListSummary data={data.totalCounts} />}
      tableComponent={
        <SensorsListTable
          data={data.elementList}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
    />
  )

  return handleQueryLoadingAndError(useSensorsListQuery(), ListPageJsx)
}

export default SensorsList
