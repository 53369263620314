import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * load environmental devices for station from URL or optional provided params
 * @typedef {import('react-query').UseQueryOptions} UseQueryOptions
 * @param {UseQueryOptions} options ie {refetchInterval: false, }
 * @param {string} _stationCode optionally provide stationCode
 * @param {string} _blockIndex optionally provide blockIndex
 * @returns UseQueryResult
 */
const useEnvironmentalDevicesQuery = (
  options = { refetchInterval: 5000 },
  _stationCode = null,
  _blockIndex = null,
) => {
  const { session } = useContext(SessionContext)
  const { stationCode, blockIndex } = useParams()
  if (!_stationCode || !_blockIndex) {
    _stationCode = stationCode
    _blockIndex = blockIndex
  }
  return useQuery(
    ['environmentalDevices', _stationCode, _blockIndex],
    async () => {
      const apiUrl = session.server
      const axiosOptions = {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      }

      const response = await axios.get(
        `${apiUrl}station/${_stationCode}/block/${_blockIndex}/environmentaldevices`,
        axiosOptions,
      )
      return response.data
    },
    { ...options },
  )
}

export default useEnvironmentalDevicesQuery
