import { useEffect, useState } from 'react'

import * as Styles from './styles'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import Icon from 'components/Icon/Icon'

import { ReactComponent as SensorsIcon } from 'icons/sensor-icon.svg'
import { ReactComponent as HVACIcon } from 'icons/hvac-icon.svg'
import CollectionSegmentDetailSensorStatusTable from 'modules/Station/components/Tables/CollectionSegmentsTable/CollectionSegmentDetailSensorStatusTable'
import CollectionSegmentDetailHVACTable from 'modules/Station/components/Tables/CollectionSegmentsTable/CollectionSegmentDetailHVACTable'
import useCollectionSegmentDetailsGraphQuery from 'api/useQueryHooks/useCollectionSegmentDetailsGraphQuery'
import { GraphHeader } from 'components/Cards/common/GraphHeader/GraphHeader'
import { SeriesChart } from 'modules/Station/components/Charts/SeriesChart/SeriesChart'
import { COLORS } from 'design_system/colors'

const CollectionSegmentDetailsMainColumn = ({ data }) => {
  const [graphPeriod, setGraphPeriod] = useState('6h')
  const {
    data: graphData,
    refetch,
    isLoading,
  } = useCollectionSegmentDetailsGraphQuery(graphPeriod)

  const onSelectPeriod = (period) => setGraphPeriod(period)

  const customTitle = (icon, title) => {
    return (
      <Styles.TitleContainer>
        <Icon icon={icon} size="sm" />
        <Styles.Title>{title}</Styles.Title>
      </Styles.TitleContainer>
    )
  }

  useEffect(() => {
    refetch()
  }, [graphPeriod, refetch])

  if (isLoading) return null

  const formattedGraphData = {
    current: {
      title: 'Current',
      unit: ' Amp',
      series: [
        {
          name: 'Master Unit 1',
          graphData: graphData.currentOneDraw.series,
          color: COLORS.charts.light_blue,
        },
        {
          name: 'Master Unit 2',
          graphData: graphData.currentTwoDraw.series,
          color: COLORS.charts.pink,
        },
      ],
    },
    airTemp: {
      title: 'Air Temperature',
      unit: '°C',
      plotLines: true,
      plotLineMin: 15,
      plotLineMinText: 'Heat To Setpoint',
      plotLineMax: 35,
      plotLineMaxText: 'Cool to Setpoint',
      series: [
        {
          name: 'Air Temp.',
          graphData: graphData.airTemp.series,
          color: COLORS.charts.orange.series,
        },
      ],
    },
    masterUnit1: {
      title: 'Master Unit 1 Activity',
      unit: '%',
      series: [
        {
          name: 'Compressor',
          graphData: graphData.masterUnit1.coolingCompressor.series,
          color: COLORS.charts.light_blue,
        },
        {
          name: 'Elec. Heat',
          graphData: graphData.masterUnit1.elecHeat.series,
          color: COLORS.charts.green,
        },
        {
          name: 'Mixed Air Relay',
          graphData: graphData.masterUnit1.airRelay.series,
          color: COLORS.charts.baby_blue,
        },
        {
          name: 'Heat Pump',
          graphData: graphData.masterUnit1.heatPump.series,
          color: COLORS.charts.orange,
        },
        {
          name: 'Cooling Damper',
          graphData: graphData.masterUnit1.coolingDamper.series,
          color: COLORS.charts.dark_yellow,
        },
        {
          name: 'Indoor Fan',
          graphData: graphData.masterUnit1.indoorFan.series,
          color: COLORS.charts.pink,
        },
      ],
    },
    masterUnit2: {
      title: 'Master Unit 2 Activity',
      unit: '%',
      showXAxis: true,
      series: [
        {
          name: 'Compressor',
          graphData: graphData.masterUnit2.coolingCompressor.series,
          color: COLORS.charts.light_blue,
        },
        {
          name: 'Elec. Heat',
          graphData: graphData.masterUnit2.elecHeat.series,
          color: COLORS.charts.green,
        },
        {
          name: 'Mixed Air Relay',
          graphData: graphData.masterUnit2.airRelay.series,
          color: COLORS.charts.baby_blue,
        },
        {
          name: 'Heat Pump',
          graphData: graphData.masterUnit2.heatPump.series,
          color: COLORS.charts.orange,
        },
        {
          name: 'Cooling Damper',
          graphData: graphData.masterUnit2.coolingDamper.series,
          color: COLORS.charts.dark_yellow,
        },
        {
          name: 'Indoor Fan',
          graphData: graphData.masterUnit2.indoorFan.series,
          color: COLORS.charts.pink,
        },
      ],
    },
  }

  return (
    <div>
      <ExpandableCard
        title={customTitle(<SensorsIcon />, 'SENSOR STATUS')}
        isExpanded={true}
        minimal={true}
        className="CollectionSegmentDetailSensorStatus"
      >
        <CollectionSegmentDetailSensorStatusTable data={data.sensors} />
      </ExpandableCard>

      <Styles.CardSpacer />

      <ExpandableCard
        title={customTitle(<HVACIcon />, 'HVAC')}
        isExpanded={true}
        minimal={true}
      >
        <CollectionSegmentDetailHVACTable data={data.hvac} />
      </ExpandableCard>

      <Styles.CardSpacer />

      <ExpandableCard
        title={customTitle(<HVACIcon />, 'HVAC TRENDS')}
        isExpanded={true}
        minimal={true}
      >
        <Styles.DropDownContainer>
          <GraphHeader
            isHvacTrends={true}
            onSelect={onSelectPeriod}
            period={graphPeriod}
          />
        </Styles.DropDownContainer>
        <Styles.GraphsWrapper>
          <SeriesChart data={formattedGraphData.current} period={graphPeriod} />
          <SeriesChart data={formattedGraphData.airTemp} period={graphPeriod} />
          <SeriesChart
            data={formattedGraphData.masterUnit1}
            period={graphPeriod}
          />
          <SeriesChart
            data={formattedGraphData.masterUnit2}
            period={graphPeriod}
          />
        </Styles.GraphsWrapper>
      </ExpandableCard>
    </div>
  )
}

export default CollectionSegmentDetailsMainColumn
