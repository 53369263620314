import getAxiosBasicUrlAndHeader from 'api/helpers/getAxiosBasicUrlAndHeader'
import axios from 'axios'

/**
 * required 8PC
 * @param {Object} payload
 * @param {*} session
 * @param {string} stationCode
 * @param {string} blockIndex
 * @returns Promise success or fail message
 */
const clearEnclosureFaultsService = async (
  payload,
  session,
  stationCode,
  blockIndex,
  additionalPayload,
) => {
  const { enclosureIndex } = payload
  if (!additionalPayload.target) throw Error('Invalid query type')

  const jsonBody = {
    target: additionalPayload.target,
    index: enclosureIndex,
  }

  const { apiUrl, axiosHeader } = getAxiosBasicUrlAndHeader(session)

  return await axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/sensors/clearfaults`,
      jsonBody,
      axiosHeader,
    )
    .then((res) => res.data)
}

export default clearEnclosureFaultsService
