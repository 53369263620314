import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'
import { getDcDcGroupList } from 'api/queries.api'

export const createKey = (stationCode, blockIndex) => [
  'dcDcGroups',
  stationCode,
  blockIndex,
]

/**
 * @param {string} stationCode
 * @param {number} blockIndex
 * @returns UseQueryResult
 */
const useDcDcGroupsQuery = () => {
  const { stationCode, blockIndex } = useParams()
  const { session } = useContext(SessionContext)

  return useQuery(
    createKey(stationCode, blockIndex),
    async () => {
      const data = await getDcDcGroupList(session, stationCode, blockIndex)
      return data
    },
    { refetchInterval: 5000 },
  )
}

export default useDcDcGroupsQuery
