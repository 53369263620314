import axios from 'axios'
import { GeneralResponse, RotationForm } from './rotation'

export const setBlockInverterRotationService = async (
  ids: string[],
  rotationInfo: RotationForm,
  session: any,
): Promise<GeneralResponse> => {
  try {
    const apiUrl = session.server
    const inOrOut = rotationInfo.outRotation === 'true' ? 'out' : 'in'
    const calls = ids.map((id) => {
      const idParts = id.split(':')
      const stationCode = idParts[0]
      const blockIndex = idParts[1]

      return axios.post(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/rotation/pcs/${inOrOut}`,
        {
          excused: rotationInfo.excused,
          reason: rotationInfo.reason,
          explanation: rotationInfo.explanation,
        },
        {
          headers: {
            Accept: 'application/json',
            papitoken: session.token,
          },
        },
      )
    })

    const responses = await axios.all(calls)
    const anyFailures =
      responses.find((response) => response && response.data.valid !== true) !==
      undefined

    if (anyFailures) {
      return {
        status: 'error',
        message: 'PCS rotation failed. No PCSes available.',
        data: responses,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (error: any) {
    return { status: 'error', message: error.message }
  }
}
