import { BlockRotationModal } from 'modules/Station/components/Commands/block-rotation'
import { BlockInverterRotationModal } from 'modules/Station/components/Commands/block-inverter-rotation'
import { ExtractReportsDownloadModal } from 'components/ExtractReportsDownloadModal/ExtractReportsDownloadModal'
import { BlockContactorModal } from 'modules/Station/components/Commands/block-contactor/BlockContactorModal'
import { ACTION_MODAL_ACTIONS } from '../actions/action-modal.actions'

const FleetlistActionModalReducer = (state, action) => {
  switch (action?.type) {
    case ACTION_MODAL_ACTIONS.SHOW_CONTACTOR_MODAL: {
      const { ids, setShowCommandModal } = action
      setShowCommandModal(true)
      return () => (
        <BlockContactorModal
          ids={ids}
          setShowCommandModal={setShowCommandModal}
        />
      )
    }
    case ACTION_MODAL_ACTIONS.SHOW_INVERTER_ROTATION_MODAL: {
      const { ids, setShowCommandModal } = action
      setShowCommandModal(true)
      return () => (
        <BlockInverterRotationModal
          ids={ids}
          setShowCommandModal={setShowCommandModal}
        />
      )
    }
    case ACTION_MODAL_ACTIONS.SHOW_ROTATION_MODAL: {
      const { ids, setShowCommandModal } = action
      setShowCommandModal(true)
      return () => (
        <BlockRotationModal
          ids={ids}
          setShowCommandModal={setShowCommandModal}
        />
      )
    }
    case ACTION_MODAL_ACTIONS.SHOW_EXTRACT_REPORT_MODAL: {
      const { setShowCommandModal } = action
      setShowCommandModal(true)
      return () => (
        <ExtractReportsDownloadModal
          onClose={() => setShowCommandModal(false)}
        />
      )
    }
    default:
      return () => null
  }
}

export default FleetlistActionModalReducer
