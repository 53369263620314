import AppTooltip from 'components/AppTooltip/AppTooltip'
import { uniqueId } from 'lodash'
// Style Imports
import * as Styles from './styles'

const TernaryBarChartStrings = ({ data, showTooltip = true }) => {
  const tooltipId = 'TernaryBarChartTooltip' + uniqueId()
  const { online, nearline, offline, nonCommunicating, total } = data
  let toolTipString = `Online: ${online} | Nearline: ${nearline} | Offline: ${offline}`
  const isNonCommunicationAvail = !isNaN(nonCommunicating)
  if (isNonCommunicationAvail) {
    toolTipString = toolTipString.concat(` | Not
  Communicating: ${nonCommunicating}`)
  }

  return (
    <Styles.Container data-tooltip-id={tooltipId}>
      <Styles.GreenBar $percent={online / total} />
      <Styles.GreyBar $percent={nearline / total} />
      <Styles.RedBar $percent={offline / total} />
      {isNonCommunicationAvail && (
        <Styles.WhiteBar $percent={nonCommunicating / total} />
      )}
      {showTooltip && (
        <AppTooltip id={tooltipId}>
          <span>{toolTipString}</span>
        </AppTooltip>
      )}
    </Styles.Container>
  )
}

export default TernaryBarChartStrings
