import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as PcsIcon } from 'icons/dc-ac-pcs-icon.svg'
import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'

const EksPcsGroupListSummary = ({ data }) => {
  const total = data.length ?? 0
  const healthy = data.reduce(
    (sum, item) => (item.isHealthy ? sum + 1 : sum),
    0,
  )
  const unHealthy = total - healthy

  const summaryMap = {
    title: `PCS Groups`,
    titleIcon: <PcsIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthy}
        negativeCount={unHealthy}
        tooltip={`Healthy: ${healthy} | Unhealthy: ${unHealthy}`}
        id="eksPcsHealth"
      />
    ),
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default EksPcsGroupListSummary
