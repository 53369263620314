import _ from 'lodash'

import { ReactComponent as BlockMeterIcon } from 'icons/block-meter-icon.svg'
import { ReactComponent as AcBatteryIcon } from 'icons/ac-battery-icon.svg'
import { ReactComponent as ArrayIcon } from 'icons/array-icon.svg'
import { ReactComponent as DcAcPCSIcon } from 'icons/dc-ac-pcs-icon.svg'
import { ReactComponent as StringIcon } from 'icons/string-icon.svg'

export const getAcSystemTopology = (
  blockStatusData,
  electricalDevicesData,
  params,
  options,
) => {
  return [
    {
      id: 'allBlockMeters',
      label: 'Block Meters',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/block-meter-list`,
      icon: <BlockMeterIcon />,
      omit: _.isEmpty(blockStatusData.blockMeters),
    },
    {
      id: 'allAcBatteries',
      label: 'AC Batteries',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/ac-battery-list`,
      icon: <AcBatteryIcon />,
      omit: _.isEmpty(blockStatusData.acBatteries),
    },
    {
      id: 'allPCSes',
      label: 'PCSes',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/array-pcs-list`,
      icon: <DcAcPCSIcon />,
      omit: !electricalDevicesData?.pcses.total,
    },
    {
      id: 'allArrays',
      label: 'Arrays',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/array-list`,
      icon: <ArrayIcon />,
      omit: _.isEmpty(blockStatusData.arrays),
    },
    {
      id: 'allStrings',
      label: 'Strings',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/string-list`,
      icon: <StringIcon />,
      omit: !electricalDevicesData?.strings?.total,
    },
    {
      id: 'allPcsGroups',
      label: 'PCS Groups',
      url: `/block-details/${params.stationCode}/${params.blockIndex}/block-topology/eks-pcs-group-list`,
      icon: <DcAcPCSIcon />,
      omit:
        !options.isPcsGroupsEnabled || !electricalDevicesData?.pcsGroups?.total,
    },
  ]
}
