import Table from 'components/Table/Table/Table'

const columns = [
  {
    name: 'Type',
    id: 'type',
    selector: (row) => row.type,
    cell: (row) => row.type,
    sortable: true,
    width: '75px',
  },
  {
    name: 'Code',
    id: 'code',
    selector: (row) => row.code,
    cell: (row) => row.code,
    sortable: true,
    width: '150px',
  },
  {
    name: 'Description',
    id: 'description',
    selector: (row) => row.description,
    cell: (row) => row.description,
    sortable: true,
  },
]

const ErrorsAndAlarmsTable = ({ data }) => {
  return (
    <Table
      tableId="EksPcsErrorsAndAlarms"
      data={data ?? []}
      columns={columns}
      fixedHeader={false}
      controls={false}
      getRowId={(row) => Number(row.code)}
      pagination={data?.length > 10}
      defaultId="type"
    />
  )
}

export default ErrorsAndAlarmsTable
