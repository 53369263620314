import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Table from 'components/Table/Table/Table'
import TernaryBarChartStrings from 'components/Charts/TernaryBarChart/TernaryBarChartStrings'
import { ReactComponent as GasIcon } from 'icons/gas-icon.svg'
import { ReactComponent as HeatIcon } from 'icons/heat-icon.svg'
import * as Styles from '../table.styled'
import { getSortableStringFromStringCounts } from '../tableUtils'
import { createStringsObjectFromStackCounts } from 'utils/strings'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const EnclosureTable = ({ fixedHeader = false, pagination }) => {
  const params = useParams()
  const { data: blockStatusData, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  const data = blockStatusData.blockEnclosures
  const getRowId = (row) => Number(row.blockEnclosureIndex)

  const formattedData = _.toArray(data)

  const findAndReturnTrippedOrLatched = (row, type) => {
    const sensor = _.chain(row.openClosedDetectors).find({ type }).value()
    return sensor?.tripped || sensor?.latched
  }

  const healthStatusIcon = (row, types, icon) => {
    if (_.isEmpty(row.openClosedDetectors)) return 'N/A'
    const unHealthy = types.reduce(
      (acc, cur) => acc || findAndReturnTrippedOrLatched(row, cur),
      false,
    )
    return <Styles.StatusIcon $isHealthy={!unHealthy}>{icon}</Styles.StatusIcon>
  }

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '40px',
      cell: (row) => (
        <Styles.RowLink
          to={`/block-details/${params.stationCode}/${params.blockIndex}/block-topology/container/${row.blockEnclosureIndex}`}
        >
          {row.blockEnclosureIndex}
        </Styles.RowLink>
      ),
    },
    {
      name: 'Name',
      id: 'containerName',
      selector: (row) => row.containerName,
      sortable: true,
      width: '120px',
      noOmit: true,
      omit: true,
    },
    {
      name: 'Temp',
      id: 'temp',
      selector: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.temperature`),
      sortable: true,
      width: '70px',
      noOmit: false,
      omit: false,
      cell: (row) => {
        const temp = _.get(
          row,
          `hvacs.${row.blockEnclosureIndex}.temperature`,
          'N/A',
        )
        return temp === 'N/A' ? 'N/A' : _.round(temp) + '° C'
      },
    },
    {
      name: 'Stage',
      id: 'hvacStage',
      selector: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.hvacStage`),
      sortable: true,
      width: '200px',
      noOmit: false,
      omit: false,
    },
    {
      name: 'Cool To',
      id: 'coolTo',
      selector: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.coolSetpoint`),
      sortable: true,
      width: '80px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.coolSetpoint`) + '° C',
    },
    {
      name: 'Heat To',
      id: 'heatTo',
      selector: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.heatSetpoint`),
      sortable: true,
      width: '80px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        _.get(row, `hvacs.${row.blockEnclosureIndex}.heatSetpoint`) + '° C',
    },
    {
      name: 'Gas',
      id: 'gas',
      selector: (row) =>
        findAndReturnTrippedOrLatched(row, 'HighHydrogenAlarm'),
      sortable: true,
      width: '50px',
      noOmit: false,
      omit: false,
      cell: (row) => healthStatusIcon(row, ['HighHydrogenAlarm'], <GasIcon />),
    },
    {
      name: 'Heat',
      id: 'heat',
      selector: (row) => findAndReturnTrippedOrLatched(row, 'FireAlarm'),
      sortable: true,
      width: '60px',
      noOmit: false,
      omit: false,
      cell: (row) =>
        healthStatusIcon(
          row,
          ['FireAlarm', 'FireSupervisory', 'FireTrouble'],
          <HeatIcon />,
        ),
    },
    {
      name: 'Strings',
      id: 'strings',
      width: '90px',
      noOmit: false,
      omit: false,
      sortable: true,
      selector: (row) =>
        getSortableStringFromStringCounts(
          createStringsObjectFromStackCounts(
            row.onlineStackCount,
            row.offlineStackCount,
            row.nearlineStackCount,
            row.nonCommunicatingStackCount,
          ),
        ),
      cell: (row) => {
        const strings = createStringsObjectFromStackCounts(
          row.onlineStackCount,
          row.offlineStackCount,
          row.nearlineStackCount,
          row.nonCommunicatingStackCount,
        )

        return (
          <div style={{ width: 60 }}>
            <TernaryBarChartStrings data={strings} />
          </div>
        )
      },
    },
  ]

  return (
    <Table
      tableId="EnclosureTable"
      data={formattedData}
      columns={columns}
      fixedHeader={fixedHeader}
      pagination={pagination}
      getRowId={getRowId}
      defaultId="id"
    />
  )
}

export default EnclosureTable
