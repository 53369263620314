/* eslint-disable no-console */
import axios from 'axios'
import { handleError } from './helpers'
import ContactorState from 'api/types/ContactorState'
import EnablementState from 'api/types/EnablementState'

// ------------------------------------------------------
// GET Log In User
// ------------------------------------------------------
export const loginQuery = async (username, password, session, clearSession) => {
  let encodedCredential = ''
  return axios
    .get(`${session.server}initSession`, {
      headers: {
        Accept: 'application/json',
      },
      params: {
        logon: username,
        passphrase: password,
      },
    })
    .then(async (response) => {
      if (response.data.twoFA) {
        encodedCredential = await getEncodedCredential(response.data)
      }
      return startSession(
        username,
        password,
        encodedCredential,
        session.server,
        clearSession,
      )
    })
    .catch((error) => handleError(error, clearSession))
}

export const logoutQuery = async (session, clearSession) => {
  return axios
    .get(`${session.server}logout`, {
      headers: {
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
    .catch((error) => handleError(error, clearSession))
}

// ------------------------------------------------------
// GET Start Session (Private)
// ------------------------------------------------------
const startSession = async (
  username,
  password,
  publicKey,
  apiUrl,
  clearSession,
) => {
  return axios
    .get(`${apiUrl}session`, {
      headers: {
        Accept: 'application/json',
      },
      params: {
        logon: username,
        passphrase: password,
        publicKey: publicKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => handleError(error, clearSession))
}

// ------------------------------------------------------
// GET User Data
// ------------------------------------------------------
export const fetchUserAuth = async (session, clearSession) => {
  return axios
    .get(`${session.server}userAuth`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
    .catch((error) => handleError(error, clearSession))
}

// ------------------------------------------------------
// GET All Stations
// ------------------------------------------------------
export const fetchStationList = async (session) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}blocks`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchControllerStatistics = async (session) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}controllers`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchBlockStatus = async (
  stationCode,
  blockIndex,
  session,
  source,
) => {
  const apiUrl = session.server

  return axios
    .get(`${apiUrl}station/${stationCode}/block/${blockIndex}/status`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
      cancelToken: source.token,
    })
    .then((response) => response.data)
}

export const fetchBlockTopology = async (stationCode, blockIndex, session) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}station/${stationCode}/block/${blockIndex}/topology`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchACBatteryStatus = async (
  stationCode,
  blockIndex,
  acBatteryIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/acbattery/${acBatteryIndex}/status`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

export const fetchArrayStatus = async (
  stationCode,
  blockIndex,
  arrayIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/status`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

export const fetchStringList = async (stationCode, blockIndex, session) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}station/${stationCode}/block/${blockIndex}/strings`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchPCSList = async (stationCode, blockIndex, session) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }
  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/arraypcses`,
      axiosOptions,
    )
    .then((response) => response.data)
}

export const fetchPlugInStatus = async (stationCode, blockIndex, session) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }

  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/plugins`,
      axiosOptions,
    )
    .then((response) => response.data)
}

// ------------------------------------------------------
// GET Distribution Chart Data
// ------------------------------------------------------
export const fetchBatteryPackVoltageDist = async (
  stationCode,
  blockIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}vdist/batteryPack/${stationCode}/${blockIndex}/data`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchCellGroupVoltageDist = async (
  stationCode,
  blockIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}vdist/cellGroup/${stationCode}/${blockIndex}/data`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchStackTemperatureDist = async (
  stationCode,
  blockIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}tdist/stack/${stationCode}/${blockIndex}/data`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchStackVoltageDist = async (
  stationCode,
  blockIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(`${apiUrl}vdist/stack/${stationCode}/${blockIndex}/data`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

export const fetchCellDistribution = async (
  stationCode,
  blockIndex,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/cellDistribution`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

// ------------------------------------------------------
// GET String Details Data
// ------------------------------------------------------
export const fetchStringDetails = async (
  stationCode,
  blockIndex,
  arrayId,
  stringID,
  session,
) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }
  return await axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayId}/string/${stringID}/status`,
      axiosOptions,
    )
    .then((response) => response.data)
}

export const fetchCellData = async (
  stationCode,
  blockIndex,
  arrayId,
  stringID,
  session,
) => {
  const apiUrl = session.server
  return axios
    .get(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayId}/string/${stringID}/cells`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

export const setStringContactors = async (
  stationCode,
  blockIndex,
  stackIds,
  contactorState,
  session,
) => {
  const apiUrl = session.server
  const openOrClosed =
    contactorState === ContactorState.CLOSED ? 'close' : 'open'
  const calls = stackIds.map((stringID) => {
    const [arrayIndex, stringIndex] = stringID.split(':')
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/string/${stringIndex}/contactors/${openOrClosed}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => {
      const anyFailures =
        responses.find((response) => response.data.valid !== true) !== undefined

      if (anyFailures) {
        return Promise.reject(responses)
      }
      return responses
    })
    .catch((e) => console.error(e))
}

export const setStringContactors2 = async (
  stationCode,
  blockIndex,
  stackIds,
  contactorState,
  ignoreHighCellGroupVoltageAlarm,
  ignoreLowCellGroupVoltageAlarm,
  session,
) => {
  const apiUrl = session.server
  const calls = stackIds.map((stringID) => {
    const [arrayIndex, stringIndex] = stringID.split(':')
    const postData = {
      contactorsCloseExpected: contactorState === ContactorState.CLOSED,
      arrayIndex: arrayIndex,
      stringIndex: stringIndex,
      ignoreHighCGVoltAlarm:
        ignoreHighCellGroupVoltageAlarm === EnablementState.ENABLED,
      ignoreLowCGVoltAlarm:
        ignoreLowCellGroupVoltageAlarm === EnablementState.ENABLED,
    }
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/contactors`,
      postData,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => {
      const anyFailures =
        responses.find((response) => response.data.valid !== true) !== undefined

      if (anyFailures) {
        return Promise.reject(responses)
      }
      return responses
    })
    .catch((e) => console.error(e))
}

export const setArrayContactors = async (
  stationCode,
  blockIndex,
  arrayIds,
  contactorState,
  session,
) => {
  const apiUrl = session.server
  const openOrClosed =
    contactorState === ContactorState.CLOSED ? 'close' : 'open'
  const calls = arrayIds.map((arrayId) => {
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayId}/contactors/${openOrClosed}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => {
      const anyFailures =
        responses.find((response) => response.data.valid !== true) !== undefined

      if (anyFailures) {
        return Promise.reject(responses)
      }
      return responses
    })
    .catch((e) => console.error(e))
}

export const setArrayContactors2 = async (
  stationCode,
  blockIndex,
  arrayIds,
  contactorState,
  ignoreHighCellGroupVoltageAlarm,
  ignoreLowCellGroupVoltageAlarm,
  session,
) => {
  const apiUrl = session.server
  const calls = arrayIds.map((arrayId) => {
    const postData = {
      contactorsCloseExpected: contactorState === ContactorState.CLOSED,
      arrayIndex: arrayId,
      ignoreHighCGVoltAlarm:
        ignoreHighCellGroupVoltageAlarm === EnablementState.ENABLED,
      ignoreLowCGVoltAlarm:
        ignoreLowCellGroupVoltageAlarm === EnablementState.ENABLED,
    }
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/contactors`,
      postData,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => {
      const anyFailures =
        responses.find((response) => response.data.valid !== true) !== undefined

      if (anyFailures) {
        return Promise.reject(responses)
      }
      return responses
    })
    .catch((e) => console.error(e))
}

export const setBlockContactors = async (ids, contactorState, session) => {
  try {
    const apiUrl = session.server
    const openOrClosed =
      contactorState === ContactorState.CLOSED ? 'close' : 'open'
    const calls = ids.map((id) => {
      const idParts = id.split(':')
      const stationCode = idParts[0]
      const blockIndex = idParts[1]

      return axios.post(
        `${apiUrl}station/${stationCode}/block/${blockIndex}/contactors/${openOrClosed}`,
        {},
        {
          headers: {
            Accept: 'application/json',
            papitoken: session.token,
          },
        },
      )
    })

    const responses = await axios.all(calls)
    const anyFailures =
      responses.find((response) => response && response.data.valid !== true) !==
      undefined

    if (anyFailures) {
      return {
        status: 'error',
        message: 'setBlockContactors() failed',
        data: responses,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (e) {
    return { status: 'error', message: e.message }
  }
}

export const setBlockContactors2 = async (
  stationCode,
  blockIndex,
  contactorState,
  ignoreHighCellGroupVoltageAlarm,
  ignoreLowCellGroupVoltageAlarm,
  session,
) => {
  const apiUrl = session.server
  const postData = {
    contactorsCloseExpected: contactorState === ContactorState.CLOSED,
    ignoreHighCGVoltAlarm:
      ignoreHighCellGroupVoltageAlarm === EnablementState.ENABLED,
    ignoreLowCGVoltAlarm:
      ignoreLowCellGroupVoltageAlarm === EnablementState.ENABLED,
  }
  axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/contactors`,
      postData,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((responses) => {
      const anyFailures =
        responses.find((response) => response.data.valid !== true) !== undefined

      if (anyFailures) {
        return Promise.reject(responses)
      }
      return responses
    })
    .catch((e) => console.error(e))
}

export const setArrayPcsRotation = async (
  stationCode,
  blockIndex,
  arrayIndex,
  inOrOut,
  excused,
  reason,
  explanation,
  session,
) => {
  const apiUrl = session.server
  return axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/rotation/pcs/${inOrOut}`,
      inOrOut === 'out' ? { excused, reason, explanation } : {},
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => {
      if (response.data.valid !== true) {
        return Promise.reject(response)
      }
      return response
    })
    .catch((e) => console.error(e))
}
export const setPcsRotation = async (
  stationCode,
  blockIndex,
  pcsIds,
  outRotation,
  excused,
  reason,
  explanation,
  session,
) => {
  const apiUrl = session.server
  const calls = pcsIds.map((pcsId) => {
    const [arrayIndex, pcsIndex] = pcsId.split(':')
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/arraypcs/${pcsIndex}/rotation/pcs/${
        outRotation === 'true' ? 'out' : 'in'
      }`,
      outRotation === 'true' ? { excused, reason, explanation } : {},
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => responses.map((response) => response.data))
    .catch((e) => console.error(e))
}

// POST dataapi/v1/station/[stationCode]/block/[blockIndex]/array/[arrayIndex]/string/[stringIndex]/rotation/string/out
export const setRotation = async (
  stationCode,
  blockIndex,
  stackIds,
  session,
  rotationForm,
) => {
  const apiUrl = session.server
  const inOrOut = rotationForm.outRotation === 'true' ? 'out' : 'in'
  const calls = stackIds.map((stringID) => {
    const [arrayIndex, stackIndex] = stringID.split(':')
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/string/${stackIndex}/rotation/string/${inOrOut}`,
      {
        excused: rotationForm.excused,
        reason: rotationForm.reason,
        explanation: rotationForm.explanation,
      },
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => responses.map((response) => response.data))
    .catch((e) => console.error(e))
}

// POST dataapi/v1/station/[stationCode]/block/[blockIndex]/array/[arrayIndex]/rotation/string/out
export const setArrayRotation = async (
  stationCode,
  blockIndex,
  arrayIds,
  session,
  rotationForm,
) => {
  const apiUrl = session.server
  const inOrOut = rotationForm.outRotation === 'true' ? 'out' : 'in'
  const calls = arrayIds.map((arrayIndex) => {
    return axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/array/${arrayIndex}/rotation/string/${inOrOut}`,
      {
        excused: rotationForm.excused,
        reason: rotationForm.reason,
        explanation: rotationForm.explanation,
      },
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
  })
  return axios
    .all(calls)
    .then((responses) => responses.map((response) => response.data))
    .catch((e) => console.error(e))
}
export const setPreferredCurrent = async (
  stationCode,
  blockIndex,
  arrayIndexes,
  preferredCurrentForm,
  session,
) => {
  const requestBody = {
    arrayIndexes,
    chargeManagerEnabled: preferredCurrentForm.chargeManagerEnabled,
    dischargeManagerEnabled: preferredCurrentForm.dischargeManagerEnabled,
  }

  const apiUrl = session.server
  return axios
    .post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/preferredcurrentmanager`,
      requestBody,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => {
      if (response.data.valid !== true) {
        return Promise.reject(response)
      }
      return response
    })
    .catch((e) => console.error(e))
}

// ------------------------------------------------------
// GET supersnap xlsx
// ------------------------------------------------------

function provideDownload(filename, data) {
  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', data)
  linkElement.setAttribute('download', filename)
  document.body.appendChild(linkElement)
  linkElement.click()
  linkElement.remove()
}

function provideBlobDownload(filename, data) {
  provideDownload(filename, window.URL.createObjectURL(new Blob([data])))
}

export const downloadSuperSnap = async (stationCode, blockIndex, session) => {
  const apiUrl = session.server
  const timeStamp = new Date().toISOString()
  const fileName = `supersnap_${stationCode}_block_${blockIndex}_${timeStamp}.xlsx`
  return axios
    .get(`${apiUrl}station/${stationCode}/block/${blockIndex}/supersnap.xlsx`, {
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        papitoken: session.token,
      },
      responseType: 'blob', // important
    })
    .then((res) => {
      provideBlobDownload(fileName, res.data)
    })
    .catch((e) => console.error(e))
}

/**
 *
 * @param {'rocorsuperusers' | 'rocusers' | 'sueprusers'} type
 * @returns
 */
export const fetchRocUsers = async (session, type) => {
  if (!type) throw Error('fetchRocUsers requires type param')
  const apiUrl = session.server
  return await axios
    .get(`${apiUrl}alerts/${type}`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
    .catch((error) => console.warning('fetchRocUsers error', error))
}

export const fetchFilteredAlerts = async (session, filters) => {
  const apiUrl = session.server
  return axios
    .post(`${apiUrl}alerts/filter`, filters, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data.bessStatusAlerts)
}

/**
 * PUT call to update a user preference by key will replace existing value
 * @param {object} session
 * @param {string} key identifier
 * @param {string} payload a JSON.stringify() -> {value: JSON string} // req value key
 * @returns {object}  {valid: true}
 */
export const updateUserPreferences = async (session, key, payload) => {
  const apiUrl = session.server
  if (typeof payload?.value !== 'string')
    throw new Error(
      'payload must be an object of { value: "any string type"} key must be "value"',
    )

  return axios
    .put(`${apiUrl}userprefs/key/${key}`, payload, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

/**
 * When user wants to save their filters
 * @param {object} session
 * @param {{filterName: string , filters: object }} payload {filterName: 'name' , filters: filter object }
 * @returns
 */
export const createNewCustomFilter = async (session, payload) => {
  const apiUrl = session.server
  return axios
    .post(`${apiUrl}alerts/filter/custom`, payload, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
}

/**
 * When user wants to save their filters
 * @param {object} session
 * @param {{filterName: string , filters: object }} payload {filterName: 'name' , filters: filter object }
 * @param {boolean} oldAccess
 * @returns
 */
export const editNewCustomFilter = async (
  session,
  payload,
  filterName,
  oldAccess,
) => {
  const apiUrl = session.server
  return axios
    .put(
      `${apiUrl}alerts/filter/custom/${filterName}?publicFilter=${oldAccess}`,
      payload,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

/**
 * When user wants to delete a custom filter
 * @param {object} session
 * @param {{filter: string}} payload {filter: 'name'}
 * @param {boolean} filterAccess
 * @returns
 */
export const deleteCustomFilter = async (session, filterName, filterAccess) => {
  const apiUrl = session.server
  return axios
    .delete(
      `${apiUrl}alerts/filter/custom/${filterName}?publicFilter=${filterAccess}`,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
}

// 2FA helpers

const getEncodedCredential = async function (initSessionResult) {
  // Construct init object
  let encodedCredString = ''
  const credentialRequestOptions = {
    publicKey: initSessionResult.options,
  }

  // Clean up init object
  credentialRequestOptions.publicKey.allowCredentials.forEach(
    function (listItem) {
      listItem.id = bufferDecode(listItem.id)
      delete listItem.transports
    },
  )

  credentialRequestOptions.publicKey.challenge = bufferDecode(
    credentialRequestOptions.publicKey.challenge.value,
  )

  await navigator.credentials
    .get(credentialRequestOptions)
    .then(function (publicKeyCredential) {
      encodedCredString = encodeCredential(publicKeyCredential)
    })
    .catch((e) => console.error(e))
  return encodedCredString
}

const encodeCredential = function (publicKeyCredential) {
  try {
    const encodedCred = {
      id: publicKeyCredential.id,
      rawId: bufferEncode(publicKeyCredential.rawId),
      type: publicKeyCredential.type,
      authenticatorData: bufferEncode(
        publicKeyCredential.response.authenticatorData,
      ),
      clientDataJSON: bufferEncode(publicKeyCredential.response.clientDataJSON),
      signature: bufferEncode(publicKeyCredential.response.signature),
      userHandle: bufferEncode(publicKeyCredential.response.userHandle),
    }
    const credString = JSON.stringify(encodedCred)
    const encodedCredString = bufferEncodeString(credString)
    return encodedCredString
  } catch (error) {
    // perhaps log internally in the future
  }
  return ''
}

//Base64 to ArrayBuffer
const bufferDecode = function (value) {
  return Uint8Array.from(atob(value), (c) => c.charCodeAt(0))
}

//ArrayBuffer to URLBase64
const bufferEncode = function (value) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

//String to URLBase64
const bufferEncodeString = function (value) {
  return btoa(value).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

/**
 * When user wants to Execute Remote Command from Admin Commands
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} payload {systemType, systemIndex, dir, command, timeout}
 * @returns
 */

export const executeRemoteCommand = async (
  session,
  station,
  blockIndex,
  payload,
) => {
  const apiUrl = session.server

  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/execremote`,
      payload,
      {
        headers: {
          Accept: 'application/json',

          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)

  return response
}

/**
 * When user wants to Execute Remote Command from Admin Commands
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {string} systemType
 * @param {integer} systemIndex
 * @param {string} path
 * @returns
 */
export const fileGrabCommand = async (
  session,
  station,
  blockIndex,
  systemType,
  systemIndex,
  fullFileName,
) => {
  const apiUrl = session.server
  const payload = {
    fullFileName,
    systemType,
    index: systemIndex,
  }

  const response = await axios.post(
    `${apiUrl}station/${station}/block/${blockIndex}/filesystem/download`,
    payload,
    {
      headers: {
        Accept: 'application/json',

        papitoken: session.token,
      },
    },
  )

  return response.data
}

/**
 * Fetch the correct System Indexes for a certain Station and System Type
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @returns
 */

export const fetchSystemIndexes = async (session, station, blockIndex) => {
  const apiUrl = session.server

  const response = await axios
    .get(`${apiUrl}station/${station}/block/${blockIndex}/controllertopology`, {
      headers: {
        Accept: 'application/json',

        papitoken: session.token,
      },
    })
    .then((response) => response.data)

  return response
}

/**
 * When user wants to use the Firmware Deploy Command from Admin Commands
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} payload {firmwareType, major, minor, revision}
 * @returns
 */

export const firmwareDeployCommand = async (
  session,
  station,
  blockIndex,
  payload,
) => {
  const apiUrl = session.server

  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/firmware/deploy`,
      payload,
      {
        headers: {
          Accept: 'application/json',

          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)

  return response
}

/**
 * When user wants to use the Firmware Trigger Command from Admin Commands
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} payload {firmwareType, major, minor, revision}
 * @returns
 */

export const firmwareTriggerCommand = async (
  session,
  station,
  blockIndex,
  payload,
) => {
  const apiUrl = session.server

  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/firmware/trigger`,
      payload,
      {
        headers: {
          Accept: 'application/json',

          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)

  return response
}

/**
 * When user wants to use the Firmware Upload Command from Admin Commands
 * @param {object} session
 * @param {FormData} payload command {firmwareType, major, minor, revision}, file
 * @returns
 */

export const firmwareUploadCommand = async (session, payload) => {
  const apiUrl = session.server

  const command = new Blob([payload.command], { type: 'application/json' })

  const form = new FormData()
  form.append('command', command, 'command.json')
  form.append('file', payload.file)

  const response = await axios
    .post(`${apiUrl}firmware/upload`, form, {
      headers: {
        Accept: '*/*',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)

  return response
}

/**
 * When user wants to use the Reset Command from Admin Commands
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} payload
 * @returns
 */

export const resetCommand = async (session, station, blockIndex, payload) => {
  const apiUrl = session.server

  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/resetcommand`,
      payload,
      {
        headers: {
          Accept: 'application/json',

          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)

  return response
}

/**
 * Fetch EKS PPC data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @returns
 */
export const fetchEksPpcDetails = async (session, station, blockIndex) => {
  const apiUrl = session.server
  const response = await axios
    .get(`${apiUrl}station/${station}/block/${blockIndex}/ppc/status`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PPC setpoints data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} data
 * @returns
 */
export const postEksPpcSetpoints = async (
  session,
  station,
  blockIndex,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/ppc/control/setpoints`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PPC control mode data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} data
 * @returns
 */
export const postEksPpcControlMode = async (
  session,
  station,
  blockIndex,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/ppc/control/mode`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PPC control mode settings data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {object} data
 * @returns
 */
export const postEksPpcControlModeSettings = async (
  session,
  station,
  blockIndex,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/ppc/control/settings`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PCS Group control mode settings data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {integer} eksPcsGroupId
 * @param {object} data
 * @returns
 */
export const postEksPcsGroupControlModeSettings = async (
  session,
  station,
  blockIndex,
  eksPcsGroupId,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/pcsgroups/${eksPcsGroupId}/control/mode`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PCS Group setpoint settings data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {integer} eksPcsGroupId
 * @param {object} data
 * @returns
 */
export const postEksPcsGroupSetpointSettings = async (
  session,
  station,
  blockIndex,
  eksPcsGroupId,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/pcsgroups/${eksPcsGroupId}/control/settings`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Post EKS PCS Modules setpoint settings data for a specific station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @param {integer} eksPcsGroupId
 * @param {object} data
 * @returns
 */
export const postEksPcsModulesSetpointSettings = async (
  session,
  station,
  blockIndex,
  eksPcsGroupId,
  data,
) => {
  const apiUrl = session.server
  const response = await axios
    .post(
      `${apiUrl}station/${station}/block/${blockIndex}/pcsgroups/${eksPcsGroupId}/control/pcsmodules/settings`,
      data,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

// ------------------------------------------------------
// GET All possible Alert Names for Alert Management
// ------------------------------------------------------
export const fetchAllAlertNames = async (session) => {
  const apiUrl = session.server
  const response = await axios
    .get(`${apiUrl}alerts/name/count`, {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    })
    .then((response) => response.data)

  return response.bessStatusAlertsCounts
}

/**
 * Get all DC:DC groups in a station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @returns
 */
export const getDcDcGroupList = async (session, station, blockIndex) => {
  const apiUrl = session.server
  const response = await axios
    .get(
      `${apiUrl}station/${station}/block/${blockIndex}/dcdcgroups`,

      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Get all PV PCSes in a station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @returns
 */
export const getPvPcsList = async (session, station, blockIndex) => {
  const apiUrl = session.server
  const response = await axios
    .get(
      `${apiUrl}station/${station}/block/${blockIndex}/pvpcses`,

      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )
    .then((response) => response.data)
  return response
}

/**
 * Get all DC:DC PCSes in a station / block
 * @param {object} session
 * @param {string} station
 * @param {integer} blockIndex
 * @returns
 */
export const getDcDcPCSesList = async (session, station, blockIndex) => {
  const apiUrl = session.server
  const axiosOptions = {
    headers: {
      Accept: 'application/json',
      papitoken: session.token,
    },
  }
  const response = await axios.get(
    `${apiUrl}station/${station}/block/${blockIndex}/dcdcpcses`,
    axiosOptions,
  )
  return response.data
}
