import { BALANCE_STATUSES } from './BALANCING_STATUSES'
import { BALANCE_LEVELS } from './BALANCING_LEVELS'

const DISCHARGE_BALANCER_MIN_VOLTAGE_DEFAULT = 3400
const CHARGE_BALANCER_MAX_VOLTAGE_DEFAULT = 3100

export const processBalancingHeadings = (userInputForm, resultHeading) => {
  if (userInputForm.balancingCmd == BALANCE_STATUSES.NO_BALANCE) {
    return <td>{resultHeading[0]}</td>
  }
  const procHeading = resultHeading.map((h) => {
    return <td key={h}>{h}</td>
  })
  if (userInputForm.balancingCmd == BALANCE_STATUSES.BALANCE_TO_PROVIDED)
    procHeading.push(<td key={'milli'}>MILLIVOLTS</td>)
  return procHeading
}

export const isImproperRange = (row, userInputForm, bmsParameters = null) => {
  let isImproper = false
  if (!row || !isFormValid(userInputForm)) return isImproper

  let target

  const dischargeBalancerMinVoltage = validOrDefault(
    bmsParameters?.dischargeBalancerMinVoltage,
    DISCHARGE_BALANCER_MIN_VOLTAGE_DEFAULT,
  )
  const chargeBalancerMaxVoltage = validOrDefault(
    bmsParameters?.chargeBalancerMaxVoltage,
    CHARGE_BALANCER_MAX_VOLTAGE_DEFAULT,
  )

  const { cellMinVoltage, cellMaxVoltage, cellAvgVoltage } = row
  const {
    balancingCmd,
    chargeDeadband,
    dischargeDeadband,
    providedMillivolts,
  } = userInputForm

  switch (balancingCmd) {
    case BALANCE_STATUSES.BALANCE_TO_AVERAGE:
      target = cellAvgVoltage
      break
    case BALANCE_STATUSES.BALANCE_TO_PROVIDED:
      target = Number(providedMillivolts)
      break
    case BALANCE_STATUSES.NO_BALANCE:
      return isImproper
  }

  isImproper =
    (target + Number(dischargeDeadband) < cellMaxVoltage &&
      cellMaxVoltage < dischargeBalancerMinVoltage) ||
    (target - Number(chargeDeadband) > cellMinVoltage &&
      cellMinVoltage > chargeBalancerMaxVoltage)
  return isImproper
}

const stopBalancingCheck = (formData) => {
  return (
    (formData.balancingCmd == BALANCE_STATUSES.NO_BALANCE &&
      formData.balanceLevel == BALANCE_LEVELS.STRING) ||
    (formData.balancingCmd == BALANCE_STATUSES.NO_BALANCE &&
      formData.balanceLevel == BALANCE_LEVELS.PACK &&
      formData.batteryPack != '0')
  )
}

const isInChooseState = (formData) => {
  return (
    (formData.balanceLevel == BALANCE_LEVELS.PACK &&
      formData.batteryPack === '0') ||
    formData.balancingCmd == 'choose'
  )
}

const isDeadbandInValid = (formData) => {
  return (
    testForNegativeOrEmpty(formData.chargeDeadband) ||
    testForNegativeOrEmpty(formData.dischargeDeadband) ||
    outOfBoundsDeadband(formData.chargeDeadband) ||
    outOfBoundsDeadband(formData.dischargeDeadband)
  )
}

const isTargetMillivoltInvalid = (formData) => {
  return (
    testForNegativeOrEmpty(formData.providedMillivolts) ||
    outOfBoundsTarget(formData.providedMillivolts)
  )
}

export const isFormValid = (formData) => {
  let valid = false
  if (isInChooseState(formData)) {
    return false
  }
  if (stopBalancingCheck(formData)) {
    return true
  }

  if (formData['balancingCmd'] === BALANCE_STATUSES.BALANCE_TO_AVERAGE) {
    valid = !isDeadbandInValid(formData)
  } else if (
    formData['balancingCmd'] === BALANCE_STATUSES.BALANCE_TO_PROVIDED
  ) {
    valid = !isDeadbandInValid(formData) && !isTargetMillivoltInvalid(formData)
  } else {
    valid = true
  }
  return valid
}

export const testForNegativeOrEmpty = (value) => {
  if (/^[0-9]*$/.test(value) && value !== '' && value > 0) return false
  return true
}

export const modalTitles = {
  start: 'Set Balancing',
  finish: 'Balancing State',
}

const validOrDefault = (value, defaultVal) => {
  return value === null || value === undefined || parseInt(value) === 0
    ? defaultVal
    : parseInt(value)
}

const outOfBoundsDeadband = (val) => Number(val) > 500
const outOfBoundsTarget = (val) => Number(val) > 4000
