export function kilowatt_to_megawatt(kW) {
  return kW / 1e3
}

export function megawatt_to_kilowatt(MW) {
  return MW * 1e3
}

export function volt_to_kilovolt(v) {
  return v / 1e3
}

export function kilovolt_to_volt(kV) {
  return kV * 1e3
}

export function kilovar_to_megavar(kVAr) {
  return kVAr / 1e3
}

export function megavar_to_kilovar(MVAr) {
  return MVAr * 1e3
}
