import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Component Imports
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import CentipedeLineupDetailsMainColumn from './MainColumn/CentipedeLineupDetailsMainColumn'
import CentipedeLineupDetailsSummary from './Summary/CentipedeLineupDetailsSummary'

// API Imports
import useCentipedeLineupDetailsQuery from 'api/useQueryHooks/useCentipedeLineupDetailsQuery'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'

const CentipedeLineupDetails = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, centipedeId } = params
  useEffect(() => {
    setSelectedParams({ ...params, listView: 'centipede-lineup-details' })
    document.title = `${stationCode}::${blockIndex} - Centipede Lineup ${centipedeId}`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DetailsPageJsx = (data) => {
    return (
      <DetailsPage
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
        setSelectedPage={setSelectedPage}
        summaryColumnComponent={<CentipedeLineupDetailsSummary data={data} />}
        mainColumnComponent={<CentipedeLineupDetailsMainColumn />}
      />
    )
  }
  return handleQueryLoadingAndError(
    useCentipedeLineupDetailsQuery(),
    DetailsPageJsx,
  )
}

export default CentipedeLineupDetails
