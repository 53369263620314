import AppTooltip from 'components/AppTooltip/AppTooltip'
import { uniqueId } from 'lodash'
// Style Imports
import * as Styles from './styles'

/**
 * use this for 3 part bar charts showing tripped sensors
 * @param {{number, number, number}} sensor total, untripped, nonCommunicating
 * @returns jsx ternary bar chart
 */
const TernaryBarChartSensorsTripped = (sensor) => {
  const tooltipId = 'onlineTooltip' + uniqueId()
  const { total, untripped, noncommunicating } = sensor
  const tripped = total - untripped
  return (
    <Styles.Container data-tooltip-id={tooltipId}>
      <Styles.GreenBar $percent={untripped / total} />
      <Styles.RedBar $percent={tripped / total} />
      <Styles.GreyBar $percent={noncommunicating / total} />
      <AppTooltip id={tooltipId}>
        Untripped: {untripped} | Tripped: {tripped} | NonCommunicating:{' '}
        {noncommunicating}
      </AppTooltip>
    </Styles.Container>
  )
}

export default TernaryBarChartSensorsTripped
