import { useParams } from 'react-router-dom'

import { ReactComponent as EnclosureIcon } from 'icons/enclosure-icon.svg'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import HealthStatus from 'components/Status/HealthStatus/HealthStatus'

const Summary = () => {
  const { containerId } = useParams()

  const summaryMap = {
    title: `Enclosure ${containerId}`,
    titleIcon: <EnclosureIcon />,
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={false} />,
            isIcon: true,
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default Summary
