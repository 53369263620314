import { uniqueId } from 'lodash'
import Table from 'components/Table/Table/Table'
// import * as Styles from '../tableStyles'
import { ReactComponent as HealthGoodIcon } from 'icons/health-good-icon.svg'
import { ReactComponent as StatusDisconnected } from 'icons/status-disconnected-icon.svg'
import {
  HealthStatusIconWithToolTips,
  checkNullRoundValue,
  getRoleFriendlyName,
} from '../tableUtils'
import { COLORS } from 'design_system/colors'
import AppTooltip from 'components/AppTooltip/AppTooltip'
import * as Styles from '../table.styled'

const BlockMeterTable = ({
  data,
  fixedHeader = true,
  blockIndex,
  stationCode,
}) => {
  const getRowId = (row) => Number(row.blockMeterIndex)

  const idCell = (row) => (
    <Styles.RowLink
      to={`/block-details/${stationCode}/${blockIndex}/block-topology/block-meter-details/${row.blockMeterIndex}`}
    >
      {row.blockMeterIndex}
    </Styles.RowLink>
  )

  const checkDisconnected = (row) => {
    if (row.isCommunicating) return <div />
    const id = uniqueId(row.blockMeterIndex)
    return (
      <div data-tooltip-id={id}>
        <StatusDisconnected
          style={{
            fill: COLORS.powin_red,
            width: 21,
            height: 21,
            marginTop: 3,
          }}
        />
        <AppTooltip id={id}>Not Communicating</AppTooltip>
      </div>
    )
  }

  const columns = [
    {
      name: 'ID',
      id: 'id',
      selector: getRowId,
      sortable: true,
      width: '40px',
      noOmit: true,
      cell: idCell,
    },
    {
      name: (
        <div style={{ fill: '#abaeb1', width: 12, height: 12 }}>
          <HealthGoodIcon />
        </div>
      ),
      id: 'healthy',
      selector: (row) => row.isHealthy,
      sortable: true,
      width: '50px',
      noOmit: true,
      cell: (row) => HealthStatusIconWithToolTips(row.isHealthy),
    },
    {
      name: (
        <div style={{ fill: '#abaeb1' }}>
          <StatusDisconnected style={{ width: 20, height: 20, marginTop: 3 }} />
        </div>
      ),
      id: 'communicating',
      selector: (row) => row.isCommunicating,
      sortable: true,
      width: '45px',
      cell: checkDisconnected,
      noOmit: true,
    },
    {
      name: 'REAL POWER',
      id: 'realPower',
      selector: (row) => row.realPowerKW,
      sortable: true,
      width: '100px',
      cell: (row) => checkNullRoundValue(row.realPowerKW, ' kW', 1),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'REACTIVE POWER',
      id: 'reactivePower',
      selector: (row) => row.reactivePowerKVAR,
      sortable: true,
      width: '120px',
      cell: (row) => checkNullRoundValue(row.reactivePowerKVAR, ' kVAr', 1),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'VOLTAGE LN',
      id: 'voltageLn',
      selector: (row) => row.voltageVoltLN,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.voltageVoltLN, ' V', 2),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'VOLTAGE LL',
      id: 'voltageLl',
      selector: (row) => row.voltageVoltLL,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.voltageVoltLL, ' V', 2),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'CURRENT',
      id: 'current',
      selector: (row) => row.currentAmp,
      sortable: true,
      width: '90px',
      cell: (row) => checkNullRoundValue(row.currentAmp, ' A', 2),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'POWER FACTOR',
      id: 'powerFactor',
      selector: (row) => row.powerFactor,
      sortable: true,
      width: '120px',
      cell: (row) => checkNullRoundValue(row.powerFactor, '', 3),
      noOmit: false,
      omit: false,
      right: true,
    },
    {
      name: 'TYPE',
      id: 'type',
      selector: (row) => row.type,
      sortable: true,
      width: '90px',
      cell: (row) => row.type,
      noOmit: false,
      omit: false,
    },
    {
      name: 'ROLE',
      id: 'role',
      selector: (row) => row.role,
      sortable: true,
      width: '120px',
      cell: (row) => getRoleFriendlyName(row.role),
      noOmit: false,
      omit: false,
    },
  ]
  return (
    <Table
      tableId="BlockMeterTable"
      defaultId="id"
      data={data ?? []}
      columns={columns}
      getRowId={getRowId}
      fixedHeader={fixedHeader}
      pagination
    />
  )
}

export default BlockMeterTable
