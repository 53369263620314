import { fetchCellDistribution } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

const useCellDistributionQuery = () => {
  const { stationCode, blockIndex } = useParams()
  const { session, setSession } = useContext(SessionContext)
  return useQuery(
    ['cellDistribution', stationCode, blockIndex],
    () => fetchCellDistribution(stationCode, blockIndex, session, setSession),
    { refetchInterval: 5000 },
  )
}

export default useCellDistributionQuery
