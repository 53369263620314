import CommandModalTemplate from '../../CommandModalTemplate'
import { BALANCE_LEVELS } from '../BALANCING_LEVELS'
import { BalancingForm, defaultBalanceFormValues } from '../BalancingForm'
import { modalTitles } from '../balancingUtils'
import { ACBatteryBalancingResults } from './ACBatteryBalancingResults'

// step 3 command query
const queryName = 'setACBatteryBalancing'
const label = 'AC BATTERIES'
// define followup modal custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <ACBatteryBalancingResults ids={ids} userInputForm={userInputForm} />
)

// define custom input form returns jsx
const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  BalancingForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    label,
    originator: BALANCE_LEVELS.ARRAY,
  })

// SetCommandModal keeps track of input form and each modal step, sending command, close modal
export const SetACBatteryBalancingModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultBalanceFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated={true}
    />
  )
}
