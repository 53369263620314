import { ReactComponent as CentipedeLineupIcon } from 'icons/centipede-lineup.svg'
import { ReactComponent as EnvControllerIcon } from 'icons/env-controller.svg'
import { ReactComponent as HVACIcon } from 'icons/hvac-icon.svg'
import { ReactComponent as UPSsIcon } from 'icons/ups-icon.svg'
import { ReactComponent as EnergySegmentsIcon } from 'icons/centipede-energy-segment.svg'
import { ReactComponent as CollectionSegmentsIcon } from 'icons/centipede-collection-segment.svg'
import { ReactComponent as EnclosuresIcon } from 'icons/enclosure-icon.svg'
import { ReactComponent as SensorIcon } from 'icons/sensor-icon.svg'
import { ReactComponent as StackManagerIcon } from 'icons/string-manager.svg'
import { getPageLinkFromTypeNameAndParams } from '../../Tables/tableUtils'

export function getSystemEnvironmentalDevices(params, environmentalDevices) {
  const buildDeviceOption = (device) => {
    switch (device.name) {
      case 'Enclosures':
        return {
          id: 'allEnclosures',
          label: 'Enclosures',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <EnclosuresIcon />,
          omit: !device.total,
        }
      case 'Centipede Lineups':
        return {
          id: 'centipede-lineups',
          label: 'Centipede Lineups',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <CentipedeLineupIcon />,
          omit: !device.total,
        }
      case 'Stack Managers':
        return {
          id: 'stack-managers-list',
          label: 'Stack Managers',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <StackManagerIcon />,
          omit: !device.total,
        }
      case 'Environmental Controllers':
        return {
          id: 'environmental-controllers',
          label: 'Environmental Controllers',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <EnvControllerIcon />,
          omit: !device.total,
        }
      case 'Collection Segments':
        return {
          id: 'collection-segments',
          label: 'Collection Segments',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <CollectionSegmentsIcon />,
          omit: !device.total,
        }
      case 'Energy Segments':
        return {
          id: 'centipede-energy-segments',
          label: 'Energy Segments',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <EnergySegmentsIcon />,
          omit: !device.total,
        }
      case 'HVACs':
        return {
          id: 'hvac-list',
          label: 'HVACs',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <HVACIcon />,
          omit: !device.total,
        }
      case 'UPSes':
        return {
          id: 'ups-list',
          label: 'UPSes',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <UPSsIcon />,
          omit: !device.total,
        }
      case 'Gas Sensors':
      case 'Fire Safety Sensors':
      case 'Door Sensors':
        return {
          id: 'sensors',
          label: 'Sensors',
          url: getPageLinkFromTypeNameAndParams(device.name, params),
          icon: <SensorIcon />,
          omit: !device.total,
        }
      default:
        return {
          id: '',
          label: '',
          url: '',
          icon: null,
          omit: true,
        }
    }
  }

  const devices = environmentalDevices
    ? environmentalDevices
        .map(buildDeviceOption)
        .reduce((accDevices, device) => {
          const exist = accDevices.some(
            (accDevice) => accDevice.id === device.id,
          )
          if (!device.omit && !exist) {
            accDevices.push(device)
          }
          return accDevices
        }, [])
    : []

  return devices
}
