import styled from 'styled-components/macro'

export const Label = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 2px;
`
export const Sublabel = styled.span`
  font-size: 11px;
  font-weight: 400;
  color: #909090;
  margin-right: 5px;
`
export const Timestamp = styled.span`
  font-size: 11px;
  color: #909090;
`
