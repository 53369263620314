import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { CommandResultsTemplate } from '../CommandResultsTemplate'

import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg'

const dcDcPcsDidUpdate = (dcDcPcsList, commandForm, id) => {
  const [acPvBatteryIndex, dcDcGroupIndex, dcDcPcsIndex] = id.split(':')
  return dcDcPcsList?.dcDcPcses?.some((pcs) =>
    Boolean(
      pcs.acPvBatteryIndex === +acPvBatteryIndex &&
        pcs.dcDcGroupIndex === +dcDcGroupIndex &&
        pcs.dcDcPcsIndex === +dcDcPcsIndex &&
        pcs.outRotation === (commandForm.outRotation === 'true'),
    ),
  )
}

const heading = 'IN ROTATION'

const ResultTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.outRotation === 'false' ? (
        <Icon size="xxs" color={COLORS.powin_green} icon={<CheckIcon />} />
      ) : (
        <Icon size="xxs" color={COLORS.powin_red} icon={<ErrorIcon />} />
      )}
    </>
  )
}

export const DcDcPcsRotationResults = ({ ids, userInputForm }) => {
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={dcDcPcsDidUpdate}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName="dcDcPcsesRotation"
    />
  )
}
