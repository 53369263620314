import { FC } from 'react'
import { CommandResultsTemplate } from '../../CommandResultsTemplate'
import { AppStatusResultProps } from './result'
import { ResultTd } from './Result-td'

export const AppStatusResult: FC<AppStatusResultProps> = (props) => {
  const { ids, userInputForm } = props
  const pendingQueryName = 'setAppStatus'
  const heading = 'Set App Status'

  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={(
        appList: any,
        form: { enabled: string },
        id: string,
      ): boolean => {
        let ret = false
        for (const key in appList) {
          if (
            `${appList[key].appCode}:${appList[key].appPriority}` === id &&
            appList[key].enabled === (form.enabled === 'true')
          ) {
            ret = true
          }
        }
        return ret
      }}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
