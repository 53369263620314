import { useState, useContext } from 'react'
import * as Styles from './ResetCommand.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import {
  FormInput,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { COLORS } from 'design_system/colors'
import useStationsDataQuery from 'api/useQueryHooks/useStationDataQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { SessionContext } from 'contexts/session'
import { resetCommand, fetchSystemIndexes } from 'api/queries.api'
import { ToastMessage } from 'components/Toasts/ToastMessages'

export const ResetCommand = () => {
  const { session } = useContext(SessionContext)
  const { data, isLoading } = useStationsDataQuery()

  const [selectedStation, setSelectedStation] = useState(['Select One'])
  const [selectedResetCommandType, setSelectedResetCommandType] =
    useState('Select One')
  const [enabledFormFields, setEnabledFormFields] = useState('')
  const [selectedSystemType, setSelectedSystemType] = useState('')
  const [availableSystemTypes, setAvailableSystemTypes] = useState([])
  const [apartSetup, setApartSetup] = useState(false)
  const [availableSystemIndexes, setAvailableSystemIndexes] = useState([])
  const [systemTypeIndexes, setSystemTypeIndexes] = useState([])
  const [selectedSystemIndex, setSelectedSystemIndex] = useState('')
  const [availableArrayStringIndexes, setAvailableArrayStringIndexes] =
    useState([])
  const [selectedArrayIndex, setSelectedArrayIndex] = useState()
  const [selectedStringIndex, setSelectedStringIndex] = useState()
  const [isUploading, setIsUploading] = useState(false)

  if (isLoading) return <LoadingPage />

  const resetCommandTypes = [
    'Reboot System',
    'Tomcat Restart',
    'Clear Logs',
    'SoCs to 0',
    'SoCs to 95',
    'SoCs to 100',
    'SC PCB',
    'BPC PCB',
  ]

  const isFormValidHandler = () => {
    switch (enabledFormFields) {
      case '':
        if (
          selectedStation[0] != 'Select One' &&
          selectedResetCommandType !== 'Select One' &&
          !isUploading
        ) {
          return true
        }
        return false
      case 'typeAndIndex':
        if (
          selectedStation[0] != 'Select One' &&
          selectedResetCommandType !== 'Select One' &&
          selectedSystemType !== 'Select One' &&
          selectedSystemIndex !== 'Select One' &&
          !isUploading
        ) {
          return true
        }
        return false
      case 'arrayAndString':
        if (
          selectedStation[0] != 'Select One' &&
          selectedResetCommandType !== 'Select One' &&
          selectedArrayIndex &&
          selectedStringIndex &&
          !isUploading
        ) {
          return true
        }
        return false
    }
  }

  const isFormValid = isFormValidHandler()

  const isSysIndexRequired =
    selectedStation[0] !== 'Select One' &&
    selectedSystemType !== 'Select One' &&
    selectedSystemType !== 'Dragon' &&
    selectedSystemType !== ''

  const treatCommandTypeName = (type) => {
    switch (type) {
      case 'Reboot System':
        return 'REBOOT'
      case 'Tomcat Restart':
        return 'RESTART'
      case 'Clear Logs':
        return 'CLEARLOGS'
      case 'SoCs to 0':
        return 'SOC0'
      case 'SoCs to 95':
        return 'SOC95'
      case 'SoCs to 100':
        return 'SOC100'
      case 'SC PCB':
        return 'SC'
      case 'BPC PCB':
        return 'BPC'
    }
  }

  const fetchIndexesHandler = async (station = null, blockIndex = null) => {
    const response = await fetchSystemIndexes(
      session,
      station ?? selectedStation[1],
      blockIndex ?? selectedStation[2],
    )
    if (
      response.localControllerIndexes.length === 0 ||
      response.environmentalControllerIndexes.length === 0
    ) {
      setApartSetup(false)
      setSelectedSystemIndex('')
      setSelectedSystemType('Dragon')
      return
    }
    setApartSetup(true)
    setSelectedSystemIndex('')
    setAvailableSystemIndexes(response)
    setAvailableSystemTypes(['Dragon', 'Feather', 'Phoenix'])
    setSelectedSystemType('Select One')
  }

  const setSelectedStationHandler = (
    station,
    blockIndex,
    valid,
    arraysCount,
    stringsCount,
  ) => {
    if (!valid) return

    if (availableArrayStringIndexes.length === 2) {
      setSelectedArrayIndex()
      setSelectedStringIndex()
    }

    let arrayIndexes = []
    let stringIndexes = []

    for (let i = 0; i < arraysCount; i++) {
      arrayIndexes.push(i + 1)
    }

    for (let i = 0; i < stringsCount / arraysCount; i++) {
      stringIndexes.push(i + 1)
    }

    setAvailableArrayStringIndexes([arrayIndexes, stringIndexes])

    fetchIndexesHandler(station, blockIndex)

    setSelectedStation([station + ' :: ' + blockIndex, station, blockIndex])
  }

  const setSelectedResetCommandTypeHandler = (
    type,
    station = null,
    blockIndex = null,
  ) => {
    if (
      type === 'Reboot System' ||
      type === 'Tomcat Restart' ||
      type === 'Clear Logs'
    ) {
      setEnabledFormFields('typeAndIndex')
      if (selectedStation[0] !== 'Select One' && selectedSystemType === '')
        fetchIndexesHandler(station, blockIndex)
    } else if (type === 'SC PCB' || type === 'BPC PCB') {
      setEnabledFormFields('arrayAndString')
    } else setEnabledFormFields('')
    setSelectedResetCommandType(type)
  }

  const setSelectedSystemTypeHandler = (type) => {
    setSelectedSystemType(type)
    if (type === 'Dragon') {
      setSelectedSystemIndex('')
      return
    }
    if (type === 'Feather')
      setSystemTypeIndexes(
        availableSystemIndexes.environmentalControllerIndexes,
      )
    if (type === 'Phoenix')
      setSystemTypeIndexes(availableSystemIndexes.localControllerIndexes)
    setSelectedSystemIndex('Select One')
  }

  const setSelectedArrayIndexHandler = (index) => {
    setSelectedArrayIndex(index)
  }

  const setSelectedStringIndexHandler = (index) => {
    setSelectedStringIndex(index)
  }

  const onSubmitHandler = (e) => {
    setIsUploading(true)
    const arrayIndexValue =
      enabledFormFields === 'typeAndIndex' || enabledFormFields === ''
        ? 0
        : selectedArrayIndex
    const stringIndexValue =
      enabledFormFields === 'typeAndIndex' || enabledFormFields === ''
        ? 0
        : selectedStringIndex
    const systemIndexValue =
      enabledFormFields === 'arrayAndString' ||
      enabledFormFields === '' ||
      selectedSystemType === 'Dragon'
        ? 0
        : selectedSystemIndex
    const systemTypeValue =
      selectedSystemType === '' || selectedSystemType === 'Select One'
        ? 'DRAGON'
        : selectedSystemType

    e.preventDefault()
    const payload = {
      commandType: treatCommandTypeName(selectedResetCommandType),
      arrayIndex: arrayIndexValue,
      stringIndex: stringIndexValue,
      systemType: systemTypeValue,
      index: systemIndexValue,
    }

    toastMessageHandler('RESET COMMAND IN PROGRESS', '', 'progress')

    const executeCommand = async () => {
      const response = await resetCommand(
        session,
        selectedStation[1],
        selectedStation[2],
        payload,
      )

      if (response.valid) {
        setIsUploading(false)
        return toastMessageHandler(
          'RESET COMMAND COMPLETE',
          selectedStation[1] +
            ':' +
            selectedStation[2] +
            ' - ' +
            selectedResetCommandType,
          'complete',
          'toastInfo',
        )
      }
      setIsUploading(false)
      return toastMessageHandler(
        'RESET COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }
    executeCommand()

    setTimeout(() => {
      setIsUploading(false)
      toastMessageHandler(
        'RESET COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }, 45000)
  }

  const toastMessageHandler = (title, body, type, toastId) => {
    return ToastMessage(title, body, type, toastId)
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        Reset Command
      </Typography>
      <Styles.FormRow>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            STATION :: BLOCK INDEX
          </Typography>
          <ToolbarDropdown
            title={selectedStation[0]}
            width="260px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
            overflow="scroll"
            maxHeight="447px"
          >
            {data?.blocks.map((x) => (
              <Styles.StationDropItem
                $backgroundColor={
                  x.stationCode + ' :: ' + x.blockIndex === selectedStation[0]
                }
                $isConnected={x.valid}
                key={x.stationCode}
                onClick={() =>
                  setSelectedStationHandler(
                    x.stationCode,
                    x.blockIndex,
                    x.valid,
                    x.arrayCount,
                    x.stringCount,
                  )
                }
                onKeyDown={null}
              >
                {x.stationCode + ' :: ' + x.blockIndex}
                {!x.valid && ' - Disconnected'}
              </Styles.StationDropItem>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            RESET COMMAND
          </Typography>
          <ToolbarDropdown
            title={selectedResetCommandType}
            width="260px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
          >
            {resetCommandTypes.map((type) => (
              <option
                style={{
                  cursor: 'pointer',
                  padding: '4px',
                  color: 'black',
                  backgroundColor:
                    type === selectedResetCommandType ? COLORS.light_grey : '',
                }}
                key={type}
                onClick={() =>
                  selectedStation[0] !== 'Select One'
                    ? setSelectedResetCommandTypeHandler(type)
                    : setSelectedResetCommandTypeHandler(
                        type,
                        selectedStation[1],
                        selectedStation[2],
                      )
                }
                onKeyDown={null}
              >
                {type}
              </option>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        {enabledFormFields === 'typeAndIndex' && (
          <>
            <Styles.FormColumn $width="260px" $marginBottom="10px">
              <Typography
                $fontSize={FONT_SIZES.medium}
                $fontColor={COLORS.font_secondary}
                $margin="12px 0"
              >
                SYSTEM TYPE
              </Typography>
              {(selectedSystemType === 'Dragon' || selectedSystemType === '') &&
                !apartSetup && (
                  <FormInput
                    id="systemType"
                    disabled={true}
                    $isDisabled={true}
                    defaultValue={selectedSystemType}
                  />
                )}
              {selectedSystemType !== '' && apartSetup && (
                <ToolbarDropdown
                  title={selectedSystemType}
                  width="260px"
                  handleClose={null}
                  backgroundColor="white"
                  textColor="black"
                  fontSize="12px"
                  height="32px"
                  border="1px solid #ccc"
                  borderRadius="4px"
                >
                  {availableSystemTypes.map((type) => (
                    <option
                      style={{
                        cursor: 'pointer',
                        padding: '4px',
                        color: 'black',
                        backgroundColor:
                          type === selectedSystemType ? COLORS.light_grey : '',
                      }}
                      key={type}
                      onClick={() => setSelectedSystemTypeHandler(type)}
                      onKeyDown={null}
                    >
                      {type}
                    </option>
                  ))}
                </ToolbarDropdown>
              )}
            </Styles.FormColumn>
            <Styles.FormColumn $width="260px" $marginBottom="10px">
              <Typography
                $fontSize={FONT_SIZES.medium}
                $fontColor={COLORS.font_secondary}
                $margin="12px 0"
              >
                SYSTEM INDEX
              </Typography>
              {!isSysIndexRequired ? (
                <FormInput
                  id="systemIndex"
                  disabled={true}
                  $isDisabled={true}
                  defaultValue={null}
                />
              ) : (
                <ToolbarDropdown
                  title={selectedSystemIndex}
                  width="260px"
                  handleClose={null}
                  backgroundColor="white"
                  textColor="black"
                  fontSize="12px"
                  height="32px"
                  border="1px solid #ccc"
                  borderRadius="4px"
                  overflow="auto"
                  maxHeight="447px"
                >
                  {systemTypeIndexes?.map((index) => (
                    <option
                      style={{
                        cursor: 'pointer',
                        padding: '4px',
                        color: 'black',
                        backgroundColor:
                          index === selectedSystemIndex
                            ? COLORS.light_grey
                            : '',
                      }}
                      key={index}
                      onClick={() => setSelectedSystemIndex(index)}
                      onKeyDown={null}
                    >
                      {index}
                    </option>
                  ))}
                </ToolbarDropdown>
              )}
            </Styles.FormColumn>
          </>
        )}
      </Styles.FormRow>
      <Styles.FormRow>
        {enabledFormFields === 'arrayAndString' && (
          <>
            <Styles.FormColumn
              $width="260px"
              $marginTop="-15px"
              $marginBottom="25px"
            >
              <Typography
                $fontSize={FONT_SIZES.medium}
                $fontColor={COLORS.font_secondary}
                $margin="12px 0"
              >
                ARRAY INDEX
              </Typography>
              {selectedStation[0] === 'Select One' ? (
                <FormInput
                  id="systemIndex"
                  disabled={true}
                  $isDisabled={true}
                  defaultValue={null}
                />
              ) : (
                <ToolbarDropdown
                  title={selectedArrayIndex ?? 'Select One'}
                  width="260px"
                  handleClose={null}
                  backgroundColor="white"
                  textColor="black"
                  fontSize="12px"
                  height="32px"
                  border="1px solid #ccc"
                  borderRadius="4px"
                  overflow="auto"
                  maxHeight="300px"
                >
                  {availableArrayStringIndexes[0].map((index) => (
                    <div
                      key={index}
                      style={{
                        height: '25px',
                      }}
                    >
                      <option
                        style={{
                          cursor: 'pointer',
                          padding: '4px',
                          color: 'black',
                          backgroundColor:
                            index === selectedArrayIndex
                              ? COLORS.light_grey
                              : '',
                        }}
                        key={index}
                        onClick={() => setSelectedArrayIndexHandler(index)}
                        onKeyDown={null}
                      >
                        {index}
                      </option>
                    </div>
                  ))}
                </ToolbarDropdown>
              )}
            </Styles.FormColumn>
            <Styles.FormColumn
              $width="260px"
              $marginTop="-15px"
              $marginBottom="25px"
            >
              <Typography
                $fontSize={FONT_SIZES.medium}
                $fontColor={COLORS.font_secondary}
                $margin="12px 0"
              >
                STRING INDEX
              </Typography>
              {selectedStation[0] === 'Select One' ? (
                <FormInput
                  id="systemIndex"
                  disabled={true}
                  $isDisabled={true}
                  defaultValue={null}
                />
              ) : (
                <ToolbarDropdown
                  title={selectedStringIndex ?? 'Select One'}
                  width="260px"
                  handleClose={null}
                  backgroundColor="white"
                  textColor="black"
                  fontSize="12px"
                  height="32px"
                  border="1px solid #ccc"
                  borderRadius="4px"
                  overflow="auto"
                  maxHeight="300px"
                >
                  {availableArrayStringIndexes[1].map((index) => (
                    <div
                      key={index}
                      style={{
                        height: '25px',
                      }}
                    >
                      <option
                        style={{
                          cursor: 'pointer',
                          padding: '4px',
                          color: 'black',
                          backgroundColor:
                            index === selectedStringIndex
                              ? COLORS.light_grey
                              : '',
                        }}
                        key={index}
                        onClick={() => setSelectedStringIndexHandler(index)}
                        onKeyDown={null}
                      >
                        {index}
                      </option>
                    </div>
                  ))}
                </ToolbarDropdown>
              )}
            </Styles.FormColumn>
          </>
        )}
      </Styles.FormRow>
      <FormSubmitButton
        $width="140px"
        $marginTop={enabledFormFields === '' ? '-5px' : ''}
        $isValid={isFormValid}
        disabled={!isFormValid}
        onClick={(e) => {
          onSubmitHandler(e)
        }}
      >
        SUBMIT
      </FormSubmitButton>
    </Styles.Container>
  )
}
