import Icon from 'components/Icon/Icon'
import { COLORS } from 'design_system/colors'
import { ReactComponent as ContactorClosedIcon } from 'icons/contactor-closed-icon.svg'
import { ReactComponent as ContactorOpenIcon } from 'icons/contactor-open-icon.svg'
import { CommandResultsTemplate } from '../CommandResultsTemplate'
import ContactorState from 'api/types/ContactorState'

// Determine if a contactor operation is pending based on user input and contactor state.
const isContactorOperationComplete = (string, userInputForm) => {
  const expectedClosed = userInputForm.contactorState === ContactorState.CLOSED
  const bothClosed =
    string.negativeContactorClosed && string.positiveContactorClosed
  const bothOpen =
    !string.negativeContactorClosed && !string.positiveContactorClosed
  return (expectedClosed && bothClosed) || (!expectedClosed && bothOpen)
}

const contactorsHeadings = 'CONTACTOR STATE'

const contactorTd = ({ userInputForm }) => {
  return (
    <>
      {userInputForm.contactorState === ContactorState.CLOSED ? (
        <Icon
          size="xs"
          color={COLORS.powin_green}
          icon={<ContactorClosedIcon />}
        />
      ) : (
        <Icon
          size="xs"
          color={COLORS.powin_green}
          icon={<ContactorOpenIcon />}
        />
      )}
    </>
  )
}

const pendingQueryName = 'stringDetails'

export const ContactorsFormResults = ({ ids, rotationForm }) => {
  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={rotationForm}
      pendingEvaluation={isContactorOperationComplete}
      resultHeading={contactorsHeadings}
      ResultsTd={contactorTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
