import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import CollectionSegmentsSummary from './Summary/CollectionSegmentsSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import CollectionSegmentsTable from 'modules/Station/components/Tables/CollectionSegmentsTable/CollectionSegmentsTable'
import useCollectionSegmentsQuery from 'api/useQueryHooks/useCollectionSegmentsQuery'

const CollectionSegments = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'collection-segments' })
    document.title = `${stationCode}::${blockIndex} - Collection Segments`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<CollectionSegmentsSummary data={data} />}
      tableComponent={
        <CollectionSegmentsTable
          data={data.elementList}
          fixedHeader={true}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
      className="collectionSgmt"
    />
  )

  return handleQueryLoadingAndError(useCollectionSegmentsQuery(), ListPageJsx)
}

export default CollectionSegments
