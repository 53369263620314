// Style Imports
import * as Styles from './styles'

//Icon Imports
import { ReactComponent as LoadingIcon } from 'icons/loading-icon.svg'
/**
 * Loading indicator with spinning icon
 * @param {number} [size = 130] px added for you
 * @returns any
 */
const LoadingPage = ({ size = 130 }) => {
  return (
    <Styles.Container>
      <Styles.Background>
        <Styles.IconContainer size={size}>
          <LoadingIcon />
        </Styles.IconContainer>
        <Styles.Text>Loading...</Styles.Text>
      </Styles.Background>
    </Styles.Container>
  )
}

export default LoadingPage
