import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  align-items: center;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #909090;
  fill: #909090;
  font-size: 11px;
  padding: 10px 0;

  &:not(:last-child):after {
    content: '::';
    margin-left: 10px;
  }

  &:last-child {
    fill: #788b95;
    color: #22242a;
    font-weight: 500;

    & > a {
      cursor: initial;
      text-decoration: none;
      pointer-events: none;
    }

    & > div {
      width: 17px;
      height: 17px;
    }

    :after {
      display: none;
    }
  }
`
export const Breadcrumb = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
`
export const Icon = styled.div`
  width: 17px;
  height: 17px;
  margin-right: 5px;
`
