import axios from 'axios'

export const setDcDcPcsRotationService = async (
  stationCode,
  blockIndex,
  ids,
  rotationInfo,
  session,
) => {
  try {
    const apiUrl = session.server
    const command =
      rotationInfo.outRotation === 'true' ? 'OUT_ROTATION' : 'IN_ROTATION'
    const mappedIds = ids.map((id) => {
      const splittedIds = id.split(':')
      return {
        acPvBatteryIndex: splittedIds[0],
        dcDcGroupIndex: splittedIds[1],
        dcDcPcsIndex: splittedIds[2],
      }
    })

    const payload =
      command === 'IN_ROTATION'
        ? {
            action: command,
            keys: mappedIds,
          }
        : {
            action: command,
            keys: mappedIds,
            excused: rotationInfo.excused === 'true' ? true : false,
            reason: rotationInfo.reason,
            explanation: rotationInfo.explanation,
          }

    const response = await axios.post(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/dcdcpcses/rotation`,
      payload,
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )

    if (!response.data.valid) {
      return {
        status: 'error',
        message: 'DC:DC PCs rotation failed.',
        data: response,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (error) {
    return { status: 'error', message: error.message }
  }
}
