// Style Imports
import * as Styles from './styles'

const Icon = ({
  icon,
  color = '#788B95',
  size = 'md',
  isCrossed = false,
  crossedColor = '',
}) => (
  <Styles.Container
    size={size}
    color={color}
    $crossedColor={crossedColor}
    $crossedOpacity={isCrossed ? '1' : '0'}
  >
    {icon}
  </Styles.Container>
)

export default Icon
