import { useState, useContext } from 'react'
import * as Styles from './styles'
import { useParams } from 'react-router-dom'

// Component Imports
import { FormCommandButton } from 'components/Form/FormComponents.styled.js'
import VoltageHeatmap from 'modules/Station/components/Charts/VoltageHeatmap/VoltageHeatMap'
import TemperatureHeatmap from 'modules/Station/components/Charts/TemperatureHeatmap/TemperatureHeatmap'
import { SetRotationModal } from 'modules/Station/components/Commands/SetRotation/SetRotationModal'
import Icon from 'components/Icon/Icon'
import Card from 'components/Cards/Card/Card'
import { SetContactorsModal } from 'modules/Station/components/Commands/SetContactors/SetContactorsModal'
import ExpandableCard from 'components/Cards/ExpandableCard/ExpandableCard'
import PackBalancingTable from 'modules/Station/components/Tables/PackBalancingTable/PackBalancingTable'
import NotificationsAndEventsTable from 'modules/Station/components/Tables/NotificationsAndEventsTable/NotificationsAndEventsTable'

// Icon Imports
import { ReactComponent as CheckIcon } from 'icons/check-icon.svg'
import { ReactComponent as ContactorsIcon } from 'icons/contactor-open-icon.svg'
import { ReactComponent as BalancingIcon } from 'icons/balancing-icon.svg'

// Theme Imports
import { COLORS } from 'design_system/colors'
import SetStringBalancingModal from 'modules/Station/components/Commands/Balancing/StringBalancing/SetStringBalancingModal'
import { useIsStationConnected } from 'modules/Station/components/Tables/tableUtils'
import useCellDetailsQuery from 'api/useQueryHooks/useCellDetailsQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import { AuthorizationContext } from 'contexts/authorizations.context'
import { API_ACCESS_CODES } from 'constants/API_ACCESS_CODES'
import styled from 'styled-components/macro'
import StringNotificationHeatmap from 'modules/Station/components/Charts/StringNotificationChart/StringNotificationHeatmap'
import { FEATURE_TOGGLES } from 'constants/FEATURE_TOGGLES'

const StringDetailsMainColumn = () => {
  const { stationCode, blockIndex, arrayId, stringID } = useParams()
  const [contactorsModalOpen, setContactorsModalOpen] = useState(false)
  const [rotationModalOpen, setRotationModalOpen] = useState(false)
  const [balanceModalOpen, setBalanceModalOpen] = useState(false)
  const { data, isLoading } = useCellDetailsQuery()
  const isStationConnected = useIsStationConnected(stationCode, blockIndex)
  const { userHasApiAccessCode, hasEnabledFlags } =
    useContext(AuthorizationContext)
  const userHas7SC = userHasApiAccessCode(API_ACCESS_CODES.SEVEN_SC)

  if (isLoading) return <LoadingPage />

  const isBalancingCellIdEnabled = hasEnabledFlags(
    FEATURE_TOGGLES.CELL_GROUP_NOTIFICATION_EVENT,
  )

  return (
    <Styles.Container>
      {isStationConnected && userHas7SC && (
        <>
          <FormRow $isRow={true}>
            <FormCommandButton onClick={() => setContactorsModalOpen(true)}>
              <Icon
                size="sm"
                color={COLORS.action_blue}
                icon={<ContactorsIcon />}
              />
              Set Contactors
            </FormCommandButton>
            {contactorsModalOpen && (
              <SetContactorsModal
                setShowCommandModal={setContactorsModalOpen}
                ids={[`${arrayId}:${stringID}`]}
              />
            )}

            <FormCommandButton onClick={() => setRotationModalOpen(true)}>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<CheckIcon />}
              />
              Set Rotation
            </FormCommandButton>
            {rotationModalOpen && (
              <SetRotationModal
                setShowCommandModal={setRotationModalOpen}
                ids={[`${arrayId}:${stringID}`]}
              />
            )}

            <FormCommandButton onClick={() => setBalanceModalOpen(true)}>
              <Icon
                size="xxs"
                color={COLORS.action_blue}
                icon={<BalancingIcon />}
              />
              Set Balancing
            </FormCommandButton>
            {balanceModalOpen && (
              <SetStringBalancingModal
                setShowCommandModal={setBalanceModalOpen}
                ids={[`${arrayId}:${stringID}`]}
                data={data}
              />
            )}
          </FormRow>
          <ExpandableCard
            title="Battery Pack Balancing"
            isExpanded={data && data.balanceMode !== 'NO_BALANCE'}
            minimal={true}
          >
            <PackBalancingTable data={data} />
          </ExpandableCard>
          {isBalancingCellIdEnabled ? (
            <ExpandableCard title="CELL GROUP MAPS" minimal={true}>
              <FormRow>
                <SimpleCard>
                  <Title>Voltage</Title>
                  <VoltageHeatmap data={data} />
                </SimpleCard>
                <SimpleCard>
                  <Title>Temperature</Title>
                  <TemperatureHeatmap data={data} />
                </SimpleCard>
                <SimpleCard>
                  <Title>Notifications</Title>
                  <StringNotificationHeatmap data={data} />
                </SimpleCard>
              </FormRow>
            </ExpandableCard>
          ) : (
            <>
              <Card title="Voltage">
                <VoltageHeatmap data={data} />
              </Card>
              <Card title="Temperature">
                <TemperatureHeatmap data={data} />
              </Card>
            </>
          )}
          {isBalancingCellIdEnabled && (
            <ExpandableCard
              title="Notifications & Events"
              isExpanded={true}
              minimal={true}
            >
              <NotificationsAndEventsTable data={data} />
            </ExpandableCard>
          )}
        </>
      )}
    </Styles.Container>
  )
}
export default StringDetailsMainColumn

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  gap: 4px;
`

const SimpleCard = styled.div``

export const Title = styled.div`
  padding-left: 4rem;
  flex: 1;
  font-size: 12px;
  font-weight: 800;
  color: #322242;
`
