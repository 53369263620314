import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import ACPVBatterySummary from './Summary/ACPVBatteryListSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useACPVBatteriesQuery from 'api/useQueryHooks/useACPVBatteriesQuery'
import ACPVBatteriesTable from 'modules/Station/components/Tables/ACPVBatteriesTable/ACPVBatteriesTable'

const ACPVBatteryList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'ac-pv-batteries-list' })
    document.title = `${stationCode}::${blockIndex} - AC PV Batteries`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<ACPVBatterySummary data={data.acPvBatteries} />}
      tableComponent={
        <ACPVBatteriesTable
          data={data.acPvBatteries}
          blockIndex={blockIndex}
          stationCode={stationCode}
        />
      }
    />
  )

  return handleQueryLoadingAndError(useACPVBatteriesQuery(), ListPageJsx)
}

export default ACPVBatteryList
