import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import CentipedeLineupsSummary from './Summary/CentipedeLineupsSummary'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import CentipedeLineupsTable from 'modules/Station/components/Tables/CentipedeLineupsTable/CentipedeLineupsTable'
import useCentipedeLineupsQuery from 'api/useQueryHooks/useCentipedeLineupsQuery'

const CentipedeLineups = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'centipede-lineups' })
    document.title = `${stationCode}::${blockIndex} - Centipede Lineups`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => {
    return (
      <ListPage
        selectedParams={selectedParams}
        summaryColumnComponent={<CentipedeLineupsSummary data={data} />}
        tableComponent={
          <CentipedeLineupsTable
            data={data}
            fixedHeader={true}
            blockIndex={blockIndex}
            stationCode={stationCode}
          />
        }
        className="centLineups"
      />
    )
  }

  return handleQueryLoadingAndError(useCentipedeLineupsQuery(), ListPageJsx)
}

export default CentipedeLineups
