import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
`
export const Column = styled.div`
  flex: 1;
  margin-left: 25px;
`
export const Row = styled.div`
  display: flex;
`
export const Flex = styled.div`
  flex: 1;
`
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #282929;
  line-height: 1;
  text-transform: uppercase;
  margin: 3px 0 0;
`
export const DetailLink = styled(Link)`
  margin-bottom: 5px;
  font-size: 12px;
`
export const HealthIcon = styled.div`
  height: 15px;
  width: 15px;
`
export const Content = styled.div`
  margin-left: -10px;
`
export const SectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  margin: 0 5px 0 10px;
  border-bottom: 1px solid #e4e4e4;

  &:last-child {
    border-bottom: none;
  }
`
export const Label = styled.div`
  font-size: 12px;
  color: #909090;
  font-weight: 500;
`
export const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #282929;
`
