import { useEffect, useState } from 'react'
import _ from 'lodash'

// Component Imports
import Summary from './Summary'
import AnalysisToolbar from '../../components/Toolbars/AnalysisToolbar/AnalysisToolbar'
import MainColumn from './MainColumn/MainColumn'

// Util Imports
import { formatAnalysisData } from 'utils/analysis.utils'

// API Imports
import AnalysisPage from '../../components/Layouts/AnalysisPage'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import { useParams } from 'react-router-dom'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'
import useCellDistributionQuery from 'api/useQueryHooks/useCellDistributionQuery'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'

const StationAnalysis = ({ setSelectedPage }) => {
  const params = useParams()
  const [viewType, setViewType] = useState('array')
  const [minArray, setMinArray] = useState(1)

  const { data: cellData, isLoading: isCellLoading } =
    useCellDistributionQuery()
  const { data: blockData, isLoading } = useBlockStatus()

  let _maxArray = 0
  if (!isLoading) {
    const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(
      blockData?.isDcCoupled,
    )
    const blockPowerAndEnergyData = blockData[blockPowerAndEnergyKey]
    _maxArray = blockPowerAndEnergyData?.arrayCount
  }
  const [maxArray, setMaxArray] = useState(_maxArray)

  const [filters, setFilters] = useState([
    {
      label: 'Connected',
      id: 'connected',
      filterBy: (row) => !row.connected,
      omit: false,
      category: 'connection',
    },
    {
      label: 'Disconnected',
      id: 'disconnected',
      filterBy: (row) => row.connected,
      omit: false,
      category: 'connection',
    },
  ])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = `${params.stationCode}::${params.blockIndex} - Analysis`
  }, [params])

  useEffect(() => {
    setFilteredData([])
    const distributions = _.get(cellData, 'stringCellDistributions')
    const distributionData = formatAnalysisData(
      distributions,
      viewType,
      minArray,
      maxArray,
      filters,
    )
    setFilteredData(distributionData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellData, viewType, minArray, maxArray, filters])

  if (isLoading || isCellLoading) return <LoadingPage />

  return (
    <AnalysisPage
      summaryColumnComponent={
        <Summary data={filteredData} minArray={minArray} maxArray={maxArray} />
      }
      toolbarComponent={
        <AnalysisToolbar
          data={{ ...blockData, cellData: cellData }}
          viewType={viewType}
          setViewType={setViewType}
          setMinArray={setMinArray}
          setMaxArray={setMaxArray}
          filters={filters}
          setFilters={setFilters}
        />
      }
      mainColumnComponent={
        <MainColumn data={filteredData} viewType={viewType} />
      }
    />
  )
}

export default StationAnalysis
