/**
 * use this for most axios calls
 *      example
 *      const {apiUrl, axiosHeader} = getAxiosBasicUrlAndHeader(session)
 *      return await axios.put(`${apiUrl}alerts/mute`, jsonBody, axiosHeader).then(res=>res.data)
 * @param {*} session
 * @returns { axiosHeader, apiUrl}
 */
const getAxiosBasicUrlAndHeader = (session) => {
  const axiosHeader = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      papitoken: session.token,
    },
  }

  const apiUrl = session.server

  return { axiosHeader, apiUrl }
}

export default getAxiosBasicUrlAndHeader
