import ModalV2 from 'components/Modals/ModalV2/ModalV2'
import { COLORS } from 'design_system/colors'
import { FONT_SIZES } from 'design_system/typography'
import styled from 'styled-components/macro'

export const Container = styled.div`
  ${ModalV2} {
    & > dialog {
      width: 600px !important;
      max-width: 600px;
    }
  }
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const InputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const Unit = styled.span`
  font-size: ${FONT_SIZES.normal};
`

export const Input = styled.input`
  flex-basis: 75%;
  padding: 0 0.5rem;
  // prettier-ignore
  &[aria-invalid="true"] {
    border-color: red;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const ErrorText = styled.span`
  flex: 1;
  color: ${COLORS.powin_red};
  font-size: ${FONT_SIZES.normal};
`
