import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
`

export const OptionWrapper = styled.div`
  cursor: pointer;
`
