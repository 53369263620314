import axios from 'axios'
import { PLUGIN_LIST } from 'modules/Station/components/PlugIns/PLUGIN_LIST'

const setPlugInStatus = async ({
  stationCode,
  blockIndex,
  session,
  ids,
  userInputForm,
}) => {
  try {
    const apiUrl = session.server
    const enableOrDisable = userInputForm.enabled === 'true' ? 'true' : 'false'

    let plugin
    switch (ids[0]) {
      case PLUGIN_LIST.BALANCER_MANAGER.id:
        plugin = PLUGIN_LIST.BALANCER_MANAGER.key
        break
      case PLUGIN_LIST.AUTO_BALANCER.id:
        plugin = PLUGIN_LIST.AUTO_BALANCER.key
        break
      case PLUGIN_LIST.AUTO_CONTACTORMANAGEMENT.id:
        plugin = PLUGIN_LIST.AUTO_CONTACTORMANAGEMENT.key
        break
      default:
        return { status: 'error', message: 'Unrecognized Plug-In' }
    }

    const response = await axios.put(
      `${apiUrl}station/${stationCode}/block/${blockIndex}/plugins/${plugin}`,
      {
        pluginEnabled: enableOrDisable,
      },
      {
        headers: {
          Accept: 'application/json',
          papitoken: session.token,
        },
      },
    )

    const failure = response && response.data.valid !== true

    if (failure) {
      // eslint-disable-next-line no-console
      console.log('setPlugInStatusService() failed', response)
      return {
        status: 'error',
        message: 'setPlugInStatusService() failed',
        data: response,
      }
    }

    return {
      status: 'success',
      message: 'Command sent successfully',
    }
  } catch (error) {
    return { status: 'error', message: error.message }
  }
}

export default setPlugInStatus
