import styled from 'styled-components/macro'

// Component Imports
import { ToolbarLabel } from 'components/Toolbar'

const OnelineToggle = ({ view, toggleView }) => {
  return (
    <Container>
      <ToolbarLabel>View: </ToolbarLabel>
      <Button $isSelected={!view} onClick={() => toggleView(!view)}>
        Tabular
      </Button>
      <Button $isSelected={view} onClick={() => toggleView(!view)}>
        Oneline
      </Button>
    </Container>
  )
}

export default OnelineToggle

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
const Button = styled.div`
  cursor: ${(props) => (props.$isSelected ? 'default' : 'pointer')};
  text-align: center;
  flex: 1;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.$isSelected ? '#00bf91' : 'transparent'};
  font-weight: ${(props) => (props.$isSelected ? 500 : 400)};
  color: ${(props) => (props.$isSelected ? '#fff' : '#282929')};
`
