import styled from 'styled-components/macro'

export const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
`
