import { useEffect, useState } from 'react'

import * as Styles from './AuditLog.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import { COLORS } from 'design_system/colors'
import { AuditLogTable } from './AuditLogTable/AuditLogTable'
import useAdminCommandAuditLog from 'api/useQueryHooks/useAdminCommandAuditLog'
import { DatePicker } from 'components/DatePicker/DatePicker'
import * as dayjs from 'dayjs'

const today = dayjs()

export const AuditLog = ({ setSelectedCommandPage }) => {
  const [selectedDate, setSelectedDate] = useState(today)
  const [tablePage, setTablePage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { data, isFetching } = useAdminCommandAuditLog(
    selectedDate?.format('YYYY-MM-DD'),
    tablePage,
    rowsPerPage,
  )

  useEffect(() => setSelectedCommandPage('Audit Log', []))

  function handleDateChange(date) {
    setSelectedDate(date)
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        Audit Log
      </Typography>
      <Styles.DatePickerWrapper>
        <Typography
          $fontSize={FONT_SIZES.small}
          $fontColor={COLORS.font_secondary}
        >
          DATE
        </Typography>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          disabledDate={(date) => date?.isAfter(new Date(), 'day')}
        />
      </Styles.DatePickerWrapper>
      <Styles.TableContainer>
        <AuditLogTable
          data={data}
          onChangePage={(page) => setTablePage(page - 1)}
          onChangeRowsPerPage={(newPerPageRows) =>
            setRowsPerPage(newPerPageRows)
          }
          isLoading={isFetching}
        />
      </Styles.TableContainer>
    </Styles.Container>
  )
}
