import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  flex: 1;
  height: 148px;
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const IconContainer = styled.div`
  fill: #00bf91;
  width: ${(props) => props.size + 'px'};
  height: ${(props) => props.size + 'px'};
`

export const Text = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #333;
`
