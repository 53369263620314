import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import boost from 'highcharts/modules/boost'

// Component Imports
import BottomDrawer from '../../../../../components/Modals/BottomDrawer/BottomDrawer'

// Util Imports
import { renderPlotlines } from '../../../../../utils/charts.utils'
import { buildYAxis } from './utils'

highchartsMore(Highcharts)
boost(Highcharts)

const StringTempDistribution = ({ data, viewType }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [temperatureData, setTemperatureData] = useState([])

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setTemperatureData(data)
    }
  }, [data])

  useEffect(() => {
    const getTemperatureSeries = () => ({
      type: 'boxplot',
      name: 'Temperature',
      turboThreshold: 10000000,
      data: formatTempData(temperatureData),
      point: {
        events: {
          click: function () {
            handleToggleModal(this.options)
          },
        },
      },
      tooltip: {
        pointFormatter: function () {
          return `<span style="color: ${this.color}">\u25CF</span> <b>${
            this.series.name
          }</span></b>
            <br />
            Maximum: ${_.round(this.high, 2).toLocaleString()}° C
            <br />
            Upper quartile: ${_.round(this.q3, 2).toLocaleString()}° C
            <br />
            Median: ${_.round(this.median, 2).toLocaleString()}° C
            <br />
            Lower quartile: ${_.round(this.q1, 2).toLocaleString()}° C
            <br />
            Minimum: ${_.round(this.low, 2).toLocaleString()} ° C
            `
        },
      },
    })

    const getMinMaxOperationTemperatures = () =>
      temperatureData?.reduce((prev, curr) => {
        const minMaxTemperatures = [...prev]
        if (
          minMaxTemperatures[0] === undefined ||
          minMaxTemperatures[1] === undefined
        ) {
          return [curr.cellTemperature000, curr.cellTemperature100]
        }

        if (curr.cellTemperature000 < minMaxTemperatures[0])
          minMaxTemperatures[0] = curr.cellTemperature000
        if (curr.cellTemperature100 > minMaxTemperatures[1])
          minMaxTemperatures[1] = curr.cellTemperature100

        return minMaxTemperatures
      }, []) ?? []

    setChartOptions((chartOptions) => ({
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        plotLines: renderPlotlines(temperatureData, viewType),
      },
      yAxis: buildYAxis(...getMinMaxOperationTemperatures()),
      series: [getTemperatureSeries()],
    }))
  }, [temperatureData, viewType])

  const handleToggleModal = (data) => {
    setModalData(data)
    setModalIsOpen(true)
  }

  const formatTempData = (data) => {
    return _.map(data, (d) => {
      return {
        low: d.cellTemperature000,
        q1: d.cellTemperature025,
        median: d.cellTemperatureAverage,
        q3: d.cellTemperature075,
        high: d.cellTemperature100,
        name: d.id,
        fillColor:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
            ? 'red'
            : 'orange',
        color:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
            ? 'red'
            : 'orange',
        lineColor:
          d.connected && d.inRotation
            ? '#00b986'
            : !d.connected
            ? 'red'
            : 'orange',
        boostThreshold: 100,
      }
    })
  }

  const [chartOptions, setChartOptions] = useState({
    accessibility: { enabled: false },
    title: {
      text: undefined,
    },
    boost: {
      enabled: true,
      seriesThreshold: 100,
    },
    chart: {
      zoomType: 'y',
      marginLeft: 70,
      panKey: 'alt',
    },
    plotOptions: {
      boxplot: {
        lineWidth: 1,
        stemWidth: 1,
        whiskerWidth: 0,
      },
    },
    credits: { enabled: false },
    legend: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      crosshair: true,
      title: {
        text: undefined,
        style: {
          textTransform: 'capitalize',
        },
      },
      type: 'category',
      plotLines: renderPlotlines(temperatureData, viewType),
    },

    yAxis: buildYAxis(),
  })

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />

      <BottomDrawer
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        title={`String ${modalData.name}`}
      ></BottomDrawer>
    </Container>
  )
}

export default StringTempDistribution

const Container = styled.div`
  flex: 1;

  & > div {
    height: 100%;
  }
`
