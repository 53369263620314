import React from 'react'

// Style Imports
import * as Styles from './styles'

const TabGroup = ({ children, stationDetailsTabs = false }) => (
  <Styles.Container $stationDetailsTabs={stationDetailsTabs}>
    {children}
  </Styles.Container>
)

export default TabGroup
