import _ from 'lodash'
import { useParams } from 'react-router-dom'

// Component Imports
import EquipmentSummaryCard from '../../../../../components/Cards/EquipmentSummaryCard'

// Icon Imports
import { ReactComponent as ArrayIcon } from '../../../../../icons/array-icon.svg'

const SummaryCardArray = ({ data }) => {
  const { stationCode, blockIndex, acBatteryId } = useParams()

  const onlineAvailable = _.chain(data)
    .get('array.dcPowerAndEnergy.onlineAvailableKWH')
    .round()
    .value()
  const totalCapacity = _.chain(data)
    .get('array.dcPowerAndEnergy.totalCapacityKWH')
    .round()
    .value()
  const dcCharge = _.chain(data)
    .get('array.dcPowerAndEnergy.maxChargePowerKW')
    .round()
    .value()
  const dcDischarge = _.chain(data)
    .get('array.dcPowerAndEnergy.maxDischargePowerKW')
    .round()
    .value()
  const onlineSoc = _.chain(data)
    .get('array.dcPowerAndEnergy.onlineSocPercent')
    .round()
    .value()

  return (
    <EquipmentSummaryCard
      icon={<ArrayIcon />}
      title={`Array ${_.get(data, 'acBatteryIndex')} :: ${_.get(
        data,
        'array.arrayIndex',
      )}`}
      link={`/block-details/${stationCode}/${blockIndex}/block-topology/acBattery/${acBatteryId}/array/${acBatteryId}`}
      isHealthy={_.get(data, 'array.valid')}
      rows={[
        {
          label: 'Online Avail. / Total Capacity DC Energy',
          value: `${onlineAvailable.toLocaleString()} kWh / ${totalCapacity.toLocaleString()} kWh`,
        },
        {
          label: 'Avail. DC Charge / Discharge Power',
          value: `${dcCharge.toLocaleString()} kW / ${dcDischarge.toLocaleString()} kW`,
        },
        {
          label: 'Online SoC',
          value: `${onlineSoc.toLocaleString()}%`,
        },
      ]}
    />
  )
}
export default SummaryCardArray
