import CommandModalTemplate from '../../CommandModalTemplate'
import { BALANCE_LEVELS } from '../BALANCING_LEVELS'
import { BalancingForm, defaultBalanceFormValues } from '../BalancingForm'
import { modalTitles } from '../balancingUtils'
import { ArrayBalancingResults } from './ArrayBalancingResults'

// step 3 command query
const queryName = 'setArrayBalancing'
const label = 'ARRAYS'
// define followup modal custom CommandResultsTemplate.jsx
const commandResultJsx = ({ ids, userInputForm }) => (
  <ArrayBalancingResults ids={ids} userInputForm={userInputForm} />
)

// define custom input form returns jsx
const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) =>
  BalancingForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    label,
    originator: BALANCE_LEVELS.ARRAY,
  })

defaultBalanceFormValues.balanceLevel = BALANCE_LEVELS.ARRAY

export const SetArrayBalancingModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultBalanceFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated={true}
    />
  )
}
