import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ListPage from 'modules/Station/components/Layouts/ListPage/ListPage'
import ACBatteryTable from 'modules/Station/components/Tables/ACBatteryTable/ACBatteryTable'
import ACBatterySummary from './Summary/ACBatterySummary'

const ACBatteryList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params
  useEffect(() => {
    setSelectedParams({ ...params, listView: 'ac-battery-list' })
    document.title = `${stationCode}::${blockIndex} - AC Batteries`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<ACBatterySummary />}
      tableComponent={
        <ACBatteryTable
          fixedHeader={true}
          stationCode={stationCode}
          blockIndex={blockIndex}
        />
      }
    />
  )
}

export default ACBatteryList
