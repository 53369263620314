import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ListPage from '../../../components/Layouts/ListPage/ListPage'
import DcDcPcsSummary from './Summary/DcDcPcsSummary'
import useDcDcPCSesQuery from 'api/useQueryHooks/useDcDcPCSesQuery'
import DcDcPCSesTable from 'modules/Station/components/Tables/DcDcPCSesTable/DcDcPCSesTable'
import useElectricalDevicesQuery from 'api/useQueryHooks/useElectricalDevicesQuery'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const DcDcPcsList = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex } = params

  const { data: dcDcPcsesData, isLoading: isLoadingDcDcPcsesData } =
    useDcDcPCSesQuery()

  const { data: electricalDevicesData, isLoading: isLoadingElectricalDevices } =
    useElectricalDevicesQuery({
      enabled: true,
    })

  useEffect(() => {
    setSelectedParams({ ...params, listView: 'dc-dc-pcs-list' })
    document.title = `${stationCode}::${blockIndex} - DC:DC PCSes`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useMemo(() => {
    if (!dcDcPcsesData) return []

    return dcDcPcsesData.dcDcPcses.map((dcDcPcs) => ({
      ...dcDcPcs,
      faults:
        electricalDevicesData?.dcDcPcses?.faulting?.filter?.(
          (device) =>
            device.acPvBatteryIndex === dcDcPcs.acPvBatteryIndex &&
            device.dcDcPcsIndex === dcDcPcs.dcDcPcsIndex &&
            device.dcDcGroupIndex === dcDcPcs.dcDcGroupIndex,
        ) ?? [],
    }))
  }, [electricalDevicesData, dcDcPcsesData])

  if (isLoadingDcDcPcsesData || isLoadingElectricalDevices)
    return <LoadingPage />

  return (
    <ListPage
      selectedParams={selectedParams}
      summaryColumnComponent={<DcDcPcsSummary data={data} />}
      tableComponent={<DcDcPCSesTable data={data} fixedHeader={true} />}
    />
  )
}

export default DcDcPcsList
