import { FC } from 'react'
import { CommandResultsTemplate } from '../../CommandResultsTemplate'
import { BlockInverterRotationResultProps } from './result'
import { ResultTd } from './Result-td'

export const BlockInverterRotationResult: FC<
  BlockInverterRotationResultProps
> = (props) => {
  const { ids, userInputForm } = props
  const pendingQueryName = 'blockDetails'
  const heading = 'IN ROTATION'

  return (
    <CommandResultsTemplate
      ids={ids}
      userInputForm={userInputForm}
      pendingEvaluation={() => {}}
      resultHeading={heading}
      ResultsTd={ResultTd}
      pendingQueryName={pendingQueryName}
    />
  )
}
