import { ClearEnclosureFaultsModal } from 'modules/Station/components/Commands/ClearEnclosureFaults/ClearEnclosureFaultsModal'

export const actions = {
  SHOW_FAULT_CLEAR_MODAL: 'showFaultClearModal',
}

const actionModalReducer = (_state, action) => {
  switch (action?.type) {
    case actions.SHOW_FAULT_CLEAR_MODAL: {
      action.setShowCommandModal(true)
      return () => (
        <ClearEnclosureFaultsModal
          stationCode={action.stationCode}
          blockIndex={action.blockIndex}
          setShowCommandModal={action.setShowCommandModal}
          enclosureIndex={action.enclosureIndex}
          enclosureType={action.enclosureType ?? null}
          target={action.target}
        />
      )
    }
    default:
      return null
  }
}

export default actionModalReducer
