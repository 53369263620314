import axios from 'axios'
import { SessionContext } from 'contexts/session'
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

/**
 * get put or delete user preferences
 * @param {'alertFilers' | 'savedFilters' | undefined} key ie.  alertFilters which contains things like severity choice
 * add new keys to definition as they are added
 * @returns userPreferences does not refresh
 */
const useUserPreferencesQuery = (key) => {
  const { session } = useContext(SessionContext)
  const apiUrl = session.server
  let url = `${apiUrl}userprefs`
  if (key) {
    url = `${apiUrl}userprefs/key/${key}`
  }

  const serviceCall = async () => {
    const axiosOptions = {
      headers: {
        Accept: 'application/json',
        papitoken: session.token,
      },
    }
    const response = await axios.get(url, axiosOptions)
    return response.data
  }

  return useQuery(['userPreferences', key], serviceCall)
}

export default useUserPreferencesQuery
