import { useEffect } from 'react'

// Style Imports
import * as Styles from './toolbarDropdown.styles'

// Icon Imports
import { ReactComponent as CaretDownIcon } from '../../../icons/caret-down-icon.svg'

// Util Imports
import useDetectClickOut from '../../../hooks/useDetectClickOut.hooks'

const ToolbarDropdown = ({
  title,
  titleValue,
  isCustomFilters = false,
  width = 'initial',
  modalWidth = 'fit-content',
  maxHeight = '500px',
  overflow = 'auto',
  display = 'flex',
  handleClose,
  children,
  backgroundColor = '',
  textColor = '#717b82',
  fontSize,
  height,
  border = '1px solid #aeaeae',
  borderRadius = '5px',
}) => {
  const { show, setShow, nodeRef, triggerRef } = useDetectClickOut(false)

  useEffect(() => {
    setShow(false)
  }, [handleClose, setShow])

  return (
    <Styles.Container $width={width} $backgroundColor={backgroundColor}>
      <Styles.ToggleButton
        ref={triggerRef}
        $border={border}
        $borderRadius={borderRadius}
      >
        <Styles.ButtonLabel
          $textColor={textColor}
          $fontSize={fontSize}
          $height={height}
        >
          {title}
          {titleValue && ': '}
          {titleValue && (
            <Styles.TitleValue
              $isCustomFilters={isCustomFilters}
              title={titleValue}
            >
              {titleValue}
            </Styles.TitleValue>
          )}
        </Styles.ButtonLabel>
        <Styles.CaretIconContainer>
          <CaretDownIcon />
        </Styles.CaretIconContainer>
      </Styles.ToggleButton>

      <Styles.ModalContainer $isOpen={show}>
        <Styles.Background />
        <Styles.Modal ref={nodeRef} $width={modalWidth}>
          <Styles.TitleRow onClick={() => setShow(!show)}>
            <Styles.ButtonLabel
              $textColor={textColor}
              $fontSize={fontSize}
              $height={height}
            >
              {title}
              {titleValue && ': '}
              {titleValue && (
                <Styles.TitleValue $isCustomFilters={isCustomFilters}>
                  {titleValue}
                </Styles.TitleValue>
              )}
            </Styles.ButtonLabel>
            <Styles.CaretIconContainer $isOpen={show}>
              <CaretDownIcon />
            </Styles.CaretIconContainer>
          </Styles.TitleRow>
          <Styles.Content
            $filterName={title}
            $display={display}
            $maxHeight={maxHeight}
            $overflow={overflow}
            $isCustomFilters={isCustomFilters}
          >
            {children}
          </Styles.Content>
        </Styles.Modal>
      </Styles.ModalContainer>
    </Styles.Container>
  )
}

export default ToolbarDropdown
