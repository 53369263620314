import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import useEnergySegmentDetailsQuery from 'api/useQueryHooks/useEnergySegmentDetailsQuery'
import DetailsPage from 'modules/Station/components/Layouts/DetailsPage/DetailsPage'
import CentipedeEnergySegmentDetailsSummary from './Summary/CentipedeEnergySegmentDetailsSummary'
import CentipedeEnergySegmentDetailsMainColumn from './MainColumn/CentipedeEnergySegmentDetailsMainColumn'

const CentipedeEnergySegmentDetails = ({
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, energySegmentId } = params
  useEffect(() => {
    setSelectedParams({
      ...params,
      listView: 'centipede-energy-segment-details',
    })
    document.title = `${stationCode}::${blockIndex} - Enegergy Segment ${energySegmentId}`
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <DetailsPage
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={
        <CentipedeEnergySegmentDetailsSummary
          data={data.availability}
          isHealthy={data.isHealthy}
          id={energySegmentId}
        />
      }
      mainColumnComponent={
        <CentipedeEnergySegmentDetailsMainColumn data={data} />
      }
      className="energySgmtDetail"
    />
  )

  return handleQueryLoadingAndError(useEnergySegmentDetailsQuery(), ListPageJsx)
}

export default CentipedeEnergySegmentDetails
