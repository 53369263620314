import { ReactComponent as DcDcPcsIcon } from 'icons/dc-dc-converter-icon.svg'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import HealthStatus from 'components/Status/HealthStatus/HealthStatus'
import RotationStatusIcon from 'components/Status/RotationStatusIcon'

const DcDcPcsDetailsSummary = ({ data }) => {
  if (!data) return null

  const summaryMap = {
    title: `DC:DC PCS ${data.acPvBatteryIndex}::${data.dcDcGroupIndex}::${data.dcDcPcsIndex}`,
    titleIcon: <DcDcPcsIcon />,

    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Health',
            value: <HealthStatus isHealthy={data.healthy} />,
            isIcon: true,
          },
          {
            label: 'In Rotation',
            value: <RotationStatusIcon outRotation={data.outRotation} />,
            isIcon: true,
          },
        ],
      },
      {
        sectionTitle: 'BATTERY',
        rows: [
          {
            label: 'Voltage',
            value: data.dcVoltageBatteryVolt.toLocaleString() + ' VDC',
          },
          {
            label: 'Current',
            value: data.dcCurrentBatteryAmp.toLocaleString() + ' A',
          },
          {
            label: 'Power',
            value: data.dcPowerBatteryKW.toLocaleString() + ' kW',
          },
        ],
      },
      {
        sectionTitle: 'PV',
        rows: [
          {
            label: 'Voltage',
            value: data.dcVoltageBusVolt.toLocaleString() + ' VDC',
          },
          {
            label: 'Current',
            value: data.dcCurrentBusAmp.toLocaleString() + ' A',
          },
          {
            label: 'Power',
            value: data.dcPowerBusKW.toLocaleString() + ' kW',
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default DcDcPcsDetailsSummary
