import { fetchStationList } from 'api/queries.api'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from 'contexts/session'

/**
 * Returns stations list
 * @returns 5 second interval block status
 */
const useStationsDataQuery = () => {
  const { session, setSession } = useContext(SessionContext)

  return useQuery(['Stations'], () => fetchStationList(session, setSession), {
    refetchInterval: 5000,
  })
}

export default useStationsDataQuery
