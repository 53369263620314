import { useState, useContext, useRef } from 'react'
import * as Styles from './FirmwareCommands.styles'
import { Typography } from 'components/typography/Typography.styled'
import { FONT_SIZES } from 'design_system/typography'
import ToolbarDropdown from 'components/Toolbar/ToolbarDropdown/ToolbarDropdown'
import {
  FormInput,
  FormSubmitButton,
} from 'components/Form/FormComponents.styled'
import { COLORS } from 'design_system/colors'
import { SessionContext } from 'contexts/session'
import { firmwareUploadCommand } from 'api/queries.api'
import { ToastMessage } from 'components/Toasts/ToastMessages'

export const FirmwareUpload = () => {
  const inputFileRef = useRef(null)

  const { session } = useContext(SessionContext)

  const [selectedFirmwareType, setSelectedFirmwareType] = useState('Select One')
  const [majorValue, setMajorValue] = useState('')
  const [isMajorValid, setIsMajorValid] = useState(false)
  const [minorValue, setMinorValue] = useState('')
  const [isMinorValid, setIsMinorValid] = useState(false)
  const [revisionValue, setRevisionValue] = useState('')
  const [isRevisionValid, setIsRevisionValid] = useState(false)
  const [isFileValid, setIsFileValid] = useState(true)
  const [uploadedFile, setUploadedFile] = useState()
  const [uploadedFileName, setUploadedFileName] = useState('No File Chosen')
  const [isUploading, setIsUploading] = useState(false)

  const firmwareTypes = ['Turtle', 'CGC', 'BPC', 'SC', 'LCD', 'Feather']

  const regex = /^\d+$/

  const checkNumeric = (e) => {
    const value = e.key

    if (!regex.test(value)) {
      e.preventDefault()
    }
  }

  const isFormValid =
    selectedFirmwareType !== 'Select One' &&
    isMajorValid &&
    isMinorValid &&
    isRevisionValid &&
    isFileValid &&
    uploadedFileName !== 'No File Chosen' &&
    uploadedFileName !== 'Please choose a .war file' &&
    uploadedFileName !== 'Please choose a .bin file' &&
    uploadedFileName !== 'Please choose a .war or a .bin file' &&
    !isUploading

  const setSelectedFirmwareTypeHandler = (type) => {
    setSelectedFirmwareType(type)
    if (uploadedFile) {
      if (type === 'Turtle' || type === 'Feather') {
        if (uploadedFile.name.substr(-4) !== '.war') {
          setIsFileValid(false)
          setUploadedFileName('Please choose a .war file')
          return
        }
        setIsFileValid(true)
        setUploadedFileName(uploadedFile.name)
        return
      }
      if (uploadedFile.name.substr(-4) !== '.bin') {
        setIsFileValid(false)
        setUploadedFileName('Please choose a .bin file')
        return
      }
      setIsFileValid(true)
      setUploadedFileName(uploadedFile.name)
    }
  }

  const onChangeHandler = (e, inputType) => {
    const valid = regex.test(e.target.value) && e.target.value.trim().length > 0

    switch (inputType) {
      case 'major':
        if (valid) {
          setIsMajorValid(true)
          setMajorValue(e.target.value)
          return
        }
        setIsMajorValid(false)
        return

      case 'minor':
        if (valid) {
          setIsMinorValid(true)
          setMinorValue(e.target.value)
          return
        }
        setIsMinorValid(false)
        return

      case 'revision':
        if (valid) {
          setIsRevisionValid(true)
          setRevisionValue(e.target.value)
          return
        }
        setIsRevisionValid(false)
    }
  }

  const onSubmitHandler = (e) => {
    setIsUploading(true)
    e.preventDefault()
    const command = {
      firmwareType: selectedFirmwareType.toUpperCase(),
      major: majorValue,
      minor: minorValue,
      revision: revisionValue,
    }

    const payload = {
      command: JSON.stringify(command),
      file: uploadedFile,
    }

    toastMessageHandler('FIRMWARE UPLOAD COMMAND IN PROGRESS', '', 'progress')

    const executeCommand = async () => {
      const response = await firmwareUploadCommand(session, payload)

      if (response.md5) {
        setIsUploading(false)
        return toastMessageHandler(
          'FIRMWARE UPLOAD COMMAND COMPLETE',
          selectedFirmwareType +
            ' - ' +
            majorValue +
            '.' +
            minorValue +
            '.' +
            revisionValue,
          'complete',
          'toastInfo',
        )
      }

      setIsUploading(false)
      return toastMessageHandler(
        'FIRMWARE UPLOAD COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }
    executeCommand()

    setTimeout(() => {
      setIsUploading(false)
      toastMessageHandler(
        'FIRMWARE UPLOAD COMMAND FAILED',
        'Please try again.',
        'fail',
        'toastInfo',
      )
    }, 300000) //300000 ms = 5 min
  }

  const toastMessageHandler = (title, body, type, toastId) => {
    return ToastMessage(title, body, type, toastId)
  }

  const onUploadClickHandler = () => {
    inputFileRef.current.click()
  }

  const onFileUploadHandler = (e) => {
    if (selectedFirmwareType === 'Select One') {
      if (
        e.target.files[0].name.substr(-4) === '.war' ||
        e.target.files[0].name.substr(-4) === '.bin'
      ) {
        setIsFileValid(true)
        setUploadedFile(e.target.files[0])
        setUploadedFileName(e.target.files[0].name)
        return
      }
      setIsFileValid(false)
      setUploadedFileName('Please choose a .war or a .bin file')
      return
    }
    if (
      selectedFirmwareType === 'Turtle' ||
      selectedFirmwareType === 'Feather'
    ) {
      if (e.target.files[0].name.substr(-4) === '.war') {
        setIsFileValid(true)
        setUploadedFile(e.target.files[0])
        setUploadedFileName(e.target.files[0].name)
        return
      }
      setIsFileValid(false)
      setUploadedFileName('Please choose a .war file')
    } else if (e.target.files[0].name.substr(-4) === '.bin') {
      setIsFileValid(true)
      setUploadedFile(e.target.files[0])
      setUploadedFileName(e.target.files[0].name)
      return
    } else if (
      selectedFirmwareType !== 'Turtle' &&
      selectedFirmwareType !== 'Feather'
    ) {
      setIsFileValid(false)
      setUploadedFileName('Please choose a .bin file')
    }
  }

  return (
    <Styles.Container>
      <Typography $bold $fontSize={FONT_SIZES.xl} $margin="5px 0">
        Firmware Upload Command
      </Typography>
      <Styles.FormRow>
        <Styles.FormColumn>
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            FIRMWARE TYPE
          </Typography>
          <ToolbarDropdown
            title={selectedFirmwareType}
            width="260px"
            handleClose={null}
            backgroundColor="white"
            textColor="black"
            fontSize="12px"
            height="32px"
            border="1px solid #ccc"
            borderRadius="4px"
          >
            {firmwareTypes.map((type) => (
              <option
                style={{
                  cursor: 'pointer',
                  padding: '4px',
                  color: 'black',
                  backgroundColor:
                    type === selectedFirmwareType ? COLORS.light_grey : '',
                }}
                key={type}
                onClick={() => setSelectedFirmwareTypeHandler(type)}
                onKeyDown={null}
              >
                {type}
              </option>
            ))}
          </ToolbarDropdown>
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            MAJOR
          </Typography>
          <FormInput
            id="major"
            onChange={(e) => onChangeHandler(e, 'major')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            MINOR
          </Typography>
          <FormInput
            id="minor"
            onChange={(e) => onChangeHandler(e, 'minor')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
        <Styles.FormColumn $width="260px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            REVISION
          </Typography>
          <FormInput
            id="revision"
            onChange={(e) => onChangeHandler(e, 'revision')}
            onKeyPress={(e) => checkNumeric(e)}
            maxLength="9"
          />
        </Styles.FormColumn>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.FormColumn $width="300px">
          <Typography
            $fontSize={FONT_SIZES.medium}
            $fontColor={COLORS.font_secondary}
            $margin="12px 0"
          >
            FIRMWARE FILE
          </Typography>
          <input
            type="file"
            ref={inputFileRef}
            style={{
              display: 'block',
              visibility: 'hidden',
              width: 0,
              height: 0,
            }}
            onChange={(e) => onFileUploadHandler(e)}
          />
          <Styles.UploadButton onClick={onUploadClickHandler}>
            <span
              style={{
                fontSize: '12px',
                fontWeight: 500,
                display: 'block',
                paddingBottom: '2px',
              }}
            >
              Choose File
            </span>
          </Styles.UploadButton>
          <Styles.FileName $isFileValid={isFileValid}>
            {uploadedFileName}
          </Styles.FileName>
        </Styles.FormColumn>
      </Styles.FormRow>
      <FormSubmitButton
        $width="180px"
        $height="30px"
        $marginTop="25px"
        $isValid={isFormValid}
        disabled={!isFormValid}
        onClick={(e) => {
          onSubmitHandler(e)
        }}
        $fontSize="12px"
      >
        UPLOAD FIRMWARE
      </FormSubmitButton>
    </Styles.Container>
  )
}
