// Style Imports
import * as Styles from './modalv2.styled-components'

// Icon imports
import { ReactComponent as CloseIcon } from '../../../icons/close-icon.svg'
import { useRef, useEffect } from 'react'
import './modalv2.styles.css'

// forwardRef lets your component expose a DOM node to parent component with a ref.
const ModalV2 = ({
  isOpen,
  handleClose,
  title,
  children,
  footerContent,
  scrollableFooter,
  isAbsolute = false,
}) => {
  const dialogRef = useRef()

  useEffect(() => {
    dialogRef.current?.showModal()
    dialogRef.current?.addEventListener('close', () => {
      handleClose()
    })
    dialogRef.current?.addEventListener('click', (e) => {
      if (e.target === dialogRef.current) {
        handleClose()
      }
    })
  }, [isOpen, handleClose])

  return (
    <dialog
      ref={dialogRef}
      style={{
        padding: 0,
        position: isAbsolute ? 'absolute' : null,
      }}
    >
      <Styles.TitleRow>
        <Styles.Title>{title}</Styles.Title>
        <Styles.CloseButton onClick={handleClose}>
          <CloseIcon />
        </Styles.CloseButton>
      </Styles.TitleRow>
      <Styles.ScrollableContent
        id="scrollable-content"
        $scrollableFooter={scrollableFooter}
      >
        <Styles.Content>{children}</Styles.Content>
        {scrollableFooter && (
          <Styles.FooterRow>{footerContent}</Styles.FooterRow>
        )}
      </Styles.ScrollableContent>
      {!scrollableFooter && (
        <Styles.FooterRow>{footerContent}</Styles.FooterRow>
      )}
    </dialog>
  )
}

export default ModalV2
