import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import handleQueryLoadingAndError from 'api/useQueryHooks/handleQueryLoadingAndError'
import DetailsPage from '../../../components/Layouts/DetailsPage/DetailsPage'
import PCSDetailsSummary from './Summary/PCSDetailsSummary'
import PCSDetailsMainColumn from './MainColumn/PCSDetailsMainColumn'
import ACBatteryOneline from '../../../components/Onelines/ACBatteryOneline/ACBatteryOneline'
import usePCSDetailsStatusQuery from 'api/useQueryHooks/usePCSDetailsStatusQuery'

const PCSDetails = ({
  viewOneline,
  toggleViewOneline,
  selectedParams,
  setSelectedParams,
  setSelectedPage,
}) => {
  const params = useParams()
  const { stationCode, blockIndex, acBatteryId, pcsIndex } = params

  useEffect(() => {
    document.title = `${stationCode} :: ${blockIndex} - PCS ${acBatteryId} :: ${pcsIndex}`
    setSelectedParams({ ...params, listView: 'pcs-details' })
    setSelectedPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ListPageJsx = (data) => (
    <DetailsPage
      viewOneline={viewOneline}
      toggleViewOneline={toggleViewOneline}
      selectedParams={selectedParams}
      setSelectedParams={setSelectedParams}
      setSelectedPage={setSelectedPage}
      summaryColumnComponent={<PCSDetailsSummary data={data} />}
      onelineComponent={<ACBatteryOneline />}
      mainColumnComponent={<PCSDetailsMainColumn />}
    />
  )

  return handleQueryLoadingAndError(usePCSDetailsStatusQuery(), ListPageJsx)
}

export default PCSDetails
