import { ResourceEnum } from 'api/dictionaries/rotation-excuses'
import CommandModalTemplate from '../CommandModalTemplate'
import {
  RotationForm,
  defaultRotationFormValues,
} from '../SetRotation/RotationForm'
import { DcDcPcsRotationResults } from './DcDcPcsRotationResults'

const modalTitles = {
  start: 'Set PCS Rotation',
  finish: 'Set PCS Rotation',
}
const label = 'PCSES'
export const queryName = 'setDcDcPcsRotation'

const userInputFormJsx = ({ ids, onUserInputFormChange, userInputForm }) => {
  return RotationForm({
    ids,
    onUserInputFormChange,
    userInputForm,
    resourceType: ResourceEnum.inverter,
    label,
  })
}

const commandResultJsx = ({ ids, userInputForm }) => (
  <DcDcPcsRotationResults
    ids={ids}
    userInputForm={userInputForm}
  ></DcDcPcsRotationResults>
)

export const DcDcPcsRotationModal = ({ setShowCommandModal, ids }) => {
  return (
    <CommandModalTemplate
      ids={ids}
      setShowCommandModal={setShowCommandModal}
      modalTitles={modalTitles}
      defaultFormValues={defaultRotationFormValues}
      CommandResultJsx={commandResultJsx}
      UserInputFormJsx={userInputFormJsx}
      queryName={queryName}
      isFormValidated
    />
  )
}
