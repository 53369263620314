import _ from 'lodash'

export const renderPlotlines = (data, viewType) => {
  if (!_.isEmpty(data) && viewType === 'string') {
    return _.reduce(
      data,
      function (result, value, i) {
        if (_.some(result, { arrayIndex: value.arrayIndex })) {
          return result
        } else {
          return [
            ...result,
            {
              value: i - 0.5,
              color: '#d8d8d8',
              dashStyle: 'dash',
              width: 1,
              zIndex: 1,
              arrayIndex: value.arrayIndex,
            },
          ]
        }
      },
      [],
    )
  } else return []
}

export const formatByArray = (
  distributionData,
  minArray,
  maxArray,
  arrayData,
) =>
  _.reduce(
    distributionData,
    function (result, value) {
      if (value.arrayIndex < minArray || value.arrayIndex > maxArray) {
        return result
      } else if (_.some(result, ['arrayIndex', value.arrayIndex])) {
        const index = _.findIndex(result, ['arrayIndex', value.arrayIndex])
        const averagedArray = _.find(result, ['arrayIndex', value.arrayIndex])

        const updatedArray = {
          ...value,
          cellTemperature000: _.mean([
            averagedArray.cellTemperature000,
            value.cellTemperature000,
          ]),
          cellTemperature025: _.mean([
            averagedArray.cellTemperature025,
            value.cellTemperature025,
          ]),
          cellTemperature075: _.mean([
            averagedArray.cellTemperature075,
            value.cellTemperature075,
          ]),
          cellTemperature100: _.mean([
            averagedArray.cellTemperature100,
            value.cellTemperature100,
          ]),
          cellTemperatureAverage: _.mean([
            averagedArray.cellTemperatureAverage,
            value.cellTemperatureAverage,
          ]),
          cellVoltage000: _.mean([
            averagedArray.cellVoltage000,
            value.cellVoltage000,
          ]),
          cellVoltage025: _.mean([
            averagedArray.cellVoltage025,
            value.cellVoltage025,
          ]),
          cellVoltage075: _.mean([
            averagedArray.cellVoltage075,
            value.cellVoltage075,
          ]),
          cellVoltage100: _.mean([
            averagedArray.cellVoltage100,
            value.cellVoltage100,
          ]),
          cellVoltageAverage: _.mean([
            averagedArray.cellVoltageAverage,
            value.cellVoltageAverage,
          ]),
          currentAmps: _.mean([averagedArray.currentAmps, value.currentAmps]),
          id: `${value.arrayIndex}`,
          stringCount: _.find(arrayData.arrays, {
            arrayIndex: value.arrayIndex,
          }).totalStackCount,
        }

        result[index] = updatedArray
        return result
      } else return [...result, value]
    },
    [],
  )

export const formatByString = (
  distributionData,
  minArray,
  maxArray,
  arrayData,
) =>
  _.reduce(
    distributionData,
    function (result, value) {
      if (value.arrayIndex < minArray || value.arrayIndex > maxArray) {
        return result
      } else
        return [
          ...result,
          {
            ...value,
            id: `${value.arrayIndex}::${value.stringIndex}`,
            stringCount: _.get(arrayData, 'dcPowerAndEnergy.totalStackCount'),
          },
        ]
    },
    [],
  )
