import { useState, useEffect } from 'react'
import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'

import BinaryBarChart from 'components/Charts/BinaryBarChart/BinaryBarChart'
import useOutdoorEnvironmentQuery from 'api/useQueryHooks/useOutdoorEnvironmentQuery'
import { ReactComponent as CentipedeLineupIcon } from 'icons/centipede-lineup.svg'
import { checkNullRoundValue } from 'modules/Station/components/Tables/tableUtils'
import { filterSensorsSummary } from 'utils/sensors-utils'

const CentipedeLineupsSummary = ({ data }) => {
  const { data: outdoorData, isLoading: isLoadingOutdoorData } =
    useOutdoorEnvironmentQuery(!!data)

  const outdoorTemp = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalAirTemp, '° C')
  const outdoorHumidity = isLoadingOutdoorData
    ? 'Loading'
    : checkNullRoundValue(outdoorData.externalHumidity, '%')

  const environmentalSection = [
    {
      sectionTitle: 'ENVIRONMENT',
      rows: [
        {
          label: 'Air Temp',
          value: outdoorTemp.toLocaleString(),
        },
        {
          label: 'Humidity',
          value: outdoorHumidity.toLocaleString(),
        },
      ],
    },
  ]

  const [formattedSections, setFormattedSections] = useState([])

  useEffect(() => {
    setFormattedSections(
      filterSensorsSummary(data, false, 'centipedeLineupsSummed'),
    )
  }, [data])

  if (!data) return null

  const centipedeLineups = data.totalLineups
  const healthyCount = data.totalHealthyLineups
  const unhealthyCount = centipedeLineups - healthyCount

  const summaryMap = {
    title: `Centipede Lineups`,
    titleIcon: <CentipedeLineupIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy Centipedes: ${healthyCount} | Unhealthy Centipedes: ${unhealthyCount}`}
        id="centipedeLineupsHealth"
      />
    ),
    sections: environmentalSection.concat(formattedSections),
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default CentipedeLineupsSummary
