import Table from 'components/Table/Table/Table'

export const AuditLogTable = ({
  data,
  onChangePage,
  onChangeRowsPerPage,
  isLoading,
}) => {
  const formattedDate = (row) =>
    row.timestamp
      ? new Date(row.timestamp).toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'UTC',
        })
      : 'Unknown'

  const columns = [
    {
      name: 'TIMESTAMP',
      id: 'timestamp',
      sortable: true,
      selector: (row) => row.timestamp,
      omit: false,
      cell: formattedDate,
    },
    {
      name: 'User',
      id: 'user',
      sortable: true,
      selector: (row) => row.userLogon,
      omit: false,
      cell: (row) => row.userLogon,
    },
    {
      name: 'ACTION',
      id: 'action',
      sortable: true,
      selector: (row) => row.action,
      omit: false,
      cell: (row) => row.action,
    },
  ]

  const getRowId = (row) => Number(row.id)
  return (
    <Table
      tableId="AuditLogTable"
      data={data?.elementList ?? []}
      columns={columns}
      getRowId={getRowId}
      fixedHeader={false}
      controls={false}
      paginationTotalRows={data?._page?.totalElements}
      pagination
      paginationServer
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      progressPending={isLoading}
      defaultId="timestamp"
    />
  )
}
