import _ from 'lodash'

import SummaryColumn from '../../../../components/SummaryColumn/SummaryColumn'
import { ReactComponent as ACBatteryIcon } from 'icons/ac-battery-icon.svg'
import { ReactComponent as AcPvBatteryIcon } from 'icons/ac-pv-battery-icon.svg'
import BinaryBarChart from '../../../../../../components/Charts/BinaryBarChart/BinaryBarChart'
import { getBlockPowerAndEnergyKey } from 'utils/common-utils'
import useBlockStatus from 'api/useQueryHooks/useBlockStatus'
import LoadingPage from 'components/Loaders/LoadingPage/LoadingPage'

const ACBatterySummary = () => {
  const { data, isLoading } = useBlockStatus()
  if (isLoading) return <LoadingPage />
  const acBatteries = _.chain(data).get('acBatteries').toArray().value()
  const { isDcCoupled } = data
  const batteryType = isDcCoupled ? 'AC PV' : 'AC'
  const blockPowerAndEnergyKey = getBlockPowerAndEnergyKey(isDcCoupled)
  const blockPowerAndEnergyData = data[blockPowerAndEnergyKey]

  const filteredArrays = _.chain(data).get('arrays').toArray().value()
  // filtering offlineStackCount and nearlineStackCount (if either is true, then the battery is not healthy):
  const filteredNearlineOrOffline = filteredArrays.filter(
    (array) =>
      array.dcPowerAndEnergy.nearlineStackCount !== 0 ||
      array.dcPowerAndEnergy.offlineStackCount !== 0,
  ).length

  const count = acBatteries.length
  const enabledReadyValidCount = _.filter(acBatteries, {
    enabled: true,
    valid: true,
    ready: true,
  }).length
  // if filteredNearlineOrOffline is bigger than or equal to enabledReadyValidCount, then there's no healthy battery:
  const healthyCount =
    enabledReadyValidCount > filteredNearlineOrOffline
      ? enabledReadyValidCount - filteredNearlineOrOffline
      : 0
  const unhealthyCount = count - healthyCount

  const summaryMap = {
    title: `${batteryType} Batteries`,
    titleIcon: isDcCoupled ? <AcPvBatteryIcon /> : <ACBatteryIcon />,
    titleChart: (
      <BinaryBarChart
        positiveCount={healthyCount}
        negativeCount={unhealthyCount}
        tooltip={`Healthy ${batteryType} Batteries: ${healthyCount} | Unhealthy ${batteryType} Batteries: ${unhealthyCount}`}
        id="acBatteryHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'Status',
        rows: [
          {
            label: 'Ready',
            value: _.filter(acBatteries, { ready: true }).length,
          },
          {
            label: 'Enabled',
            value: _.filter(acBatteries, { enabled: true }).length,
          },
          {
            label: 'Total',
            value: count,
          },
        ],
      },
      {
        sectionTitle: 'Availability',
        rows: [
          {
            label: 'Online Energy',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('onlineAvailableKWH', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'Energy Capacity',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('totalCapacityKWH', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kWh`,
          },
          {
            label: 'AC Charge Power',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('availableChargeAcPowerKW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'AC Discharge Power',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('availableDischargeAcPowerKW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Real Power',
        rows: [
          {
            label: 'Measured',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('measuredkW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
          {
            label: 'Commanded',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('commandedkW', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kW`,
          },
        ],
      },
      {
        sectionTitle: 'Reactive Power',
        rows: [
          {
            label: 'Measured',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('measuredKVAr', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kVAR`,
          },
          {
            label: 'Commanded',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('commandedKVAr', 'N/A')
              .round(1)
              .value()
              .toLocaleString()} kVAR`,
          },
        ],
      },
      {
        sectionTitle: 'Strings',
        rows: [
          {
            label: 'Online Strings',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('onlineStackCount', 'N/A')
              .value()
              .toLocaleString()}`,
          },
          {
            label: 'Total Strings',
            value: `${_.chain(blockPowerAndEnergyData)
              .get('totalStackCount', 'N/A')
              .value()
              .toLocaleString()}`,
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default ACBatterySummary
