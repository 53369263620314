import SummaryColumn from 'modules/Station/components/SummaryColumn/SummaryColumn'
import { ReactComponent as EnvironmentalControllersIcon } from 'icons/env-controller.svg'
import BinaryBarChartTotalVsUsed from 'components/Charts/BinaryBarChart/BinaryBarChartTotalVsUsed'

const EnvironmentalControllersListSummary = ({ data }) => {
  if (!data) return null

  const summaryMap = {
    title: 'Environmental Controllers',
    titleIcon: <EnvironmentalControllersIcon />,
    titleChart: (
      <BinaryBarChartTotalVsUsed
        positiveCount={data.healthyTotal}
        totalCount={data.total}
        tooltip={`Healthy: ${data.healthyTotal} | Total: ${data.total}`}
        id="envControllersHealth"
      />
    ),
    sections: [
      {
        sectionTitle: 'STATUS',
        rows: [
          {
            label: 'Healthy',
            value: data.healthyTotal.toLocaleString(),
          },
          {
            label: 'Total',
            value: data.total.toLocaleString(),
          },
        ],
      },
    ],
  }

  return <SummaryColumn summaryMap={summaryMap} />
}

export default EnvironmentalControllersListSummary
